import React from 'react';
import { Condenser } from 'data/types';
import { CpuChipIcon, RectangleStackIcon } from '@heroicons/react/24/outline';
import { abbreviateNumber } from 'utils/helpers';
import Tag from 'components/tag';

type CondenserMapProps = {
  condenser: Condenser;
  showSpaces?: boolean;
  showBTUs?: boolean;
};

export default function CondenserMap({
  condenser,
  showSpaces = true,
  showBTUs = true
}: CondenserMapProps) {
  return (
    <div className='flex flex-col'>
      <div className='flex'>
        <div className='flex flex-col pr-2 pt-2'>
          <div className='h-3 w-3 rounded-full bg-gray-300' />
          <div className='border-l-4 border-gray-300 grow ml-[4px]' />
        </div>
        <div className='flex flex-wrap grow items-center gap-2'>
          <CpuChipIcon className='h-6 w-6 text-gray-800' />
          <span className='text-xl font-semibold'>
            {condenser.name}
          </span>
        </div>
      </div>
      {condenser.zones.map((zone, zoneIndex) => (
        <div key={zone.uniqueId} className='flex items-start gap-2'>
          <div className="flex items-start relative self-stretch">
            {zoneIndex < condenser.zones.length - 1 && (
              <div className='absolute left-[4px] h-full border-l-4 border-gray-300' />
            )}
            <div className='border-b-4 border-l-4 border-gray-300 rounded-bl-2xl pt-6 mb-[4px] w-7 ml-[4px]' />
            <div className='h-3 w-3 rounded-full bg-gray-300 mt-[20px]' />
          </div>
          <div className='flex flex-col gap-2 grow pt-3'>
            <div className='flex items-center flex-wrap gap-2'>
              <RectangleStackIcon className='h-6 w-6 text-gray-800 shrink-0' />
              <span className='text-xl font-semibold shrink-0'>
                {zone.name}
              </span>
              {showBTUs && (
                <>
                  <Tag>
                    <span className='inline-flex items-center gap-1'>
                      <div className='h-3 w-3 rounded-full bg-orange-400' />
                      {abbreviateNumber(zone.zoneItems.reduce((acc, item) => acc + item.BTUs.heating, 0))}
                    </span>
                  </Tag>
                  <Tag>
                    <span className='inline-flex items-center gap-1'>
                      <div className='h-3 w-3 rounded-full bg-blue-400' />
                      {abbreviateNumber(zone.zoneItems.reduce((acc, item) => acc + item.BTUs.cooling, 0))}
                    </span>
                  </Tag>
                </>
              )}
              {showSpaces && zone.zoneItems.map((item) => (
                <Tag key={item.uniqueId} text={item.name} />
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

CondenserMap.defaultProps = {
  showSpaces: true,
  showBTUs: true
};