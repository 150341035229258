import React, { useContext, useState, useEffect } from 'react';
import { useDrag } from 'react-dnd';
import clsx from 'clsx';
import { ConfigurationContext } from 'data/context';
import { Configuration, ExistingSystem } from 'data/types';
import { confirmationModal } from 'utils/helpers';
import { XMarkIcon, EllipsisVerticalIcon } from '@heroicons/react/20/solid';

type ExistingSystemProps = {
  configId: string;
  existingSystem: ExistingSystem;
  zoneable?: boolean;
};

export default function ExistingSystemComponent({
  configId,
  existingSystem,
  zoneable = false
}: ExistingSystemProps) {
  const {
    configurationOptions,
    setConfigurationOptions,
    selectedSystems
  } = useContext(ConfigurationContext);
  const [config, setConfig] = useState<Configuration | null>(null);

  useEffect(() => {
    setConfig(configurationOptions.find((conf) => conf.uniqueId === configId) ?? null);
  }, [configurationOptions, configId]);

  const [{ isDragging, canDrag }, drag] = useDrag(() => ({
    type: `config-${configId}-existing-system-item`,
    item: { id: existingSystem.id, type: 'existing-system-item' },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
      canDrag: !!monitor.canDrag()
    })
  }), [config]);

  const deleteSelf = () => {
    setConfigurationOptions((draft) => {
      const configIndex = draft.findIndex((conf) => conf.uniqueId === configId);
      const condenserIndex = draft[configIndex].condensers.findIndex((cond) =>
        cond.zones.some((zone) => zone.existingSystemId === existingSystem.id)
      );
      const zoneIndex = draft[configIndex].condensers[
        condenserIndex
      ].zones.findIndex((zone) => zone.existingSystemId === existingSystem.id);
      // eslint-disable-next-line no-param-reassign
      draft[configIndex].condensers[condenserIndex].zones[
        zoneIndex
      ].existingSystemId = undefined;
    });
  };

  const handleDeleteClick = () => {
    if (selectedSystems.length > 0) {
      confirmationModal('Are you sure you want to update this configuration? This will reset all selected products and systems!', deleteSelf);
    } else {
      deleteSelf();
    }
  };

  const zoneableClasses = clsx(
    'flex gap-1 items-center bg-gray-200 rounded-md py-1 pr-3 text-sm font-medium text-gray-700 whitespace-nowrap border-2 border-red-400 capitalize',
    isDragging ? 'cursor-grabbing' : canDrag && 'cursor-grab'
  );

  return (
    <div className='inline-block'>
      <div ref={drag}>
        {zoneable ? (
          <div className={zoneableClasses}>
            <div className='flex -ml-2'>
              <EllipsisVerticalIcon className='h-4 w-4 translate-x-3' />
              <EllipsisVerticalIcon className='h-4 w-4' />
            </div>
            {existingSystem.name} ({existingSystem.heatingCapacity} BTU {existingSystem.heatingEquipmentType})
          </div>
        ) : (
          <span className='inline-flex items-center gap-1 rounded-md bg-gray-200 px-3 py-1 text-sm font-medium text-gray-700 whitespace-nowrap capitalize'>
            <button
              type='button'
              className='-ml-1 relative h-5 w-5 rounded-sm hover:bg-gray-500/20 inline-flex items-center justify-center'
              onClick={handleDeleteClick}
            >
              <XMarkIcon className='w-4 h-4' />
            </button>
            {existingSystem.name} ({existingSystem.heatingCapacity} BTU {existingSystem.heatingEquipmentType})
          </span>
        )}
      </div>
    </div>
  );
}
