import React, { useContext, useEffect, useState } from 'react';
import { CpuChipIcon, RectangleStackIcon } from '@heroicons/react/24/outline';
import { ConfigurationContext } from 'data/context';
import Select from 'components/inputs/select';
import ProgressIndicator from 'components/progress-indicator';
import {
  AccessoryProduct,
  AccessoryWithLength,
  AccessoryWithQuantity,
  SystemOptionCondenser
} from 'data/types';
import Checkbox from 'components/inputs/checkbox';
import Tag from 'components/tag';
import axios from 'utils/api';
import { errorModal, formatUSD } from 'utils/helpers';
import ProductPhoto from 'components/product-photo';
import ProductLink from 'components/product-link';

type LineSetOptionProps = {
  condenser: SystemOptionCondenser;
};

export default function LineSetOption({ condenser }: LineSetOptionProps) {
  const {
    selectedSystems,
    setSelectedSystems,
    activeSelectedSystem
  } = useContext(ConfigurationContext);
  const [firstWireClickZoneId, setFirstWireClickZoneId] = useState<string | null>(null);

  const addCombos = (lengths: number[], maxWireLength: number, combos: Set<string>) => {
    const uniqueLengths = new Set(lengths);
    uniqueLengths.forEach((length1) => {
      const arrLengths = lengths.slice();
      const idx = arrLengths.indexOf(length1);
      arrLengths.splice(idx, 1);
      for (let i = 0; i < arrLengths.length; i += 1) {
        const arr = arrLengths.slice();
        const length2 = arr[i];
        if ((length1 + length2) > maxWireLength) {
          // eslint-disable-next-line no-continue
          continue;
        }
        arr.splice(i, 1);
        const newArr = [...arr, length1 + length2].sort((a, b) => a - b);
        const newArrJSON = JSON.stringify(newArr);
        if (!combos.has(newArrJSON)) {
          combos.add(newArrJSON);
          if (arr.length) {
            addCombos(newArr, maxWireLength, combos);
          }
        }
      }
    });
  };

  const calculateWireProducts = async (lengths: number[], wires: AccessoryWithLength[]): Promise<AccessoryWithQuantity[]> => {
    lengths.sort((a, b) => a - b);
    const maxWireLength = Math.max(...wires.map(w => w.length));
    const combos: Set<string> = new Set();
    combos.add(JSON.stringify(lengths));
    addCombos(lengths, maxWireLength, combos);

    const options = new Set();
    const productsForLengths: { [key: number]: AccessoryWithLength } = {};

    combos.forEach((combo) => {
      const comboLengths = JSON.parse(combo);
      const option = comboLengths.map((length: number) => {
        if (!(length in productsForLengths)) {
          [productsForLengths[length]] = wires.filter((w) => w.length >= length).sort((a, b) => a.price - b.price);
        }
        return productsForLengths[length];
      }).reduce((accumulator: any, product: AccessoryWithLength) => {
        const newAccumulator = { ...accumulator };
        newAccumulator.products.push(product.productId.toString());
        newAccumulator.retailPrice += product.price;
        newAccumulator.totalLength += product.length;
        return newAccumulator;
      }, {
        products: [],
        retailPrice: 0,
        totalLength: 0
      });

      options.add(JSON.stringify(option));
    });

    const arrOptions = Array.from(options).map((option) => JSON.parse(option as string));
    arrOptions.sort((a, b) => {
      if (a.retailPrice === b.retailPrice) {
        return a.totalLength - b.totalLength;
      }
      return a.retailPrice - b.retailPrice;
    });

    if (arrOptions.length === 0) {
      return [];
    }

    const bestOption = arrOptions[0];

    return bestOption.products.map((productId: string) => {
      const product = wires.find(wire => wire.productId === parseInt(productId, 10));
      if (product) {
        return {
          ...product,
          productId: product.productId,
          price: product.price,
          quantity: 1,
          additionalInfo: { wireLength: product.length }
        };
      }
      return null;
    }).filter(Boolean) as AccessoryWithQuantity[];
  };

  const updateConfigAccessories = async (abortController: AbortController) => {
    const newSelectedSystems = structuredClone(selectedSystems);
    newSelectedSystems[activeSelectedSystem].selectedAccessories = newSelectedSystems[
      activeSelectedSystem
    ].selectedAccessories.filter(
      (acc) => acc.type !== 'line_set' && acc.type !== 'wire'
    );
    const totalLengths: number[] = [];
    newSelectedSystems[activeSelectedSystem].configuration.condensers.forEach((cond) => {
      cond.zones.forEach((zone) => {
        if (zone.selectedLineSet) {
          const accessoryIndex = newSelectedSystems[
            activeSelectedSystem
          ].selectedAccessories.findIndex(
            (acc) => acc.productId === zone.selectedLineSet?.productId
          );
          if (accessoryIndex !== -1) {
            newSelectedSystems[activeSelectedSystem].selectedAccessories[
              accessoryIndex
            ].quantity += 1;
          } else {
            newSelectedSystems[activeSelectedSystem].selectedAccessories.push({
              ...zone.selectedLineSet,
              quantity: 1
            });
          }
          if (zone.addWire && zone.selectedLineSet.additionalInfo?.linesetLength) {
            totalLengths.push(zone.selectedLineSet.additionalInfo.linesetLength);
          }
        }
      });
    });
    if (totalLengths.length > 0) {
      try {
        const wires = (await axios.get(
          'wires',
          { signal: abortController.signal }
        )).data as AccessoryWithLength[];
        const wireProducts = await calculateWireProducts(totalLengths, wires);
        wireProducts.forEach(product => {
          const existingIndex = newSelectedSystems[activeSelectedSystem].selectedAccessories.findIndex(
            acc => acc.productId === product.productId
          );
          if (existingIndex !== -1) {
            newSelectedSystems[activeSelectedSystem].selectedAccessories[existingIndex].quantity += product.quantity;
          } else {
            newSelectedSystems[activeSelectedSystem].selectedAccessories.push(product);
          }
        });
      } catch (error: any) {
        console.error(error);
        if (error.name !== 'CanceledError') {
          errorModal('Failed to fetch wire products. Please try again.');
        }
      }
    }
    newSelectedSystems[activeSelectedSystem].accessoriesCompleted = newSelectedSystems[
      activeSelectedSystem
    ].accessoriesCompleted.filter((category) => category !== 'lineSet');
    if (
      newSelectedSystems[activeSelectedSystem].configuration.condensers.filter(c => !c.selectedProduct.diy).every((c) =>
        c.zones.every((z) => z.selectedLineSet)
      )
    ) {
      newSelectedSystems[activeSelectedSystem].accessoriesCompleted.push('lineSet');
    }
    if (!abortController.signal.aborted) {
      setSelectedSystems(newSelectedSystems);
    }
  };

  const handleChange = (
    zoneUniqueId: string,
    lineSetProduct?: AccessoryProduct
  ) => {
    setSelectedSystems(draft => {
      const condenserIndex = draft[activeSelectedSystem].configuration.condensers.findIndex(
        c => c.zones.some(z => z.uniqueId === zoneUniqueId)
      );
      if (condenserIndex !== -1) {
        const zoneIndex = draft[activeSelectedSystem].configuration.condensers[condenserIndex].zones.findIndex(
          z => z.uniqueId === zoneUniqueId
        );
        if (zoneIndex !== -1) {
          // eslint-disable-next-line no-param-reassign
          draft[activeSelectedSystem].configuration.condensers[condenserIndex].zones[zoneIndex].selectedLineSet = lineSetProduct;
        }
      }
    });
  };

  const handleAddWire = (zoneUniqueId: string, selected: boolean) => {
    if (!selectedSystems[activeSelectedSystem].configuration.condensers.some(c => c.zones.some(z => z.addWire)) && selected) {
      setSelectedSystems(draft => {
        draft[activeSelectedSystem].configuration.condensers.filter(c => !c.selectedProduct.diy).forEach(c => {
          c.zones.forEach(z => {
            // eslint-disable-next-line no-param-reassign
            z.addWire = true;
          });
        });
      });
    } else {
      setSelectedSystems(draft => {
        const condenserIndex = draft[activeSelectedSystem].configuration.condensers.findIndex(
          c => c.zones.some(z => z.uniqueId === zoneUniqueId)
        );
        if (condenserIndex !== -1) {
          const zoneIndex = draft[activeSelectedSystem].configuration.condensers[condenserIndex].zones.findIndex(
            z => z.uniqueId === zoneUniqueId
          );
          if (zoneIndex !== -1) {
            // eslint-disable-next-line no-param-reassign
            draft[activeSelectedSystem].configuration.condensers[condenserIndex].zones[zoneIndex].addWire = selected;
          }
        }
      });
      if (selectedSystems[activeSelectedSystem].configuration.condensers.every(c => c.zones.every(z => z.addWire)) && !selected && firstWireClickZoneId === zoneUniqueId) {
        setSelectedSystems(draft => {
          draft[activeSelectedSystem].configuration.condensers.filter(c => !c.selectedProduct.diy).forEach(c => {
            c.zones.forEach(z => {
              // eslint-disable-next-line no-param-reassign
              z.addWire = false;
            });
          });
        });
      }
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    updateConfigAccessories(abortController);
    return () => abortController.abort();
  }, [
    JSON.stringify(condenser.zones.map(z => z.addWire)),
    JSON.stringify(condenser.zones.map(z => z.selectedLineSet))
  ]);

  useEffect(() => {
    const firstZoneId = selectedSystems[activeSelectedSystem].configuration.condensers[0]?.zones[0]?.uniqueId;
    if (firstZoneId) {
      setFirstWireClickZoneId(firstZoneId);
    }
    setSelectedSystems(draft => {
      draft[activeSelectedSystem].configuration.condensers.forEach(cond => {
        cond.zones.forEach(zone => {
          // eslint-disable-next-line no-param-reassign
          zone.addWire = true;
        });
      });
    });
  }, []);

  return (
    <div className='flex divide-x divide-gray-200'>
      <div className='flex w-[40%] shrink-0 grow-0 flex-col gap-3 p-5 justify-center'>
        <div className='flex items-center gap-2'>
          <CpuChipIcon className='h-6 w-6' />
          <span className='mr-3 text-lg font-bold'>{condenser.name}</span>
        </div>
        <div className='flex items-center divide-x divide-gray-200'>
          <div className='flex items-center gap-4 pr-8'>
            <ProductPhoto product={condenser.selectedProduct} />
            <div className='flex flex-col gap-1'>
              <span className='text-xs font-semibold uppercase text-gray-600'>
                Series
              </span>
              <span className='font-semibold'>
                {condenser.selectedProduct.series}
              </span>
            </div>
          </div>
          <div className='flex grow basis-0 flex-col gap-1 pl-8'>
            <span className='text-xs font-semibold uppercase text-gray-600'>
              Product
            </span>
            <ProductLink type='condenser' product={condenser.selectedProduct} fullName hoverInfo />
          </div>
        </div>
      </div>
      <div className='flex w-1/5 shrink-0 grow-0 flex-col'>
        {condenser.zones.map((zone) => {
          const distanceFromCondenser = zone.distanceFromCondenser ?? 0;
          const options = zone.selectedProduct?.accessories.filter((acc) => acc.type === 'line_set')
            .filter(
              (acc) =>
                (acc.additionalInfo?.linesetLength ?? 0) >=
                distanceFromCondenser
            )
            .sort(
              (a, b) =>
                (a.additionalInfo?.linesetLength ?? 0) -
                (b.additionalInfo?.linesetLength ?? 0)
            ).map((acc) => ({
              product: acc,
              label: `${acc.additionalInfo?.linesetLength ?? 0}ft`
            })) ?? [];

          return (
            <div
              key={zone.uniqueId}
              className='flex min-h-[150px] items-center'
            >
              <div className='w-full border-b-2 border-dashed border-gray-200 pt-1' />
              <div className='flex flex-col gap-2'>
                <div className='flex items-center gap-2'>
                  <ProgressIndicator
                    required={
                      !selectedSystems[
                        activeSelectedSystem
                      ].accessoriesNotNeeded.includes('lineSet') &&
                      !selectedSystems[activeSelectedSystem].noAccessoriesNeeded &&
                      !condenser.selectedProduct.diy
                    }
                    checked={!!zone.selectedLineSet}
                  />
                  <div className='text-lg font-bold text-gray-800'>
                    Line Set
                  </div>
                </div>
                <div className='w-[150px]'>
                  <Select
                    placeholder='Select line set'
                    options={options.map((o) => ({ label: `${o.label} (${formatUSD(Math.ceil(o.product.price))})`, value: o.label }))}
                    onChange={(value) =>
                      handleChange(
                        zone.uniqueId,
                        options.find((o) => o.label === value)?.product
                      )
                    }
                    value={
                      options.find(
                        (o) =>
                          o.product.productId ===
                          zone.selectedLineSet?.productId
                      )?.label
                    }
                  />
                </div>
                <label className='flex cursor-pointer items-center gap-2'>
                  <Checkbox
                    onChange={(checked) => handleAddWire(zone.uniqueId, checked)}
                    value={!!zone.addWire}
                  />
                  <span className='font-semibold whitespace-nowrap'>Add Wire</span>
                </label>
              </div>
              <div className='w-full border-b-2 border-dashed border-gray-200 pt-1' />
            </div>
          );
        })}
      </div>
      <div className='flex grow flex-col'>
        {condenser.zones.map((zone) => (
          <div
            key={zone.uniqueId}
            className='flex grow flex-col gap-3 p-5 min-h-[150px] justify-center'
          >
            <div className='flex items-center gap-5'>
              <div className="flex items-center gap-2">
                <RectangleStackIcon className='h-6 w-6 shrink-0' />
                <span className='text-lg font-bold'>{zone.name}</span>
              </div>
              <div className="flex items-center flex-wrap gap-2">
                {zone.zoneItems.map((item) => (
                  <Tag key={item.uniqueId} color='default' text={item.name} />
                ))}
              </div>
            </div>
            <div className='flex items-center divide-x divide-gray-200'>
              <div className='flex items-center gap-4 pr-8'>
                <ProductPhoto product={zone.selectedProduct} />
                <div className='flex flex-col gap-1'>
                  <span className='text-xs font-semibold uppercase text-gray-600'>
                    Type
                  </span>
                  <span className='font-semibold'>
                    {zone.selectedProduct.type ?? 'N/A'}
                  </span>
                </div>
              </div>
              <div className='flex grow basis-0 flex-col gap-1 pl-8'>
                <span className='text-xs font-semibold uppercase text-gray-600'>
                  Product
                </span>
                <ProductLink type='air handler' product={zone.selectedProduct} fullName hoverInfo />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}