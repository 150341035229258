import { useContext, useEffect, useLayoutEffect } from 'react';
import { ProjectContext, ConfigurationContext, DataContext, UIContext } from 'data/context';
import { errorModal, getNthPercentile } from 'utils/helpers';
import { rankSystems } from 'utils/ranking';
import axios from 'utils/api';
import { ValidDuctlessSystemsInput, ValidSystem, AirHandlerTypeCode, ValidDuctedSystem, ValidDuctedSystemsInput } from 'data/types';
import { findSpaceById, getRestrictedAirHandlerTypes } from 'utils/structure';
import { airHandlerTypeCodes } from 'data/constants';

type ValidSystemsUpdaterProps = {
  loading: boolean;
};

export default function ValidSystemsUpdater({ loading }: ValidSystemsUpdaterProps) {
  const {
    locationData,
    customerValues,
    setSystemOptions
  } = useContext(ProjectContext);
  const {
    structure,
    configurations,
    setConfigurations,
    configurationOptions,
    setConfigurationOptions,
    existingSystems
  } = useContext(ConfigurationContext);
  const {
    items,
    airHandlerTypes,
    rankingModifiers,
    airHandlerProducts,
    condenserProducts
  } = useContext(DataContext);
  const {
    activeTab,
    setPreferredAirHandlerErrors
  } = useContext(UIContext);

  const getValidSystems = async (systemInput: ValidDuctlessSystemsInput, abortController: AbortController) => {
    if (locationData?.zipcode) {
      try {
        const systems = (await axios.post(
          'valid-systems',
          {
            zipCode: locationData.zipcode,
            systemInput
          },
          { signal: abortController.signal }
        )).data as ValidSystem[];
        return systems;
      } catch (error: any) {
        console.error(error);
        if (error.name !== 'CanceledError') {
          errorModal('Error getting valid systems.');
        }
        return [];
      }
    }
    console.error('No location data, cannot get valid systems!');
    errorModal('No location data found, cannot get valid systems');
    return [];
  };

  const getValidDuctedSystems = async (systemInput: ValidDuctedSystemsInput, abortController: AbortController) => {
    try {
      const systems = (await axios.post(
        'valid-ducted-systems',
        { systemInput },
        { signal: abortController.signal }
      )).data as ValidDuctedSystem[];
      return systems;
    } catch (error: any) {
      console.error(error);
      if (error.name !== 'CanceledError') {
        errorModal('Error getting valid systems.');
      }
      return [];
    }
  };

  const updateValidSystems = async (abortController: AbortController) => {
    console.log('Updating valid systems...');
    const restrictedTypesById = {};
    configurationOptions.forEach((config) => {
      config.condensers.forEach((condenser) => {
        const newErrorMessages: { zoneId: string, error: string }[] = [];
        condenser.zones.forEach((zone) => {
          const restrictedAirHandlerTypes = getRestrictedAirHandlerTypes(zone, structure, items, condenser.type === 'ducted');
          restrictedTypesById[zone.uniqueId] = restrictedAirHandlerTypes;
          zone.zoneItems.forEach((zoneItem) => {
            const structureItem = findSpaceById(structure, zoneItem.structureItemId);
            if (structureItem) {
              const preferredAirHandlerType = structureItem.attributes.find((a) => a.code === 'preferred_air_handler_type')?.value;
              restrictedAirHandlerTypes.forEach((type) => {
                if (type === airHandlerTypeCodes[preferredAirHandlerType]) {
                  newErrorMessages.push({
                    zoneId: zone.uniqueId,
                    error: `One or more spaces in this zone cannot use a ${preferredAirHandlerType} air handler.`
                  });
                }
              });
            }
          });
        });
        setPreferredAirHandlerErrors(newErrorMessages);
      });
    });
    const newValidSystems: {
      condenserId: string
      validSystems?: ValidSystem[],
      validDuctedSystems?: ValidDuctedSystem[]
    }[] = [];
    await Promise.all(configurationOptions.filter(config => config.valid && config.condensers.some(cond => !cond.validSystemsFetched)).flatMap((config) =>
      config.condensers.map(async (condenser) => {
        if (condenser.type === 'ducted') {
          const systemBeingReplaced = existingSystems.find(system => system.id === condenser.zones.find((zone) => zone.existingSystemId)?.existingSystemId);
          if (locationData?.zipcode && systemBeingReplaced) {
            const systemInput: ValidDuctedSystemsInput = {
              zipCode: locationData.zipcode,
              coolingCapacity: systemBeingReplaced.coolingCapacity ?? 0,
              heatingCapacity: systemBeingReplaced.heatingCapacity ?? 0,
              heatingEquipmentType: systemBeingReplaced.heatingEquipmentType ?? null,
              heatingFuelType: systemBeingReplaced.heatingFuelType ?? 'electric',
              heatingOrientation: systemBeingReplaced.heatingOrientation ?? 'upflow',
              existingCabinetSize: systemBeingReplaced.existingCabinetSize ?? 'C'
            };
            const t1 = performance.now();
            const systems = await getValidDuctedSystems(systemInput, abortController);
            const t2 = performance.now();
            console.log({ systemInput, systems, time: `${Math.round(t2 - t1)}ms` });
            newValidSystems.push({ condenserId: condenser.uniqueId, validDuctedSystems: systems });
          }
        } else {
          const systemInput: ValidDuctlessSystemsInput = {
            name: condenser.name,
            uniqueId: condenser.uniqueId,
            coolingBTU: Math.round(condenser.zones.reduce(
              (a, b) => a + b.zoneItems.reduce((c, d) => c + d.BTUs.cooling, 0),
              0
            )),
            heatingBTU: Math.round(condenser.zones.reduce(
              (a, b) => a + b.zoneItems.reduce((c, d) => c + d.BTUs.heating, 0),
              0
            )),
            zones: condenser.zones.map((zone, zoneIndex) => ({
              name: zone.name,
              uniqueId: zone.uniqueId,
              zoneIndex,
              heatingBTU: Math.round(zone.zoneItems.reduce((a, b) => a + b.BTUs.heating, 0)),
              coolingBTU: Math.round(zone.zoneItems.reduce((a, b) => a + b.BTUs.cooling, 0)),
              restrictedAirHandlerTypes: restrictedTypesById[zone.uniqueId],
              spaces: zone.zoneItems.map((zoneItem) => ({
                name: zoneItem.name,
                uniqueId: zoneItem.uniqueId,
                heatingBTU: Math.round(zoneItem.BTUs.heating),
                coolingBTU: Math.round(zoneItem.BTUs.cooling),
                squareFootage:
                  structure
                    .find((level) =>
                      level.sections.some((section) =>
                        section.spaces.some(
                          (space) => space.uniqueId === zoneItem.structureItemId
                        )
                      )
                    )
                    ?.sections.find((section) =>
                      section.spaces.some(
                        (space) => space.uniqueId === zoneItem.structureItemId
                      )
                    )
                    ?.spaces.find(
                      (space) => space.uniqueId === zoneItem.structureItemId
                    )
                    ?.attributes.find((a) => a.code === 'square_footage')
                    ?.value || null,
                restrictedAirHandlerTypes: []
              }))
            }))
          };
          const t1 = performance.now();
          const systems = await getValidSystems(systemInput, abortController);
          const t2 = performance.now();
          console.log({ systemInput, systems, time: `${Math.round(t2 - t1)}ms` });
          const priceThreshold = getNthPercentile(systems.map((s) => s.baseRetailPrice), 80);
          const coverageThreshold = 98;
          const aestheticThreshold = 3.5;
          const installationThreshold = 2.5;
          const seerThreshold = getNthPercentile(systems.map((s) => s.seer), 80);
          systems.forEach((system, systemIndex) => {
            const zoneTypes = [
              system.airHandler1Type,
              system.airHandler2Type,
              system.airHandler3Type,
              system.airHandler4Type,
              system.airHandler5Type
            ].filter((type) => type) as AirHandlerTypeCode[];
            const averageAestheticRank = zoneTypes.reduce((total, type) => (airHandlerTypes.find(aht => aht.code === type)?.aestheticsRank ?? 0) + total, 0) / zoneTypes.length;
            const averageInstallationRank = zoneTypes.reduce((total, type) => (airHandlerTypes.find(aht => aht.code === type)?.installationComplexityRank ?? 0) + total, 0) / zoneTypes.length;
            if (!systems[systemIndex].tags) {
              systems[systemIndex].tags = [];
            }
            if (system.baseRetailPrice <= priceThreshold) {
              systems[systemIndex].tags?.push('Cheap');
            }
            if (system.heatingScore >= coverageThreshold) {
              systems[systemIndex].tags?.push('Full Heating');
            }
            if (system.coolingScore >= coverageThreshold) {
              systems[systemIndex].tags?.push('Full Cooling');
            }
            if (averageAestheticRank >= aestheticThreshold) {
              systems[systemIndex].tags?.push('Aesthetic');
            }
            if (averageInstallationRank <= installationThreshold) {
              systems[systemIndex].tags?.push('Easy Installation');
            }
            if (system.seer >= seerThreshold) {
              systems[systemIndex].tags?.push('Efficient');
            }
            if (system.condenserProductId === condenser.selectedProduct?.productId &&
              condenser.zones.every((zone) => (
                zone.selectedProduct && (
                  (zone.uniqueId === system.airHandler1Unique && system.airHandler1Type === airHandlerTypeCodes[zone.selectedProduct.type]) ||
                  (zone.uniqueId === system.airHandler2Unique && system.airHandler2Type === airHandlerTypeCodes[zone.selectedProduct.type]) ||
                  (zone.uniqueId === system.airHandler3Unique && system.airHandler3Type === airHandlerTypeCodes[zone.selectedProduct.type]) ||
                  (zone.uniqueId === system.airHandler4Unique && system.airHandler4Type === airHandlerTypeCodes[zone.selectedProduct.type]) ||
                  (zone.uniqueId === system.airHandler5Unique && system.airHandler5Type === airHandlerTypeCodes[zone.selectedProduct.type])
                )
              ))) {
              systems[systemIndex].tags?.push('Preferred Air Handlers');
            }
            systems[systemIndex].configId = config.uniqueId;
            const selectedCondenserProduct = condenserProducts.find(product => product.productId === system.condenserProductId);
            const selectedProductAmperage = Number(selectedCondenserProduct?.amperage) || null;
            // eslint-disable-next-line no-param-reassign
            system.amperage = Number(selectedProductAmperage);
            if (selectedCondenserProduct) {
              const { depth, height, width } = selectedCondenserProduct.dimensions;
              const totalSize = depth * height * width;
              // eslint-disable-next-line no-param-reassign
              system.totalSize = totalSize;
              if (totalSize <= 12498.42) {
                // eslint-disable-next-line no-param-reassign
                system.outdoorVisibility = 'Least Visible';
              } else if (totalSize <= 20167.73) {
                // eslint-disable-next-line no-param-reassign
                system.outdoorVisibility = 'Less Visible';
              } else {
                // eslint-disable-next-line no-param-reassign
                system.outdoorVisibility = 'Most Visible';
              }
            }
          });
          newValidSystems.push({ condenserId: condenser.uniqueId, validSystems: systems });
        }
        return null;
      })
    ));
    if (!abortController.signal.aborted) {
      setConfigurationOptions((draft) => {
        draft.forEach((config) => {
          config.condensers.filter((cond) => newValidSystems.some((vs) => vs.condenserId === cond.uniqueId)).forEach((cond) => {
            // eslint-disable-next-line no-param-reassign
            cond.validSystems = newValidSystems.find((vs) => vs.condenserId === cond.uniqueId)?.validSystems ?? [];
            // eslint-disable-next-line no-param-reassign
            cond.validDuctedSystems = newValidSystems.find((vs) => vs.condenserId === cond.uniqueId)?.validDuctedSystems ?? [];
            // eslint-disable-next-line no-param-reassign
            cond.validSystemsFetched = true;
          });
        });
      });
    }
  };

  const updateRankedSystems = () => {
    console.log('Ranking systems...');
    const t1 = performance.now();
    setConfigurations((draft) => {
      draft.forEach((configuration) => {
        configuration.condensers.forEach((condenser) => {
          if (rankingModifiers) {
            const rankedSystems = rankSystems(
              structure,
              items,
              condenser,
              customerValues,
              airHandlerTypes,
              rankingModifiers,
              airHandlerProducts
            );
            // eslint-disable-next-line no-param-reassign
            condenser.rankedSystems = rankedSystems;
          } else {
            console.error('Missing valid systems or ranking modifiers');
            errorModal(`Missing valid systems or ranking modifiers`);
          }
        });
      });
    });
    const t2 = performance.now();
    console.log(`Done ranking. (${Math.round(t2 - t1)}ms)`);
  };

  useEffect(() => {
    const abortController = new AbortController();
    const validSystemsTimeout = setTimeout(() => {
      if (!loading && locationData && configurationOptions.length > 0 && configurationOptions.some(config => config.valid && config.condensers.some(cond => !cond.validSystems.length))) {
        updateValidSystems(abortController);
      }
    }, 500);
    return () => {
      clearTimeout(validSystemsTimeout);
      abortController.abort();
    };
  }, [
    configurationOptions,
    locationData,
    loading
  ]);

  useEffect(() => {
    if (activeTab !== 'configuration' && !loading && locationData && configurations.length > 0 && configurations.every(config => config.condensers.every(cond => cond.validSystems.length > 0)) && configurations.some(config => config.valid && config.condensers.some(cond => !cond.rankedSystems.length))) {
      updateRankedSystems();
    }
  }, [
    configurations,
    locationData,
    activeTab,
    loading
  ]);

  useLayoutEffect(() => {
    if (!loading) {
      setSystemOptions([]);
      setTimeout(() => {
        updateRankedSystems();
      }, 10);
    }
  }, [
    rankingModifiers,
    customerValues
  ]);

  return null;
}
