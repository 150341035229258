import React, { useEffect, useContext, useState, MouseEvent } from 'react';
import { AccessoryCategory, SelectedSystem } from 'data/types';
import XButton from 'components/inputs/x-button';
import { ConfigurationContext } from 'data/context';
import {
  useFloating,
  useInteractions,
  useHover,
  offset,
  autoUpdate,
  shift,
  flip
} from '@floating-ui/react';
import { confirmationModal, warningModal } from 'utils/helpers';
import clsx from 'clsx';
import SystemMap from 'components/system-map';
import ProgressIndicator from 'components/progress-indicator';

type SystemOptionProps = {
  system: SelectedSystem;
  isAccessoryPage?: boolean;
};

export default function SystemOptionComponent({
  system,
  isAccessoryPage = false
}: SystemOptionProps) {
  const {
    selectedSystems,
    setSelectedSystems,
    activeSelectedSystem,
    setActiveSelectedSystem
  } = useContext(ConfigurationContext);
  const [showPreview, setShowPreview] = useState(false);
  const [active, setActive] = useState(false);
  const [attentionNeeded, setAttentionNeeded] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    setActive(selectedSystems.findIndex(sys => sys.id === system.id) === activeSelectedSystem);
    const selectedSystem = selectedSystems.find(sys => sys.id === system.id);
    if (selectedSystem) {
      const systemRequirementsMet: boolean[] = [];
      const categories: AccessoryCategory[] = [
        'mounting',
        'power',
        'install',
        'control'
      ];
      if (!selectedSystem.configuration.condensers.every(c => c.selectedProduct.diy)) {
        categories.push('lineSet');
      }
      if (selectedSystem.configuration.condensers.some(c => c.zones.some(z => z.selectedProduct?.type === 'Multi Room'))) {
        categories.push('ductingKit');
      }
      categories.forEach((accessoryCategory: AccessoryCategory) => {
        systemRequirementsMet.push(
          selectedSystem.noAccessoriesNeeded ||
          selectedSystem.accessoriesCompleted.includes(accessoryCategory) ||
          selectedSystem.accessoriesNotNeeded.includes(accessoryCategory)
        );
      });
      setAttentionNeeded(systemRequirementsMet.includes(false));
    }
  }, [activeSelectedSystem, selectedSystems]);

  const { refs, floatingStyles, context } = useFloating({
    open: showPreview,
    onOpenChange: setShowPreview,
    placement: 'bottom-start',
    strategy: 'fixed',
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(10),
      shift({ padding: 5 }),
      flip({ fallbackAxisSideDirection: 'end' })
    ]
  });

  const hover = useHover(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover
  ]);

  const handleClick = () => {
    setActiveSelectedSystem(selectedSystems.findIndex(sys => sys.id === system.id));
  };

  const deleteSelf = () => {
    setSelectedSystems((draft) => draft.filter((s) => s.id !== system.id));
  };

  const handleDeleteClick = (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    event.stopPropagation();
    setShowPreview(false);
    if (isAccessoryPage || system.selectedAccessories.some(acc => acc.quantity > 0)) {
      confirmationModal('Are you sure you want to delete this system?', () => {
        const systemIndex = selectedSystems.findIndex((s) => s.id === system.id);
        if (activeSelectedSystem >= systemIndex) {
          setActiveSelectedSystem(Math.max(0, activeSelectedSystem - 1));
        }
        setSelectedSystems((draft) => draft.filter((s) => s.id !== system.id));
      });
    } else {
      deleteSelf();
    }
  };

  const classes = clsx(
    'inline-flex items-center rounded-full pl-2 shadow ring-1 ring-black ring-opacity-5 whitespace-nowrap relative',
    isAccessoryPage && active && 'bg-blue-100',
    isAccessoryPage && !active && 'bg-white hover:bg-gray-50',
    !isAccessoryPage && 'bg-white'
  );

  return (
    <div ref={refs.setReference} {...getReferenceProps()}>
      <div
        className={classes}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        {(!isAccessoryPage || isHovering) && (
          <XButton
            onClick={(e) => handleDeleteClick(e)}
            tooltip="Delete Selected System"
            onDisabledClick={() => warningModal('You must have at least one selected system.')}
            disabled={isAccessoryPage && selectedSystems.length <= 1}
          />
        )}
        {isAccessoryPage && !isHovering && (
          <ProgressIndicator size='sm' checked={!attentionNeeded} required />
        )}
        <button
          className={`inline-flex items-center gap-2 py-2 pl-2 pr-3 ${isAccessoryPage ? 'cursor-pointer' : 'cursor-help'}`}
          onClick={isAccessoryPage ? handleClick : undefined}
        >
          <span className='text-sm font-semibold'>
            System {selectedSystems.findIndex(sys => sys.id === system.id) + 1}
          </span>
          {!isAccessoryPage && (
            <span className='text-sm text-green-600 font-semibold'>
              ${system.price}
            </span>
          )}
        </button>
      </div>
      {showPreview && (
        <div
          ref={refs.setFloating}
          className='z-20 max-w-lg'
          style={floatingStyles}
          {...getFloatingProps()}
        >
          <div className="flex flex-col gap-2 bg-white rounded-lg shadow-xl p-5">
            {system.configuration.condensers.map((condenser) => (
              <SystemMap
                key={condenser.uniqueId}
                condenser={condenser}
                showTags
                showSpaces
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

SystemOptionComponent.defaultProps = {
  isAccessoryPage: false
};