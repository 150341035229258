import React, {
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import OpenAI from 'openai';
import { ChatBubbleBottomCenterTextIcon, AcademicCapIcon } from '@heroicons/react/24/outline';
import { useImmer } from 'use-immer';
import { ProjectContext, ConfigurationContext, DataContext, UIContext } from 'data/context';
import { Popover } from '@headlessui/react';
import { removeNullishValues } from 'utils/helpers';
import axios from 'utils/api';
import policies from 'data/policies.json';
import airHandlerBenefits from 'data/air-handler-benefits.json';
import Text from 'components/inputs/text';
import Tooltip from 'components/tooltip';
import { AccessoryCategory } from 'data/types';

export default function ChatBot() {
  const { structureTypes } = useContext(DataContext);
  const {
    structure,
    configurations,
    selectedSystems
  } = useContext(ConfigurationContext);
  const {
    locationData,
    warmUpNotes,
    structureTypeSelected,
    structureSubtypeSelected,
    attomData,
    addressSearched,
    partialStructure
  } = useContext(ProjectContext);
  const {
    debugMode
  } = useContext(UIContext);
  const [progressContext, setProgressContext] = useState<{ category: string, messages: string[] }[]>([]);
  const [messages, setMessages] = useImmer<OpenAI.Chat.Completions.ChatCompletionMessageParam[]>([
    {
      role: 'system',
      content:
        `I am a helpful AI assistant for the company "Alpine Home Air"'s HVAC sales consultants. I keep my answers very short and very concise and don't mention calculations, 
        I just provide direct answers that reference the property data, structure, configurations, or company policies. I do not format my answers, i reply in plain text. `
    },
    {
      role: 'system',
      content: 'No property data loaded yet.'
    },
    {
      role: 'system',
      content: 'No structure created yet.'
    },
    {
      role: 'system',
      content: 'No configurations created yet.'
    },
    {
      role: 'system',
      content: `Here is our return policies, shipping policies, warranty policies, and company information: "Company Policies": ${JSON.stringify(
        policies
      )}. I can only answer policy-related questions pertaining to these specific policies, or questions regarding your property data, structure, and configurations.
      If the user asks questions I do not have answers to I refer them to our main website "https://www.alpinehomeair.com/contact-us" for further assistance.`
    },
    {
      role: 'system',
      content: `When recommending air handlers, I always follow these important recommendation guidelines!!!: 
      High Wall air handlers are not recommended for spaces with wall obstructions as the wall obstructions may obstruct the air handler. 
      Low Wall air handlers are not recommended for spaces with wall obstructions as the wall obstructions may obstruct the air handler. 
      Ceiling cassette air handlers are not recommended for spaces with ceiling fans or chandeliers.`
    },
    {
      role: 'system',
      content: `Here are some benefits of different air handlers types, "Benefits": ${JSON.stringify(
        airHandlerBenefits
      )}.`
    },
    {
      role: 'system',
      content: `Below is an object showing the current progress of the sales consultant in sequential order as they are using this tool you are assisting with. 
      This tool is called the "System Selector" and it is used to help HVAC sales consultants with their sales process.
      Take note of the current category that is incomplete (the first key in the below object that shows "(INCOMPLETE)" is the page the user needs to complete). 
      IMPORTANT: Any key in the object with a value that indicates less than 100% complete is the current category that needs to be completed. The category keys are in sequential order, with the first
      keys needing to be completed first. The object key will also say "(INCOMPLETE)" after the category name if it is incomplete.
      Following the category name key is the category progress values. If the user gets stuck, instruct them of what is missing from the first category key showing "(INCOMPLETE)". 
      If the user asks what to do next, or if the user is stuck, or if the user needs help, refer to the following "System Selector User Progress" and look for the first category key in the object that shows "(INCOMPLETE)"
     "System Selector User Progress":
      ${JSON.stringify(
        progressContext
      )}.

      Below is general information on each page of the System Selector web app that you can provide to the user:
      - The Description Page is used to collect information about the structure and location of the property. 
      - The Layout Page is used to create a layout of the structure of the property. Users can press the Easy Mode button which will guide them step by step. The easy mode button is available only on the Layout and Configuration pages.
      - The Configuration Page is used to determine the location of the condensers and air handlers. Users can press the Easy Mode button which will guide them step by step. The easy mode button is available only on the Layout and Configuration pages.
      - The Air Handlers Page is used to select the preferred air handlers for each air handler "zone" in the property. 
      - The Equipment Page displays all possible equipment options for the user to select from, this includes their preferred options as well as new recommendations based on the user's selections.
      - The Accessories Page is used to select possible accessories for the equipment selected. 
      - The Quote page is used to display the final quote to the user with a summary of all the selections made.
      - Any missing steps will be highlighted RED on that page. Instruct the user to look for the red highlighted area on that page and click on those.

      Glossary:
      - Zones: Air Handler Zones are a space within a property that is heated or cooled by a single air handler.
      - Air Handlers: Air Handlers are the indoor units that supply cooling or heating to the air handler zones.
      - Condensers: Condensers are the outdoor units that supply cooling or heating to the air handler. 
      - Area: An area is a group of nearby spaces that can share open air or can be serviced by a single air handler, such as a multi room air handler. 
      - Systems: Systems are the combination of condensers and air handlers that are used to heat or cool a property.

      NOTE: If the user is stuck or needs help, always disregard user messages and refer to the object above on what category is INCOMPLETE and WHY. User messages may mislead you on the furthest progress of the user, so ALWAYS refer to the "System Selector User Progress" above.
      `
    },
    {
      role: 'system',
      content: `
      NOTE: Description page is the current incomplete category
      `
    }
  ]);
  const [prompt, setPrompt] = useState('');
  const [loading, setLoading] = useState(false);

  const lastMessageRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    lastMessageRef.current?.scrollIntoView();
  };

  useEffect(() => {
    if (attomData) {
      setMessages((draft) => {
        // eslint-disable-next-line no-param-reassign
        draft[1].content = `Here are the specs of the current structure: ${JSON.stringify(attomData)}`;
      });
    }
  }, [
    JSON.stringify(attomData)
  ]);

  useEffect(() => {
    if (structure.length > 0) {
      setMessages((draft) => {
        // eslint-disable-next-line no-param-reassign
        draft[2].content = `Here is the user created structure: 
        ${JSON.stringify(removeNullishValues(structure.map(level => ({ ...level, attributes: undefined, sections: level.sections.map(section => ({ ...section, spaces: section.spaces.map(space => ({ ...space, attributes: Object.fromEntries(space.attributes.map(att => [att.code, att.value])) })) })) }))))}`;
      });
    }
  }, [
    JSON.stringify(structure)
  ]);

  useEffect(() => {
    if (configurations.length > 0) {
      setMessages((draft) => {
        // eslint-disable-next-line no-param-reassign
        draft[3].content = `Here is the user created configurations: 
        ${JSON.stringify(removeNullishValues(configurations.map((config) => config.condensers.map(c => ({ name: c.name, zones: c.zones.map(z => ({ name: z.name, zoneItems: z.zoneItems.map(zi => ({ name: zi.name, BTUs: zi.BTUs })) })) })))))}`;
      });
    }
  }, [
    JSON.stringify(removeNullishValues(configurations.map((config) => config.condensers.map(c => ({ name: c.name, zones: c.zones.map(z => ({ name: z.name, zoneItems: z.zoneItems.map(zi => ({ name: zi.name, BTUs: zi.BTUs })) })) })))))
  ]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const progressArray: { category: string, messages: string[] }[] = [];
    // description page
    const descriptionRequirementsMet = [
      !!locationData,
      structureTypeSelected !== null,
      structureSubtypeSelected !== null
    ];
    if (!debugMode) {
      descriptionRequirementsMet.push(!!warmUpNotes);
    }
    if (
      structureTypes
        .find((t) => t.id === structureTypeSelected)
        ?.subtypes.find((st) => st.id === structureSubtypeSelected)
        ?.addressRequired && !addressSearched
    ) {
      descriptionRequirementsMet.push(!!attomData);
    }
    if (
      structureTypes
        .find((t) => t.id === structureTypeSelected)
        ?.subtypes.find((s) => s.id === structureSubtypeSelected)
        ?.partialEnabled
    ) {
      descriptionRequirementsMet.push(partialStructure !== null);
    }

    const descriptionProgress = descriptionRequirementsMet.length > 0 ?
      ((descriptionRequirementsMet.filter((r) => r).length /
        descriptionRequirementsMet.length) *
        100) : 0;

    const descriptionMissingItems: string[] = [];
    if (!locationData) descriptionMissingItems.push("Location data is missing. On the Description page under Location, please enter the customer's location");
    if (structureTypeSelected === null) descriptionMissingItems.push("Structure type selection is missing. On the Description page under Structure Type, please enter the structure type. You may also enter an address under the Address field and let us automatically detect the structure type.");
    if (structureSubtypeSelected === null) descriptionMissingItems.push("Structure subtype selection is missing. On the Description page under Structure Subtype, please enter the structure subtype. You may also enter an address under the Address field and let us automatically detect the structure subtype.");
    if (!debugMode && !warmUpNotes) descriptionMissingItems.push("Warm-up notes are missing. On the Description page under Notes, please enter relevant notes about the customer.");
    if (structureTypes
      .find((t) => t.id === structureTypeSelected)
      ?.subtypes.find((st) => st.id === structureSubtypeSelected)
      ?.addressRequired && !addressSearched && !attomData) descriptionMissingItems.push("Attom data is missing. On the Description page under Address, please enter the customer's address.");
    if (structureTypes
      .find((t) => t.id === structureTypeSelected)
      ?.subtypes.find((s) => s.id === structureSubtypeSelected)
      ?.partialEnabled && partialStructure === null) descriptionMissingItems.push("Partial structure data is missing. On the Description page under Full or Partial Home, please answer Full or Partial Home.");
    progressArray.push({
      category: descriptionProgress < 100 ? "Description Page (INCOMPLETE)" : "Description Page (COMPLETE)",
      messages: descriptionProgress < 100 ? [...descriptionMissingItems, `Description page is ${descriptionProgress}% complete`, "Description Page is INCOMPLETE."] : [...descriptionMissingItems, `Description page is ${descriptionProgress}% complete`]
    });

    // layout (structure) page
    const structureRequirementsMet = [
      !!structure.length,
      !!structure.some((l) => l.sections.length),
      !!structure.some((l) => l.sections.some((s) => s.spaces.length)),
      (structure.length > 0 && structure.every((l) => l.sections.every((s) => s.spaces.every((sp) => sp.valid))))
    ];

    const structureProgress = structureRequirementsMet.length > 0 ?
      ((structureRequirementsMet.filter((r) => r).length /
        structureRequirementsMet.length) *
        100) : 0;

    const structureMissingItems: string[] = [];
    if (!structure.length) structureMissingItems.push("Structure data is missing. Please create a structure on the Layout page or press the Easy Mode button which will guide you step by step.");
    if (!structure.some((l) => l.sections.length)) structureMissingItems.push("Sections data is missing. Please create sections on the Layout page or press the Easy Mode button which will guide you step by step.");
    if (!structure.some((l) => l.sections.some((s) => s.spaces.length))) structureMissingItems.push("Spaces data is missing. Please create spaces on the Layout page or press the Easy Mode button which will guide you step by step.");
    if (structure.length > 0 && !structure.every((l) => l.sections.every((s) => s.spaces.every((sp) => sp.valid)))) structureMissingItems.push("Some spaces are invalid. Please click on the red spaces and complete the missing data.");

    progressArray.push({
      category: structureProgress < 100 ? "Layout Page (INCOMPLETE)" : "Layout Page (COMPLETE)",
      messages: structureProgress < 100 ? [...structureMissingItems, `Layout page is ${structureProgress}% complete`, "Layout Page is INCOMPLETE."] : [...structureMissingItems, `Layout page is ${structureProgress}% complete`]
    });

    // configuration page
    const configurationRequirementsMet = configurations.flatMap((conf) => conf.condensers.map((cond) => cond.validSystems.length > 0));

    const configurationProgress = configurationRequirementsMet.length > 0 ?
      ((configurationRequirementsMet.filter((r) => r).length /
        configurationRequirementsMet.length) *
        100) : 0;

    const configurationMissingItems: string[] = [];
    if (!configurationRequirementsMet.every(Boolean)) configurationMissingItems.push("Valid Configurations are missing. Please select or create a valid configuration and ensure any red error messages are resolved. You may also press the Easy Mode button which will guide you step by step on how to create valid configurations.");

    progressArray.push({
      category: configurationProgress < 100 ? "Configuration Page (INCOMPLETE)" : "Configuration Page (COMPLETE)",
      messages: configurationProgress < 100 ? [...configurationMissingItems, `Configuration page is ${configurationProgress}% complete`, "Configuration Page is INCOMPLETE."] : [...configurationMissingItems, `Configuration page is ${configurationProgress}% complete`]
    });

    // air handler page
    const airHandlersRequirementsMet = configurations.flatMap((config) =>
      config.condensers.flatMap((c) =>
        c.zones.map((z) => !!z.selectedProduct)
      )
    );

    const airHandlersProgress = airHandlersRequirementsMet.length > 0 ?
      ((airHandlersRequirementsMet.filter((r) => r).length /
        airHandlersRequirementsMet.length) *
        100) : 0;

    const airHandlersMissingItems: string[] = [];
    if (!airHandlersRequirementsMet.every(Boolean)) airHandlersMissingItems.push("Selected products for air handler zones are missing. Please select a product for each air handler zone by click on the zone then selecting an air handler product type. Missing zones are highlighted in red.");

    progressArray.push({
      category: airHandlersProgress < 100 ? "Air Handlers Page (INCOMPLETE)" : "Air Handlers Page (COMPLETE)",
      messages: airHandlersProgress < 100 ? [...airHandlersMissingItems, `Air handlers page is ${airHandlersProgress}% complete`, "Air Handlers Page is INCOMPLETE."] : [...airHandlersMissingItems, `Air handlers page is ${airHandlersProgress}% complete`]
    });

    // equipment page
    const equipmentRequirementsMet = [selectedSystems.length > 0];

    const equipmentProgress = equipmentRequirementsMet.length > 0 ?
      ((equipmentRequirementsMet.filter((r) => r).length /
        equipmentRequirementsMet.length) *
        100) : 0;

    const equipmentMissingItems: string[] = [];
    if (!equipmentRequirementsMet.every(Boolean)) equipmentMissingItems.push("Selected systems are missing. Please select systems on the Equipment page to add to your quote.");

    progressArray.push({
      category: equipmentProgress < 100 ? "Equipment Page (INCOMPLETE)" : "Equipment Page (COMPLETE)",
      messages: equipmentProgress < 100 ? [...equipmentMissingItems, `Equipment page is ${equipmentProgress}% complete`, "Equipment Page is INCOMPLETE."] : [...equipmentMissingItems, `Equipment page is ${equipmentProgress}% complete`]
    });

    // accessories page
    const accessoriesRequirementsMet: boolean[] = [];
    const accessoriesMissingItems: string[] = [];
    selectedSystems.forEach((system) => {
      const categories: AccessoryCategory[] = [
        'mounting',
        'power',
        'install',
        'control'
      ];
      if (!system.configuration.condensers.every(c => c.selectedProduct.diy)) {
        categories.push('lineSet');
      }
      if (system.configuration.condensers.some(c => c.zones.some(z => z.selectedProduct?.type === 'Multi Room'))) {
        categories.push('ductingKit');
      }
      categories.forEach((accessoryCategory: AccessoryCategory) => {
        accessoriesRequirementsMet.push(
          system.noAccessoriesNeeded ||
          system.accessoriesCompleted.includes(accessoryCategory) ||
          system.accessoriesNotNeeded.includes(accessoryCategory)
        );
        if (!(
          system.noAccessoriesNeeded ||
          system.accessoriesCompleted.includes(accessoryCategory) ||
          system.accessoriesNotNeeded.includes(accessoryCategory)
        )) {
          accessoriesMissingItems.push(`
            Accessory category "${accessoryCategory}" is incomplete. Please select accessories for each category or toggle off the 'Include Accessories' switch on the top right if no accessories are needed. 
            There ${selectedSystems.length > 1 ? 'are' : 'is'} ${selectedSystems.length} system${selectedSystems.length > 1 ? 's' : ''} selected. 
            You need to select accessories for ${selectedSystems.length > 1 ? 'each system' : 'the system'}.
            `);
        }
      });
    });

    const accessoriesProgress = accessoriesRequirementsMet.length > 0 ?
      ((accessoriesRequirementsMet.filter((r) => r).length /
        accessoriesRequirementsMet.length) *
        100) : 0;

    progressArray.push({
      category: accessoriesProgress < 100 ? "Accessories Page (INCOMPLETE)" : "Accessories Page (COMPLETE)",
      messages: accessoriesProgress < 100 ? [...accessoriesMissingItems, `Accessories page is ${accessoriesProgress}% complete`, "Accessories Page is INCOMPLETE."] : [...accessoriesMissingItems, `Accessories page is ${accessoriesProgress}% complete`]
    });

    // quote page
    const quoteRequirementsMet = [selectedSystems.some((system) => system.quoteStatus === 'sent')];

    const quoteProgress = quoteRequirementsMet.length > 0 ?
      ((quoteRequirementsMet.filter((r) => r).length /
        quoteRequirementsMet.length) *
        100) : 0;

    const quoteMissingItems: string[] = [];
    if (!quoteRequirementsMet.every(Boolean)) quoteMissingItems.push("Select a quote and press Send quote to customer on the bottom right of the page.");

    progressArray.push({
      category: quoteProgress < 100 ? "Quote Page (INCOMPLETE)" : "Quote Page (COMPLETE)",
      messages: quoteProgress < 100 ? [...quoteMissingItems, `Quote page is ${quoteProgress}% complete`, "Quote Page is INCOMPLETE."] : [...quoteMissingItems, `Quote page is ${quoteProgress}% complete`]
    });

    setProgressContext(progressArray);
  }, [
    structureTypes,
    locationData,
    warmUpNotes,
    structureTypeSelected,
    structureSubtypeSelected,
    attomData,
    addressSearched,
    structure,
    configurations,
    selectedSystems,
    partialStructure,
    messages
  ]);

  useEffect(() => {
    setMessages((draft) => {
      // eslint-disable-next-line no-param-reassign
      draft[7].content = `
      Below is an object showing the current progress of the sales consultant in sequential order as they are using this tool you are assisting with. 
      This tool is called the "System Selector" and it is used to help HVAC sales consultants with their sales process.
      Take note of the current category that is incomplete (the first key in the below object that shows "(INCOMPLETE)" is the page the user needs to complete). 
      IMPORTANT: Any key in the object with a value that indicates less than 100% complete is the current category that needs to be completed. The category keys are in sequential order, with the first
      keys needing to be completed first. The object key will also say "(INCOMPLETE)" after the category name if it is incomplete.
      Following the category name key is the category progress values. If the user gets stuck, instruct them of what is missing from the first category key showing "(INCOMPLETE)". 
      If the user asks what to do next, or if the user is stuck, or if the user needs help, refer to the following "System Selector User Progress" and look for the first category key in the object that shows "(INCOMPLETE)"
     "System Selector User Progress":
      ${JSON.stringify(
        progressContext
      )}.

      Below is general information on each page of the System Selector web app that you can provide to the user:
      - The Description Page is used to collect information about the structure and location of the property. 
      - The Layout Page is used to create a layout of the structure of the property. Users can press the Easy Mode button which will guide them step by step. The easy mode button is available only on the Layout and Configuration pages.
      - The Configuration Page is used to determine the location of the condensers and air handlers. Users can press the Easy Mode button which will guide them step by step. The easy mode button is available only on the Layout and Configuration pages.
      - The Air Handlers Page is used to select the preferred air handlers for each air handler "zone" in the property. 
      - The Equipment Page displays all possible equipment options for the user to select from, this includes their preferred options as well as new recommendations based on the user's selections.
      - The Accessories Page is used to select possible accessories for the equipment selected. 
      - The Quote page is used to display the final quote to the user with a summary of all the selections made.
      - Any missing steps will be highlighted RED on that page. Instruct the user to look for the red highlighted area on that page and click on those. 

      Glossary:
      - Zones: Air Handler Zones are a space within a property that is heated or cooled by a single air handler.
      - Air Handlers: Air Handlers are the indoor units that supply cooling or heating to the air handler zones.
      - Condensers: Condensers are the outdoor units that supply cooling or heating to the air handler. 
      - Area: An area is a group of nearby spaces that can share open air or can be serviced by a single air handler, such as a multi room air handler. 
      - Systems: Systems are the combination of condensers and air handlers that are used to heat or cool a property.

      NOTE: If the user is stuck or needs help, always disregard user messages and refer to the object above on what category is INCOMPLETE and WHY. User messages may mislead you on the furthest progress of the user, so ALWAYS refer to the "System Selector User Progress" above.
      `;
    });
  }, [progressContext]);

  useEffect(() => {
    const firstIncompleteCategory = progressContext.find((category) =>
      category.category.includes('(INCOMPLETE)')
    );
    const incompleteCategoryMessage = firstIncompleteCategory
      ? `NOTE: ${JSON.stringify(firstIncompleteCategory)} is the current incomplete category. The user needs to complete this category. Refer to this category if the user gets stuck or needs help.`
      : 'NOTE: All categories are complete.';

    setMessages((draft) => {
      // eslint-disable-next-line no-param-reassign
      draft[8].content = incompleteCategoryMessage;
    });
  }, [progressContext]);

  const handleKeyPress = async (key: string) => {
    if (key === 'Enter' && !loading && prompt.trim() !== '') {
      const newMessage: OpenAI.Chat.Completions.ChatCompletionMessageParam = {
        role: 'user',
        content: prompt
      };
      setPrompt('');
      setLoading(true);
      const newMessages = [...messages, newMessage];
      setMessages(newMessages);
      try {
        const reply = (await axios.post(
          'ai',
          newMessages
        )).data;
        if (reply) {
          setMessages([...newMessages, reply]);
        }
        setLoading(false);
      } catch (err) {
        console.error(err);
        setMessages([...newMessages, { role: 'system', content: 'Sorry, something went wrong' }]);
        setLoading(false);
      }
    }
  };

  const handleButtonClick = () => {
    setTimeout(() => {
      scrollToBottom();
    }, 50);
  };

  return (
    <Popover className='relative'>
      <Popover.Button
        className='relative flex h-16 w-16 cursor-pointer items-center justify-center rounded-full bg-white shadow-lg hover:bg-gray-50'
        onClick={handleButtonClick}
      >
        <ChatBubbleBottomCenterTextIcon className='h-8 w-8 text-gray-700' />
        <Tooltip text='Assistant' options={{ placement: 'left' }} />
      </Popover.Button>
      <Popover.Panel className='absolute bottom-0 right-0 flex h-[400px] w-[400px] flex-col gap-3 rounded-lg bg-white p-3 shadow-lg'>
        {messages.filter((msg) => msg !== undefined)
          .filter((msg) => msg.role !== 'system').length === 0 && (
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
              <AcademicCapIcon className='h-20 w-20 text-gray-300' />
            </div>
          )
        }
        <div className='flex w-full grow flex-col gap-3 overflow-auto'>
          {messages
            .filter((msg) => msg !== undefined)
            .filter((msg) => msg.role !== 'system')
            .filter((msg) => typeof msg.content === 'string')
            .map((msg, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index} className={`flex ${msg.role === 'user' ? 'justify-end ml-16' : 'justify-start mr-16'}`}>
                <span className={`inline-block ${msg.role === 'user' ? 'bg-blue-500 text-white rounded-bl-xl' : 'bg-gray-100 rounded-br-xl'} rounded-t-xl px-3 py-2`}>
                  {msg.content as string}
                </span>
              </div>
            ))}
          <div ref={lastMessageRef} />
        </div>
        {loading && (
          <div className='text-gray-500'>Assistant is thinking...</div>
        )}
        <Text
          placeholder='Ask me anything'
          onInput={(value) => setPrompt(value)}
          value={prompt}
          onKeyDown={(e) => handleKeyPress(e.key)}
          autoFocus
        />
      </Popover.Panel>
    </Popover>
  );
}
