import React, { useContext, useMemo } from 'react';
import { Column } from '@tanstack/react-table';
import { AirHandlerProduct, CondenserProduct } from 'data/types';
import { abbreviateNumber } from 'utils/helpers';
import Text from 'components/inputs/text';
import Select from 'components/inputs/select';
import Multiselect from 'components/inputs/multiselect';
import { ConfigurationContext } from 'data/context';

export default function TableFilter({ column }: { column: Column<any, unknown> }) {
  const { configurations } = useContext(ConfigurationContext);
  const { filterVariant } = column.columnDef.meta ?? {};
  const columnFilterValue = column.getFilterValue();
  const uniqueProducts = useMemo(() =>
    Array.from(column.getFacetedUniqueValues().keys()).filter((item) => item),
    [column.getFacetedUniqueValues()]
  );

  if (filterVariant === 'condenser') return (
    <div className='flex gap-2'>
      <div className='grow basis-0'>
        <Text
          value={(columnFilterValue as [string, string])?.[0] ?? ''}
          onChange={(value) => column.setFilterValue((old: [string, string]) => [value, old?.[1]])}
          placeholder='Search...'
          debounce={500}
          size='sm'
        />
      </div>
      <div className='grow basis-0'>
        <Select
          value={(columnFilterValue as [string, string])?.[1] ?? ''}
          onChange={(value) => column.setFilterValue((old: [string, string]) => [old?.[0], value])}
          options={[...new Map((uniqueProducts as CondenserProduct[]).map(item =>
            [item.coolingBTU, item])).values()].sort((a, b) => a.coolingBTU - b.coolingBTU).map(product => ({
              label: abbreviateNumber(product.coolingBTU),
              value: product.coolingBTU.toString()
            }))}
          placeholder='Size...'
          size='sm'
        />
      </div>
    </div>
  );

  if (filterVariant === 'air handler') return (
    <div className='flex gap-2'>
      <div className='grow basis-0'>
        <Text
          value={(columnFilterValue as [string, string])?.[0] ?? ''}
          onChange={(value) => column.setFilterValue((old: [string, string]) => [value, old?.[1]])}
          placeholder='Search...'
          debounce={500}
          size='sm'
        />
      </div>
      <div className='grow basis-0'>
        <Select
          value={(columnFilterValue as [string, string])?.[1] ?? ''}
          onChange={(value) => column.setFilterValue((old: [string, string]) => [old?.[0], value])}
          options={[...new Map((uniqueProducts as AirHandlerProduct[]).map((item) =>
            [item.sizeCode, item])).values()].sort((a, b) => Number(a.sizeCode.replace('k', '')) - Number(b.sizeCode.replace('k', ''))).map(product => ({
              label: product.sizeCode?.toUpperCase(),
              value: product.sizeCode
            }))}
          placeholder='Size...'
          size='sm'
        />
      </div>
    </div>
  );

  if (filterVariant === 'tags') return (
    <Multiselect
      onChange={(value) => column.setFilterValue(value)}
      options={[
        'Cheap',
        'Full Heating',
        'Full Cooling',
        'Aesthetic',
        'Easy Installation',
        'Efficient',
        'Preferred Air Handlers'
      ]}
      placeholder='Filter...'
    />
  );

  if (filterVariant === 'config') return (
    <Multiselect
      onChange={(value) => column.setFilterValue(value)}
      options={configurations.map((config) => ({
        label: config.name,
        value: config.uniqueId
      }))}
      placeholder='Filter...'
    />
  );

  return (
    <Text
      value={columnFilterValue as string ?? ''}
      onChange={(value) => column.setFilterValue(value)}
      placeholder='Search...'
      debounce={500}
      size='sm'
    />
  );
}