import React, { Dispatch, SetStateAction, useContext } from 'react';
import Map from 'components/google-map';
import Modal from 'components/modals/modal';
import { ProjectContext } from 'data/context';

type StreetViewModalProps = {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
};

export default function StreetViewModal({ openState }: StreetViewModalProps) {
  const { locationData } = useContext(ProjectContext);

  return (
    <Modal title={locationData?.location ?? 'Street View'} openState={openState} infoMode size='wide'>
      <Map />
    </Modal>
  );
}
