import React, { useRef, useEffect, useState } from 'react';
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';

export type QuantityProps = {
  onChange?: (value: number | undefined) => void;
  debounce?: number;
  value?: any;
  disabled?: boolean;
  max?: number;
  autoFocus?: boolean;
  autoSelect?: boolean;
};

export default function QuantityInput({
  onChange,
  debounce,
  value,
  disabled = false,
  max,
  autoFocus = false,
  autoSelect = false
}: QuantityProps) {
  const [uncontrolledValue, setUncontrolledValue] = useState<number | undefined>(value);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (autoFocus) {
      ref.current?.focus();
    }
  }, [autoFocus]);

  useEffect(() => {
    if (autoSelect) {
      ref.current?.select();
    }
  }, [autoSelect]);

  useEffect(() => {
    setUncontrolledValue(value);
  }, [value]);

  const classes = clsx(
    'block w-10 rounded-md border-gray-300 px-0 text-center shadow-sm [appearance:textfield] placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 sm:text-sm [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none',
    disabled && 'opacity-[70%] cursor-not-allowed'
  );

  const adjustValue = (input: string) => {
    if (input !== '') {
      let newValue = Number(input);
      if (!Number.isNaN(newValue)) {
        newValue = Math.max(0, newValue);
        if (max) {
          newValue = Math.min(max, newValue);
        }
      }
      setUncontrolledValue(newValue);
      if (onChange) {
        onChange(newValue);
      }
    } else {
      setUncontrolledValue(undefined);
      if (onChange) {
        onChange(undefined);
      }
    }
  };

  useEffect(() => {
    let timeout;
    if (onChange) {
      if (debounce) {
        timeout = setTimeout(() => {
          onChange(uncontrolledValue);
        }, debounce);
      } else {
        onChange(uncontrolledValue);
      }
    }
    return () => clearTimeout(timeout);
  }, [uncontrolledValue]);

  return (
    <div className='flex items-center'>
      <button
        className='w-10 h-10 text-gray-500 hover:text-black flex items-center justify-center'
        onClick={() => setUncontrolledValue(Math.max((uncontrolledValue ?? 0) - 1, 0))}
      >
        <MinusIcon className='h-5 w-5' />
      </button>
      <input
        ref={ref}
        type='number'
        className={classes}
        placeholder='0'
        onChange={(e) => setUncontrolledValue(e.target.value ? Number(e.target.value) : undefined)}
        onBlur={(e) => adjustValue(e.target.value)}
        value={uncontrolledValue ?? ''}
        min={0}
        max={max}
        disabled={disabled}
      />
      <button
        className='w-10 h-10 text-gray-500 hover:text-black flex items-center justify-center'
        onClick={() => setUncontrolledValue((uncontrolledValue ?? 0) + 1)}
      >
        <PlusIcon className='h-5 w-5' />
      </button>
    </div>
  );
}

QuantityInput.defaultProps = {
  onChange: undefined,
  debounce: undefined,
  value: undefined,
  disabled: false,
  max: undefined,
  autoFocus: false,
  autoSelect: false
};
