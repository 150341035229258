import { useContext, useEffect } from 'react';
import { ConfigurationContext, ProjectContext, UIContext } from 'data/context';
import { notification } from 'utils/helpers';
import { findSpaceById } from 'utils/structure';
import { defaultUIState } from 'data/context/ui-context';
import { accessoryCategories } from 'data/constants';

type ResetDataWatcherProps = {
  loading: boolean;
};

export default function ResetDataWatcher({ loading }: ResetDataWatcherProps) {
  const {
    structureTypeSelected,
    structureSubtypeSelected,
    locationData,
    setSystemOptions
  } = useContext(ProjectContext);
  const {
    configurations,
    configurationOptions,
    structure,
    setStructure,
    setConfigurations,
    setConfigurationOptions,
    setActiveConfig,
    selectedSystems,
    setSelectedSystems,
    activeSelectedSystem,
    setActiveSelectedSystem
  } = useContext(ConfigurationContext);
  const {
    activeTab,
    setActiveAirHandlerPicker,
    setActiveCondenserPicker,
    setSystemFilters
  } = useContext(UIContext);

  // reset structure, configurations, and selected systems when structure type or subtype changes
  useEffect(() => {
    if (!loading && (structure.length > 0 || configurations.length > 0 || configurationOptions.length > 0 || selectedSystems.length > 0)) {
      console.log('Resetting structure, configs, and selected systems (structure type changed)...');
      setStructure([]);
      setConfigurations([]);
      setConfigurationOptions([]);
      setActiveConfig(0);
      setSelectedSystems([]);
      setActiveSelectedSystem(0);
      notification('Structure, configurations, and selected systems reset', 'success');
    }
  }, [
    structureTypeSelected,
    structureSubtypeSelected,
    locationData
  ]);

  // reset configurations and selected systems when a space is added or deleted
  useEffect(() => {
    if (!loading && (configurations.length > 0 || configurationOptions.length > 0 || selectedSystems.length > 0)) {
      console.log('Resetting configs and selected systems (structure changed)...');
      setConfigurations([]);
      setConfigurationOptions([]);
      setActiveConfig(0);
      setSelectedSystems([]);
      setActiveSelectedSystem(0);
      notification('Configurations and selected systems reset', 'success');
    }
  }, [
    JSON.stringify(structure.map(l => l.sections.map(s => s.spaces.map(sp => sp.uniqueId)))),
  ]);

  // reset valid systems, selected products, and selected systems when space attributes/BTUs change
  useEffect(() => {
    if (!loading && (configurations.some(config => config.condensers.some(cond => cond.selectedProduct || cond.validSystems.length > 0 || cond.zones.some(zone => zone.selectedProduct))) || selectedSystems.length > 0)) {
      console.log('Resetting valid systems, selected products, and selected systems (space attributes/BTUs changed)...');
      if (configurations.some(config => config.condensers.some(cond => cond.selectedProduct || cond.validSystems.length > 0 || cond.zones.some(zone => zone.selectedProduct)))) {
        setConfigurations((draft) => {
          draft.forEach((config) => {
            // eslint-disable-next-line no-param-reassign
            config.valid = false;
            config.condensers.forEach((cond) => {
              // eslint-disable-next-line no-param-reassign
              cond.validSystems = [];
              // eslint-disable-next-line no-param-reassign
              cond.rankedSystems = [];
              // eslint-disable-next-line no-param-reassign
              cond.validSystemsFetched = false;
              // eslint-disable-next-line no-param-reassign
              cond.selectedProduct = undefined;
              cond.zones.forEach((zone) => {
                // eslint-disable-next-line no-param-reassign
                zone.selectedProduct = undefined;
              });
            });
          });
        });
      }
      if (selectedSystems.length > 0) {
        setSelectedSystems([]);
        setActiveSelectedSystem(0);
        notification('Selected systems reset', 'success');
      }
    }
  }, [
    JSON.stringify(structure.map(l => l.sections.map(s => s.spaces.map(sp => ({ id: sp.uniqueId, attributes: sp.attributes, BTUs: sp.BTUs })))))
  ]);

  // reset tax, shipping, and quote status when selected systems change
  useEffect(() => {
    if (!loading) {
      console.log('Resetting selected system tax, shipping, and rebate info (selected systems changed)...');
      setSelectedSystems((draft) => {
        draft.forEach((system, systemIndex) => {
          if (systemIndex === activeSelectedSystem) {
            // eslint-disable-next-line no-param-reassign
            system.tax = undefined;
            // eslint-disable-next-line no-param-reassign
            system.shipping = undefined;
            // eslint-disable-next-line no-param-reassign
            system.rebates = undefined;
            // eslint-disable-next-line no-param-reassign
            system.quoteStatus = 'unsent';
          }
          // eslint-disable-next-line no-param-reassign
          system.quotableAccessories = system.selectedAccessories.filter(
            (acc) => !system.accessoriesNotNeeded.some((cat) => accessoryCategories[cat]?.includes(acc.type)) && !system.noAccessoriesNeeded
          );
        });
      });
    }
  }, [
    JSON.stringify(selectedSystems.map(system => ({
      ...system,
      tax: undefined,
      shipping: undefined,
      rebates: undefined,
      quoteStatus: undefined,
      quotableAccessories: undefined
    })))
  ]);

  // reset air handler and condenser pickers when tab changes
  useEffect(() => {
    if (activeTab !== 'air handlers') {
      setActiveAirHandlerPicker(null);
    }
    if (activeTab !== 'condensers') {
      setActiveCondenserPicker(null);
    }
  }, [activeTab]);

  // update zone item names and BTUs when spaces change
  useEffect(() => {
    if (configurations.length > 0) {
      console.log('Updating zone items (spaces changed)...');
      setConfigurations((draft) => {
        draft.forEach((config) => {
          config.condensers.forEach((cond) => {
            cond.zones.forEach((zone) => {
              zone.zoneItems.forEach((zoneItem) => {
                const structureItem = findSpaceById(structure, zoneItem.structureItemId);
                if (structureItem) {
                  // eslint-disable-next-line no-param-reassign
                  zoneItem.name = structureItem.label;
                  // eslint-disable-next-line no-param-reassign
                  zoneItem.originalBTUs = structureItem.BTUs;
                  if (zoneItem.isPartition) {
                    const partitionCount = zone.zoneItems.filter((item) => item.structureItemId === structureItem.uniqueId).length;
                    // eslint-disable-next-line no-param-reassign
                    zoneItem.BTUs = {
                      ...structureItem.BTUs,
                      heating: structureItem.BTUs.heating / partitionCount,
                      cooling: structureItem.BTUs.cooling / partitionCount
                    };
                  } else {
                    // eslint-disable-next-line no-param-reassign
                    zoneItem.BTUs = structureItem.BTUs;
                  }
                }
              });
            });
          });
        });
      });
    }
  }, [
    JSON.stringify(structure.map(l => l.sections.map(s => s.spaces.map(sp => ({ label: sp.label, BTUs: sp.BTUs })))))
  ]);

  // reset system options and filters when configurations change
  useEffect(() => {
    console.log(`Resetting system options and filters (configurations changed)...`);
    setSystemOptions([]);
    setSystemFilters(defaultUIState.systemFilters);
  }, [JSON.stringify(configurations.map(config => ({ uniqueId: config.uniqueId, condensers: config.condensers.map(cond => ({ uniqueId: cond.uniqueId, zones: cond.zones.map(zone => ({ uniqueId: zone.uniqueId })) })) })))]);

  // update configuration, condenser, and zone names on system options when names are updated
  useEffect(() => {
    setSystemOptions((draft) => {
      draft.forEach((option) => {
        const config = configurations.find((c) => c.uniqueId === option.configuration.uniqueId);
        if (config) {
          // eslint-disable-next-line no-param-reassign
          option.configuration.name = config.name;
          option.configuration.condensers.forEach((cond) => {
            const condenser = config.condensers.find((c) => c.uniqueId === cond.uniqueId);
            if (condenser) {
              // eslint-disable-next-line no-param-reassign
              cond.name = condenser.name;
              cond.zones.forEach((z) => {
                const zone = condenser.zones.find((zz) => zz.uniqueId === z.uniqueId);
                if (zone) {
                  // eslint-disable-next-line no-param-reassign
                  z.name = zone.name;
                }
              });
            }
          });
        }
      });
    });
  }, [JSON.stringify(configurations.map(config => ({ name: config.name, condensers: config.condensers.map(cond => ({ name: cond.name, zones: cond.zones.map(zone => ({ name: zone.name })) })) })))]);

  return null;
}