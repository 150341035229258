import React, { useContext, useEffect, useState } from 'react';
import { AdminContext } from 'data/context';
import { successModal, errorModal } from 'utils/helpers';
import axios from 'utils/api';
import Button from 'components/inputs/button';
import { Attribute } from 'data/types';
import Text from 'components/inputs/text';
import NumberInput from 'components/inputs/number';
import ToggleSwitch from 'components/inputs/toggle-switch';

export default function Attributes() {
  const { refreshData, attributes } = useContext(AdminContext);
  const [newAttributes, setNewAttributes] = useState<Attribute[]>([]);

  useEffect(() => {
    setNewAttributes(attributes);
  }, [attributes]);

  const handleValueChange = (attributeId: number, key: string, value: any) => {
    setNewAttributes((oldValues) => {
      const newValues = oldValues.map((att) => {
        if (att.id === attributeId) {
          return {
            ...att,
            [key]: value,
          };
        }
        return att;
      });
      return newValues;
    });
  };

  const handleSave = async () => {
    try {
      await axios.patch('attributes', { attributes: newAttributes });
      await refreshData();
      successModal(`Attributes updated.`);
    } catch (error) {
      errorModal('Something went wrong.');
    }
  };

  return (
    <div className='mx-auto flex max-w-[1600px] flex-col gap-5'>
      <div className='text-3xl font-bold text-gray-900'>
        Attributes
      </div>
      <div className='max-h-[50vh] overflow-auto'>
        <table className='min-w-full border-separate border-spacing-0 divide-y divide-gray-300'>
          <thead>
            <tr>
              <th
                scope='col'
                className='sticky top-0 z-10 border-b border-gray-300 bg-gray-100 pb-3.5 pl-3 pr-0 text-left font-semibold text-gray-900'
              >
                Label
              </th>
              <th
                scope='col'
                className='sticky top-0 z-10 border-b border-gray-300 bg-gray-100 px-3 pb-3.5 text-left font-semibold text-gray-900'
              >
                Type
              </th>
              <th
                scope='col'
                className='sticky top-0 z-10 border-b border-gray-300 bg-gray-100 px-3 pb-3.5 text-left font-semibold text-gray-900'
              >
                Placeholder
              </th>
              <th
                scope='col'
                className='sticky top-0 z-10 border-b border-gray-300 bg-gray-100 px-3 pb-3.5 text-left font-semibold text-gray-900'
              >
                Priority
              </th>
              <th
                scope='col'
                className='sticky top-0 z-10 border-b border-gray-300 bg-gray-100 px-3 pb-3.5 text-left font-semibold text-gray-900'
              >
                Inheritable
              </th>
              <th
                scope='col'
                className='sticky top-0 z-10 border-b border-gray-300 bg-gray-100 px-3 pb-3.5 text-left font-semibold text-gray-900'
              >
                Group
              </th>
              <th
                scope='col'
                className='sticky top-0 z-10 border-b border-gray-300 bg-gray-100 px-3 pb-3.5 text-left font-semibold text-gray-900'
              >
                Advanced
              </th>
              <th
                scope='col'
                className='sticky top-0 z-10 border-b border-gray-300 bg-gray-100 px-3 pb-3.5 text-left font-semibold text-gray-900'
              >
                Min
              </th>
              <th
                scope='col'
                className='sticky top-0 z-10 border-b border-gray-300 bg-gray-100 px-3 pb-3.5 text-left font-semibold text-gray-900'
              >
                Max
              </th>
              <th
                scope='col'
                className='sticky top-0 z-10 border-b border-gray-300 bg-gray-100 px-3 pb-3.5 text-left font-semibold text-gray-900'
              >
                Prefix
              </th>
              <th
                scope='col'
                className='sticky top-0 z-10 border-b border-gray-300 bg-gray-100 px-3 pb-3.5 text-left font-semibold text-gray-900'
              >
                Suffix
              </th>
            </tr>
          </thead>
          <tbody className='divide-y divide-gray-200'>
            {newAttributes
              .sort((a, b) => a.label.localeCompare(b.label))
              .map((attribute) => (
                <tr key={attribute.id}>
                  <td className='whitespace-nowrap border-b border-gray-200 py-4 pl-3 pr-0 text-gray-900'>
                    <Text
                      value={attribute.label}
                      onChange={(value) => handleValueChange(attribute.id, 'label', value)}
                      placeholder='Label...'
                    />
                  </td>
                  <td className='whitespace-nowrap border-b border-gray-200 px-3 py-4'>
                    {attribute.type}
                  </td>
                  <td className='whitespace-nowrap border-b border-gray-200 px-3 py-4'>
                    <Text
                      value={attribute.placeholder}
                      onChange={(value) => handleValueChange(attribute.id, 'placeholder', value)}
                      placeholder='Placeholder...'
                    />
                  </td>
                  <td className='whitespace-nowrap border-b border-gray-200 px-3 py-4'>
                    <NumberInput
                      value={attribute.priority}
                      onChange={(value) => handleValueChange(attribute.id, 'priority', value)}
                      placeholder='Priority...'
                    />
                  </td>
                  <td className='whitespace-nowrap border-b border-gray-200 px-3 py-4'>
                    <ToggleSwitch
                      value={attribute.inheritable}
                      onChange={(value) => handleValueChange(attribute.id, 'inheritable', value)}
                    />
                  </td>
                  <td className='whitespace-nowrap border-b border-gray-200 px-3 py-4'>
                    <Text
                      value={attribute.group}
                      onChange={(value) => handleValueChange(attribute.id, 'group', value)}
                      placeholder='Group...'
                    />
                  </td>
                  <td className='whitespace-nowrap border-b border-gray-200 px-3 py-4'>
                    <ToggleSwitch
                      value={attribute.advanced}
                      onChange={(value) => handleValueChange(attribute.id, 'advanced', value)}
                    />
                  </td>
                  <td className='whitespace-nowrap border-b border-gray-200 px-3 py-4'>
                    {attribute.type === 'number' ? (
                      <NumberInput
                        value={attribute.min}
                        onChange={(value) => handleValueChange(attribute.id, 'min', value)}
                        placeholder='Min...'
                      />
                    ) : (
                      null
                    )}
                  </td>
                  <td className='whitespace-nowrap border-b border-gray-200 px-3 py-4'>
                    {attribute.type === 'number' ? (
                      <NumberInput
                        value={attribute.max}
                        onChange={(value) => handleValueChange(attribute.id, 'max', value)}
                        placeholder='Max...'
                      />
                    ) : (
                      null
                    )}
                  </td>
                  <td className='whitespace-nowrap border-b border-gray-200 px-3 py-4'>
                    {attribute.type === 'number' || attribute.type === 'text' ? (
                      <Text
                        value={attribute.prefix}
                        onChange={(value) => handleValueChange(attribute.id, 'prefix', value)}
                        placeholder='Prefix...'
                      />
                    ) : (
                      null
                    )}
                  </td>
                  <td className='whitespace-nowrap border-b border-gray-200 px-3 py-4'>
                    {attribute.type === 'number' || attribute.type === 'text' ? (
                      <Text
                        value={attribute.suffix}
                        onChange={(value) => handleValueChange(attribute.id, 'suffix', value)}
                        placeholder='Suffix...'
                      />
                    ) : (
                      null
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div>
        <Button text='Save' color='green' onClick={handleSave} />
      </div>
    </div>
  );
}
