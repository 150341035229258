import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import { AdminContext } from 'data/context';
import { successModal, errorModal } from 'utils/helpers';
import axios from 'utils/api';
import Modal from 'components/modals/modal';
import Text from 'components/inputs/text';
import Button from 'components/inputs/button';

type CreateStructureTypeModalProps = {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
};

export default function CreateStructureTypeModal({ openState }: CreateStructureTypeModalProps) {
  const {
    refreshData
  } = useContext(AdminContext);
  const [open, setOpen] = openState;
  const [name, setName] = useState('');

  useEffect(() => {
    setName('');
  }, [open]);

  const handleSave = async () => {
    try {
      await axios.post('structure-types', { label: name });
      successModal('Structure type created');
    } catch (error) {
      console.error(error);
      errorModal('Failed to create structure type');
    }
    await refreshData();
    setOpen(false);
  };

  return (
    <Modal
      title='New Structure Type'
      openState={openState}
      size='wide'
      infoMode
    >
      <div className='flex flex-col gap-5'>
        <div className="flex flex-col gap-2">
          <div className='font-semibold text-gray-900'>Name</div>
          <div>
            <Text
              value={name}
              onChange={(value) => setName(value)}
              placeholder='Name...'
            />
          </div>
        </div>
        <div className='flex items-center gap-3'>
          <Button
            text='Create'
            color='green'
            onClick={handleSave}
          />
          <Button
            text='Cancel'
            color='red'
            onClick={() => setOpen(false)}
          />
        </div>
      </div>
    </Modal>
  );
}
