import React, { useContext, useState, useEffect } from 'react';
import { AirHandlerProduct, CondenserProduct, SystemOptionCondenser, RankedSystem } from 'data/types';
import { CpuChipIcon, RectangleStackIcon } from '@heroicons/react/24/outline';
import { LockClosedIcon, LockOpenIcon, ArrowPathIcon, FireIcon, SparklesIcon } from '@heroicons/react/20/solid';
import { abbreviateNumber, createSystemOption, getZoneNumberById, formatUSD } from 'utils/helpers';
import Tag from 'components/tag';
import { DataContext, ConfigurationContext, ProjectContext } from 'data/context';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverClose
} from 'components/popover';
import ProductPhoto from 'components/product-photo';
import Tooltip from 'components/tooltip';
import ProductLink from 'components/product-link';
import { calculateOptimalDesignScore } from 'utils/ranking';

type SystemMapProps = {
  condenser: SystemOptionCondenser;
  editable?: boolean;
  systemOptionIndex?: number;
  showSpaces?: boolean;
  showTags?: boolean;
  showPrices?: boolean;
  showBTUs?: boolean;
  customerFacing?: boolean;
  trackingId?: string;
};

export default function SystemMap({
  condenser,
  editable = false,
  systemOptionIndex,
  showSpaces = false,
  showTags = false,
  showPrices = false,
  showBTUs = false,
  customerFacing = false,
  trackingId
}: SystemMapProps) {
  const { condenserProducts, airHandlerProducts, ductingKitProducts } = useContext(DataContext);
  const { configurations } = useContext(ConfigurationContext);
  const { systemOptions, setSystemOptions } = useContext(ProjectContext);
  const [validCondenserProducts, setValidCondenserProducts] = useState<CondenserProduct[]>([]);
  const [validAirHandlerProducts, setValidAirHandlerProducts] = useState<{ [zoneId: string]: AirHandlerProduct[] }>({});
  const [condenserLocked, setCondenserLocked] = useState(false);
  const [lockedZones, setLockedZones] = useState<string[]>([]);
  const [rankedSystems, setRankedSystems] = useState<RankedSystem[]>([]);
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (systemOptions.length > 0 && systemOptionIndex !== undefined && systemOptions[systemOptionIndex]) {
      const config = configurations.find(c => c.uniqueId === systemOptions[systemOptionIndex ?? 0].configuration.uniqueId);
      setRankedSystems(configurations.find(c => c.uniqueId === config?.uniqueId)?.condensers.find(c => c.uniqueId === condenser.uniqueId)?.rankedSystems ?? []);
    }
  }, [systemOptions[systemOptionIndex ?? 0]]);

  useEffect(() => {
    if (!isFirstRender) {
      setCondenserLocked(false);
      setLockedZones([]);
    }
    setIsFirstRender(false);
  }, [trackingId]);

  useEffect(() => {
    const validCondenserProductIds = rankedSystems.filter(system => {
      let valid = true;
      lockedZones.forEach(zoneId => {
        const zoneNumber = getZoneNumberById(system, zoneId);
        if (system[`airHandler${zoneNumber}ProductId`] !== condenser.zones.find(zone => zone.uniqueId === zoneId)?.selectedProduct.productId) {
          valid = false;
        }
      });
      return valid;
    }).map(system => system.condenserProductId).filter(id => id !== condenser.selectedProduct.productId);
    const newValidCondenserProducts = condenserProducts.filter((product) => validCondenserProductIds.includes(product.productId));
    setValidCondenserProducts(newValidCondenserProducts);
    const newValidAirHandlerProducts: { [zoneId: string]: AirHandlerProduct[] } = {};
    condenser.zones.forEach((zone, zoneIndex) => {
      const validAirHandlerProductIds = rankedSystems.filter(system => {
        if (!condenserLocked) {
          return true;
        }
        if (system.condenserProductId === condenser.selectedProduct.productId) {
          return true;
        }
        return false;
      }).filter(system => {
        let valid = true;
        lockedZones.filter(id => id !== zone.uniqueId).forEach(zoneId => {
          const zoneNumber = getZoneNumberById(system, zoneId);
          if (system[`airHandler${zoneNumber}ProductId`] !== condenser.zones.find(z => z.uniqueId === zoneId)?.selectedProduct.productId) {
            valid = false;
          }
        });
        return valid;
      }).map(system => system[`airHandler${zoneIndex + 1}ProductId`]).filter(id => id !== zone.selectedProduct.productId);
      const zoneValidAirHandlerProducts = airHandlerProducts.filter((product) => validAirHandlerProductIds.includes(product.productId));
      newValidAirHandlerProducts[zone.uniqueId] = zoneValidAirHandlerProducts;
    });
    setValidAirHandlerProducts(newValidAirHandlerProducts);
  }, [condenser, condenserLocked, lockedZones, condenserProducts, airHandlerProducts, rankedSystems]);

  const handleCondenserClick = (productId: number) => {
    if (editable && systemOptionIndex !== undefined && systemOptions) {
      const selectedSystem = rankedSystems.filter(system => {
        let valid = true;
        lockedZones.forEach(zoneId => {
          const zoneNumber = getZoneNumberById(system, zoneId);
          if (system[`airHandler${zoneNumber}ProductId`] !== condenser.zones.find(zone => zone.uniqueId === zoneId)?.selectedProduct.productId) {
            valid = false;
          }
        });
        return valid;
      }).find((system) => system.condenserProductId === productId);
      if (selectedSystem) {
        const systemsForNewOption: RankedSystem[] = [];
        systemOptions[systemOptionIndex].configuration.condensers.forEach((cond) => {
          if (cond.uniqueId === condenser.uniqueId) {
            systemsForNewOption.push(selectedSystem);
          } else {
            const otherRankedSystems = configurations.find(c => c.uniqueId === systemOptions[systemOptionIndex].configuration.uniqueId)?.condensers.find(c => c.uniqueId === cond.uniqueId)?.rankedSystems ?? [];
            const system = otherRankedSystems.find((sys) => sys.condenserProductId === cond.selectedProduct.productId &&
              cond.zones.every(zone => {
                const zoneNumber = getZoneNumberById(sys, zone.uniqueId);
                return sys[`airHandler${zoneNumber}ProductId`] === cond.zones.find(z => z.uniqueId === zone.uniqueId)?.selectedProduct.productId;
              }));
            if (system) {
              systemsForNewOption.push(system);
            }
          }
        });
        const config = configurations.find(c => c.uniqueId === systemOptions[systemOptionIndex].configuration.uniqueId);
        // console.log({ config });
        if (config) {
          const currentSystem = systemOptions[systemOptionIndex];
          const currentSystemIsCustom = currentSystem.tags.includes('Custom');
          let systemNumber = 1;
          // eslint-disable-next-line @typescript-eslint/no-loop-func
          while (systemOptions.some((o) => o.configuration.uniqueId === currentSystem.configuration.uniqueId && o.name === `Custom System ${systemNumber}`)) {
            systemNumber += 1;
          }
          const configScore = config.score ?? 1;
          // console.log({ configScore });
          const systemsOptimalDesignScore = calculateOptimalDesignScore(systemsForNewOption, configScore);
          // console.log({ systemsOptimalDesignScore });
          const newSystemOption = createSystemOption(
            systemsForNewOption,
            config,
            condenserProducts,
            airHandlerProducts,
            ductingKitProducts,
            currentSystemIsCustom ? currentSystem.name : `Custom System ${systemNumber}`,
            ['Custom'],
            undefined,
            systemsOptimalDesignScore
          );
          if (currentSystemIsCustom) {
            newSystemOption.uniqueId = currentSystem.uniqueId;
          }
          // console.log({ newSystemOption });
          setSystemOptions((draft) => {
            const oldSystem = draft.splice(systemOptionIndex, 1, newSystemOption)[0];
            if (!currentSystemIsCustom) {
              draft.push(oldSystem);
            }
          });
        } else {
          console.error('System option config not found');
        }
      } else {
        console.error('No system found');
      }
      setCondenserLocked(true);
    } else {
      console.error('No system option index provided');
    }
  };

  const handleAirHandlerClick = (zoneId: string, productId: number) => {
    if (editable && systemOptionIndex !== undefined && systemOptions) {
      const zoneNumber = getZoneNumberById(rankedSystems[0], zoneId);
      const selectedSystem = rankedSystems.filter(system => {
        if (!condenserLocked) {
          return true;
        }
        if (system.condenserProductId === condenser.selectedProduct.productId) {
          return true;
        }
        return false;
      }).filter(system => {
        let valid = true;
        lockedZones.filter(id => id !== zoneId).forEach(lockedZoneId => {
          const lockedZoneNumber = getZoneNumberById(system, lockedZoneId);
          if (system[`airHandler${lockedZoneNumber}ProductId`] !== condenser.zones.find(zone => zone.uniqueId === lockedZoneId)?.selectedProduct.productId) {
            valid = false;
          }
        });
        return valid;
      }).find((system) => system[`airHandler${zoneNumber}ProductId`] === productId);
      if (selectedSystem) {
        const systemsForNewOption: RankedSystem[] = [];
        systemOptions[systemOptionIndex].configuration.condensers.forEach((cond) => {
          if (cond.uniqueId === condenser.uniqueId) {
            systemsForNewOption.push(selectedSystem);
          } else {
            const otherRankedSystems = configurations.find(c => c.uniqueId === systemOptions[systemOptionIndex].configuration.uniqueId)?.condensers.find(c => c.uniqueId === cond.uniqueId)?.rankedSystems ?? [];
            const system = otherRankedSystems.find((sys) => sys.condenserProductId === cond.selectedProduct.productId &&
              cond.zones.every(zone => {
                const zNumber = getZoneNumberById(sys, zone.uniqueId);
                return sys[`airHandler${zNumber}ProductId`] === cond.zones.find(z => z.uniqueId === zone.uniqueId)?.selectedProduct.productId;
              }));
            if (system) {
              systemsForNewOption.push(system);
            }
          }
        });
        const config = configurations.find(c => c.uniqueId === systemOptions[systemOptionIndex].configuration.uniqueId);
        if (config) {
          const currentSystem = systemOptions[systemOptionIndex];
          const currentSystemIsCustom = currentSystem.tags.includes('Custom');
          let systemNumber = 1;
          // eslint-disable-next-line @typescript-eslint/no-loop-func
          while (systemOptions.some((o) => o.configuration.uniqueId === currentSystem.configuration.uniqueId && o.name === `Custom System ${systemNumber}`)) {
            systemNumber += 1;
          }
          const configScore = config.score ?? 1;
          const systemsOptimalDesignScore = calculateOptimalDesignScore(systemsForNewOption, configScore);
          const newSystemOption = createSystemOption(
            systemsForNewOption,
            config,
            condenserProducts,
            airHandlerProducts,
            ductingKitProducts,
            currentSystemIsCustom ? currentSystem.name : `Custom System ${systemNumber}`,
            ['Custom'],
            undefined,
            systemsOptimalDesignScore
          );
          if (currentSystemIsCustom) {
            newSystemOption.uniqueId = currentSystem.uniqueId;
          }
          setSystemOptions((draft) => {
            const oldSystem = draft.splice(systemOptionIndex, 1, newSystemOption)[0];
            if (!currentSystemIsCustom) {
              draft.push(oldSystem);
            }
          });
        } else {
          console.error('System option config not found');
        }
      } else {
        console.error('No system found');
      }
      setLockedZones((oldValues) => [...oldValues, zoneId]);
    } else {
      console.error('No system option index provided');
    }
  };

  const toggleZoneLock = (zoneId: string) => {
    if (lockedZones.includes(zoneId)) {
      setLockedZones((oldValues) => oldValues.filter(v => v !== zoneId));
    } else {
      setLockedZones((oldValues) => [...oldValues, zoneId]);
    }
  };

  return (
    <div key={condenser.uniqueId} className='flex flex-col'>
      <div className='flex'>
        <div className='flex flex-col pr-2 pt-2'>
          <div className='h-3 w-3 rounded-full bg-gray-300' />
          <div className='border-l-4 border-gray-300 grow ml-[4px]' />
        </div>
        <div className='flex flex-col gap-2 grow'>
          <div className='flex flex-wrap items-center gap-2'>
            <CpuChipIcon className='h-6 w-6 text-gray-800' />
            <span className='text-xl font-semibold'>
              {condenser.name}
            </span>
            {editable && (
              <Popover placement='bottom-start'>
                <PopoverTrigger className='text-gray-400 hover:text-gray-500 relative'>
                  <ArrowPathIcon className='w-6 h-6' />
                  <Tooltip text='Change Condenser' />
                </PopoverTrigger>
                <PopoverContent className="p-2 rounded-lg bg-white shadow-lg ring-1 ring-black/5 z-10 max-h-[500px] overflow-auto">
                  {validCondenserProducts.length > 0 ? (
                    <div className='table'>
                      <div className='table-row-group'>
                        {validCondenserProducts
                          .sort((a, b) => a.seer - b.seer)
                          .sort((a, b) => a.coolingBTU - b.coolingBTU)
                          .map((product) => (
                            <PopoverClose
                              className='group px-2 py-1 rounded-md font-semibold table-row'
                              onClick={() => handleCondenserClick(product.productId)}
                              key={product.productId}
                            >
                              <div className='table-cell p-1 align-middle group-hover:bg-gray-100 rounded-l-md'>
                                <ProductPhoto product={product} size='small' />
                              </div>
                              <div className='table-cell p-1 align-middle group-hover:bg-gray-100'>
                                <span className='whitespace-nowrap'>{product.model}</span>
                              </div>
                              <div className='table-cell p-1 align-middle group-hover:bg-gray-100'>
                                <Tag noWrap>
                                  <span>{abbreviateNumber(product.coolingBTU)}</span> {product.coolingBTU !== condenser.selectedProduct.coolingBTU && <span>(<span className={product.coolingBTU < condenser.selectedProduct.coolingBTU ? 'text-red-600' : 'text-green-600'}>{product.coolingBTU < condenser.selectedProduct.coolingBTU ? '-' : '+'}{abbreviateNumber(Math.abs(product.coolingBTU - condenser.selectedProduct.coolingBTU))}</span>)</span>}
                                </Tag>
                              </div>
                              <div className='table-cell p-1 align-middle group-hover:bg-gray-100'>
                                <Tag noWrap>
                                  <span>{product.seer} SEER</span> {product.seer !== condenser.selectedProduct.seer && <span>(<span className={product.seer < condenser.selectedProduct.seer ? 'text-red-600' : 'text-green-600'}>{product.seer < condenser.selectedProduct.seer ? '-' : '+'}{Math.round(Math.abs(product.seer - condenser.selectedProduct.seer) * 10) / 10}</span>)</span>}
                                </Tag>
                              </div>
                              <div className='table-cell p-1 align-middle group-hover:bg-gray-100 rounded-r-md'>
                                <span className={`text-sm font-semibold ${product.price <= condenser.selectedProduct.price ? 'text-green-600' : 'text-red-600'}`}>
                                  {product.price < condenser.selectedProduct.price ? '-' : '+'}{formatUSD(Math.abs(product.price - condenser.selectedProduct.price))}
                                </span>
                              </div>
                            </PopoverClose>
                          ))}
                      </div>
                    </div>
                  ) : (
                    <div className='max-w-[400px]'>No other condensers available.{lockedZones.length > 0 && ' Try unlocking zones to see more options.'}</div>
                  )}
                </PopoverContent>
              </Popover>
            )}
          </div>
          <div className='flex grow items-start gap-3'>
            <ProductPhoto product={condenser.selectedProduct} size='medium' />
            <div className='flex flex-col gap-1 grow'>
              <div className="flex items-center w-full">
                <div className='grow flex flex-wrap items-center gap-2'>
                  <span className='text-lg font-semibold'>{condenser.selectedProduct.series ?? 'Unknown Series'}</span>
                  {showTags && (
                    <>
                      <Tag text={`${abbreviateNumber(Math.round(condenser.selectedProduct.coolingBTU / 1000) * 1000)}`} />
                      <Tag text={`${condenser.selectedProduct.seer} SEER`} />
                    </>
                  )}
                </div>
                {showPrices && (
                  <span className='text-right text-lg font-bold'>
                    {formatUSD(condenser.selectedProduct.price, true)}
                  </span>
                )}
              </div>
              <ProductLink
                type='condenser'
                product={condenser.selectedProduct}
                hoverInfo={editable}
                fullName={!editable}
              />
            </div>
            {editable && (
              <button className='relative text-gray-300 hover:text-gray-400 p-2' onClick={() => setCondenserLocked(oldValue => !oldValue)}>
                {condenserLocked ?
                  <LockClosedIcon className='h-6 w-6 text-gray-400' /> :
                  <LockOpenIcon className='h-6 w-6' />}
                <Tooltip text={condenserLocked ? 'Unlock Condenser' : 'Lock Condenser'} />
              </button>
            )}
          </div>
        </div>
      </div>
      {condenser.zones.map((zone, zoneIndex) => (
        <div key={zone.uniqueId} className='flex items-start gap-2'>
          <div className="flex items-start relative self-stretch">
            {zoneIndex < condenser.zones.length - 1 && (
              <div className='absolute left-[4px] h-full border-l-4 border-gray-300' />
            )}
            <div className='border-b-4 border-l-4 border-gray-300 rounded-bl-2xl pt-10 mb-[4px] w-7 ml-[4px]' />
            <div className='h-3 w-3 rounded-full bg-gray-300 mt-[36px]' />
          </div>
          <div className='flex flex-col gap-2 grow pt-7'>
            <div className='flex items-center flex-wrap gap-2'>
              <RectangleStackIcon className='h-6 w-6 text-gray-800 shrink-0' />
              <span className='text-xl font-semibold shrink-0'>
                {zone.name}
              </span>
              {editable && (
                <Popover placement='bottom-start'>
                  <PopoverTrigger className='text-gray-400 hover:text-gray-500 relative'>
                    <ArrowPathIcon className='w-6 h-6' />
                    <Tooltip text='Change Air Handler' />
                  </PopoverTrigger>
                  <PopoverContent className="p-2 rounded-lg bg-white shadow-lg ring-1 ring-black/5 z-10 max-h-[500px] overflow-auto">
                    {validAirHandlerProducts[zone.uniqueId]?.length > 0 ? (
                      <div className='table'>
                        <div className='table-row-group'>
                          {validAirHandlerProducts[zone.uniqueId]
                            ?.sort((a, b) => Number(a.sizeCode.toLowerCase().replace('k', '')) - Number(b.sizeCode.toLowerCase().replace('k', '')))
                            .sort((a, b) => a.type.localeCompare(b.type))
                            .map((product) => (
                              <PopoverClose
                                key={product.productId}
                                className='group px-2 py-1 rounded-md font-semibold table-row'
                                onClick={() => handleAirHandlerClick(zone.uniqueId, product.productId)}
                              >
                                <div className='table-cell p-1 align-middle group-hover:bg-gray-100 rounded-l-md'>
                                  <ProductPhoto product={product} size='small' />
                                </div>
                                <div className='table-cell p-1 align-middle group-hover:bg-gray-100'>
                                  <span className='whitespace-nowrap'>{product.model}</span>
                                </div>
                                <div className='table-cell p-1 align-middle group-hover:bg-gray-100'>
                                  <Tag noWrap text={product.type} />
                                </div>
                                <div className='table-cell p-1 align-middle group-hover:bg-gray-100'>
                                  <Tag noWrap>
                                    <span>{product.sizeCode}</span> {product.sizeCode !== zone.selectedProduct.sizeCode && <span>(<span className={Number(product.sizeCode.toLowerCase().replace('k', '')) < Number(zone.selectedProduct.sizeCode.toLowerCase().replace('k', '')) ? 'text-red-600' : 'text-green-600'}>{Number(product.sizeCode.toLowerCase().replace('k', '')) < Number(zone.selectedProduct.sizeCode.toLowerCase().replace('k', '')) ? '-' : '+'}{Math.abs(Number(product.sizeCode.toLowerCase().replace('k', '')) - Number(zone.selectedProduct.sizeCode.toLowerCase().replace('k', '')))}K</span>)</span>}
                                  </Tag>
                                </div>
                                <div className='table-cell p-1 align-middle group-hover:bg-gray-100 rounded-r-md'>
                                  <span className={`text-sm font-semibold ${product.price <= zone.selectedProduct.price ? 'text-green-600' : 'text-red-600'}`}>
                                    {product.price < zone.selectedProduct.price ? '-' : '+'}{formatUSD(Math.abs(product.price - zone.selectedProduct.price))}
                                  </span>
                                </div>
                              </PopoverClose>
                            ))}
                        </div>
                      </div>
                    ) : (
                      <div className='max-w-[400px]'>No other air handlers available.{(condenserLocked || lockedZones.length > 0) && ' Try unlocking condenser or other zones to see more options.'}</div>
                    )}
                  </PopoverContent>
                </Popover>
              )}
              {showBTUs && (
                <>
                  <Tag>
                    <span className='inline-flex items-center gap-1'>
                      {!customerFacing ? (
                        <>
                          <div className='h-3 w-3 rounded-full bg-orange-400' />
                          {abbreviateNumber(zone.zoneItems.reduce((acc, item) => acc + item.BTUs.heating, 0))}
                        </>
                      ) : (
                        <>
                          <FireIcon className='h-3 w-3 text-orange-500' />
                          <span>
                            {(() => {
                              const totalHeatingBTUs = zone.zoneItems.reduce((acc, item) => acc + item.BTUs.heating, 0);
                              const heatingCapacity = zone.selectedProduct.heatingBTU;
                              return `${Math.min((heatingCapacity / totalHeatingBTUs) * 100, 100).toFixed(2)}%`;
                            })()}
                          </span>
                        </>
                      )}
                    </span>
                  </Tag>
                  <Tag>
                    <span className='inline-flex items-center gap-1'>
                      {!customerFacing ? (
                        <>
                          <div className='h-3 w-3 rounded-full bg-blue-400' />
                          {abbreviateNumber(zone.zoneItems.reduce((acc, item) => acc + item.BTUs.cooling, 0))}
                        </>
                      ) : (
                        <>
                          <SparklesIcon className='h-3 w-3 text-sky-500' />
                          <span>
                            {(() => {
                              const totalCoolingBTUs = zone.zoneItems.reduce((acc, item) => acc + item.BTUs.cooling, 0);
                              const coolingCapacity = zone.selectedProduct.coolingBTU;
                              return `${Math.min((coolingCapacity / totalCoolingBTUs) * 100, 100).toFixed(2)}%`;
                            })()}
                          </span>
                        </>
                      )}
                    </span>
                  </Tag>
                </>
              )}
              {showSpaces && zone.zoneItems.map((item) => (
                <Tag key={item.uniqueId} text={item.name} />
              ))}
            </div>
            <div className='flex grow items-start gap-3'>
              <ProductPhoto product={zone.selectedProduct} size='medium' />
              <div className='flex flex-col gap-1 grow'>
                <div className="flex items-center w-full">
                  <div className='grow flex flex-wrap items-center gap-2'>
                    <span className='text-lg font-semibold'>{zone.selectedProduct.type}</span>
                    {showTags && <Tag text={`${zone.selectedProduct.sizeCode.toUpperCase()}`} />}
                  </div>
                  {showPrices && (
                    <span className='text-right text-lg font-bold'>
                      {formatUSD(zone.selectedProduct.price, true)}
                    </span>
                  )}
                </div>
                <ProductLink
                  type='air handler'
                  product={zone.selectedProduct}
                  hoverInfo={editable}
                  fullName={!editable}
                />
              </div>
              {editable && (
                <button className='relative text-gray-300 hover:text-gray-400 p-2' onClick={() => toggleZoneLock(zone.uniqueId)}>
                  {lockedZones.includes(zone.uniqueId) ?
                    <LockClosedIcon className='h-6 w-6 text-gray-400' /> :
                    <LockOpenIcon className='h-6 w-6' />}
                  <Tooltip text={lockedZones.includes(zone.uniqueId) ? 'Unlock Zone' : 'Lock Zone'} />
                </button>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

SystemMap.defaultProps = {
  editable: false,
  systemOptionIndex: undefined,
  showSpaces: false,
  showTags: false,
  showPrices: false,
  showBTUs: false,
  customerFacing: false,
  trackingId: undefined
};