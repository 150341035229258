import React, { useContext } from 'react';
import { ProjectContext, ConfigurationContext, DataContext } from 'data/context';
import { confirmationModal } from 'utils/helpers';
import Select from 'components/inputs/select';

type StructureTypeSelectorProps = {
  showInvalid?: boolean;
};

export default function StructureTypeSelector({
  showInvalid = false
}: StructureTypeSelectorProps) {
  const {
    structureTypeSelected,
    setStructureTypeSelected,
    setStructureSubtypeSelected,
    setPartialStructure
  } = useContext(ProjectContext);
  const {
    structure,
    configurations,
    selectedSystems
  } = useContext(ConfigurationContext);
  const { structureTypes } = useContext(DataContext);

  const handleChangeStructureType = (structureTypeId: number | null) => {
    const changeStructureType = () => {
      setStructureSubtypeSelected(null);
      setStructureTypeSelected(structureTypeId);
      setPartialStructure(null);
    };
    if (structure.length === 0 && configurations.length === 0 && selectedSystems.length === 0) {
      changeStructureType();
    } else {
      confirmationModal('Are you sure you want to change the structure type? This will reset your structure, configurations, and selected systems!', changeStructureType);
    }
  };

  return (
    <Select
      onChange={(value) => handleChangeStructureType(value !== '' ? Number(value) : null)}
      options={structureTypes.map((t) => ({ label: t.label, value: t.id.toString() }))}
      value={structureTypeSelected?.toString() ?? undefined}
      invalid={showInvalid && structureTypeSelected === null}
    />
  );
}

StructureTypeSelector.defaultProps = {
  showInvalid: false
};