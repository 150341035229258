import React from 'react';
import clsx from 'clsx';

type SkeletonProps = {
  color?: 'gray' | 'blue';
}

export default function Skeleton({ color = 'gray' }: SkeletonProps) {
  const classes = clsx(
    'w-full h-4 animate-pulse rounded',
    color === 'gray' && 'bg-gray-300',
    color === 'blue' && 'bg-blue-300',
  );

  return (
    <div className={classes} />
  );
}