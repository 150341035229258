import React, { useEffect, useState } from 'react';
import { BTUs as BTUsType } from 'data/types';
import { abbreviateNumber } from 'utils/helpers';

type BTUBubblesProps = {
  BTUs: BTUsType | BTUsType[];
  roundNumbers?: boolean;
};

export default function BTUBubbles({ BTUs, roundNumbers = false }: BTUBubblesProps) {
  const [totalBTUs, setTotalBTUs] = useState<BTUsType>({
    heating: 0,
    heatingAmount: 'full',
    cooling: 0,
    coolingAmount: 'full',
  });

  useEffect(() => {
    if (Array.isArray(BTUs)) {
      let mostRestrictiveHeating: BTUsType['heatingAmount'] = 'none';
      let mostRestrictiveCooling: BTUsType['coolingAmount'] = 'none';
      let totalHeating = 0;
      let totalCooling = 0;
      BTUs.forEach((BTU) => {
        totalHeating += BTU.heating;
        if (BTU.heatingAmount === 'partial' && mostRestrictiveHeating !== 'full') {
          mostRestrictiveHeating = 'partial';
        } else if (BTU.heatingAmount === 'full') {
          mostRestrictiveHeating = 'full';
        }
        totalCooling += BTU.cooling;
        if (BTU.coolingAmount === 'partial' && mostRestrictiveCooling !== 'full') {
          mostRestrictiveCooling = 'partial';
        } else if (BTU.coolingAmount === 'full') {
          mostRestrictiveCooling = 'full';
        }
      });
      setTotalBTUs({
        heating: totalHeating,
        heatingAmount: mostRestrictiveHeating,
        cooling: totalCooling,
        coolingAmount: mostRestrictiveCooling,
      });
    } else {
      setTotalBTUs(BTUs);
    }
  }, [BTUs]);

  return (
    <div className='flex items-center gap-3'>
      <div className='flex items-center gap-1'>
        <div className='h-4 w-4 rounded-full bg-orange-400 flex justify-end'>
          {totalBTUs.heatingAmount === 'partial' && (
            <div className='bg-gray-300 w-1/2 h-full rounded-r-full border-y-2 border-r-2 border-orange-400' />
          )}
          {totalBTUs.heatingAmount === 'none' && (
            <div className='bg-gray-300 w-full h-full rounded-full border-2 border-orange-400' />
          )}
        </div>
        <span className='text-sm text-gray-500'>
          {roundNumbers ? abbreviateNumber(Math.round(totalBTUs.heating)) : Math.round(totalBTUs.heating).toLocaleString()}
        </span>
      </div>
      <div className='flex items-center gap-1'>
        <div className='h-4 w-4 rounded-full bg-blue-400 flex justify-end'>
          {totalBTUs.coolingAmount === 'partial' && (
            <div className='bg-gray-300 w-1/2 h-full rounded-r-full border-y-2 border-r-2 border-blue-400' />
          )}
          {totalBTUs.coolingAmount === 'none' && (
            <div className='bg-gray-300 w-full h-full rounded-full border-2 border-blue-400' />
          )}
        </div>
        <span className='text-sm text-gray-500'>
          {roundNumbers ? abbreviateNumber(Math.round(totalBTUs.cooling)) : Math.round(totalBTUs.cooling).toLocaleString()}
        </span>
      </div>
    </div>
  );
}

BTUBubbles.defaultProps = {
  roundNumbers: false
};