import React, { Dispatch, SetStateAction, useContext, useState, useEffect } from 'react';
import { ProjectContext, ConfigurationContext } from 'data/context';
import AttributeComponent from 'components/attribute';
import Modal from 'components/modals/modal';
import TalkingPoint from 'components/talking-point';
import InfoTooltip from 'components/info-tooltip';

type BudgetModalProps = {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
};

export default function BudgetModal({ openState }: BudgetModalProps) {
  const { configurations } = useContext(ConfigurationContext);
  const {
    customerValues,
    setCustomerValues
  } = useContext(ProjectContext);
  const [open] = openState;
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);

  const values = [
    { code: 'max_budget', talkingPoint: 'Do you have a maximum budget I should be aware of, or did you want to consider all options?' }
  ];

  const handleChangeCustomerValues = (
    attributeId: number,
    value: any
  ) => {
    setCustomerValues((draft) => {
      const updatedCustomerValues = draft;
      const index = updatedCustomerValues.findIndex(
        (a) => a.id === attributeId
      );
      updatedCustomerValues[index].value = value;
      return updatedCustomerValues;
    });
  };

  const updatePriceRanges = () => {
    const minimums: number[] = [];
    const maximums: number[] = [];
    configurations.forEach((config) => {
      let configMin = 0;
      let configMax = 0;
      config.condensers.forEach((condenser) => {
        configMin += Math.min(...condenser.validSystems.map(system => system.baseRetailPrice));
        configMax += Math.max(...condenser.validSystems.map(system => system.baseRetailPrice));
      });
      minimums.push(configMin);
      maximums.push(configMax);
    });
    setMinPrice(Math.min(...minimums));
    setMaxPrice(Math.max(...maximums));
  };

  useEffect(() => {
    if (open && configurations.every((config) => config.condensers.every((cond) => cond.validSystems.length > 0))) {
      updatePriceRanges();
    }
  }, [open, configurations]);

  useEffect(() => {
    if (!open) {
      setMinPrice(0);
      setMaxPrice(0);
    }
  }, [open]);

  return (
    <Modal title='Customer Budget' openState={openState} buttonText='Continue'>
      <div className='flex w-full flex-col gap-6'>
        <TalkingPoint>
          {(minPrice !== 0 && maxPrice !== 0) ? (
            <span>
              {minPrice !== maxPrice ? `For your floor plan, I'm showing systems with a price range
          between $${minPrice.toLocaleString()} and $${maxPrice.toLocaleString()}, not including accessories.` :
                `For your floor plan, I'm showing systems with a price of $${minPrice.toLocaleString()}, not including accessories.`}
            </span>
          ) : (
            <span>Loading...</span>
          )}
        </TalkingPoint>
        <div className='flex flex-col items-center gap-2 rounded-lg bg-blue-100 p-2'>
          <div className="flex items-center gap-2">
            <span className='text-lg font-bold'>Price Range*</span>
            <InfoTooltip>
              <span>*Not including accessories</span>
            </InfoTooltip>
          </div>
          {(minPrice !== 0 && maxPrice !== 0) ? (
            <div className='flex items-center gap-4'>
              <span className='text-xl font-bold text-gray-700'>${minPrice.toLocaleString()}</span>
              {minPrice !== maxPrice && (
                <>
                  <div className='h-1 w-6 rounded-full bg-gray-400' />
                  <span className='text-xl font-bold text-gray-700'>${maxPrice.toLocaleString()}</span>
                </>
              )}
            </div>
          ) : (
            <span className='text-xl font-bold text-gray-700'>Loading...</span>
          )}
        </div>
        {customerValues.filter(cv => values.map(s => s.code).includes(cv.code)).map((customerValue) => (
          <div key={customerValue.id} className='flex flex-col gap-6'>
            <TalkingPoint>
              {values.find(s => s.code === customerValue.code)?.talkingPoint}
            </TalkingPoint>
            <AttributeComponent
              onChange={(value) => handleChangeCustomerValues(customerValue.id, value)}
              completed={!!customerValue.value}
              debounce={500}
              {...{ ...customerValue }}
            />
          </div>
        ))}
      </div>
    </Modal>
  );
}
