import {
  Item,
  Level,
  Section,
  Space,
  Attribute,
  Zone,
  AirHandlerTypeCode
} from 'data/types';
import { airHandlerTypeCodes } from 'data/constants';
import { v4 as uuid } from 'uuid';

export function findSpaceById(structure: Level[], id: string) {
  for (const level of structure) {
    for (const section of level.sections) {
      for (const space of section.spaces) {
        if (space.uniqueId === id) {
          return space;
        }
      }
    }
  }
  return null;
}

export function findStructureItemById(structure: Level[], id: string) {
  for (const level of structure) {
    if (level.uniqueId === id) {
      return level;
    }
    for (const section of level.sections) {
      for (const space of section.spaces) {
        if (space.uniqueId === id) {
          return space;
        }
      }
    }
  }
  return null;
}

export function findSectionBySpaceId(structure: Level[], id: string) {
  for (const level of structure) {
    for (const section of level.sections) {
      for (const space of section.spaces) {
        if (space.uniqueId === id) {
          return section;
        }
      }
    }
  }
  return null;
}

export function findCountOfItem(structure: Level[], itemId: number) {
  let count = 0;
  for (const level of structure) {
    if (level.itemId === itemId) {
      count += 1;
    }
    for (const section of level.sections) {
      for (const space of section.spaces) {
        if (space.itemId === itemId) {
          count += 1;
        }
      }
    }
  }
  return count;
}

export function getAllLabelsOfType(structure: Level[], type: 'level' | 'section' | 'space') {
  const labels: string[] = [];
  for (const level of structure) {
    if (type === 'level') {
      labels.push(level.label);
    }
    for (const section of level.sections) {
      if (type === 'section') {
        labels.push(section.label);
      }
      for (const space of section.spaces) {
        if (type === 'space') {
          labels.push(space.label);
        }
      }
    }
  }
  return labels;
}

function inheritAttributes(parentAttributes: Attribute[], childAttributes: Attribute[]): Attribute[] {
  const newAttributes = [...childAttributes];
  const newAttributeIds = newAttributes.map((a) => a.id);
  parentAttributes.forEach((att) => {
    if (newAttributeIds.includes(att.id) && att.inheritable) {
      const attIndex = newAttributes.findIndex((na) => na.id === att.id);
      if (attIndex !== -1 && (att.value || att.value === 0)) {
        newAttributes[attIndex] = { ...newAttributes[attIndex], value: att.value };
      }
    }
  });

  return newAttributes;
}

export function createLevel(item: Item, options?: { data?: Partial<Level>, attributesToInherit?: Attribute[] }): Level {
  const newAttributes = options?.attributesToInherit ?
    inheritAttributes(options.attributesToInherit, item.attributes) :
    [...item.attributes];

  return {
    uniqueId: uuid(),
    itemId: item.id,
    label: item.label,
    sections: [],
    attributes: newAttributes,
    modified: false,
    ...options?.data
  };
}

export function createSection(options?: { data?: Partial<Section> }): Section {
  return {
    uniqueId: uuid(),
    label: 'Area',
    spaces: [],
    ...options?.data
  };
}

export function createSpace(item: Item, options?: { data?: Partial<Space>, attributesToInherit?: Attribute[] }): Space {
  const newAttributes = options?.attributesToInherit ?
    inheritAttributes(options.attributesToInherit, item.attributes) :
    [...item.attributes];

  const newSpace: Space = {
    uniqueId: uuid(),
    label: item.label,
    itemId: item.id,
    attributes: newAttributes,
    BTUs: {
      heating: 0,
      heatingAmount: 'none',
      cooling: 0,
      coolingAmount: 'none'
    },
    airHandlerRankings: [],
    valid: newAttributes.every((att) => (
      !att.required ||
      (att.parentAttributes !== undefined && att.parentAttributes.every((pa) => pa.value?.toString() !== newAttributes.find((a) => a.id === pa.attributeId)?.value?.toString())) ||
      att.value
    )),
    modified: false,
    ...options?.data
  };
  return newSpace;
}

export function getRestrictedAirHandlerTypes(zone: Zone, structure: Level[], items: Item[], ducted: boolean = false) {
  if (ducted) {
    return Object.values(airHandlerTypeCodes).filter(c => c !== 'ducted');
  }
  const restrictedTypes: AirHandlerTypeCode[] = ['ducted'];
  const zoneItemsCount = zone.zoneItems.length;
  zone.zoneItems.forEach((zoneItem) => {
    const space = structure
      .find((level) =>
        level.sections.some((section) =>
          section.spaces.some(
            (sp) => sp.uniqueId === zoneItem.structureItemId
          )
        )
      )
      ?.sections.find((section) =>
        section.spaces.some(
          (sp) => sp.uniqueId === zoneItem.structureItemId
        )
      )
      ?.spaces.find((sp) => sp.uniqueId === zoneItem.structureItemId);
    if (space) {
      const attributeValues = Object.fromEntries(space.attributes.map((a) => [a.code, a.value]));
      const item = items.find(i => i.id === space.itemId);
      if (item && item.allowedAirHandlerTypes.length > 0) {
        const airHandlerTypes = Object.values(airHandlerTypeCodes);
        restrictedTypes.push(...airHandlerTypes.filter(type => !item.allowedAirHandlerTypes.includes(type)));
      }
      if (
        attributeValues.ceiling_type === 'Vaulted' ||
        attributeValues.ceiling_type === 'Sloped' ||
        attributeValues.space_above !== 'Yes'
      ) {
        restrictedTypes.push(
          'cassette',
          'cassette_16'
        );
      }
      if (attributeValues.joist_width === '16"') {
        restrictedTypes.push('cassette');
      }
      if (attributeValues.ceiling_type !== 'Drop Ceiling') {
        restrictedTypes.push('cassette_drop_ceiling');
      }
      if (attributeValues.ceiling_height < 6) {
        restrictedTypes.push('wall', 'wallDIY', 'heatingConsole');
      }
      if (
        attributeValues.space_above !== 'Yes' &&
        attributeValues.space_below !== 'Yes'
      ) {
        restrictedTypes.push('concealed');
      }
      if (attributeValues.share_air === 'No' && zoneItemsCount > 1) {
        restrictedTypes.push(
          'wall',
          'cassette',
          'heatingConsole',
          'console',
          'wallDIY',
          'cassette_16',
          'cassette_drop_ceiling'
        );
      }
    }
  });
  return Array.from(new Set(restrictedTypes));
}
