import React from 'react';
import clsx from 'clsx';
import { AccessoryProduct, AccessoryWithQuantity, AirHandlerProduct, CondenserProduct } from 'data/types';
import { PhotoIcon } from '@heroicons/react/20/solid';

type ProductPhotoProps = {
  product?: AirHandlerProduct | CondenserProduct | AccessoryProduct | AccessoryWithQuantity;
  size?: 'small' | 'medium' | 'large';
};

export default function ProductPhoto({ product, size = 'medium' }: ProductPhotoProps) {
  const classes = clsx(
    'relative flex items-center justify-center bg-gray-300 shrink-0 overflow-hidden',
    size === 'small' && 'h-8 w-12 rounded-sm',
    size === 'medium' && 'h-14 w-20 rounded-md',
    size === 'large' && 'h-[200px] w-full rounded-lg'
  );

  const iconClasses = clsx(
    'text-gray-400',
    size === 'small' && 'h-4 w-4',
    size === 'medium' && 'h-6 w-6',
    size === 'large' && 'h-8 w-8'
  );

  return (
    <div className={classes}>
      <PhotoIcon className={iconClasses} />
      <img
        className='absolute left-0 top-0 w-full h-full'
        src={`https://images.alpinehomeair.com/300x200/uwm/photos/${product?.photo}`}
        alt={product?.name}
      />
    </div>
  );
}