import React, { useRef, useEffect, useState } from 'react';
import clsx from 'clsx';

export type CheckboxProps = {
  value?: boolean;
  onChange?: (value: boolean) => void;
  debounce?: number;
  disabled?: boolean;
  htmlId?: string;
  autoFocus?: boolean;
};

export default function Checkbox({
  value: initialValue,
  onChange,
  debounce,
  disabled = false,
  htmlId,
  autoFocus = false
}: CheckboxProps) {
  const [value, setValue] = useState(!!initialValue);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setValue(!!initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (autoFocus && ref.current) {
      ref.current.focus();
    }
  }, [autoFocus]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (onChange && value !== initialValue) {
        onChange(value);
      }
    }, debounce);
    return () => clearTimeout(timeout);
  }, [value]);

  const classes = clsx(
    'h-5 w-5 rounded border-2 border-gray-300 text-blue-600 focus:ring-blue-600 bg-white',
    disabled ? 'opacity-[70%] cursor-not-allowed' : 'cursor-pointer'
  );

  return (
    <input
      ref={ref}
      id={htmlId}
      type='checkbox'
      className={classes}
      checked={value}
      onChange={(e) => setValue(e.target.checked)}
      disabled={disabled}
    />
  );
}

Checkbox.defaultProps = {
  value: undefined,
  onChange: undefined,
  debounce: undefined,
  disabled: false,
  htmlId: undefined,
  autoFocus: false
};
