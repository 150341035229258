import React, { useContext, useEffect, useState } from 'react';
import { warningModal } from 'utils/helpers';
import SendQuoteModal from 'components/modals/send-quote-modal';
import Button from 'components/inputs/button';
import ZipCodeModal from 'components/modals/zip-code-modal';
import { ProjectContext, UIContext, ConfigurationContext } from 'data/context';
import { proceedErrorMessages } from 'data/constants';
import ProgressIndicator from 'components/progress-indicator';

export default function Footer() {
  const {
    locationData,
    quotesToSend
  } = useContext(ProjectContext);
  const {
    activeTab,
    setActiveTab,
    pageProgress
  } = useContext(UIContext);
  const { selectedSystems } = useContext(ConfigurationContext);
  const zipCodeModalOpenState = useState(false);
  const sendQuoteModalOpenState = useState(false);
  const [, setZipCodeModalOpen] = zipCodeModalOpenState;
  const [, setSendQuoteModalOpen] = sendQuoteModalOpenState;
  const [quoteButtonText, setQuoteButtonText] = useState('Send Quote to Customer');

  useEffect(() => {
    if (quotesToSend.length < 1) {
      setQuoteButtonText('Select a quote to send');
    } else if (selectedSystems.filter(sys => quotesToSend.includes(sys.id)).every(sys => sys.quoteStatus === 'sent')) {
      setQuoteButtonText('Quote sent');
    } else if (selectedSystems.filter(sys => quotesToSend.includes(sys.id)).some(sys => sys.quoteStatus === 'sending')) {
      setQuoteButtonText('Sending quote...');
    } else {
      setQuoteButtonText('Send quote to customer');
    }
  }, [selectedSystems, quotesToSend]);

  const nextTab = () => {
    if (activeTab === 'description' && !locationData) {
      setZipCodeModalOpen(true);
      return;
    }
    if (pageProgress[activeTab] === 100) {
      switch (activeTab) {
        case 'description':
          setActiveTab('layout');
          break;
        case 'layout':
          setActiveTab('configuration');
          break;
        case 'configuration':
          setActiveTab('air handlers');
          break;
        case 'air handlers':
          setActiveTab('equipment');
          break;
        case 'equipment':
          setActiveTab('accessories');
          break;
        case 'accessories':
          setActiveTab('quote');
          break;
        default:
          break;
      }
    } else {
      warningModal(proceedErrorMessages[activeTab]);
    }
  };

  const prevTab = () => {
    switch (activeTab) {
      case 'layout':
        setActiveTab('description');
        break;
      case 'configuration':
        if (pageProgress.description === 100) {
          setActiveTab('layout');
        } else {
          warningModal(proceedErrorMessages.description);
        }
        break;
      case 'air handlers':
        if (pageProgress.layout === 100) {
          setActiveTab('configuration');
        } else {
          warningModal(proceedErrorMessages.layout);
        }
        break;
      case 'equipment':
        if (pageProgress.configuration === 100) {
          setActiveTab('air handlers');
        } else {
          warningModal(proceedErrorMessages.configuration);
        }
        break;
      case 'accessories':
        if (pageProgress['air handlers'] === 100) {
          setActiveTab('equipment');
        } else {
          warningModal(proceedErrorMessages['air handlers']);
        }
        break;
      case 'quote':
        if (pageProgress.equipment === 100) {
          setActiveTab('accessories');
        } else {
          warningModal(proceedErrorMessages.equipment);
        }
        break;
      default:
        break;
    }
  };

  const handleQuoteButtonClick = () => {
    if (quotesToSend.length > 0) {
      setSendQuoteModalOpen(true);
    }
  };

  const handleDisabledQuoteButtonClick = () => {
    if (quotesToSend.length < 1) {
      warningModal('Select at least one quote to send');
    } else if (selectedSystems.filter(sys => quotesToSend.includes(sys.id)).every(sys => sys.quoteStatus === 'sent')) {
      warningModal('Quote has already been sent to customer');
    }
  };

  return (
    <>
      <div className='flex w-full justify-between border-t border-gray-200 bg-white px-5 py-3 relative'>
        <Button
          color='secondary'
          text='Back'
          onClick={prevTab}
          disabled={activeTab === 'description'}
        />
        {activeTab === 'quote' ? (
          <Button
            color='green'
            text={quoteButtonText}
            onClick={handleQuoteButtonClick}
            onDisabledClick={handleDisabledQuoteButtonClick}
            disabled={!selectedSystems.filter((sys) => quotesToSend.includes(sys.id)).some((sys) => sys.quoteStatus === 'unsent')}
          />
        ) : (
          <Button
            color='primary'
            text='Next'
            onClick={nextTab}
          />
        )}
        {activeTab === 'equipment' && (
          <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 inline-flex items-center gap-2 font-semibold'>
            <ProgressIndicator required checked={selectedSystems.length > 0} />
            <span>Systems Selected: {selectedSystems.length}/3</span>
          </div>
        )}
      </div>
      <ZipCodeModal openState={zipCodeModalOpenState} />
      <SendQuoteModal openState={sendQuoteModalOpenState} />
    </>
  );
}
