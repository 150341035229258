import React, { useContext, useEffect, useState } from 'react';
import { successModal, errorModal, confirmationModal } from 'utils/helpers';
import { AdminContext } from 'data/context';
import NumberInput from 'components/inputs/number';
import ToggleSwitch from 'components/inputs/toggle-switch';
import Button from 'components/inputs/button';
import axios from 'utils/api';
import AirHandler from 'components/admin/air-handler';
import { Item, AirHandlerTypeCode } from 'data/types';
import { useImmer } from 'use-immer';
import Checkbox from 'components/inputs/checkbox';
import AttributeComponent from 'components/attribute';
import Textarea from 'components/inputs/textarea';
import Select from 'components/inputs/select';
import { PlusIcon, XMarkIcon } from '@heroicons/react/20/solid';
import CreateSpaceModal from 'components/modals/create-space-modal';
import { Popover, PopoverTrigger, PopoverContent, PopoverClose } from 'components/popover';

export default function Spaces() {
  const {
    items,
    attributes,
    airHandlerTypes,
    fenestration,
    refreshData
  } = useContext(AdminContext);
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
  const [currentItem, setCurrentItem] = useImmer<Item | null>(null);
  const [airHandlerRankingChanged, setAirHandlerRankingChanged] = useState(false);
  const [createSpaceModalOpen, setCreateSpaceModalOpen] = useState(false);
  const [hoveredAttribute, setHoveredAttribute] = useState<number | null>(null);

  const setDefaultValues = () => {
    if (selectedItemId !== null && selectedItemId !== -1) {
      const newItem = structuredClone(items.find((s) => s.id === selectedItemId));
      if (newItem) {
        if (!newItem.allowedAirHandlerTypes.length) {
          newItem.allowedAirHandlerTypes = airHandlerTypes.map((t) => t.code);
        }
        if (!newItem.airHandlerRanking.length) {
          newItem.airHandlerRanking = airHandlerTypes.map((t) => t.code);
        }
        setCurrentItem(newItem);
      }
    } else if (selectedItemId === -1) {
      setCreateSpaceModalOpen(true);
      setSelectedItemId(null);
    } else {
      setCurrentItem(null);
    }
    setAirHandlerRankingChanged(false);
  };

  useEffect(() => {
    setDefaultValues();
  }, [selectedItemId]);

  const handleDefaultValueChange = (attributeId: number, value: any) => {
    setCurrentItem((draft) => {
      if (draft) {
        const attributeIndex = draft.attributes.findIndex((a) => a.id === attributeId);
        if (attributeIndex !== -1) {
          // eslint-disable-next-line no-param-reassign
          draft.attributes[attributeIndex].value = value;
        }
      }
    });
  };

  const handleFieldChange = (key: string, value: any) => {
    setCurrentItem((draft) => {
      if (draft) {
        // eslint-disable-next-line no-param-reassign
        draft[key] = value;
      }
    });
  };

  const handleRequiredChange = (attributeId: number, required: boolean) => {
    setCurrentItem((draft) => {
      if (draft) {
        const attributeIndex = draft.attributes.findIndex((a) => a.id === attributeId);
        if (attributeIndex !== -1) {
          // eslint-disable-next-line no-param-reassign
          draft.attributes[attributeIndex].required = required;
        }
      }
    });
  };

  const handleAssignmentChange = (attributeId: number, assigned: boolean) => {
    setCurrentItem((draft) => {
      if (draft) {
        const attributeIndex = draft.attributes.findIndex((a) => a.id === attributeId);
        if (attributeIndex !== -1 && !assigned) {
          // eslint-disable-next-line no-param-reassign
          draft.attributes.splice(attributeIndex, 1);
        } else if (attributeIndex === -1 && assigned) {
          const newAttribute = attributes.find((a) => a.id === attributeId);
          if (newAttribute) {
            draft.attributes.push({ ...newAttribute, required: false });
          }
        }
      }
    });
  };

  const handleAllowedAirHandlerTypeChange = (code: AirHandlerTypeCode, allowed: boolean) => {
    setCurrentItem((draft) => {
      if (draft) {
        if (allowed) {
          draft.allowedAirHandlerTypes?.push(code);
        } else {
          // eslint-disable-next-line no-param-reassign
          draft.allowedAirHandlerTypes = draft.allowedAirHandlerTypes?.filter((t) => t !== code) ?? null;
        }
      }
    });
  };

  const handleAddFenestration = (item: string) => {
    setCurrentItem((draft) => {
      if (draft) {
        draft.defaultFenestration.push(item);
      }
    });
  };

  const handleDeleteFenestration = (index: number) => {
    setCurrentItem((draft) => {
      if (draft) {
        // eslint-disable-next-line no-param-reassign
        draft.defaultFenestration = draft.defaultFenestration.filter((_, i) => i !== index);
      }
    });
  };

  const rerankAirHandler = (fromCode: AirHandlerTypeCode, toCode: AirHandlerTypeCode) => {
    setAirHandlerRankingChanged(true);
    setCurrentItem((draft) => {
      if (draft) {
        const fromIndex = draft.airHandlerRanking.indexOf(fromCode);
        const toIndex = draft.airHandlerRanking.indexOf(toCode);
        const [movingItem] = draft.airHandlerRanking.splice(fromIndex, 1);
        draft.airHandlerRanking.splice(toIndex, 0, movingItem);
      }
    });
  };

  const handleSave = async () => {
    if (currentItem) {
      try {
        await axios.patch('item', {
          item: airHandlerRankingChanged ? currentItem : { ...currentItem, airHandlerRanking: items.find((i) => i.id === currentItem.id)?.airHandlerRanking }
        });
        await refreshData();
        successModal(`${currentItem.label} updated.`);
      } catch (error) {
        errorModal('Something went wrong.');
      }
    } else {
      errorModal('Please select an item to save.');
    }
  };

  const handleReset = () => {
    confirmationModal('Are you sure you want to reset this item? All your changes will be lost!', () => {
      setDefaultValues();
    });
  };

  const handleDelete = () => {
    confirmationModal('Are you sure you want to delete this item?', async () => {
      if (selectedItemId) {
        try {
          await axios.delete(`items/${selectedItemId}`);
          await refreshData();
          successModal('Item deleted.');
          setSelectedItemId(null);
        } catch (error) {
          errorModal('Something went wrong.');
        }
      }
    });
  };

  return (
    <div className='mx-auto flex max-w-[1600px] flex-col gap-5'>
      <div className='text-3xl font-bold text-gray-900'>
        Space/Subspace Settings
      </div>
      <div className='flex gap-5'>
        <div className='flex flex-col gap-2'>
          <div className='font-semibold text-gray-900'>Space/Subspace</div>
          <Select
            onChange={(value) => setSelectedItemId(value !== '' ? Number(value) : null)}
            value={selectedItemId?.toString() ?? undefined}
            options={[
              ...items.map((item) => ({ label: `${item.label} (${item.group})`, value: item.id.toString() })),
              { label: '+ New Space', value: '-1' }
            ]}
          />
        </div>
        <div className='flex grow flex-col gap-5'>
          <div className='max-h-[50vh] overflow-auto'>
            <table className='min-w-full border-separate border-spacing-0 divide-y divide-gray-300'>
              <thead>
                <tr>
                  <th
                    scope='col'
                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-100 pb-3.5 pl-3 pr-0 text-left font-semibold text-gray-900'
                  />
                  <th
                    scope='col'
                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-100 px-3 pb-3.5 text-left font-semibold text-gray-900'
                  >
                    Attribute
                  </th>
                  <th
                    scope='col'
                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-100 px-3 pb-3.5 text-left font-semibold text-gray-900'
                  >
                    Default Value
                  </th>
                  <th
                    scope='col'
                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-100 px-3 pb-3.5 text-left font-semibold text-gray-900'
                  >
                    Required?
                  </th>
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200'>
                {attributes
                  .sort((a, b) => a.label.localeCompare(b.label))
                  .map((attribute) => (
                    <tr
                      key={attribute.id}
                      className={hoveredAttribute === attribute.id ? 'bg-gray-200' : ''}
                    >
                      <td className='whitespace-nowrap border-b border-gray-200 py-4 pl-3 pr-0 text-gray-900'>
                        <Checkbox
                          htmlId={`checkbox-${attribute.id}`}
                          value={!!currentItem?.attributes.find((a) => a.id === attribute.id)}
                          onChange={(checked) => handleAssignmentChange(attribute.id, checked)}
                          disabled={!currentItem}
                        />
                      </td>
                      <td
                        className={`whitespace-nowrap border-b border-gray-200 px-3 py-4 text-gray-900 ${!currentItem ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                        onClick={currentItem ?
                          () => handleAssignmentChange(attribute.id, !currentItem?.attributes.find((a) => a.id === attribute.id)) :
                          undefined
                        }
                        onMouseEnter={() => setHoveredAttribute(attribute.id)}
                        onMouseLeave={() => setHoveredAttribute(null)}
                      >
                        {attribute.label}
                      </td>
                      <td className='whitespace-nowrap border-b border-gray-200 px-3 py-4'>
                        <div className='inline-block'>
                          <AttributeComponent
                            key={attribute.id}
                            {...{ ...attribute }}
                            completed={!!attribute.value}
                            value={currentItem?.attributes.find((a) => a.id === attribute.id)?.value}
                            onChange={(value) => handleDefaultValueChange(attribute.id, value)}
                            showProgress={false}
                            disabled={!currentItem?.attributes.find((a) => a.id === attribute.id)}
                            showLabel={attribute.type !== 'checkbox'}
                          />
                        </div>
                      </td>
                      <td className='whitespace-nowrap border-b border-gray-200 px-3 py-4'>
                        <ToggleSwitch
                          value={currentItem?.attributes.find((a) => a.id === attribute.id)?.required}
                          onChange={(value) => handleRequiredChange(attribute.id, value)}
                          disabled={!currentItem?.attributes.find((a) => a.id === attribute.id)}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className='flex items-center gap-5'>
            <div className='font-semibold text-gray-900'>Description:</div>
            <Textarea
              value={currentItem?.description}
              onChange={(value) => handleFieldChange('description', value)}
              placeholder='Description...'
              disabled={!currentItem}
              rows={3}
            />
          </div>
          <div className='flex items-center gap-5'>
            <div className='font-semibold text-gray-900'>Default Sizes:</div>
            <div className='flex flex-col gap-1'>
              <span className='font-semibold text-gray-900'>Small:</span>
              <NumberInput
                onChange={(value) => handleFieldChange('smallSize', value)}
                value={currentItem?.smallSize ?? undefined}
                placeholder='Small sqft'
                disabled={!currentItem}
              />
            </div>
            <div className='flex flex-col gap-1'>
              <span className='font-semibold text-gray-900'>Medium:</span>
              <NumberInput
                onChange={(value) => handleFieldChange('mediumSize', value)}
                value={currentItem?.mediumSize ?? undefined}
                placeholder='Medium sqft'
                disabled={!currentItem}
              />
            </div>
            <div className='flex flex-col gap-1'>
              <span className='font-semibold text-gray-900'>Large:</span>
              <NumberInput
                onChange={(value) => handleFieldChange('largeSize', value)}
                value={currentItem?.largeSize ?? undefined}
                placeholder='Large sqft'
                disabled={!currentItem}
              />
            </div>
          </div>
          <div className='flex gap-5'>
            <div className='font-semibold text-gray-900'>
              Air Handler Ranking:
            </div>
            <div className='flex flex-wrap gap-3 border-2 border-dashed border-gray-300 p-3'>
              {(currentItem ? currentItem.airHandlerRanking : airHandlerTypes.map(t => t.code)).map((typeCode, index) => {
                const airHandlerType = airHandlerTypes.find((t) => t.code === typeCode);
                return airHandlerType ? (
                  <AirHandler
                    key={airHandlerType.id}
                    type={airHandlerType}
                    index={index}
                    rerankFn={rerankAirHandler}
                    disabled={!currentItem}
                  />
                ) : null;
              })}
            </div>
          </div>
          <div className='flex gap-5'>
            <div className='font-semibold text-gray-900'>
              Allowed Air Handler Types:
            </div>
            <div className='flex flex-wrap gap-3'>
              {airHandlerTypes.map((type) => (
                <label
                  key={type.id}
                  className={`flex items-center gap-1 ${!currentItem ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                >
                  <Checkbox
                    value={currentItem?.allowedAirHandlerTypes?.includes(type.code)}
                    onChange={(checked) => handleAllowedAirHandlerTypeChange(type.code, checked)}
                    disabled={!currentItem}
                  />
                  <span>{type.label}</span>
                </label>
              ))}
            </div>
          </div>
          <div className='flex gap-5'>
            <div className='font-semibold text-gray-900'>
              Default Fenestration:
            </div>
            <div className='flex flex-col gap-3'>
              <div className='flex flex-wrap gap-3'>
                {currentItem?.defaultFenestration.map((item, index) => (
                  <span
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    className='inline-flex items-center gap-1 rounded-md bg-gray-200 px-3 py-2 text-sm font-medium text-gray-700 whitespace-nowrap'
                  >
                    <button
                      type='button'
                      className='-ml-1 relative h-5 w-5 rounded-sm hover:bg-gray-500/20 inline-flex items-center justify-center'
                      onClick={() => handleDeleteFenestration(index)}
                    >
                      <XMarkIcon className='w-4 h-4' />
                    </button>
                    {fenestration.find((f) => f.code === item)?.label || 'Unknown Item'}
                  </span>
                ))}
                <Popover placement='bottom-start'>
                  <div>
                    <PopoverTrigger
                      className={`inline-flex items-center gap-1 justify-center rounded-md px-3 py-2 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 bg-blue-100 text-blue-900 hover:bg-blue-200 whitespace-nowrap ${!currentItem && 'opacity-[70%] cursor-not-allowed'}`}
                      disabled={!currentItem}
                    >
                      <PlusIcon className='w-4 h-4 -ml-1' />
                      <span>Add Glass/Doors</span>
                    </PopoverTrigger>
                  </div>
                  <PopoverContent className="flex flex-col p-2 w-56 rounded-lg bg-white shadow-lg ring-1 ring-black/5 focus:outline-none z-50">
                    {fenestration.map((item) => (
                      <PopoverClose
                        key={item.label}
                        onClick={() => handleAddFenestration(item.code)}
                        className='rounded-md w-full flex items-center gap-3 px-3 py-2 text-left text-sm font-semibold text-gray-900 hover:bg-gray-100'
                      >
                        <span>{item.label}</span>
                      </PopoverClose>
                    ))}
                  </PopoverContent>
                </Popover>
              </div>
            </div>
          </div>
          <label className='flex cursor-pointer items-center gap-2'>
            <span className='font-semibold'>Recommend oversizing?</span>
            <ToggleSwitch
              value={currentItem?.recommendOversizing}
              onChange={(value) => handleFieldChange('recommendOversizing', value)}
              disabled={!currentItem}
            />
          </label>
          <label className='flex cursor-pointer items-center gap-2'>
            <span className='font-semibold'>Can be in its own zone?</span>
            <ToggleSwitch
              value={currentItem?.canBeAlone}
              onChange={(value) => handleFieldChange('canBeAlone', value)}
              disabled={!currentItem}
            />
          </label>
          <label className='flex cursor-pointer items-center gap-2'>
            <span className='font-semibold'>Override air handler suggestion?</span>
            <ToggleSwitch
              value={currentItem?.airHandlerSuggestionOverride}
              onChange={(value) => handleFieldChange('airHandlerSuggestionOverride', value)}
              disabled={!currentItem}
            />
          </label>
          <label className='flex cursor-pointer items-center gap-2'>
            <span className='font-semibold'>Typically is an open floor plan?</span>
            <ToggleSwitch
              value={currentItem?.typicallySharesAir}
              onChange={(value) => handleFieldChange('typicallySharesAir', value)}
              disabled={!currentItem}
            />
          </label>
          <div className='flex justify-between'>
            <div className="flex gap-3">
              <Button
                text='Save'
                color='green'
                onClick={handleSave}
                disabled={!currentItem}
              />
              <Button
                text='Reset'
                color='red'
                onClick={handleReset}
                disabled={!currentItem}
              />
            </div>
            <Button
              text='Delete Space'
              color='red'
              onClick={handleDelete}
              disabled={!currentItem}
            />
          </div>
        </div>
      </div>
      <CreateSpaceModal openState={[createSpaceModalOpen, setCreateSpaceModalOpen]} />
    </div>
  );
}
