import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useState,
  ReactNode
} from 'react';
import { useImmer, Updater } from 'use-immer';
import {
  ActiveItem,
  SliderValues,
  ZoneSliderValues,
  AccessoryCategory,
  SliderRanges,
  SystemFilters,
  ActiveTab,
  PageProgress,
  Rebate,
  EasyModal
} from 'data/types';

type UIState = {
  activeTab: ActiveTab;
  setActiveTab: Dispatch<SetStateAction<ActiveTab>> | (() => void);
  pageProgress: PageProgress;
  setPageProgress: Updater<PageProgress> | (() => void);
  activeItem: ActiveItem | null;
  setActiveItem: Dispatch<SetStateAction<ActiveItem | null>> | (() => void);
  showPropertySummary: boolean;
  setShowPropertySummary: Dispatch<SetStateAction<boolean>> | (() => void);
  sliderValues: SliderValues,
  setSliderValues: Dispatch<SetStateAction<SliderValues>> | (() => void);
  defaultSliderValues: SliderValues,
  setDefaultSliderValues: Dispatch<SetStateAction<SliderValues>> | (() => void);
  zoneSliderValues: { [zoneId: string]: ZoneSliderValues; }
  setZoneSliderValues: Dispatch<SetStateAction<{ [zoneId: string]: ZoneSliderValues; }>> | (() => void);
  sliderRanges: SliderRanges;
  setSliderRanges: Updater<SliderRanges> | (() => void);
  systemFilters: SystemFilters;
  setSystemFilters: Dispatch<SetStateAction<SystemFilters>> | (() => void);
  showRankingSandbox: boolean;
  setShowRankingSandbox: Dispatch<SetStateAction<boolean>> | (() => void);
  showSettingsPanel: boolean;
  setShowSettingsPanel: Dispatch<SetStateAction<boolean>> | (() => void);
  showDetailsPanel: boolean;
  setShowDetailsPanel: Dispatch<SetStateAction<boolean>> | (() => void);
  preferredAirHandlerErrors: { zoneId: string, error: string }[];
  setPreferredAirHandlerErrors: Dispatch<SetStateAction<{ zoneId: string, error: string }[]>>
  | (() => void);
  activeAirHandlerPicker: string | null;
  setActiveAirHandlerPicker: Dispatch<SetStateAction<string | null>> | (() => void);
  activeCondenserPicker: string | null;
  setActiveCondenserPicker: Dispatch<SetStateAction<string | null>> | (() => void);
  activeAccessoryDrawer: AccessoryCategory | null;
  setActiveAccessoryDrawer: Dispatch<SetStateAction<AccessoryCategory | null>> | (() => void);
  debugMode: boolean;
  setDebugMode: Dispatch<SetStateAction<boolean>> | (() => void);
  rebatesForModal: Rebate[];
  setRebatesForModal: Dispatch<SetStateAction<Rebate[]>> | (() => void);
  estimatingStructure: boolean;
  setEstimatingStructure: Dispatch<SetStateAction<boolean>> | (() => void);
  easyMode: boolean;
  setEasyMode: Dispatch<SetStateAction<boolean>> | (() => void);
  easyModalsFinished: EasyModal[];
  setEasyModalsFinished: Dispatch<SetStateAction<EasyModal[]>> | (() => void);
};

export const defaultUIState: UIState = {
  activeTab: 'description',
  setActiveTab: () => { },
  pageProgress: {
    description: 0,
    layout: 0,
    configuration: 0,
    'air handlers': 0,
    condensers: 0,
    equipment: 0,
    accessories: 0,
    quote: 0
  },
  setPageProgress: () => { },
  activeItem: null,
  setActiveItem: () => { },
  showPropertySummary: false,
  setShowPropertySummary: () => { },
  sliderValues: {
    heatingComfort: 7,
    coolingComfort: 7,
    aesthetics: 3,
    installComplexity: 3,
    efficiency: 28
  },
  setSliderValues: () => { },
  defaultSliderValues: {
    heatingComfort: 7,
    coolingComfort: 7,
    aesthetics: 3,
    installComplexity: 3,
    efficiency: 28
  },
  setDefaultSliderValues: () => { },
  zoneSliderValues: {},
  setZoneSliderValues: () => { },
  sliderRanges: {
    heatingComfort: { min: 1, max: 7 },
    coolingComfort: { min: 1, max: 7 },
    aesthetics: { min: 1, max: 5 },
    installComplexity: { min: 1, max: 5 },
    efficiency: { min: 16, max: 38 }
  },
  setSliderRanges: () => { },
  systemFilters: {
    inStock: true,
    belowBudget: false,
    selectedAirHandlers: false,
    isOnSale: false
  },
  setSystemFilters: () => { },
  showRankingSandbox: false,
  setShowRankingSandbox: () => { },
  showSettingsPanel: false,
  setShowSettingsPanel: () => { },
  showDetailsPanel: false,
  setShowDetailsPanel: () => { },
  preferredAirHandlerErrors: [],
  setPreferredAirHandlerErrors: () => { },
  activeAirHandlerPicker: null,
  setActiveAirHandlerPicker: () => { },
  activeCondenserPicker: null,
  setActiveCondenserPicker: () => { },
  activeAccessoryDrawer: null,
  setActiveAccessoryDrawer: () => { },
  debugMode: false,
  setDebugMode: () => { },
  rebatesForModal: [],
  setRebatesForModal: () => { },
  estimatingStructure: false,
  setEstimatingStructure: () => { },
  easyMode: false,
  setEasyMode: () => { },
  easyModalsFinished: [],
  setEasyModalsFinished: () => { }
};

export const UIContext = createContext(defaultUIState);

export function UIContextProvider({ children }: { children: ReactNode }) {
  const [activeTab, setActiveTab] = useState(defaultUIState.activeTab);
  const [pageProgress, setPageProgress] = useImmer(defaultUIState.pageProgress);
  const [activeItem, setActiveItem] = useState(defaultUIState.activeItem);
  const [showPropertySummary, setShowPropertySummary] = useState(defaultUIState.showPropertySummary);
  const [sliderValues, setSliderValues] = useState(defaultUIState.sliderValues);
  const [defaultSliderValues, setDefaultSliderValues] = useState(defaultUIState.defaultSliderValues);
  const [zoneSliderValues, setZoneSliderValues] = useState(defaultUIState.zoneSliderValues);
  const [sliderRanges, setSliderRanges] = useImmer(defaultUIState.sliderRanges);
  const [systemFilters, setSystemFilters] = useState(defaultUIState.systemFilters);
  const [showRankingSandbox, setShowRankingSandbox] = useState(defaultUIState.showRankingSandbox);
  const [showSettingsPanel, setShowSettingsPanel] = useState(defaultUIState.showSettingsPanel);
  const [showDetailsPanel, setShowDetailsPanel] = useState(defaultUIState.showDetailsPanel);
  const [preferredAirHandlerErrors, setPreferredAirHandlerErrors] = useState(defaultUIState.preferredAirHandlerErrors);
  const [activeAirHandlerPicker, setActiveAirHandlerPicker] = useState(defaultUIState.activeAirHandlerPicker);
  const [activeCondenserPicker, setActiveCondenserPicker] = useState(defaultUIState.activeCondenserPicker);
  const [activeAccessoryDrawer, setActiveAccessoryDrawer] = useState(defaultUIState.activeAccessoryDrawer);
  const [debugMode, setDebugMode] = useState(defaultUIState.debugMode);
  const [rebatesForModal, setRebatesForModal] = useState(defaultUIState.rebatesForModal);
  const [estimatingStructure, setEstimatingStructure] = useState(defaultUIState.estimatingStructure);
  const [easyMode, setEasyMode] = useState(defaultUIState.easyMode);
  const [easyModalsFinished, setEasyModalsFinished] = useState(defaultUIState.easyModalsFinished);

  return (
    <UIContext.Provider value={{
      activeTab,
      setActiveTab,
      pageProgress,
      setPageProgress,
      activeItem,
      setActiveItem,
      showPropertySummary,
      setShowPropertySummary,
      sliderValues,
      setSliderValues,
      defaultSliderValues,
      setDefaultSliderValues,
      zoneSliderValues,
      setZoneSliderValues,
      sliderRanges,
      setSliderRanges,
      systemFilters,
      setSystemFilters,
      showRankingSandbox,
      setShowRankingSandbox,
      showSettingsPanel,
      setShowSettingsPanel,
      showDetailsPanel,
      setShowDetailsPanel,
      preferredAirHandlerErrors,
      setPreferredAirHandlerErrors,
      activeAirHandlerPicker,
      setActiveAirHandlerPicker,
      activeCondenserPicker,
      setActiveCondenserPicker,
      activeAccessoryDrawer,
      setActiveAccessoryDrawer,
      debugMode,
      setDebugMode,
      rebatesForModal,
      setRebatesForModal,
      estimatingStructure,
      setEstimatingStructure,
      easyMode,
      setEasyMode,
      easyModalsFinished,
      setEasyModalsFinished
    }}
    >
      {children}
    </UIContext.Provider>
  );
}
