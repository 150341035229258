import React, { useEffect, useState } from 'react';
import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';

type YesNoButtonProps = {
  defaultState?: 0 | 1 | -1;
  onYesClick?: () => void;
  onNoClick?: () => void;
};

export default function YesNoButton({
  defaultState = 0,
  onYesClick,
  onNoClick
}: YesNoButtonProps) {
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    setState(defaultState);
  }, [defaultState]);

  const buttonClasses =
    'inline-flex items-center gap-1 rounded-md px-3 py-2 text-sm font-medium leading-4 shadow-sm border border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2';

  const yesButtonClasses = clsx(
    buttonClasses,
    state === 1
      ? 'bg-green-500 text-white'
      : 'bg-blue-100 hover:bg-blue-200 text-gray-900'
  );

  const noButtonClasses = clsx(
    buttonClasses,
    state === -1
      ? 'bg-red-500 text-white'
      : 'bg-blue-100 hover:bg-blue-200 text-gray-900'
  );

  const yesIconClasses = clsx(
    'w-4 h-4 rounded-full border-2 flex items-center justify-center',
    state === 1 ? 'border-white bg-white' : 'border-gray-500'
  );

  const noIconClasses = clsx(
    'w-4 h-4 rounded-full border-2 flex items-center justify-center',
    state === -1 ? 'border-white bg-white' : 'border-gray-500'
  );

  const handleYesClick = () => {
    if (state !== 1) {
      setState(1);
      onYesClick?.();
    }
  };

  const handleNoClick = () => {
    if (state !== -1) {
      setState(-1);
      onNoClick?.();
    }
  };

  return (
    <div className='flex gap-2'>
      <button
        type='button'
        className={yesButtonClasses}
        onClick={handleYesClick}
      >
        <div className={yesIconClasses}>
          {state === 1 && <CheckIcon className='h-3 w-3 text-green-500' />}
        </div>
        <span>Yes</span>
      </button>
      <button
        type='button'
        className={noButtonClasses}
        onClick={handleNoClick}
      >
        <div className={noIconClasses}>
          {state === -1 && <XMarkIcon className='h-3 w-3 text-red-500' />}
        </div>
        <span>No</span>
      </button>
    </div>
  );
}

YesNoButton.defaultProps = {
  defaultState: 0,
  onYesClick: () => { },
  onNoClick: () => { }
};
