import React, { useState, useContext, useEffect } from 'react';
import OpenAI from 'openai';
import { confirmationModal } from 'utils/helpers';
import TalkingPoint from 'components/talking-point';
import ProgressIndicator from 'components/progress-indicator';
import Button from 'components/inputs/button';
import ZipCodeModal from 'components/modals/zip-code-modal';
import axios, { getLocationData } from 'utils/api';
import LiveChatModal from 'components/modals/live-chat-modal';
import Select from 'components/inputs/select';
import { ProjectContext, DataContext, ConfigurationContext, UIContext } from 'data/context';
import StructureTypeSelector from 'components/structure-type-selector';
import StructureSubypeSelector from 'components/structure-subtype-selector';
import AddressInfo from 'components/address-info';
import { BookOpenIcon } from '@heroicons/react/24/outline';
import Textarea from 'components/inputs/textarea';
import DescriptionModal from 'components/modals/description-modal';

type ProjectDescriptionProps = {
  loading: boolean;
};

export default function ProjectDescription({ loading }: ProjectDescriptionProps) {
  const {
    structureTypes,
  } = useContext(DataContext);
  const {
    structure,
    configurations,
    selectedSystems
  } = useContext(ConfigurationContext);
  const {
    attomData,
    addressSearched,
    locationData,
    setLocationData,
    structureTypeSelected,
    structureSubtypeSelected,
    partialStructure,
    setPartialStructure,
    projectDetails,
    warmUpNotes,
    setWarmUpNotes,
    liveChatData,
    goalStatement,
    setGoalStatement,
    setAttomData,
    projectSettings,
    setProjectSettings,
    customerValues,
    setCustomerValues
  } = useContext(ProjectContext);
  const { easyModalsFinished } = useContext(UIContext);
  const zipCodeModalState = useState(false);
  const [, setZipCodeModalShown] = zipCodeModalState;
  const liveChatModalState = useState(false);
  const [, setLiveChatModalShown] = liveChatModalState;
  const descriptionModalState = useState(true);
  const [, setDescriptionModalShown] = descriptionModalState;
  const [structureImageUrl, setStructureImageUrl] = useState<string | null>(null);
  const [structureTypeLoading, setStructureTypeLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (!loading && !easyModalsFinished.includes('Description')) {
      setDescriptionModalShown(true);
    }
  }, [loading, easyModalsFinished]);

  useEffect(() => {
    let timeout;
    if (attomData !== null && !structureTypeSelected && !structureSubtypeSelected) {
      setStructureTypeLoading(true);
      timeout = setTimeout(() => setStructureTypeLoading(false), 5000);
    }
    return () => clearTimeout(timeout);
  }, [attomData]);

  useEffect(() => {
    setStructureTypeLoading(false);
  }, [structureTypeSelected, structureSubtypeSelected]);

  useEffect(() => {
    if (structureTypes && structureSubtypeSelected) {
      const subtypeCode = structureTypes
        .find((t) => t.id === structureTypeSelected)
        ?.subtypes.find((s) => s.id === structureSubtypeSelected)?.code;
      if (subtypeCode) {
        const exteriorUrl = `https://images.alpinehomeair.com/400x400/uwm/nws/photos/system-selector-structures/${subtypeCode}-exterior.jpg`;
        const interiorUrl = `https://images.alpinehomeair.com/400x400/uwm/nws/photos/system-selector-structures/${subtypeCode}-interior.jpg`;
        axios.get(exteriorUrl, { withCredentials: false })
          .then(() => setStructureImageUrl(exteriorUrl))
          .catch(() => setStructureImageUrl(interiorUrl));
      }
    } else {
      setStructureImageUrl(null);
    }
  }, [structureSubtypeSelected, structureTypes]);

  const generateGoalStatement = async () => {
    if (warmUpNotes && !attomData && !goalStatement) {
      setGoalStatement('Loading...');
      const messages: OpenAI.Chat.Completions.ChatCompletionMessageParam[] = [
        {
          role: 'system',
          content: `Here are the consultants warm up notes for the current project: ${JSON.stringify(
            warmUpNotes
          )}`
        },
        {
          role: 'user',
          content:
            'Write a very short goal statement for the consultant that informs the customer we are going to design a HVAC system tailored to their specific needs and also prompts the customer for their address. Dont include any extra text besides the statement itself.'
        }
      ];
      try {
        const reply = (await axios.post(
          'ai',
          messages
        )).data;
        setGoalStatement(reply.content);
      } catch (err) {
        console.error(err);
        setGoalStatement("I'd like to ask you some questions about your project and then I can get a quote or order sent out to you.Can I start by getting the address of the property we're going to be building the system for?");
      }
    }
  };

  const handleChangeSettings = (attributeCode, value) => {
    setProjectSettings((draft) => {
      const updatedSettings = draft.map(setting =>
        setting.code === attributeCode ? { ...setting, value } : setting
      );
      return updatedSettings;
    });
  };

  const handleChangeCustomerValues = (attributeCode, value) => {
    setCustomerValues((draft) => {
      const updatedCustomerValues = draft.map(valueObj =>
        valueObj.code === attributeCode ? { ...valueObj, value } : valueObj
      );
      return updatedCustomerValues;
    });
  };

  const updateSettingsAndValues = (data) => {
    if (projectSettings.length === 0 || customerValues.length === 0) {
      return;
    }

    Object.keys(data).forEach((key) => {
      if (data[key] !== null) {
        const projectSetting = projectSettings.find(ps => ps.code === key);
        const customerValue = customerValues.find(cv => cv.code === key);

        if (projectSetting && (projectSetting.value === null || projectSetting.value === undefined || projectSetting.value === '')) {
          handleChangeSettings(key, data[key]);
        } else if (customerValue && (customerValue.value === null || customerValue.value === undefined || customerValue.value === '')) {
          handleChangeCustomerValues(key, data[key]);
        }
      }
    });
  };

  const extractKeyLiveChatData = async () => {
    if (liveChatData) {
      const messages: OpenAI.Chat.Completions.ChatCompletionMessageParam[] = [
        {
          role: 'system',
          content:
            ` I extract home structure information (such as home size, bedrooms, etc), project settings, and customer preferences based off of liveChatData between a customer and a sales rep.
            Specifically, I scan the chat and extract any of the following information if it's available then return it in a JSON object (no markdown syntax) with keys and values that match the below object:
            {
              "ceiling_height": number | null,
              "square_footage": number | null,
              "vaulted_height": number | null,
              "sloped_height": number | null,
              (if a desired target heating temperature is mentioned) "desired_heating": number | null,
              (if a desired target cooling temperature is mentioned) "desired_cooling": number | null,
              "ductwork": "Full Ductwork" | "Partial Ductwork" | "No Ductwork" | null,
              "insulation_level": "Poor" | "Good" | "Great" | null,
              "ceiling_type": "Flat" | "Vaulted" | "Sloped" | "Drop Ceiling" | null, 
              (only populate if customer mentions they have joists in their ceiling) "joist_width": '16"', '24"', 'Unknown',
              (if customer mentions primary, supplemental, or no heating desired) "heating": 'Primary' | 'Supplemental' | 'None' | null,
              (if customer mentions primary, supplemental, or no cooling desired) "cooling": 'Primary' | 'Supplemental' | 'None' | null,
              "ceiling_fan": 'Yes' | 'No' | null,
              "max_budget": number | null,
              "installation_plan": 'Self Installation' | 'Professional Installation' | 'Split Self and Professional Installation' | null,
              "installation_difficulty": 'Simple Installation Preferred' | 'Okay with Moderately Difficult Installation' | 'Okay with Challenging Installation' | null,
              "aesthetic_preference": 'Hidden' | 'Visible' | 'No Preference' | null,
              "savings_preference": Savings Up Front' | 'Optimal Efficiency' | 'Balanced System' | null, 
              "wifi_control_desired": 'Yes' | 'No' | 'No Preference' | null,
              "project_type": "Designing New System" | "Replacing Old System" | "Adding Partial System" | null,
              "property_type": "Primary Residence" | "Rental Property" | "Secondary Property" | "Other" | null
            }
          `
        },
        {
          role: 'user',
          content:
            `Please see the following live chat data and respond with any home / structure, project settings, and customer preferences information in a JSON object, no markdown:
            ${JSON.stringify(liveChatData.transcript.map(({ author, chatText }) => ({ author, chatText })))}`
        }
      ];
      try {
        const reply = (await axios.post(
          'ai',
          messages
        )).data;
        const parsedData = JSON.parse(reply.content);
        updateSettingsAndValues(parsedData);
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    if (projectSettings.length > 0 && customerValues.length > 0) {
      setDataLoaded(true);
    }
  }, [projectSettings, customerValues]);

  useEffect(() => {
    if (dataLoaded) {
      extractKeyLiveChatData();
    }
  }, [dataLoaded]);

  useEffect(() => {
    generateGoalStatement();
  }, [warmUpNotes, attomData, goalStatement]);

  const handleChangeLocationData = () => {
    const openZipCodeModal = () => {
      setZipCodeModalShown(true);
    };
    if (structure.length === 0 && configurations.length === 0 && selectedSystems.length === 0) {
      openZipCodeModal();
    } else {
      confirmationModal('Are you sure you want to change the zip code? This will reset your configurations and selected systems!', openZipCodeModal);
    }
  };

  const handleResetLocationData = () => {
    const resetLocationData = () => {
      if (projectDetails?.postal) {
        getLocationData(projectDetails.postal).then((res) => setLocationData(res.data ?? null));
      } else {
        setLocationData(null);
      }
      setAttomData(null);
    };
    if (structure.length === 0 && configurations.length === 0 && selectedSystems.length === 0) {
      resetLocationData();
    } else {
      confirmationModal('Are you sure you want to reset the zip code? This will reset your configurations and selected systems!', resetLocationData);
    }
  };

  return (
    <div className='mx-auto flex max-w-[1000px] flex-col gap-5'>
      <div className='text-3xl font-bold text-gray-900'>Project Details</div>
      <div className='flex gap-5'>
        <div className={`flex grow items-center justify-between gap-4 rounded-lg bg-white p-4 ${!locationData && 'border-2 border-red-500'}`}>
          <div className='flex flex-col gap-2'>
            <span className='text-xs font-semibold uppercase text-gray-500'>
              Location
            </span>
            <span className='font-semibold text-gray-800'>
              {locationData?.location ?? '-'}
            </span>
          </div>
          <div className='flex flex-col gap-2'>
            {!attomData && (
              <Button color='secondary' text='Change' onClick={handleChangeLocationData} />
            )}
            {projectDetails?.postal && locationData?.zipcode !== projectDetails.postal && (
              <Button color='secondary' text='Reset' onClick={handleResetLocationData} />
            )}
          </div>
        </div>
        {liveChatData && (
          <div className='grow rounded-lg bg-white p-4'>
            <div className='flex flex-col gap-2'>
              <span className='whitespace-nowrap text-xs font-semibold uppercase text-gray-500'>
                Live Chat
              </span>
              <Button color='secondary' text='View' onClick={() => setLiveChatModalShown(true)} />
            </div>
          </div>
        )}
        <div className='grow rounded-lg bg-white p-4'>
          <div className='flex flex-col gap-2'>
            <span className='whitespace-nowrap text-xs font-semibold uppercase text-gray-500'>
              Number of Systems
            </span>
            <span className='font-semibold text-gray-800'>
              {projectDetails?.number_of_systems ?? '-'}
            </span>
          </div>
        </div>
        <div className='grow rounded-lg bg-white p-4'>
          <div className='flex flex-col gap-2'>
            <span className='whitespace-nowrap text-xs font-semibold uppercase text-gray-500'>
              Must Receive By
            </span>
            <span className='font-semibold text-gray-800'>
              {projectDetails?.due ?? '-'}
            </span>
          </div>
        </div>
        <div className='grow rounded-lg bg-white p-4'>
          <div className='flex flex-col gap-2'>
            <span className='whitespace-nowrap text-xs font-semibold uppercase text-gray-500'>
              Timeframe
            </span>
            <span className='font-semibold text-gray-800'>
              {projectDetails?.urgency ?? '-'}
            </span>
          </div>
        </div>
        <div className='grow rounded-lg bg-white p-4'>
          <div className='flex flex-col gap-2'>
            <span className='whitespace-nowrap text-xs font-semibold uppercase text-gray-500'>
              Product Line
            </span>
            <span className='font-semibold text-gray-800'>
              {projectDetails?.productLine ?? '-'}
            </span>
          </div>
        </div>
      </div>
      <div className='flex flex-col items-center'>
        <div className='flex w-full flex-col gap-2 rounded-lg bg-white p-5'>
          <div className='text-xl font-bold text-gray-900'>
            {projectDetails
              ? projectDetails?.jobName ||
              `Project #${projectDetails.projectId}`
              : 'New Mini-Split System'}
          </div>
          <div className='text-gray-600'>
            {projectDetails?.description ?? ''}
          </div>
        </div>
      </div>
      <div className='flex flex-col gap-2 rounded-lg bg-white p-5'>
        <div className='flex items-center gap-2'>
          <ProgressIndicator checked={!!warmUpNotes} />
          <span className='text-lg font-bold text-gray-800'>Notes</span>
          <BookOpenIcon className='h-5 w-5' />
        </div>
        <Textarea
          rows={5}
          value={warmUpNotes}
          onChange={(value) => setWarmUpNotes(value ?? '')}
          placeholder='Notes...'
          debounce={500}
        />
      </div>
      <div className='rounded-lg bg-white p-5'>
        <div className='flex w-full flex-col gap-2'>
          <div className='flex items-center gap-2'>
            <ProgressIndicator
              checked={!!attomData}
              required={
                (structureTypes
                  .find((t) => t.id === structureTypeSelected)
                  ?.subtypes.find((st) => st.id === structureSubtypeSelected)
                  ?.addressRequired ?? false) && !addressSearched
              }
            />
            <span className='text-lg font-bold text-gray-800 capitalize'>
              {attomData ? `${attomData.address.line1?.toLowerCase()}, ${attomData.address.locality?.toLowerCase()}, ${attomData.address.countrySubd} ${attomData.address.postal1}` : 'Address'}
            </span>
          </div>
          <div className='w-1/2'>
            <AddressInfo />
          </div>
        </div>
      </div>
      {!structureSubtypeSelected && (
        <TalkingPoint>
          One moment while I select your structure type...
        </TalkingPoint>
      )}
      <div className='flex gap-5 rounded-lg bg-white p-5'>
        <div className='flex w-1/2 flex-col gap-5'>
          <div className='flex w-full flex-col gap-2'>
            <div className='flex items-center gap-2'>
              <ProgressIndicator required checked={!!structureTypeSelected} />
              <span className='text-lg font-bold text-gray-800'>
                Structure Type
              </span>
              {structureTypeLoading && <span className='text-sm text-gray-500'>Loading...</span>}
            </div>
            <StructureTypeSelector />
          </div>
          <div className='flex w-full flex-col gap-2'>
            <div className='flex items-center gap-2'>
              <ProgressIndicator
                required
                checked={!!structureSubtypeSelected}
              />
              <span className='text-lg font-bold text-gray-800'>
                {structureTypeSelected
                  ? `${structureTypes.find((s) => s.id === structureTypeSelected)
                    ?.label || 'Unknown'
                  } Type`
                  : 'Structure Subtype'}
              </span>
              {structureTypeLoading && <span className='text-sm text-gray-500'>Loading...</span>}
            </div>
            <StructureSubypeSelector />
          </div>
          {structureTypes
            .find((t) => t.id === structureTypeSelected)
            ?.subtypes.find((s) => s.id === structureSubtypeSelected)
            ?.partialEnabled && (() => {
              const structureTypeLabel = structureTypes
                .find((t) => t.id === structureTypeSelected)
                ?.label.toLowerCase();
              const structureSubtypeLabel = structureTypes
                .find((t) => t.id === structureTypeSelected)
                ?.subtypes.find((s) => s.id === structureSubtypeSelected)
                ?.label.toLowerCase();
              const labelContainsHomeOrHouse =
                structureTypeLabel?.includes('home') || structureTypeLabel?.includes('house') ||
                structureSubtypeLabel?.includes('home') || structureSubtypeLabel?.includes('house');

              return (
                <div className='flex w-full flex-col gap-2'>
                  <div className='flex items-center gap-2'>
                    <ProgressIndicator required checked={partialStructure !== null} />
                    <span className='text-lg font-bold text-gray-800'>
                      {labelContainsHomeOrHouse ? 'Full or Partial Home?' : 'Full or Partial Structure?'}
                    </span>
                  </div>
                  <Select
                    // eslint-disable-next-line no-nested-ternary
                    value={partialStructure === null ? '' : partialStructure ? 'Yes' : 'No'}
                    onChange={(value) => setPartialStructure(value === '' ? null : value === 'Yes')}
                    options={[
                      {
                        value: 'No',
                        label: labelContainsHomeOrHouse ? 'Full Home' : 'Full Structure'
                      },
                      {
                        value: 'Yes',
                        label: labelContainsHomeOrHouse ? 'Partial Home' : 'Partial Structure',
                      }
                    ]}
                    enableChips
                  />
                </div>
              );
            })()}
        </div>
        <div className='relative h-[120px] w-[200px]'>
          {structureSubtypeSelected && (
            <img
              className='rounded-lg'
              src={structureImageUrl || undefined}
              alt='Example of the structure type'
            />
          )}
        </div>
        <div className='flex w-1/2 flex-col gap-1'>
          <div className='text-sm font-semibold'>
            {structureSubtypeSelected
              ? structureTypes
                .find((t) => t.id === structureTypeSelected)
                ?.subtypes.find((s) => s.id === structureSubtypeSelected)
                ?.label
              : ''}
          </div>
          <div className='text-sm'>
            {structureSubtypeSelected
              ? structureTypes
                .find((t) => t.id === structureTypeSelected)
                ?.subtypes.find((s) => s.id === structureSubtypeSelected)
                ?.description
              : ''}
          </div>
        </div>
      </div>
      <ZipCodeModal openState={zipCodeModalState} />
      <LiveChatModal openState={liveChatModalState} />
      <DescriptionModal
        openState={descriptionModalState}
        structureTypeLoading={structureTypeLoading}
        structureImageUrl={structureImageUrl ?? undefined}
      />
    </div>
  );
}
