import React, { useContext, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { ConfigurationContext } from 'data/context';
import TalkingPoint from 'components/talking-point';
import RebatesModal from 'components/modals/rebates-modal';
import QuoteColumn from 'components/quote-column';
import Button from 'components/inputs/button';

export default function Quote() {
  const { selectedSystems } = useContext(ConfigurationContext);
  const rebatesModalState = useState(false);
  const [, setRebatesModalOpen] = rebatesModalState;
  const [referenceTableIndex, setReferenceTableIndex] = useState<number | null>(null);
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [, setOptionIndex] = useState<number | null>(null);

  const whatsIncluded = [
    'Product Warranty',
    'Free Warranty Processing',
    'Unlimited Lifetime Tech Support',
    '30 Day Money Back Guarantee',
    'Free Shipping'
  ];

  const handleQuoteColumnClick = (systemIndex: number) => {
    setReferenceTableIndex(systemIndex);
    setRebatesModalOpen(true);
  };

  return (
    <div className='flex max-w-[1770px] flex-col gap-5 mx-auto'>
      <div className='text-3xl font-bold text-gray-900'>Project Quote</div>
      <TalkingPoint>
        <div className='flex w-full flex-col gap-2'>
          <div className='font-semibold'>
            What&apos;s included with your system?
          </div>
          <ul className='flex w-full flex-wrap list-disc gap-3 pt-1'>
            {whatsIncluded.map((bullet) => (
              <li key={bullet} className='ml-5 w-1/4 min-w-[200px] pr-3'>
                {bullet}
              </li>
            ))}
          </ul>
        </div>
      </TalkingPoint>
      <div className='flex gap-5'>
        {selectedSystems.map((system, systemIndex: number | null) => (
          <div
            key={system.id}
            className='max-w-[33%] min-w-[500px] flex-shrink grow basis-1/3'
          >
            <QuoteColumn
              system={system}
              setRebatesModalOpen={setRebatesModalOpen}
              referenceTableIndex={referenceTableIndex}
              setReferenceTableIndex={setReferenceTableIndex}
              optionIndex={systemIndex}
              setOptionIndex={setOptionIndex}
              showAdvanced={showMoreInfo}
              systemIndex={systemIndex ?? 0}
              handleQuoteColumnClick={handleQuoteColumnClick}
            />
          </div>
        ))}
      </div>
      <div className='w-full flex justify-center'>
        <Button
          color='subtle'
          text={showMoreInfo ? 'Less Info' : 'More Info'}
          icon={showMoreInfo ? ChevronUpIcon : ChevronDownIcon}
          iconSize='md'
          iconPosition='right'
          onClick={() => setShowMoreInfo((prev) => !prev)}
        />
      </div>
      {referenceTableIndex !== null && (
        <RebatesModal openState={rebatesModalState} system={selectedSystems[referenceTableIndex]} />
      )}
    </div>
  );
}
