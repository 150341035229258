import React, { useContext, useState, Fragment, useEffect, Dispatch, SetStateAction, useRef } from 'react';
import { ConfigurationContext, ProjectContext, DataContext } from 'data/context';
import { AirHandlerProduct, ComparisonFeature, CondenserProduct, SelectedSystem, SystemOption } from 'data/types';
import { createSystemOption, errorModal, warningModal, notification, sortSystemOptions, getComparisonValue, abbreviateNumber, formatUSD, getCoverageTooltipData } from 'utils/helpers';
import Button from 'components/inputs/button';
import Tag from 'components/tag';
import { CheckIcon, ChevronDownIcon, ChevronUpIcon, ArrowPathIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import Tooltip from 'components/tooltip';
import SystemMap from 'components/system-map';
import { Popover, PopoverTrigger, PopoverContent, PopoverClose } from 'components/popover';
import CoverageIndicator from 'components/coverage-indicator';
import InternalNote from 'components/internal-note';
import SystemsTable from 'components/systems-table';
import { v4 as uuid } from 'uuid';
import Text from 'components/inputs/text';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import CondenserMap from 'components/condenser-map';
import { calculateOptimalDesignScore } from 'utils/ranking';
import StatDifference from './stat-difference';
import ProductLink from './product-link';
import AirHandlerCell from './air-handler-cell';
import ProgressIndicator from './progress-indicator';

type EquipmentTableProps = {
  advancedMode: boolean;
  setAdvancedMode: Dispatch<SetStateAction<boolean>>
};

export default function EquipmentTable({ advancedMode, setAdvancedMode }: EquipmentTableProps) {
  const {
    configurations,
    activeConfig,
    setActiveConfig,
    selectedSystems,
    setSelectedSystems
  } = useContext(ConfigurationContext);
  const {
    systemOptions,
    setSystemOptions,
    systemsForNewOption,
    setSystemsForNewOption,
    customerValues
  } = useContext(ProjectContext);
  const {
    airHandlerProducts,
    condenserProducts,
    ductingKitProducts
  } = useContext(DataContext);
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [referenceTableIndex, setReferenceTableIndex] = useState<number | null>(null);
  const [activeOptionIndex, setActiveOptionIndex] = useState<number | null>(null);
  const [trackingIds, setTrackingIds] = useState<string[]>([uuid(), uuid(), uuid()]);
  const [renaming, setRenaming] = useState<number | null>(null);
  const [newName, setNewName] = useState('');
  const [expandedIndex, setExpandedIndex] = useState<number | null>(0);
  const [hasResetIndex, setHasResetIndex] = useState(false);
  const drawerRefs = useRef<(HTMLDivElement | null)[]>([]);
  const toggleOpen = (index: number) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  const handleSelectSystem = (index: number) => {
    const allSelected = configurations[activeConfig].condensers.every((condenser) =>
      systemsForNewOption.some(s => s.condenserUnique === condenser.uniqueId)
    );
    if (allSelected) {
      setExpandedIndex(0);
    } else {
      toggleOpen(index);
    }
  };

  const renameSizeLimit = 25;
  const comparisonFeatures: ComparisonFeature[] = [
    'Price',
    'Potential Rebates',
    'Price After Rebates',
    'Heating Coverage',
    'Cooling Coverage',
    'Optimal Design Score',
    'Amperage',
    'Average Efficiency',
    'Total Units to Install',
    'Indoor Visibility',
    'Outdoor Visibility',
    'Installation Difficulty',
    'Indoor Units',
    'Outdoor Units',
  ];
  const advancedFeatures: ComparisonFeature[] = [
    'Indoor Units',
    'Outdoor Units',
    'Potential Rebates',
    'Amperage'
  ];
  const featuresToDisplay = comparisonFeatures.filter(feature =>
    !advancedFeatures.includes(feature) || showMoreInfo
  );

  const handleOptionSelect = (id: string) => {
    if (!selectedSystems.some((s) => s.uniqueId === id)) {
      const system = systemOptions.find((o) => o.uniqueId === id);
      if (system) {
        const newSelectedSystem: SelectedSystem = {
          ...system,
          configuration: {
            ...system.configuration,
            condensers: system.configuration.condensers.map(cond => ({
              ...cond,
              validSystems: [],
              rankedSystems: []
            }))
          },
          selectedAccessories: [],
          quotableAccessories: [],
          accessoriesCompleted: [],
          accessoriesNotNeeded: [],
          quoteStatus: 'unsent'
        };
        setSelectedSystems((draft) => [...draft, newSelectedSystem]);
      } else {
        console.error(`System ${id} not found`);
        errorModal(`System ${id} not found`);
      }
    } else {
      setSelectedSystems((draft) => draft.filter((s) => s.uniqueId !== id));
    }
  };

  const handleChangeSystem = (systemOption: SystemOption, index: number) => {
    setSystemOptions((draft) => {
      const fromIndex = draft.findIndex((o) => o.uniqueId === systemOption.uniqueId);
      if (fromIndex !== -1) {
        const temp = draft[fromIndex];
        // eslint-disable-next-line no-param-reassign
        draft[fromIndex] = draft[index];
        // eslint-disable-next-line no-param-reassign
        draft[index] = temp;
      }
    });
    setTrackingIds((prev) => prev.map((id, i) => i === index ? uuid() : id));
  };

  useEffect(() => {
    if (advancedMode && !hasResetIndex) {
      setExpandedIndex(0);
      setHasResetIndex(true);
    } else if (!advancedMode) {
      setActiveOptionIndex(null);
      setHasResetIndex(false);
      setExpandedIndex(0);
    }
  }, [advancedMode]);

  useEffect(() => {
    if (activeOptionIndex !== null && systemsForNewOption.length === configurations[activeConfig].condensers.length) {
      let systemNumber = 1;
      // eslint-disable-next-line @typescript-eslint/no-loop-func
      while (systemOptions.some((o) => o.configuration.uniqueId === configurations[activeConfig].uniqueId && o.name === `Custom System ${systemNumber}`)) {
        systemNumber += 1;
      }
      const configScore = configurations.find((c) => c.uniqueId === configurations[activeConfig].uniqueId)?.score ?? 1;
      // console.log({ configScore });
      const systemsOptimalDesignScore = calculateOptimalDesignScore(systemsForNewOption, configScore);
      const newSystemOption = createSystemOption(
        systemsForNewOption,
        configurations[activeConfig],
        condenserProducts,
        airHandlerProducts,
        ductingKitProducts,
        `Custom System ${systemNumber}`,
        ['Custom'],
        undefined,
        systemsOptimalDesignScore
      );
      setSystemOptions((draft) => {
        const currentSystem = draft.splice(activeOptionIndex, 1, newSystemOption)[0];
        draft.push(currentSystem);
      });
      setSystemsForNewOption([]);
      setAdvancedMode(false);
      notification('System option added', 'success');
    }
  }, [JSON.stringify(systemsForNewOption)]);

  useEffect(() => {
    if (!advancedMode) {
      setActiveOptionIndex(null);
    }
  }, [advancedMode]);

  const handleNewSystemClick = (optionIndex: number, configId: string) => {
    setActiveConfig(configurations.findIndex((c) => c.uniqueId === configId));
    setActiveOptionIndex(optionIndex);
    setSystemsForNewOption([]);
    setAdvancedMode(true);
  };

  const handleRenameClick = (optionIndex: number) => {
    setRenaming(optionIndex);
    setNewName(systemOptions[optionIndex].name);
  };

  const handleUpdateName = (optionIndex: number) => {
    if (newName.trim() !== '') {
      setSystemOptions((draft) => {
        // eslint-disable-next-line no-param-reassign
        draft[optionIndex].name = newName.trim();
      });
    }
    setRenaming(null);
  };

  const handleRenameKeyDown = (key: string, optionIndex: number) => {
    if (key === 'Enter') {
      handleUpdateName(optionIndex);
    }
  };

  const handleDeleteSystemOption = (optionId: string) => {
    if (systemOptions.length > 3) {
      const sortedOptions = sortSystemOptions(systemOptions, customerValues);
      setSystemOptions((draft) => {
        const replacementOptionId = sortedOptions.find((o) => !draft.some((d) => d.uniqueId === o.uniqueId))?.uniqueId;
        const replacementIndex = draft.findIndex((o) => o.uniqueId === replacementOptionId);
        const replacement = draft.splice(replacementIndex, 1)[0];
        const index = draft.findIndex((o) => o.uniqueId === optionId);
        // eslint-disable-next-line no-param-reassign
        draft[index] = replacement;
      });
    } else {
      setSystemOptions((prev) => prev.filter(o => o.uniqueId !== optionId));
    }
  };

  const isFeatureSameAcrossAllSystems = (feature, options) => {
    const optionsForComparison = options.slice(0, 3);
    if (feature === 'Total Units to Install') {
      const value = optionsForComparison[0].configuration.condensers.length + optionsForComparison[0].configuration.condensers.flatMap(cond => cond.zones).length;
      return optionsForComparison.every(option => (option.configuration.condensers.length + option.configuration.condensers.flatMap(cond => cond.zones).length) === value);
    }
    if (feature === 'Potential Rebates' || feature === 'Price After Rebates') {
      const value = optionsForComparison[0].totalRebates;
      return optionsForComparison.every(option => (option.totalRebates) === value);
    }
    return false;
  };

  const maxOptimalDesignScore = Math.max(...systemOptions.map(option => (option.optimalDesignScore ?? 1)));

  return advancedMode ? (
    <div className='w-full relative'>
      <div className='absolute top-0 inset-x-0 flex flex-col gap-5 pb-5'>
        <InternalNote>
          {configurations[activeConfig].condensers.length > 1 ?
            'Select a system for each condenser to create a new option' :
            'Select a system to create a new option'}
        </InternalNote>
        {configurations[activeConfig].condensers.map((condenser, index) => (
          <div key={condenser.uniqueId} className='w-full flex gap-5'>
            <div className='w-[350px] shrink-0 pt-10'>
              <div
                className='bg-white rounded-lg shadow ring-1 ring-black ring-opacity-5 p-5'
                onClick={() => toggleOpen(index)}
              >
                <CondenserMap condenser={condenser} />
              </div>
            </div>
            <div className='grow overflow-auto'>
              <div
                className={`transition-height duration-500 ease-in-out overflow-hidden ${expandedIndex === index ? 'max-h-screen' : 'mt-6'}`}
                ref={(el) => {
                  drawerRefs.current[index] = el;
                }}
              >
                <div className='w-full border-t border-gray-200'>
                  {expandedIndex === index ? (
                    <SystemsTable
                      key={condenser.uniqueId}
                      condenser={condenser}
                      condenserIndex={index}
                      totalCondensers={configurations[activeConfig].condensers.length}
                      selectable
                      onSelectSystem={handleSelectSystem}
                    />
                  ) : (
                    systemsForNewOption[index] && (
                      <div className="p-3 mt-4 border rounded-lg shadow ring-1 ring-black ring-opacity-5 bg-white">
                        <div className="flex items-center gap-2">
                          {expandedIndex !== index && (
                            <ProgressIndicator
                              required
                              checked={systemsForNewOption.some(s => s.condenserUnique === condenser.uniqueId)}
                            />
                          )}
                          <div className='text-xl font-bold text-gray-900'>
                            {condenser.name}
                          </div>
                        </div>
                        <div className="flex overflow-x-auto">
                          <div className="flex flex-nowrap items-start">
                            <div className="flex-1 min-w-[300px] p-4 border-r border-gray-300">
                              {(() => {
                                const condenserProduct = condenserProducts.find(cp => cp.productId === systemsForNewOption[index].condenserProductId);
                                return condenserProduct ? (
                                  <>
                                    <h3 className="text-lg font-semibold">Condenser Product</h3>
                                    <p><ProductLink type='condenser' product={condenserProduct} hoverInfo /></p>
                                    <div className='flex flex-wrap gap-1'>
                                      <Tag color='default' text={condenserProduct.model} />
                                      <Tag color='default' text={abbreviateNumber(condenserProduct?.coolingBTU ?? 0)} />
                                      <Tag color='default' text={`${condenserProduct.seer} SEER`} />
                                    </div>
                                  </>
                                ) : null;
                              })()}
                            </div>
                            {[...Array(systemsForNewOption[index].numberOfZones).keys()].map(zoneIndex => {
                              const airHandlerProductId = systemsForNewOption[index][`airHandler${zoneIndex + 1}ProductId`];
                              const airHandlerProduct = airHandlerProducts.find(ahp => ahp.productId === airHandlerProductId);
                              return (
                                <div key={zoneIndex} className="flex-1 min-w-[300px] p-4 border-r border-gray-300">
                                  <h3 className="text-lg font-semibold">Zone {zoneIndex + 1} Air Handler</h3>
                                  {airHandlerProduct ? (
                                    <AirHandlerCell airHandler={airHandlerProduct} />
                                  ) : (
                                    <p>No selected product for this zone.</p>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <div className='flex flex-col gap-5'>
      <div className='flex gap-5'>
        {[0, 1, 2].map((optionIndex) => {
          const option = systemOptions[optionIndex];
          const optionsForMenu = option ? systemOptions.filter((opt) => !systemOptions.slice(0, 3).map(o => o.uniqueId).includes(opt.uniqueId)) : [];
          return option ? (
            <div
              key={option.uniqueId}
              className='rounded-lg shadow ring-1 ring-black ring-opacity-5 relative flex flex-col items-center gap-3 p-5 pt-[70px] bg-white grow shrink-0 basis-0 max-w-[35%] overflow-hidden'
            >
              <div className='absolute left-2 right-2 top-2 flex gap-1 items-start'>
                <div className='flex flex-wrap gap-1 grow'>
                  {option.inStock && <Tag color='default' text='In Stock' />}
                  {!option.inStock && option.restockDate && (
                    <Tag
                      color='yellow'
                      text={`ETA ${new Date(option.restockDate).toLocaleDateString(
                        'en-us',
                        { month: 'numeric', day: 'numeric' }
                      )}`}
                    />
                  )}
                  {!option.inStock && !option.restockDate && (
                    <Tag color='red' text='Out of Stock' />
                  )}
                  {option.onSale && (
                    <Tag color='light green' text='Sale' />
                  )}
                  {option.tags?.slice(0, 4).map((tag) => tag === 'Mixed Manufacturers' ? (
                    <div key={tag} className='relative cursor-help'>
                      <Tag text={tag} color='yellow' icon={ExclamationTriangleIcon} />
                      <Tooltip text='Systems are not all the same manufacturer' />
                    </div>
                  ) : (
                    <Tag key={tag} text={tag} color={tag === 'Recommended' ? 'purple' : 'default'} />
                  ))}
                  {(option.tags?.length ?? 0) > 4 && (
                    <div className="relative cursor-help">
                      <Tag text={`+${(option.tags?.length ?? 4) - 4}`} />
                      <Tooltip text={option.tags?.slice(4).join(', ') ?? 'N/A'} />
                    </div>
                  )}
                </div>
                <div className='flex items-center gap-1'>
                  {option.tags?.includes('Custom') && (
                    <button
                      className='text-gray-400 hover:text-gray-500 relative'
                      onClick={() => handleDeleteSystemOption(option.uniqueId)}
                    >
                      <TrashIcon className='w-6 h-6' />
                      <Tooltip text='Delete System' />
                    </button>
                  )}
                  <Popover placement='bottom-start'>
                    <PopoverTrigger className='text-gray-400 hover:text-gray-500 relative'>
                      <ArrowPathIcon className='w-6 h-6' />
                      <Tooltip text='Change System' />
                    </PopoverTrigger>
                    <PopoverContent className="flex flex-col p-2 rounded-lg bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                      {Array.from(new Set(systemOptions.map((o) => o.configuration.uniqueId))).length === 1 ?
                        <>
                          {optionsForMenu.map((systemOption) => (
                            <PopoverClose
                              key={systemOption.uniqueId}
                              onClick={() => handleChangeSystem(systemOption, optionIndex)}
                              className='rounded-md px-3 py-2 text-left text-sm font-semibold text-gray-900 hover:bg-gray-100 flex items-center gap-2'
                            >
                              <span>{systemOption.name}</span>
                              {(systemOption.tags?.length ?? 0) >= 1 && (
                                <Tag text={systemOption.tags?.[0]} color={systemOption.tags?.[0] === 'Recommended' ? 'purple' : 'default'} />
                              )}
                              {(systemOption.tags?.length ?? 0) > 1 && (
                                <div className="relative cursor-help">
                                  <Tag text={`+${(systemOption.tags?.length ?? 1) - 1}`} />
                                  <Tooltip text={systemOption.tags?.slice(1).join(', ') ?? 'N/A'} />
                                </div>
                              )}
                              {systemOption.tags?.includes('Custom') && (
                                <button
                                  className='text-gray-400 hover:text-gray-500 relative ml-auto'
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteSystemOption(systemOption.uniqueId);
                                  }}
                                >
                                  <TrashIcon className='w-6 h-6' />
                                  <Tooltip text='Delete System' />
                                </button>
                              )}
                            </PopoverClose>
                          ))}
                          <PopoverClose
                            onClick={() => handleNewSystemClick(optionIndex, configurations[activeConfig].uniqueId)}
                            className='rounded-md px-3 py-2 text-left text-sm font-semibold text-gray-900 hover:bg-gray-100 flex items-center gap-2'
                          >
                            <span>+ New Option</span>
                          </PopoverClose>
                        </> : configurations.map((config) => (
                          <Fragment key={config.uniqueId}>
                            <div className="text-gray-500 font-semibold text-sm my-1">{config.name}</div>
                            {optionsForMenu.filter((o) => o.configuration.uniqueId === config.uniqueId).map((systemOption) => (
                              <PopoverClose
                                key={systemOption.id}
                                onClick={() => handleChangeSystem(systemOption, optionIndex)}
                                className='rounded-md px-3 py-2 text-left text-sm font-semibold text-gray-900 hover:bg-gray-100 flex items-center gap-2'
                              >
                                <span>{systemOption.name}</span>
                                {(systemOption.tags?.length ?? 0) >= 1 && (
                                  <Tag text={systemOption.tags?.[0]} color={systemOption.tags?.[0] === 'Recommended' ? 'purple' : 'default'} />
                                )}
                                {(systemOption.tags?.length ?? 0) > 1 && (
                                  <div className="relative cursor-help">
                                    <Tag text={`+${(systemOption.tags?.length ?? 1) - 1}`} />
                                    <Tooltip text={systemOption.tags?.slice(1).join(', ') ?? 'N/A'} />
                                  </div>
                                )}
                                {systemOption.tags?.includes('Custom') && (
                                  <button
                                    className='text-gray-400 hover:text-gray-500 relative ml-auto'
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleDeleteSystemOption(systemOption.uniqueId);
                                    }}
                                  >
                                    <TrashIcon className='w-6 h-6' />
                                    <Tooltip text='Delete System' />
                                  </button>
                                )}
                              </PopoverClose>
                            ))}
                            <PopoverClose
                              onClick={() => handleNewSystemClick(optionIndex, config.uniqueId)}
                              className='rounded-md px-3 py-2 text-left text-sm font-semibold text-gray-900 hover:bg-gray-100 flex items-center gap-2'
                            >
                              <span>+ New Option</span>
                            </PopoverClose>
                          </Fragment>
                        ))}
                    </PopoverContent>
                  </Popover>
                </div>
              </div>
              <div className='w-full flex flex-col items-center'>
                {configurations.length > 1 && (
                  <div className='text-xl font-bold whitespace-nowrap overflow-hidden text-ellipsis'>
                    {option.configuration.name}
                  </div>
                )}
                {!option.tags?.includes('Custom') && (
                  <div className='text-xl font-bold whitespace-nowrap overflow-hidden text-ellipsis'>
                    {option.name}
                  </div>
                )}
                {option.tags?.includes('Custom') && renaming === optionIndex && (
                  <div className='w-full relative'>
                    <Text
                      size='sm'
                      onInput={(value) => setNewName((value ?? '').slice(0, renameSizeLimit))}
                      value={newName}
                      onBlur={() => handleUpdateName(optionIndex)}
                      onKeyDown={(e) => handleRenameKeyDown(e.key, optionIndex)}
                      autoFocus
                      autoSelect
                      controlled
                    />
                    <div className='absolute inset-y-0 right-0 flex items-center pr-2 text-gray-500 text-sm pointer-events-none'>
                      {newName.length}/{renameSizeLimit}
                    </div>
                  </div>
                )}
                {option.tags?.includes('Custom') && renaming !== optionIndex && (
                  <button
                    type='button'
                    onClick={() => handleRenameClick(optionIndex)}
                    className='relative flex items-center gap-2 truncate text-xl font-bold whitespace-nowrap overflow-hidden text-ellipsis outline-none cursor-pointer group'
                  >
                    <span>
                      {option.name}
                    </span>
                    <PencilSquareIcon className='h-5 w-5 text-gray-600 group-hover:text-gray-900' />
                    <Tooltip text='Rename' />
                  </button>
                )}
              </div>
              <div className="flex flex-col gap-1 items-center">
                <div className='text-4xl font-bold text-gray-800'>
                  ${option.price.toLocaleString()}
                </div>
                {option.totalRebates !== undefined && option.totalRebates > 0 && (
                  <>
                    <span className='relative cursor-help font-semibold text-green-500'>
                      -{formatUSD(option.totalRebates)} in Potential Rebates*
                      <Tooltip text='* Products + Installation Cost' />
                    </span>
                  </>
                )}
                <div className='font-semibold text-gray-500'>
                  +${option.operatingCost.toLocaleString()} Estimated Annual Utility Cost
                </div>
              </div>
              <div className='flex items-center gap-2 font-semibold'>
                <span className='text-gray-500'>Coverage:</span>
                <CoverageIndicator
                  type='heating'
                  score={option.heatingScore}
                  condenserCoverage={option.condenserCoverage}
                />
                <CoverageIndicator
                  type='cooling'
                  score={option.coolingScore}
                  condenserCoverage={option.condenserCoverage}
                />
              </div>
              <Button
                fullWidth
                size='lg'
                color={selectedSystems.some((s) => s.uniqueId === option.uniqueId) ? 'green' : 'primary'}
                text={selectedSystems.some((s) => s.uniqueId === option.uniqueId) ? 'Added to Quote' : 'Add to Quote'}
                onClick={() => handleOptionSelect(option.uniqueId)}
                disabled={selectedSystems.length >= 3 && !selectedSystems.some((s) => s.uniqueId === option.uniqueId)}
                onDisabledClick={() => warningModal('You can only select up to 3 options at a time')}
                icon={selectedSystems.some((s) => s.uniqueId === option.uniqueId) ? CheckIcon : undefined}
              />
              <div className='flex flex-col justify-between grow'>
                <div className="w-full flex flex-col gap-5 pt-2">
                  {option.configuration.condensers.map((condenser, condIndex) => (
                    <SystemMap
                      key={condIndex}
                      condenser={condenser}
                      systemOptionIndex={optionIndex}
                      trackingId={trackingIds[optionIndex]}
                      editable
                      showSpaces
                      showBTUs
                    // customerFacing={true}
                    />
                  ))}
                </div>
                <div
                  className={`relative mt-5 rounded-lg cursor-pointer ${referenceTableIndex === optionIndex ? 'border-2 border-blue-500 bg-blue-50' : 'hover:bg-gray-100/75'}`}
                  onClick={() => setReferenceTableIndex(prev => prev === optionIndex ? null : optionIndex)}
                >
                  <div className='flex flex-col divide-y divide-gray-300'>
                    {featuresToDisplay.map((feature) => {
                      if (isFeatureSameAcrossAllSystems(feature, systemOptions)) {
                        return null;
                      }
                      return (
                        <div key={feature} className='w-full py-3 flex items-center justify-center'>
                          <div className='flex items-center justify-center gap-2 font-semibold w-full'>
                            {(!showMoreInfo || (feature !== 'Heating Coverage' && feature !== 'Cooling Coverage' && feature !== 'Indoor Units' && feature !== 'Outdoor Units')) && (
                              <span className='text-gray-500 font-normal'>{feature}:</span>
                            )}
                            {feature === 'Heating Coverage' && (
                              <div className='flex flex-col items-center gap-1'>
                                <div className='flex items-center justify-center gap-2'>
                                  {showMoreInfo && <span className='text-gray-500 font-normal'>Heating Coverage:</span>}
                                  <div className='flex items-center gap-1'>
                                    <CoverageIndicator
                                      type='heating'
                                      score={option.heatingScore}
                                      condenserCoverage={option.condenserCoverage}
                                    />
                                    {referenceTableIndex !== null && referenceTableIndex !== optionIndex && (
                                      <StatDifference
                                        value={option.heatingScore}
                                        referenceValue={systemOptions[referenceTableIndex].heatingScore}
                                        formatFn={(val) => `${Math.ceil(val)}%`}
                                      />
                                    )}
                                  </div>
                                </div>
                                {showMoreInfo && (
                                  <div className="w-full mt-1 text-center">
                                    {getCoverageTooltipData('heating', option.heatingScore, option.condenserCoverage)}
                                  </div>
                                )}
                              </div>
                            )}
                            {feature === 'Cooling Coverage' && (
                              <div className='flex flex-col items-center gap-1'>
                                <div className='flex items-center justify-center gap-2'>
                                  {showMoreInfo && <span className='text-gray-500 font-normal'>Cooling Coverage:</span>}
                                  <div className='flex items-center gap-1'>
                                    <CoverageIndicator
                                      type='cooling'
                                      score={option.coolingScore}
                                      condenserCoverage={option.condenserCoverage}
                                    />
                                    {referenceTableIndex !== null && referenceTableIndex !== optionIndex && (
                                      <StatDifference
                                        value={option.coolingScore}
                                        referenceValue={systemOptions[referenceTableIndex].coolingScore}
                                        formatFn={(val) => `${Math.ceil(val)}%`}
                                      />
                                    )}
                                  </div>
                                </div>
                                {showMoreInfo && (
                                  <div className='w-full mt-1 text-center'>
                                    {getCoverageTooltipData('cooling', option.coolingScore, option.condenserCoverage)}
                                  </div>
                                )}
                              </div>
                            )}
                            {feature === 'Optimal Design Score' && (
                              <div className='flex items-center gap-1 whitespace-nowrap'>
                                <span>{Math.floor(((option.optimalDesignScore ?? 1) / maxOptimalDesignScore) * 10)}/10</span>
                                {referenceTableIndex !== null && referenceTableIndex !== optionIndex && (
                                  <StatDifference
                                    value={Math.floor(((option.optimalDesignScore ?? 1) / maxOptimalDesignScore) * 10)}
                                    referenceValue={Math.floor(((systemOptions[referenceTableIndex].optimalDesignScore ?? 1) / maxOptimalDesignScore) * 10)}
                                  />
                                )}
                              </div>
                            )}
                            {feature === 'Average Efficiency' && (
                              <div className='flex items-center gap-1 whitespace-nowrap'>
                                <span>{option.averageSeer} SEER</span>
                                {referenceTableIndex !== null && referenceTableIndex !== optionIndex && (
                                  <StatDifference
                                    value={option.averageSeer}
                                    referenceValue={systemOptions[referenceTableIndex].averageSeer}
                                  />
                                )}
                              </div>
                            )}
                            {feature === 'Price' && (
                              <div className='flex items-center gap-1 whitespace-nowrap'>
                                <span>${option.price.toLocaleString()}</span>
                                {referenceTableIndex !== null && referenceTableIndex !== optionIndex && (
                                  <StatDifference
                                    value={option.price}
                                    referenceValue={systemOptions[referenceTableIndex].price}
                                    formatFn={formatUSD}
                                    lowerIsBetter
                                  />
                                )}
                              </div>
                            )}
                            {feature === 'Total Units to Install' && (
                              <div className='flex items-center gap-1 whitespace-nowrap'>
                                <span>{option.configuration.condensers.length + option.configuration.condensers.flatMap(cond => cond.zones).length}</span>
                                {referenceTableIndex !== null && referenceTableIndex !== optionIndex && (
                                  <StatDifference
                                    value={option.configuration.condensers.length + option.configuration.condensers.flatMap(cond => cond.zones).length}
                                    referenceValue={systemOptions[referenceTableIndex].configuration.condensers.length + systemOptions[referenceTableIndex].configuration.condensers.flatMap(cond => cond.zones).length}
                                    lowerIsBetter
                                  />
                                )}
                              </div>
                            )}
                            {feature === 'Indoor Units' && (
                              <div className='flex flex-col items-center gap-1'>
                                <div className='flex items-center gap-2'>
                                  <span className='text-gray-500 font-normal'>Indoor Units:</span>
                                  <span>{option.configuration.condensers.flatMap(cond => cond.zones).length}</span>
                                  {referenceTableIndex !== null && referenceTableIndex !== optionIndex && (
                                    <StatDifference
                                      value={option.configuration.condensers.flatMap(cond => cond.zones).length}
                                      referenceValue={systemOptions[referenceTableIndex].configuration.condensers.flatMap(cond => cond.zones).length}
                                      lowerIsBetter
                                    />
                                  )}
                                </div>
                                {showMoreInfo && (
                                  <div className='w-full mt-1 text-center'>
                                    {(() => {
                                      const allZones = option.configuration.condensers.flatMap(cond => cond.zones);
                                      const uniqueProducts = new Map<string, any>();
                                      allZones.forEach(zone => {
                                        if (zone.selectedProduct) {
                                          uniqueProducts.set(zone.selectedProduct.name, zone.selectedProduct);
                                        }
                                      });
                                      return (
                                        <>
                                          {Array.from(uniqueProducts.values()).map((product: AirHandlerProduct, index) => {
                                            const installationRequirements = product.requirements
                                              .filter(requirement => requirement.type === 'installation')
                                              .map(requirement => requirement.value);

                                            const drainageRequirements = product.requirements
                                              .filter(requirement => requirement.type === 'drainage')
                                              .map(requirement => requirement.value);

                                            const clearances = product.dimensions
                                              ? [
                                                product.dimensions.clearanceFront ? `Front Clearance: ${product.dimensions.clearanceFront}"` : null,
                                                product.dimensions.clearanceSides ? `Side Clearance: ${product.dimensions.clearanceSides}"` : null,
                                                product.dimensions.clearanceTop ? `Top Clearance: ${product.dimensions.clearanceTop}"` : null,
                                                product.dimensions.clearanceBottom ? `Bottom Clearance: ${product.dimensions.clearanceBottom}"` : null
                                              ].filter(Boolean)
                                              : [];

                                            const allRequirements = [...installationRequirements, ...drainageRequirements];

                                            return (
                                              <div key={index} className='text-sm text-gray-700 mb-4'>
                                                <div className='font-semibold text-black mb-2'>{product.name}</div>
                                                <div className='font-semibold mt-2 text-gray-500'>Requirements:</div>
                                                {allRequirements.length > 0 ? (
                                                  <ul className='list-disc list-inside space-y-1'>
                                                    {allRequirements.map((requirement, reqIndex) => (
                                                      <li key={reqIndex} className='text-gray-500 font-normal'>{requirement}</li>
                                                    ))}
                                                  </ul>
                                                ) : (
                                                  <span className='text-gray-500 font-normal'>Requirements unavailable</span>
                                                )}
                                                {clearances.length > 0 && (
                                                  <div>
                                                    <div className='font-semibold mt-2 text-gray-500'>Clearances:</div>
                                                    <div className='flex flex-col space-y-1'>
                                                      {clearances.map((clearance, clearanceIndex) => (
                                                        <div key={clearanceIndex} className='text-gray-500 font-normal'>{clearance}</div>
                                                      ))}
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            );
                                          })}
                                        </>
                                      );
                                    })()}
                                  </div>
                                )}
                              </div>
                            )}
                            {feature === 'Outdoor Units' && (
                              <div className='flex flex-col items-center gap-1'>
                                <div className='flex items-center gap-2'>
                                  <span className='text-gray-500 font-normal'>Outdoor Units:</span>
                                  <span>{option.configuration.condensers.length}</span>
                                  {referenceTableIndex !== null && referenceTableIndex !== optionIndex && (
                                    <StatDifference
                                      value={option.configuration.condensers.length}
                                      referenceValue={systemOptions[referenceTableIndex].configuration.condensers.length}
                                      lowerIsBetter
                                    />
                                  )}
                                </div>
                                {showMoreInfo && (
                                  <div className='w-full mt-1 text-center'>
                                    {(() => {
                                      const uniqueOutdoorProducts = new Map<string, any>();
                                      option.configuration.condensers.forEach(condenser => {
                                        if (condenser.selectedProduct) {
                                          uniqueOutdoorProducts.set(condenser.selectedProduct.name, condenser.selectedProduct);
                                        }
                                      });
                                      return (
                                        <>
                                          {Array.from(uniqueOutdoorProducts.values()).map((product: CondenserProduct, index) => {
                                            const installationRequirements = product.requirements
                                              .filter(requirement => requirement.type === 'installation' || requirement.type === 'drainage')
                                              .map(requirement => requirement.value);

                                            return (
                                              <div key={index} className='text-sm text-gray-700'>
                                                <div className='font-semibold text-black mb-2'>{product.name}</div>
                                                <div className='font-semibold mt-2 text-gray-500'>Requirements:</div>
                                                {installationRequirements.length > 0 ? (
                                                  <ul className='list-disc list-inside space-y-1'>
                                                    {installationRequirements.map((requirement, reqIndex) => (
                                                      <li key={reqIndex} className='text-gray-500 font-normal'>{requirement}</li>
                                                    ))}
                                                  </ul>
                                                ) : (
                                                  <span className='text-gray-500 font-normal'>Requirements unavailable</span>
                                                )}
                                              </div>
                                            );
                                          })}
                                        </>
                                      );
                                    })()}
                                  </div>
                                )}
                              </div>
                            )}
                            {feature === 'Indoor Visibility' && (
                              <div className='flex items-center gap-1 whitespace-nowrap'>
                                <span>{getComparisonValue('aesthetics', option, systemOptions)}</span>
                                {referenceTableIndex !== null && referenceTableIndex !== optionIndex && (
                                  <StatDifference
                                    value={getComparisonValue('aesthetics', option, systemOptions)}
                                    referenceValue={getComparisonValue('aesthetics', systemOptions[referenceTableIndex], systemOptions)}
                                  />
                                )}
                              </div>
                            )}
                            {feature === 'Installation Difficulty' && (
                              <div className='flex items-center gap-1 whitespace-nowrap'>
                                <span>{getComparisonValue('difficulty', option, systemOptions)}</span>
                                {referenceTableIndex !== null && referenceTableIndex !== optionIndex && (
                                  <StatDifference
                                    value={getComparisonValue('difficulty', option, systemOptions)}
                                    referenceValue={getComparisonValue('difficulty', systemOptions[referenceTableIndex], systemOptions)}
                                  />
                                )}
                              </div>
                            )}
                            {feature === 'Potential Rebates' && (
                              <div className='flex items-center whitespace-nowrap'>
                                <span>${(option.totalRebates ?? 0).toLocaleString()}</span>
                                {option.totalRebates !== undefined && (
                                  <>
                                    <span>*</span>
                                    <Tooltip text='* Products + Installation Cost' />
                                  </>
                                )}
                                {referenceTableIndex !== null && referenceTableIndex !== optionIndex && (
                                  <StatDifference
                                    value={Math.floor(option.totalRebates ?? 0)}
                                    referenceValue={Math.min(systemOptions[referenceTableIndex].totalRebates ?? 0, systemOptions[referenceTableIndex].price)}
                                    formatFn={formatUSD}
                                  />
                                )}
                              </div>
                            )}
                            {feature === 'Price After Rebates' && (
                              <div className='flex items-center gap-1 whitespace-nowrap'>
                                <span>${Math.max(option.price - (option.totalRebates ?? 0), 0).toLocaleString()}</span>
                                {referenceTableIndex !== null && referenceTableIndex !== optionIndex && (
                                  <StatDifference
                                    value={Math.max(option.price - (option.totalRebates ?? 0), 0)}
                                    referenceValue={Math.max(systemOptions[referenceTableIndex].price - (systemOptions[referenceTableIndex].totalRebates ?? 0), 0)}
                                    formatFn={formatUSD}
                                    lowerIsBetter
                                  />
                                )}
                              </div>
                            )}
                            {feature === 'Amperage' && (
                              <div className='flex items-center gap-1 whitespace-nowrap'>
                                <span>{option.amperage != null && option.amperage !== 0 ? option.amperage : 'unavailable'}</span>
                                {referenceTableIndex !== null && referenceTableIndex !== optionIndex &&
                                  option.amperage != null && option.amperage !== 0 &&
                                  systemOptions[referenceTableIndex].amperage != null && systemOptions[referenceTableIndex].amperage !== 0 && (
                                    <StatDifference
                                      value={option.amperage}
                                      referenceValue={systemOptions[referenceTableIndex].amperage ?? 0}
                                      lowerIsBetter
                                    />
                                  )}
                              </div>
                            )}
                            {feature === 'Outdoor Visibility' && (
                              <div className='flex items-center gap-1 whitespace-nowrap'>
                                <span>{option.outdoorVisibility ?? 'N/A'}</span>
                                {referenceTableIndex !== null && referenceTableIndex !== optionIndex && (
                                  <StatDifference
                                    value={option.outdoorVisibility ?? 'N/A'}
                                    referenceValue={systemOptions[referenceTableIndex].outdoorVisibility ?? 'N/A'}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {referenceTableIndex !== optionIndex && <Tooltip text='Click to compare' />}
                </div>
              </div>
            </div>
          ) : null;
        })}
      </div>
      <div className='flex justify-center'>
        <Button
          color='subtle'
          text={showMoreInfo ? 'Less Info' : 'More Info'}
          icon={showMoreInfo ? ChevronUpIcon : ChevronDownIcon}
          iconSize='md'
          iconPosition='right'
          onClick={() => setShowMoreInfo(v => !v)}
        />
      </div>
    </div>
  );
}
