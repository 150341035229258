import React, { useRef, useState } from 'react';
import { AccessoryWithQuantity } from 'data/types';
import { formatUSD } from 'utils/helpers';
import ProductPhoto from 'components/product-photo';
import Quantity from 'components/inputs/quantity';

type CartAccessoryProps = {
  accessory: AccessoryWithQuantity;
  onChange?: (value: number) => void;
};

export default function CartAccessory({
  accessory,
  onChange
}: CartAccessoryProps) {
  const [value, setValue] = useState(accessory.quantity);
  const valueRef = useRef(value);

  valueRef.current = value;

  const handleChange = (newValue: number) => {
    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <div className='flex items-center py-5'>
      <div className='flex grow items-center gap-4'>
        <ProductPhoto product={accessory} />
        <div>
          <a
            className='font-semibold text-blue-500 hover:text-blue-600'
            href={`https://alpinehomeair.com${accessory.productUrl}`}
            rel='noreferrer'
            target='_blank'
          >
            {accessory.name}
          </a>
        </div>
      </div>
      <Quantity value={value} onChange={(quantity) => handleChange(quantity ?? 0)} />
      <div className='w-[90px] text-right text-lg font-bold'>
        {formatUSD(accessory.price * Math.max(value, 1), true)}
      </div>
    </div>
  );
}

CartAccessory.defaultProps = {
  onChange: undefined
};
