import React, { useContext, useEffect, useState, Dispatch, SetStateAction, MouseEvent } from 'react';
import { FireIcon, SparklesIcon } from '@heroicons/react/20/solid';
import axios from 'axios';
import { ProjectContext, ConfigurationContext, UIContext } from 'data/context';
import { SelectedSystem, ShippingInfo, Rebate, ComparisonFeature, AirHandlerProduct, CondenserProduct } from 'data/types';
import { formatUSD, errorModal, notification, getComparisonValue, getCoverageTooltipData } from 'utils/helpers';
import Tag from 'components/tag';
import Button from 'components/inputs/button';
import Checkbox from 'components/inputs/checkbox';
import { QuestionMarkCircleIcon, RectangleGroupIcon } from '@heroicons/react/24/outline';
import Skeleton from 'components/skeleton';
import SystemMap from 'components/system-map';
import ProductPhoto from 'components/product-photo';
import Tooltip from 'components/tooltip';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import CoverageIndicator from 'components/coverage-indicator';
import ProductLink from 'components/product-link';
import StatDifference from 'components/stat-difference';

type QuoteColumnProps = {
  system: SelectedSystem;
  systemIndex: number;
  handleQuoteColumnClick: (systemIndex: number) => void;
  setRebatesModalOpen: Dispatch<SetStateAction<boolean>>;
  referenceTableIndex: number | null;
  setReferenceTableIndex: Dispatch<SetStateAction<number | null>>;
  optionIndex: number | null;
  setOptionIndex: Dispatch<SetStateAction<number | null>>;
  showAdvanced: boolean;
};

export default function QuoteColumn({
  system,
  systemIndex,
  handleQuoteColumnClick,
  setRebatesModalOpen,
  referenceTableIndex,
  setReferenceTableIndex,
  optionIndex,
  setOptionIndex,
  showAdvanced
}: QuoteColumnProps) {
  const {
    locationData,
    projectDetails,
    quotesToSend,
    setQuotesToSend,
    systemOptions
  } = useContext(ProjectContext);
  const {
    setRebatesForModal
  } = useContext(UIContext);
  const {
    selectedSystems,
    setSelectedSystems
  } = useContext(ConfigurationContext);
  const [showAllItems, setShowAllItems] = useState(false);
  const [animate] = useAutoAnimate({ duration: 150 });
  const comparisonFeatures: ComparisonFeature[] = [
    'Price',
    'Potential Rebates',
    'Price After Rebates',
    'Heating Coverage',
    'Cooling Coverage',
    'Optimal Design Score',
    'Amperage',
    'Average Efficiency',
    'Total Units to Install',
    'Indoor Visibility',
    'Outdoor Visibility',
    'Installation Difficulty',
    'Indoor Units',
    'Outdoor Units',
  ];

  const advancedFeatures: ComparisonFeature[] = [
    'Indoor Units',
    'Outdoor Units',
    'Potential Rebates',
    'Amperage'
  ];

  const featuresToDisplay = comparisonFeatures.filter(feature =>
    !advancedFeatures.includes(feature) || showAdvanced
  );

  const maxOptimalDesignScore = Math.max(...systemOptions.map(option => (option.optimalDesignScore ?? 1)));

  const getTax = async () => {
    if (locationData) {
      const payload = JSON.stringify({
        destinationZip: locationData.zipcode,
        shippingcost: 0,
        products: [
          ...(system.configuration.condensers.map((c) => ({
            id: c.selectedProduct?.productId,
            quantity: 1
          })) ?? []),
          ...(system.configuration.condensers.flatMap(c => c.zones.map((z) => ({
            id: z.selectedProduct?.productId,
            quantity: 1
          }))) ?? []),
          ...system.quotableAccessories.map((acc) => ({
            id: acc.productId,
            quantity: acc.quantity
          }))
        ]
      });
      try {
        const newTax = (await axios.post(
          `${process.env.REACT_APP_PARTNERNET_API_URL}/v1/api/cart/tax`,
          payload
        )).data as number;
        return newTax;
      } catch (error) {
        console.error(error);
        errorModal('Unable to calculate tax.');
        return null;
      }
    }
    return null;
  };

  const getShippingInfo = async () => {
    if (locationData) {
      const payload = JSON.stringify({
        zipcode: locationData.zipcode,
        products: [
          ...(system.configuration.condensers.map((c) => ({
            id: c.selectedProduct?.productId,
            qty: 1
          })) ?? []),
          ...(system.configuration.condensers.flatMap(c => c.zones.map((z) => ({
            id: z.selectedProduct?.productId,
            qty: 1
          }))) ?? []),
          ...system.quotableAccessories.map((acc) => ({
            id: acc.productId,
            qty: acc.quantity
          }))
        ]
      });
      try {
        const newShippingInfo = (
          await axios.post(
            `${process.env.REACT_APP_PARTNERNET_API_URL}/v1/api/shipping/info`,
            payload
          )
        ).data as ShippingInfo;
        return newShippingInfo;
      } catch (error) {
        console.error(error);
        errorModal('Unable to get shipping information.');
        return null;
      }
    }
    return null;
  };

  const getRebates = async () => {
    if (locationData) {
      const rebateInfo: Rebate[] = [];

      const productData = [
        ...(system.configuration.condensers.map((c) => ({
          product_id: c.selectedProduct?.productId,
          product_price: c.selectedProduct?.price
        })) ?? []),
        ...(system.configuration.condensers.flatMap(c => c.zones.map((z) => ({
          product_id: z.selectedProduct?.productId,
          product_price: z.selectedProduct?.price
        }))) ?? [])
      ];

      const payload = {
        zipCode: locationData.zipcode,
        productsData: productData
      };

      try {
        const rebateResponse = (
          await axios.post(
            `${process.env.REACT_APP_PARTNERNET_API_URL}/v1/api/upfront/get_rebates`,
            payload
          )
        ).data.data;
        rebateResponse.forEach((rebate) => {
          rebateInfo.push({
            productId: Number(rebate.id),
            rebateMaximum: rebate.rebateMaximum,
            rebateMinimum: rebate.rebateMinimum,
            programs: rebate.programs,
            price: rebate.price,
            zipcode: locationData.zipcode,
            productName: rebate.productName
          });
        });
        return rebateInfo;
      } catch (error) {
        console.error(error);
        errorModal('Unable to get rebate information.');
        return null;
      }
    }
    return null;
  };

  function estimateOperatingCost(currentOperatingCost: number, currentSeer: number, newSeer: number): number {
    return currentOperatingCost * (currentSeer / newSeer);
  }

  const estimated14SeerCost = system.operatingCost
    ? estimateOperatingCost(system.operatingCost, system.averageSeer, 14)
    : null;

  useEffect(() => {
    if (optionIndex !== null) {
      setOptionIndex(optionIndex);
    }
  }, [optionIndex]);

  useEffect(() => {
    if (!system.tax || !system.shipping) {
      const taxPromise = getTax();
      const shippingPromise = getShippingInfo();
      const rebatesPromise = getRebates();
      Promise.all([taxPromise, shippingPromise, rebatesPromise]).then(
        ([taxInfo, shippingInfo, rebatesInfo]) => {
          let totalRebates = 0;
          if (rebatesInfo) {
            const productQuantities = system.configuration.condensers.flatMap(c => [
              { id: c.selectedProduct?.productId, quantity: 1 },
              ...c.zones.map(z => ({ id: z.selectedProduct?.productId, quantity: 1 }))
            ]).reduce((acc, product) => {
              if (product.id) {
                acc[product.id] = (acc[product.id] || 0) + product.quantity;
              }
              return acc;
            }, {});

            totalRebates = rebatesInfo.reduce((sum, rebate) => {
              const quantity = productQuantities[rebate.productId] || 0;
              return sum + Math.max(rebate.rebateMinimum, rebate.rebateMaximum) * quantity;
            }, 0);
          }
          setSelectedSystems((prevSystems) =>
            prevSystems.map((s) =>
              s.id === system.id
                ? { ...s, tax: taxInfo ?? undefined, shipping: shippingInfo ?? undefined, rebates: rebatesInfo ?? undefined, totalRebates }
                : s
            )
          );
        }
      );
    }
  }, [system]);

  const handleColumnClick = () => {
    if (!quotesToSend.includes(system.id)) {
      setQuotesToSend((oldValue) => [...oldValue, system.id]);
    } else {
      setQuotesToSend((oldValue) =>
        oldValue.filter((id) => id !== system.id)
      );
    }
  };

  const handleShowAllItems = () => {
    setShowAllItems((oldValue) => !oldValue);
  };

  const handleRebatesClick = (event?: MouseEvent) => {
    event?.preventDefault();
    event?.stopPropagation();
    setRebatesForModal(system.rebates?.filter(rebate => rebate.programs.length > 0) ?? []);
    setRebatesModalOpen(true);
    handleQuoteColumnClick(systemIndex);
  };

  const isFeatureSameAcrossAllSystems = (feature, options) => {
    const optionsForComparison = options.slice(0, 3);
    if (feature === 'Total Units to Install') {
      const value = optionsForComparison[0].configuration.condensers.length + optionsForComparison[0].configuration.condensers.flatMap(cond => cond.zones).length;
      return optionsForComparison.every(option => (option.configuration.condensers.length + option.configuration.condensers.flatMap(cond => cond.zones).length) === value);
    }
    if (feature === 'Potential Rebates' || feature === 'Price After Rebates') {
      if (optionsForComparison.length <= 1) {
        return false;
      }
      const value = optionsForComparison[0].totalRebates;
      return optionsForComparison.every(option => (option.totalRebates) === value);
    }
    return false;
  };

  return system ? (
    <div
      data-active={quotesToSend.includes(system.id)}
      className='transition-all w-full flex flex-col p-5 rounded-lg bg-white border-4 border-white data-[active=true]:border-blue-500 shadow-lg ring-1 ring-black/5'
    >
      <button
        className='cursor-pointer flex flex-col text-left outline-none focus:outline-none border-gray-200 items-center'
        onClick={handleColumnClick}
        ref={animate}
      >
        <div className='w-full flex flex-col gap-5 pb-5 border-b'>
          <div className='relative flex items-center justify-between'>
            <div className='inline-block'>
              <Checkbox
                value={quotesToSend.includes(system.id)}
                onChange={() => handleColumnClick()}
              />
            </div>
            {system.inStock && (
              <Tag color='green' text='In Stock' />
            )}
            {!system.inStock &&
              system.restockDate && (
                <Tag
                  color='yellow'
                  text={`ETA ${new Date(
                    system.restockDate
                  ).toLocaleDateString('en-us', {
                    month: 'numeric',
                    day: 'numeric'
                  })}`}
                />
              )}
            {!system.inStock &&
              !system.restockDate && (
                <Tag color='red' text='Out of Stock' />
              )}
            <div className='absolute top-1/2 -translate-y-1/2 font-bold left-1/2 -translate-x-1/2 text-xl'>
              Option {selectedSystems.findIndex((s) => s.id === system.id) + 1}
            </div>
          </div>
          <div className='flex flex-col'>
            <div className='text-center text-4xl font-bold'>
              {formatUSD(
                system.price +
                system.quotableAccessories.reduce(
                  (total, acc) => total + acc.price * acc.quantity,
                  0
                ) +
                (projectDetails?.customerTaxExempt ? 0 : system.tax ?? 0),
                true
              )}
            </div>
            {projectDetails?.customerTaxExempt ? (
              <div className='text-center font-semibold text-gray-600'>
                (Tax exempt)
              </div>
            ) : (
              <div className='text-center font-semibold text-gray-500'>
                {system.tax !== undefined
                  ? `incl. ${formatUSD(system.tax, true)} tax`
                  : 'Calculating tax...'}
              </div>
            )}
          </div>
          {estimated14SeerCost && (
            <div className='text-center'>
              <span>Save up to</span>
              <span className='mx-1 font-semibold text-green-500'>
                {formatUSD(estimated14SeerCost - system.operatingCost)}
              </span>
              <span>
                annually compared to a typical 14 SEER system
              </span>
            </div>
          )}
          {system.totalRebates !== undefined && system.totalRebates > 0 && system.rebates && (
            <div className='flex justify-center text-center'>
              <a
                role='button'
                className='font-semibold text-blue-500 hover:text-blue-600'
                onClick={(e) => handleRebatesClick(e)}
              >
                Get up to {formatUSD(Math.floor(system.totalRebates))} in rebates for {locationData?.location ?? 'your location'}
              </a>
            </div>
          )}
          {!system.rebates && (
            <div className='flex justify-center text-center'>
              <div className='w-[350px]'>
                <Skeleton color='blue' />
              </div>
            </div>
          )}
        </div>
        <div className='w-full flex flex-col gap-3 py-5 border-b'>
          {system.shipping ? [
            ...system.shipping.shipOptions.rates.filter(
              (r) => r.type === 'standard'
            ),
            ...system.shipping.shipOptions.rates
              .filter((r) => r.type === 'guaranteed')
              .sort(
                (a, b) =>
                  new Date(a.deliverydate).getTime() -
                  new Date(b.deliverydate).getTime()
              )
          ]
            .slice(0, 2)
            .map((option) => {
              const location = locationData?.location ? `${locationData.location} ${locationData.zipcode}` : option.calculatedFor;
              const name = option.type === 'standard' ? `Standard Shipping for ${location}` : `Express Shipping for ${location}`;
              const deliveryDate = new Date(option.deliverydate).toLocaleDateString('en-us', {
                weekday: 'long',
                month: 'numeric',
                day: 'numeric'
              });
              const timeframe = option.label.match(/[\w\d/]+ - [\w\d/]+/gi)?.[0];
              const formattedTimeframe = timeframe?.split(' - ').map((date) => new Date(date).toLocaleDateString('en-us', {
                month: 'numeric',
                day: 'numeric'
              })).join(' - ');
              // eslint-disable-next-line no-nested-ternary
              const description = !timeframe ?
                `Arrives by ${deliveryDate}` :
                option.type === 'standard'
                  ? `Arrives between ${formattedTimeframe ?? timeframe}`
                  : `Arrives by ${deliveryDate} (${timeframe})`;

              return (
                <div
                  key={option.id}
                  className='flex items-center justify-between gap-3'
                >
                  <div>
                    <div className='mb-1 font-semibold'>{name}</div>
                    <div className='text-sm text-gray-600'>{description}</div>
                  </div>
                  <div className='text-lg font-bold'>
                    {option.cost > 0 ? formatUSD(option.cost, true) : 'Free'}
                  </div>
                </div>
              );
            }) : (
            [1, 2].map((i) => (
              <div key={i} className='flex items-center justify-between'>
                <div>
                  <div className='mb-1 w-[300px]'>
                    <Skeleton />
                  </div>
                  <div className='w-[200px]'>
                    <Skeleton />
                  </div>
                </div>
                <div className='w-[50px]'>
                  <Skeleton />
                </div>
              </div>
            ))
          )}
        </div>
        <div className='w-full flex flex-col gap-2 py-5 border-b'>
          <div className='text-lg font-bold'>Annual Coverage</div>
          <div className='flex items-center justify-between'>
            <div className='flex items-center gap-2'>
              <FireIcon className='h-5 w-5 text-orange-500' />
              <span className='text-gray-600'>Heating</span>
            </div>
            <div className='relative'>
              <div className="flex items-center gap-1">
                {system.heatingScore < 100 && <QuestionMarkCircleIcon className='h-5 w-5 text-gray-500' />}
                <span className='font-semibold'>
                  {Math.floor(system.heatingScore)}%
                </span>
              </div>
              {system.heatingScore < 100 && (
                <Tooltip>
                  {system.condenserCoverage.map((condenser, condenserIndex) => (
                    <div key={condenserIndex} className='whitespace-nowrap'>
                      {system.configuration.condensers.length > 1 && `${condenser.condenserName}: `}{condenser.daysOfInsufficientHeating} day{condenser.daysOfInsufficientHeating > 1 && 's'} at {Math.floor(condenser.avgHeatingPercent)}% average coverage
                    </div>
                  ))}
                </Tooltip>
              )}
            </div>
          </div>
          <div className='flex items-center justify-between'>
            <div className='flex items-center gap-2'>
              <SparklesIcon className='h-5 w-5 text-sky-500' />
              <span className='text-gray-600'>Cooling</span>
            </div>
            <div className='relative'>
              <div className="flex items-center gap-1">
                {system.coolingScore < 100 && <QuestionMarkCircleIcon className='h-5 w-5 text-gray-500' />}
                <span className='font-semibold'>
                  {Math.floor(system.coolingScore)}%
                </span>
              </div>
              {system.coolingScore < 100 && (
                <Tooltip>
                  {system.condenserCoverage.map((condenser, condenserIndex) => (
                    <div key={condenserIndex} className='whitespace-nowrap'>
                      {system.configuration.condensers.length > 1 && `${condenser.condenserName}: `}{condenser.daysOfInsufficientCooling} day{condenser.daysOfInsufficientCooling > 1 && 's'} at {Math.floor(condenser.avgCoolingPercent)}% average coverage
                    </div>
                  ))}
                </Tooltip>
              )}
            </div>
          </div>
        </div>
        <div className='w-full flex flex-col gap-2 py-5 border-b'>
          <div className='text-lg font-bold'>Warranty Information</div>
          <div className='flex items-center justify-between'>
            <span className='text-gray-600'>Parts</span>
            <span className='font-semibold'>
              {
                system.configuration.condensers[0].zones.find(
                  (z) => z.selectedProduct?.warranties?.some(w => w.id === 74)
                )?.selectedProduct?.warranties?.find(
                  (w) => w.id === 74
                )?.value.split(' ')[0] ?? 'N/A'
              }{' '}
              years
            </span>
          </div>
          <div className='flex items-center justify-between'>
            <span className='text-gray-600'>Compressor</span>
            <span className='font-semibold'>
              {
                system.configuration.condensers.find(
                  (c) => c.selectedProduct?.warranties?.some(w => w.id === 40)
                )?.selectedProduct?.warranties?.find(
                  (w) => w.id === 40
                )?.value.split(' ')[0] ?? 'N/A'
              }{' '}
              years
            </span>
          </div>
        </div>
        <div className='w-full flex flex-col gap-5 py-5'>
          {system.configuration.condensers.map((condenser) => (
            <SystemMap
              key={condenser.uniqueId}
              condenser={condenser}
              showPrices showSpaces
            />
          ))}
        </div>
        {showAllItems && (
          <div className='w-full flex flex-col gap-5 pb-5'>
            <div className='flex items-center gap-2'>
              <RectangleGroupIcon className='h-6 w-6 text-gray-800' />
              <span className='text-xl font-semibold'>
                Accessories
              </span>
            </div>
            <div className="flex flex-col gap-7">
              {system.quotableAccessories
                .filter((acc) => acc.quantity > 0)
                .map((accessory) => (
                  <div
                    key={accessory.productId}
                    className='flex items-center gap-4'
                  >
                    <ProductPhoto product={accessory} />
                    <div className='flex grow flex-col gap-1'>
                      <ProductLink type='accessory' product={accessory} quantity={accessory.quantity} />
                      <span className='text-xs text-gray-600'>
                        {accessory.description}
                      </span>
                    </div>
                    <div className='pl-3 text-lg font-bold'>
                      {formatUSD(accessory.price * accessory.quantity, true)}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
        <div
          className={`relative mt-5 rounded-lg w-full ${referenceTableIndex === optionIndex ? 'border-2 border-blue-500 bg-blue-50' : 'hover:bg-gray-100/75'}`}
          onClick={(e) => {
            e.stopPropagation();
            setReferenceTableIndex(prev => prev === optionIndex ? null : optionIndex);
          }}
        >
          <div className='flex flex-col divide-y divide-gray-300'>
            {featuresToDisplay.map((feature) => {
              if (isFeatureSameAcrossAllSystems(feature, selectedSystems)) {
                return null;
              }
              return (
                <div key={feature} className='w-full py-3 flex items-center justify-center'>
                  <div className='flex items-center justify-center gap-2 font-semibold w-full'>
                    {(!showAdvanced || (feature !== 'Heating Coverage' && feature !== 'Cooling Coverage' && feature !== 'Indoor Units' && feature !== 'Outdoor Units')) && (
                      <span className='text-gray-500 font-normal'>{feature}:</span>
                    )}
                    {feature === 'Heating Coverage' && (
                      <div className='flex flex-col items-center gap-1' >
                        <div className='flex items-center justify-center gap-2'>
                          {showAdvanced && <span className='text-gray-500 font-normal'>Heating Coverage:</span>}
                          <div className='flex items-center gap-1'>
                            <CoverageIndicator
                              type='heating'
                              score={system.heatingScore}
                              condenserCoverage={system.condenserCoverage}
                            />
                            {referenceTableIndex !== null && referenceTableIndex !== optionIndex && (
                              <StatDifference
                                value={system.heatingScore}
                                referenceValue={selectedSystems[referenceTableIndex].heatingScore}
                                formatFn={(val) => `${Math.ceil(val)}%`}
                              />
                            )}
                          </div>
                        </div>
                        {showAdvanced && (
                          <div className="w-full mt-1 text-center">
                            {getCoverageTooltipData('heating', system.heatingScore, system.condenserCoverage)}
                          </div>
                        )}
                      </div>
                    )}
                    {feature === 'Cooling Coverage' && (
                      <div className='flex flex-col items-center gap-1'>
                        <div className='flex items-center justify-center gap-2'>
                          {showAdvanced && <span className='text-gray-500 font-normal'>Cooling Coverage:</span>}
                          <div className='flex items-center gap-1'>
                            <CoverageIndicator
                              type='cooling'
                              score={system.coolingScore}
                              condenserCoverage={system.condenserCoverage}
                            />
                            {referenceTableIndex !== null && referenceTableIndex !== optionIndex && (
                              <StatDifference
                                value={system.coolingScore}
                                referenceValue={selectedSystems[referenceTableIndex].coolingScore}
                                formatFn={(val) => `${Math.ceil(val)}%`}
                              />
                            )}
                          </div>
                        </div>
                        {showAdvanced && (
                          <div className='w-full mt-1 text-center'>
                            {getCoverageTooltipData('cooling', system.coolingScore, system.condenserCoverage)}
                          </div>
                        )}
                      </div>
                    )}
                    {feature === 'Optimal Design Score' && (
                      <div className='flex items-center gap-1 whitespace-nowrap'>
                        <span>{Math.floor(((system.optimalDesignScore ?? 1) / maxOptimalDesignScore) * 10)}/10</span>
                        {referenceTableIndex !== null && referenceTableIndex !== optionIndex && (
                          <StatDifference
                            value={Math.floor(((system.optimalDesignScore ?? 1) / maxOptimalDesignScore) * 10)}
                            referenceValue={Math.floor(((selectedSystems[referenceTableIndex].optimalDesignScore ?? 1) / maxOptimalDesignScore) * 10)}
                          />
                        )}
                      </div>
                    )}
                    {feature === 'Average Efficiency' && (
                      <div className='flex items-center gap-1 whitespace-nowrap'>
                        <span>{system.averageSeer} SEER</span>
                        {referenceTableIndex !== null && referenceTableIndex !== optionIndex && (
                          <StatDifference
                            value={system.averageSeer}
                            referenceValue={selectedSystems[referenceTableIndex].averageSeer}
                          />
                        )}
                      </div>
                    )}
                    {feature === 'Price' && (
                      <div className='flex items-center gap-1 whitespace-nowrap'>
                        <span>${system.price.toLocaleString()}</span>
                        {referenceTableIndex !== null && referenceTableIndex !== optionIndex && (
                          <StatDifference
                            value={system.price}
                            referenceValue={selectedSystems[referenceTableIndex].price}
                            formatFn={formatUSD}
                            lowerIsBetter
                          />
                        )}
                      </div>
                    )}
                    {feature === 'Total Units to Install' && (
                      <div className='flex items-center gap-1 whitespace-nowrap'>
                        <span>{system.configuration.condensers.length + system.configuration.condensers.flatMap(cond => cond.zones).length}</span>
                        {referenceTableIndex !== null && referenceTableIndex !== optionIndex && (
                          <StatDifference
                            value={system.configuration.condensers.length + system.configuration.condensers.flatMap(cond => cond.zones).length}
                            referenceValue={selectedSystems[referenceTableIndex].configuration.condensers.length + selectedSystems[referenceTableIndex].configuration.condensers.flatMap(cond => cond.zones).length}
                            lowerIsBetter
                          />
                        )}
                      </div>
                    )}
                    {feature === 'Indoor Units' && (
                      <div className='flex flex-col items-center gap-1'>
                        <div className='flex items-center gap-2'>
                          <span className='text-gray-500 font-normal'>Indoor Units:</span>
                          <span>{system.configuration.condensers.flatMap(cond => cond.zones).length}</span>
                          {referenceTableIndex !== null && referenceTableIndex !== optionIndex && (
                            <StatDifference
                              value={system.configuration.condensers.flatMap(cond => cond.zones).length}
                              referenceValue={selectedSystems[referenceTableIndex].configuration.condensers.flatMap(cond => cond.zones).length}
                              lowerIsBetter
                            />
                          )}
                        </div>
                        {showAdvanced && (
                          <div className='w-full mt-1 text-center'>
                            {(() => {
                              const allZones = system.configuration.condensers.flatMap(cond => cond.zones);
                              const uniqueProducts = new Map<string, any>();
                              allZones.forEach(zone => {
                                if (zone.selectedProduct) {
                                  uniqueProducts.set(zone.selectedProduct.name, zone.selectedProduct);
                                }
                              });
                              return (
                                <>
                                  {Array.from(uniqueProducts.values()).map((product: AirHandlerProduct, index) => {
                                    const installationRequirements = product.requirements
                                      .filter(requirement => requirement.type === 'installation')
                                      .map(requirement => requirement.value);

                                    const drainageRequirements = product.requirements
                                      .filter(requirement => requirement.type === 'drainage')
                                      .map(requirement => requirement.value);

                                    const clearances = product.dimensions
                                      ? [
                                        product.dimensions.clearanceFront ? `Front Clearance: ${product.dimensions.clearanceFront}"` : null,
                                        product.dimensions.clearanceSides ? `Side Clearance: ${product.dimensions.clearanceSides}"` : null,
                                        product.dimensions.clearanceTop ? `Top Clearance: ${product.dimensions.clearanceTop}"` : null,
                                        product.dimensions.clearanceBottom ? `Bottom Clearance: ${product.dimensions.clearanceBottom}"` : null
                                      ].filter(Boolean)
                                      : [];

                                    const allRequirements = [...installationRequirements, ...drainageRequirements];

                                    return (
                                      <div key={index} className='text-sm text-gray-700 mb-4'>
                                        <div className='font-semibold text-black mb-2'>{product.name}</div>
                                        <div className='font-semibold mt-2 text-gray-500'>Requirements:</div>
                                        {allRequirements.length > 0 ? (
                                          <ul className='list-disc list-inside space-y-1'>
                                            {allRequirements.map((requirement, reqIndex) => (
                                              <li key={reqIndex} className='text-gray-500 font-normal'>{requirement}</li>
                                            ))}
                                          </ul>
                                        ) : (
                                          <span className='text-gray-500 font-normal'>Requirements unavailable</span>
                                        )}
                                        {clearances.length > 0 && (
                                          <div>
                                            <div className='font-semibold mt-2 text-gray-500'>Clearances:</div>
                                            <div className='flex flex-col space-y-1'>
                                              {clearances.map((clearance, clearanceIndex) => (
                                                <div key={clearanceIndex} className='text-gray-500 font-normal'>{clearance}</div>
                                              ))}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    );
                                  })}
                                </>
                              );
                            })()}
                          </div>
                        )}
                      </div>
                    )}
                    {feature === 'Outdoor Units' && (
                      <div className='flex flex-col items-center gap-1'>
                        <div className='flex items-center gap-2'>
                          <span className='text-gray-500 font-normal'>Outdoor Units:</span>
                          <span>{system.configuration.condensers.length}</span>
                          {referenceTableIndex !== null && referenceTableIndex !== optionIndex && (
                            <StatDifference
                              value={system.configuration.condensers.length}
                              referenceValue={selectedSystems[referenceTableIndex].configuration.condensers.length}
                              lowerIsBetter
                            />
                          )}
                        </div>
                        {showAdvanced && (
                          <div className='w-full mt-1 text-center'>
                            {(() => {
                              const uniqueOutdoorProducts = new Map<string, any>();
                              system.configuration.condensers.forEach(condenser => {
                                if (condenser.selectedProduct) {
                                  uniqueOutdoorProducts.set(condenser.selectedProduct.name, condenser.selectedProduct);
                                }
                              });
                              return (
                                <>
                                  {Array.from(uniqueOutdoorProducts.values()).map((product: CondenserProduct, index) => {
                                    const installationRequirements = product.requirements
                                      .filter(requirement => requirement.type === 'installation' || requirement.type === 'drainage')
                                      .map(requirement => requirement.value);

                                    return (
                                      <div key={index} className='text-sm text-gray-700'>
                                        <div className='font-semibold text-black mb-2'>{product.name}</div>
                                        <div className='font-semibold mt-2 text-gray-500'>Requirements:</div>
                                        {installationRequirements.length > 0 ? (
                                          <ul className='list-disc list-inside space-y-1'>
                                            {installationRequirements.map((requirement, reqIndex) => (
                                              <li key={reqIndex} className='text-gray-500 font-normal'>{requirement}</li>
                                            ))}
                                          </ul>
                                        ) : (
                                          <span className='text-gray-500 font-normal'>Requirements unavailable</span>
                                        )}
                                      </div>
                                    );
                                  })}
                                </>
                              );
                            })()}
                          </div>
                        )}
                      </div>
                    )}
                    {feature === 'Indoor Visibility' && (
                      <div className='flex items-center gap-1 whitespace-nowrap'>
                        <span>{getComparisonValue('aesthetics', system, systemOptions)}</span>
                        {referenceTableIndex !== null && referenceTableIndex !== optionIndex && (
                          <StatDifference
                            value={getComparisonValue('aesthetics', system, systemOptions)}
                            referenceValue={getComparisonValue('aesthetics', selectedSystems[referenceTableIndex], systemOptions)}
                          />
                        )}
                      </div>
                    )}
                    {feature === 'Installation Difficulty' && (
                      <div className='flex items-center gap-1 whitespace-nowrap'>
                        <span>{getComparisonValue('difficulty', system, systemOptions)}</span>
                        {referenceTableIndex !== null && referenceTableIndex !== optionIndex && (
                          <StatDifference
                            value={getComparisonValue('difficulty', system, systemOptions)}
                            referenceValue={getComparisonValue('difficulty', selectedSystems[referenceTableIndex], systemOptions)}
                          />
                        )}
                      </div>
                    )}
                    {feature === 'Potential Rebates' && (
                      <div className='flex items-center whitespace-nowrap'>
                        <span>${(system.totalRebates ?? 0).toLocaleString()}</span>
                        {referenceTableIndex !== null && referenceTableIndex !== optionIndex && (
                          <StatDifference
                            value={Math.floor(system.totalRebates ?? 0)}
                            referenceValue={Math.min(selectedSystems[referenceTableIndex].totalRebates ?? 0, selectedSystems[referenceTableIndex].price)}
                            formatFn={formatUSD}
                          />
                        )}
                      </div>
                    )}
                    {feature === 'Price After Rebates' && (
                      <div className='flex items-center gap-1 whitespace-nowrap'>
                        <span>${Math.max(system.price - (system.totalRebates ?? 0), 0).toLocaleString()}</span>
                        {referenceTableIndex !== null && referenceTableIndex !== optionIndex && (
                          <StatDifference
                            value={Math.max(system.price - (system.totalRebates ?? 0), 0)}
                            referenceValue={Math.max(selectedSystems[referenceTableIndex].price - (selectedSystems[referenceTableIndex].totalRebates ?? 0), 0)}
                            formatFn={formatUSD}
                            lowerIsBetter
                          />
                        )}
                      </div>
                    )}
                    {feature === 'Amperage' && (
                      <div className='flex items-center gap-1 whitespace-nowrap'>
                        <span>{system.amperage != null && system.amperage !== 0 ? system.amperage : 'unavailable'}</span>
                        {referenceTableIndex !== null && referenceTableIndex !== optionIndex &&
                          system.amperage != null && system.amperage !== 0 &&
                          selectedSystems[referenceTableIndex].amperage != null && selectedSystems[referenceTableIndex].amperage !== 0 && (
                            <StatDifference
                              value={system.amperage}
                              referenceValue={selectedSystems[referenceTableIndex].amperage ?? 0}
                              lowerIsBetter
                            />
                          )}
                      </div>
                    )}
                    {feature === 'Outdoor Visibility' && (
                      <div className='flex items-center gap-1 whitespace-nowrap'>
                        <span>{system.outdoorVisibility ?? 'N/A'}</span>
                        {referenceTableIndex !== null && referenceTableIndex !== optionIndex && (
                          <StatDifference
                            value={system.outdoorVisibility ?? 'N/A'}
                            referenceValue={selectedSystems[referenceTableIndex].outdoorVisibility ?? 'N/A'}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          {referenceTableIndex !== optionIndex && <Tooltip text='Click to compare' />}
        </div>
      </button>
      <div className="flex flex-col gap-5 pt-5">
        {system.quotableAccessories.length > 0 && (
          <Button
            text={showAllItems ? 'Show Fewer Items' : 'Show All Items'}
            color='secondary'
            size='lg'
            fullWidth
            onClick={handleShowAllItems}
          />
        )}
        <Button
          text='Buy Now'
          size='lg'
          fullWidth
          onClick={() => notification('Feature not yet enabled', 'warning')}
        />
      </div>
    </div>
  ) : null;
}
