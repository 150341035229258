import React, { useState, ReactNode, useRef } from 'react';
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  FloatingPortal,
  FloatingArrow,
  arrow
} from '@floating-ui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

type WarningTooltipProps = {
  children?: ReactNode;
  constrainWidth?: boolean;
  type?: 'warning' | 'error';
};

export default function WarningTooltip({
  children,
  constrainWidth = true,
  type = 'warning'
}: WarningTooltipProps) {
  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef(null);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "top",
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        fallbackAxisSideDirection: "start"
      }),
      shift(),
      arrow({
        element: arrowRef
      }),
    ]
  });

  const hover = useHover(context, { move: false });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role
  ]);

  const classes = clsx(
    'w-max bg-[#444] text-white text-sm px-2 py-1 rounded z-50',
    constrainWidth && 'max-w-[300px]'
  );

  const iconClasses = clsx(
    'h-5 w-5 cursor-help',
    type === 'warning' ? 'text-yellow-500 hover:text-yellow-700' : 'text-red-500 hover:text-red-700'
  );

  return (
    <>
      <div className='inline-block' ref={refs.setReference} {...getReferenceProps()}>
        <ExclamationTriangleIcon className={iconClasses} />
      </div>
      <FloatingPortal>
        {isOpen && (
          <div
            className={classes}
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <FloatingArrow fill='#444' ref={arrowRef} context={context} />
            {children}
          </div>
        )}
      </FloatingPortal>
    </>
  );
}

WarningTooltip.defaultProps = {
  constrainWidth: true,
  type: 'warning'
};