import React, { useContext, useEffect, useRef, useState, useLayoutEffect } from 'react';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  PhotoIcon,
  ExclamationTriangleIcon,
  CheckIcon
} from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { removeNullishValues, warningModal } from 'utils/helpers';
import OpenAI from 'openai';
import { findSpaceById } from 'utils/structure';
import {
  Zone,
  AirHandlerProduct,
  Condenser,
  AirHandlerTypeCode,
  AirHandlerTypeLabel,
  Configuration,
  AirHandlerProductWithPriceRange
} from 'data/types';
import axios from 'utils/api';
import { ConfigurationContext, DataContext, ProjectContext, UIContext } from 'data/context';
import Button from 'components/inputs/button';
import ProgressIndicator from 'components/progress-indicator';
import Tag from 'components/tag';
import Select from 'components/inputs/select';
import { useSpring, animated } from '@react-spring/web';
import airHandlerBenefitsMini from 'data/air-handler-benefits-mini.json';
import Tooltip from 'components/tooltip';
import { airHandlerTypeCodes } from 'data/constants';

type AirHandlerPickerProps = {
  configId: string;
  condenser: Condenser;
  zone: Zone;
};

type Features = {
  [key in AirHandlerTypeCode]?: string[];
};

export default function AirHandlerPicker({
  configId,
  condenser,
  zone
}: AirHandlerPickerProps) {
  const {
    projectDetails,
    structureTypeSelected,
    structureSubtypeSelected,
    projectSettings,
    customerValues
  } = useContext(ProjectContext);
  const {
    structure,
    configurations,
    setConfigurations
  } = useContext(ConfigurationContext);
  const {
    airHandlerProducts,
    structureTypes
  } = useContext(DataContext);
  const {
    preferredAirHandlerErrors,
    activeAirHandlerPicker,
    setActiveAirHandlerPicker
  } = useContext(UIContext);
  const configuration = configurations.find((c) => c.uniqueId === configId);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [neverShowFilter, setNeverShowFilter] = useState<AirHandlerTypeLabel | ''>('');
  const [options, setOptions] = useState<AirHandlerProductWithPriceRange[]>([]);
  const [filteredOptions, setFilteredOptions] = useState<AirHandlerProductWithPriceRange[]>([]);
  const [explanation, setExplanation] = useState('');
  const [features, setFeatures] = useState<Features | null>(null);
  const [featuresAttempted, setFeaturesAttempted] = useState(false);
  const [hasBeenRanked, setHasBeenRanked] = useState(false);
  const [zoneAttributeValues, setZoneAttributeValues] = useState<
    { [zoneId: string]: { [key: string]: any } }
  >({});
  const drawerRef = useRef<HTMLDivElement>(null);
  const [spring, api] = useSpring(() => { });
  const columnWidth = 450;
  const optionsRef = useRef(options);

  optionsRef.current = options;

  const imageNames = {
    'High Wall': 'air_handler_wall.jpg',
    'Ceiling Cassette Standard': 'air_handler_cassette.jpg',
    'Low Wall': 'air_handler_low_wall.png',
    'Floor Ceiling Console': 'air_handler_console.jpg',
    'Multi Room': 'air_handler_concealed.jpg',
    'High Wall DIY': 'air_handler_wall.jpg',
    'Ceiling Cassette Slim Fit': 'air_handler_cassette.jpg',
    'Ceiling Cassette Drop Ceiling': 'air_handler_cassette.jpg',
    'Ducted': 'air_handler_concealed.jpg'
  };

  const possibleConflicts = [
    {
      attribute: 'ceiling_fan',
      attributeValue: 'Yes',
      airHandlerTypes: ['cassette', 'cassette_16', 'cassette_drop_ceiling'],
      modalText: 'Ceiling Cassette air handlers are not recommended for spaces with ceiling fans or chandeliers. Be sure to confirm clearances with customer.',
      tooltipText: 'Not recommended for spaces with ceiling fans/chandeliers.',
      operator: 'equals'
    },
    {
      attribute: 'wall_obstructions',
      attributeValue: true,
      airHandlerTypes: ['wall', 'wallDIY'],
      modalText: 'High Wall air handlers are not recommended for spaces with wall obstructions. Be sure to confirm clearances with customer.',
      tooltipText: 'Not recommended for spaces with wall obstructions.',
      operator: 'equals'
    }
  ];

  if (zone.zoneItems.length > 1) {
    possibleConflicts.push({
      attribute: 'share_air',
      attributeValue: 'Not Sharing',
      airHandlerTypes: Object.values(airHandlerTypeCodes).filter((type) => type !== 'concealed' && type !== 'ducted'),
      modalText: 'This air handler type is not recommended for multiple spaces unless they can all share air with each other. Be sure to confirm with customer.',
      tooltipText: 'Not recommended unless sharing air between all spaces.',
      operator: 'equals'
    });
  }

  useEffect(() => {
    if (dataLoaded) {
      setDrawerOpen(activeAirHandlerPicker === zone.uniqueId);
    }
  }, [activeAirHandlerPicker, dataLoaded]);

  useEffect(() => {
    api.start({
      height: drawerOpen ? drawerRef.current?.scrollHeight ?? 0 : 0,
      config: { tension: 300, friction: 30 }
    });
  }, [features, drawerOpen, detailsOpen, filteredOptions]);

  useEffect(() => {
    if (!drawerOpen) {
      setDetailsOpen(false);
    }
  }, [drawerOpen]);

  useEffect(() => {
    setHasBeenRanked(configurations.every((config) =>
      config.condensers.every(
        (cond) => cond.rankedSystems.length > 0
      )
    ));
  }, [configurations]);

  useEffect(() => {
    const newZoneAttributeValues: { [zoneId: string]: { [key: string]: any } } = {};
    zone.zoneItems.forEach((zoneItem) => {
      const structureItem = findSpaceById(structure, zoneItem.structureItemId);
      if (structureItem) {
        newZoneAttributeValues[zoneItem.uniqueId] = Object.fromEntries(structureItem.attributes.map((a) => ([a.code, a.value])));
      }
    });
    setZoneAttributeValues(newZoneAttributeValues);
  }, [zone, structure]);

  const getProductFeatures = async () => {
    const config = configurations.find(c => c.uniqueId === configId) ?? {} as Configuration;
    const simplifiedProjectDetails = projectDetails ? {
      description: projectDetails.description,
      system_selector_notes: projectDetails.system_selector_notes
    } : { description: '', system_selector_notes: '' };
    const dataInput = removeNullishValues({
      projectDetails: simplifiedProjectDetails,
      structureInfo:
        structureTypeSelected && structureSubtypeSelected
          ? {
            type: structureTypes.find((st) => st.id === structureTypeSelected)?.label,
            subType: structureTypes.find((st) => st.subtypes.some((sst) => sst.id === structureSubtypeSelected))?.subtypes.find((sst) => sst.id === structureSubtypeSelected)?.label
          }
          : undefined,
      projectSettings: Object.fromEntries(projectSettings.map((a) => ([a.label, a.value]))),
      customerValues: Object.fromEntries(customerValues.map((a) => ([a.label, a.value]))),
      systemConfiguration: { name: config.name, condensers: config.condensers.map(c => ({ name: c.name, id: c.uniqueId, zones: c.zones.map(z => ({ name: z.name, id: z.uniqueId, airHandlerRoomPlacement: z.zoneItems.map(zi => ({ name: zi.name, BTUs: zi.BTUs })) })) })) },
      airHandlerTypeOptions: options.map(o => ({
        description: o.description,
        type: o.type,
        coolingBTU: o.coolingBTU,
        heatingBTU: o.heatingBTU,
        dimensions: o.dimensions,
        minPrice: o.minPrice,
        maxPrice: o.maxPrice,
        model: o.model,
        name: o.name,
        productId: o.productId,
        requirements: o.requirements,
        warranties: o.warranties,
        wifiEnabled: o.wifiEnabled
      })),
      condenserId: condenser.uniqueId,
      airHandlersRoomPlacement: config.condensers.flatMap(cond =>
        cond.zones.flatMap(z =>
          z.zoneItems.map(item => ({
            airHandlersPlacedInThisRoom: item.name,
            roomsBTUs: item.BTUs
          }))
        )
      ),
    });

    const messages: OpenAI.Chat.Completions.ChatCompletionMessageParam[] = [
      {
        role: 'system',
        content:
          `I am tasked with generating an easily understood 20 word sentence on why a given air handler option 
          is a great fit for a given "airHandlersRoomPlacement" provided by the user.
          I focus on only the most relevant key benefits provided for each air handler type. I avoid complicated language and focus on easy to understand benefits
          that customers can easily understand. 
          I only reference the most relevant benefits from the "Benefits" list when generating the 20 word sales pitch and I take into consideration the user provided 
          rooms ("airHandlersRoomPlacement") and structure ("structureInfo") when choosing which benefits are best to communicate.
          I especially consider the customer's preferences ("customerValues") and the user's structure ("structureInfo") provided by the user. 
          My answer should be a JSON string in the form of an object with keys and a values, where the keys are the "type" of "airHandlerTypeOptions" 
          and value is a single 20 word sentence. 
          I always have exactly one key and one value for each "airHandlerTypeOptions" listed here: ${options.map(o => o.type).join(', ')}! 
          I generate one air handler key for each of these options: ${options.map(o => o.type).join(', ')}!
          I do not use markdown syntax. I do not mention specific model name and instead reference airHandlerTypeOptions names. I do not mention specific BTU values.
          Here are the "Benefits" of different air handlers types which I will always reference when generating benefits for the user, "Benefits": ${JSON.stringify(airHandlerBenefitsMini)}.
          I always reference these "Benefits" when generating my response to the user. I respond with a JSON object directly, and I DO NOT format with markdown syntax!
          `
      },
      {
        role: 'user',
        content:
          `Please take a look at my complete information here: ${JSON.stringify(dataInput)}.
          Please assign key values pairs with the keys as the air handler type and the value as an easily understood 20 word
          sentence on why these air handler types (${options.map(o => o.type).join(', ')})
          are a great choice for me, focusing on the most relevant and applicable benefits for each air handler. 
          Please avoid using specific models, BTU values, and technical language. Use easy to understand benefits that I can appreciate and understand.
          Please be sure to refer to the air handler types by their air handler type name (airHandlerTypeOptions names), not the model name.
          Always consider my "airHandlersRoomPlacement", my "structureInfo", and the "Benefits" mentioned above, when generating your 20 word sentence
          on why each air handler type is a great fit for me. DO NOT format with markdown syntax. Please respond with the JSON object directly.
          `
      }
    ];
    try {
      setDataLoaded(true);
      const reply = (await axios.post(
        'ai?type=json',
        messages
      )).data;
      const parsedData = JSON.parse(reply.content) as { [key in AirHandlerTypeLabel]: string };
      if (parsedData) {
        if (optionsRef.current.every((o) => parsedData[o.type])) {
          const newFeatures = Object.fromEntries(Object.entries(parsedData).map(([key, value]) => ([airHandlerTypeCodes[key], [value]]))) as Features;
          setFeatures(newFeatures);
          setConfigurations((draft) => {
            const configIndex = draft.findIndex((c) => c.uniqueId === configId);
            if (configIndex !== -1) {
              const condIndex = draft[configIndex].condensers.findIndex((c) => c.uniqueId === condenser.uniqueId);
              if (condIndex !== -1) {
                const zoneIndex = draft[configIndex].condensers[condIndex].zones.findIndex((z) => z.uniqueId === zone.uniqueId);
                if (zoneIndex !== -1) {
                  // eslint-disable-next-line no-param-reassign
                  draft[configIndex].condensers[condIndex].zones[zoneIndex].airHandlerFeatures = Object.entries(newFeatures).map(([key, value]) => ({ airHandlerType: key, features: value })) as {
                    airHandlerType: AirHandlerTypeCode;
                    features: string[];
                  }[];
                }
              }
            }
          });
        } else {
          console.error(
            `Invalid JSON for ${condenser.name} / ${zone.name} features: `,
            parsedData
          );
        }
      } else {
        console.error(
          `Error parsing ${condenser.name} / ${zone.name} features: `,
          reply.content.replace(/^[^{]*/, '').replace(/[^}]*$/, '')
        );
      }
    } catch (err) {
      console.error(err);
    }
    setDataLoaded(true);
    setFeaturesAttempted(true);
  };

  useLayoutEffect(() => {
    if (options.length > 0 && hasBeenRanked) {
      if (options.every(o => zone.airHandlerFeatures?.find(f => f.airHandlerType === airHandlerTypeCodes[o.type]))) {
        const newFeatures: Features = {};
        options.forEach((o) => {
          newFeatures[airHandlerTypeCodes[o.type]] = zone.airHandlerFeatures?.find(f => f.airHandlerType === airHandlerTypeCodes[o.type])?.features ?? ['Error'];
        });
        setFeatures(newFeatures);
        setDataLoaded(true);
      } else {
        getProductFeatures();
      }
    }
  }, [
    JSON.stringify(options.map(o => o.type)),
    hasBeenRanked
  ]);

  const updateProductOptions = () => {
    const config = configurations.find((c) => c.uniqueId === configId);
    if (config) {
      condenser.zones.forEach((currentZone) => {
        const systemsStillAvailable = condenser.rankedSystems.filter((system) => {
          let valid = false;
          const zoneIds = [
            system.airHandler1Unique,
            system.airHandler2Unique,
            system.airHandler3Unique,
            system.airHandler4Unique,
            system.airHandler5Unique
          ];
          const airHandlerTypes = [
            system.airHandler1Type,
            system.airHandler2Type,
            system.airHandler3Type,
            system.airHandler4Type,
            system.airHandler5Type
          ];
          if (
            condenser.zones.every((z) => (
              !z.selectedProduct ||
              currentZone.uniqueId === z.uniqueId ||
              zoneIds.some(
                (zoneId, index) =>
                  z.selectedProduct &&
                  z.uniqueId === zoneId &&
                  airHandlerTypeCodes[z.selectedProduct.type] ===
                  airHandlerTypes[index]
              )
            ))
          ) {
            valid = true;
          }
          return valid;
        });
        const productOptions: AirHandlerProduct[] = systemsStillAvailable
          .map((s) => {
            const zoneIds = [
              s.airHandler1Unique,
              s.airHandler2Unique,
              s.airHandler3Unique,
              s.airHandler4Unique,
              s.airHandler5Unique
            ];
            const airHandlerProductIds = [
              s.airHandler1ProductId,
              s.airHandler2ProductId,
              s.airHandler3ProductId,
              s.airHandler4ProductId,
              s.airHandler5ProductId
            ];
            let product: AirHandlerProduct | undefined;
            zoneIds.forEach((zoneId, index) => {
              if (zoneId === zone.uniqueId) {
                product = airHandlerProducts.find(
                  (ahp) => ahp.productId === airHandlerProductIds[index]
                );
              }
            });
            return product;
          })
          .filter((p) => p !== undefined) as AirHandlerProduct[];
        const newOptions = productOptions.reduce((acc, product) => {
          if (!acc.find((p) => p.type === product.type)) {
            const typePrices = productOptions.filter((o) => o.type === product.type).map((o) => o.price);
            const minPrice = Math.min(...typePrices);
            const maxPrice = Math.max(...typePrices);
            return [...acc, { ...product, minPrice, maxPrice }];
          }
          return acc;
        }, [] as AirHandlerProductWithPriceRange[]);
        if (currentZone.uniqueId === zone.uniqueId) {
          setOptions(newOptions);
        }
      });
    } else {
      console.error('config not found');
    }
  };

  const updateSelectedProduct = (selection: AirHandlerProduct | null) => {
    setConfigurations((draft) => {
      const config = draft.find((c) => c.uniqueId === configId);
      if (config) {
        const cond = config.condensers.find(
          (c) => c.uniqueId === condenser.uniqueId
        );
        if (cond) {
          cond.selectedProduct = undefined;
          const z = cond.zones.find((zz) => zz.uniqueId === zone.uniqueId);
          if (z) {
            z.selectedProduct = selection ?? undefined;
          }
        }
      }
    });
    if (selection) {
      setActiveAirHandlerPicker(null);
      possibleConflicts.forEach((conflict) => {
        if (zone.zoneItems.some((zi) => ((conflict.operator === 'equals' && zoneAttributeValues[zi.uniqueId]?.[conflict.attribute] === conflict.attributeValue) || (conflict.operator === 'not equals' && zoneAttributeValues[zi.uniqueId]?.[conflict.attribute] !== conflict.attributeValue)) && conflict.airHandlerTypes.includes(airHandlerTypeCodes[selection.type]))) {
          warningModal(conflict.modalText);
        }
      });
    }
  };

  useEffect(() => {
    const newFilteredOptions = options.filter((o) => o.type !== neverShowFilter);
    setFilteredOptions(newFilteredOptions);
    if (neverShowFilter) {
      setExplanation(`Showing valid air handler types excluding ${neverShowFilter}`);
    } else {
      setExplanation('Showing all valid air handler types');
    }
  }, [options, neverShowFilter]);

  useEffect(() => {
    if (condenser.rankedSystems.length > 0 && airHandlerProducts.length > 0) {
      updateProductOptions();
    }
  }, [configuration?.condensers, airHandlerProducts]);

  useEffect(() => {
    if (filteredOptions.length === 1 && filteredOptions[0].type === 'Ducted' && !zone.selectedProduct) {
      updateSelectedProduct(filteredOptions[0]);
    }
  }, [filteredOptions]);

  if (zone.selectedProduct && zone.selectedProduct.type === 'Ducted') {
    return null;
  }

  const unselectedHeaderClasses = clsx(
    'bg-white p-5 flex items-center justify-between',
    drawerOpen ? 'rounded-t-lg' : 'rounded-lg'
  );

  const selectedHeaderClasses = clsx(
    'bg-white p-5 flex flex-col gap-3',
    drawerOpen ? 'rounded-t-lg' : 'rounded-lg'
  );

  const toggleDrawer = () => {
    if (activeAirHandlerPicker === zone.uniqueId) {
      setActiveAirHandlerPicker(null);
    } else {
      setActiveAirHandlerPicker(zone.uniqueId);
    }
  };

  return (
    <div className='flex flex-col relative'>
      {!zone.selectedProduct ? (
        <div className={unselectedHeaderClasses}>
          <div className='flex items-center gap-2'>
            <ProgressIndicator required checked={!!zone.selectedProduct} />
            <span className='mr-3 text-lg font-bold'>
              {condenser.name} / {zone.name}
            </span>
            {zone.zoneItems.map((zoneItem) => (
              <Tag
                key={zoneItem.uniqueId}
                color='default'
                text={zoneItem.name}
              />
            ))}
          </div>
          <Button
            size='lg'
            color='secondary'
            text={drawerOpen ? 'Close' : 'Select Air Handler'}
            icon={drawerOpen ? ChevronUpIcon : ChevronDownIcon}
            iconSize='lg'
            iconPosition='right'
            onClick={toggleDrawer}
          />
        </div>
      ) : (
        <div className={selectedHeaderClasses}>
          <div className='flex items-center gap-2'>
            <ProgressIndicator required checked={!!zone.selectedProduct} />
            <span className='mr-3 text-lg font-bold'>
              {condenser.name} / {zone.name}
            </span>
            {zone.zoneItems.map((zoneItem) => (
              <Tag
                key={zoneItem.uniqueId}
                color='default'
                text={zoneItem.name}
              />
            ))}
          </div>
          <div className='flex items-center divide-x divide-gray-200'>
            <div className='flex grow basis-0 items-center gap-4'>
              <div className='relative flex h-14 w-20 items-center justify-center rounded-lg bg-gray-300 overflow-hidden'>
                <PhotoIcon className='h-6 w-6 text-gray-400' />
                <img
                  className='absolute left-0 top-0 h-full w-full'
                  src={`https://www.alpinehomeair.com/assets/images/blueridge-product-templates/misc/system-selector/air-handler/${imageNames[zone.selectedProduct.type]}`}
                  alt={`${zone.selectedProduct.type} air handler`}
                />
              </div>
              <div className='flex flex-col gap-1'>
                <span className='text-xs font-semibold uppercase text-gray-600'>
                  Type
                </span>
                <span className='text-lg font-bold'>
                  {zone.selectedProduct.type}
                </span>
              </div>
            </div>
            <div className='flex grow basis-0 flex-col gap-1 pl-10'>
              <span className='text-xs font-semibold uppercase text-gray-600'>
                Price
              </span>
              {filteredOptions.find(o => o.type === zone.selectedProduct?.type)?.minPrice ? (
                <span className='text-lg font-bold'>
                  {filteredOptions.find(o => o.type === zone.selectedProduct?.type)?.minPrice === filteredOptions.find(o => o.type === zone.selectedProduct?.type)?.maxPrice ?
                    `$${filteredOptions.find(o => o.type === zone.selectedProduct?.type)?.minPrice}` :
                    `$${filteredOptions.find(o => o.type === zone.selectedProduct?.type)?.minPrice} - $${filteredOptions.find(o => o.type === zone.selectedProduct?.type)?.maxPrice}`}
                </span>
              ) : (
                <span className='text-lg font-bold'>
                  Loading...
                </span>
              )}
            </div>
            <Button
              size='lg'
              color='secondary'
              onClick={toggleDrawer}
              text={drawerOpen ? 'Close' : 'Edit Selection'}
              icon={drawerOpen ? ChevronUpIcon : ChevronDownIcon}
              iconSize='lg'
              iconPosition='right'
            />
          </div>
        </div>
      )
      }
      <animated.div className='overflow-hidden' style={spring}>
        <div ref={drawerRef} className='rounded-b-lg border-t border-gray-200 bg-white'>
          <div className='w-full divide-y divide-gray-200'>
            <div className='flex items-center gap-3 px-5 py-3'>
              <span className='text-lg font-bold'>Filters</span>
              {options.length > 1 && (
                <div className='w-[300px]'>
                  <Select
                    onChange={(value) => setNeverShowFilter(value as AirHandlerTypeLabel)}
                    options={options.map((o) => o.type)}
                    placeholder='Never show...'
                    value={neverShowFilter}
                  />
                </div>
              )}
            </div>
            <div className='overflow-x-auto relative'>
              <table className='w-full inline-block' style={{ width: filteredOptions.length * columnWidth + 280 }}>
                <tbody className='w-full inline-block'>
                  <tr className='divide-x divide-x-reverse divide-gray-200 w-full inline-flex whitespace-normal'>
                    <td className='w-[280px] sticky left-0 bg-white z-10 border-r border-b border-gray-200' />
                    {filteredOptions.map((option, optionIndex) => (
                      <td
                        key={option.productId}
                        className='flex flex-col items-center gap-3 p-5 border-b border-gray-200'
                        style={{ width: columnWidth }}
                      >
                        <button className='relative flex h-[280px] w-full items-center justify-center rounded-lg bg-gray-300 overflow-hidden'
                          onClick={() => updateSelectedProduct(zone.selectedProduct?.type === option.type ? null : option)}
                        >
                          <PhotoIcon className='h-6 w-6 text-gray-400' />
                          <img
                            className='absolute left-0 top-0 h-full w-full'
                            src={`https://www.alpinehomeair.com/assets/images/blueridge-product-templates/misc/system-selector/air-handler/${imageNames[option.type]}`}
                            alt={`${option.type} air handler`}
                            draggable={false}
                          />
                          <div className='absolute left-2 right-2 top-2 flex flex-wrap gap-1'>
                            {optionIndex === 0 && (
                              <Tag color='purple' text='Recommended' />
                            )}
                            {options.length > 1 && option.minPrice === Math.min(...filteredOptions.map(o => o.minPrice)) && (
                              <Tag color='purple' text='Cheapest' />
                            )}
                            {optionIndex !== 0 && option.minPrice !== Math.min(...filteredOptions.map(o => o.minPrice)) && option.minPrice < options[0].minPrice && (
                              <Tag color='green' text='Cheaper' />
                            )}
                            {optionIndex !== 0 && option.aestheticsRank < options[0].aestheticsRank && (
                              <Tag color='green' text='Less Visible' />
                            )}
                            {optionIndex !== 0 && option.installComplexityRank < options[0].installComplexityRank && (
                              <Tag color='green' text='Easier Install' />
                            )}
                            {possibleConflicts.map((conflict, conflictIndex) => (
                              zone.zoneItems.some((zi) => ((conflict.operator === 'equals' && zoneAttributeValues[zi.uniqueId]?.[conflict.attribute] === conflict.attributeValue) || (conflict.operator === 'not equals' && zoneAttributeValues[zi.uniqueId]?.[conflict.attribute] !== conflict.attributeValue)) && conflict.airHandlerTypes.includes(airHandlerTypeCodes[option.type])) && (
                                <div key={conflictIndex} className='relative cursor-help'>
                                  <Tag color='yellow' icon={ExclamationTriangleIcon} text='Possible Conflict' />
                                  <Tooltip text={conflict.tooltipText} />
                                </div>
                              )
                            ))}
                          </div>
                        </button>
                        <span className='text-xl font-bold text-center'>{option.type}</span>
                        <span className='text-xl font-bold'>
                          {option.minPrice === option.maxPrice ? `$${option.minPrice}` : `$${option.minPrice} - $${option.maxPrice}`}
                        </span>
                        <Button
                          fullWidth
                          color={zone.selectedProduct?.type === option.type ? 'green' : 'primary'}
                          text={zone.selectedProduct?.type === option.type ? 'Selected' : 'Select'}
                          onClick={() => updateSelectedProduct(zone.selectedProduct?.type === option.type ? null : option)}
                          icon={zone.selectedProduct?.type === option.type ? CheckIcon : undefined}
                        />
                      </td>
                    ))}
                  </tr>
                  <tr className='flex divide-x divide-x-reverse divide-gray-200'>
                    <td className='w-[280px] sticky left-0 bg-white z-10 border-r border-gray-200 p-5'>
                      <span className='text-sm font-semibold'>Features</span>
                    </td>
                    {filteredOptions.map((option) => (
                      <td
                        key={option.productId}
                        className='p-5 border-gray-200'
                        style={{ width: columnWidth }}
                      >
                        <ul className='list-disc space-y-3'>
                          {features?.[airHandlerTypeCodes[option.type]] ? (
                            features?.[airHandlerTypeCodes[option.type]]?.map((feature) => (
                              <li key={feature} className='ml-5 text-sm'>
                                {feature}
                              </li>
                            ))
                          ) : (
                            <li className='ml-5 text-sm'>
                              {featuresAttempted ? 'Error' : 'Loading...'}
                            </li>
                          )}
                        </ul>
                      </td>
                    ))}
                  </tr>
                  {detailsOpen && (
                    <>
                      <tr className='flex divide-x divide-x-reverse divide-gray-200'>
                        <td className='w-[280px] sticky left-0 bg-white z-10 border-r border-t border-gray-200 p-5'>
                          <span className='text-sm font-semibold'>
                            Drainage Requirements
                          </span>
                        </td>
                        {filteredOptions.map((option) => (
                          <td
                            key={option.productId}
                            className='p-5 border-t border-gray-200'
                            style={{ width: columnWidth }}
                          >
                            <ul className='list-disc space-y-3'>
                              {option.requirements
                                .filter((r) => r.type === 'drainage')
                                .map((requirement) => (
                                  <li key={requirement.value} className='ml-5 text-sm'>
                                    {requirement.value}
                                  </li>
                                ))}
                            </ul>
                          </td>
                        ))}
                      </tr>
                      <tr className='flex divide-x divide-x-reverse divide-gray-200'>
                        <td className='w-[280px] sticky left-0 bg-white z-10 border-r border-t border-gray-200 p-5'>
                          <span className='text-sm font-semibold'>
                            Installation Requirements
                          </span>
                        </td>
                        {filteredOptions.map((option) => (
                          <td
                            key={option.productId}
                            className='p-5 border-t border-gray-200'
                            style={{ width: columnWidth }}
                          >
                            <ul className='list-disc space-y-3'>
                              {option.requirements
                                .filter((r) => r.type === 'installation')
                                .map((requirement) => (
                                  <li key={requirement.value} className='ml-5 text-sm'>
                                    {requirement.value}
                                  </li>
                                ))}
                            </ul>
                          </td>
                        ))}
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <div className='w-full'>
              <button
                type='button'
                className='flex w-full cursor-pointer items-center justify-center py-3 text-gray-600 hover:text-gray-900'
                onClick={() => setDetailsOpen((oldValue) => !oldValue)}
              >
                <span className='text-sm font-semibold'>
                  {detailsOpen ? 'Less Info' : 'More Info'}
                </span>
                {detailsOpen ? (
                  <ChevronUpIcon className='h-5 w-5' />
                ) : (
                  <ChevronDownIcon className='h-5 w-5' />
                )}
              </button>
            </div>
            <div className='w-full'>
              <div className='bg-blue-50 p-5 text-sm'>
                <ul className='list-disc'>
                  <li className='ml-5'>{explanation}</li>
                  {preferredAirHandlerErrors.find((error) => error.zoneId === zone.uniqueId) && (
                    <li className='ml-5 text-orange-500'>{preferredAirHandlerErrors.find((error) => error.zoneId === zone.uniqueId)?.error}</li>
                  )}
                  {Array.from(new Set(zone.zoneItems.map((zi) => zoneAttributeValues[zi.uniqueId]?.preferred_air_handler_type))).filter((t) => t).filter((preferredType) => !options.map(o => airHandlerTypeCodes[o.type]).includes(airHandlerTypeCodes[preferredType])).map((type) => (
                    <li key={type} className='ml-5 text-orange-500'>
                      {airHandlerTypeCodes[type] === 'concealed' ? 'Multi Room air handler not available due to sizing or airflow incompatibilities' : `${type} air handler not available due to sizing incompatibilities`}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </animated.div>
    </div >
  );
};