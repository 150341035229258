import React, { useState, ReactNode, useRef } from 'react';
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  FloatingPortal,
  FloatingArrow,
  arrow
} from '@floating-ui/react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

type InfoTooltipProps = {
  children?: ReactNode;
  constrainWidth?: boolean;
};

export default function InfoTooltip({
  children,
  constrainWidth = true
}: InfoTooltipProps) {
  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef(null);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "top",
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        fallbackAxisSideDirection: "start"
      }),
      shift(),
      arrow({
        element: arrowRef
      }),
    ]
  });

  const hover = useHover(context, { move: false });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role
  ]);

  const classes = clsx(
    'w-max bg-[#444] text-white text-sm px-2 py-1 rounded z-50',
    constrainWidth && 'max-w-[300px]'
  );

  return (
    <>
      <div className='inline-block' ref={refs.setReference} {...getReferenceProps()}>
        <InformationCircleIcon className='h-5 w-5 cursor-help text-gray-500 hover:text-gray-700' />
      </div>
      <FloatingPortal>
        {isOpen && (
          <div
            className={classes}
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <FloatingArrow fill='#444' ref={arrowRef} context={context} />
            {children}
          </div>
        )}
      </FloatingPortal>
    </>
  );
}

InfoTooltip.defaultProps = {
  constrainWidth: true
};