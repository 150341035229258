import React from 'react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { useDrag } from 'react-dnd';

type ZoneableSpaceProps = {
  id: string;
  label: string;
  configId: string;
};

export default function ZoneableSpace({
  id,
  label,
  configId
}: ZoneableSpaceProps) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: `config-${configId}-zoneable-space`,
    item: { id, type: 'zoneable-space' },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  }));

  const classes = clsx(
    'flex gap-1 items-center bg-gray-200 rounded-md py-1 pr-3 text-sm font-medium text-gray-700 whitespace-nowrap border-2 border-red-400',
    isDragging ? 'cursor-grabbing' : 'cursor-grab'
  );

  return (
    <div ref={drag} className={classes}>
      <div className='flex -ml-2'>
        <EllipsisVerticalIcon className='h-4 w-4 translate-x-3' />
        <EllipsisVerticalIcon className='h-4 w-4' />
      </div>
      {label}
    </div>
  );
}
