import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useState,
  ReactNode
} from 'react';
import { useImmer, Updater } from 'use-immer';
import {
  Attribute,
  AttomData,
  Location,
  ProjectDetails,
  LiveChatData,
  WeatherData,
  LocationData,
  SystemOption,
  RankedSystem
} from 'data/types';

type ProjectState = {
  projectSettings: Attribute[];
  setProjectSettings: Updater<Attribute[]> | (() => void);
  customerValues: Attribute[];
  setCustomerValues: Updater<Attribute[]> | (() => void);
  attomData: AttomData | null;
  setAttomData: Dispatch<SetStateAction<AttomData | null>> | (() => void);
  addressSearched: boolean,
  setAddressSearched: Dispatch<SetStateAction<boolean>> | (() => void),
  propertyLocation: Location | null;
  setPropertyLocation: Dispatch<SetStateAction<Location | null>> | (() => void);
  propertySummary: string | null;
  setPropertySummary: Dispatch<SetStateAction<string | null>> | (() => void);
  structureTypeSelected: number | null;
  setStructureTypeSelected:
  | Dispatch<SetStateAction<number | null>>
  | (() => void);
  structureSubtypeSelected: number | null;
  setStructureSubtypeSelected:
  | Dispatch<SetStateAction<number | null>>
  | (() => void);
  partialStructure: boolean | null;
  setPartialStructure: Dispatch<SetStateAction<boolean | null>> | (() => void);
  totalBTUs: { heating: number; cooling: number };
  setTotalBTUs:
  | Dispatch<SetStateAction<{ heating: number; cooling: number }>>
  | (() => void);
  locationData: LocationData | null;
  setLocationData: Dispatch<SetStateAction<LocationData | null>> | (() => void);
  weatherData: WeatherData | null;
  setWeatherData: Dispatch<SetStateAction<WeatherData | null>> | (() => void);
  projectDetails: ProjectDetails | null;
  setProjectDetails: Updater<ProjectDetails | null> | (() => void);
  warmUpNotes: string;
  setWarmUpNotes: Dispatch<SetStateAction<string>> | (() => void);
  liveChatData: LiveChatData | null;
  setLiveChatData: Dispatch<SetStateAction<LiveChatData | null>> | (() => void);
  liveChatSummary: string | null;
  setLiveChatSummary: Dispatch<SetStateAction<string | null>> | (() => void);
  quotesToSend: string[];
  setQuotesToSend: Dispatch<SetStateAction<string[]>> | (() => void);
  goalStatement: string | null;
  setGoalStatement: Dispatch<SetStateAction<string | null>> | (() => void);
  saveDebugSnapshot: boolean;
  setSaveDebugSnapshot: Dispatch<SetStateAction<boolean>> | (() => void);
  systemsForNewOption: RankedSystem[];
  setSystemsForNewOption: Dispatch<SetStateAction<RankedSystem[]>> | (() => void);
  systemOptions: SystemOption[];
  setSystemOptions: Updater<SystemOption[]> | (() => void);
  showDuctlessOptions: boolean | null;
  setShowDuctlessOptions: Dispatch<SetStateAction<boolean | null>> | (() => void);
};

const defaultProjectState: ProjectState = {
  projectSettings: [],
  setProjectSettings: () => { },
  customerValues: [],
  setCustomerValues: () => { },
  attomData: null,
  setAttomData: () => { },
  addressSearched: false,
  setAddressSearched: () => { },
  propertyLocation: null,
  setPropertyLocation: () => { },
  propertySummary: null,
  setPropertySummary: () => { },
  structureTypeSelected: null,
  setStructureTypeSelected: () => { },
  structureSubtypeSelected: null,
  setStructureSubtypeSelected: () => { },
  partialStructure: null,
  setPartialStructure: () => { },
  totalBTUs: { heating: 0, cooling: 0 },
  setTotalBTUs: () => { },
  locationData: null,
  setLocationData: () => { },
  weatherData: null,
  setWeatherData: () => { },
  projectDetails: null,
  setProjectDetails: () => { },
  warmUpNotes: '',
  setWarmUpNotes: () => { },
  liveChatData: null,
  setLiveChatData: () => { },
  liveChatSummary: null,
  setLiveChatSummary: () => { },
  quotesToSend: [],
  setQuotesToSend: () => { },
  goalStatement: null,
  setGoalStatement: () => { },
  saveDebugSnapshot: false,
  setSaveDebugSnapshot: () => { },
  systemsForNewOption: [],
  setSystemsForNewOption: () => { },
  systemOptions: [],
  setSystemOptions: () => { },
  showDuctlessOptions: null,
  setShowDuctlessOptions: () => { }
};

export const ProjectContext = createContext(defaultProjectState);

export function ProjectContextProvider({ children }: { children: ReactNode }) {
  const [projectSettings, setProjectSettings] = useImmer(defaultProjectState.projectSettings);
  const [customerValues, setCustomerValues] = useImmer(defaultProjectState.customerValues);
  const [attomData, setAttomData] = useState(defaultProjectState.attomData);
  const [addressSearched, setAddressSearched] = useState(defaultProjectState.addressSearched);
  const [propertyLocation, setPropertyLocation] = useState(defaultProjectState.propertyLocation);
  const [propertySummary, setPropertySummary] = useState(defaultProjectState.propertySummary);
  const [structureTypeSelected, setStructureTypeSelected] = useState(defaultProjectState.structureTypeSelected);
  const [structureSubtypeSelected, setStructureSubtypeSelected] = useState(defaultProjectState.structureSubtypeSelected);
  const [partialStructure, setPartialStructure] = useState(defaultProjectState.partialStructure);
  const [totalBTUs, setTotalBTUs] = useState(defaultProjectState.totalBTUs);
  const [locationData, setLocationData] = useState(defaultProjectState.locationData);
  const [weatherData, setWeatherData] = useState(defaultProjectState.weatherData);
  const [projectDetails, setProjectDetails] = useImmer(defaultProjectState.projectDetails);
  const [warmUpNotes, setWarmUpNotes] = useState(defaultProjectState.warmUpNotes);
  const [liveChatData, setLiveChatData] = useState(defaultProjectState.liveChatData);
  const [liveChatSummary, setLiveChatSummary] = useState(defaultProjectState.liveChatSummary);
  const [quotesToSend, setQuotesToSend] = useState(defaultProjectState.quotesToSend);
  const [goalStatement, setGoalStatement] = useState(defaultProjectState.goalStatement);
  const [saveDebugSnapshot, setSaveDebugSnapshot] = useState(defaultProjectState.saveDebugSnapshot);
  const [systemsForNewOption, setSystemsForNewOption] = useState(defaultProjectState.systemsForNewOption);
  const [systemOptions, setSystemOptions] = useImmer(defaultProjectState.systemOptions);
  const [showDuctlessOptions, setShowDuctlessOptions] = useState(defaultProjectState.showDuctlessOptions);

  return (
    <ProjectContext.Provider value={{
      projectSettings,
      setProjectSettings,
      customerValues,
      setCustomerValues,
      attomData,
      setAttomData,
      addressSearched,
      setAddressSearched,
      propertyLocation,
      setPropertyLocation,
      propertySummary,
      setPropertySummary,
      structureTypeSelected,
      setStructureSubtypeSelected,
      structureSubtypeSelected,
      setStructureTypeSelected,
      partialStructure,
      setPartialStructure,
      totalBTUs,
      setTotalBTUs,
      locationData,
      setLocationData,
      weatherData,
      setWeatherData,
      projectDetails,
      setProjectDetails,
      warmUpNotes,
      setWarmUpNotes,
      liveChatData,
      setLiveChatData,
      liveChatSummary,
      setLiveChatSummary,
      quotesToSend,
      setQuotesToSend,
      goalStatement,
      setGoalStatement,
      saveDebugSnapshot,
      setSaveDebugSnapshot,
      systemsForNewOption,
      setSystemsForNewOption,
      systemOptions,
      setSystemOptions,
      showDuctlessOptions,
      setShowDuctlessOptions
    }}
    >
      {children}
    </ProjectContext.Provider>
  );
}
