import React, {
  useContext,
  useCallback,
  useRef,
  useEffect,
  useState
} from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';
import { useDrag, useDragDropManager, useDrop } from 'react-dnd';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { DataContext, UIContext, ConfigurationContext, ProjectContext } from 'data/context';
import { Level } from 'data/types';
import { confirmationModal, createActiveItem } from 'utils/helpers';
import { createSection, createSpace, findSectionBySpaceId, getAllLabelsOfType } from 'utils/structure';
import SectionComponent from 'components/dnd/section';
import Tooltip from 'components/tooltip';
import clsx from 'clsx';

type LevelProps = {
  level: Level,
  showSections?: boolean,
  showSpaces?: boolean;
  focus?: 'levels' | 'sections' | 'spaces';
};

export default function LevelComponent({
  level,
  showSections = true,
  showSpaces = true,
  focus
}: LevelProps) {
  const { items, fenestration } = useContext(DataContext);
  const { setActiveItem, easyMode } = useContext(UIContext);
  const {
    structure,
    setStructure,
    configurations,
    selectedSystems
  } = useContext(ConfigurationContext);
  const { projectSettings } = useContext(ProjectContext);
  const projectSettingsRef = useRef(projectSettings);
  const structureRef = useRef(structure);
  const attributesRef = useRef(level.attributes);
  const [attentionNeeded, setAttentionNeeded] = useState(false);
  const [animate] = useAutoAnimate({ duration: 100 });
  const dndManager = useDragDropManager();
  const [separatableItemDragging, setSeparatableItemDragging] = useState(false);

  useEffect(() => {
    if (dndManager.getMonitor().isDragging()) {
      const levelItemIds = level.sections.flatMap((section) => section.spaces.map((space) => space.uniqueId));
      const itemId = dndManager.getMonitor().getItem().id;
      const itemSection = findSectionBySpaceId(structure, itemId);
      if ((levelItemIds.includes(itemId) && (itemSection?.spaces.length ?? 0) > 1) || (!levelItemIds.includes(itemId) && level.sections.length > 0)) {
        setSeparatableItemDragging(true);
      }
    } else {
      setSeparatableItemDragging(false);
    }
  }, [structure, dndManager.getMonitor().isDragging()]);

  useEffect(() => {
    setAttentionNeeded(
      level.sections.some((section) =>
        section.spaces.some((space) => !space.valid)
      )
    );
  }, [level.sections]);

  useEffect(() => {
    attributesRef.current = level.attributes;
    projectSettingsRef.current = projectSettings;
    structureRef.current = structure;
  }, [level.attributes, projectSettings, structure]);

  const handleDeleteClick = useCallback(() => {
    const deleteSelf = () => {
      setStructure((draft) => {
        const levelIndex = draft.findIndex((l) => l.uniqueId === level.uniqueId);
        if (levelIndex !== -1) {
          draft.splice(levelIndex, 1);
        }
      });
    };
    if (
      level.sections.some((section) => section.spaces.some((space) => space.modified)) ||
      configurations.length > 0 ||
      selectedSystems.length > 0
    ) {
      confirmationModal(`Are you sure you want to delete ${level.label}? This will reset all configurations and selected systems!`, deleteSelf);
    } else {
      deleteSelf();
    }
  }, [level, setStructure]);

  const addSpace = (itemId: number) => {
    const spaceItem = items.find((i) => i.id === itemId);
    if (spaceItem) {
      let spaceNumber = 2;
      let sectionNumber = 1;
      const existingSpaceLabels = getAllLabelsOfType(structureRef.current, 'space');
      const existingSectionLabels = getAllLabelsOfType(structureRef.current, 'section');
      const isMultipleSpaces = existingSpaceLabels.filter(label => label.startsWith(spaceItem.label)).length > 0;

      while (existingSpaceLabels.includes(`${spaceItem.label} ${spaceNumber}`)) {
        spaceNumber += 1;
      }
      while (existingSectionLabels.includes(`Area ${sectionNumber}`)) {
        sectionNumber += 1;
      }

      const newSpaceLabel = isMultipleSpaces ? `${spaceItem.label} ${spaceNumber}` : spaceItem.label;

      const newSpace = createSpace(spaceItem, {
        data: {
          label: newSpaceLabel
        },
        attributesToInherit: [...attributesRef.current, ...projectSettingsRef.current.filter((s) => !attributesRef.current.map(a => a.id).includes(s.id))]
      });
      const newSection = createSection({
        data: {
          label: `Area ${sectionNumber}`,
          spaces: [newSpace]
        }
      });
      setStructure((draft) => {
        const levelIndex = draft.findIndex((l) => l.uniqueId === level.uniqueId);
        if (levelIndex !== -1) {
          draft[levelIndex].sections.push(newSection);
        }
      });
      if (!easyMode) {
        setActiveItem(createActiveItem({ type: 'space', item: newSpace, items, fenestration }));
      }
    }
  };

  const moveSection = (sectionId: string, toLevelId: string) => {
    setStructure((draft) => {
      const fromLevelIndex = draft.findIndex((l) =>
        l.sections.some((s) => s.uniqueId === sectionId)
      );
      const toLevelIndex = draft.findIndex((l) => l.uniqueId === toLevelId);
      if (
        fromLevelIndex !== -1 &&
        toLevelIndex !== -1 &&
        fromLevelIndex !== toLevelIndex
      ) {
        const sectionIndex = draft[fromLevelIndex].sections.findIndex(
          (s) => s.uniqueId === sectionId
        );
        if (sectionIndex !== -1) {
          const [section] = draft[fromLevelIndex].sections.splice(
            sectionIndex,
            1
          );
          draft[toLevelIndex].sections.push(section);
        }
      }
    });
  };

  const moveSpace = (spaceId: string, toLevelId: string) => {
    let sectionNumber = 1;
    const existingSectionLabels = getAllLabelsOfType(structureRef.current, 'section');
    while (existingSectionLabels.includes(`Area ${sectionNumber}`)) {
      sectionNumber += 1;
    }
    setStructure((draft) => {
      const fromLevelIndex = draft.findIndex((l) =>
        l.sections.some((s) => s.spaces.some((sp) => sp.uniqueId === spaceId))
      );
      const toLevelIndex = draft.findIndex((l) => l.uniqueId === toLevelId);
      if (fromLevelIndex !== -1 && toLevelIndex !== -1) {
        const sectionIndex = draft[fromLevelIndex].sections.findIndex((s) =>
          s.spaces.some((sp) => sp.uniqueId === spaceId)
        );
        if (sectionIndex !== -1) {
          const spaceIndex = draft[fromLevelIndex].sections[
            sectionIndex
          ].spaces.findIndex((sp) => sp.uniqueId === spaceId);
          if (spaceIndex !== -1) {
            const [space] = draft[fromLevelIndex].sections[
              sectionIndex
            ].spaces.splice(spaceIndex, 1);
            const newSection = createSection({
              data: {
                label: `Area ${sectionNumber}`,
                spaces: [space]
              }
            });
            draft[toLevelIndex].sections.push(newSection);
          }
        }
      }
    });
  };

  const moveLevel = (fromLevelId: string) => {
    setStructure((draft) => {
      const fromLevelIndex = draft.findIndex((l) => l.uniqueId === fromLevelId);
      const toLevelIndex = draft.findIndex((l) => l.uniqueId === level.uniqueId);
      if (
        fromLevelIndex !== -1 &&
        toLevelIndex !== -1 &&
        fromLevelIndex !== toLevelIndex
      ) {
        const [levelToMove] = draft.splice(fromLevelIndex, 1);
        draft.splice(toLevelIndex, 0, levelToMove);
      }
    });
  };

  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type: 'level',
    item: { id: level.uniqueId, itemId: level.itemId, type: 'level' },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  }));

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ['new-space', 'space', 'section', 'level'],
    drop: (
      item: {
        id: string;
        label: string;
        type: 'new-space' | 'space' | 'section' | 'level';
        itemId?: number;
      },
      monitor
    ) => {
      if (item.type === 'new-space' && item.itemId && !monitor.didDrop()) {
        if (configurations.length > 0 || selectedSystems.length > 0) {
          confirmationModal('Are you sure you want to add a space? This will reset all configurations and selected systems!', () => {
            addSpace(item.itemId as number);
          });
        } else {
          addSpace(item.itemId);
        }
      }
      if (item.type === 'space' && !monitor.didDrop()) {
        if (configurations.length > 0 || selectedSystems.length > 0) {
          confirmationModal('Are you sure you want to move this space? This will reset all configurations and selected systems!', () => {
            moveSpace(item.id, level.uniqueId);
          });
        } else {
          moveSpace(item.id, level.uniqueId);
        }
      }
      if (item.type === 'section' && !monitor.didDrop()) {
        if (configurations.length > 0 || selectedSystems.length > 0) {
          confirmationModal('Are you sure you want to move this section? This will reset all configurations and selected systems!', () => {
            moveSection(item.id, level.uniqueId);
          });
        } else {
          moveSection(item.id, level.uniqueId);
        }
      }
      if (item.type === 'level') {
        moveLevel(item.id);
      }
    },
    collect: (monitor) => ({
      canDrop: !!monitor.canDrop() && monitor.getItem().id !== level.uniqueId,
      isOver: !!monitor.isOver({ shallow: true }) && monitor.getItem().id !== level.uniqueId
    })
  }), [configurations, selectedSystems]);

  const [{ isOver: isOverSeparate }, dropSeparate] = useDrop(() => ({
    accept: ['space', 'section'],
    drop: (
      item: {
        id: string;
        label: string;
        type: 'space' | 'section';
        itemId?: number;
      },
      monitor
    ) => {
      if (item.type === 'space' && item.itemId && !monitor.didDrop()) {
        if (configurations.length > 0 || selectedSystems.length > 0) {
          confirmationModal('Are you sure you want to move this space? This will reset all configurations and selected systems!', () => {
            moveSpace(item.id, level.uniqueId);
          });
        } else {
          moveSpace(item.id, level.uniqueId);
        }
      }
      if (item.type === 'section' && item.itemId && !monitor.didDrop()) {
        if (configurations.length > 0 || selectedSystems.length > 0) {
          confirmationModal('Are you sure you want to move this section? This will reset all configurations and selected systems!', () => {
            moveSection(item.id, level.uniqueId);
          });
        } else {
          moveSection(item.id, level.uniqueId);
        }
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver({ shallow: true })
    })
  }), [configurations, selectedSystems]);

  const classes = clsx(
    'border-2',
    attentionNeeded && !canDrop && !easyMode && 'border-red-300',
    canDrop && 'border-dashed border-green-500',
    focus === 'levels' && 'ring-4 ring-green-500',
  );

  const headerClasses = clsx(
    'flex h-10 w-full items-center border-b-2',
    attentionNeeded && !easyMode ? 'bg-red-100' : 'bg-gray-100'
  );

  const containerWrapperClasses = clsx(
    'rounded-b-md relative',
    [items.find((i) => i.code === 'crawlspace')?.id, items.find((i) => i.code === 'attic')?.id].includes(level.itemId) ? 'h-[100px]' : 'h-[200px]',
    isOver && 'bg-green-50',
  );

  const separateClasses = clsx(
    'border-2 border-dashed rounded-md flex items-center justify-center w-[200px] shrink-0',
    isOverSeparate ? 'bg-green-50 border-green-500 text-green-600' : 'border-gray-300 text-gray-400'
  );

  return (
    <div ref={drop}>
      <div ref={dragPreview} className={classes}>
        <div className={headerClasses}>
          <button
            type='button'
            ref={drag}
            className={`h-full grow pl-3 text-left ${isDragging ? 'cursor-grabbing' : 'cursor-grab'}`}
            onClick={() => setActiveItem(createActiveItem({ type: 'level', item: level, items }))}
          >
            {level.label}
          </button>
          <button
            type='button'
            className='relative h-full px-3 cursor-pointer text-gray-500 hover:text-gray-700'
            onClick={handleDeleteClick}
          >
            <TrashIcon className='h-5 w-5' />
            <Tooltip text='Delete Level' />
          </button>
        </div>
        <div className={containerWrapperClasses}>
          <div
            ref={animate}
            className='w-full h-full flex gap-3 p-3'
          >
            {(showSections || showSpaces) && level.sections.map((section) => (
              <SectionComponent
                key={section.uniqueId}
                section={section}
                levelAttributes={level.attributes}
                renderSelf={showSections}
                focus={focus}
              />
            ))}
            {separatableItemDragging && (
              <div
                ref={dropSeparate}
                className={separateClasses}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

LevelComponent.defaultProps = {
  showSections: true,
  showSpaces: true,
  focus: undefined
};