import React from 'react';

type StatDifferenceProps = {
  value: number | string;
  referenceValue: number | string;
  lowerIsBetter?: boolean;
  formatFn?: (value: number) => number | string;
}

export default function StatDifference({
  value,
  referenceValue,
  lowerIsBetter = false,
  formatFn = (val) => val,
}: StatDifferenceProps) {
  const higherColor = lowerIsBetter ? 'text-red-500' : 'text-green-500';
  const lowerColor = lowerIsBetter ? 'text-green-500' : 'text-red-500';

  if (value === referenceValue) {
    return null;
  }

  if (typeof value === 'string' && typeof referenceValue === 'string') {
    const visibilityOrder = [
      'Least Visible',
      'Less Visible',
      'More Visible',
      'Most Visible'
    ];
    const difficultyOrder = [
      'Easiest Installation',
      'Average Installation',
      'Hardest Installation'
    ];
    const valueIndex = (visibilityOrder.includes(value) ? visibilityOrder : difficultyOrder).indexOf(value);
    const referenceValueIndex = (visibilityOrder.includes(value) ? visibilityOrder : difficultyOrder).indexOf(referenceValue);

    return valueIndex !== -1 && referenceValueIndex !== -1 ? (
      <span>
        ({valueIndex > referenceValueIndex ? (
          <span className={lowerColor}>-</span>
        ) : (
          <span className={higherColor}>+</span>
        )})
      </span>
    ) : null;
  }

  if (typeof value === 'number' && typeof referenceValue === 'number') {
    return value !== referenceValue ? (
      <span>
        ({value > referenceValue ? (
          <span className={higherColor}>+{formatFn(value - referenceValue)}</span>
        ) : (
          <span className={lowerColor}>-{formatFn(referenceValue - value)}</span>
        )})
      </span>
    ) : null;
  }
}
