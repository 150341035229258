/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect } from 'react';
import { ConfigurationContext, DataContext, UIContext } from 'data/context';
import InternalNote from 'components/internal-note';
import AirHandlerTypePicker from 'components/air-handler-type-picker';
import TalkingPoint from 'components/talking-point';
import { getCondenserOptions } from 'utils/helpers';
import { airHandlerTypeCodes } from 'data/constants';
import ConfigOptionComponent from 'components/config-option';

export default function AirHandlers() {
  const {
    configurations,
    setConfigurations,
    activeConfig,
    setActiveConfig
  } = useContext(ConfigurationContext);
  const {
    airHandlerProducts,
    condenserProducts
  } = useContext(DataContext);
  const {
    setActiveAirHandlerPicker
  } = useContext(UIContext);

  useEffect(() => {
    const newActiveConfigIndex = configurations.findIndex((config) => config.condensers.some(cond => cond.zones.some(zone => !zone.selectedProduct)));
    setActiveConfig(newActiveConfigIndex);
  }, []);

  useEffect(() => {
    if (configurations.every(config => config.condensers.every((cond) => cond.zones.every((zone) => zone.selectedProduct)))) {
      setConfigurations((draft) => {
        draft.forEach((config) => {
          config.condensers.forEach((condenser) => {
            const validProducts = getCondenserOptions(condenser, condenserProducts);
            if (validProducts.length > 0) {
              // eslint-disable-next-line no-param-reassign, prefer-destructuring
              condenser.selectedProduct = validProducts[0];
              const bestMatchingSystem = condenser.rankedSystems.find((system) => (
                system.condenserProductId === condenser.selectedProduct?.productId &&
                condenser.zones.every((zone) => (
                  zone.selectedProduct && (
                    (system.airHandler1Unique === zone.uniqueId && system.airHandler1Type === airHandlerTypeCodes[zone.selectedProduct.type]) ||
                    (system.airHandler2Unique === zone.uniqueId && system.airHandler2Type === airHandlerTypeCodes[zone.selectedProduct.type]) ||
                    (system.airHandler3Unique === zone.uniqueId && system.airHandler3Type === airHandlerTypeCodes[zone.selectedProduct.type]) ||
                    (system.airHandler4Unique === zone.uniqueId && system.airHandler4Type === airHandlerTypeCodes[zone.selectedProduct.type]) ||
                    (system.airHandler5Unique === zone.uniqueId && system.airHandler5Type === airHandlerTypeCodes[zone.selectedProduct.type])
                  )
                ))
              ));
              if (bestMatchingSystem) {
                condenser.zones.forEach((zone) => {
                  let airHandlerProductId: number | null = null;
                  for (let i = 0; i < 5; i += 1) {
                    if (bestMatchingSystem[`airHandler${i + 1}Unique`] === zone.uniqueId) {
                      airHandlerProductId = bestMatchingSystem[`airHandler${i + 1}ProductId`];
                    }
                  }
                  const newAirHandlerProduct = airHandlerProducts.find(
                    (p) => p.productId === airHandlerProductId
                  );
                  if (newAirHandlerProduct) {
                    // eslint-disable-next-line no-param-reassign
                    zone.selectedProduct = newAirHandlerProduct;
                  }
                });
              }
            }
          });
        });
      });
    }
  }, [
    JSON.stringify(configurations.map((config) => config.condensers.map((cond) => cond.zones.map((zone) => zone.selectedProduct))))
  ]);

  useEffect(() => {
    if (configurations[activeConfig]) {
      for (const condenser of configurations[activeConfig].condensers) {
        for (const zone of condenser.zones) {
          if (!zone.selectedProduct) {
            setActiveAirHandlerPicker(zone.uniqueId);
            return;
          }
        }
      }
    }
  }, [
    JSON.stringify(configurations.map((config) => config.condensers.map((cond) => cond.zones.map((zone) => zone.selectedProduct)))),
    activeConfig
  ]);

  return (
    <div className='mx-auto flex max-w-[1400px] flex-col gap-5'>
      <div className='text-3xl font-bold text-gray-900'>
        Air Handler Selection
      </div>
      {configurations.some(config => config.condensers.some((cond) => cond.rankedSystems.length > 0)) ? (
        <TalkingPoint>
          Next let&apos;s choose the air handler units that will go in each zone.
        </TalkingPoint>
      ) : (
        <InternalNote>
          {configurations.length === 0 ?
            'No valid configurations detected. Please create a valid configuration to start selecting air handler types.' :
            'No valid system options found. Try increasing the number of condensers or zones in your configuration.'
          }
        </InternalNote>
      )}
      <div className="flex flex-wrap gap-3">
        {configurations.map((config) => (
          <ConfigOptionComponent key={config.uniqueId} config={config} isAirHandlerPage />
        ))}
      </div>
      {configurations.map((config) => config.condensers.map((condenser) => condenser.zones.map((zone) => (
        <div
          key={zone.uniqueId}
          className={config.uniqueId === configurations[activeConfig]?.uniqueId ? 'block' : 'hidden'}
        >
          <AirHandlerTypePicker
            configId={configurations[activeConfig]?.uniqueId}
            condenser={condenser}
            zone={zone}
          />
        </div>
      ))))}
    </div>
  );
}
