import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import SteppedModal from 'components/modals/stepped-modal';
import { Attribute, Level, ModalStep } from 'data/types';
import { ConfigurationContext, DataContext, ProjectContext, UIContext } from 'data/context';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DnDPanel from 'components/layout/dnd-panel';
import StructurePanel from 'components/dnd/structure-panel';
import YesNoButton from 'components/inputs/yes-no-button';
import AttributeComponent from 'components/attribute';
import {
  createLevel,
  findSectionBySpaceId,
  findSpaceById,
  findStructureItemById,
  getAllLabelsOfType
} from 'utils/structure';
import { errorModal, filterAttributes, removeNullishValues } from 'utils/helpers';
import { XMarkIcon, ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import NumberInput from 'components/inputs/number';
import LinkButton from 'components/inputs/link-button';
import Multiselect from 'components/inputs/multiselect';
import Fenestration from 'components/fenestration';
import TalkingPoint from 'components/talking-point';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import Button from 'components/inputs/button';
import { SparklesIcon } from '@heroicons/react/24/outline';
import OpenAI from 'openai';
import axios from 'utils/api';
import { v4 as uuid } from 'uuid';
import SimpleStructurePanel from 'components/dnd/simple-structure-panel';

type StructureModalProps = {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
};

export default function StructureModal({ openState }: StructureModalProps) {
  const [open] = openState;
  const {
    items,
    structureTypes
  } = useContext(DataContext);
  const {
    attomData,
    projectSettings,
    setProjectSettings,
    structureSubtypeSelected,
    propertyLocation,
    locationData,
    showDuctlessOptions
  } = useContext(ProjectContext);
  const { setEasyModalsFinished } = useContext(UIContext);
  const { structure, setStructure } = useContext(ConfigurationContext);
  const { estimatingStructure } = useContext(UIContext);
  const [currentStep, setCurrentStep] = useState('project settings');
  const [steps, setSteps] = useState<ModalStep[]>([]);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const atticItemId = items?.find(item => item.code === 'attic')?.id;
  const basementItemId = items?.find(item => item.code === 'basement')?.id;
  const crawlspaceItemId = items?.find(item => item.code === 'crawlspace')?.id;
  const openFloorPlanIds = items?.filter(item => item.typicallySharesAir).map(item => item.id);
  const [spacesBelowAttic, setSpacesBelowAttic] = useState<string[]>([]);
  const [spacesAboveBasement, setSpacesAboveBasement] = useState<string[]>([]);
  const [callEstimateRoomSizes, setCallEstimateRoomSizes] = useState(false);
  const [estimatingRoomSizes, setEstimatingRoomSizes] = useState(false);
  const [lastSpaceAppliedToFloor, setLastSpaceAppliedToFloor] = useState<string | null>(null);
  const projectSettingsRef = useRef(projectSettings);
  const structureRef = useRef(structure);
  const [animate] = useAutoAnimate({ duration: 100 });

  const settings = [
    { code: 'heating', talkingPoint: 'What level of heating are you looking for?', advanced: false },
    { code: 'cooling', talkingPoint: 'What level of cooling are you looking for?', advanced: false },
    { code: 'ceiling_height', talkingPoint: 'How tall are your ceilings on average?', advanced: false },
    { code: 'ceiling_type', talkingPoint: 'What type of ceilings do you have?', advanced: false },
    { code: 'vaulted_height', talkingPoint: 'How tall are the vaulted ceilings?', advanced: false },
    { code: 'sloped_height', talkingPoint: 'How tall are the sloped ceilings?', advanced: false },
    { code: 'joist_width', talkingPoint: 'How wide are the joists, if any?', advanced: false },
    { code: 'insulation_level', talkingPoint: 'How good is the insulation in the structure?', advanced: false },
    { code: 'desired_heating', talkingPoint: 'What temperature do you plan on heating to?', advanced: true },
    { code: 'desired_cooling', talkingPoint: 'What temperature do you plan on cooling to?', advanced: true }
  ];

  useEffect(() => {
    setShowAdvancedOptions(false);
  }, [open]);

  useEffect(() => {
    projectSettingsRef.current = projectSettings;
    structureRef.current = structure;
  }, [projectSettings, structure]);

  useEffect(() => {
    if (open && currentStep === 'sharing air') {
      setStructure((draft) => {
        [...draft.flatMap((level) => level.sections.flatMap(section => section.spaces))].sort((a, b) => a.uniqueId.localeCompare(b.uniqueId)).forEach((space) => {
          const section = findSectionBySpaceId(draft, space.uniqueId);
          if (space.openTo?.length) {
            space.openTo.forEach((uniqueId) => {
              const item = findSpaceById(draft, uniqueId);
              if (item) {
                const currentSection = draft.find(level => level.sections.some(s => s.spaces.some(sp => sp.uniqueId === item.uniqueId)))?.sections.find(s => s.spaces.some(sp => sp.uniqueId === space.uniqueId));
                if (currentSection && !currentSection.spaces.some(s => s.uniqueId === item.uniqueId)) {
                  const fromLevelIndex = draft.findIndex(level => level.sections.some(s => s.spaces.some(sp => sp.uniqueId === item.uniqueId)));
                  const toLevelIndex = draft.findIndex(level => level.sections.some(s => s.spaces.some(sp => sp.uniqueId === space.uniqueId)));
                  const fromSectionIndex = draft[fromLevelIndex].sections.findIndex(s => s.spaces.some(sp => sp.uniqueId === item.uniqueId));
                  const toSectionIndex = draft[toLevelIndex].sections.findIndex(s => s.spaces.some(sp => sp.uniqueId === space.uniqueId));
                  const fromSpaceIndex = draft[fromLevelIndex].sections[fromSectionIndex].spaces.findIndex(sp => sp.uniqueId === item.uniqueId);
                  const toSpaceIndex = draft[toLevelIndex].sections[toSectionIndex].spaces.findIndex(sp => sp.uniqueId === space.uniqueId);
                  if (fromLevelIndex !== -1 && toLevelIndex !== -1 && fromSectionIndex !== -1 && toSectionIndex !== -1 && fromSpaceIndex !== -1 && toSpaceIndex !== -1) {
                    const splicedItem = draft[fromLevelIndex].sections[fromSectionIndex].spaces.splice(fromSpaceIndex, 1)[0];
                    draft[toLevelIndex].sections[toSectionIndex].spaces.splice(toSpaceIndex, 0, splicedItem);
                  }
                }
              }
            });
          } else if ((section?.spaces.length ?? 0) > 1) {
            const sectionIndex = draft.findIndex(level => level.sections.some(s => s.spaces.some(sp => sp.uniqueId === space.uniqueId)));
            const spaceIndex = draft[sectionIndex].sections.findIndex(s => s.spaces.some(sp => sp.uniqueId === space.uniqueId));
            if (section && sectionIndex !== -1 && spaceIndex !== -1) {
              const splicedItem = draft[sectionIndex].sections[spaceIndex].spaces.splice(draft[sectionIndex].sections[spaceIndex].spaces.findIndex(sp => sp.uniqueId === space.uniqueId), 1)[0];
              draft[sectionIndex].sections.push({
                uniqueId: uuid(),
                label: `${section.label} ${draft[sectionIndex].sections.length + 1}`,
                spaces: [splicedItem]
              });
            }
          }
        });
      });
    }
  }, [JSON.stringify([...structure.flatMap((level) => level.sections.flatMap(section => section.spaces))].sort((a, b) => a.uniqueId.localeCompare(b.uniqueId)).map((space) => space.openTo))]);

  useEffect(() => {
    const filterStructure = (struct: Level[]) => struct.map(level => ({
      label: level.label,
      sections: level.sections.map(section => ({
        label: section.label,
        spaces: section.spaces.map(space => ({
          label: space.label,
          uniqueId: space.uniqueId,
          attributes: space.attributes
            .filter(att => ['square_footage', 'ceiling_height', 'ceiling_type', 'length', 'width'].includes(att.code))
            .filter(att => att.value !== null && att.value !== undefined)
            .map(({ code, value }) => ({ code, value }))
        }))
      }))
    }));
    const filteredStructure = filterStructure(structure);
    const performEstimateRoomSizes = async () => {
      if (callEstimateRoomSizes) {
        try {
          if (attomData) {
            const roomsToEstimate = structure.flatMap(level =>
              level.sections.flatMap(section =>
                section.spaces.filter(space => {
                  const sqftAttr = space.attributes.find(attr => attr.code === 'square_footage');
                  return !sqftAttr || !sqftAttr.value;
                }).map(space => ({
                  label: space.label,
                  uniqueId: space.uniqueId,
                  attributes: space.attributes
                    .filter(att => ['square_footage', 'ceiling_height', 'ceiling_type', 'length', 'width'].includes(att.code))
                    .filter(att => att.value !== null && att.value !== undefined)
                    .map(({ code, value }) => ({ code, value }))
                }))
              )
            );
            if (roomsToEstimate.length > 0) {
              const levelCount = attomData.building.summary.levels ?? 1;
              const bedroomCount = attomData.building.rooms.beds ?? 2; // if attom is missing bedroom count, default to 2
              const totalRoomCount = attomData.building.rooms.roomsTotal ?? bedroomCount + 2; // if attom missing total rooms, adding 2 extra rooms for kitchen and living room
              const totalSize = attomData.building.size.livingSize ?? projectSettingsRef.current.find((attr) => attr.code === 'square_footage')?.value ?? 1500;
              const hasAttic = (attomData.building.size.atticSize ?? 0) > 0;
              const hasBasement = (attomData.building.interior.bsmtSize ?? 0) > 0;
              const customAttomData = {
                location: {
                  country: attomData.address.country,
                  address: attomData.address.oneLine,
                  zipCode: attomData.address.postal1,
                  county: attomData.area.countrySecSubd,
                },
                summary: {
                  propertyClass: attomData.summary.propClass,
                  yearBuilt: attomData.summary.yearBuilt,
                },
                building: {
                  hasAttic: (attomData.building.size.atticSize ?? 0) > 0,
                  hasBasement: (attomData.building.interior.bsmtSize ?? 0) > 0
                }
              };
              const exampleLevelItem = items.find((i) => i.code === 'level');
              const exampleBedroomItem = items.find((i) => i.code === 'bedroom');
              const exampleKitchenItem = items.find((i) => i.code === 'kitchen');
              const exampleDiningRoomItem = items.find((i) => i.code === 'dining_room');
              const exampleLivingRoomItem = items.find((i) => i.code === 'living_room');
              const exampleFamilyRoomItem = items.find((i) => i.code === 'family_room');
              const exampleStructure = [
                {
                  "itemId": 2,
                  "label": "Level 2",
                  "sections": [
                    {
                      "label": "Area 3",
                      "spaces": [
                        {
                          "label": "Primary Bedroom",
                          "uniqueId": "d8555c6b-6765-43e5-bfb9-01e27f226c13",
                          "itemId": exampleBedroomItem?.id ?? 5,
                          "size": 210,
                        }
                      ],
                    },
                    {
                      "label": "Area 4",
                      "spaces": [
                        {
                          "label": "Bedroom 2",
                          "uniqueId": "examplea-6765-43e5-bfb9-01e27f226c13",
                          "itemId": exampleBedroomItem?.id ?? 5,
                          "size": 150,
                        }
                      ],
                    },
                    {
                      "label": "Area 5",
                      "spaces": [
                        {
                          "label": "Bedroom 3",
                          "uniqueId": "examplea-6765-43e5-bfb9-01e27f226c13",
                          "itemId": exampleBedroomItem?.id ?? 5,
                          "size": 150,
                        }
                      ],
                    },
                    {
                      "label": "Area 6",
                      "spaces": [
                        {
                          "label": "Bedroom 4",
                          "uniqueId": "examplea-6765-43e5-bfb9-01e27f226c13",
                          "itemId": exampleBedroomItem?.id ?? 5,
                          "size": 150,
                        }
                      ],
                    }
                  ],
                },
                {
                  "itemId": exampleLevelItem?.id ?? 2,
                  "label": "Level 1",
                  "sections": [
                    {
                      "label": "Area 1",
                      "spaces": [
                        {
                          "label": "Kitchen",
                          "uniqueId": "examplea-6765-43e5-bfb9-01e27f226c13",
                          "itemId": exampleKitchenItem?.id ?? 6,
                          "size": 135,
                        },
                        {
                          "label": "Dining Room",
                          "uniqueId": "examplea-6765-43e5-bfb9-01e27f226c13",
                          "itemId": exampleDiningRoomItem?.id ?? 7,
                          "size": 155,
                        }
                      ],
                    },
                    {
                      "label": "Area 2",
                      "spaces": [
                        {
                          "label": "Living Room",
                          "uniqueId": "examplea-6765-43e5-bfb9-01e27f226c13",
                          "itemId": exampleLivingRoomItem?.id ?? 8,
                          "size": 280,
                        },
                        {
                          "label": "Family Room",
                          "uniqueId": "examplea-6765-43e5-bfb9-01e27f226c13",
                          "itemId": exampleFamilyRoomItem?.id ?? 9,
                          "size": 220,
                        }
                      ],
                    }
                  ],
                }
              ];
              const messages: OpenAI.Chat.Completions.ChatCompletionMessageParam[] = [
                {
                  role: 'system',
                  content: `
                  I estimate room square footage based off structure and room data provided by the user.
                  The structure gives me context for my estimations but I only estimate square footage for the specific rooms the user asks me to estimate.
                  I always respond with a JSON object in this example format: ${JSON.stringify({ structure: exampleStructure })}.
                  I may estimate sizes for 1 room or for multiple rooms depending on the users request. 
                  Whichever rooms the user asks me to estimate sizes for is what I will include in my response. 
                  I only respond with unformatted JSON and nothing else. 
                  ${totalRoomCount > 0 ? `The user's structure has ${levelCount} levels, ${bedroomCount} bedrooms, and ${totalRoomCount} total rooms.` : `The user's structure has ${levelCount} levels`}
                  For the room size estimations, I consider the room sizing of nearby similar properties in the user's area and how many square feet these rooms tend to be on average for similar properties.
                  Room sizes take up no more than 70% of the user provided total home size. 
                `
                },
                {
                  role: 'user',
                  content: `
                  Here is my property data to give you some context: ${JSON.stringify(customAttomData)}.
                  Here is the full structure to give you more context: ${JSON.stringify(filteredStructure)}.
                  Please estimate sizes for these rooms only (and only these rooms should be in your response): ${JSON.stringify(roomsToEstimate)}.
                  Please always respond with a JSON object with room size estimations that follow this format: ${JSON.stringify({ structure: exampleStructure })}.
                  Only respond with unformatted JSON and nothing else.
                  The structure ${hasAttic ? 'has' : 'does not have'} an attic and ${hasBasement ? 'has' : 'does not have'} a basement.           
                  ${totalRoomCount > 0 ? `The structure has ${levelCount} levels, ${bedroomCount} bedrooms, and ${totalRoomCount} total rooms.` : `The structure has ${levelCount} levels`}
                  The structure is ${totalSize} square feet with ${totalRoomCount} total rooms and ${bedroomCount} bedrooms, always use this information in your room size estimations.
                  Your room size estimations should consider similar ${customAttomData.summary.propertyClass} properties in ${locationData?.location ?? 'my city'} that are ${totalSize} square feet and 
                  how many square feet these rooms in similar properties tend to be on average.
                  All your room size estimations should take up no more than ${totalSize * .7} square feet, which is 70% of the total home size of ${totalSize} square feet. 
                `
                }
              ];
              if (propertyLocation) {
                messages.push({
                  role: 'user',
                  content: [
                    {
                      type: 'text',
                      text: "Here is are some images of the property for your reference, if you can't see them don't worry about it."
                    },
                    {
                      type: 'image_url',
                      image_url: {
                        url: `https://maps.googleapis.com/maps/api/staticmap?center=${propertyLocation.lat},${propertyLocation.lng}&zoom=20&size=500x500&maptype=satellite&markers=color:red%7C${propertyLocation.lat},${propertyLocation.lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
                      }
                    },
                    {
                      type: 'image_url',
                      image_url: {
                        url: `https://maps.googleapis.com/maps/api/streetview?size=500x500&location=${propertyLocation.lat},${propertyLocation.lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
                      }
                    }
                  ]
                });
              }
              try {
                const estimatedRoomSizes = removeNullishValues(JSON.parse((await axios.post('chatgpt?type=json', messages)).data.content).structure);
                if (estimatedRoomSizes) {
                  const sizeMapping = {};
                  estimatedRoomSizes.forEach(level => {
                    level.sections.forEach(section => {
                      section.spaces.forEach(space => {
                        sizeMapping[space.uniqueId] = space.size;
                      });
                    });
                  });
                  setStructure(draft => {
                    draft.forEach(level => {
                      level.sections.forEach(section => {
                        section.spaces.forEach(space => {
                          const sqftAttribute = space.attributes.find(att => att.code === 'square_footage');
                          if (sqftAttribute && sizeMapping[space.uniqueId]) {
                            sqftAttribute.value = sizeMapping[space.uniqueId];
                          }
                        });
                      });
                    });
                  });
                } else {
                  console.error(`No reply received`);
                  errorModal('Failed to estimate room sizes. Please try again.');
                }
              } catch (error) {
                console.error(error);
                errorModal('Failed to estimate room sizes. Please try again.');
              }
            } else {
              errorModal('All rooms have already have square footage values.');
              console.log("No rooms need size estimation.");
            }
          } else {
            const roomsToEstimate = structure.flatMap(level =>
              level.sections.flatMap(section =>
                section.spaces.filter(space => {
                  const sqftAttr = space.attributes.find(attr => attr.code === 'square_footage');
                  return !sqftAttr || !sqftAttr.value;
                }).map(space => ({
                  label: space.label,
                  uniqueId: space.uniqueId,
                  attributes: space.attributes
                    .filter(att => ['square_footage', 'ceiling_height', 'ceiling_type', 'length', 'width'].includes(att.code))
                    .filter(att => att.value !== null && att.value !== undefined)
                    .map(({ code, value }) => ({ code, value }))
                }))
              )
            );
            if (roomsToEstimate.length > 0) {
              const homeSize = projectSettingsRef.current.find((attr) => attr.code === 'square_footage')?.value ?? null;
              const exampleLevelItem = items.find((i) => i.code === 'level');
              const exampleBedroomItem = items.find((i) => i.code === 'bedroom');
              const exampleKitchenItem = items.find((i) => i.code === 'kitchen');
              const exampleDiningRoomItem = items.find((i) => i.code === 'dining_room');
              const exampleLivingRoomItem = items.find((i) => i.code === 'living_room');
              const exampleFamilyRoomItem = items.find((i) => i.code === 'family_room');
              const exampleStructure = [
                {
                  "itemId": 2,
                  "label": "Level 2",
                  "sections": [
                    {
                      "label": "Area 3",
                      "spaces": [
                        {
                          "label": "Primary Bedroom",
                          "uniqueId": "examplea-6765-43e5-bfb9-01e27f226c13",
                          "itemId": exampleBedroomItem?.id ?? 5,
                          "size": 210,
                        }
                      ],
                    },
                    {
                      "label": "Area 4",
                      "spaces": [
                        {
                          "label": "Bedroom 2",
                          "uniqueId": "examplea-6765-43e5-bfb9-01e27f226c13",
                          "itemId": exampleBedroomItem?.id ?? 5,
                          "size": 150,
                        }
                      ],
                    },
                    {
                      "label": "Area 5",
                      "spaces": [
                        {
                          "label": "Bedroom 3",
                          "uniqueId": "examplea-6765-43e5-bfb9-01e27f226c13",
                          "itemId": exampleBedroomItem?.id ?? 5,
                          "size": 150,
                        }
                      ],
                    },
                    {
                      "label": "Area 6",
                      "spaces": [
                        {
                          "label": "Bedroom 4",
                          "uniqueId": "examplea-6765-43e5-bfb9-01e27f226c13",
                          "itemId": exampleBedroomItem?.id ?? 5,
                          "size": 150,
                        }
                      ],
                    }
                  ],
                },
                {
                  "itemId": exampleLevelItem?.id ?? 2,
                  "label": "Level 1",
                  "sections": [
                    {
                      "label": "Area 1",
                      "spaces": [
                        {
                          "label": "Kitchen",
                          "uniqueId": "examplea-6765-43e5-bfb9-01e27f226c13",
                          "itemId": exampleKitchenItem?.id ?? 6,
                          "size": 135,
                        },
                        {
                          "label": "Dining Room",
                          "uniqueId": "examplea-6765-43e5-bfb9-01e27f226c13",
                          "itemId": exampleDiningRoomItem?.id ?? 7,
                          "size": 155,
                        }
                      ],
                    },
                    {
                      "label": "Area 2",
                      "spaces": [
                        {
                          "label": "Living Room",
                          "uniqueId": "examplea-6765-43e5-bfb9-01e27f226c13",
                          "itemId": exampleLivingRoomItem?.id ?? 8,
                          "size": 280,
                        },
                        {
                          "label": "Family Room",
                          "uniqueId": "examplea-6765-43e5-bfb9-01e27f226c13",
                          "itemId": exampleFamilyRoomItem?.id ?? 9,
                          "size": 220,
                        }
                      ],
                    }
                  ],
                }
              ];
              const chosenStructure = structureTypes.find((st) => st.subtypes.some((sst) => sst.id === structureSubtypeSelected))?.subtypes.find((sst) => sst.id === structureSubtypeSelected)?.label;
              const messages: OpenAI.Chat.Completions.ChatCompletionMessageParam[] = [
                {
                  role: 'system',
                  content: `
                I estimate room square footage based off structure and room data provided by the user.
                The structure gives me context for my estimations but I only estimate square footage for the specific rooms the user asks me to estimate.
                I always respond with a JSON object in this example format: ${JSON.stringify({ structure: exampleStructure })}.
                I may estimate sizes for 1 room or for multiple rooms depending on the users request. 
                Whichever rooms the user asks me to estimate sizes for is what I will include in my response. 
                I only respond with unformatted JSON and nothing else.               
                For the room size estimations, I consider the room sizing of nearby similar properties in the user's area and how many square feet these rooms tend to be on average for similar properties.
                Room sizes take up no more than 70% of the user provided total home size. 
              `
                },
                {
                  role: 'user',
                  content: `
                Here is the full structure to give you more context: ${JSON.stringify(filteredStructure)}.
                My structure is a ${JSON.stringify(chosenStructure)} property. 
                Please estimate sizes for these rooms only (and only these rooms should be in your response): ${JSON.stringify(roomsToEstimate)}.
                Please always respond with a JSON object with room size estimations that follow this format: ${JSON.stringify({ structure: exampleStructure })}.
                Only respond with unformatted JSON and nothing else.                    
                Your room size estimations should consider similar ${JSON.stringify(chosenStructure)} properties in ${locationData?.location ?? 'my city'} that are ${homeSize} square feet and 
                how many square feet these rooms in similar properties tend to be on average.
                All your room size estimations should take up no more than ${homeSize * .7} square feet, which is 70% of the total home size of ${homeSize} square feet. 
              `
                }
              ];
              if (propertyLocation) {
                messages.push({
                  role: 'user',
                  content: [
                    {
                      type: 'text',
                      text: "Here is are some images of the property for your reference, if you can't see them don't worry about it."
                    },
                    {
                      type: 'image_url',
                      image_url: {
                        url: `https://maps.googleapis.com/maps/api/staticmap?center=${propertyLocation.lat},${propertyLocation.lng}&zoom=20&size=500x500&maptype=satellite&markers=color:red%7C${propertyLocation.lat},${propertyLocation.lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
                      }
                    },
                    {
                      type: 'image_url',
                      image_url: {
                        url: `https://maps.googleapis.com/maps/api/streetview?size=500x500&location=${propertyLocation.lat},${propertyLocation.lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
                      }
                    }
                  ]
                });
              }
              try {
                const estimatedRoomSizes = removeNullishValues(JSON.parse((await axios.post('chatgpt?type=json', messages)).data.content).structure);
                if (estimatedRoomSizes) {
                  const sizeMapping = {};
                  estimatedRoomSizes.forEach(level => {
                    level.sections.forEach(section => {
                      section.spaces.forEach(space => {
                        sizeMapping[space.uniqueId] = space.size;
                      });
                    });
                  });
                  setStructure(draft => {
                    draft.forEach(level => {
                      level.sections.forEach(section => {
                        section.spaces.forEach(space => {
                          const sqftAttribute = space.attributes.find(att => att.code === 'square_footage');
                          if (sqftAttribute && sizeMapping[space.uniqueId]) {
                            sqftAttribute.value = sizeMapping[space.uniqueId];
                          }
                        });
                      });
                    });
                  });
                } else {
                  console.error(`No reply received`);
                  errorModal('Failed to estimate room sizes. Please try again.');
                }
              } catch (error) {
                console.error(error);
                errorModal('Failed to estimate room sizes. Please try again.');
              }
            } else {
              errorModal('All rooms have already have square footage values.');
              console.log("No rooms need size estimation.");
            }
          }
        } catch (error) {
          console.error(error);
          errorModal('Failed to estimate room sizes. Please try again.');
        }
      }
      setEstimatingRoomSizes(false);
      setCallEstimateRoomSizes(false);
    };

    performEstimateRoomSizes();
  }, [callEstimateRoomSizes]);

  const handleChangeProjectSettings = (
    attributeId: number,
    value: any
  ) => {
    setProjectSettings((draft) => {
      const updatedProjectSettings = draft;
      const index = updatedProjectSettings.findIndex(
        (a) => a.id === attributeId
      );
      updatedProjectSettings[index].value = value;
      return updatedProjectSettings;
    });
  };

  const addLevel = (itemId: number) => {
    const levelItem = items.find((i) => i.id === itemId);
    if (levelItem) {
      let levelNumber = 1;
      const existingLevelLabels = getAllLabelsOfType(structureRef.current, 'level');
      while (existingLevelLabels.includes(`${levelItem.label} ${levelNumber}`)) {
        levelNumber += 1;
      }
      const newLevel = createLevel(levelItem, {
        data: {
          label: levelItem.code === 'level' ? `${levelItem.label} ${levelNumber}` : levelItem.label,
        },
        attributesToInherit: projectSettingsRef.current
      });
      setStructure((draft) => {
        let newStructure;
        if (levelItem.code === 'attic') {
          newStructure = [newLevel, ...draft];
        } else if (levelItem.code === 'basement') {
          newStructure = [...draft, newLevel];
        } else if (levelItem.code === 'crawlspace') {
          newStructure = [
            ...draft.filter((l) => items.find((i) => i.id === l.itemId)?.code !== 'basement'),
            newLevel,
            ...draft.filter((l) => items.find((i) => i.id === l.itemId)?.code === 'basement')
          ];
        } else {
          newStructure = [
            ...draft.filter((l) => items.find((i) => i.id === l.itemId)?.code === 'attic'),
            newLevel,
            ...draft.filter((l) => items.find((i) => i.id === l.itemId)?.code !== 'attic')
          ];
        }
        return newStructure;
      });
    } else {
      console.error('Level item not found');
      errorModal(`Level item not found`);
    }
  };

  const updateAttic = (hasAttic: boolean) => {
    if (hasAttic && atticItemId && !structureRef.current.some(level => level.itemId === atticItemId)) {
      addLevel(atticItemId);
    }
    if (!hasAttic && atticItemId) {
      setStructure((draft) => draft.filter((level) => level.itemId !== atticItemId));
    }
  };

  const updateBasement = (hasBasement: boolean) => {
    if (hasBasement && basementItemId && !structureRef.current.some(level => level.itemId === basementItemId)) {
      addLevel(basementItemId);
    }
    if (!hasBasement && basementItemId) {
      setStructure((draft) => draft.filter((level) => level.itemId !== basementItemId));
    }
  };

  const updateCrawlspace = (hasCrawlspace: boolean) => {
    if (hasCrawlspace && crawlspaceItemId && !structureRef.current.some(level => level.itemId === crawlspaceItemId)) {
      addLevel(crawlspaceItemId);
    }
    if (!hasCrawlspace && crawlspaceItemId) {
      setStructure((draft) => draft.filter((level) => level.itemId !== crawlspaceItemId));
    }
  };

  const handleChangeOpenTo = (structureItemId: string, openToUniqueIds: string[]) => {
    setStructure((draft) => {
      draft.forEach((level) => {
        level.sections.forEach((section) => {
          section.spaces.forEach((space) => {
            if (space.uniqueId === structureItemId) {
              // eslint-disable-next-line no-param-reassign
              space.openTo = openToUniqueIds;
            }
            if (openToUniqueIds.includes(space.uniqueId)) {
              // eslint-disable-next-line no-param-reassign
              space.openTo = [...(space.openTo ?? []), structureItemId];
              const shareAirAttributeIndex = space.attributes.findIndex(att => att.code === 'share_air');
              if (shareAirAttributeIndex !== -1 && space.attributes[shareAirAttributeIndex].value !== 'Open Floor Plan') {
                // eslint-disable-next-line no-param-reassign
                space.attributes[shareAirAttributeIndex].value = 'Open Floor Plan';
              }
            } else {
              // eslint-disable-next-line no-param-reassign
              space.openTo = space.openTo?.filter((id) => id !== structureItemId);
            }
          });
        });
      });
    });
  };

  const handleChangeSharingAir = (structureItemId: string, openToUniqueIds: string[]) => {
    setStructure((draft) => {
      draft.forEach((level) => {
        level.sections.forEach((section) => {
          section.spaces.forEach((space) => {
            if (space.uniqueId === structureItemId) {
              // eslint-disable-next-line no-param-reassign
              space.openTo = openToUniqueIds;
            }
            if (openToUniqueIds.includes(space.uniqueId)) {
              // eslint-disable-next-line no-param-reassign
              space.openTo = [...(space.openTo ?? []), structureItemId];
              const shareAirAttributeIndex = space.attributes.findIndex(att => att.code === 'share_air');
              if (shareAirAttributeIndex !== -1 && space.attributes[shareAirAttributeIndex].value !== 'Sharing Air With Door Open') {
                // eslint-disable-next-line no-param-reassign
                space.attributes[shareAirAttributeIndex].value = 'Sharing Air With Door Open';
              }
            } else {
              // eslint-disable-next-line no-param-reassign
              space.openTo = space.openTo?.filter((id) => id !== structureItemId);
            }
          });
        });
      });
    });
  };

  const handleChangeAttribute = (structureItemId: string, attributeId: number, value: any) => {
    setStructure((draft) => {
      const item = findStructureItemById(draft, structureItemId);
      if (item) {
        const attribute = item.attributes.find(att => att.id === attributeId);
        if (attribute) {
          attribute.value = value;
        }
      }
    });
    setLastSpaceAppliedToFloor(null);
  };

  const handleChangeDimension = (structureItemId: string, dimension: 'length' | 'width', value: any) => {
    setStructure((draft) => {
      const item = findSpaceById(draft, structureItemId);
      if (item) {
        if (dimension === 'length') {
          item.length = value;
        } else {
          item.width = value;
        }
        if (item.length && item.width) {
          const sqftAttribute = item.attributes.find(att => att.code === 'square_footage');
          if (sqftAttribute) {
            sqftAttribute.value = item.length * item.width;
          }
        }
      }
    });
  };

  const handleAddFenestrationItem = (structureItemId: string, fenestrationItemId: number) => {
    setStructure((draft) => {
      const space = draft.flatMap(level => level.sections.flatMap(section => section.spaces)).find(sp => sp.uniqueId === structureItemId);
      if (space) {
        space.fenestration = [...(space.fenestration ?? []), fenestrationItemId];
      }
    });
  };

  const handleRemoveFenestrationItem = (structureItemId: string, fenestrationItemId: number) => {
    setStructure((draft) => {
      const space = draft.flatMap(level => level.sections.flatMap(section => section.spaces)).find(sp => sp.uniqueId === structureItemId);
      if (space) {
        const itemIndex = space.fenestration?.findIndex(id => id === fenestrationItemId);
        if (itemIndex !== undefined) {
          space.fenestration = space.fenestration?.filter((_, i) => i !== itemIndex);
        }
      }
    });
  };

  const handleApplyCeilingToLevel = (spaceUniqueId: string) => {
    setLastSpaceAppliedToFloor(spaceUniqueId);
    setStructure((draft) => {
      const space = draft.flatMap(level => level.sections.flatMap(section => section.spaces)).find(sp => sp.uniqueId === spaceUniqueId);
      if (space) {
        const ceilingType = space.attributes.find(att => att.code === 'ceiling_type')?.value;
        const ceilingHeight = space.attributes.find(att => att.code === 'ceiling_height')?.value;
        const slopedHeight = space.attributes.find(att => att.code === 'sloped_height')?.value;
        const vaultedHeight = space.attributes.find(att => att.code === 'vaulted_height')?.value;
        const level = draft.find(l => l.sections.some(s => s.spaces.some(sp => sp.uniqueId === spaceUniqueId)));
        if (level) {
          level.sections.forEach(section => {
            section.spaces.forEach(sp => {
              if (sp.uniqueId !== spaceUniqueId) {
                sp.attributes.forEach(att => {
                  if (att.code === 'ceiling_type') {
                    // eslint-disable-next-line no-param-reassign
                    att.value = ceilingType;
                  }
                  if (att.code === 'ceiling_height') {
                    // eslint-disable-next-line no-param-reassign
                    att.value = ceilingHeight;
                  }
                  if (att.code === 'sloped_height') {
                    // eslint-disable-next-line no-param-reassign
                    att.value = slopedHeight;
                  }
                  if (att.code === 'vaulted_height') {
                    // eslint-disable-next-line no-param-reassign
                    att.value = vaultedHeight;
                  }
                });
              }
            });
          });
        }
      }
    });
  };

  const onFinish = () => {
    setEasyModalsFinished((prev) => [...prev.filter(m => m !== 'Structure'), 'Structure']);
  };

  useEffect(() => {
    const newSteps: ModalStep[] = [
      {
        id: 'project settings',
        title: 'Confirm Project Settings',
        requirementsMet: (settings
          .filter(a => !a.advanced)
          .map((attribute) => projectSettings.find(ps => ps.code === attribute.code))
          .filter(a => a) as Attribute[])
          .filter((a) => (
            !a.parentAttributes ||
            a.parentAttributes.some((pa) => pa.value?.toString() === projectSettings.find((ps) => ps.id === pa.attributeId)?.value?.toString()) ||
            a.parentAttributes.every((pa) => !projectSettings.find((ps) => ps.id === pa.attributeId))
          )).every(a => a.value !== undefined && a.value !== null)
      }
    ];

    if (showDuctlessOptions === false) {
      newSteps.push({
        id: 'simple structure info',
        title: 'Confirm Structure Information',
        requirementsMet: structure.length > 0 && !!projectSettings.find(ps => ps.code === 'square_footage')?.value
      });
    } else {
      newSteps.push({
        id: 'floors',
        title: 'Confirm the Floors on the Structure',
        requirementsMet: structure.length > 0
      },
        {
          id: 'spaces',
          title: 'Confirm the Spaces on Each Floor',
          requirementsMet: structure.flatMap(level => level.sections.flatMap(section => section.spaces)).length > 0
        },
        {
          id: 'sharing air',
          title: 'Confirm Any Spaces That Are Sharing Air',
          requirementsMet: structure.every(level => level.sections.every(section => section.spaces.filter(space => !openFloorPlanIds.includes(space.itemId)).every(space => space.attributes.find(att => att.code === 'share_air')?.value !== 'Yes' || space.openTo?.length)))
        },
        {
          id: 'areas',
          title: 'Confirm Proximity Areas',
          requirementsMet: true
        },
        {
          id: 'square footage',
          title: 'Confirm Square Footages of Spaces',
          requirementsMet: structure.every(level => level.sections.every(section => section.spaces.every(space => space.attributes.find(att => att.code === 'square_footage')?.value)))
        },
        {
          id: 'ceiling',
          title: 'Confirm Ceiling Height & Types',
          requirementsMet: structure.every(level => level.sections.every(section => section.spaces.every(space => space.attributes.find(att => att.code === 'ceiling_height')?.value && space.attributes.find(att => att.code === 'ceiling_type')?.value)))
        },
        {
          id: 'fenestration',
          title: 'Confirm Windows/Doors',
          requirementsMet: true
        });

      if (
        structure.some(level => level.sections.some(section => section.spaces.some(space => openFloorPlanIds.includes(space.itemId)))) &&
        !newSteps.some(step => step.id === 'open floor plans')
      ) {
        const sharingAirIndex = newSteps.findIndex(step => step.id === 'sharing air');
        newSteps.splice(sharingAirIndex, 0, {
          id: 'open floor plans',
          title: 'Confirm Any Spaces That Are Open to Each Other',
          requirementsMet: structure.every(level => level.sections.every(section => section.spaces.filter(space => openFloorPlanIds.includes(space.itemId)).every(space => space.attributes.find(att => att.code === 'share_air')?.value !== 'Yes' || space.openTo?.length)))
        });
      }

      const newSpacesBelowAttic: string[] = [];
      if (structure.some(level => level.itemId === atticItemId || level.itemId === crawlspaceItemId)) {
        structure.forEach((level, levelIndex) => {
          if (level.itemId === atticItemId || level.itemId === crawlspaceItemId) {
            const levelBelowAttic = structure[levelIndex + 1];
            if (levelBelowAttic) {
              newSpacesBelowAttic.push(...levelBelowAttic.sections.flatMap(section => section.spaces).map(space => space.uniqueId));
            }
          }
        });
        setSpacesBelowAttic(newSpacesBelowAttic);
      } else {
        setSpacesBelowAttic([]);
      }

      const newSpacesAboveBasement: string[] = [];
      if (structure.some(level => level.itemId === basementItemId || level.itemId === crawlspaceItemId)) {
        structure.forEach((level, levelIndex) => {
          if (level.itemId === basementItemId || level.itemId === crawlspaceItemId) {
            const levelAboveBasement = structure[levelIndex - 1];
            if (levelAboveBasement) {
              newSpacesAboveBasement.push(...levelAboveBasement.sections.flatMap(section => section.spaces).map(space => space.uniqueId));
            }
          }
        });
        setSpacesAboveBasement(newSpacesAboveBasement);
      } else {
        setSpacesAboveBasement([]);
      }

      if (
        (newSpacesAboveBasement.length || newSpacesBelowAttic.length) &&
        !newSteps.some(step => step.id === 'duct space')
      ) {
        newSteps.push({
          id: 'duct space',
          title: 'Confirm Space Above/Below Rooms',
          requirementsMet: true,
          reason: 'This will help us make sure we are considering every possible air handler type for your ideal system'
        });
      }
    }

    setSteps(newSteps);
  }, [structure, projectSettings]);

  return (
    <SteppedModal
      openState={openState}
      steps={steps}
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      finalButtonText='Finish'
      size='xl'
      handleFinish={onFinish}
    >
      {currentStep === 'project settings' && (
        <div ref={animate} className='flex w-full flex-col gap-6'>
          <TalkingPoint>
            To get a more accurate estimate for your sizing needs, please answer these questions.
          </TalkingPoint>
          {(settings
            .filter(a => !a.advanced)
            .map((attribute) => projectSettings.find(ps => ps.code === attribute.code))
            .filter(a => a) as Attribute[])
            .filter((a) => (
              !a.parentAttributes ||
              a.parentAttributes.some((pa) => pa.value?.toString() === projectSettings.find((ps) => ps.id === pa.attributeId)?.value?.toString()) ||
              a.parentAttributes.every((pa) => !projectSettings.find((ps) => ps.id === pa.attributeId))
            ))
            .map((attribute) => (
              <div key={attribute.id} className='flex flex-col gap-1'>
                <div className='text-lg font-semibold'>
                  {attribute.label}
                </div>
                <div className='flex gap-3'>
                  <div className='w-1/2 max-w-[calc(50%-.5rem)] flex-shrink grow basis-1/2'>
                    <TalkingPoint>
                      {settings.find(s => s.code === attribute.code)?.talkingPoint}
                    </TalkingPoint>
                  </div>
                  <div className='w-1/2 max-w-[calc(50%-.5rem)] flex-shrink grow basis-1/2'>
                    <div className='inline-block'>
                      <AttributeComponent
                        onChange={(value) => handleChangeProjectSettings(attribute.id, value)}
                        completed={!!attribute.value}
                        showProgress={false}
                        debounce={500}
                        {...{ ...attribute }}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          <div>
            <button
              type='button'
              className='flex items-center text-xl font-bold text-gray-900'
              onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}
            >
              <span>Advanced Options</span>
              {showAdvancedOptions ? (
                <ChevronDownIcon className='h-7 w-7' />
              ) : (
                <ChevronRightIcon className='h-7 w-7' />
              )}
            </button>
          </div>
          {showAdvancedOptions && (
            <>
              {(settings
                .filter(a => a.advanced)
                .map((attribute) => projectSettings.find(ps => ps.code === attribute.code))
                .filter(a => a) as Attribute[])
                .filter((a) => (
                  !a.parentAttributes ||
                  a.parentAttributes.some((pa) => pa.value?.toString() === projectSettings.find((ps) => ps.id === pa.attributeId)?.value?.toString()) ||
                  a.parentAttributes.every((pa) => !projectSettings.find((ps) => ps.id === pa.attributeId))
                ))
                .map((attribute) => (
                  <div key={attribute.id} className='flex flex-col gap-1'>
                    <div className='text-lg font-semibold'>
                      {attribute.label}
                    </div>
                    <div className='flex gap-3'>
                      <div className='w-1/2 max-w-[calc(50%-.5rem)] flex-shrink grow basis-1/2'>
                        <TalkingPoint>
                          {settings.find(s => s.code === attribute.code)?.talkingPoint}
                        </TalkingPoint>
                      </div>
                      <div className='w-1/2 max-w-[calc(50%-.5rem)] flex-shrink grow basis-1/2'>
                        <div className='inline-block'>
                          <AttributeComponent
                            onChange={(value) => handleChangeProjectSettings(attribute.id, value)}
                            completed={!!attribute.value}
                            showProgress={false}
                            debounce={500}
                            {...{ ...attribute }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </>
          )}
        </div>
      )}
      {currentStep === 'simple structure info' && (
        <SimpleStructurePanel />
      )}
      {currentStep === 'floors' && (
        <DndProvider backend={HTML5Backend}>
          <div className='flex items-start w-full gap-5'>
            <DnDPanel
              include={['levels']}
              imageLink='https://i.imgur.com/ChbbRvl.gif'
            />
            <div className="flex flex-col gap-5 w-full">
              <StructurePanel
                include={['levels']}
                focus='levels'
                showBorder
              />
              {!estimatingStructure && (
                <div className='flex flex-col items-center gap-5'>
                  <div className='flex items-center gap-3'>
                    <span className='font-semibold text-lg'>Does the structure have an Attic?</span>
                    <YesNoButton
                      defaultState={structure.some(level => level.itemId === atticItemId) ? 1 : 0}
                      onYesClick={() => updateAttic(true)}
                      onNoClick={() => updateAttic(false)}
                    />
                  </div>
                  <div className='flex items-center gap-3'>
                    <span className='font-semibold text-lg'>Does the structure have a Basement?</span>
                    <YesNoButton
                      defaultState={structure.some(level => level.itemId === basementItemId) ? 1 : 0}
                      onYesClick={() => updateBasement(true)}
                      onNoClick={() => updateBasement(false)}
                    />
                  </div>
                  <div className='flex items-center gap-3'>
                    <span className='font-semibold text-lg'>Does the structure have a Crawlspace?</span>
                    <YesNoButton
                      defaultState={structure.some(level => level.itemId === crawlspaceItemId) ? 1 : 0}
                      onYesClick={() => updateCrawlspace(true)}
                      onNoClick={() => updateCrawlspace(false)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </DndProvider>
      )}
      {currentStep === 'spaces' && (
        <DndProvider backend={HTML5Backend}>
          <div className='flex items-start w-full gap-5 h-full'>
            <DnDPanel
              include={['spaces']}
              imageLink='https://i.imgur.com/7ja6huX.gif'
            />
            <StructurePanel
              include={['levels', 'spaces']}
              focus='spaces'
              showBorder
            />
          </div>
        </DndProvider>
      )}
      {currentStep === 'areas' && (
        <DndProvider backend={HTML5Backend}>
          <div className='flex items-start w-full gap-5 h-full'>
            <DnDPanel
              include={['levels', 'spaces']}
              imageLink='https://i.imgur.com/zCZf9S6.gif'
            />
            <StructurePanel
              include={['levels', 'sections', 'spaces']}
              focus='sections'
              showBorder
            />
          </div>
        </DndProvider>
      )}
      {currentStep === 'square footage' && (
        <div className='flex flex-col gap-5'>
          {structure.filter(level => level.sections.length > 0).map(level => (
            <fieldset key={level.uniqueId} className='w-full flex flex-col items-center gap-5 border-2 border-gray-200 px-5 pt-3 pb-5 rounded-lg'>
              <legend className='px-1 font-semibold text-lg'>{level.label}</legend>
              {level.sections.flatMap((section) => section.spaces.filter(space => space.attributes.some(att => att.code === 'square_footage')).flatMap((space) => {
                const sqftAttribute = space.attributes.find(att => att.code === 'square_footage');
                return sqftAttribute ? (
                  <div key={space.uniqueId} className='w-full flex items-center gap-3 justify-between'>
                    <span className='font-semibold text-lg'>What is the square footage of {space.label}?</span>
                    <div className='flex items-center gap-3 shrink-0'>
                      <AttributeComponent
                        required
                        showProgress={false}
                        completed={!!sqftAttribute.value}
                        onChange={(value) => handleChangeAttribute(space.uniqueId, sqftAttribute.id, value)}
                        debounce={500}
                        {...{ ...sqftAttribute }}
                      />
                      <span className='py-2 font-semibold'>OR</span>
                      <div className='shrink'>
                        <div className='flex items-center gap-2'>
                          <NumberInput
                            value={space.length}
                            placeholder='Length'
                            suffix=' ft'
                            onChange={(value) => handleChangeDimension(space.uniqueId, 'length', value)}
                            debounce={500}
                          />
                          <div className='h-6 w-6'>
                            <XMarkIcon className='h-6 w-6' />
                          </div>
                          <NumberInput
                            value={space.width}
                            placeholder='Width'
                            suffix=' ft'
                            onChange={(value) => handleChangeDimension(space.uniqueId, 'width', value)}
                            debounce={500}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null;
              }))}
            </fieldset>
          ))}
          {structure.some(level => level.sections.some(section => section.spaces.some(space => !space.attributes.find(att => att.code === 'square_footage')?.value))) && (
            <div>
              <Button
                text='Estimate Room Sizes'
                color='white'
                icon={SparklesIcon}
                onClick={() => {
                  setEstimatingRoomSizes(true);
                  setCallEstimateRoomSizes(true);
                }}
                loading={estimatingRoomSizes}
              />
            </div>
          )}
        </div>
      )}
      {currentStep === 'ceiling' && (
        <div className='flex flex-col gap-5'>
          {structure.filter(level => level.sections.length > 0).map(level => (
            <fieldset key={level.uniqueId} className='w-full flex flex-col items-center gap-5 border-2 border-gray-200 px-5 pt-3 pb-5 rounded-lg'>
              <legend className='px-1 font-semibold text-lg'>{level.label}</legend>
              {level.sections.flatMap(section => section.spaces.filter(space => space.attributes.some(att => att.code === 'ceiling_height')).flatMap(space => {
                const typeAttribute = space.attributes.find(att => att.code === 'ceiling_type');
                const heightAttribute = space.attributes.find(att => att.code === 'ceiling_height');
                const slopedAttribute = space.attributes.find(att => att.code === 'sloped_height');
                const vaultedAttribute = space.attributes.find(att => att.code === 'vaulted_height');
                return heightAttribute && typeAttribute && slopedAttribute && vaultedAttribute ? (
                  <div key={space.uniqueId} className='w-full flex items-center justify-between gap-3 group'>
                    <span className='font-semibold text-lg'>What kind of ceiling does {space.label} have?</span>
                    <div className='flex items-center gap-3'>
                      {level.sections.flatMap(s => s.spaces).length > 1 && lastSpaceAppliedToFloor !== space.uniqueId && (
                        <div className='hidden group-focus-within:inline-block shrink-0'>
                          <LinkButton
                            text='Copy for entire floor'
                            onClick={() => handleApplyCeilingToLevel(space.uniqueId)}
                          />
                        </div>
                      )}
                      {filterAttributes([typeAttribute, heightAttribute, slopedAttribute, vaultedAttribute], 'normal', 'Ceiling').map((attribute) => (
                        <AttributeComponent
                          key={attribute.id}
                          required
                          showProgress={false}
                          completed={!!attribute.value}
                          onChange={(value) => handleChangeAttribute(space.uniqueId, attribute.id, value)}
                          debounce={500}
                          {...{ ...attribute }}
                          // eslint-disable-next-line no-nested-ternary
                          placeholder={attribute.id === slopedAttribute.id ? 'Sloped Peak Height' : attribute.id === vaultedAttribute.id ? 'Vaulted Peak Height' : attribute.placeholder}
                        />
                      ))}
                    </div>
                  </div>
                ) : null;
              }))}
            </fieldset>
          ))}
        </div>
      )}
      {currentStep === 'fenestration' && (
        <div className='flex flex-col gap-5'>
          {structure.filter(level => level.sections.length > 0).map(level => (
            <fieldset key={level.uniqueId} className='w-full flex flex-col items-center gap-5 border-2 border-gray-200 px-5 pt-3 pb-5 rounded-lg'>
              <legend className='px-1 font-semibold text-lg'>{level.label}</legend>
              {level.sections.flatMap(section => section.spaces.filter(space => space.attributes.some(att => att.code === 'fenestration')).flatMap(space => {
                const fenestrationAttribute = space.attributes.find(att => att.code === 'fenestration');
                return fenestrationAttribute ? (
                  <div key={space.uniqueId} className='flex items-center gap-3'>
                    <span className='font-semibold text-lg'>What kind of windows/doors does {space.label} have?</span>
                    <Fenestration
                      items={space.fenestration ?? []}
                      addItem={(itemId) => handleAddFenestrationItem(space.uniqueId, itemId)}
                      removeItem={(itemId) => handleRemoveFenestrationItem(space.uniqueId, itemId)}
                    />
                  </div>
                ) : null;
              }))}
            </fieldset>
          ))}
        </div>
      )}
      {currentStep === 'open floor plans' && (
        <div className='flex flex-col items-center gap-5'>
          {[...structure.flatMap(level => level.sections.flatMap(section => section.spaces))].sort((a, b) => a.uniqueId.localeCompare(b.uniqueId)).filter(space => openFloorPlanIds.includes(space.itemId)).flatMap(space => {
            const shareAirAttribute = space.attributes.find(att => att.code === 'share_air');
            return shareAirAttribute ? (
              <Fragment key={space.uniqueId}>
                <div className='flex items-center gap-3'>
                  <span className='font-semibold text-lg'>Is {space.label} open to any other spaces?</span>
                  <YesNoButton
                    // eslint-disable-next-line no-nested-ternary
                    defaultState={shareAirAttribute.value ? (shareAirAttribute.value === 'Open Floor Plan' ? 1 : -1) : 0}
                    onYesClick={() => handleChangeAttribute(space.uniqueId, shareAirAttribute.id, 'Open Floor Plan')}
                    onNoClick={() => handleChangeAttribute(space.uniqueId, shareAirAttribute.id, 'Not Sharing')}
                  />
                </div>
                {shareAirAttribute.value === 'Open Floor Plan' && (
                  <div className='flex items-center gap-3'>
                    <span className='font-semibold text-lg'>What space(s) is {space.label} open to?</span>
                    <div className='min-w-[200px]'>
                      <Multiselect
                        value={space.openTo ?? []}
                        onChange={(value) => handleChangeOpenTo(space.uniqueId, value as string[])}
                        options={structure.flatMap(l => l.sections.flatMap(s => s.spaces)).filter(sp => sp.uniqueId !== space.uniqueId).map(sp => ({ value: sp.uniqueId, label: sp.label }))}
                        invalid={!space.openTo || space.openTo.length === 0}
                      />
                    </div>
                  </div>
                )}
              </Fragment>
            ) : null;
          })}
        </div>
      )}
      {currentStep === 'sharing air' && (
        <div className='flex flex-col items-center gap-5'>
          {[...structure.flatMap(level => level.sections.flatMap(section => section.spaces))].sort((a, b) => a.uniqueId.localeCompare(b.uniqueId)).filter(space => !openFloorPlanIds.includes(space.itemId)).flatMap(space => {
            const shareAirAttribute = space.attributes.find(att => att.code === 'share_air');
            return shareAirAttribute ? (
              <Fragment key={space.uniqueId}>
                <div className='flex items-center gap-3'>
                  <span className='font-semibold text-lg'>Is {space.label} sharing air with any other spaces?</span>
                  <YesNoButton
                    // eslint-disable-next-line no-nested-ternary
                    defaultState={shareAirAttribute.value ? (shareAirAttribute.value === 'Sharing Air With Door Open' ? 1 : -1) : 0}
                    onYesClick={() => handleChangeAttribute(space.uniqueId, shareAirAttribute.id, 'Sharing Air With Door Open')}
                    onNoClick={() => handleChangeAttribute(space.uniqueId, shareAirAttribute.id, 'Not Sharing')}
                  />
                </div>
                {shareAirAttribute.value === 'Sharing Air With Door Open' && (
                  <div className='flex items-center gap-3'>
                    <span className='font-semibold text-lg'>What space(s) is {space.label} open to?</span>
                    <div className='min-w-[200px]'>
                      <Multiselect
                        value={space.openTo ?? []}
                        onChange={(value) => handleChangeSharingAir(space.uniqueId, value as string[])}
                        options={structure.flatMap(l => l.sections.flatMap(s => s.spaces)).filter(sp => sp.uniqueId !== space.uniqueId).map(sp => ({ value: sp.uniqueId, label: sp.label }))}
                        invalid={!space.openTo || space.openTo.length === 0}
                      />
                    </div>
                  </div>
                )}
              </Fragment>
            ) : null;
          })}
        </div>
      )}
      {currentStep === 'duct space' && (
        <div className='flex flex-col gap-5'>
          {structure.filter(level => level.sections.some(section => section.spaces.some(space => spacesBelowAttic.includes(space.uniqueId) || spacesAboveBasement.includes(space.uniqueId)))).map(level => (
            <fieldset key={level.uniqueId} className='w-full flex flex-col items-center gap-5 border-2 border-gray-200 px-5 pt-3 pb-5 rounded-lg'>
              <legend className='px-1 font-semibold text-lg'>{level.label}</legend>
              {level.sections.flatMap(section => section.spaces.filter(space => spacesBelowAttic.includes(space.uniqueId)).flatMap(space => {
                const attribute = space.attributes.find(att => att.code === 'space_above');
                return attribute ? (
                  <div key={space.uniqueId} className='flex items-center gap-3'>
                    <span className='font-semibold text-lg'>Does {space.label} have space for an air handler/ductwork above it?</span>
                    <YesNoButton
                      // eslint-disable-next-line no-nested-ternary
                      defaultState={attribute.value !== undefined ? (attribute.value === 'Yes' ? 1 : -1) : 0}
                      onYesClick={() => handleChangeAttribute(space.uniqueId, attribute.id, 'Yes')}
                      onNoClick={() => handleChangeAttribute(space.uniqueId, attribute.id, 'No')}
                    />
                  </div>
                ) : null;
              }))}
              {level.sections.flatMap(section => section.spaces.filter(space => spacesAboveBasement.includes(space.uniqueId)).flatMap(space => {
                const attribute = space.attributes.find(att => att.code === 'space_below');
                return attribute ? (
                  <div key={space.uniqueId} className='flex items-center gap-3'>
                    <span className='font-semibold text-lg'>Does {space.label} have space for an air handler/ductwork below it?</span>
                    <YesNoButton
                      // eslint-disable-next-line no-nested-ternary
                      defaultState={attribute.value !== undefined ? (attribute.value === 'Yes' ? 1 : -1) : 0}
                      onYesClick={() => handleChangeAttribute(space.uniqueId, attribute.id, 'Yes')}
                      onNoClick={() => handleChangeAttribute(space.uniqueId, attribute.id, 'No')}
                    />
                  </div>
                ) : null;
              }))}
            </fieldset>
          ))}
        </div>
      )}
    </SteppedModal>
  );
}
