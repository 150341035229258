import React, { useContext, useState, useEffect } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { ProjectContext, DataContext, UIContext } from 'data/context';
import AttributeComponent from 'components/attribute';
import ProgressIndicator from 'components/progress-indicator';
import Button from 'components/inputs/button';
import StructureTypeSelector from 'components/structure-type-selector';
import StructureSubypeSelector from 'components/structure-subtype-selector';
import { animated, useSpring } from '@react-spring/web';
import { filterAttributes, sortAttributes } from 'utils/helpers';
import clsx from 'clsx';

type SettingsPanelProps = {
  loading: boolean;
}

export default function SettingsPanel({ loading }: SettingsPanelProps) {
  const { structureTypes } = useContext(DataContext);
  const {
    attomData,
    projectSettings,
    setProjectSettings,
    projectDetails,
    structureTypeSelected,
    structureSubtypeSelected,
    customerValues,
    setCustomerValues
  } = useContext(ProjectContext);
  const {
    showSettingsPanel,
    setShowSettingsPanel
  } = useContext(UIContext);
  const [activeStructure, setActiveStructure] = useState('Structure 1');
  const [structureTypeLoading, setStructureTypeLoading] = useState(false);
  const spring = useSpring({
    width: showSettingsPanel ? '320px' : '0px',
    config: { tension: 300, friction: 30 }
  });

  useEffect(() => {
    let timeout;
    if (attomData !== null && !structureTypeSelected && !structureSubtypeSelected) {
      setStructureTypeLoading(true);
      timeout = setTimeout(() => setStructureTypeLoading(false), 5000);
    }
    return () => clearTimeout(timeout);
  }, [attomData]);

  useEffect(() => {
    setStructureTypeLoading(false);
  }, [structureTypeSelected, structureSubtypeSelected]);

  const handleChangeSettings = (
    attributeId: number,
    value: any
  ) => {
    setProjectSettings((draft) => {
      const updatedSettings = draft;
      const index = updatedSettings.findIndex((a) => a.id === attributeId);
      updatedSettings[index].value = value;
      return updatedSettings;
    });
  };

  const handleChangeCustomerValues = (
    attributeId: number,
    value: any
  ) => {
    setCustomerValues((draft) => {
      const updatedCustomerValues = draft;
      const index = updatedCustomerValues.findIndex(
        (a) => a.id === attributeId
      );
      updatedCustomerValues[index].value = value;
      return updatedCustomerValues;
    });
  };

  const buttonClasses = clsx(
    'mt-5 flex h-[240px] w-10 items-center justify-center rounded-r-lg bg-white hover:bg-gray-50 z-20',
    loading ? 'opacity-[70%] cursor-not-allowed' : 'cursor-pointer'
  );

  return (
    <div className='flex'>
      <animated.div
        className='relative h-full border-r border-gray-200 bg-white overflow-hidden'
        style={spring}
      >
        <div className='absolute w-[320px] h-full overflow-y-auto p-5'>
          <div className='flex flex-col gap-4'>
            <div className='flex w-full flex-col gap-2'>
              <div>
                <span className='font-semibold text-gray-800'>
                  Customer Type:
                </span>{' '}
                {projectDetails?.customerType ?? 'N/A'}
              </div>
              <div>
                <span className='font-semibold text-gray-800'>
                  Tax Status:
                </span>{' '}
                {projectDetails?.customerTaxExempt ? 'Exempt' : 'Normal'}
              </div>
              <div className='font-semibold text-gray-800'>Structure</div>
              <select
                className='block w-full cursor-pointer rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm'
                defaultValue={activeStructure}
                onChange={(e) => setActiveStructure(e.target.value)}
              >
                <option value='Structure 1'>Structure 1</option>
                <option value='New Structure' disabled>
                  + New Structure
                </option>
              </select>
            </div>
            <div className='text-xl font-bold text-gray-900'>
              Project Settings
            </div>
            <div className='flex flex-col gap-4'>
              <div className='flex w-full flex-col gap-2'>
                <div className='flex items-center gap-2'>
                  <ProgressIndicator
                    required
                    checked={!!structureTypeSelected}
                  />
                  <div className='font-semibold text-gray-800'>
                    Structure Type
                  </div>
                  {structureTypeLoading && <span className='text-sm text-gray-500'>Loading...</span>}
                </div>
                <StructureTypeSelector />
              </div>
              <div className='flex w-full flex-col gap-2'>
                <div className='flex items-center gap-2'>
                  <ProgressIndicator
                    required
                    checked={!!structureSubtypeSelected}
                  />
                  <div className='font-semibold text-gray-800'>
                    {structureTypeSelected
                      ? `${structureTypes.find(
                        (s) => s.id === structureTypeSelected
                      )?.label || 'Unknown'
                      } Type`
                      : 'Structure Subtype'}
                  </div>
                  {structureTypeLoading && <span className='text-sm text-gray-500'>Loading...</span>}
                </div>
                <StructureSubypeSelector />
              </div>
            </div>
            {sortAttributes(filterAttributes(projectSettings, 'both')).map((attribute) => (
              <AttributeComponent
                key={attribute.id}
                completed={!!attribute.value}
                onChange={(value) => handleChangeSettings(attribute.id, value)}
                debounce={500}
                {...{ ...attribute }}
              />
            ))}
            <div className='mt-2 text-xl font-bold text-gray-900'>
              Customer Values
            </div>
            {sortAttributes(filterAttributes(customerValues, 'both')).map((attribute) => (
              <AttributeComponent
                key={attribute.id}
                completed={!!attribute.value}
                onChange={(value) => handleChangeCustomerValues(attribute.id, value)}
                debounce={500}
                {...{ ...attribute }}
              />
            ))}
            <Button color='white' text='Duplicate Structure' disabled />
            <Button color='red' text='Delete Structure' disabled />
          </div>
        </div>
      </animated.div>
      <button
        type='button'
        className={buttonClasses}
        onClick={() => setShowSettingsPanel((oldValue) => !oldValue)}
        disabled={loading}
      >
        <div className='flex -rotate-90 items-center gap-1 text-gray-500'>
          <div className='pr-1'>
            <div className='rotate-90'>
              <ProgressIndicator
                required
                checked={
                  !projectSettings.some((a) => a.required && !a.value) &&
                  !customerValues.some((a) => a.required && !a.value) &&
                  !!structureTypeSelected &&
                  !!structureSubtypeSelected
                }
              />
            </div>
          </div>
          <span className='whitespace-nowrap text-sm font-semibold'>
            {showSettingsPanel ? 'Hide Project Settings' : 'Show Project Settings'}
          </span>
          {showSettingsPanel ? (
            <ChevronUpIcon className='h-6 w-6' />
          ) : (
            <ChevronDownIcon className='h-6 w-6' />
          )}
        </div>
      </button>
    </div>
  );
}
