import React from 'react';
import clsx from 'clsx';
import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';

type ProgressIndicatorProps = {
  checked?: boolean;
  required?: boolean;
  error?: boolean;
  size?: 'sm' | 'md';
};

export default function ProgressIndicator({
  checked,
  required = false,
  error = false,
  size = 'md'
}: ProgressIndicatorProps) {
  const classes = clsx(
    'rounded-full border-[3px] flex items-center justify-center shrink-0',
    !required && !checked && !error && 'border-gray-400 bg-gray-100',
    required && !checked && !error && 'border-red-500 bg-red-100',
    checked && !error && 'border-green-600 bg-green-600',
    error && 'border-red-500 bg-red-500',
    size === 'sm' ? 'h-5 w-5' : 'h-6 w-6',
  );

  const iconClasses = clsx(
    'text-white',
    size === 'sm' ? 'h-3 w-3' : 'h-4 w-4',
  );

  return (
    <div className={classes}>
      {checked && !error && <CheckIcon className={iconClasses} />}
      {error && <XMarkIcon className={iconClasses} />}
    </div>
  );
}

ProgressIndicator.defaultProps = {
  checked: false,
  required: false,
  error: false
};
