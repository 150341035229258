import React, { useContext, useEffect, useState } from 'react';
import { Tab } from '@headlessui/react';
import { RectangleGroupIcon } from '@heroicons/react/24/outline';
import { ConfigurationContext } from 'data/context';
import { AccessoryProduct, AccessoryCategory } from 'data/types';
import { formatUSD } from 'utils/helpers';
import CartAccessory from 'components/accessory-options/cart-accessory';
import SystemMap from 'components/system-map';
import { accessoryCategories } from 'data/constants';

export default function ShoppingCart() {
  const {
    selectedSystems,
    setSelectedSystems,
    activeSelectedSystem
  } = useContext(ConfigurationContext);
  const [cartTab, setCartTab] = useState(0);
  const [subtotals, setSubtotals] = useState<number[]>([]);

  useEffect(() => {
    const newSubtotals: number[] = [];
    selectedSystems.forEach((system) => {
      let subtotal = 0;
      system.configuration.condensers.forEach((condenser) => {
        subtotal += condenser.selectedProduct?.price ?? 0;
        condenser.zones.forEach((zone) => {
          subtotal += zone.selectedProduct?.price ?? 0;
        });
      });
      system.quotableAccessories.forEach((accessory) => {
        subtotal += accessory.price * accessory.quantity;
      });
      newSubtotals.push(subtotal);
    });
    setSubtotals(newSubtotals);
  }, [selectedSystems]);

  useEffect(() => {
    setCartTab(activeSelectedSystem);
  }, [activeSelectedSystem]);

  const handleAccessoryChange = (
    accessory: AccessoryProduct,
    quantity: number
  ) => {
    setSelectedSystems((draft) => {
      const category = Object.keys(accessoryCategories).find((key) =>
        accessoryCategories[key].includes(accessory.type)
      ) as AccessoryCategory | undefined;
      const accessoryIndex = draft[
        cartTab
      ].selectedAccessories.findIndex(
        (acc) => acc.productId === accessory.productId
      );
      if (accessoryIndex !== -1) {
        // eslint-disable-next-line no-param-reassign
        draft[cartTab].selectedAccessories[accessoryIndex].quantity =
          quantity;
      } else {
        draft[cartTab].selectedAccessories.push({
          ...accessory,
          quantity
        });
      }
      if (category) {
        // eslint-disable-next-line no-param-reassign
        draft[cartTab].accessoriesCompleted = draft[
          cartTab
        ].accessoriesCompleted.filter((c) => c !== category);
        if (
          draft[cartTab].selectedAccessories.some((acc) =>
            accessoryCategories[category]?.includes(acc.type) && acc.quantity > 0
          )
        ) {
          draft[cartTab].accessoriesCompleted.push(category);
        }
      }
    });
  };

  return (
    <div className='fixed right-6 top-[60px] flex max-h-[calc(100vh-240px)] w-[600px] flex-col divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow-xl'>
      <Tab.Group selectedIndex={cartTab} onChange={setCartTab}>
        <Tab.List className={`${selectedSystems.length > 1 ? 'flex' : 'hidden'} focus:outline-none`}>
          {selectedSystems.map((system, systemIndex) => (
            <Tab
              key={system.id}
              className='grow basis-0 py-3 text-center font-semibold focus:outline-none ui-selected:bg-blue-50 ui-selected:text-blue-600 ui-not-selected:bg-white ui-not-selected:text-gray-600'
            >
              Option {systemIndex + 1}
            </Tab>
          ))}
        </Tab.List>
        <div className='divide-y divide-gray-200 overflow-y-auto'>
          <Tab.Panels className='focus:outline-none'>
            {selectedSystems.map((system) => (
              <Tab.Panel key={system.id} className='focus:outline-none'>
                <div className="flex flex-col gap-5 p-5">
                  {system.configuration.condensers.map((condenser) => (
                    <SystemMap
                      key={condenser.uniqueId}
                      condenser={condenser}
                      showPrices
                    />
                  ))}
                </div>
                {system.quotableAccessories.length > 0 && (
                  <div className='px-5'>
                    <div className='flex items-center gap-2'>
                      <RectangleGroupIcon className='h-6 w-6 text-gray-800' />
                      <span className='text-xl font-semibold'>
                        Accessories
                      </span>
                    </div>
                    <div className='divide-y divide-gray-200'>
                      {system.quotableAccessories
                        .map((accessory) => (
                          <CartAccessory
                            key={accessory.productId}
                            accessory={accessory}
                            onChange={(quantity) => handleAccessoryChange(accessory, quantity)}
                          />
                        ))}
                    </div>
                  </div>
                )}
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </div>
        <div className='flex items-center justify-end gap-3 p-5'>
          <span className='font-semibold text-gray-600'>Subtotal:</span>
          <span className='text-2xl font-bold'>
            {formatUSD(subtotals[cartTab] || 0, true)}
          </span>
        </div>
      </Tab.Group>
    </div>
  );
}
