import React, { useContext, useEffect, useState } from 'react';
import { UIContext, ProjectContext } from 'data/context';
import { ActiveTab } from 'data/types';
import BudgetModal from 'components/modals/budget-modal';

export default function TransitionModalWatcher() {
  const { projectSettings, customerValues } = useContext(ProjectContext);
  const { activeTab, pageProgress } = useContext(UIContext);
  const [previousTab, setPreviousTab] = useState<ActiveTab>('description');
  const budgetModalOpenState = useState(false);
  const [, setBudgetOpenModal] = budgetModalOpenState;

  const budgetModalCodes = [
    'max_budget'
  ];

  useEffect(() => {
    if (
      previousTab === 'configuration' &&
      activeTab === 'air handlers' &&
      pageProgress['air handlers'] === 0 &&
      customerValues
        .filter(cv => budgetModalCodes.includes(cv.code))
        .filter((a) => (
          !a.parentAttributes ||
          a.parentAttributes.some((pa) => pa.value?.toString() === projectSettings.find((ps) => ps.id === pa.attributeId)?.value?.toString())
        ))
        .filter(a => !a.value)
        .length > 0
    ) {
      setBudgetOpenModal(true);
    }
    setPreviousTab(activeTab);
  }, [activeTab]);

  return (
    <>
      <BudgetModal openState={budgetModalOpenState} />
    </>
  );
}
