import React from 'react';

type PropertyStatisticProps = {
  label: string;
  value: string | number | null;
};

export default function PropertyStatistic({
  label,
  value
}: PropertyStatisticProps) {
  return (
    <div className='w-full rounded-lg bg-white p-4'>
      <div className='flex flex-col gap-2'>
        <span className='whitespace-nowrap text-xs font-semibold uppercase text-gray-500'>
          {label}
        </span>
        <span className='truncate font-semibold text-gray-800'>
          {value || '-'}
        </span>
      </div>
    </div>
  );
}
