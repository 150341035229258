import React, { useContext, useState, useEffect } from 'react';
import {
  ConfigurationContext,
  UIContext,
  DataContext,
  ProjectContext
} from 'data/context';
import {
  SystemOption,
  RankedSystem,
  SystemFilters,
  SystemOptionTag
} from 'data/types';
import {
  createSystemOption,
  filterSystems,
  sortSystemOptions,
  warningModal
} from 'utils/helpers';
import EquipmentTable from 'components/equipment-table';
import Button from 'components/inputs/button';
import InternalNote from 'components/internal-note';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import SystemOptionComponent from 'components/system-option';
import Chip from 'components/inputs/chip';
import { defaultUIState } from 'data/context/ui-context';
import CanAccess from 'components/auth/can-access';
import LinkButton from 'components/inputs/link-button';
import { airHandlerTypeCodes, systemFilterLabels } from 'data/constants';
import axios from 'axios';
import { calculateOptimalDesignScore } from 'utils/ranking';

export default function Equipment() {
  const {
    activeConfig,
    configurations,
    selectedSystems
  } = useContext(ConfigurationContext);
  const {
    systemFilters,
    setSystemFilters,
    setShowRankingSandbox
  } = useContext(UIContext);
  const {
    airHandlerProducts,
    condenserProducts,
    ductingKitProducts
  } = useContext(DataContext);
  const {
    customerValues,
    systemOptions,
    locationData,
    setSystemOptions
  } = useContext(ProjectContext);
  const [advancedMode, setAdvancedMode] = useState(false);
  const [resultsMessage, setResultsMessage] = useState('Fetching system options...');
  const [animate] = useAutoAnimate({ duration: 100 });
  const [hasBeenReranked, setHasBeenReranked] = useState(false);

  useEffect(() => {
    setHasBeenReranked(JSON.stringify(systemFilters) !== JSON.stringify(defaultUIState.systemFilters));
  }, [systemFilters]);

  const getTotalRebates = async (system) => {
    if (locationData && system.configuration && system.configuration.condensers) {
      const payload = {
        zipCode: locationData.zipcode,
        productsData: [
          ...(system.configuration.condensers.map((c) => ({
            product_id: c.selectedProduct?.productId,
            product_price: c.selectedProduct?.price
          })) ?? []),
          ...(system.configuration.condensers.flatMap(c => c.zones.map((z) => ({
            product_id: z.selectedProduct?.productId,
            product_price: z.selectedProduct?.price
          }))) ?? [])
        ]
      };

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_PARTNERNET_API_URL}/v1/api/upfront/get_rebates`,
          payload
        );
        const rebateData = response.data;
        return rebateData;
      } catch (error) {
        console.error(error);
        return null;
      }
    }
    return null;
  };

  const updateSystemOptions = async () => {
    setResultsMessage('Fetching system options...');

    const customerCaresAboutMoney = customerValues.find(cv => cv.code === 'savings_preference')?.value === 'Savings Up Front';
    const customerWantsOptimalSystem = customerValues.find(cv => cv.code === 'savings_preference')?.value === 'Optimal System';
    const customerCaresAboutAesthetics = customerValues.find(cv => cv.code === 'aesthetic_preference')?.value === 'Hidden';
    const customerCaresAboutInstallation = customerValues.find(cv => cv.code === 'installation_difficulty')?.value === 'Simple Installation Preferred';

    const systemOptionsMatrix: SystemOption[][] = await Promise.all([...configurations]
      .sort((a, b) => (b.score ?? 0) - (a.score ?? 0))
      .map(async (config, configIndex) => {
        const newOptions: { optionName: string; systemsForOption: RankedSystem[], tags: SystemOptionTag[], optimalDesignScore?: number }[] = [];
        const isDucted = config.condensers.some(cond => cond.type === 'ducted');

        // selected system
        const selectedCombination: RankedSystem[] = [];
        const filteredSystems = {};
        config.condensers.forEach(condenser => {
          filteredSystems[condenser.uniqueId] = filterSystems(condenser.rankedSystems, systemFilters, customerValues, condenser);
        });
        config.condensers.forEach((condenser) => {
          const selectedSystem = [...filteredSystems[condenser.uniqueId]].find((system) => (
            system.condenserProductId === condenser.selectedProduct?.productId &&
            condenser.zones.every((zone) => (
              zone.selectedProduct && (
                (zone.uniqueId === system.airHandler1Unique && system.airHandler1Type === airHandlerTypeCodes[zone.selectedProduct.type]) ||
                (zone.uniqueId === system.airHandler2Unique && system.airHandler2Type === airHandlerTypeCodes[zone.selectedProduct.type]) ||
                (zone.uniqueId === system.airHandler3Unique && system.airHandler3Type === airHandlerTypeCodes[zone.selectedProduct.type]) ||
                (zone.uniqueId === system.airHandler4Unique && system.airHandler4Type === airHandlerTypeCodes[zone.selectedProduct.type]) ||
                (zone.uniqueId === system.airHandler5Unique && system.airHandler5Type === airHandlerTypeCodes[zone.selectedProduct.type])
              )
            ))
          ));
          if (selectedSystem) {
            selectedCombination.push(selectedSystem);
          }
        });
        if (!isDucted) {
          if (selectedCombination.length === config.condensers.length) {
            newOptions.push({
              optionName: 'Preferred System',
              systemsForOption: selectedCombination,
              tags: ['Preferred Air Handlers'],
              optimalDesignScore: calculateOptimalDesignScore(selectedCombination, config.score ?? 1)
            });
          }
        }
        // recommended system
        const recommendedCombination: RankedSystem[] = [];
        config.condensers.forEach((condenser) => {
          const recommendedSystem = [...filteredSystems[condenser.uniqueId]][0];
          if (recommendedSystem) {
            recommendedCombination.push(recommendedSystem);
          }
        });
        if (recommendedCombination.length === config.condensers.length) {
          newOptions.push({
            optionName: configIndex === 0 ? 'Recommended System' : 'Good System',
            systemsForOption: recommendedCombination,
            // tags: configIndex === 0 ? ['Recommended', 'Best For Configuration'] : ['Best For Configuration'] // keep logic in case we want to add this back
            tags: configIndex === 0 ? ['Recommended'] : [],
            optimalDesignScore: calculateOptimalDesignScore(recommendedCombination, config.score ?? 1)
          });
        }
        // cheapest system
        const cheapestCombination: RankedSystem[] = [];
        config.condensers.forEach((condenser) => {
          const cheapestSystem = [...filteredSystems[condenser.uniqueId]].sort((a, b) => a.baseRetailPrice - b.baseRetailPrice)[0];
          if (cheapestSystem) {
            cheapestCombination.push(cheapestSystem);
          }
        });
        if (cheapestCombination.length === config.condensers.length) {
          newOptions.push({
            optionName: 'Affordable System',
            systemsForOption: cheapestCombination,
            tags: configurations.length === 1 ? ['Cheapest'] : [],
            optimalDesignScore: calculateOptimalDesignScore(cheapestCombination, config.score ?? 1)
          });
        }

        // discounted system
        const onSaleCombination: RankedSystem[] = [];
        config.condensers.forEach((condenser) => {
          const discountedSystems = [...filteredSystems[condenser.uniqueId]].filter(system => system.discountAmount > 0);
          const highestDiscountSystem = discountedSystems.sort((a, b) => b.discountAmount - a.discountAmount)[0];
          if (highestDiscountSystem) {
            onSaleCombination.push(highestDiscountSystem);
          }
        });
        if (onSaleCombination.length === config.condensers.length) {
          newOptions.push({
            optionName: 'Discounted System',
            systemsForOption: onSaleCombination,
            tags: configurations.length === 1 ? ['Best Discounted System'] : [],
            optimalDesignScore: calculateOptimalDesignScore(onSaleCombination, config.score ?? 1)
          });
        }

        // most efficienct system
        const efficientCombination: RankedSystem[] = [];
        config.condensers.forEach((condenser) => {
          const mostEfficientSystem = [...filteredSystems[condenser.uniqueId]].sort((a, b) => a.operatingCost - b.operatingCost)[0];
          if (mostEfficientSystem) {
            efficientCombination.push(mostEfficientSystem);
          }
        });
        if (efficientCombination.length === config.condensers.length) {
          newOptions.push({
            optionName: 'Efficient System',
            systemsForOption: efficientCombination,
            tags: configurations.length === 1 ? ['Most Efficient'] : [],
            optimalDesignScore: calculateOptimalDesignScore(efficientCombination, config.score ?? 1)
          });
        }

        // best coverage system
        const coverageCombination: RankedSystem[] = [];
        config.condensers.forEach((condenser) => {
          const mostCoverageSystem = [...filteredSystems[condenser.uniqueId]].sort((a, b) => (b.heatingScore + b.coolingScore) - (a.heatingScore + a.coolingScore))[0];
          if (mostCoverageSystem) {
            coverageCombination.push(mostCoverageSystem);
          }
        });
        if (coverageCombination.length === config.condensers.length) {
          newOptions.push({
            optionName: 'High Coverage System',
            systemsForOption: coverageCombination,
            tags: configurations.length === 1 ? ['Best Coverage'] : [],
            optimalDesignScore: calculateOptimalDesignScore(coverageCombination, config.score ?? 1)
          });
        }

        // most aesthetic system
        const aestheticCombination: RankedSystem[] = [];
        config.condensers.forEach((condenser) => {
          const aestheticScores = [...filteredSystems[condenser.uniqueId]].map((system) => {
            let score = 0;
            [
              system.airHandler1ProductId,
              system.airHandler2ProductId,
              system.airHandler3ProductId,
              system.airHandler4ProductId,
              system.airHandler5ProductId
            ].filter(id => id).forEach((id) => {
              const product = airHandlerProducts.find(p => p.productId === id);
              score += product?.aestheticsRank ?? 0;
            });
            return { systemId: system.id, score };
          }).sort((a, b) => a.score - b.score);
          const mostAestheticSystem = condenser.rankedSystems.find(system => system.id === aestheticScores[0]?.systemId);
          if (mostAestheticSystem) {
            aestheticCombination.push(mostAestheticSystem);
          }
        });
        if (aestheticCombination.length === config.condensers.length) {
          newOptions.push({
            optionName: 'Aesthetic System',
            systemsForOption: aestheticCombination,
            tags: configurations.length === 1 ? ['Most Aesthetic'] : [],
            optimalDesignScore: calculateOptimalDesignScore(aestheticCombination, config.score ?? 1)
          });
        }

        // easiest to install system
        const easyCombination: RankedSystem[] = [];
        config.condensers.forEach((condenser) => {
          const installationScores = [...filteredSystems[condenser.uniqueId]].map((system) => {
            let score = 0;
            [
              system.airHandler1ProductId,
              system.airHandler2ProductId,
              system.airHandler3ProductId,
              system.airHandler4ProductId,
              system.airHandler5ProductId
            ].filter(id => id).forEach((id) => {
              const product = airHandlerProducts.find(p => p.productId === id);
              score += product?.installComplexityRank ?? 0;
            });
            return { systemId: system.id, score };
          }).sort((a, b) => a.score - b.score);
          const easiestSystem = condenser.rankedSystems.find(system => system.id === installationScores[0]?.systemId);
          if (easiestSystem) {
            easyCombination.push(easiestSystem);
          }
        });
        if (easyCombination.length === config.condensers.length) {
          newOptions.push({
            optionName: 'Easy to Install System',
            systemsForOption: easyCombination,
            tags: configurations.length === 1 ? ['Easiest Installation'] : [],
            optimalDesignScore: calculateOptimalDesignScore(easyCombination, config.score ?? 1)
          });
        }

        // optimal design system
        const optimalCombination: RankedSystem[] = [];
        config.condensers.forEach((condenser) => {
          const optimalSystem = [...filteredSystems[condenser.uniqueId]].sort((a, b) => (b.score + config.score) - (a.score + config.score))[0];
          if (optimalSystem) {
            optimalCombination.push(optimalSystem);
          }
        });
        if (optimalCombination.length === config.condensers.length) {
          newOptions.push({
            optionName: 'Optimal Design',
            systemsForOption: optimalCombination,
            tags: configurations.length === 1 ? ['Optimal Design'] : [],
            optimalDesignScore: calculateOptimalDesignScore(optimalCombination, config.score ?? 1)
          });
        }

        // best value system
        const valueCombination: RankedSystem[] = [];
        config.condensers.forEach((condenser) => {
          const valueSystem = [...filteredSystems[condenser.uniqueId]].map((option) => {
            const cappedHeatingScore = Math.min(option.heatingScore, 100);
            const cappedCoolingScore = Math.min(option.coolingScore, 100);
            const price = option.price ?? option.baseRetailPrice;
            if (price === undefined) {
              console.error('Price is undefined for system:', option);
              return null;
            }
            const coveragePerDollar = (cappedHeatingScore + cappedCoolingScore) / price;
            return { ...option, coveragePerDollar };
          }).filter(option => option !== null).sort((a, b) => b.coveragePerDollar - a.coveragePerDollar)[0];
          if (valueSystem) {
            valueCombination.push(valueSystem);
          }
        });
        if (valueCombination.length === config.condensers.length) {
          newOptions.push({
            optionName: 'Great Value System',
            systemsForOption: valueCombination,
            tags: configurations.length === 1 ? ['Best Value'] : [],
            optimalDesignScore: calculateOptimalDesignScore(valueCombination, config.score ?? 1)
          });
        }

        // // Find the lowest amperage system - only adding amps to comparison table for now, no system tag
        // const lowAmpCombination: RankedSystem[] = [];
        // config.condensers.forEach((condenser) => {
        //   const sortedSystems = filteredSystems[condenser.uniqueId]
        //     .filter(system => system.condenserProductId === condenser.selectedProduct?.productId)
        //     .sort((a, b) => Number(a.amperage) - Number(b.amperage));
        //   if (sortedSystems.length > 0) {
        //     const lowestAmperage = Number(sortedSystems[0].amperage);
        //     const lowAmperageSystems = sortedSystems.filter(system => Number(system.amperage) === lowestAmperage)
        //       .sort((a, b) => b.score - a.score);
        //     lowAmpCombination.push(lowAmperageSystems[0]);
        //   } else {
        //     console.warn(`Amperage not defined for condenser: ${condenser}`);
        //   }
        // });
        // if (lowAmpCombination.length === config.condensers.length) {
        //   const optimalDesignScore = lowAmpCombination.reduce((sum, system) => sum + system.score, 0) + (config.score ?? 0);
        //   newOptions.push({
        //     optionName: 'Lowest Amperage System',
        //     systemsForOption: lowAmpCombination,
        //     tags: configurations.length === 1 ? ['Lowest Amps'] : [],
        //     optimalDesignScore
        //   });
        // }

        return newOptions.map(({ optionName, systemsForOption, tags, optimalDesignScore }) => createSystemOption(
          systemsForOption,
          config,
          condenserProducts,
          airHandlerProducts,
          ductingKitProducts,
          optionName,
          tags,
          undefined,
          optimalDesignScore
        ));
      }));

    // find best systems in each category for multiple configs
    if (configurations.length > 1) {
      const flattenedOptions = systemOptionsMatrix.flat().map(option => ({
        ...option,
        totalRebates: option.totalRebates ?? undefined,
        optimalDesignScore: option.optimalDesignScore ?? 0
      }));

      // cheapest
      const cheapestPrice = Math.min(...flattenedOptions.map(option => option.price));
      const cheapestSystems = flattenedOptions.filter(option => option.price === cheapestPrice);
      cheapestSystems.forEach(cheapestSystem => {
        const cheapestConfigIndex = systemOptionsMatrix.findIndex(options => options.some(option => option.id === cheapestSystem.id));
        systemOptionsMatrix[cheapestConfigIndex] = systemOptionsMatrix[cheapestConfigIndex].map(
          option => option.id === cheapestSystem.id ?
            { ...option, name: option.name || 'Most Affordable System', tags: [...option.tags, 'Cheapest'] } :
            option
        );
      });

      // most efficient
      const lowestOperatingCost = Math.min(...flattenedOptions.map(option => option.operatingCost));
      const mostEfficientSystems = flattenedOptions.filter(option => option.operatingCost === lowestOperatingCost);
      mostEfficientSystems.forEach(efficientSystem => {
        const efficientConfigIndex = systemOptionsMatrix.findIndex(options => options.some(option => option.id === efficientSystem.id));
        systemOptionsMatrix[efficientConfigIndex] = systemOptionsMatrix[efficientConfigIndex].map(
          option => option.id === efficientSystem.id ?
            { ...option, name: option.name || 'Most Efficient System', tags: [...option.tags, 'Most Efficient'] } :
            option
        );
      });

      // best coverage
      const highestCoverage = Math.max(...flattenedOptions.map(option => option.heatingScore + option.coolingScore));
      const bestCoverageSystems = flattenedOptions.filter(option => (option.heatingScore + option.coolingScore) === highestCoverage);
      bestCoverageSystems.forEach(coverageSystem => {
        const coverageConfigIndex = systemOptionsMatrix.findIndex(options => options.some(option => option.id === coverageSystem.id));
        systemOptionsMatrix[coverageConfigIndex] = systemOptionsMatrix[coverageConfigIndex].map(
          option => option.id === coverageSystem.id ?
            { ...option, name: option.name || 'Best Coverage System', tags: [...option.tags, 'Best Coverage'] } :
            option
        );
      });

      // most aesthetic
      const lowestAestheticRank = Math.min(...flattenedOptions.map(option => option.averageAestheticsRank ?? Infinity));
      const mostAestheticSystems = flattenedOptions.filter(option => option.averageAestheticsRank === lowestAestheticRank);
      mostAestheticSystems.forEach(aestheticSystem => {
        const aestheticConfigIndex = systemOptionsMatrix.findIndex(options => options.some(option => option.id === aestheticSystem.id));
        systemOptionsMatrix[aestheticConfigIndex] = systemOptionsMatrix[aestheticConfigIndex].map(
          option => option.id === aestheticSystem.id ?
            { ...option, name: option.name || 'Most Aesthetic System', tags: [...option.tags, 'Most Aesthetic'] } :
            option
        );
      });

      // easiest to install
      const lowestInstallDifficultyScore = Math.min(...flattenedOptions.map(option => option.installationDifficultyScore));
      const easiestSystems = flattenedOptions.filter(option => option.installationDifficultyScore === lowestInstallDifficultyScore);
      easiestSystems.forEach(easySystem => {
        const easyConfigIndex = systemOptionsMatrix.findIndex(options => options.some(option => option.id === easySystem.id));
        systemOptionsMatrix[easyConfigIndex] = systemOptionsMatrix[easyConfigIndex].map(
          option => option.id === easySystem.id ?
            { ...option, name: option.name || 'Easiest to Install System', tags: [...option.tags, 'Easiest Installation'] } :
            option
        );
      });

      // biggest discount system
      const highestDiscount = Math.max(...flattenedOptions.filter(option => option.onSale).map(option => option.price));
      const bestSaleSystems = flattenedOptions.filter(option => option.price === highestDiscount);
      bestSaleSystems.forEach(saleSystem => {
        const saleConfigIndex = systemOptionsMatrix.findIndex(options => options.some(option => option.id === saleSystem.id));
        systemOptionsMatrix[saleConfigIndex] = systemOptionsMatrix[saleConfigIndex].map(
          option => option.id === saleSystem.id ?
            { ...option, name: option.name || 'Best Discounted System', tags: [...option.tags, 'Best Discounted System'] } :
            option
        );
      });

      // best value system
      const bestValueSystems = flattenedOptions.map(option => {
        const cappedHeatingScore = Math.min(option.heatingScore, 100);
        const cappedCoolingScore = Math.min(option.coolingScore, 100);
        const { price } = option;
        const coveragePerDollar = (cappedHeatingScore + cappedCoolingScore) / price;
        return { ...option, coveragePerDollar };
      });
      const highestCoveragePerDollar = Math.max(...bestValueSystems.map(option => option.coveragePerDollar));
      const bestValueOptions = bestValueSystems.filter(option => option.coveragePerDollar === highestCoveragePerDollar);
      bestValueOptions.forEach(bestValueSystem => {
        const bestValueConfigIndex = systemOptionsMatrix.findIndex(options => options.some(option => option.id === bestValueSystem.id));
        systemOptionsMatrix[bestValueConfigIndex] = systemOptionsMatrix[bestValueConfigIndex].map(
          option => option.id === bestValueSystem.id ?
            { ...option, name: option.name || 'Great Value System', tags: [...option.tags, 'Best Value'] } :
            option
        );
      });

      // most optimal designed system
      const highestOptimalScore = Math.max(...flattenedOptions.map(option => option.optimalDesignScore));
      const mostOptimalSystems = flattenedOptions.filter(option => option.optimalDesignScore === highestOptimalScore);
      mostOptimalSystems.forEach(optimalSystem => {
        const optimalConfigIndex = systemOptionsMatrix.findIndex(options => options.some(option => option.id === optimalSystem.id));
        systemOptionsMatrix[optimalConfigIndex] = systemOptionsMatrix[optimalConfigIndex].map(
          option => option.id === optimalSystem.id ?
            { ...option, name: option.name || 'Most Optimal System', tags: [...option.tags, 'Optimal Design'] } :
            option
        );
      });
    }

    // merge duplicate systems
    systemOptionsMatrix.forEach((options, configIndex) => {
      systemOptionsMatrix[configIndex] = options.reduce((acc, curr) => {
        if (!acc.some(system => system.id === curr.id)) {
          acc.push(curr);
        } else {
          const existing = acc.find(system => system.id === curr.id);
          if (existing && curr.tags) {
            if (existing.tags) {
              existing.tags = Array.from(new Set([...existing.tags, ...curr.tags])) as SystemOptionTag[];
            } else {
              existing.tags = curr.tags;
            }
          }
        }
        return acc;
      }, [] as SystemOption[]);
    });

    let newSystemOptions = systemOptionsMatrix.flat();

    // conditionally order tags based off customer values
    newSystemOptions.forEach((option, optionIndex) => {
      if (option.tags.includes('Recommended')) {
        let tagsOrder: SystemOptionTag[] = ['Recommended'];
        if (customerCaresAboutMoney) {
          if (option.tags.includes('Cheapest')) tagsOrder.push('Cheapest');
          if (customerCaresAboutAesthetics && option.tags.includes('Most Aesthetic')) tagsOrder.push('Most Aesthetic');
        } else if (customerWantsOptimalSystem) {
          if (option.tags.includes('Optimal Design')) tagsOrder.push('Optimal Design');
          if (option.tags.includes('Best Coverage')) tagsOrder.push('Best Coverage');
          if (customerCaresAboutAesthetics && option.tags.includes('Most Aesthetic')) tagsOrder.push('Most Aesthetic');
        } else if (customerCaresAboutAesthetics) {
          if (option.tags.includes('Most Aesthetic')) tagsOrder.push('Most Aesthetic');
        } else {
          // eslint-disable-next-line no-lonely-if
          if (option.tags.includes('Most Aesthetic')) tagsOrder.push('Most Aesthetic');
        }
        if (customerCaresAboutInstallation && option.tags.includes('Easiest Installation')) {
          tagsOrder.push('Easiest Installation');
        }
        const remainingTags = option.tags.filter(tag => !tagsOrder.includes(tag));
        tagsOrder = tagsOrder.concat(remainingTags);
        newSystemOptions[optionIndex].tags = tagsOrder;
      }
    });

    const defaultTagsOrder: SystemOptionTag[] = ['Recommended', 'Optimal Design', 'Best Value', 'Cheapest', 'Best Coverage', 'Most Efficient', 'Most Aesthetic', 'Best Discounted System', 'Easiest Installation'];

    newSystemOptions.forEach((option, optionIndex) => {
      if (!customerCaresAboutMoney && !customerWantsOptimalSystem && !customerCaresAboutAesthetics && !customerCaresAboutInstallation) {
        newSystemOptions[optionIndex].tags = defaultTagsOrder.filter(tag => option.tags.includes(tag)) as SystemOptionTag[];
      }
    });

    // Rename Recommended to Best Fit if not Optimal Design
    newSystemOptions = newSystemOptions.map(option => {
      if (option.tags.includes('Recommended')) {
        const isOptimal = newSystemOptions.some(optimal => optimal.id === option.id && optimal.tags.includes('Optimal Design'));
        if (!isOptimal) {
          return {
            ...option,
            tags: option.tags.map(tag => tag === 'Recommended' ? 'Best Fit' : tag) as SystemOptionTag[]
          };
        }
      }
      return option;
    });

    setSystemOptions(sortSystemOptions(newSystemOptions, customerValues));

    if (newSystemOptions.length < 1) {
      setResultsMessage('No valid system options, try adjusting your configuration or filters.');
    }

  };

  useEffect(() => {
    const fetchAndSetRebates = async () => {
      const systemOptionsForRebate = systemOptions.slice(0, 3).filter(option => option.totalRebates === undefined);
      const rebatesRequest = systemOptionsForRebate.map(systemOption => getTotalRebates(systemOption));
      const rebates = await Promise.all(rebatesRequest);

      const newSystemOptions = systemOptions.map((option) => {
        const rebateIndex = systemOptionsForRebate.findIndex(rebateOption => rebateOption.id === option.id);
        if (rebateIndex !== -1) {
          let totalRebates = 0;
          const rebateData = rebates[rebateIndex]?.data;

          if (rebateData && rebateData.length > 0) {
            const products = [
              ...(option.configuration.condensers.map((c) => ({
                product_id: c.selectedProduct?.productId,
                product_price: c.selectedProduct?.price
              })) ?? []),
              ...(option.configuration.condensers.flatMap(c => c.zones.map((z) => ({
                product_id: z.selectedProduct?.productId,
                product_price: z.selectedProduct?.price
              }))) ?? [])
            ];

            const productCounts = products.reduce((acc, { product_id }) => {
              if (product_id) {
                acc[product_id] = (acc[product_id] || 0) + 1;
              }
              return acc;
            }, {} as Record<string, number>);

            totalRebates = Object.entries(productCounts).reduce((sum, [productId, count]) => {
              const productRebate = rebateData.find((rebate) => rebate.id === productId)?.rebateMaximum || 0;
              return sum + (productRebate * count);
            }, 0);
          }

          return {
            ...option,
            totalRebates: totalRebates > 0 ? totalRebates : 0
          };
        }
        return option;
      });

      setSystemOptions(newSystemOptions);
    };

    fetchAndSetRebates();
  }, [JSON.stringify(systemOptions.slice(0, 3).map(option => option.totalRebates))]);

  useEffect(() => {
    if (!systemOptions.length && configurations.every(config => config.condensers.every(condenser => condenser.rankedSystems.length > 0))) {
      const t1 = performance.now();
      console.log('Updating system options...');
      updateSystemOptions();
      const t2 = performance.now();
      console.log(`Done updating system options. (${Math.round(t2 - t1)}ms)`);
    }
  }, [
    activeConfig,
    airHandlerProducts,
    condenserProducts,
    configurations,
    systemFilters,
    customerValues
  ]);

  const handleFilterToggle = (code: keyof SystemFilters) => {
    setSystemOptions([]);
    setTimeout(() => {
      setSystemFilters((oldFilters) => {
        const newFilters = { ...oldFilters };
        newFilters[code] = !newFilters[code];
        return newFilters;
      });
    }, 10);
  };

  const handleResetFilters = () => {
    setSystemFilters(defaultUIState.systemFilters);
  };

  return (
    <div className='mx-auto flex max-w-[1400px] flex-col gap-5'>
      <div className="flex items-center gap-3 justify-between">
        <div className='text-3xl font-bold text-gray-900'>System Selection</div>
        <div className="flex gap-3">
          {advancedMode && (
            <Button
              color='primary'
              text='Cancel'
              onClick={() => setAdvancedMode(false)}
            />
          )}
          <CanAccess role='administrators'>
            <Button
              color='primary'
              text='Toggle Sandbox'
              onClick={() => setShowRankingSandbox(true)}
              disabled={!systemOptions.length}
            />
          </CanAccess>
        </div>
      </div>
      <div className='flex grow flex-col'>
        <div className='flex items-start gap-4 rounded-lg bg-white p-5 shadow ring-1 ring-black ring-opacity-5 mb-5'>
          <div className='font-semibold py-1'>Filters</div>
          <div className='flex grow flex-wrap gap-4'>
            {Object.keys(systemFilters).filter((key) => !configurations.some(c => c.condensers.length > 1) || key !== 'belowBudget').map((filter) => (
              <Chip
                key={filter}
                value={filter}
                label={systemFilterLabels[filter]}
                selected={systemFilters[filter]}
                onClick={() => handleFilterToggle(filter as keyof SystemFilters)}
                disabled={advancedMode}
                onDisabledClick={() => warningModal('Filters are disabled in advanced mode!')}
                showIcon
              />
            ))}
            {hasBeenReranked && (
              <LinkButton
                text='Reset'
                onClick={handleResetFilters}
                disabled={advancedMode}
                onDisabledClick={() => warningModal('Filters are disabled in advanced mode!')}
                bold
              />
            )}
          </div>
        </div>
        <div ref={animate} className="flex flex-wrap">
          {selectedSystems.map((system) => (
            <div key={system.id} className="mb-5 mr-3">
              <SystemOptionComponent system={system} />
            </div>
          ))}
        </div>
        {systemOptions.length > 0 ? (
          <EquipmentTable
            advancedMode={advancedMode}
            setAdvancedMode={setAdvancedMode}
          />
        ) : (
          <InternalNote>{resultsMessage}</InternalNote>
        )}
      </div>
    </div>
  );
}
