import React, { useContext } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { ProjectContext } from 'data/context';

export default function Map() {
  const { propertyLocation } = useContext(ProjectContext);

  const options: google.maps.MapOptions = {
    center: propertyLocation || { lat: 0, lng: 0 },
    zoom: 20,
    tilt: 45,
    mapTypeId: 'satellite',
    backgroundColor: 'lightgray'
  };

  const containerStyle = {
    height: '500px',
    width: '100%'
  };

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''
  });

  if (loadError) {
    return <div>Error loading map.</div>;
  }

  return isLoaded ? (
    <GoogleMap options={options} mapContainerStyle={containerStyle}>
      <Marker position={propertyLocation || { lat: 0, lng: 0 }} />
    </GoogleMap>
  ) : (
    <div>Loading...</div>
  );
}
