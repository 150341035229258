import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Condenser, RankedSystem, CondenserProduct, AirHandlerProduct, ValidSystemTag } from 'data/types';
import { abbreviateNumber, formatUSD, errorModal } from 'utils/helpers';
import { DataContext, ProjectContext } from 'data/context';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/16/solid';
import Tag from 'components/tag';
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  flexRender,
  FilterFn,
  Row,
  getSortedRowModel,
  RowData,
  ColumnDef,
  getFacetedUniqueValues
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import TableFilter from 'components/inputs/table-filter';
import ProductLink from 'components/product-link';
import Checkbox from 'components/inputs/checkbox';
import CoverageIndicator from 'components/coverage-indicator';
import ProgressIndicator from 'components/progress-indicator';
import AirHandlerCell from './air-handler-cell';

declare module '@tanstack/react-table' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    filterVariant?: 'condenser' | 'air handler' | 'tags';
  }
  interface FilterFns {
    condenserFilter: FilterFn<unknown>
    airHandlerFilter: FilterFn<unknown>
    tagsFilter: FilterFn<unknown>
  }
}

type SystemsTableProps = {
  condenser: Condenser;
  condenserIndex?: number;
  totalCondensers?: number;
  selectable?: boolean;
  onSelectSystem?: (index: number) => void;
};

export default function SystemsTable({ condenser, selectable = false, onSelectSystem, condenserIndex, totalCondensers }: SystemsTableProps) {
  const {
    condenserProducts,
    airHandlerProducts
  } = useContext(DataContext);
  const {
    systemsForNewOption,
    setSystemsForNewOption
  } = useContext(ProjectContext);
  const columnHelper = createColumnHelper<RankedSystem>();
  const [data, setData] = useState<RankedSystem[]>([]);
  const tableContainerRef = React.useRef<HTMLDivElement>(null);

  const filterUniqueSystems = (systems) => {
    const uniqueIds = new Set();
    return systems.filter(system => {
      if (uniqueIds.has(system.id)) {
        return false;
      }
      uniqueIds.add(system.id);
      return true;

    });
  };
  const handleSelectSystem = (systemId: number) => {
    const newSystems = [...systemsForNewOption];
    const condenserSystems = systemsForNewOption.filter(s => s.condenserUnique === condenser.uniqueId);
    const systemIds = condenser.rankedSystems.map((s) => s.id);
    if (!condenserSystems.map(s => s.id).includes(systemId)) {
      systemIds.forEach((id) => {
        const oldSystemIndex = systemsForNewOption.findIndex((s) => s.condenserUnique === condenser.uniqueId && s.id === id);
        if (oldSystemIndex !== -1) {
          newSystems.splice(oldSystemIndex, 1);
        }
      });
      const selectedSystem = structuredClone(condenser.rankedSystems.find((s) => s.id === systemId));
      if (selectedSystem) {
        newSystems.push(selectedSystem as RankedSystem);
        setSystemsForNewOption(newSystems);
        if (condenserIndex !== undefined && totalCondensers !== undefined && onSelectSystem) {
          const nextCondenserIndex = condenserIndex + 1;
          if (nextCondenserIndex < totalCondensers) {
            onSelectSystem(nextCondenserIndex);
          }
        }
      } else {
        errorModal('Selected system not found');
      }
    } else {
      setSystemsForNewOption(newSystems.filter((s) => s.condenserUnique !== condenser.uniqueId && s.id !== systemId));
    }
  };

  const columns = useMemo<ColumnDef<RankedSystem, any>[]>(
    () => [
      columnHelper.accessor('id', {
        header: '',
        size: 50,
        cell: (info) => selectable ? (
          <div className="px-2">
            <Checkbox
              value={systemsForNewOption.filter(s => s.condenserUnique === condenser.uniqueId).map((s) => s.id).includes(info.getValue())}
              onChange={() => handleSelectSystem(info.getValue())}
            />
          </div>
        ) : <div />,
        enableColumnFilter: false,
        enableSorting: false
      }),
      columnHelper.accessor((system) => condenserProducts.find((cp) => cp.productId === system.condenserProductId), {
        header: 'Condenser',
        size: 200,
        cell: (info) => info.getValue() ? (
          <div className='flex flex-col'>
            <ProductLink type='condenser' product={info.getValue() as CondenserProduct} hoverInfo />
            <div className='flex flex-wrap gap-1'>
              {info.getValue()?.coolingBTU && (
                <Tag
                  color='default'
                  text={abbreviateNumber(info.getValue()?.coolingBTU ?? 0)}
                />
              )}
              <Tag
                color='default'
                text={`${info.getValue()?.seer} SEER`}
              />
            </div>
          </div>
        ) : (
          <span className='text-red-500'>Unknown Product</span>
        ),
        meta: {
          filterVariant: 'condenser'
        },
        filterFn: 'condenserFilter',
        enableColumnFilter: true,
        enableSorting: false
      }),
      columnHelper.accessor((system) => airHandlerProducts.find((ah) => ah.productId === system.airHandler1ProductId), {
        header: 'Zone 1',
        size: 200,
        cell: (info) => <AirHandlerCell airHandler={info.getValue()} />,
        meta: {
          filterVariant: 'air handler'
        },
        filterFn: 'airHandlerFilter',
        enableColumnFilter: true,
        enableSorting: false
      }),
      columnHelper.accessor((system) => airHandlerProducts.find((ah) => ah.productId === system.airHandler2ProductId), {
        id: 'zone2',
        header: 'Zone 2',
        size: 200,
        cell: (info) => <AirHandlerCell airHandler={info.getValue()} />,
        meta: {
          filterVariant: 'air handler'
        },
        filterFn: 'airHandlerFilter',
        enableColumnFilter: true,
        enableSorting: false
      }),
      columnHelper.accessor((system) => airHandlerProducts.find((ah) => ah.productId === system.airHandler3ProductId), {
        id: 'zone3',
        header: 'Zone 3',
        size: 200,
        cell: (info) => <AirHandlerCell airHandler={info.getValue()} />,
        meta: {
          filterVariant: 'air handler'
        },
        filterFn: 'airHandlerFilter',
        enableColumnFilter: true,
        enableSorting: false
      }),
      columnHelper.accessor((system) => airHandlerProducts.find((ah) => ah.productId === system.airHandler4ProductId), {
        id: 'zone4',
        header: 'Zone 4',
        size: 200,
        cell: (info) => <AirHandlerCell airHandler={info.getValue()} />,
        meta: {
          filterVariant: 'air handler'
        },
        filterFn: 'airHandlerFilter',
        enableColumnFilter: true,
        enableSorting: false
      }),
      columnHelper.accessor((system) => airHandlerProducts.find((ah) => ah.productId === system.airHandler5ProductId), {
        id: 'zone5',
        header: 'Zone 5',
        size: 200,
        cell: (info) => <AirHandlerCell airHandler={info.getValue()} />,
        meta: {
          filterVariant: 'air handler'
        },
        filterFn: 'airHandlerFilter',
        enableColumnFilter: true,
        enableSorting: false
      }),
      columnHelper.accessor('baseRetailPrice', {
        header: 'Price',
        size: 80,
        cell: (info) => formatUSD(info.getValue()),
        enableColumnFilter: false,
        enableSorting: true,
        sortDescFirst: false,
      }),
      columnHelper.accessor(
        (system) => ({
          missingCoolingDays: system.daysOfInsufficientCooling,
          missingHeatingDays: system.daysOfInsufficientHeating,
          minCoolingPercent: system.avgCoolingPercent,
          minHeatingPercent: system.avgHeatingPercent,
          coolingScore: system.coolingScore,
          heatingScore: system.heatingScore
        }),
        {
          header: 'Coverage',
          cell: (info) => (
            <div className='flex items-center gap-3'>
              <CoverageIndicator
                type='heating'
                score={info.getValue().heatingScore}
                missingDays={info.getValue().missingHeatingDays}
                minimumPercent={info.getValue().minHeatingPercent}
              />
              <CoverageIndicator
                type='cooling'
                score={info.getValue().coolingScore}
                missingDays={info.getValue().missingCoolingDays}
                minimumPercent={info.getValue().minCoolingPercent}
              />
            </div>
          ),
          enableColumnFilter: false,
          enableSorting: true,
          sortingFn: (rowA, rowB) => (rowB.original.heatingScore + rowB.original.coolingScore) - (rowA.original.heatingScore + rowA.original.coolingScore),
          sortDescFirst: false,
        }
      ),
      columnHelper.accessor('inStock', {
        header: 'Stock',
        size: 60,
        cell: (info) => (
          info.getValue() ? (
            <div className='h-4 w-4 rounded-full bg-green-500' />
          ) : (
            <div className='h-4 w-4 rounded-full bg-red-500' />
          )
        ),
        enableColumnFilter: false,
        enableSorting: false
      }),
      columnHelper.accessor('score', {
        header: 'Score',
        size: 60,
        cell: (info) => info.getValue()?.toFixed(2),
        enableColumnFilter: false,
        enableSorting: false
      }),
      columnHelper.accessor('tags', {
        header: 'Tags',
        size: 200,
        cell: (info) => (
          <div className='flex flex-wrap gap-1'>
            {info.getValue()?.map((tag) => (
              <Tag key={tag} text={tag} />
            ))}
          </div>
        ),
        meta: {
          filterVariant: 'tags'
        },
        filterFn: 'tagsFilter',
        enableColumnFilter: true,
        enableSorting: false
      })
    ], [systemsForNewOption]);

  const condenserFilter: FilterFn<RankedSystem> = (row: Row<RankedSystem>, columnId: string, filterValue: string[]): boolean => {
    const product = row.getValue(columnId) as CondenserProduct;
    let matchesFilter = true;
    if (filterValue[0] && !product.model.toLowerCase().includes(filterValue[0]?.toLowerCase())) {
      matchesFilter = false;
    }
    if (filterValue[1] && product.coolingBTU.toString() !== filterValue[1]) {
      matchesFilter = false;
    }
    return matchesFilter;
  };

  const airHandlerFilter: FilterFn<RankedSystem> = (row: Row<RankedSystem>, columnId: string, filterValue: string[]): boolean => {
    const product = row.getValue(columnId) as AirHandlerProduct;
    let matchesFilter = true;
    if (filterValue[0] && !product.model.toLowerCase().includes(filterValue[0]?.toLowerCase()) && !product.type.toLowerCase().includes(filterValue[0]?.toLowerCase())) {
      matchesFilter = false;
    }
    if (filterValue[1] && product.sizeCode !== filterValue[1]) {
      matchesFilter = false;
    }
    return matchesFilter;
  };

  const tagsFilter: FilterFn<RankedSystem> = (row: Row<RankedSystem>, columnId: string, filterValue: ValidSystemTag[]): boolean => {
    const tags = row.getValue(columnId) as ValidSystemTag[];
    let matchesFilter = true;
    if (filterValue.length > 0 && !filterValue.every((tag) => tags.includes(tag))) {
      matchesFilter = false;
    }
    return matchesFilter;
  };

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility: {
        'zone2': condenser.rankedSystems[0].airHandler2ProductId !== null,
        'zone3': condenser.rankedSystems[0].airHandler3ProductId !== null,
        'zone4': condenser.rankedSystems[0].airHandler4ProductId !== null,
        'zone5': condenser.rankedSystems[0].airHandler5ProductId !== null
      }
    },
    filterFns: {
      condenserFilter,
      airHandlerFilter,
      tagsFilter
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    enableRowSelection: true
  });

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 26,
    getScrollElement: () => tableContainerRef.current,
    measureElement:
      typeof window !== 'undefined' &&
        navigator.userAgent.indexOf('Firefox') === -1
        ? element => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  });

  useEffect(() => {
    setData(filterUniqueSystems(condenser.rankedSystems));
  }, [condenser.rankedSystems]);

  return (
    <div className='flex flex-col gap-3'>
      <div className="flex items-center gap-2">
        {selectable && (
          <ProgressIndicator
            required
            checked={systemsForNewOption.some(s => s.condenserUnique === condenser.uniqueId)}
          />
        )}
        <div className='text-xl font-bold text-gray-900'>
          {condenser.name} ({table.getFilteredRowModel().rows.length} systems)
        </div>
      </div>
      <div ref={tableContainerRef} className='overflow-auto relative max-h-[500px] shadow ring-1 ring-black ring-opacity-5 rounded-lg bg-white'>
        <table className='grid border-collapse table-fixed'>
          <thead className='grid sticky top-0 z-10 border-b border-gray-300 bg-gray-50'>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id} className='flex w-full text-left'>
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    className='flex flex-col gap-1 px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    style={{ width: header.getSize() }}
                  >
                    <button
                      className={`group flex items-center ${header.column.getCanSort() ? 'cursor-pointer' : 'cursor-default'}`}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {header.isPlaceholder ? null :
                        flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      {header.column.getIsSorted() === 'asc' && (
                        <ChevronDownIcon className='w-5 h-5' />
                      )}
                      {header.column.getIsSorted() === 'desc' && (
                        <ChevronUpIcon className='w-5 h-5' />
                      )}
                      {header.column.getCanSort() && header.column.getIsSorted() === false && (
                        <ChevronDownIcon className='w-5 h-5 text-transparent group-hover:text-gray-400' />
                      )}
                    </button>
                    {header.column.getCanFilter() ? (
                      <div className='font-normal'>
                        <TableFilter column={header.column} />
                      </div>
                    ) : null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className='grid relative bg-white' style={{ height: rowVirtualizer.getVirtualItems().length > 0 ? `${rowVirtualizer.getTotalSize()}px` : 'auto' }}>
            {rowVirtualizer.getVirtualItems().length > 0 ? rowVirtualizer.getVirtualItems().map(virtualRow => {
              const row = rows[virtualRow.index];
              const system = row.original;
              const isSelected = systemsForNewOption.filter(s => s.condenserUnique === condenser.uniqueId).map((s) => s.id).includes(system.id);
              return (
                <tr
                  className={`flex items-center absolute w-full border-b border-gray-300 ${isSelected && 'bg-blue-50'} ${!isSelected && selectable && 'hover:bg-gray-50'} ${selectable && 'cursor-pointer'}`}
                  data-index={virtualRow.index}
                  ref={node => rowVirtualizer.measureElement(node)}
                  key={row.id}
                  style={{ transform: `translateY(${virtualRow.start}px)` }}
                  onClick={selectable ? () => handleSelectSystem(system.id) : undefined}
                >
                  {row.getVisibleCells().map(cell => (
                    <td
                      key={cell.id}
                      style={{ width: cell.column.getSize() }}
                      className='flex px-3 py-4 text-sm text-gray-900'
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              );
            }) : (
              <tr>
                <td className='flex items-center justify-center h-20' colSpan={table.getAllColumns().filter(col => col.getIsVisible()).length}>
                  <span className='text-gray-500'>No systems found.</span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

SystemsTable.defaultProps = {
  selectable: false,
  onSelectSystem: () => { }
};