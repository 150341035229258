import React, { useContext, useCallback, useState, useEffect } from 'react';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import { PlusIcon, CheckIcon, ArrowPathIcon } from '@heroicons/react/20/solid';
import { v4 as uuid } from 'uuid';
import { ConfigurationContext } from 'data/context';
import { confirmationModal, errorModal, warningModal } from 'utils/helpers';
import { Configuration, Condenser, Space, ZoneItem, Zone, ExistingSystem } from 'data/types';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import Tooltip from 'components/tooltip';
import { useDrop } from 'react-dnd';
import { findSpaceById } from 'utils/structure';
import Text from 'components/inputs/text';
import Tag from 'components/tag';
import { Popover, PopoverTrigger, PopoverContent, PopoverClose } from 'components/popover';
import Button from 'components/inputs/button';
import CondenserComponent from 'components/dnd/condenser';
import WarningTooltip from 'components/warning-tooltip';
import ZoneableSpace from 'components/dnd/zoneable-space';
import ExistingSystemComponent from 'components/dnd/existing-system-item';

type ComparisonFeature =
  | 'Condenser Location'
  | 'Outdoor Units'
  | 'Indoor Units'
  | 'Total Components'
  | 'Total Line Set Length';

type ConfigurationProps = {
  config: Configuration;
};

export default function ConfigurationComponent({ config }: ConfigurationProps) {
  const {
    structure,
    configurations,
    configurationOptions,
    setConfigurations,
    setConfigurationOptions,
    setActiveConfig,
    selectedSystems,
    setSelectedSystems,
    setActiveSelectedSystem,
    allConfigurationOptions,
    existingSystems
  } = useContext(ConfigurationContext);
  const [zoneableSpaces, setZoneableSpaces] = useState<Space[]>([]);
  const [zoneableExistingSystems, setZoneableExistingSystems] = useState<ExistingSystem[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('Invalid Configuration');
  const [renaming, setRenaming] = useState(false);
  const [newName, setNewName] = useState(config.name);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [animate] = useAutoAnimate({ duration: 100 });
  const renameSizeLimit = 50;
  const comparisonFeatures: ComparisonFeature[] = [
    'Condenser Location',
    'Outdoor Units',
    'Indoor Units',
    'Total Components',
    'Total Line Set Length'
  ];

  const addUnzonedItem = (unzonedItemId: string) => {
    const structureItem = findSpaceById(structure, unzonedItemId);
    if (structureItem) {
      setConfigurationOptions((draft) => {
        const configIndex = draft.findIndex((conf) => conf.uniqueId === config.uniqueId);
        if (configIndex !== -1) {
          // eslint-disable-next-line no-param-reassign
          draft[configIndex].modified = true;
          const newZoneItem: ZoneItem = {
            uniqueId: uuid(),
            structureItemId: unzonedItemId,
            name: structureItem.label,
            BTUs: structureItem.BTUs,
            originalBTUs: structureItem.BTUs,
            isPartition: false
          };
          let zoneNumber = 1;
          const zoneNames = draft[configIndex].condensers.flatMap((cond) => cond.zones.map((zone) => zone.name));
          while (zoneNames.includes(`Zone ${zoneNumber}`)) {
            zoneNumber += 1;
          }
          const newZone: Zone = {
            uniqueId: uuid(),
            name: `Zone ${zoneNumber}`,
            type: 'ductless',
            zoneItems: [newZoneItem],
            modified: true
          };
          let condenserNumber = 1;
          const condenserNames = draft[configIndex].condensers.map((cond) => cond.name);
          while (condenserNames.includes(`Condenser ${condenserNumber}`)) {
            condenserNumber += 1;
          }
          const newCondenser: Condenser = {
            uniqueId: uuid(),
            type: 'ductless',
            name: `Condenser ${condenserNumber}`,
            validSystems: [],
            validDuctedSystems: [],
            rankedSystems: [],
            rankedDuctedSystems: [],
            zones: [newZone],
            modified: true
          };
          draft[configIndex].condensers.push(newCondenser);
        };
      });
    } else {
      console.error('Structure item not found.');
      errorModal('Structure item not found.');
    }
  };

  const moveZone = (zoneId: string) => {
    setConfigurationOptions((draft) => {
      const configIndex = draft.findIndex((conf) => conf.uniqueId === config.uniqueId);
      if (configIndex !== -1) {
        const fromCondenserIndex = draft[configIndex].condensers.findIndex((cond) =>
          cond.zones.some((zone) => zone.uniqueId === zoneId)
        );
        if (fromCondenserIndex !== -1) {
          const fromZoneIndex = draft[configIndex].condensers[fromCondenserIndex].zones.findIndex(
            (zone) => zone.uniqueId === zoneId
          );
          if (fromZoneIndex !== -1) {
            const zone = draft[configIndex].condensers[fromCondenserIndex].zones.splice(
              fromZoneIndex,
              1
            )[0];
            let condenserNumber = 1;
            const condenserNames = draft[configIndex].condensers.map((cond) => cond.name);
            while (condenserNames.includes(`Condenser ${condenserNumber}`)) {
              condenserNumber += 1;
            }
            const newCondenser: Condenser = {
              uniqueId: uuid(),
              type: 'ductless',
              name: `Condenser ${condenserNumber}`,
              validSystems: [],
              validDuctedSystems: [],
              rankedSystems: [],
              rankedDuctedSystems: [],
              zones: [zone],
              modified: true
            };
            draft[configIndex].condensers.push(newCondenser);
          }
        }
      };
    });
  };

  const moveZoneItem = (itemId: string) => {
    setConfigurationOptions((draft) => {
      const configIndex = draft.findIndex((conf) => conf.uniqueId === config.uniqueId);
      if (configIndex !== -1) {
        const fromCondenserIndex = draft[configIndex].condensers.findIndex((cond) =>
          cond.zones.some((zone) => zone.zoneItems.some((item) => item.uniqueId === itemId))
        );
        if (fromCondenserIndex !== -1) {
          const fromZoneIndex = draft[configIndex].condensers[fromCondenserIndex].zones.findIndex(
            (zone) => zone.zoneItems.some((item) => item.uniqueId === itemId)
          );
          if (fromZoneIndex !== -1) {
            const itemIndex = draft[configIndex].condensers[fromCondenserIndex].zones[fromZoneIndex].zoneItems.findIndex(
              (item) => item.uniqueId === itemId
            );
            if (itemIndex !== -1) {
              const zoneItem = draft[configIndex].condensers[fromCondenserIndex].zones[fromZoneIndex].zoneItems.splice(
                itemIndex,
                1
              )[0];
              let zoneNumber = 1;
              const zoneNames = draft[configIndex].condensers.flatMap((cond) => cond.zones.map((zone) => zone.name));
              while (zoneNames.includes(`Zone ${zoneNumber}`)) {
                zoneNumber += 1;
              }
              const newZone: Zone = {
                uniqueId: uuid(),
                name: `Zone ${zoneNumber}`,
                type: 'ductless',
                zoneItems: [zoneItem],
                modified: true
              };
              let condenserNumber = 1;
              const condenserNames = draft[configIndex].condensers.map((cond) => cond.name);
              while (condenserNames.includes(`Condenser ${condenserNumber}`)) {
                condenserNumber += 1;
              }
              const newCondenser: Condenser = {
                uniqueId: uuid(),
                type: 'ductless',
                name: `Condenser ${condenserNumber}`,
                validSystems: [],
                validDuctedSystems: [],
                rankedSystems: [],
                rankedDuctedSystems: [],
                zones: [newZone],
                modified: true
              };
              draft[configIndex].condensers.push(newCondenser);
            }
          }
        }
      };
    });
  };

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: [
      `config-${config.uniqueId}-zone`,
      `config-${config.uniqueId}-zone-item`,
      `config-${config.uniqueId}-zoneable-space`
    ],
    drop: (
      item: {
        id: string;
        type: 'zone' | 'zone-item' | 'zoneable-space';
      },
      monitor
    ) => {
      if (item.type === 'zone' && !monitor.didDrop()) {
        if (selectedSystems.filter(system => system.configuration.uniqueId === config.uniqueId).length > 0 || config.condensers.some(cond => cond.selectedProduct || cond.zones.some(zone => zone.selectedProduct))) {
          confirmationModal('Are you sure you want to update this configuration? This will reset all selected products and systems for this configuration!', () => {
            moveZone(item.id);
          });
        } else {
          moveZone(item.id);
        }
      }
      if (item.type === 'zoneable-space' && !monitor.didDrop()) {
        addUnzonedItem(item.id);
      }
      if (item.type === 'zone-item' && !monitor.didDrop()) {
        if (selectedSystems.filter(system => system.configuration.uniqueId === config.uniqueId).length > 0 || config.condensers.some(cond => cond.selectedProduct || cond.zones.some(zone => zone.selectedProduct))) {
          confirmationModal('Are you sure you want to update this configuration? This will reset all selected products and systems for this configuration!', () => {
            moveZoneItem(item.id);
          });
        } else {
          moveZoneItem(item.id);
        }
      }
    },
    collect: (monitor) => ({
      canDrop: !!monitor.canDrop(),
      isOver: !!monitor.isOver({ shallow: true })
    })
  }), [selectedSystems, config]);

  useEffect(() => {
    const newZoneableSpaces: Space[] = [];
    structure.forEach((level) => {
      level.sections.forEach((section) => {
        section.spaces.forEach((space) => {
          if (
            (space.BTUs?.heating || space.BTUs?.cooling) &&
            !config.condensers.some((cond) =>
              cond.zones.some((zone) =>
                zone.zoneItems.some((item) => item.structureItemId === space.uniqueId)
              )
            )
          ) {
            newZoneableSpaces.push(space);
          }
        });
      });
    });
    setZoneableSpaces(newZoneableSpaces);
  }, [structure, config.condensers]);

  useEffect(() => {
    const newZoneableExistingSystems: ExistingSystem[] = [];
    existingSystems.forEach((existingSystem) => {
      if (
        !config.condensers.some((cond) =>
          cond.zones.some((zone) => zone.existingSystemId === existingSystem.id)
        )
      ) {
        newZoneableExistingSystems.push(existingSystem);
      }
    });
    setZoneableExistingSystems(newZoneableExistingSystems);
  }, [existingSystems, config.condensers]);

  useEffect(() => {
    setConfigurationOptions((draft) => {
      const confIndex = draft.findIndex((conf) => conf.uniqueId === config.uniqueId);
      if (config.type === 'ductless' && zoneableSpaces.length > 0) {
        // eslint-disable-next-line no-param-reassign
        draft[confIndex].valid = false;
        return;
      }
      const everyCondenserIsValid = config.condensers.every(
        (cond) => cond.valid
      );
      const everyZoneIsValid = config.condensers.every((cond) =>
        cond.zones.every((zone) => zone.valid)
      );
      // eslint-disable-next-line no-param-reassign
      draft[confIndex].valid = everyCondenserIsValid && everyZoneIsValid;
    });
    let newErrorMessage = 'Invalid Configuration';
    if (!config.condensers.every((cond) => cond.zones.every((zone) => zone.valid))) {
      newErrorMessage = 'One or more zones are invalid';
    }
    if (!config.condensers.every((cond) => cond.valid)) {
      newErrorMessage = 'One or more condensers are invalid';
    }
    if (config.type === 'ductless' && zoneableSpaces.length > 0) {
      newErrorMessage = 'Not all spaces assigned to a zone';
    }
    if (config.condensers.length > 0 && config.condensers.every(cond => cond.validSystemsFetched) && !config.condensers.every(cond => cond.validSystems.length > 0 || cond.validDuctedSystems.length > 0)) {
      newErrorMessage = 'One or more condensers have no valid systems';
    }
    setErrorMessage(newErrorMessage);
  }, [config.condensers, zoneableSpaces]);

  useEffect(() => {
    if (!isFirstRender) {
      setConfigurationOptions((draft) => {
        const configIndex = draft.findIndex((conf) => conf.uniqueId === config.uniqueId);
        if (configIndex !== -1) {
          draft[configIndex].condensers.forEach((cond) => {
            // eslint-disable-next-line no-param-reassign
            cond.validSystems = [];
            // eslint-disable-next-line no-param-reassign
            cond.rankedSystems = [];
            // eslint-disable-next-line no-param-reassign
            cond.validSystemsFetched = false;
            // eslint-disable-next-line no-param-reassign
            cond.selectedProduct = undefined;
            cond.zones.forEach((zone) => {
              // eslint-disable-next-line no-param-reassign
              zone.selectedProduct = undefined;
              // eslint-disable-next-line no-param-reassign
              zone.airHandlerFeatures = undefined;
            });
          });
        }
      });
      setSelectedSystems((draft) => draft.filter(system => system.configuration.uniqueId !== config.uniqueId));
      setActiveSelectedSystem(0);
    } else {
      setIsFirstRender(false);
    }
  }, [
    JSON.stringify({
      uniqueId: config.uniqueId, condensers: config.condensers.map((cond) => ({
        uniqueId: cond.uniqueId, zones: cond.zones.map((zone) => ({
          uniqueId: zone.uniqueId, zoneItems: zone.zoneItems.map((item) => ({
            uniqueId: item.uniqueId
          }))
        }))
      }))
    })
  ]);

  const addCondenser = () => {
    setConfigurationOptions((draft) => {
      const configIndex = draft.findIndex((conf) => conf.uniqueId === config.uniqueId);
      if (configIndex !== -1) {
        let condenserNumber = 1;
        const condenserNames = draft[configIndex].condensers.map((cond) => cond.name);
        while (condenserNames.includes(`Condenser ${condenserNumber}`)) {
          condenserNumber += 1;
        }
        const newCondenser: Condenser = {
          uniqueId: uuid(),
          type: 'ductless',
          name: `Condenser ${condenserNumber}`,
          validSystems: [],
          validDuctedSystems: [],
          rankedSystems: [],
          rankedDuctedSystems: [],
          zones: [],
          modified: false
        };
        draft[configIndex].condensers.push(newCondenser);
      }
    });
  };

  const handleAddCondenserClick = useCallback(() => {
    if (selectedSystems.filter(system => system.configuration.uniqueId === config.uniqueId).length > 0 || config.condensers.some(cond => cond.selectedProduct || cond.zones.some(zone => zone.selectedProduct))) {
      confirmationModal('Are you sure you want to update this configuration? This will reset all selected products and systems for this configuration!', () => {
        addCondenser();
      });
    } else {
      addCondenser();
    }
  }, [selectedSystems]);

  const handleDeleteClick = () => {
    const deleteSelf = () => {
      setConfigurationOptions((draft) => draft.filter((conf) => conf.uniqueId !== config.uniqueId));
      setSelectedSystems((draft) => draft.filter(system => system.configuration.uniqueId !== config.uniqueId));
      setActiveConfig(0);
      setActiveSelectedSystem(0);
    };
    const confirmText = selectedSystems.filter(system => system.configuration.uniqueId === config.uniqueId).length > 0 || config.condensers.some(cond => cond.selectedProduct || cond.zones.some(zone => zone.selectedProduct)) ?
      'Are you sure you want to delete this configuration? This will reset all selected products and systems for this configuration!' :
      'Are you sure you want to delete this configuration option?';
    if (
      config.modified ||
      config.condensers.some(cond => cond.modified || cond.zones.some(zone => zone.modified)) ||
      selectedSystems.filter(system => system.configuration.uniqueId === config.uniqueId).length > 0 ||
      config.condensers.some(cond => cond.selectedProduct || cond.zones.some(zone => zone.selectedProduct))
    ) {
      confirmationModal(confirmText, deleteSelf);
    } else {
      deleteSelf();
    }
  };

  const handleUpdateName = () => {
    if (newName.trim() !== '') {
      setConfigurationOptions((draft) => {
        const configIndex = draft.findIndex((conf) => conf.uniqueId === config.uniqueId);
        if (configIndex !== -1) {
          // eslint-disable-next-line no-param-reassign
          draft[configIndex].name = newName.trim();
        }
      });
      setNewName(newName.trim());
    } else {
      setNewName(config.name);
    }
    setRenaming(false);
  };

  const handleRenameKeyDown = (key: string) => {
    if (key === 'Enter') {
      handleUpdateName();
    }
  };

  const handleChangeConfig = (uniqueId: string) => {
    const newConfig = allConfigurationOptions.find((opt) => opt.uniqueId === uniqueId);
    if (newConfig) {
      setConfigurationOptions((draft) => {
        const configIndex = draft.findIndex((conf) => conf.uniqueId === config.uniqueId);
        if (configIndex !== -1) {
          // eslint-disable-next-line no-param-reassign
          draft[configIndex] = newConfig;
        }
      });
    }
  };

  const handleConfigSelect = () => {
    setConfigurations((draft) => {
      const configIndex = draft.findIndex((opt) => opt.uniqueId === config.uniqueId);
      if (configIndex !== -1) {
        draft.splice(configIndex, 1);
      } else {
        draft.push(config);
      }
    });
  };

  return (
    <div className='rounded-lg shadow ring-1 ring-black ring-opacity-5 relative flex flex-col items-center gap-3 p-5 pt-[60px] bg-white grow shrink-0 basis-0 max-w-[35%] overflow-hidden'>
      <div className='absolute left-2 right-2 top-2 flex gap-1 items-start'>
        <div className='flex flex-wrap gap-1 grow'>
          {config.tags?.slice(0, 3).map((tag) => (
            <Tag key={tag} text={tag} color={tag === 'Recommended' ? 'purple' : 'default'} />
          ))}
          {(config.tags?.length ?? 0) > 4 && (
            <div className="relative cursor-help">
              <Tag text={`+${(config.tags?.length ?? 3) - 3}`} />
              <Tooltip text={config.tags?.slice(3).join(', ') ?? 'N/A'} />
            </div>
          )}
        </div>
        <div className='flex items-center gap-1'>
          {!config.valid && (
            <WarningTooltip type={'error'}>
              {errorMessage}
            </WarningTooltip>
          )}
          <Popover placement='bottom-start'>
            <PopoverTrigger className='text-gray-400 hover:text-gray-500 relative'>
              <ArrowPathIcon className='w-6 h-6' />
              <Tooltip text='Change Configuration' />
            </PopoverTrigger>
            <PopoverContent className="flex flex-col p-2 rounded-lg bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
              {allConfigurationOptions.filter((option) => !configurationOptions.map((co) => co.uniqueId).includes(option.uniqueId)).map((configOption) => (
                <PopoverClose
                  key={configOption.uniqueId}
                  onClick={() => handleChangeConfig(configOption.uniqueId)}
                  className='rounded-md px-3 py-2 text-left text-sm font-semibold text-gray-900 hover:bg-gray-100 flex items-center gap-2'
                >
                  <span>{configOption.name}</span>
                  {(configOption.tags?.length ?? 0) >= 1 && (
                    <Tag text={configOption.tags?.[0]} color={configOption.tags?.[0] === 'Recommended' ? 'purple' : 'default'} />
                  )}
                  {(configOption.tags?.length ?? 0) > 1 && (
                    <div className="relative cursor-help">
                      <Tag text={`+${(configOption.tags?.length ?? 1) - 1}`} />
                      <Tooltip text={configOption.tags?.slice(1).join(', ') ?? 'N/A'} />
                    </div>
                  )}
                </PopoverClose>
              ))}
            </PopoverContent>
          </Popover>
          <button
            className='text-gray-400 hover:text-gray-500 relative'
            onClick={() => handleDeleteClick()}
          >
            <TrashIcon className='w-6 h-6' />
            <Tooltip text='Delete Option' />
          </button>
        </div>
      </div>
      <div className='w-full flex flex-col items-center'>
        {renaming && (
          <div className='w-full relative'>
            <Text
              size='sm'
              onInput={(value) => setNewName((value ?? '').slice(0, renameSizeLimit))}
              value={newName}
              onBlur={() => handleUpdateName()}
              onKeyDown={(e) => handleRenameKeyDown(e.key)}
              autoFocus
              autoSelect
              controlled
            />
            <div className='absolute inset-y-0 right-0 flex items-center pr-2 text-gray-500 text-sm pointer-events-none'>
              {newName.length}/{renameSizeLimit}
            </div>
          </div>
        )}
        {!renaming && (
          <button
            type='button'
            onClick={() => setRenaming(true)}
            className='relative flex items-center gap-2 text-xl font-bold outline-none cursor-pointer group'
          >
            <span>
              {config.name}
            </span>
            <div className="shrink-none">
              <PencilSquareIcon className='h-5 w-5 text-gray-600 group-hover:text-gray-900' />
            </div>
            <Tooltip text='Rename' />
          </button>
        )}
      </div>
      <Button
        fullWidth
        color={configurations.some((s) => s.uniqueId === config.uniqueId) ? 'green' : 'primary'}
        text={configurations.some((s) => s.uniqueId === config.uniqueId) ? 'Selected' : 'Select'}
        onClick={handleConfigSelect}
        disabled={(configurations.length >= 3 && !configurations.some((s) => s.uniqueId === config.uniqueId)) || !config.condensers.length || !config.condensers.every(c => c.validSystems.length > 0 || c.validDuctedSystems.length > 0)}
        onDisabledClick={configurations.length >= 3 ? () => warningModal('You can only select up to 3 configurations at a time') : undefined}
        icon={configurations.some((s) => s.uniqueId === config.uniqueId) ? CheckIcon : undefined}
        size='lg'
      />
      <div className='w-full flex flex-col justify-between grow'>
        <div ref={animate} className="w-full flex flex-col gap-3 pt-2">
          {config.condensers.map((condenser) => (
            <CondenserComponent
              key={condenser.uniqueId}
              condenser={condenser}
              configId={config.uniqueId}
              simpleMode
            />
          ))}
          {config.type !== 'ducted' && (
            <div ref={drop}>
              <button
                type='button'
                className='inline-flex cursor-pointer items-center gap-1 p-3 text-sm font-semibold text-blue-500 hover:text-blue-600'
                onClick={handleAddCondenserClick}
              >
                <PlusIcon className='h-5 w-5' />
                <span>{config.type === 'hybrid' ? 'Add Mini-Split Condenser' : 'Add Condenser'}</span>
              </button>
            </div>
          )}
          {zoneableExistingSystems.length > 0 && (config.type === 'ducted' || config.type === 'hybrid') && (
            <fieldset className='flex flex-wrap gap-2 border-2 border-gray-300 rounded-lg px-2 pb-2 pt-1'>
              <legend className='px-2 text-gray-700 font-semibold'>Unzoned Replacement Systems</legend>
              {zoneableExistingSystems.map((existingSystem) => (
                <ExistingSystemComponent
                  key={existingSystem.id}
                  configId={config.uniqueId}
                  existingSystem={existingSystem}
                  zoneable
                />
              ))}
            </fieldset>
          )}
          {zoneableSpaces.length > 0 && config.type !== 'ducted' && (
            <fieldset className='flex flex-wrap gap-2 border-2 border-gray-300 rounded-lg px-2 pb-2 pt-1'>
              <legend className='px-2 text-gray-700 font-semibold'>Unzoned Spaces</legend>
              {zoneableSpaces.map((zoneableSpace) => (
                <ZoneableSpace
                  key={zoneableSpace.uniqueId}
                  id={zoneableSpace.uniqueId}
                  configId={config.uniqueId}
                  label={zoneableSpace.label}
                />
              ))}
            </fieldset>
          )}
        </div>
        <div className={`flex flex-col divide-y divide-gray-300 mt-5 rounded-lg`}>
          {comparisonFeatures.map((feature) => (
            <div key={feature} className='w-full py-3 flex items-center justify-center'>
              <div className='flex items-center justify-center gap-2 font-semibold w-full'>
                <span className='text-gray-500 font-normal'>{feature}:</span>
                {feature === 'Condenser Location' && (
                  <div className='flex items-center gap-1 whitespace-nowrap'>
                    <span>{config.condensers[0].location?.location ?? 'N/A'}</span>
                  </div>
                )}
                {feature === 'Outdoor Units' && (
                  <div className='flex items-center gap-1 whitespace-nowrap'>
                    <span>{config.condensers.length}</span>
                  </div>
                )}
                {feature === 'Indoor Units' && (
                  <div className='flex items-center gap-1 whitespace-nowrap'>
                    <span>{config.condensers.flatMap(cond => cond.zones).length}</span>
                  </div>
                )}
                {feature === 'Total Components' && (
                  <div className='flex items-center gap-1 whitespace-nowrap'>
                    <span>{config.condensers.length + config.condensers.flatMap(cond => cond.zones).length}</span>
                  </div>
                )}
                {feature === 'Total Line Set Length' && config.condensers[0].type !== 'ducted' && (
                  <div className='flex items-center gap-1 whitespace-nowrap'>
                    <span>{config.condensers[0].location?.distances.reduce((total, current) => total + current.distance, 0) ?? 'N/A'} ft</span>
                  </div>
                )}
                {feature === 'Total Line Set Length' && config.condensers[0].type === 'ducted' && (
                  <div className='flex items-center gap-1 whitespace-nowrap'>
                    <span>{(config.condensers[0].location?.distances[0].distance ?? 0) + (config.condensers[0].location?.distanceFromElectricalPanel ?? 0)} ft</span>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
