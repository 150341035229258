/* eslint-disable no-param-reassign */
import React, {
  Dispatch,
  SetStateAction,
  useState,
  useContext,
  useEffect,
  KeyboardEvent
} from 'react';
import { useImmer } from 'use-immer';
import { XMarkIcon, ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { ConfigurationContext, DataContext, UIContext } from 'data/context';
import { errorModal, sortAttributes, filterAttributes } from 'utils/helpers';
import Checkbox from 'components/inputs/checkbox';
import AttributeComponent from 'components/attribute';
import Modal from 'components/modals/modal';
import ProgressIndicator from 'components/progress-indicator';
import NumberInput from 'components/inputs/number';
import Select from 'components/inputs/select';
import { Attribute } from 'data/types';
import InternalNote from 'components/internal-note';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import Fenestration from 'components/fenestration';

type AttributeModalProps = {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
  title: string;
  itemUniqueId: string | null;
  attributes: Attribute[];
  fenestrationItems: number[];
  defaultFenestration?: number[];
  description?: string;
  modified: boolean;
};

type DefaultSizes = {
  [key: string]: number;
};

export default function AttributeModal({
  openState,
  title,
  itemUniqueId,
  attributes,
  fenestrationItems,
  defaultFenestration,
  description,
  modified
}: AttributeModalProps) {
  const { setStructure } = useContext(ConfigurationContext);
  const { items } = useContext(DataContext);
  const { activeItem, setActiveItem } = useContext(UIContext);
  const [newAttributes, setNewAttributes] = useImmer<Attribute[]>([]);
  const [addedGlassItems, setAddedGlassItems] = useState<number[]>([]);
  const [defaultSizes, setDefaultSizes] = useState<DefaultSizes>({});
  const [length, setLength] = useState<number | undefined>();
  const [width, setWidth] = useState<number | undefined>();
  const [sqFt, setSqFt] = useState<number | undefined>();
  const [itemName, setItemName] = useState<string>(title);
  const [attributesToApplyToLevel, setAttributesToApplyToLevel] = useState<string[]>([]);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [open, setOpen] = openState;
  const [animate] = useAutoAnimate({ duration: 100 });
  const [focusedAttributeId, setFocusedAttributeId] = useState<number | null>(null);

  useEffect(() => {
    setShowAdvancedOptions(false);
    setAttributesToApplyToLevel([]);
  }, [open]);

  useEffect(() => {
    setItemName(title);
  }, [title]);

  useEffect(() => {
    const item = items.find((i) => i.id === activeItem?.itemId);
    if (item && (item.smallSize || item.mediumSize || item.largeSize)) {
      const newDefaultSizes: DefaultSizes = {};
      if (item.smallSize) {
        newDefaultSizes.Small = item.smallSize;
      }
      if (item.mediumSize) {
        newDefaultSizes.Medium = item.mediumSize;
      }
      if (item.largeSize) {
        newDefaultSizes.Large = item.largeSize;
      }
      setDefaultSizes(newDefaultSizes);
    } else {
      setDefaultSizes({});
    }
  }, [items, activeItem]);

  const calculateFocusedAttributeId = (attributeArray: Attribute[]): number | null => {
    const attributesToCheck = [
      attributeArray.find(a => a.code === 'square_footage'),
      ...sortAttributes(filterAttributes(attributeArray, 'normal', 'Ceiling')),
      attributeArray.find(a => a.code === 'fenestration'),
      ...sortAttributes(filterAttributes(attributeArray, 'normal', false)),
      ...(showAdvancedOptions ? sortAttributes(filterAttributes(attributeArray, 'advanced', false)) : [])
    ].filter(a => a) as Attribute[];
    let newFocusedAttributeId: number | null = null;
    attributesToCheck.filter(a => a.required).forEach((attribute) => {
      if (newFocusedAttributeId === null && !attribute.value) {
        newFocusedAttributeId = attribute.id;
      }
    });
    return newFocusedAttributeId;
  };

  useEffect(() => {
    setNewAttributes(attributes);
    setWidth(undefined);
    setLength(undefined);
    setSqFt(
      (attributes.find((a) => a.code === 'square_footage')?.value as number) ||
      undefined
    );
    if (attributes.some(a => a.required && a.advanced)) {
      setShowAdvancedOptions(true);
    }
    setFocusedAttributeId(calculateFocusedAttributeId(attributes));
  }, [attributes]);

  useEffect(() => {
    if (fenestrationItems.length) {
      setAddedGlassItems(fenestrationItems);
    } else if (!modified) {
      setAddedGlassItems(defaultFenestration || []);
    }
  }, [fenestrationItems, defaultFenestration]);

  const handleSave = () => {
    const item = items.find((i) => i.id === activeItem?.itemId);
    if (item || !activeItem?.itemId) {
      setStructure((draft) => {
        const currentLevel = draft.find(
          (l) => l.sections.some(
            (s) => s.spaces.some(
              (sp) => sp.uniqueId === itemUniqueId
            ) || s.uniqueId === itemUniqueId
          ) || l.uniqueId === itemUniqueId
        );
        draft.forEach((level) => {
          if (level.uniqueId === itemUniqueId) {
            level.attributes = newAttributes;
            level.label = itemName;
            level.modified = true;
          } else if (level.uniqueId === currentLevel?.uniqueId) {
            attributesToApplyToLevel.forEach((code) => {
              const attributeIndex = level.attributes.findIndex(
                (a) => a.code === code
              );
              const attributeValue = newAttributes.find(
                (a) => a.code === code
              )?.value;
              if (attributeIndex !== -1 && attributeValue) {
                level.modified = true;
                level.attributes[attributeIndex].value = attributeValue;
                level.attributes[attributeIndex].modified = true;
              }
            });
            level.sections.forEach((section) => {
              if (section.uniqueId === itemUniqueId) {
                section.label = itemName;
              }
              section.spaces.forEach((space) => {
                if (space.uniqueId === itemUniqueId) {
                  space.recalculateBTUs = true;
                  space.valid = false;
                  space.modified = true;
                  space.label = itemName;
                  space.fenestration = addedGlassItems;
                  space.attributes = newAttributes;
                } else {
                  attributesToApplyToLevel.forEach((code) => {
                    const attributeIndex = space.attributes.findIndex(
                      (a) => a.code === code
                    );
                    const attributeValue = newAttributes.find(
                      (a) => a.code === code
                    )?.value;
                    if (attributeIndex !== -1 && attributeValue) {
                      space.recalculateBTUs = true;
                      space.valid = false;
                      space.modified = true;
                      space.attributes[attributeIndex].value = attributeValue;
                      space.attributes[attributeIndex].modified = true;
                    }
                  });
                }
              });
            });
          }
        });
      });
    } else {
      errorModal('Item not found');
    }
    setActiveItem(null);
  };

  const handleApplyToLevel = (checked: boolean, codes: string[]) => {
    if (checked) {
      setAttributesToApplyToLevel((old) => [...old, ...codes]);
    } else {
      setAttributesToApplyToLevel((old) =>
        old.filter((code) => !codes.includes(code))
      );
    }
  };

  const handleChange = (
    attributeId: number,
    value: string | number | boolean | undefined
  ) => {
    setNewAttributes((draft) => {
      const updatedAttributes = draft;
      const index = updatedAttributes.findIndex((a) => a.id === attributeId);
      updatedAttributes[index].value = value;
      updatedAttributes[index].modified = true;
      return updatedAttributes;
    });
  };

  useEffect(() => {
    if ((length && !width) || (!length && width)) {
      setSqFt(undefined);
    }
    const sqFtAttribute = attributes.find((a) => a.code === 'square_footage');
    if (sqFtAttribute) {
      handleChange(sqFtAttribute.id, length && width ? length * width : undefined);
    }
  }, [length, width]);

  useEffect(() => {
    if (sqFt) {
      setLength(undefined);
      setWidth(undefined);
    }
    const sqFtAttribute = attributes.find((a) => a.code === 'square_footage');
    if (sqFtAttribute) {
      handleChange(sqFtAttribute.id, sqFt || undefined);
    }
  }, [sqFt]);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const newFocusedAttributeId = calculateFocusedAttributeId(newAttributes);
      if (newFocusedAttributeId !== null) {
        setFocusedAttributeId(newFocusedAttributeId);
      } else {
        handleSave();
        setOpen(false);
      }
    }
  };

  const handleAddGlassItem = (itemId: number) => {
    setAddedGlassItems((oldValues) => [...oldValues, itemId]);
  };

  const handleRemoveGlassItem = (itemId: number) => {
    setAddedGlassItems((oldValues) => {
      const itemIndex = oldValues.findIndex(id => id === itemId);
      return itemIndex !== undefined ? oldValues.filter((_, i) => i !== itemIndex) : oldValues;
    });
  };

  return (
    <Modal
      title={title}
      openState={openState}
      onSave={handleSave}
      onClose={() => setActiveItem(null)}
      onRename={(value) => setItemName(value)}
      defaultRename={!attributes.length}
      renameSizeLimit={25}
    >
      <div
        ref={animate}
        className='flex w-full flex-col gap-3'
        onKeyDown={handleKeyDown}
      >
        {description && <InternalNote>{description}</InternalNote>}
        {newAttributes.some((a) => a.code === 'square_footage') && (
          <>
            <div className='flex items-center justify-between'>
              <div className='text-xl font-bold text-gray-900'>Dimensions</div>
            </div>
            <div className='flex items-end gap-4 rounded-lg bg-blue-100 p-4'>
              <div className='flex w-full flex-col gap-2'>
                <div className='flex items-center gap-2'>
                  <ProgressIndicator required checked={!!(sqFt || (length && width))} />
                  <div className='font-semibold text-gray-800'>
                    Square Footage
                  </div>
                </div>
                <div className='inline-flex gap-3'>
                  {Object.keys(defaultSizes).length > 0 && (
                    <Select
                      options={[
                        { label: 'Custom', value: '' },
                        ...Object.entries(defaultSizes).map(([key, value]) => (
                          { label: key, value: value.toString() }
                        ))
                      ]}
                      onChange={(value) => setSqFt(Number(value))}
                      value={sqFt?.toString() || undefined}
                    />
                  )}
                  <NumberInput
                    placeholder='0 sqft'
                    onChange={(value) => setSqFt(value)}
                    value={sqFt || undefined}
                    min={0}
                    autoFocus={focusedAttributeId === newAttributes.find((a) => a.code === 'square_footage')?.id}
                    suffix=' sqft'
                  />
                </div>
              </div>
              <span className='py-2 font-semibold'>OR</span>
              <div className='w-full'>
                <div className='flex items-center gap-2'>
                  <NumberInput
                    placeholder='0 ft'
                    onChange={(value) => setLength(value)}
                    value={length || undefined}
                    min={0}
                    suffix=' ft'
                  />
                  <div className='h-6 w-6'>
                    <XMarkIcon className='h-6 w-6' />
                  </div>
                  <NumberInput
                    placeholder='0 ft'
                    onChange={(value) => setWidth(value)}
                    value={width || undefined}
                    min={0}
                    suffix=' ft'
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {filterAttributes(newAttributes, 'normal', 'Ceiling').length > 0 && (
          <>
            <div className='flex items-center justify-between'>
              <div className='text-xl font-bold text-gray-900'>Ceiling</div>
              <label className='cursor-pointer flex items-center gap-2'>
                <Checkbox
                  onChange={(checked) =>
                    handleApplyToLevel(
                      checked,
                      newAttributes
                        .filter((a) => a.group === 'Ceiling')
                        .map((a) => a.code)
                    )}
                  value={
                    newAttributes
                      .filter((a) => a.group === 'Ceiling')
                      .some((a) => attributesToApplyToLevel.includes(a.code))
                  }
                />
                <span className='text-sm font-semibold'>Apply to level</span>
              </label>
            </div>
            <div className='flex flex-wrap gap-4 rounded-lg bg-blue-100 p-4'>
              {sortAttributes(filterAttributes(newAttributes, 'normal', 'Ceiling')).map((attribute) => (
                <div key={attribute.id} className='flex max-w-[calc(50%-.5rem)] flex-shrink grow basis-1/3 flex-col gap-2' >
                  <AttributeComponent
                    onChange={(value) => handleChange(attribute.id, value)}
                    completed={!!attribute.value}
                    autoFocus={attribute.id === focusedAttributeId}
                    {...{ ...attribute }}
                  />
                </div>
              ))}
            </div>
          </>
        )}
        {newAttributes.find((a) => a.code === 'fenestration') && (
          <div className='flex flex-col gap-3'>
            <div className='flex items-center gap-2'>
              <div className='font-semibold text-gray-800'>
                {newAttributes.find(a => a.code === 'fenestration')?.label}{newAttributes.find(a => a.code === 'fenestration')?.required && (
                  <ProgressIndicator checked={addedGlassItems.length > 0} required />
                )}
              </div>
            </div>
            <Fenestration
              items={addedGlassItems}
              addItem={(id) => handleAddGlassItem(id)}
              removeItem={(id) => handleRemoveGlassItem(id)}
            />
          </div>
        )}
        {filterAttributes(newAttributes, 'normal', false).length > 0 && (
          <div className='flex flex-wrap gap-4'>
            {sortAttributes(filterAttributes(newAttributes, 'normal', false)).map((attribute) => (
              <div key={attribute.id} className='flex max-w-[calc(50%-.5rem)] flex-shrink grow basis-1/3 flex-col gap-2' >
                <AttributeComponent
                  onChange={(value) => handleChange(attribute.id, value)}
                  completed={!!attribute.value}
                  autoFocus={attribute.id === focusedAttributeId}
                  {...{ ...attribute }}
                />
              </div>
            ))}
          </div>
        )}
        {filterAttributes(newAttributes, 'advanced').length > 0 && (
          <div>
            <button
              type='button'
              className='flex items-center text-xl font-bold text-gray-900'
              onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}
            >
              <span>Advanced Options</span>
              {showAdvancedOptions ? (
                <ChevronDownIcon className='h-7 w-7' />
              ) : (
                <ChevronRightIcon className='h-7 w-7' />
              )}
            </button>
          </div>
        )}
        {showAdvancedOptions && (
          <div className='bg-gray-100 flex flex-wrap gap-4 rounded-lg p-4'>
            {filterAttributes(newAttributes, 'advanced', false).length > 0 && (
              <div className='w-full flex flex-wrap gap-4'>
                {sortAttributes(filterAttributes(newAttributes, 'advanced', false)).map((attribute) => (
                  <div key={attribute.id} className='flex max-w-[calc(50%-.5rem)] flex-shrink grow basis-1/3 flex-col gap-2' >
                    <AttributeComponent
                      onChange={(value) => handleChange(attribute.id, value)}
                      completed={!!attribute.value}
                      autoFocus={attribute.id === focusedAttributeId}
                      {...{ ...attribute }}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
}

AttributeModal.defaultProps = {
  description: undefined,
  defaultFenestration: []
};
