import React, { useContext, useEffect } from 'react';
import { CpuChipIcon, RectangleStackIcon } from '@heroicons/react/24/outline';
import { ConfigurationContext } from 'data/context';
import Select from 'components/inputs/select';
import ProgressIndicator from 'components/progress-indicator';
import {
  AccessoryProduct,
  SystemOption,
  SystemOptionCondenser,
  SystemOptionZone
} from 'data/types';
import Tag from 'components/tag';
import ProductPhoto from 'components/product-photo';
import { formatUSD } from 'utils/helpers';

type DuctingKitOptionProps = {
  condenser: SystemOptionCondenser;
  zones: SystemOptionZone[];
  systemOption?: SystemOption;
};

export default function DuctingKitOption({
  condenser,
  zones,
  systemOption
}: DuctingKitOptionProps) {
  const {
    selectedSystems,
    setSelectedSystems,
    activeSelectedSystem
  } = useContext(ConfigurationContext);

  const updateConfigAccessories = () => {
    setSelectedSystems((draft) => {
      // eslint-disable-next-line no-param-reassign
      draft[activeSelectedSystem].selectedAccessories = draft[
        activeSelectedSystem
      ].selectedAccessories.filter(
        (acc) => acc.type !== 'ducting_kit_supply' && acc.type !== 'ducting_kit_return' && acc.type !== 'plenum'
      );
      draft[activeSelectedSystem].configuration.condensers.forEach((cond) => {
        cond.zones.forEach((zone) => {
          if (zone.selectedDuctingKit) {
            const selectedReturnKitIndex = draft[
              activeSelectedSystem
            ].selectedAccessories.findIndex(
              (acc) => acc.productId === zone.selectedDuctingKit?.productId
            );
            if (selectedReturnKitIndex !== -1) {
              // eslint-disable-next-line no-param-reassign
              draft[activeSelectedSystem].selectedAccessories[
                selectedReturnKitIndex
              ].quantity += 1;
            } else {
              draft[activeSelectedSystem].selectedAccessories.push({
                ...zone.selectedDuctingKit,
                quantity: 1
              });
            }
            const supplyKit = zone.selectedProduct?.accessories?.find((acc) => acc.type === 'ducting_kit_supply');
            if (supplyKit) {
              const supplyKitIndex = draft[activeSelectedSystem].selectedAccessories.findIndex((acc) => acc.productId === supplyKit?.productId);
              if (supplyKitIndex !== -1) {
                // eslint-disable-next-line no-param-reassign
                draft[activeSelectedSystem].selectedAccessories[
                  supplyKitIndex
                ].quantity += 1;
              } else {
                draft[activeSelectedSystem].selectedAccessories.push({
                  ...supplyKit,
                  quantity: 1
                });
              }
            } else {
              console.error(`Supply kit not found for ${condenser.name} ${zone.name}`);
            }
            const plenumKit = zone.selectedProduct?.accessories?.find((acc) => acc.type === 'plenum');
            if (plenumKit) {
              const plenumKitIndex = draft[activeSelectedSystem].selectedAccessories.findIndex((acc) => acc.productId === plenumKit?.productId);
              if (plenumKitIndex !== -1) {
                // eslint-disable-next-line no-param-reassign
                draft[activeSelectedSystem].selectedAccessories[
                  plenumKitIndex
                ].quantity += 1;
              } else {
                draft[activeSelectedSystem].selectedAccessories.push({
                  ...plenumKit,
                  quantity: 1
                });
              }
            } else {
              console.error(`Plenum kit not found for ${condenser.name} ${zone.name}`);
            }
          }
        });
      });
      // eslint-disable-next-line no-param-reassign
      draft[activeSelectedSystem].accessoriesCompleted = draft[
        activeSelectedSystem
      ].accessoriesCompleted.filter((category) => category !== 'ductingKit');
      if (
        draft[activeSelectedSystem].configuration.condensers.every((c) =>
          c.zones.every((z) => z.selectedDuctingKit || z.selectedProduct?.type !== 'Multi Room')
        )
      ) {
        draft[activeSelectedSystem].accessoriesCompleted.push('ductingKit');
      }
    });
  };

  const handleChange = (
    zoneUniqueId: string,
    ductingKit?: AccessoryProduct
  ) => {
    setSelectedSystems(draft => {
      const condenserIndex = draft[activeSelectedSystem].configuration.condensers.findIndex(
        c => c.zones.some(z => z.uniqueId === zoneUniqueId)
      );
      if (condenserIndex !== -1) {
        const zoneIndex = draft[activeSelectedSystem].configuration.condensers[condenserIndex].zones.findIndex(
          z => z.uniqueId === zoneUniqueId
        );
        if (zoneIndex !== -1) {
          // eslint-disable-next-line no-param-reassign
          draft[activeSelectedSystem].configuration.condensers[condenserIndex].zones[zoneIndex].selectedDuctingKit = ductingKit;
        }
      }
    });
  };

  useEffect(() => {
    updateConfigAccessories();
  }, [
    ...zones.map(z => z.selectedDuctingKit)
  ]);

  return systemOption ? (
    <div className='flex divide-x divide-gray-200'>
      <div className='flex w-[40%] shrink-0 grow-0 flex-col gap-3 p-5 justify-center'>
        <div className='flex items-center gap-2'>
          <CpuChipIcon className='h-6 w-6' />
          <span className='mr-3 text-lg font-bold'>{condenser.name}</span>
        </div>
        <div className='flex items-center divide-x divide-gray-200'>
          <div className='flex items-center gap-4 pr-8'>
            <ProductPhoto product={condenser.selectedProduct} />
            <div className='flex flex-col gap-1'>
              <span className='text-xs font-semibold uppercase text-gray-600'>
                Series
              </span>
              <span className='font-semibold'>
                {condenser.selectedProduct?.series ?? 'Unknown Series'}
              </span>
            </div>
          </div>
          <div className='flex grow basis-0 flex-col gap-1 pl-8'>
            <span className='text-xs font-semibold uppercase text-gray-600'>
              Product
            </span>
            <a
              className='text-sm font-semibold text-blue-500 hover:text-blue-600'
              href={`https://alpinehomeair.com${condenser.selectedProduct?.productUrl}`}
              rel='noreferrer'
              target='_blank'
            >
              {condenser.selectedProduct?.name}
            </a>
          </div>
        </div>
      </div>
      <div className='flex w-1/5 shrink-0 grow-0 flex-col'>
        {zones.map((zone) => {
          const options = zone.selectedProduct?.accessories.filter((acc) => acc.type === 'ducting_kit_return')
            .sort((a, b) => a.price - b.price)
            .map((acc) => {
              const ductQuantity = acc.additionalInfo?.ductQuantity;
              const ductSize = acc.additionalInfo?.ductSize;
              const itemLabel = ductQuantity && ductSize ? `${ductQuantity} x ${ductSize}"` : acc.name;
              return {
                product: acc,
                label: itemLabel
              };
            }) ?? [];

          return zone.selectedProduct?.type === 'Multi Room' ? (
            <div
              key={zone.uniqueId}
              className='flex min-h-[200px] items-center'
            >
              <div className='w-full border-b-2 border-dashed border-gray-200 pt-2' />
              <div className='flex flex-col gap-2'>
                <div className='flex items-center gap-2'>
                  <div className="shrink-0">
                    <ProgressIndicator
                      required={
                        !selectedSystems[
                          activeSelectedSystem
                        ].accessoriesNotNeeded.includes('ductingKit') &&
                        !selectedSystems[activeSelectedSystem].noAccessoriesNeeded
                      }
                      checked={!!zone.selectedDuctingKit}
                    />
                  </div>
                  <div className='text-lg font-bold text-gray-800'>
                    Ducting Kit (Return)*
                  </div>
                </div>
                <div className='w-[170px]'>
                  <Select
                    placeholder='Select ducting kit'
                    options={options.map((o) => ({ label: `${o.label} (${formatUSD(Math.ceil(o.product.price))})`, value: o.label }))}
                    onChange={(value) =>
                      handleChange(
                        zone.uniqueId,
                        options.find((o) => o.label === value)?.product
                      )
                    }
                    value={
                      options.find(
                        (o) =>
                          o.product.productId ===
                          zone.selectedDuctingKit?.productId
                      )?.label
                    }
                  />
                </div>
                <span>*Will also add supply & plenum kit</span>
              </div>
              <div className='w-full border-b-2 border-dashed border-gray-200 pt-2' />
            </div>
          ) : (
            <div
              key={zone.uniqueId}
              className='min-h-[200px]'
            />
          );
        })}
      </div>
      <div className='flex grow flex-col'>
        {zones.map((zone) => (
          <div
            key={zone.uniqueId}
            className='flex flex-col gap-3 p-5 min-h-[200px] justify-center'
          >
            <div className='flex items-center gap-5'>
              <div className='flex items-center gap-2'>
                <RectangleStackIcon className='h-6 w-6' />
                <span className='text-lg font-bold'>{zone.name}</span>
              </div>
              <div className='flex items-center flex-wrap gap-2'>
                {zone.zoneItems.map((item) => (
                  <Tag key={item.uniqueId} color='default' text={item.name} />
                ))}
              </div>
            </div>
            <div className='flex items-center divide-x divide-gray-200'>
              <div className='flex items-center gap-4 pr-8'>
                <ProductPhoto product={condenser.selectedProduct} />
                <div className='flex flex-col gap-1'>
                  <span className='text-xs font-semibold uppercase text-gray-600'>
                    Type
                  </span>
                  <span className='font-semibold'>
                    {zone.selectedProduct?.type ?? 'N/A'}
                  </span>
                </div>
              </div>
              <div className='flex grow basis-0 flex-col gap-1 pl-8'>
                <span className='text-xs font-semibold uppercase text-gray-600'>
                  Product
                </span>
                <a
                  className='text-sm font-semibold text-blue-500 hover:text-blue-600'
                  href={
                    zone.selectedProduct
                      ? `https://alpinehomeair.com${zone.selectedProduct.productUrl}`
                      : '/'
                  }
                  rel='noreferrer'
                  target='_blank'
                >
                  {zone.selectedProduct?.name ?? 'N/A'}
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : null;
}

DuctingKitOption.defaultProps = {
  systemOption: undefined
};
