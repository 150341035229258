import React from 'react';
import { AirHandlerProduct } from 'data/types';
import { abbreviateNumber } from 'utils/helpers';
import Tag from 'components/tag';
import ProductLink from 'components/product-link';

interface AirHandlerCellProps {
  airHandler: AirHandlerProduct | undefined;
}

export default function AirHandlerCell({ airHandler }: AirHandlerCellProps) {
  return airHandler ? (
    <div className='flex flex-col'>
      <span className='whitespace-nowrap'>
        <ProductLink type='air handler' product={airHandler} hoverInfo />
      </span>
      <div className='flex flex-wrap gap-1'>
        <Tag color='default' text={airHandler.type} />
        <Tag color='orange' text={abbreviateNumber(airHandler.heatingBTU)} />
        <Tag color='blue' text={abbreviateNumber(airHandler.coolingBTU)} />
      </div>
    </div>
  ) : (
    <span>N/A</span>
  );
}
