import React, { useContext, useState, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import clsx from 'clsx';
import { TrashIcon, ScissorsIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { v4 as uuid } from 'uuid';
import { ConfigurationContext, DataContext, UIContext } from 'data/context';
import BTUBubbles from 'components/btu-bubbles';
import { ZoneItem, Item, Space, Configuration, Condenser } from 'data/types';
import Tooltip from 'components/tooltip';
import { findSpaceById } from 'utils/structure';
import { confirmationModal, errorModal } from 'utils/helpers';
import { XMarkIcon } from '@heroicons/react/20/solid';

type ZoneItemProps = {
  zoneItem: ZoneItem;
  configId: string;
  showSectionName: boolean;
};

export default function ZoneItemComponent({
  zoneItem,
  configId,
  showSectionName
}: ZoneItemProps) {
  const {
    items,
    fenestration
  } = useContext(DataContext);
  const {
    configurationOptions,
    setConfigurationOptions,
    structure,
    selectedSystems
  } = useContext(ConfigurationContext);
  const {
    setActiveTab,
    setActiveItem
  } = useContext(UIContext);
  const [dbItem, setDbItem] = useState<Item | null>(null);
  const [structureItem, setStructureItem] = useState<Space | null>(null);
  const [valid, setValid] = useState(true);
  const [warning, setWarning] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [config, setConfig] = useState<Configuration | null>(null);

  useEffect(() => {
    setConfig(configurationOptions.find((conf) => conf.uniqueId === configId) ?? null);
  }, [configurationOptions, configId]);

  useEffect(() => {
    const newStructureItem = findSpaceById(structure, zoneItem.structureItemId);
    setStructureItem(newStructureItem);
    if (newStructureItem) {
      const newItem = items.find((i) => i.id === newStructureItem.itemId);
      setDbItem(newItem || null);
      if (!newItem) {
        console.error(`Item ${newStructureItem.itemId} not found in the database.`);
        errorModal(`Item ${newStructureItem.itemId} not found in the database.`);
      }
    } else {
      console.error(`Item "${zoneItem.name}" not found on structure.`);
      errorModal(`Item "${zoneItem.name}" not found on structure.`);
    }
  }, [zoneItem]);

  const moveZoneItem = (fromZoneItemId: string) => {
    setConfigurationOptions((draft) => {
      const configIndex = draft.findIndex((conf) => conf.uniqueId === configId);
      const fromCondenserIndex = draft[configIndex].condensers.findIndex(
        (cond) => cond.zones.some((z) => z.zoneItems.some((zi) => zi.uniqueId === fromZoneItemId))
      );
      const toCondenserIndex = draft[configIndex].condensers.findIndex(
        (cond) => cond.zones.some((z) => z.zoneItems.some((zi) => zi.uniqueId === zoneItem.uniqueId))
      );
      if (fromCondenserIndex !== -1 && toCondenserIndex !== -1) {
        const fromZoneIndex = draft[configIndex].condensers[fromCondenserIndex].zones.findIndex(
          (z) => z.zoneItems.some((zi) => zi.uniqueId === fromZoneItemId)
        );
        const toZoneIndex = draft[configIndex].condensers[toCondenserIndex].zones.findIndex(
          (z) => z.zoneItems.some((zi) => zi.uniqueId === zoneItem.uniqueId)
        );
        if (fromZoneIndex !== -1 && toZoneIndex !== -1) {
          const fromItemIndex = draft[configIndex].condensers[fromCondenserIndex].zones[fromZoneIndex].zoneItems.findIndex(
            (zi) => zi.uniqueId === fromZoneItemId
          );
          const toItemIndex = draft[configIndex].condensers[toCondenserIndex].zones[toZoneIndex].zoneItems.findIndex(
            (zi) => zi.uniqueId === zoneItem.uniqueId
          );
          if (fromItemIndex !== -1 && toItemIndex !== -1) {
            const [itemToMove] = draft[configIndex].condensers[fromCondenserIndex].zones[fromZoneIndex].zoneItems.splice(fromItemIndex, 1);
            draft[configIndex].condensers[toCondenserIndex].zones[toZoneIndex].zoneItems.splice(toItemIndex, 0, itemToMove);
          }
        }
      }
    });
  };

  const [{ isDragging, canDrag }, drag] = useDrag(() => ({
    type: `config-${configId}-zone-item`,
    item: { id: zoneItem.uniqueId, type: 'zone-item' },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
      canDrag: !!monitor.canDrag()
    })
  }), [config]);

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: [`config-${configId}-zone-item`],
    drop: (item: { id: string; }) => {
      if (selectedSystems.filter(system => system.configuration.uniqueId === configId).length > 0 || config?.condensers.some(cond => cond.selectedProduct || cond.zones.some(z => z.selectedProduct))) {
        confirmationModal('Are you sure you want to update this configuration? This will reset all selected products and systems for this configuration!', () => {
          moveZoneItem(item.id);
        });
      } else {
        moveZoneItem(item.id);
      }
    },
    collect: (monitor) => ({
      canDrop: !!monitor.canDrop() && monitor.getItem().id !== zoneItem.uniqueId,
      isOver: !!monitor.isOver({ shallow: true }) && monitor.getItem().id !== zoneItem.uniqueId,
    })
  }), [selectedSystems, configurationOptions]);

  const splitSelf = () => {
    setConfigurationOptions((draft) => {
      const configIndex = draft.findIndex((conf) => conf.uniqueId === configId);
      const condenserIndex = draft[configIndex].condensers.findIndex((cond) =>
        cond.zones.some((zone) =>
          zone.zoneItems.some((i) => i.uniqueId === zoneItem.uniqueId)
        )
      );
      const zoneIndex = draft[configIndex].condensers[
        condenserIndex
      ].zones.findIndex((zone) =>
        zone.zoneItems.some((i) => i.uniqueId === zoneItem.uniqueId)
      );
      const itemIndex = draft[configIndex].condensers[condenserIndex].zones[
        zoneIndex
      ].zoneItems.findIndex((i) => i.uniqueId === zoneItem.uniqueId);
      const newPartition: ZoneItem = {
        ...zoneItem,
        uniqueId: uuid()
      };
      draft[configIndex].condensers[condenserIndex].zones[
        zoneIndex
      ].zoneItems.splice(itemIndex, 0, newPartition);
    });
  };

  const deleteSelf = () => {
    setConfigurationOptions((draft) => {
      const configIndex = draft.findIndex((conf) => conf.uniqueId === configId);
      const condenserIndex = draft[configIndex].condensers.findIndex((cond) =>
        cond.zones.some((zone) =>
          zone.zoneItems.some((i) => i.uniqueId === zoneItem.uniqueId)
        )
      );
      const zoneIndex = draft[configIndex].condensers[
        condenserIndex
      ].zones.findIndex((zone) =>
        zone.zoneItems.some((i) => i.uniqueId === zoneItem.uniqueId)
      );
      const itemIndex = draft[configIndex].condensers[condenserIndex].zones[
        zoneIndex
      ].zoneItems.findIndex((i) => i.uniqueId === zoneItem.uniqueId);
      draft[configIndex].condensers[condenserIndex].zones[
        zoneIndex
      ].zoneItems.splice(itemIndex, 1);
    });
  };

  const handleDeleteClick = () => {
    if (selectedSystems.length > 0) {
      confirmationModal('Are you sure you want to update this configuration? This will reset all selected products and systems!', deleteSelf);
    } else {
      deleteSelf();
    }
  };

  // TODO: reimplement this
  const handleEditClick = () => {
    if (dbItem && structureItem) {
      setActiveItem({
        itemId: structureItem.itemId,
        uniqueId: structureItem.uniqueId,
        label: structureItem.label,
        attributes: structureItem.attributes,
        fenestrationItems: structureItem.fenestration,
        defaultFenestration: dbItem.defaultFenestration.map(df => fenestration.find(f => f.code === df)?.id).filter(id => id) as number[],
        modified: structureItem.modified,
      });
      setActiveTab('layout');
    } else {
      console.error("Couldn't find item on structure.");
      errorModal("Couldn't find item on structure.");
    }
  };

  useEffect(() => {
    let isValid = true;
    let isWarning = false;
    let newErrorMessage: string | null = null;
    const zone = configurationOptions.find(c => c.uniqueId === configId)?.condensers.find(c => c.zones.some(z => z.zoneItems.some(i => i.uniqueId === zoneItem.uniqueId)))?.zones.find(z => z.zoneItems.some(i => i.uniqueId === zoneItem.uniqueId));
    if (zone) {
      if (dbItem && structureItem) {
        const uniqueStructureItemIds = Array.from(new Set(zone.zoneItems.map(i => i.structureItemId)));
        if (!dbItem?.canBeAlone && uniqueStructureItemIds.length === 1) {
          isWarning = true;
          newErrorMessage = 'This space should not be by itself in a zone';
        }
        if (zone.type !== 'ducted' && structureItem.attributes.find(a => a.code === 'share_air')?.value !== 'Open Floor Plan' && structureItem.attributes.find(a => a.code === 'share_air')?.value !== 'Sharing Air With Door Open' && structureItem.attributes.find(a => a.code === 'space_above')?.value !== 'Yes' && structureItem.attributes.find(a => a.code === 'space_below')?.value !== 'Yes' && uniqueStructureItemIds.length > 1) {
          isValid = false;
          newErrorMessage = 'This space cannot share air with other spaces';
        }
        if (zone.type !== 'ducted' && structureItem.attributes.find(a => a.code === 'zone_control')?.value && uniqueStructureItemIds.length > 1) {
          isValid = false;
          newErrorMessage = 'This space must be by itself (zone control required)';
        }
        if (zone.zoneItems.filter(i => i.structureItemId === zoneItem.structureItemId).length > 1) {
          isValid = false;
          newErrorMessage = 'Cannot be in the same zone';
        }
      }
    } else {
      console.error(`ZoneItem "${zoneItem.name}" not found on configurations.`);
      errorModal(`ZoneItem "${zoneItem.name}" not found on configurations.`);
    }
    setValid(isValid);
    setWarning(isWarning);
    setErrorMessage(newErrorMessage);
  }, [configurationOptions, dbItem, structureItem]);

  const classes = clsx(
    'w-full rounded border-2 flex h-10 pl-3',
    !valid && !canDrop && !isOver && 'bg-red-100 border-red-300',
    warning && valid && !canDrop && !isOver && 'bg-amber-100 border-amber-300',
    !warning && valid && !canDrop && !isOver && 'bg-gray-100',
    canDrop && 'bg-gray-100 border-dashed border-green-300',
    isOver && 'bg-green-50',
    isDragging ? 'cursor-grabbing' : canDrag && 'cursor-grab'
  );

  return (
    <div ref={drop} className='inline-block'>
      <div ref={drag}>
        <span className='inline-flex items-center gap-1 rounded-md bg-gray-200 px-3 py-1 text-sm font-medium text-gray-700 whitespace-nowrap'>
          <button
            type='button'
            className='-ml-1 relative h-5 w-5 rounded-sm hover:bg-gray-500/20 inline-flex items-center justify-center'
            onClick={handleDeleteClick}
          >
            <XMarkIcon className='w-4 h-4' />
          </button>
          {zoneItem.name}
        </span>
      </div>
    </div>
  );
}
