import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useContext,
  useState
} from 'react';
import axios from 'utils/api';
import Modal from 'components/modals/modal';
import Text from 'components/inputs/text';
import Checkbox from 'components/inputs/checkbox';
import ToggleSwitch from 'components/inputs/toggle-switch';
import Button from 'components/inputs/button';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { v4 as uuid } from 'uuid';
import TemplateItem from 'components/admin/template-item';
import { AdminContext } from 'data/context';
import { successModal, errorModal } from 'utils/helpers';
import Textarea from 'components/inputs/textarea';

type CreateStructureSubtypeModalProps = {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
  structureTypeId?: number;
};

export default function CreateStructureSubtypeModal({
  openState,
  structureTypeId
}: CreateStructureSubtypeModalProps) {
  const {
    refreshData,
    items,
    structureTypes
  } = useContext(AdminContext);
  const [open, setOpen] = openState;
  const [name, setName] = useState('');
  const [validItemIds, setValidItemIds] = useState<number[]>([]);
  const [template, setTemplate] = useState<string[]>([]);
  const [partialEnabled, setPartialEnabled] = useState(false);
  const [addressRequired, setAddressRequired] = useState(false);
  const [description, setDescription] = useState('');

  useEffect(() => {
    setName('');
    setValidItemIds([]);
    setTemplate([]);
    setPartialEnabled(false);
    setAddressRequired(false);
    setDescription('');
  }, [open]);

  const handleSave = async () => {
    try {
      await axios.post('structure-subtypes', {
        structureTypeId,
        label: name,
        partialEnabled,
        addressRequired,
        template,
        validItemIds,
        description
      });
      successModal('Space created');
    } catch (error) {
      console.error(error);
      errorModal('Failed to create space');
    }
    refreshData();
    setOpen(false);
  };

  const handleValidItemChange = (itemId: number, checked: boolean) => {
    setValidItemIds((prev) => {
      if (checked) {
        return [...prev.filter((id) => id !== itemId), itemId];
      }
      return prev.filter((id) => id !== itemId);
    });
  };

  return (
    <Modal
      title={`New ${structureTypes.find(st => st.id === structureTypeId)?.label ?? 'Structure'} Subtype`}
      openState={openState}
      size='wide'
      infoMode
    >
      <div className='flex flex-col gap-5'>
        <div className="flex flex-col gap-2">
          <div className='font-semibold text-gray-900'>Name</div>
          <div>
            <Text
              value={name}
              onChange={(value) => setName(value)}
              placeholder='Name...'
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className='font-semibold text-gray-900'>Description</div>
          <Textarea
            rows={5}
            value={description}
            onChange={(value) => setDescription(value)}
            placeholder='Description...'
          />
        </div>
        <div className='max-h-[400px] overflow-auto'>
          <table className='min-w-full border-separate border-spacing-0 divide-y divide-gray-300'>
            <thead>
              <tr>
                <th
                  scope='col'
                  className='sticky top-0 z-10 border-b border-gray-300 bg-white pb-3.5 pl-3 pr-0 text-left font-semibold text-gray-900'
                />
                <th
                  scope='col'
                  className='sticky top-0 z-10 border-b border-gray-300 bg-white px-3 pb-3.5 text-left font-semibold text-gray-900'
                >
                  Valid Spaces
                </th>
              </tr>
            </thead>
            <tbody className='divide-y divide-gray-200'>
              {items
                .filter((s) => s.group === 'Space')
                .map((space) => (
                  <tr
                    key={space.id}
                    className='cursor-pointer hover:bg-gray-100'
                    onClick={() => handleValidItemChange(space.id, !validItemIds.includes(space.id))}
                  >
                    <td className='whitespace-nowrap border-b border-gray-200 py-4 pl-3 pr-0 text-gray-900'>
                      <Checkbox
                        htmlId={`checkbox-${space.id}`}
                        value={validItemIds.includes(space.id)}
                        onChange={(checked) => handleValidItemChange(space.id, checked)}
                      />
                    </td>
                    <td className='whitespace-nowrap border-b border-gray-200 px-3 py-4 text-gray-900'>
                      {space.label}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div>
          <label className='inline-flex cursor-pointer items-center gap-2'>
            <span className='font-semibold'>Partial Enabled?</span>
            <ToggleSwitch
              value={partialEnabled}
              onChange={(checked) => setPartialEnabled(checked)}
            />
          </label>
        </div>
        <div>
          <label className='inline-flex cursor-pointer items-center gap-2'>
            <span className='font-semibold'>Address Required?</span>
            <ToggleSwitch
              value={addressRequired}
              onChange={(checked) => setAddressRequired(checked)}
            />
          </label>
        </div>
        <div className="flex flex-col gap-3">
          <div className='font-semibold text-gray-900'>Default Template</div>
          <DndProvider backend={HTML5Backend}>
            <div className='flex flex-col gap-3 border-2 border-dashed border-gray-300 p-3'>
              {template.map((item, index) => (
                <TemplateItem
                  key={uuid()}
                  label={item}
                  index={index}
                  updateArray={setTemplate}
                  deleteSelf={() => {
                    setTemplate((prev) => {
                      const newItems = [...prev];
                      newItems.splice(index, 1);
                      return newItems;
                    });
                  }}
                />
              ))}
            </div>
          </DndProvider>
          <div className='flex flex-wrap gap-3'>
            {['attic', 'basement', 'level', 'crawlspace'].map((item) => (
              <Button
                key={item}
                text={`+ ${item}`}
                color='white'
                onClick={() => setTemplate((prev) => [...prev, item])}
              />
            ))}
          </div>
        </div>
        <div className='flex items-center gap-3'>
          <Button
            text='Create'
            color='green'
            onClick={handleSave}
          />
          <Button
            text='Cancel'
            color='red'
            onClick={() => setOpen(false)}
          />
        </div>
      </div>
    </Modal>
  );
}
