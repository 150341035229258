import React, { useRef, useEffect, useState } from 'react';
import clsx from 'clsx';
import { measureTextWidth } from 'utils/helpers';

export type NumberProps = {
  onChange?: (value: number | undefined) => void;
  debounce?: number;
  value?: any;
  placeholder?: string;
  disabled?: boolean;
  min?: number;
  max?: number;
  autoFocus?: boolean;
  autoSelect?: boolean;
  size?: 'sm' | 'md';
  hideControls?: boolean;
  prefix?: string;
  suffix?: string;
  invalid?: boolean;
};

export default function NumberInput({
  onChange,
  debounce,
  value: initialValue,
  placeholder,
  disabled = false,
  min,
  max,
  autoFocus = false,
  autoSelect = false,
  size = 'md',
  hideControls = false,
  prefix,
  suffix,
  invalid = false
}: NumberProps) {
  const [value, setValue] = useState<string>((initialValue ?? '').toString());
  const [prefixWidth, setPrefixWidth] = useState(0);
  const [valueWidth, setValueWidth] = useState(0);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setValue((initialValue ?? '').toString());
  }, [initialValue]);

  useEffect(() => {
    if (autoFocus && ref.current) {
      ref.current.focus();
    }
  }, [autoFocus]);

  useEffect(() => {
    if (autoSelect && ref.current) {
      ref.current.select();
    }
  }, [autoSelect]);

  const adjustValue = () => {
    if (value !== '' && !Number.isNaN(Number(value))) {
      let newValue = Number(value);
      if (min !== undefined) {
        newValue = Math.max(min, newValue);
      }
      if (max !== undefined) {
        newValue = Math.min(max, newValue);
      }
      setValue(newValue.toString());
      if (onChange) {
        onChange(newValue);
      }
    } else if (onChange) {
      onChange(undefined);
    }
  };

  useEffect(() => {
    if (prefix) {
      setPrefixWidth(measureTextWidth(prefix, 14));
    }
  }, [prefix]);

  useEffect(() => {
    setValueWidth(measureTextWidth(value, 14));
    const timeout = setTimeout(() => {
      if (onChange && value !== (initialValue ?? '').toString()) {
        onChange(value !== '' ? Number(value) : undefined);
      }
    }, debounce);
    return () => clearTimeout(timeout);
  }, [value]);

  const classes = clsx(
    'block w-full relative rounded-md border-gray-300 shadow-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 text-sm',
    disabled && 'opacity-[70%] cursor-not-allowed',
    size === 'sm' && 'px-2 py-1',
    size === 'md' && 'px-3 py-2',
    hideControls && '[appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none',
    invalid && 'ring-2 ring-red-500'
  );

  return (
    <div className='w-full relative'>
      <input
        ref={ref}
        type='number'
        className={classes}
        placeholder={placeholder}
        onChange={(e) => setValue(e.target.value)}
        onBlur={adjustValue}
        value={value}
        disabled={disabled}
        min={min}
        max={max}
        style={prefix && value !== '' ? { paddingLeft: (size === 'sm' ? 8 : 12) + prefixWidth } : undefined}
      />
      <div className='absolute inset-0 overflow-hidden pointer-events-none'>
        {value !== '' && prefix && (
          <span
            className='absolute inset-y-0 flex items-center text-sm pointer-events-none whitespace-pre'
            style={{ left: size === 'sm' ? 8 : 12 }}
          >
            {prefix}
          </span>
        )}
        {value !== '' && suffix && (
          <span
            className='absolute inset-y-0 flex items-center text-sm pointer-events-none whitespace-pre'
            style={{ left: (size === 'sm' ? 8 : 12) + valueWidth + prefixWidth }}
          >
            {suffix}
          </span>
        )}
      </div>
    </div>
  );
}

NumberInput.defaultProps = {
  onChange: undefined,
  debounce: undefined,
  value: undefined,
  placeholder: undefined,
  disabled: false,
  min: undefined,
  max: undefined,
  autoFocus: false,
  autoSelect: false,
  size: 'md',
  hideControls: false,
  prefix: undefined,
  suffix: undefined,
  invalid: false
};
