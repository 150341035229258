import React from 'react';
import clsx from 'clsx';
import { CheckIcon } from '@heroicons/react/20/solid';

type ProgressRingProps = {
  progress?: number;
  required?: boolean;
};

export default function ProgressRing({
  progress = 0,
  required = false
}: ProgressRingProps) {
  const classes = clsx(
    'h-6 w-6 rounded-full border-[3px] flex items-center justify-center',
    progress >= 100 ? 'border-green-600 bg-green-600' : 'border-gray-400',
    required && progress < 100 && 'border-red-500 bg-red-100'
  );

  const width = 24;
  const strokeWidth = 3;
  const radius = width / 2 - strokeWidth / 2;
  const circumference = radius * 2 * Math.PI;
  const offset = circumference - (progress / 100) * circumference;

  return (
    <div className='relative inline-block'>
      <div className={classes}>
        {progress >= 100 && <CheckIcon className='h-4 w-4 text-white' />}
      </div>
      {progress < 100 && (
        <div className='absolute inset-0 flex items-center justify-center'>
          <svg height={width} width={width}>
            <circle
              style={{
                strokeDasharray: `${circumference} ${circumference}`,
                strokeDashoffset: `${offset}`,
                transformOrigin: '50% 50%'
              }}
              className='-rotate-90'
              strokeWidth={strokeWidth}
              stroke='#16a34a'
              fill='transparent'
              r={radius}
              cx={width / 2}
              cy={width / 2}
            />
          </svg>
        </div>
      )}
    </div>
  );
}

ProgressRing.defaultProps = {
  progress: 0,
  required: false,
};
