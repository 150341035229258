/* eslint-disable no-nested-ternary */
import React, { Dispatch, SetStateAction } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { TrashIcon } from '@heroicons/react/24/outline';

type TemplateItemProps = {
  label: string;
  index: number;
  updateArray: Dispatch<SetStateAction<string[]>>;
  deleteSelf: () => void;
};

export default function TemplateItem({
  label,
  index,
  updateArray,
  deleteSelf
}: TemplateItemProps) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'template-item',
    item: { label, index },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  }));

  const moveItem = (fromIndex: number, toIndex: number) => {
    updateArray((prev) => {
      const newItems = [...prev];
      const item = newItems[fromIndex];
      newItems.splice(fromIndex, 1);
      newItems.splice(toIndex, 0, item);
      return newItems;
    });
  };

  const [{ isOver }, drop] = useDrop(() => ({
    accept: ['template-item'],
    drop: (item: { label: string; index: number }) => {
      moveItem(item.index, index);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver({ shallow: true })
    })
  }));

  return (
    <div ref={drop}>
      <div
        ref={drag}
        className={`flex items-center justify-between whitespace-nowrap rounded-md border ${
          isOver ? 'bg-blue-100' : 'bg-white'
        } px-3 py-1 ${isDragging ? 'cursor-grabbing' : 'cursor-grab'}`}
      >
        <span>{label}</span>
        <TrashIcon
          className='h-5 w-5 shrink-0 cursor-pointer text-gray-500 hover:text-gray-700'
          onClick={deleteSelf}
        />
      </div>
    </div>
  );
}
