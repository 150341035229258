import React, { useRef, useEffect, useState, TextareaHTMLAttributes } from 'react';
import clsx from 'clsx';

export type TextareaProps = {
  value?: any;
  onChange?: (value: string) => void;
  onInput?: (value: string) => void;
  debounce?: number;
  autoFocus?: boolean;
  autoSelect?: boolean;
  resize?: boolean;
  controlled?: boolean;
} & Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'value' | 'onChange' | 'onInput' | 'autoFocus'>;

export default function Textarea({
  value: initialValue,
  onChange,
  onInput,
  debounce,
  autoFocus = false,
  autoSelect = false,
  resize = false,
  controlled = false,
  ...props
}: TextareaProps) {
  const [value, setValue] = useState<string>(initialValue ?? '');
  const ref = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (autoFocus && ref.current) {
      ref.current.focus();
      ref.current.selectionStart = ref.current.value.length;
    }
  }, [autoFocus]);

  useEffect(() => {
    if (autoSelect && ref.current) {
      ref.current.select();
    }
  }, [autoSelect]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (onChange && value !== (initialValue ?? '')) {
        onChange(value ?? '');
      }
    }, debounce);
    return () => clearTimeout(timeout);
  }, [value]);

  const classes = clsx(
    'block min-h-[38px] w-full rounded-md border-gray-300 shadow-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 text-sm',
    props.disabled && 'opacity-[70%] cursor-not-allowed',
    !resize && 'resize-none'
  );

  return (
    <textarea
      {...props}
      ref={ref}
      className={classes}
      value={controlled ? initialValue : value}
      onChange={(e) => setValue(e.target.value)}
      onInput={(e) => onInput && onInput(e.currentTarget.value)}
    />
  );
}

Textarea.defaultProps = {
  value: undefined,
  onChange: undefined,
  onInput: undefined,
  debounce: undefined,
  autoFocus: false,
  autoSelect: false,
  resize: false,
  controlled: false
};
