import React, { useContext } from 'react';
import clsx from 'clsx';
import { ConfigurationContext } from 'data/context';
import BTUBubbles from 'components/btu-bubbles';

export default function ConfigSelector() {
  const { configurations, activeConfig, setActiveConfig } = useContext(ConfigurationContext);

  const classes = (selected: boolean) =>
    clsx(
      'w-full cursor-pointer border-2 rounded-md text-left overflow-hidden group',
      selected ? 'border-blue-200' : 'border-gray-200'
    );

  const headerClasses = (selected: boolean) =>
    clsx(
      'w-full border-b flex items-center justify-between px-3 py-2 cursor-pointer',
      selected ? 'bg-blue-200 border-blue-200' : 'bg-gray-100 border-gray-200'
    );

  const containerClasses = (selected: boolean) =>
    clsx(
      'w-full flex flex-col gap-2 p-4',
      selected ? 'bg-blue-100' : 'bg-white group-hover:bg-gray-50'
    );

  return (
    <div className='flex flex-col gap-5'>
      {configurations.map((config, configIndex) => (
        <button
          type='button'
          key={config.name}
          className={classes(configIndex === activeConfig)}
          onClick={() => setActiveConfig(configIndex)}
        >
          <div className={headerClasses(configIndex === activeConfig)}>
            <div className='flex items-center gap-3'>
              <span className='text-lg font-bold text-gray-900'>
                {config.name}
              </span>
            </div>
          </div>
          <div className={containerClasses(configIndex === activeConfig)}>
            {config.condensers.map((cond) => (
              <div key={cond.uniqueId} className='flex flex-col gap-2'>
                <div className='flex items-center justify-between'>
                  <span className='text-lg font-bold text-gray-900'>
                    {cond.name}
                  </span>
                  <BTUBubbles
                    BTUs={cond.zones.flatMap(zone => zone.zoneItems.map(item => item.BTUs))}
                  />
                </div>
                {cond.zones.map((zone) => (
                  <div
                    key={zone.uniqueId}
                    className='flex w-full items-center justify-between rounded-md border border-gray-300 bg-gray-100 px-3 py-2'
                  >
                    <span className='text-sm'>
                      {zone.name}
                    </span>
                    <div className='flex gap-3'>
                      <div className='mr-1 flex items-center gap-2 text-sm text-gray-600'>
                        <span>BTUs:</span>
                        <BTUBubbles BTUs={{
                          heating: zone.zoneItems
                            .reduce((total, item) => total + item.BTUs.heating, 0),
                          heatingAmount: [...zone.zoneItems].sort((a, b) => {
                            const heatingAmountValues = {
                              none: 0,
                              partial: 1,
                              full: 2,
                            };
                            return heatingAmountValues[b.BTUs.heatingAmount] - heatingAmountValues[a.BTUs.heatingAmount];
                          })[0].BTUs.heatingAmount,
                          cooling: zone.zoneItems
                            .reduce((total, item) => total + item.BTUs.cooling, 0),
                          coolingAmount: [...zone.zoneItems].sort((a, b) => {
                            const coolingAmountValues = {
                              none: 0,
                              partial: 1,
                              full: 2,
                            };
                            return coolingAmountValues[b.BTUs.coolingAmount] - coolingAmountValues[a.BTUs.coolingAmount];
                          })[0].BTUs.coolingAmount,
                        }} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )
            )}
          </div>
        </button>
      ))}
    </div>
  );
}
