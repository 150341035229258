import { useContext, useEffect } from 'react';
import { ConfigurationContext, ProjectContext } from 'data/context';
import { createDebugSnapshot, successModal, errorModal } from 'utils/helpers';

export default function DebugSnapshotWatcher() {
  const {
    projectDetails,
    attomData,
    projectSettings,
    customerValues,
    structureTypeSelected,
    structureSubtypeSelected,
    partialStructure,
    locationData,
    saveDebugSnapshot,
    setSaveDebugSnapshot
  } = useContext(ProjectContext);
  const {
    configurations,
    structure,
    selectedSystems,
  } = useContext(ConfigurationContext);

  const createSnapshot = async () => {
    const snapshotId = await createDebugSnapshot({
      projectId: projectDetails?.projectId ?? null,
      structureTypeId: structureTypeSelected ?? null,
      structureSubtypeId: structureSubtypeSelected ?? null,
      partialStructure: partialStructure ?? null,
      propertyData: attomData ?? null,
      projectSettings: projectSettings ?? null,
      customerValues: customerValues ?? null,
      structure: structure ?? null,
      configurations: configurations.map((config) => ({
        ...config,
        condensers: config.condensers.map((cond) => ({
          ...cond,
          validSystems: [],
          rankedSystems: []
        })),
      })) ?? null,
      selectedSystems: selectedSystems ?? null,
      locationData: locationData ?? null,
    });
    if (snapshotId) {
      successModal(`Debug snapshot created with URL: ${window.location.href.split('?')[0]}?debugId=${snapshotId}`);
    } else {
      errorModal('Failed to create debug snapshot');
    }
  };

  useEffect(() => {
    if (saveDebugSnapshot) {
      createSnapshot();
      setSaveDebugSnapshot(false);
    }
  }, [saveDebugSnapshot]);

  return null;
}
