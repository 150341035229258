import React, { useState, useRef } from 'react';
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  FloatingPortal,
  FloatingArrow,
  arrow
} from '@floating-ui/react';
import { ComputerDesktopIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { Condenser } from 'data/types';

type ValidSystemsTooltipProps = {
  condenser: Condenser;
  constrainWidth?: boolean;
};

export default function ValidSystemsTooltip({
  condenser,
  constrainWidth = true
}: ValidSystemsTooltipProps) {
  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef(null);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'top',
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        fallbackAxisSideDirection: 'start'
      }),
      shift(),
      arrow({
        element: arrowRef
      }),
    ]
  });

  const hover = useHover(context, { move: false });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role
  ]);

  const classes = clsx(
    'w-max bg-[#444] text-white text-sm px-2 py-1 rounded z-50',
    constrainWidth && 'max-w-[300px]'
  );

  const iconClasses = clsx(
    'inline-flex items-center gap-1 cursor-help',
    condenser.valid && condenser.validSystemsFetched && (condenser.validSystems.length > 0 || condenser.validDuctedSystems.length > 0) && 'text-green-500 hover:text-green-700',
    (condenser.valid && condenser.validSystemsFetched && condenser.validSystems.length === 0 && condenser.validDuctedSystems.length === 0) || !condenser.valid && 'text-red-500 hover:text-red-700',
    !condenser.validSystemsFetched && 'text-gray-500 hover:text-gray-700'
  );

  return (
    <>
      <div className={iconClasses} ref={refs.setReference} {...getReferenceProps()}>
        <ComputerDesktopIcon className='w-5 h-5' />
        {condenser.valid && !condenser.validSystemsFetched && (
          <svg className='w-4 h-4 animate-spin' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path fill='gray' className="opacity-40" d="M256 64C150 64 64 150 64 256s86 192 192 192c70.1 0 131.3-37.5 164.9-93.6l.1 .1c-6.9 14.9-1.5 32.8 13 41.2c15.3 8.9 34.9 3.6 43.7-11.7c.2-.3 .4-.6 .5-.9l0 0C434.1 460.1 351.1 512 256 512C114.6 512 0 397.4 0 256S114.6 0 256 0c-17.7 0-32 14.3-32 32s14.3 32 32 32z" />
            <path fill='gray' d="M224 32c0-17.7 14.3-32 32-32C397.4 0 512 114.6 512 256c0 46.6-12.5 90.4-34.3 128c-8.8 15.3-28.4 20.5-43.7 11.7s-20.5-28.4-11.7-43.7c16.3-28.2 25.7-61 25.7-96c0-106-86-192-192-192c-17.7 0-32-14.3-32-32z" />
          </svg>
        )}
        {condenser.valid && condenser.validSystemsFetched && (
          <span>{condenser.validSystems.length || condenser.validDuctedSystems.length}</span>
        )}
      </div>
      <FloatingPortal>
        {isOpen && (
          <div
            className={classes}
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <FloatingArrow fill='#444' ref={arrowRef} context={context} />
            <span>{condenser.validSystemsFetched ? `${condenser.validSystems.length || condenser.validDuctedSystems.length} valid systems` : 'Fetching valid systems...'}</span>
          </div>
        )}
      </FloatingPortal>
    </>
  );
}

ValidSystemsTooltip.defaultProps = {
  constrainWidth: true
};