import React from 'react';
import { AirHandlerProduct } from 'data/types';

export default function AirHandlerDiagram({ product }: { product: AirHandlerProduct }) {
  return (
    <div className='relative h-[240px] flex flex-col rounded-lg bg-gray-300'>
      <div className='flex h-1/3'>
        <div className='flex w-1/3' />
        <div className='flex w-1/3 items-center justify-center'>
          <span className='text-center'>
            {product?.dimensions?.clearanceTop
              ? `${Math.ceil(product?.dimensions?.clearanceTop)}"`
              : ' N/A'}
          </span>
        </div>
        <div className='flex w-1/3' />
      </div>
      <div className='flex h-1/3'>
        <div className='flex w-1/4 items-center justify-center'>
          <span className='text-center'>
            {product?.dimensions?.clearanceSides
              ? `${Math.ceil(
                product?.dimensions?.clearanceSides
              )}"`
              : ' N/A'}
            {product?.dimensions?.clearanceReturnSide &&
              ` (*${Math.ceil(
                product?.dimensions?.clearanceReturnSide
              )}")`}
          </span>
        </div>
        <div className='relative flex w-1/2 items-center justify-center rounded border-r-[30px] border-t-[30px] border-r-gray-400 border-t-gray-200 bg-gray-100 p-3'>
          <span className='whitespace-nowrap text-center'>
            {Math.ceil(product?.dimensions?.height || 0)}
            &quot; H
          </span>
          <div className='absolute -top-[15px] left-[calc(50%+15px)] -translate-x-1/2 -translate-y-1/2 whitespace-nowrap'>
            {Math.ceil(product?.dimensions?.width || 0)}
            &quot; W
          </div>
          <div className='absolute left-[calc(100%+15px)] top-0 -translate-x-1/2 whitespace-nowrap'>
            {Math.ceil(product?.dimensions?.depth || 0)}
            &quot; L
          </div>
          <div className='absolute -top-[30px] left-0 border-[15px] border-b-transparent border-l-gray-300 border-r-transparent border-t-gray-300' />
          <div className='absolute -right-[30px] bottom-0 border-[15px] border-b-gray-300 border-l-transparent border-r-gray-300 border-t-transparent' />
        </div>
        <div className='flex w-1/4 items-center justify-center'>
          <span className='text-center'>
            {product?.dimensions?.clearanceSides
              ? `${Math.ceil(
                product?.dimensions?.clearanceSides
              )}"`
              : ' N/A'}
            {product?.dimensions?.clearanceReturnSide &&
              ` (*${Math.ceil(
                product?.dimensions?.clearanceReturnSide
              )}")`}
          </span>
        </div>
      </div>
      <div className='flex h-1/3'>
        <div className='flex w-1/3' />
        <div className='flex w-1/3 items-center justify-center'>
          <span className='text-center'>
            {product?.dimensions?.clearanceBottom
              ? `${Math.ceil(
                product?.dimensions?.clearanceBottom
              )}"`
              : ' N/A'}
          </span>
        </div>
        <div className='flex w-1/3' />
      </div>
    </div>
  );
}
