import React, { useRef, useEffect, useState, InputHTMLAttributes } from 'react';
import clsx from 'clsx';
import { measureTextWidth } from 'utils/helpers';

export type TextProps = {
  value?: string;
  onChange?: (value: string) => void;
  onInput?: (value: string) => void;
  debounce?: number;
  autoFocus?: boolean;
  autoSelect?: boolean;
  size?: 'sm' | 'md';
  controlled?: boolean;
  prefix?: string;
  suffix?: string;
  invalid?: boolean;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange' | 'onInput' | 'autoFocus' | 'size'>;

export default function Text({
  value: initialValue,
  onChange,
  onInput,
  debounce,
  autoFocus = false,
  autoSelect = false,
  size = 'md',
  controlled = false,
  prefix,
  suffix,
  invalid = false,
  ...props
}: TextProps) {
  const [value, setValue] = useState(initialValue ?? '');
  const [prefixWidth, setPrefixWidth] = useState(0);
  const [valueWidth, setValueWidth] = useState(0);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setValue(initialValue ?? '');
  }, [initialValue]);

  useEffect(() => {
    if (autoFocus && ref.current) {
      ref.current.focus();
    }
  }, [autoFocus]);

  useEffect(() => {
    if (autoSelect && ref.current) {
      ref.current.select();
    }
  }, [autoSelect]);

  useEffect(() => {
    if (prefix) {
      setPrefixWidth(measureTextWidth(prefix, 14));
    }
  }, [prefix]);

  useEffect(() => {
    setValueWidth(measureTextWidth(value, 14));
    const timeout = setTimeout(() => {
      if (onChange && value !== (initialValue ?? '')) {
        onChange(value);
      }
    }, debounce);
    return () => clearTimeout(timeout);
  }, [value]);

  const classes = clsx(
    'block w-full rounded-md border-gray-300 shadow-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 text-sm',
    props.disabled && 'opacity-[70%] cursor-not-allowed',
    size === 'sm' && 'px-2 py-1',
    size === 'md' && 'px-3 py-2',
    invalid && 'ring-2 ring-red-500',
  );

  return (
    <div className='w-full relative'>
      <input
        {...props}
        ref={ref}
        type='text'
        className={classes}
        value={controlled ? initialValue : value}
        onChange={(e) => setValue(e.target.value)}
        onInput={(e) => onInput && onInput(e.currentTarget.value)}
        style={prefix && value !== '' ? { paddingLeft: (size === 'sm' ? 8 : 12) + prefixWidth } : undefined}
      />
      <div className='absolute inset-0 overflow-hidden pointer-events-none'>
        {value !== '' && prefix && (
          <span
            className='absolute inset-y-0 flex items-center text-sm pointer-events-none whitespace-pre'
            style={{ left: size === 'sm' ? 8 : 12 }}
          >
            {prefix}
          </span>
        )}
        {value !== '' && suffix && (
          <span
            className='absolute inset-y-0 flex items-center text-sm pointer-events-none whitespace-pre'
            style={{ left: (size === 'sm' ? 8 : 12) + valueWidth + prefixWidth }}
          >
            {suffix}
          </span>
        )}
      </div>
    </div>
  );
}

Text.defaultProps = {
  value: undefined,
  onChange: undefined,
  onInput: undefined,
  debounce: undefined,
  autoFocus: false,
  autoSelect: false,
  size: 'md',
  controlled: false,
  prefix: undefined,
  suffix: undefined,
  invalid: false
};
