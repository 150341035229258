import { AccessoryCategory, AccessoryType, ActiveTab, AirHandlerTypeCode, AirHandlerTypeLabel, SystemFilters } from 'data/types';

export const accessoryCategories: {
  [type in AccessoryCategory]?: AccessoryType[];
} = {
  lineSet: [
    'line_set',
    'wire'
  ],
  ductingKit: [
    'concealed_duct_installation_kit',
    'ducting_kit_supply',
    'ducting_kit_return',
    'plenum'
  ],
  mounting: [
    'pad',
    'wall_bracket',
    'mounting_blocks',
    'slab_stand',
    'mighty_bracket'
  ],
  power: [
    'electrical_disconnect',
    'electrical_whips',
    'surge_protector'
  ],
  install: [
    'adapter',
    'brass_nut',
    'brass_union',
    'brass_union_nut_kit',
    'end_cap',
    'drain_hose',
    'condensate_pump',
    'expander',
    'flaring_tool',
    'grille',
    'sealant'
  ],
  control: [
    'controls',
    'remote',
    'wifi'
  ],
  lineSetCover: ['line_set_cover']
};

export const accessoryGroupLabels: {
  [type in AccessoryCategory]?: {
    singular: string;
    plural: string;
    description: {
      singular: string;
      plural: string;
    };
  };
} = {
  mounting: {
    singular: 'Mounting the Condenser',
    plural: 'Mounting the Condensers',
    description: {
      singular: "Let's discuss how the condenser will be mounted outside.",
      plural: "Let's discuss how the condensers will be mounted outside."
    }
  },
  power: {
    singular: 'Powering the Condenser',
    plural: 'Powering the Condensers',
    description: {
      singular:
        "Let's go over the standard electrical needs for powering and maintaining the condenser.",
      plural:
        "Let's go over the standard electrical needs for powering and maintaining the condensers."
    }
  },
  install: {
    singular: 'Installing the Components',
    plural: 'Installing the Components',
    description: {
      singular:
        'There are a few additional accessories needed for a hassle-free installation.',
      plural:
        'There are a few additional accessories needed for a hassle-free installation.'
    }
  },
  control: {
    singular: 'Controlling the System',
    plural: 'Controlling the System',
    description: {
      singular:
        "When it comes to setting the temperature, are there accessories that you'll need us to include?",
      plural:
        "When it comes to setting the temperature, are there accessories that you'll need us to include?"
    }
  },
  lineSetCover: {
    singular: 'Covering the Line Set',
    plural: 'Covering the Line Sets',
    description: {
      singular:
        'Line Set Covers are good for protecting your Line Set from the elements.',
      plural:
        'Line Set Covers are good for protecting your Line Sets from the elements.'
    }
  },
  advanced: {
    singular: 'Advanced Accessory',
    plural: 'Advanced Accessories',
    description: {
      singular:
        'Advanced accessory is available for specific needs.',
      plural:
        'Advanced accessories are available for specific needs.'
    }
  }
};

export const airHandlerTypeCodes: {
  [type in AirHandlerTypeLabel]: AirHandlerTypeCode;
} = {
  'High Wall': 'wall',
  'Ceiling Cassette Standard': 'cassette',
  'Low Wall': 'heatingConsole',
  'Floor Ceiling Console': 'console',
  'Multi Room': 'concealed',
  'High Wall DIY': 'wallDIY',
  'Ceiling Cassette Slim Fit': 'cassette_16',
  'Ceiling Cassette Drop Ceiling': 'cassette_drop_ceiling',
  Ducted: 'ducted'
};

export const proceedErrorMessages: { [tab in ActiveTab]: string } = {
  'description': 'Please complete all required fields before proceeding.',
  'layout': 'Please complete the structure before proceeding.',
  'configuration': 'Please validate all configurations before proceeding.',
  'air handlers': 'Please select all your air handlers before proceeding.',
  'condensers': 'Please select all your condensers before proceeding.',
  'equipment': 'Please select at least one system before proceeding.',
  'accessories': 'Please select all your accessories before proceeding.',
  'quote': 'Please send a quote before proceeding.'
};

export const sliders = {
  heatingComfort: { label: 'Heating Comfort', minLabel: 'Cheaper', maxLabel: 'More Comfortable' },
  coolingComfort: { label: 'Cooling Comfort', minLabel: 'Cheaper', maxLabel: 'More Comfortable' },
  aesthetics: { label: 'Air Handler Visibility', minLabel: 'Visible', maxLabel: 'Hidden' },
  installComplexity: { label: 'Install Complexity', minLabel: 'Simpler', maxLabel: 'More Complex' },
  efficiency: { label: 'Efficiency', minLabel: 'Cheaper', maxLabel: 'More Efficient' }
};

export const heatingCoolingCodes = {
  'Primary': 'full',
  'Supplemental': 'partial',
  'None': 'none'
};

export const adjustmentFactors = [
  {
    heatCoolRatio: 2.0,
    insulation: {
      poor: {
        heatingFactor: 1.25,
        coolingFactor: 0.8
      },
      good: {
        heatingFactor: 1.0,
        coolingFactor: 0.6
      },
      great: {
        heatingFactor: 0.75,
        coolingFactor: 0.5
      }
    }
  },
  {
    heatCoolRatio: 1.35,
    insulation: {
      poor: {
        heatingFactor: 1.0,
        coolingFactor: 1.0
      },
      good: {
        heatingFactor: 0.8,
        coolingFactor: 0.8
      },
      great: {
        heatingFactor: 0.6,
        coolingFactor: 0.6
      }
    }
  },
  {
    heatCoolRatio: 1.0,
    insulation: {
      poor: {
        heatingFactor: 0.8,
        coolingFactor: 1.25
      },
      good: {
        heatingFactor: 0.6,
        coolingFactor: 1.0
      },
      great: {
        heatingFactor: 0.5,
        coolingFactor: 0.75
      }
    }
  },
  {
    heatCoolRatio: 0,
    insulation: {
      poor: {
        heatingFactor: 0.6,
        coolingFactor: 1.6
      },
      good: {
        heatingFactor: 0.5,
        coolingFactor: 1.3
      },
      great: {
        heatingFactor: 0.4,
        coolingFactor: 1.0
      }
    }
  }
];

export const systemFilterLabels: {
  [type in keyof SystemFilters]: string;
} = {
  inStock: 'Show only in-stock systems',
  belowBudget: `Stay below customer's budget`,
  selectedAirHandlers: 'Show only chosen air handler types',
  isOnSale: 'Show only discounted systems'
};