import React, { useState, useEffect, useRef, InputHTMLAttributes } from 'react';
import clsx from 'clsx';

type SelectOption = {
  label: string;
  value: string;
};

export type SelectProps = {
  value?: string;
  onChange?: (value: string) => void;
  options?: string[] | SelectOption[];
  debounce?: number;
  enableChips?: boolean;
  autoFocus?: boolean;
  size?: 'sm' | 'md';
  invalid?: boolean;
} & Omit<InputHTMLAttributes<HTMLSelectElement>, 'value' | 'onChange' | 'size' | 'id'>;

export default function Select({
  value: initialValue,
  onChange,
  options,
  debounce,
  enableChips = false,
  autoFocus = false,
  size = 'md',
  invalid = false,
  ...props
}: SelectProps) {
  const [value, setValue] = useState(initialValue ?? '');
  const [formattedOptions, setFormattedOptions] = useState<SelectOption[]>([]);
  const ref = useRef<HTMLSelectElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setValue(initialValue ?? '');
  }, [initialValue]);

  useEffect(() => {
    if (options) {
      if (typeof options[0] === 'string') {
        setFormattedOptions((options as string[]).map((option) => ({ label: option, value: option })));
      } else {
        setFormattedOptions(options as SelectOption[]);
      }
    }
  }, [JSON.stringify(options)]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (onChange && value !== (initialValue ?? '')) {
        onChange(value);
      }
    }, debounce);
    return () => clearTimeout(timeout);
  }, [value]);

  useEffect(() => {
    if (autoFocus) {
      ref.current?.focus();
      buttonRef.current?.focus();
    }
  }, [autoFocus, formattedOptions]);

  const selectClasses = clsx(
    'block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 text-sm',
    props.disabled ? 'opacity-[70%] cursor-not-allowed' : 'cursor-pointer',
    (value === '' || value === undefined || value === null) && !formattedOptions.some(o => o.value === '') && 'text-gray-400',
    size === 'sm' && 'py-1 pl-2 pr-8',
    size === 'md' && 'py-2 pl-3 pr-8',
    invalid && 'ring-2 ring-red-500'
  );

  const buttonClasses = (option: SelectOption, optionIndex: number) => clsx(
    'relative inline-flex items-center px-3 py-2 ring-1 ring-inset ring-gray-300 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500',
    optionIndex > 0 && '-ml-px',
    optionIndex === 0 && 'rounded-l-md',
    optionIndex === ((options?.length ?? 0) - 1) && 'rounded-r-md',
    option.value === value ? 'bg-blue-200' : 'bg-white hover:bg-blue-100',
    props.disabled ? 'opacity-[70%] cursor-not-allowed' : 'cursor-pointer'
  );

  return enableChips ? (
    <div>
      <span className={`isolate inline-flex rounded-md shadow-sm ${invalid && 'ring-2 ring-red-500'}`}>
        {formattedOptions.map((option, optionIndex) => (
          <button
            key={option.value}
            ref={optionIndex === 0 ? buttonRef : undefined}
            className={buttonClasses(option, optionIndex)}
            value={option.value}
            onClick={() => setValue(option.value === value ? '' : option.value)}
            disabled={props.disabled}
          >
            {option.label}
          </button>
        ))}
      </span>
    </div>
  ) : (
    <select
      {...props}
      ref={ref}
      className={selectClasses}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    >
      {!formattedOptions.some(o => o.value === '') && <option value='' className='text-black'>{props.placeholder ?? 'Select...'}</option>}
      {formattedOptions.map((option) => (
        <option key={option.label} value={option.value} className='text-black'>
          {option.label}
        </option>
      ))}
    </select>
  );
}

Select.defaultProps = {
  value: undefined,
  onChange: undefined,
  debounce: undefined,
  enableChips: false,
  autoFocus: false,
  size: 'md',
  invalid: false
};
