/* eslint-disable no-param-reassign */
import { useContext, useEffect } from 'react';
import { AccessoryCategory } from 'data/types';
import { ProjectContext, UIContext, ConfigurationContext, DataContext } from 'data/context';

export default function PageProgressUpdater() {
  const { structureTypes } = useContext(DataContext);
  const {
    structure,
    configurations,
    selectedSystems
  } = useContext(ConfigurationContext);
  const {
    locationData,
    structureTypeSelected,
    structureSubtypeSelected,
    attomData,
    addressSearched,
    partialStructure,
    showDuctlessOptions
  } = useContext(ProjectContext);
  const {
    setPageProgress
  } = useContext(UIContext);

  useEffect(() => {
    // description page
    const descriptionRequirementsMet = [
      !!locationData,
      structureTypeSelected !== null,
      structureSubtypeSelected !== null
    ];
    if (
      structureTypes
        .find((t) => t.id === structureTypeSelected)
        ?.subtypes.find((st) => st.id === structureSubtypeSelected)
        ?.addressRequired && !addressSearched
    ) {
      descriptionRequirementsMet.push(!!attomData);
    }
    if (
      structureTypes
        .find((t) => t.id === structureTypeSelected)
        ?.subtypes.find((s) => s.id === structureSubtypeSelected)
        ?.partialEnabled
    ) {
      descriptionRequirementsMet.push(partialStructure !== null);
    }

    // layout (structure) page
    const structureRequirementsMet = showDuctlessOptions === false ? [
      !!structure.length
    ] : [
      !!structure.length,
      !!structure.some((l) => l.sections.length),
      !!structure.some((l) => l.sections.some((s) => s.spaces.length)),
      (structure.length > 0 && structure.every((l) => l.sections.every((s) => s.spaces.every((sp) => sp.valid))))
    ];

    // configuration page
    const configurationRequirementsMet = configurations.flatMap((conf) => conf.condensers.map((cond) => cond.validSystems.length > 0 || cond.validDuctedSystems.length > 0));

    // air handler page
    const airHandlersRequirementsMet = configurations.flatMap((config) =>
      config.condensers.flatMap((c) =>
        c.zones.map((z) => !!z.selectedProduct)
      )
    );

    // condenser page
    const condenserRequirementsMet = configurations.flatMap((config) =>
      config.condensers.map((c) => !!c.selectedProduct)
    );

    // equipment page
    const equipmentRequirementsMet = [selectedSystems.length > 0];

    // accessories page
    const accessoriesRequirementsMet: boolean[] = [];
    selectedSystems.forEach((system) => {
      const categories: AccessoryCategory[] = [
        'mounting',
        'power',
        'install',
        'control'
      ];
      if (!system.configuration.condensers.every(c => c.selectedProduct.diy)) {
        categories.push('lineSet');
      }
      if (system.configuration.condensers.some(c => c.zones.some(z => z.selectedProduct?.type === 'Multi Room'))) {
        categories.push('ductingKit');
      }
      categories.forEach((accessoryCategory: AccessoryCategory) => {
        accessoriesRequirementsMet.push(
          system.noAccessoriesNeeded ||
          system.accessoriesCompleted.includes(accessoryCategory) ||
          system.accessoriesNotNeeded.includes(accessoryCategory)
        );
      });
    });

    // quote page
    const quoteRequirementsMet = [selectedSystems.some((system) => system.quoteStatus === 'sent')];

    // update page progress
    setPageProgress((draft) => {
      draft.description = descriptionRequirementsMet.length > 0 ?
        ((descriptionRequirementsMet.filter((r) => r).length /
          descriptionRequirementsMet.length) *
          100) : 0;
      draft.layout = structureRequirementsMet.length > 0 ?
        ((structureRequirementsMet.filter((r) => r).length /
          structureRequirementsMet.length) *
          100) : 0;
      draft.configuration = configurationRequirementsMet.length > 0 ?
        ((configurationRequirementsMet.filter((r) => r).length /
          configurationRequirementsMet.length) *
          100) : 0;
      draft['air handlers'] = airHandlersRequirementsMet.length > 0 ?
        ((airHandlersRequirementsMet.filter((r) => r).length /
          airHandlersRequirementsMet.length) *
          100) : 0;
      draft.condensers = condenserRequirementsMet.length > 0 ?
        ((condenserRequirementsMet.filter((r) => r).length /
          condenserRequirementsMet.length) *
          100) : 0;
      draft.equipment = equipmentRequirementsMet.length > 0 ?
        ((equipmentRequirementsMet.filter((r) => r).length /
          equipmentRequirementsMet.length) *
          100) : 0;
      draft.accessories = accessoriesRequirementsMet.length > 0 ?
        ((accessoriesRequirementsMet.filter((r) => r).length /
          accessoriesRequirementsMet.length) *
          100) : 0;
      draft.quote = quoteRequirementsMet.length > 0 ?
        ((quoteRequirementsMet.filter((r) => r).length /
          quoteRequirementsMet.length) *
          100) : 0;
    });
  }, [
    structureTypes,
    locationData,
    structureTypeSelected,
    structureSubtypeSelected,
    attomData,
    addressSearched,
    structure,
    configurations,
    selectedSystems,
    partialStructure
  ]);

  return null;
}
