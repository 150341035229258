import React, { useContext, useEffect, useState } from 'react';
import { AdminContext } from 'data/context';
import { successModal, errorModal } from 'utils/helpers';
import NumberInput from 'components/inputs/number';
import { RankingModifiers } from 'data/types';
import axios from 'utils/api';
import Button from 'components/inputs/button';

export default function Ranking() {
  const { rankingModifiers, refreshData } = useContext(AdminContext);
  const [values, setValues] = useState(rankingModifiers);

  useEffect(() => {
    setValues(rankingModifiers);
  }, [rankingModifiers]);

  const handleSave = async () => {
    if (values) {
      axios.patch('ranking-modifiers', values).then(success => {
        if (success) {
          refreshData();
          successModal(`Ranking modifiers updated.`);
        } else {
          errorModal('Something went wrong.');
        }
      });
    }
  };

  const handleModifierChange = (modifier: keyof RankingModifiers, value: number) => {
    setValues((oldValue) => {
      if (oldValue) {
        return {
          ...oldValue,
          [modifier]: value
        };
      }
      return null;
    });
  };

  return (
    <div className='mx-auto flex max-w-[1600px] flex-col gap-5'>
      <div className='text-3xl font-bold text-gray-900'>
        System Ranking Modifiers
      </div>
      <div className='flex flex-wrap gap-5'>
        {values &&
          Object.keys(values).map((modifier) => (
            <div key={modifier} className='flex flex-col gap-2'>
              <label htmlFor={modifier}>{modifier}</label>
              <NumberInput
                value={values?.[modifier]}
                onChange={(value) => handleModifierChange(modifier as keyof RankingModifiers, value ?? 0)}
              />
            </div>
          ))
        }
      </div>
      <div>
        <Button color='green' onClick={handleSave} text='Save' />
      </div>
    </div>
  );
}
