import React, { useContext, useEffect, useState, useCallback } from 'react';
import { TrashIcon, PencilSquareIcon, CpuChipIcon } from '@heroicons/react/24/outline';
import { PlusIcon } from '@heroicons/react/20/solid';
import { v4 as uuid } from 'uuid';
import { useDrag, useDrop } from 'react-dnd';
import { ConfigurationContext, DataContext } from 'data/context';
import { errorModal, warningModal, confirmationModal } from 'utils/helpers';
import { Condenser, Configuration, Zone, ZoneItem } from 'data/types';
import BTUBubbles from 'components/btu-bubbles';
import ZoneComponent from 'components/dnd/zone';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import Tooltip from 'components/tooltip';
import { findSpaceById, getRestrictedAirHandlerTypes } from 'utils/structure';
import clsx from 'clsx';
import Text from 'components/inputs/text';
import WarningTooltip from 'components/warning-tooltip';
import ValidSystemsTooltip from 'components/valid-systems-tooltip';

type CondenserProps = {
  condenser: Condenser;
  configId: string;
  simpleMode?: boolean;
};

export default function CondenserComponent({
  condenser,
  configId,
  simpleMode = false
}: CondenserProps) {
  const {
    items,
    BTULimits,
    airHandlerTypes
  } = useContext(DataContext);
  const {
    structure,
    configurationOptions,
    setConfigurationOptions,
    selectedSystems
  } = useContext(ConfigurationContext);
  const [valid, setValid] = useState(true);
  const [warning, setWarning] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [animate] = useAutoAnimate({ duration: 100 });
  const [renaming, setRenaming] = useState(false);
  const [newName, setNewName] = useState(condenser.name);
  const [config, setConfig] = useState<Configuration | null>(null);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const renameSizeLimit = 25;

  useEffect(() => {
    setConfig(configurationOptions.find((conf) => conf.uniqueId === configId) ?? null);
  }, [configurationOptions, configId]);

  useEffect(() => {
    let isValid = true;
    let isWarning = false;
    let newErrorMessage: string | null = null;
    const zoneCoolingBTUs = condenser.zones.map(zone => zone.zoneItems.reduce((acc, item) => acc + item.BTUs.cooling, 0)).sort((a, b) => b - a);
    const zoneHeatingBTUs = condenser.zones.map(zone => zone.zoneItems.reduce((acc, item) => acc + item.BTUs.heating, 0)).sort((a, b) => b - a);
    const zoneDistances = condenser.zones.filter(zone => zone.distanceFromCondenser !== undefined).map(zone => zone.distanceFromCondenser) as number[];
    if (zoneDistances.length > 1 && Math.max(...zoneDistances) > Math.min(...zoneDistances) * 2) {
      isWarning = true;
      newErrorMessage = 'Zone distances should be within 2x of each other';
    }
    const minHeatingBTU = Math.min(...zoneHeatingBTUs);
    const maxHeatingBTU = Math.max(...zoneHeatingBTUs);
    if (maxHeatingBTU > minHeatingBTU * 3) {
      isWarning = true;
      newErrorMessage = 'Zone heating capacities are not balanced';
    }
    if (
      BTULimits.length > 0 &&
      !BTULimits
        .filter(l => l.length === condenser.zones.length)
        .find(l => l.every((limit, i) => zoneCoolingBTUs[i] <= limit && zoneHeatingBTUs[i] <= limit))
    ) {
      isValid = false;
      newErrorMessage = 'Total BTUs too high (Try adding more zones or condensers)';
    }
    condenser.zones.forEach((zone) => {
      const restrictedAirHandlerTypes = getRestrictedAirHandlerTypes(zone, structure, items, condenser.type === 'ducted');
      if (restrictedAirHandlerTypes.length === airHandlerTypes.length) {
        isValid = false;
        newErrorMessage = 'One or more zones have no valid air handler types';
      }
      const partitionItems: string[] = [];
      zone.zoneItems.forEach((zoneItem) => {
        if (zoneItem.isPartition) {
          if (partitionItems.includes(zoneItem.structureItemId)) {
            isValid = false;
            newErrorMessage = 'One or more zones have duplicate partitions';
          } else {
            partitionItems.push(zoneItem.structureItemId);
          }
        };
      });
    });
    if (condenser.zones.length < 1) {
      isValid = false;
      newErrorMessage = 'Condensers must have at least one zone';
    }
    if (condenser.zones.some(zone => zone.zoneItems.length < 1 && !zone.existingSystemId)) {
      isValid = false;
      newErrorMessage = 'One or more zones are empty';
    }
    if (condenser.zones.length > 5) {
      isValid = false;
      newErrorMessage = 'Condensers can only have up to 5 zones';
    }
    if (condenser.validSystemsFetched && condenser.validSystems.length < 1 && condenser.validDuctedSystems.length < 1) {
      isValid = false;
      newErrorMessage = 'No valid systems found for this condenser';
    }
    setValid(isValid);
    setWarning(isWarning);
    setErrorMessage(newErrorMessage);
    setConfigurationOptions((draft) => {
      const configIndex = draft.findIndex((conf) => conf.uniqueId === configId);
      const condenserIndex = draft[configIndex].condensers.findIndex(
        (cond) => cond.uniqueId === condenser.uniqueId
      );
      if (condenserIndex !== -1) {
        // eslint-disable-next-line no-param-reassign
        draft[configIndex].condensers[condenserIndex].valid = isValid;
      }
    });
  }, [condenser.zones, condenser.validSystemsFetched, BTULimits]);

  const [{ isDragging, canDrag }, drag, dragPreview] = useDrag(() => ({
    type: `config-${configId}-condenser`,
    item: { id: condenser.uniqueId, type: 'condenser' },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
      canDrag: !!monitor.canDrag()
    }),
    canDrag: condenser.type !== 'ducted'
  }), [config]);

  const moveZone = (zoneId: string) => {
    setConfigurationOptions((draft) => {
      const configIndex = draft.findIndex((conf) => conf.uniqueId === configId);
      const fromCondenserIndex = draft[configIndex].condensers.findIndex(
        (cond) => cond.zones.some((zone) => zone.uniqueId === zoneId)
      );
      const toCondenserIndex = draft[configIndex].condensers.findIndex(
        (cond) => cond.uniqueId === condenser.uniqueId
      );
      const zoneIndex = draft[configIndex].condensers[
        fromCondenserIndex
      ].zones.findIndex((zone) => zone.uniqueId === zoneId);
      const zone = draft[configIndex].condensers[
        fromCondenserIndex
      ].zones.splice(zoneIndex, 1)[0];
      draft[configIndex].condensers[toCondenserIndex].zones.push(zone);
      // eslint-disable-next-line no-param-reassign
      draft[configIndex].condensers[toCondenserIndex].modified = true;
    });
  };

  const moveZoneItem = (itemId: string) => {
    setConfigurationOptions((draft) => {
      const configIndex = draft.findIndex((conf) => conf.uniqueId === configId);
      const fromCondenserIndex = draft[configIndex].condensers.findIndex(
        (cond) =>
          cond.zones.some((z) =>
            z.zoneItems.some((item) => item.uniqueId === itemId)
          )
      );
      const toCondenserIndex = draft[configIndex].condensers.findIndex((cond) =>
        cond.uniqueId === condenser.uniqueId
      );
      const fromZoneIndex = draft[configIndex].condensers[
        fromCondenserIndex
      ].zones.findIndex((z) =>
        z.zoneItems.some((item) => item.uniqueId === itemId)
      );
      const zoneItemIndex = draft[configIndex].condensers[
        fromCondenserIndex
      ].zones[fromZoneIndex].zoneItems.findIndex(
        (item) => item.uniqueId === itemId
      );
      const zoneItem = draft[configIndex].condensers[fromCondenserIndex].zones[
        fromZoneIndex
      ].zoneItems.splice(zoneItemIndex, 1)[0];
      let zoneNumber = 1;
      const zoneNames = draft[configIndex].condensers.flatMap((cond) => cond.zones.map((zone) => zone.name));
      while (zoneNames.includes(`Zone ${zoneNumber}`)) {
        zoneNumber += 1;
      }
      draft[configIndex].condensers[toCondenserIndex].zones.push({
        uniqueId: uuid(),
        name: `Zone ${zoneNumber}`,
        type: 'ductless',
        zoneItems: [zoneItem],
        modified: false
      });
      // eslint-disable-next-line no-param-reassign
      draft[configIndex].condensers[toCondenserIndex].modified = true;
    });
  };

  const addZone = (type: 'ductless' | 'ducted', unzonedItemId?: string) => {
    if (condenser.zones.length < 5) {
      setConfigurationOptions((draft) => {
        const configIndex = draft.findIndex((conf) => conf.uniqueId === configId);
        if (configIndex !== -1) {
          const condenserIndex = draft[configIndex].condensers.findIndex((conf) => conf.uniqueId === condenser.uniqueId);
          if (condenserIndex !== -1) {
            let zoneNumber = 1;
            const zoneLabel = condenser.type === 'hybrid' ? 'Mini-Split Zone' : 'Zone';
            const zoneNames = draft[configIndex].condensers.flatMap((cond) => cond.zones.map((zone) => zone.name));
            while (zoneNames.includes(`${zoneLabel} ${zoneNumber}`)) {
              zoneNumber += 1;
            }
            const newZone: Zone = {
              uniqueId: uuid(),
              name: `${zoneLabel} ${zoneNumber}`,
              type,
              zoneItems: [],
              modified: false
            };
            if (unzonedItemId) {
              const structureItem = findSpaceById(structure, unzonedItemId);
              if (structureItem) {
                const newZoneItem: ZoneItem = {
                  uniqueId: uuid(),
                  structureItemId: unzonedItemId,
                  name: structureItem.label,
                  BTUs: structureItem.BTUs,
                  originalBTUs: structureItem.BTUs,
                  isPartition: false
                };
                newZone.zoneItems.push(newZoneItem);
              } else {
                console.error('Item not found on structure.');
                errorModal('Item not found on structure.');
              }
            }
            draft[configIndex].condensers[condenserIndex].zones.push(newZone);
            // eslint-disable-next-line no-param-reassign
            draft[configIndex].condensers[condenserIndex].modified = unzonedItemId !== undefined;
          }
        }
      });
    } else {
      warningModal('Condensers can only have up to 5 zones!');
    }
  };

  const handleAddZoneClick = useCallback((type: 'ductless' | 'ducted', unzonedItemId?: string) => {
    if (selectedSystems.filter(system => system.configuration.uniqueId === configId).length > 0 || config?.condensers.some(cond => cond.selectedProduct || cond.zones.some(zone => zone.selectedProduct))) {
      confirmationModal('Are you sure you want to update this configuration? This will reset all selected products and systems for this configuration!', () => {
        addZone(type, unzonedItemId);
      });
    } else {
      addZone(type, unzonedItemId);
    }
  }, [selectedSystems, config]);

  const moveCondenser = (fromCondenserId: string) => {
    setConfigurationOptions((draft) => {
      const configIndex = draft.findIndex((conf) => conf.uniqueId === configId);
      const fromCondenserIndex = draft[configIndex].condensers.findIndex(
        (cond) => cond.uniqueId === fromCondenserId
      );
      const toCondenserIndex = draft[configIndex].condensers.findIndex(
        (cond) => cond.uniqueId === condenser.uniqueId
      );
      if (fromCondenserIndex !== -1 && toCondenserIndex !== -1) {
        const [condenserToMove] = draft[configIndex].condensers.splice(fromCondenserIndex, 1);
        draft[configIndex].condensers.splice(toCondenserIndex, 0, condenserToMove);
      }
    });
  };

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: [
      `config-${configId}-zone`,
      `config-${configId}-zone-item`,
      `config-${configId}-zoneable-space`,
      `config-${configId}-condenser`
    ],
    drop: (
      item: {
        id: string;
        type: 'zone' | 'zone-item' | 'zoneable-space' | 'condenser';
      },
      monitor
    ) => {
      if (item.type === 'zone' && !monitor.didDrop()) {
        if (selectedSystems.filter(system => system.configuration.uniqueId === configId).length > 0 || config?.condensers.some(cond => cond.selectedProduct || cond.zones.some(zone => zone.selectedProduct))) {
          confirmationModal('Are you sure you want to update this configuration? This will reset all selected products and systems for this configuration!', () => {
            moveZone(item.id);
          });
        } else {
          moveZone(item.id);
        }
      }
      if (item.type === 'zoneable-space' && !monitor.didDrop()) {
        addZone('ductless', item.id);
      }
      if (item.type === 'zone-item' && !monitor.didDrop()) {
        if (selectedSystems.filter(system => system.configuration.uniqueId === configId).length > 0 || config?.condensers.some(cond => cond.selectedProduct || cond.zones.some(zone => zone.selectedProduct))) {
          confirmationModal('Are you sure you want to update this configuration? This will reset all selected products and systems for this configuration!', () => {
            moveZoneItem(item.id);
          });
        } else {
          moveZoneItem(item.id);
        }
      }
      if (item.type === 'condenser' && !monitor.didDrop()) {
        if (selectedSystems.filter(system => system.configuration.uniqueId === configId).length > 0 || config?.condensers.some(cond => cond.selectedProduct || cond.zones.some(zone => zone.selectedProduct))) {
          confirmationModal('Are you sure you want to update this configuration? This will reset all selected products and systems for this configuration!', () => {
            moveCondenser(item.id);
          });
        } else {
          moveCondenser(item.id);
        }
      }
    },
    collect: (monitor) => ({
      canDrop: !!monitor.canDrop() && monitor.getItem().id !== condenser.uniqueId,
      isOver: !!monitor.isOver({ shallow: true }) && monitor.getItem().id !== condenser.uniqueId
    })
  }), [selectedSystems, config]);

  const deleteSelf = () => {
    setConfigurationOptions((draft) => {
      const configIndex = draft.findIndex((conf) => conf.uniqueId === configId);
      const condenserIndex = draft[configIndex].condensers.findIndex(
        (cond) => cond.uniqueId === condenser.uniqueId
      );
      draft[configIndex].condensers.splice(condenserIndex, 1);
    });
  };

  const handleDeleteClick = () => {
    const confirmText = selectedSystems.filter(system => system.configuration.uniqueId === configId).length > 0 || config?.condensers.some(cond => cond.selectedProduct || cond.zones.some(zone => zone.selectedProduct)) ?
      'Are you sure you want to update this configuration? This will reset all selected products and systems for this configuration!' :
      `Are you sure you want to delete ${condenser.name}?`;
    if (condenser.modified || condenser.zones.some(zone => zone.modified) || selectedSystems.length > 0) {
      confirmationModal(confirmText, deleteSelf);
    } else {
      deleteSelf();
    }
  };

  const handleUpdateName = () => {
    if (newName.trim() !== '' && newName !== condenser.name) {
      setConfigurationOptions((draft) => {
        const configIndex = draft.findIndex((conf) => conf.uniqueId === configId);
        if (configIndex !== -1) {
          const condenserIndex = draft[configIndex].condensers.findIndex(
            (cond) => cond.uniqueId === condenser.uniqueId
          );
          if (condenserIndex !== -1) {
            // eslint-disable-next-line no-param-reassign
            draft[configIndex].condensers[condenserIndex].name = newName.trim();
            // eslint-disable-next-line no-param-reassign
            draft[configIndex].condensers[condenserIndex].modified = true;
          }
        }
      });
      setNewName(newName.trim());
    } else {
      setNewName(condenser.name);
    }
    setRenaming(false);
  };

  const handleRenameKeyDown = (key: string) => {
    if (key === 'Enter') {
      handleUpdateName();
    }
  };

  useEffect(() => {
    if (!isFirstRender) {
      if (condenser.zones.length === 0) {
        deleteSelf();
      }
    } else {
      setIsFirstRender(false);
    }
  }, [condenser.zones]);

  const classes = clsx(
    'w-full rounded border-2 overflow-hidden',
    !valid && !canDrop && 'border-red-300',
    warning && valid && !canDrop && 'border-amber-300',
    canDrop && 'border-dashed border-green-500'
  );

  const headerClasses = clsx(
    'flex h-10 w-full items-center border-b-2 pl-3',
    !valid && 'bg-red-100',
    warning && valid && 'bg-amber-100',
    valid && !warning && 'bg-gray-100'
  );

  return (
    <div ref={drop}>
      <div ref={dragPreview} className='flex flex-col'>
        <div className='flex'>
          <div className='flex flex-col pr-2 pt-2'>
            <div className='h-3 w-3 rounded-full bg-gray-300' />
            {condenser.zones.length > 0 && <div className='border-l-4 border-gray-300 grow ml-[4px]' />}
          </div>
          <div ref={drag} className='flex grow items-center gap-2'>
            <CpuChipIcon className='h-6 w-6 text-gray-800 skrink-0' />
            {renaming ? (
              <div className='grow'>
                <div className="relative">
                  <Text
                    size='sm'
                    onInput={(value) => setNewName((value ?? '').slice(0, renameSizeLimit))}
                    value={newName}
                    onBlur={handleUpdateName}
                    onKeyDown={(e) => handleRenameKeyDown(e.key)}
                    autoFocus
                    autoSelect
                    controlled
                  />
                  <div className='absolute inset-y-0 right-0 flex items-center pr-2 text-gray-500 text-sm pointer-events-none'>
                    {newName.length}/{renameSizeLimit}
                  </div>
                </div>
              </div>
            ) : (
              <button
                type='button'
                onClick={() => setRenaming(true)}
                className='relative flex items-center gap-2 truncate outline-none cursor-pointer group'
              >
                <span className='text-xl font-semibold shrink-0'>
                  {condenser.name}
                </span>
                <PencilSquareIcon className='h-5 w-5 text-gray-600 group-hover:text-gray-900' />
                <Tooltip text='Rename' />
              </button>
            )}
            {!simpleMode && condenser.zones.some(z => z.zoneItems.length > 0) && (
              <BTUBubbles
                BTUs={condenser.zones.flatMap(z => z.zoneItems.map(item => item.BTUs))}
                roundNumbers
              />
            )}
            {config?.valid && condenser.valid && <ValidSystemsTooltip condenser={condenser} />}
            {errorMessage && (
              <WarningTooltip type={valid ? 'warning' : 'error'}>
                {errorMessage}
              </WarningTooltip>
            )}
            {condenser.type !== 'ducted' && (
              <button
                className='h-full cursor-pointer text-gray-500 hover:text-gray-700 relative'
                type='button'
                onClick={handleDeleteClick}
              >
                <TrashIcon className='h-5 w-5' />
                <Tooltip text="Delete Condenser" />
              </button>
            )}
          </div>
        </div>
        <div ref={animate} className='flex flex-col'>
          {condenser.zones.map((zone) => (
            <ZoneComponent
              key={zone.uniqueId}
              zone={zone}
              condenser={condenser}
              configId={configId}
              simpleMode={simpleMode}
              systemType={condenser.type}
            />
          ))}
        </div>
        {condenser.type !== 'ducted' ? (
          <div className='pl-10'>
            <button
              type='button'
              className={`inline-flex items-center gap-1 p-3 text-sm font-semibold text-blue-500 hover:text-blue-600 ${condenser.zones.length < 5 ? 'cursor-pointer' : 'opacity-[70%] cursor-not-allowed'}`}
              onClick={() => handleAddZoneClick('ductless')}
            >
              <PlusIcon className='h-5 w-5' />
              <span>{condenser.type === 'hybrid' ? 'Add Mini-Split Zone' : 'Add Zone'}</span>
            </button>
          </div>
        ) : (
          <div className='bg-white pb-3' />
        )}
      </div>
    </div>
  );
}

CondenserComponent.defaultProps = {
  simpleMode: false
};