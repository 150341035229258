import React from 'react';
import * as ReactDOM from 'react-dom/client';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import { AuthContext, AuthContextProvider } from 'data/context/auth-context';
import { ConfigurationContextProvider } from 'data/context/configuration-context';
import { ProjectContextProvider } from 'data/context/project-context';
import { DataContextProvider } from 'data/context/data-context';
import { UIContextProvider } from 'data/context/ui-context';
import { AdminContextProvider } from 'data/context/admin-context';
import MainApp from 'routes/main';
import AdminApp from 'routes/admin';
import 'styles/main.css';

const PrivateRoutes = ({ requiredRole, requiredPermission }: { requiredRole?: string; requiredPermission?: string }) => {
  const authContext = React.useContext(AuthContext);

  if (!authContext) {
    return <div>Loading...</div>;
  }

  const { isAuthenticated, isLoading } = authContext;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    const redirectUrl = window.location.href;
    window.location.href = `${process.env.REACT_APP_PARTNERNET_URL}/partnernet/security/logon.cfm?redirectUrl=${redirectUrl}`;
    return null;
  }

  if (requiredPermission && !authContext.hasPermission(requiredPermission)) {
    return <div>Not authorized</div>;
  }

  if (requiredRole && !authContext.hasRole(requiredRole)) {
    return <div>Not authorized</div>;
  }

  return <Outlet />;
};

const router = createBrowserRouter([{
  path: '/',
  element: <AuthContextProvider><Outlet /></AuthContextProvider>,
  children: [{
    path: '/',
    element: <PrivateRoutes />,
    children: [{
      path: '/',
      element: (
        <ProjectContextProvider>
          <ConfigurationContextProvider>
            <DataContextProvider>
              <UIContextProvider>
                <MainApp />
              </UIContextProvider>
            </DataContextProvider>
          </ConfigurationContextProvider>
        </ProjectContextProvider>)
    }, {
      path: '/admin',
      element: <PrivateRoutes requiredRole='administrators' />,
      children: [{
        path: '/admin',
        element: (
          <AdminContextProvider>
            <AdminApp />
          </AdminContextProvider>
        )
      }]
    }
    ]
  }]
}]);

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <RouterProvider router={router} />
  );
}