import React from 'react';
import clsx from 'clsx';
import { CheckIcon } from '@heroicons/react/20/solid';

type ChipProps = {
  value: string | number;
  selected: boolean;
  onClick?: (value: string | number) => void;
  onDisabledClick?: () => void;
  label?: string;
  disabled?: boolean;
  showIcon?: boolean;
}

export default function Chip({
  onClick,
  onDisabledClick,
  value,
  selected,
  label,
  disabled = false,
  showIcon = false,
}: ChipProps) {
  const classes = clsx(
    'flex items-center gap-2 px-4 py-1 rounded-full border-2 border-gray-200 text-sm whitespace-nowrap',
    selected ? 'bg-gray-200' : 'bg-white hover:bg-gray-100',
    disabled && 'opacity-[70%] cursor-not-allowed'
  );

  const handleClick = () => {
    if (onClick && !disabled) {
      onClick(value);
    }
    if (onDisabledClick && disabled) {
      onDisabledClick();
    }
  };

  return (
    <button
      className={classes}
      onClick={handleClick}
    >
      {showIcon && selected && <CheckIcon className='h-4 w-4' />}
      <span>{label ?? value}</span>
    </button>
  );
}

Chip.defaultProps = {
  onClick: undefined,
  onDisabledClick: undefined,
  label: undefined,
  disabled: false,
  showIcon: false
};
