import React, { useContext } from 'react';
import { ConfigurationContext, DataContext, UIContext } from 'data/context';
import Button from 'components/inputs/button';
import ConfigSelector from 'components/config-selector';
import NumberInput from 'components/inputs/number';
import { RankingModifiers } from 'data/types';
import SystemsTable from 'components/systems-table';

export default function RankingSandbox() {
  const {
    configurations,
    activeConfig
  } = useContext(ConfigurationContext);
  const {
    rankingModifiers,
    setRankingModifiers
  } = useContext(DataContext);
  const {
    setShowRankingSandbox
  } = useContext(UIContext);

  const handleModifierChange = (modifier: keyof RankingModifiers, value: number) => {
    setRankingModifiers((oldValue) => {
      if (oldValue) {
        return {
          ...oldValue,
          [modifier]: value
        };
      }
      return null;
    });
  };

  return (
    <div className='mx-auto flex max-w-[1400px] flex-col gap-5'>
      <div className="flex items-center gap-3 justify-between">
        <div className='text-3xl font-bold text-gray-900'>Ranking Sandbox</div>
        <Button
          color='primary'
          text='Toggle Sandbox'
          onClick={() => setShowRankingSandbox(false)}
        />
      </div>
      <div className='flex flex-wrap gap-5'>
        {rankingModifiers &&
          Object.keys(rankingModifiers).map((modifier) => (
            <div key={modifier} className='w-[210px] flex flex-col gap-2'>
              <label htmlFor={modifier}>{modifier}</label>
              <NumberInput
                value={rankingModifiers?.[modifier]}
                onChange={(value) => handleModifierChange(modifier as keyof RankingModifiers, value ?? 0)}
              />
            </div>
          ))
        }
      </div>
      <div className='flex gap-5'>
        <div className='flex w-[350px] shrink-0 flex-col gap-5'>
          <ConfigSelector />
        </div>
        <div className='grow relative'>
          <div className='absolute top-0 inset-x-0 flex flex-col gap-5 pb-5'>
            {configurations[activeConfig].condensers.map((cond) => (
              <SystemsTable key={cond.uniqueId} condenser={cond} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
