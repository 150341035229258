import React, { useContext, useState, useEffect, useRef } from 'react';
import { ConfigurationContext, ProjectContext, UIContext } from 'data/context';
import { animated, useSpring } from '@react-spring/web';

export default function DetailsPanel() {
  const {
    attomData,
    locationData,
    projectDetails,
    weatherData
  } = useContext(ProjectContext);
  const { activeConfig, configurations } = useContext(ConfigurationContext);
  const { showDetailsPanel } = useContext(UIContext);
  const [locationStats, setLocationStats] = useState<{ label: string; value: any; color: 'bg-gray-100' | 'bg-orange-200' | 'bg-blue-200'; }[]>([]);
  const [projectStats, setProjectStats] = useState<{ label: string; value: any; }[]>([]);
  const panelRef = useRef<HTMLDivElement>(null);
  const spring = useSpring({
    height: showDetailsPanel ? (panelRef.current?.scrollHeight ?? 0) + 5 : 0,
    config: { tension: 300, friction: 30 }
  });

  useEffect(() => {
    setLocationStats([
      { label: 'Stats for', value: locationData?.location ?? '-', color: 'bg-gray-100' },
      { label: 'Avg Days with Low Temperature Below 60°F', value: weatherData?.minTemps.find(row => row.temperature === 60)?.avgDaysPerYear ? `${weatherData?.minTemps.find(row => row.temperature === 60)?.avgDaysPerYear} Days` : '-', color: 'bg-gray-100' },
      { label: 'Avg Days with High Temperature Above 80°F', value: weatherData?.maxTemps.find(row => row.temperature === 80)?.avgDaysPerYear ? `${weatherData?.maxTemps.find(row => row.temperature === 80)?.avgDaysPerYear} Days` : '-', color: 'bg-gray-100' },
      { label: 'Altitude', value: weatherData ? `${weatherData.elevation.toFixed()}'` : '-', color: 'bg-gray-100' },
      { label: 'Avg Yearly Maximum Snow Depth', value: weatherData ? `${weatherData.avgMaxSnowDepth.toFixed()}"` : '-', color: 'bg-gray-100' }
    ]);
  }, [attomData, locationData, projectDetails, weatherData, activeConfig, configurations]);

  useEffect(() => {
    setProjectStats([
      { label: 'Number of Systems', value: projectDetails?.number_of_systems ?? '-' },
      { label: 'Must Receive By', value: projectDetails?.due ?? '-' },
      { label: 'Timeframe', value: projectDetails?.urgency ?? '-' },
      { label: 'Product', value: projectDetails?.productLine ?? '-' }
    ]);
  }, [projectDetails]);

  return (
    <animated.div
      className='z-10 overflow-hidden'
      style={spring}
    >
      <div ref={panelRef} className='flex w-full flex-col gap-5 border-b border-gray-200 bg-white p-5 shadow mb-2'>
        <div className='flex gap-5'>
          {locationStats.map((stat) => (
            <div key={stat.label} className={`flex flex-col gap-2 grow rounded-lg ${stat.color} p-4`}>
              <span className='text-xs font-semibold uppercase text-gray-500'>
                {stat.label}
              </span>
              <span className='font-semibold text-gray-800 capitalize'>
                {stat.value}
              </span>
            </div>
          ))}
        </div>
        <div className='flex gap-5'>
          <div className='flex max-w-[50%] grow flex-col gap-2'>
            <span className='text-lg font-bold'>
              {projectDetails
                ? projectDetails?.jobName ||
                `Project #${projectDetails.projectId}`
                : 'New Mini-Split System'}
            </span>
            <div className='line-clamp-2 text-ellipsis text-gray-600'>
              {projectDetails?.description ?? ''}
            </div>
          </div>
          {projectStats.map((stat) => (
            <div key={stat.label} className='flex flex-col gap-2 grow rounded-lg border-2 border-gray-200 p-4'>
              <span className='whitespace-nowrap text-xs font-semibold uppercase text-gray-500'>
                {stat.label}
              </span>
              <span className='font-semibold text-gray-800'>
                {stat.value}
              </span>
            </div>
          ))}
        </div>
      </div>
    </animated.div>
  );
}
