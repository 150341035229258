import React, { useState, ReactNode, useEffect, useRef, useContext } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import ToggleSwitch from 'components/inputs/toggle-switch';
import ProgressIndicator from 'components/progress-indicator';
import TalkingPoint from 'components/talking-point';
import { useSpring, animated } from '@react-spring/web';
import { UIContext } from 'data/context';
import { AccessoryCategory } from 'data/types';
import Button from 'components/inputs/button';

type AccessoryDrawerProps = {
  category: AccessoryCategory;
  title: string;
  description: string;
  required: boolean;
  itemSelected: boolean;
  notNeeded: boolean;
  children?: ReactNode;
  handleNotNeeded: (value: boolean) => void;
  disabled?: boolean;
  warning?: string;
};

export default function AccessoryDrawer({
  category,
  title,
  description,
  required,
  itemSelected,
  notNeeded,
  children,
  handleNotNeeded,
  disabled = false,
  warning
}: AccessoryDrawerProps) {
  const {
    activeAccessoryDrawer,
    setActiveAccessoryDrawer
  } = useContext(UIContext);
  const [open, setOpen] = useState(false);
  const drawerRef = useRef<HTMLDivElement>(null);
  const [spring] = useSpring(() => ({
    height: open ? drawerRef.current?.scrollHeight ?? 0 : 0,
    config: { tension: 300, friction: 30 }
  }), [open]);

  useEffect(() => {
    setOpen(activeAccessoryDrawer === category);
  }, [activeAccessoryDrawer]);

  useEffect(() => {
    if ((notNeeded || disabled) && activeAccessoryDrawer === category) {
      setActiveAccessoryDrawer(null);
    }
  }, [notNeeded, disabled]);

  const toggleOpen = () => {
    if (activeAccessoryDrawer === category) {
      setActiveAccessoryDrawer(null);
    } else {
      setActiveAccessoryDrawer(category);
    }
  };

  return (
    <div className={`flex w-full flex-col rounded-lg bg-white ${disabled && 'opacity-50'}`}>
      <div className='flex flex-col gap-2 p-5'>
        <div className='flex items-center gap-2'>
          {required && (
            <ProgressIndicator
              required
              checked={itemSelected || notNeeded}
            />
          )}
          <span className='text-lg font-bold text-gray-800'>{title}</span>
          {warning && <span className='text-amber-500 ml-2'>{warning}</span>}
        </div>
        <div className='flex items-center gap-3'>
          <TalkingPoint>{description}</TalkingPoint>
          {category !== 'lineSetCover' && (
            <label className={`flex items-center gap-2 p-2 ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}>
              <ToggleSwitch
                value={!notNeeded}
                onChange={(checked) => handleNotNeeded(!checked)}
                disabled={disabled}
              />
              <span className='whitespace-nowrap text-sm text-gray-600'>
                Include
              </span>
            </label>
          )}
          <Button
            size='lg'
            color='secondary'
            text={open ? 'Close' : 'Select'}
            icon={open ? ChevronUpIcon : ChevronDownIcon}
            iconSize='lg'
            iconPosition='right'
            onClick={toggleOpen}
            disabled={disabled}
          />
        </div>
      </div>
      <animated.div style={spring} className='overflow-hidden'>
        <div ref={drawerRef} className='w-full border-t border-gray-200'>
          {children}
        </div>
      </animated.div>
    </div >
  );
}

AccessoryDrawer.defaultProps = {
  children: undefined,
  disabled: false,
  warning: undefined
};
