import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';

type TypeaheadProps = {
  options: string[] | { label: string; value: string | number }[];
  onChange: (value: string | number) => void;
  placeholder?: string;
  disabled?: boolean;
  allowCustom?: boolean;
  customOptionFormat?: (input: string) => string;
};

export default function Typeahead({
  options,
  onChange,
  placeholder,
  disabled = false,
  allowCustom = false,
  customOptionFormat
}: TypeaheadProps) {
  const [formattedOptions, setFormattedOptions] = useState<{ label: string; value: string | number }[]>([]);

  useEffect(() => {
    if (options) {
      if (typeof options[0] === 'string') {
        setFormattedOptions((options as string[]).map((option) => ({ label: option, value: option })));
      } else {
        setFormattedOptions(options as { label: string; value: string | number }[]);
      }
    }
  }, [options]);

  return allowCustom ? (
    <CreatableSelect
      className='[&_input]:!ring-0'
      classNames={{
        input: () => '!cursor-text',
        dropdownIndicator: () => '!cursor-pointer',
        clearIndicator: () => '!cursor-pointer',
        option: () => '!cursor-pointer'
      }}
      placeholder={placeholder}
      options={formattedOptions}
      onChange={(option) => onChange(option?.value ?? '')}
      formatCreateLabel={customOptionFormat}
      isDisabled={disabled}
      openMenuOnFocus
      openMenuOnClick
      isClearable
    />
  ) : (
    <Select
      className='[&_input]:!ring-0'
      classNames={{
        input: () => '!cursor-text',
        dropdownIndicator: () => '!cursor-pointer',
        clearIndicator: () => '!cursor-pointer',
        option: () => '!cursor-pointer'
      }}
      placeholder={placeholder}
      options={formattedOptions}
      onChange={(option) => onChange(option?.value ?? '')}
      isDisabled={disabled}
      openMenuOnFocus
      openMenuOnClick
      isClearable
    />
  );
}

Typeahead.defaultProps = {
  disabled: false,
  allowCustom: false,
  customOptionFormat: undefined
};
