import React, { MouseEvent } from 'react';
import clsx from 'clsx';
import { XMarkIcon } from '@heroicons/react/20/solid';
import Tooltip from 'components/tooltip';

type XButtonProps = {
  onClick?: (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void;
  onDisabledClick?: (event?: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void;
  disabled?: boolean;
  size?: 'sm' | 'md';
  color?: 'red' | 'gray';
  tooltip?: string;
};

export default function XButton({
  onClick,
  onDisabledClick,
  disabled = false,
  size = 'sm',
  color = 'red',
  tooltip,
}: XButtonProps) {
  const classes = clsx(
    'rounded-full flex items-center justify-center relative',
    disabled && 'opacity-[70%] cursor-not-allowed',
    size === 'sm' ? 'h-5 w-5' : 'h-6 w-6',
    color === 'red' && 'bg-red-500 hover:bg-red-600 text-white',
    color === 'gray' && 'bg-gray-300 hover:bg-gray-400 text-black'
  );

  const iconClasses = size === 'sm' ? 'h-4 w-4' : 'h-5 w-5';

  const handleClick = (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    if (onClick && !disabled) {
      onClick(event);
    }
    if (onDisabledClick && disabled) {
      onDisabledClick(event);
    }
  };

  return (
    <button
      type='button'
      className={classes}
      onClick={(e) => handleClick(e)}
    >
      {tooltip && <Tooltip text={tooltip} />}
      <XMarkIcon className={iconClasses} />
    </button>
  );
}

XButton.defaultProps = {
  disabled: false,
  size: 'sm',
  color: 'red',
  onClick: undefined,
  onDisabledClick: undefined,
  tooltip: undefined,
};
