import React, { MouseEvent } from 'react';
import clsx from 'clsx';

type LinkButtonProps = {
  color?: 'blue' | 'green' | 'red';
  text: string;
  onClick?: (event?: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void;
  onDisabledClick?: (event?: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void;
  disabled?: boolean;
  bold?: boolean
};

export default function LinkButton({
  color = 'blue',
  text,
  onClick,
  onDisabledClick,
  disabled = false,
  bold = false
}: LinkButtonProps) {
  const classes = clsx(
    color === 'blue' &&
    'text-blue-500 hover:text-blue-600 focus:outline-none focus:ring-0',
    color === 'green' &&
    'text-green-500 hover:text-green-600 focus:outline-none focus:ring-0',
    color === 'red' &&
    'text-red-500 hover:text-red-600 focus:outline-none focus:ring-0',
    bold && 'font-semibold',
    disabled && 'opacity-[70%] cursor-not-allowed',
  );

  const handleClick = (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    if (onClick && !disabled) {
      onClick(event);
    }
    if (onDisabledClick && disabled) {
      onDisabledClick(event);
    }
  };

  return (
    <button
      type='button'
      className={classes}
      onClick={(e) => handleClick(e)}
    >
      {text}
    </button>
  );
}

LinkButton.defaultProps = {
  color: 'blue',
  disabled: false,
  onClick: undefined,
  onDisabledClick: undefined,
  bold: false
};
