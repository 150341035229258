import React, { useContext, useState } from 'react';
import { AdminContext } from 'data/context';
import { successModal, errorModal, confirmationModal } from 'utils/helpers';
import axios from 'utils/api';
import Button from 'components/inputs/button';
import { TrashIcon } from '@heroicons/react/24/outline';
import { PlusIcon } from '@heroicons/react/20/solid';
import CreateAttributeFollowupModal from 'components/modals/create-attribute-followup-modal';

export default function AttributeFollowups() {
  const { refreshData, attributes, attributeFollowUps } = useContext(AdminContext);
  const [modalOpen, setModalOpen] = useState(false);

  const deleteItem = (followUpId: number) => {
    confirmationModal('Are you sure you want to delete this fenestration item?', async () => {
      try {
        await axios.delete(`attribute-followups/${followUpId}`);
        await refreshData();
        successModal('Attribute followup deleted.');
      } catch (error) {
        errorModal('Something went wrong.');
      }
    });
  };

  return (
    <div className='mx-auto flex max-w-[1600px] flex-col gap-5'>
      <div className='text-3xl font-bold text-gray-900'>
        Attribute Followups
      </div>
      <div className='max-h-[50vh] overflow-auto'>
        <table className='min-w-full border-separate border-spacing-0 divide-y divide-gray-300'>
          <thead>
            <tr>
              <th
                scope='col'
                className='sticky top-0 z-10 border-b border-gray-300 bg-gray-100 pb-3.5 pl-3 pr-0 text-left font-semibold text-gray-900'
              >
                Attribute
              </th>
              <th
                scope='col'
                className='sticky top-0 z-10 border-b border-gray-300 bg-gray-100 px-3 pb-3.5 text-left font-semibold text-gray-900'
              >
                Value
              </th>
              <th
                scope='col'
                className='sticky top-0 z-10 border-b border-gray-300 bg-gray-100 px-3 pb-3.5 text-left font-semibold text-gray-900'
              >
                Followup Attribute
              </th>
              <th
                scope='col'
                className='sticky top-0 z-10 border-b border-gray-300 bg-gray-100 px-3 pb-3.5 text-left font-semibold text-gray-900'
              />
            </tr>
          </thead>
          <tbody className='divide-y divide-gray-200'>
            {attributeFollowUps
              .map((followUp) => (
                <tr key={followUp.id}>
                  <td className='whitespace-nowrap border-b border-gray-200 py-4 pl-3 pr-0 text-gray-900'>
                    {attributes.find((a) => a.id === followUp.attributeId)?.label}
                  </td>
                  <td className='whitespace-nowrap border-b border-gray-200 px-3 py-4'>
                    {followUp.value}
                  </td>
                  <td className='whitespace-nowrap border-b border-gray-200 px-3 py-4'>
                    {attributes.find((a) => a.id === followUp.followUpAttributeId)?.label}
                  </td>
                  <td className='whitespace-nowrap border-b border-gray-200 px-3 py-4'>
                    <TrashIcon
                      className='h-5 w-5 shrink-0 cursor-pointer text-gray-500 hover:text-gray-700'
                      onClick={() => deleteItem(followUp.id)}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div>
        <Button text='New Attribute Followup' color='secondary' onClick={() => setModalOpen(true)} icon={PlusIcon} />
      </div>
      <CreateAttributeFollowupModal openState={[modalOpen, setModalOpen]} />
    </div>
  );
}
