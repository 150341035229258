import React, { ReactNode } from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

type InternalNoteProps = {
  children?: ReactNode;
};

export default function InternalNote({ children }: InternalNoteProps) {
  return (
    <div className='w-full rounded-lg bg-yellow-50 px-4 py-3 text-yellow-700 shadow'>
      <div className='flex gap-2'>
        <InformationCircleIcon className='h-5 w-5 flex-shrink-0' />
        <div className='grow text-sm'>{children}</div>
      </div>
    </div>
  );
}

InternalNote.defaultProps = {
  children: null
};
