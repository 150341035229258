import React, {
  useContext,
  useEffect,
  useRef
} from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { DataContext, ConfigurationContext, ProjectContext, UIContext } from 'data/context';
import { Section, Attribute } from 'data/types';
import { confirmationModal, createActiveItem, errorModal } from 'utils/helpers';
import { createSpace, getAllLabelsOfType } from 'utils/structure';
import SpaceComponent from 'components/dnd/space';
import clsx from 'clsx';

type SectionProps = {
  section: Section;
  levelAttributes: Attribute[];
  renderSelf?: boolean;
  focus?: 'levels' | 'sections' | 'spaces';
};

export default function SectionComponent({
  section,
  levelAttributes,
  renderSelf = true,
  focus
}: SectionProps) {
  const { items, fenestration } = useContext(DataContext);
  const {
    structure,
    setStructure,
    configurations,
    selectedSystems
  } = useContext(ConfigurationContext);
  const { projectSettings } = useContext(ProjectContext);
  const { setActiveItem, easyMode } = useContext(UIContext);
  const levelAttributesRef = useRef(levelAttributes);
  const structureRef = useRef(structure);
  const projectSettingsRef = useRef(projectSettings);
  const [animate] = useAutoAnimate({ duration: 100 });

  useEffect(() => {
    levelAttributesRef.current = levelAttributes;
    structureRef.current = structure;
    projectSettingsRef.current = projectSettings;
  }, [levelAttributes, structure, projectSettings]);

  const deleteSelf = () => {
    setStructure((draft) => {
      const levelIndex = draft.findIndex((l) =>
        l.sections.some((s) => s.uniqueId === section.uniqueId)
      );
      if (levelIndex !== -1) {
        const sectionIndex = draft[levelIndex].sections.findIndex(
          (s) => s.uniqueId === section.uniqueId
        );
        if (sectionIndex !== -1) {
          draft[levelIndex].sections.splice(sectionIndex, 1);
        }
      }
    });
  };

  useEffect(() => {
    if (section.spaces.length === 0) {
      deleteSelf();
    }
  }, [section.spaces]);

  const addSpace = (itemId: number) => {
    const spaceItem = items.find((i) => i.id === itemId);
    if (spaceItem) {
      let itemNumber = 2;
      const existingLabels = getAllLabelsOfType(structureRef.current, 'space');
      const isMultipleSpaces = existingLabels.filter(label => label.startsWith(spaceItem.label)).length > 0;
      while (existingLabels.includes(`${spaceItem.label} ${itemNumber}`)) {
        itemNumber += 1;
      }
      const newSpaceLabel = isMultipleSpaces ? `${spaceItem.label} ${itemNumber}` : spaceItem.label;
      const newSpace = createSpace(spaceItem, {
        data: {
          label: newSpaceLabel
        },
        attributesToInherit: [...levelAttributesRef.current, ...projectSettingsRef.current.filter((s) => !levelAttributesRef.current.map(a => a.id).includes(s.id))]
      });
      setStructure((draft) => {
        const levelIndex = draft.findIndex((l) =>
          l.sections.some((s) => s.uniqueId === section.uniqueId)
        );
        if (levelIndex !== -1) {
          const sectionIndex = draft[levelIndex].sections.findIndex(
            (s) => s.uniqueId === section.uniqueId
          );
          if (sectionIndex !== -1) {
            draft[levelIndex].sections[sectionIndex].spaces.push(newSpace);
          }
        }
      });
      if (!easyMode) {
        setActiveItem(createActiveItem({ type: 'space', item: newSpace, items, fenestration }));
      }
    } else {
      console.error('Level item not found');
      errorModal(`Level item not found`);
    }
  };

  const moveSpace = (spaceId: string) => {
    setStructure((draft) => {
      const fromLevelIndex = draft.findIndex((l) =>
        l.sections.some((s) => s.spaces.some((sp) => sp.uniqueId === spaceId))
      );
      const toLevelIndex = draft.findIndex((l) =>
        l.sections.some((s) => s.uniqueId === section.uniqueId)
      );
      if (fromLevelIndex !== -1 && toLevelIndex !== -1) {
        const fromSectionIndex = draft[fromLevelIndex].sections.findIndex((s) =>
          s.spaces.some((sp) => sp.uniqueId === spaceId)
        );
        const toSectionIndex = draft[toLevelIndex].sections.findIndex(
          (s) => s.uniqueId === section.uniqueId
        );
        if (fromSectionIndex !== -1 && toSectionIndex !== -1) {
          const fromSectionId =
            draft[fromLevelIndex].sections[fromSectionIndex].uniqueId;
          const toSectionId = draft[toLevelIndex].sections[toSectionIndex].uniqueId;
          if (fromSectionId !== toSectionId) {
            const spaceIndex = draft[fromLevelIndex].sections[
              fromSectionIndex
            ].spaces.findIndex((sp) => sp.uniqueId === spaceId);
            if (spaceIndex !== -1) {
              const [space] = draft[fromLevelIndex].sections[
                fromSectionIndex
              ].spaces.splice(spaceIndex, 1);
              draft[toLevelIndex].sections[toSectionIndex].spaces.push(space);
            }
          }
        }
      }
    });
  };

  const moveSection = (fromSectionId: string) => {
    setStructure((draft) => {
      const fromLevelIndex = draft.findIndex((l) =>
        l.sections.some((s) => s.uniqueId === fromSectionId)
      );
      const toLevelIndex = draft.findIndex((l) =>
        l.sections.some((s) => s.uniqueId === section.uniqueId)
      );
      if (fromLevelIndex !== -1 && toLevelIndex !== -1) {
        const fromSectionIndex = draft[fromLevelIndex].sections.findIndex(
          (s) => s.uniqueId === fromSectionId
        );
        const toSectionIndex = draft[toLevelIndex].sections.findIndex(
          (s) => s.uniqueId === section.uniqueId
        );
        if (fromSectionIndex !== -1 && toSectionIndex !== -1) {
          const [sectionToMove] = draft[fromLevelIndex].sections.splice(
            fromSectionIndex,
            1
          );
          draft[toLevelIndex].sections.splice(toSectionIndex, 0, sectionToMove);
        }
      }
    });
  };

  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type: 'section',
    item: { id: section.uniqueId, type: 'section' },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  }));

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ['new-space', 'space', 'section'],
    drop: (
      item: {
        id: string;
        label: string;
        type: 'new-space' | 'space' | 'section';
        itemId?: number;
      },
      monitor
    ) => {
      if (item.type === 'new-space' && item.itemId) {
        if (configurations.length > 0 || selectedSystems.length > 0) {
          confirmationModal('Are you sure you want to add a space? This will reset all configurations and selected systems!', () => {
            addSpace(item.itemId as number);
          });
        } else {
          addSpace(item.itemId);
        }
      }
      if (item.type === 'space' && !monitor.didDrop()) {
        if (configurations.length > 0 || selectedSystems.length > 0) {
          confirmationModal('Are you sure you want to add a space? This will reset all configurations and selected systems!', () => {
            moveSpace(item.id);
          });
        } else {
          moveSpace(item.id);
        }
      }
      if (item.type === 'section') {
        if (configurations.length > 0 || selectedSystems.length > 0) {
          confirmationModal('Are you sure you want to modify the section? This will reset all configurations and selected systems!', () => {
            moveSection(item.id);
          });
        } else {
          moveSection(item.id);
        }
      }
    },
    collect: (monitor) => ({
      canDrop: !!monitor.canDrop() && monitor.getItem().id !== section.uniqueId,
      isOver: !!monitor.isOver({ shallow: true }) && monitor.getItem().id !== section.uniqueId
    })
  }), [configurations, selectedSystems]);

  const classes = clsx(
    'w-full h-full rounded-lg',
    canDrop && 'border-dashed border-green-500',
    isOver && 'bg-green-50',
    focus === 'sections' && 'ring-4 ring-green-500'
  );

  const headerClasses = clsx(
    'w-full border-2 rounded-lg px-2',
    canDrop && 'border-dashed border-green-500',
    isOver ? 'bg-green-50' : 'bg-gray-100',
    isDragging ? 'cursor-grabbing' : 'cursor-grab'
  );

  return (section.spaces.length > 1 && renderSelf) ? (
    <div ref={drop} className='w-full h-full'>
      <div ref={dragPreview} className={classes}>
        <div ref={animate} className='flex flex-col w-full h-full'>
          <div ref={drag} className={headerClasses}>
            Proximity Area
          </div>
          {section.spaces.map((space) => (
            <SpaceComponent
              key={space.uniqueId}
              space={space}
            />
          ))}
        </div>
      </div>
    </div>
  ) : (
    <>
      {section.spaces.map((space) => (
        <SpaceComponent
          key={space.uniqueId}
          space={space}
          focus={focus}
        />
      ))}
    </>
  );
}

SectionComponent.defaultProps = {
  renderSelf: true,
  focus: undefined
};