import React, { useContext, useEffect, useState, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import { PlusIcon } from '@heroicons/react/20/solid';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ProjectContext, ConfigurationContext, DataContext } from 'data/context';
import { AirHandlerType, Condenser, Configuration, Item, Level, Section, Space, Zone } from 'data/types';
import ConfigurationComponent from 'components/dnd/configuration';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import ConfigurationModal from 'components/modals/configuration-modal';
import ConfigOptionComponent from 'components/config-option';
import { findSectionBySpaceId, findSpaceById, getRestrictedAirHandlerTypes } from 'utils/structure';
import { rankConfigsNew } from 'utils/ranking';

export default function Configurations() {
  const {
    totalBTUs,
    partialStructure,
    customerValues,
    projectSettings
  } = useContext(ProjectContext);
  const {
    configurations,
    setConfigurations,
    configurationOptions,
    setConfigurationOptions,
    setAllConfigurationOptions,
    structure,
    existingSystems
  } = useContext(ConfigurationContext);
  const {
    items,
    BTULimits,
    airHandlerTypes,
    configRankingModifiers
  } = useContext(DataContext);
  const [ductedBTULimit, setDuctedBTULimit] = useState(0);
  const [tempConfigsGenerated, setTempConfigsGenerated] = useState(false);
  const [temporaryConfigurations, setTemporaryConfigurations] = useState<Configuration[]>([]);
  const [tempConfigsFixed, setTempConfigsFixed] = useState(false);
  const [temporaryConfigurationsPermutations, setTemporaryConfigurationsPermutations] = useState<Configuration[]>([]);
  const [temporaryConfigurationsPermutationsRanked, setTemporaryConfigurationsPermutationsRanked] = useState<Configuration[]>([]);
  const generateTempConfigsCalled = useRef(false);
  const configurationModalState = useState(true);
  const [animate] = useAutoAnimate({ duration: 100 });

  useEffect(() => {
    setDuctedBTULimit(BTULimits.filter((limit) => limit.length === 1).sort((a, b) => b[0] - a[0])[0][0]);
  }, [BTULimits]);

  const createEmptyConfig = () => {
    const newConfig: Configuration = {
      uniqueId: uuid(),
      optionId: 1,
      name: 'Custom Configuration',
      type: 'ductless',
      condensers: [{
        uniqueId: uuid(),
        type: 'ductless',
        name: 'Condenser 1',
        validSystems: [],
        validDuctedSystems: [],
        rankedSystems: [],
        rankedDuctedSystems: [],
        modified: false,
        zones: [{
          uniqueId: uuid(),
          name: 'Zone 1',
          type: 'ductless',
          modified: false,
          zoneItems: []
        }]
      }],
      valid: false,
      modified: false
    };
    return newConfig;
  };

  const createDuctedConfig = (hybrid = false) => {
    let newName = hybrid ? 'Hybrid Configuration' : 'Ducted Configuration';
    const existingLabels = configurations.map(c => c.name);
    if (existingLabels.includes(newName)) {
      let configCount = 2;
      while (existingLabels.includes(`${newName} ${configCount}`)) {
        configCount += 1;
      }
      newName = `${newName} ${configCount}`;
    }
    const newCondensers: Condenser[] = [];
    for (let i = 0; i < existingSystems.length; i += 1) {
      const newZones: Zone[] = [{
        uniqueId: uuid(),
        name: 'Ducted Zone',
        type: 'ducted',
        modified: false,
        zoneItems: []
      }];
      if (hybrid) {
        newZones.push({
          uniqueId: uuid(),
          name: 'Mini-Split Zone 1',
          type: 'ductless',
          modified: false,
          zoneItems: []
        });
      }
      newCondensers.push({
        uniqueId: uuid(),
        type: hybrid ? 'hybrid' : 'ducted',
        name: `Condenser ${i + 1}`,
        validSystems: [],
        validDuctedSystems: [],
        rankedSystems: [],
        rankedDuctedSystems: [],
        modified: false,
        zones: newZones
      });
    }
    const newConfig: Configuration = {
      uniqueId: uuid(),
      name: newName,
      type: hybrid ? 'hybrid' : 'ducted',
      condensers: newCondensers,
      valid: false,
      modified: false
    };
    return newConfig;
  };

  const addLayoutBasedConfig = (temporary = false) => {
    let newName = 'Layout Based Configuration';
    const existingLabels = configurations.map(c => c.name);
    if (existingLabels.includes(newName)) {
      let configCount = 2;
      while (existingLabels.includes(`${newName} ${configCount}`)) {
        configCount += 1;
      }
      newName = `${newName} ${configCount}`;
    }
    let zoneCount = 0;
    const newConfig: Configuration = {
      uniqueId: uuid(),
      name: newName,
      type: 'ductless',
      condensers: structure
        .filter((level) => level.sections.length > 0)
        .map((level, levelIndex) => ({
          uniqueId: uuid(),
          type: 'ductless',
          name: `Condenser ${levelIndex + 1}`,
          validSystems: [],
          validDuctedSystems: [],
          rankedSystems: [],
          rankedDuctedSystems: [],
          modified: false,
          zones: level.sections.map((section) => {
            zoneCount += 1;
            return {
              uniqueId: uuid(),
              name: `Zone ${zoneCount}`,
              type: 'ductless',
              modified: false,
              zoneItems: section.spaces
                .filter((s) => s.BTUs.heating || s.BTUs.cooling)
                .map((space) => ({
                  uniqueId: uuid(),
                  structureItemId: space.uniqueId,
                  name: space.label,
                  BTUs: space.BTUs,
                  originalBTUs: space.BTUs,
                  isPartition: false
                }))
            };
          })
        })),
      valid: false,
      modified: false
    };
    if (temporary) {
      setTemporaryConfigurations((oldConfigs) => [...oldConfigs, newConfig]);
    } else {
      setConfigurations((oldConfigs) => [...oldConfigs, newConfig]);
    }
  };

  const addIndividualZonesConfig = (temporary = false) => {
    let zoneCount = 0;
    let newName = 'Zone-Per-Space Configuration';
    const existingLabels = configurations.map(c => c.name);
    if (existingLabels.includes(newName)) {
      let configCount = 2;
      while (existingLabels.includes(`${newName} ${configCount}`)) {
        configCount += 1;
      }
      newName = `${newName} ${configCount}`;
    }
    const newConfig: Configuration = {
      uniqueId: uuid(),
      name: newName,
      type: 'ductless',
      condensers: structure
        .filter((level) => level.sections.length > 0)
        .map((level, levelIndex) => ({
          uniqueId: uuid(),
          type: 'ductless',
          name: `Condenser ${levelIndex + 1}`,
          validSystems: [],
          validDuctedSystems: [],
          rankedSystems: [],
          rankedDuctedSystems: [],
          modified: false,
          zones: level.sections.flatMap((section) => section.spaces
            .filter((s) => s.BTUs.heating || s.BTUs.cooling))
            .map((space) => {
              zoneCount += 1;
              return {
                uniqueId: uuid(),
                name: `Zone ${zoneCount}`,
                type: 'ductless',
                modified: false,
                zoneItems: [{
                  uniqueId: uuid(),
                  structureItemId: space.uniqueId,
                  name: space.label,
                  BTUs: space.BTUs,
                  originalBTUs: space.BTUs,
                  isPartition: false
                }]
              };
            })
        })),
      valid: false,
      modified: false
    };
    if (temporary) {
      setTemporaryConfigurations((oldConfigs) => [...oldConfigs, newConfig]);
    } else {
      setConfigurations((oldConfigs) => [...oldConfigs, newConfig]);
    }
  };

  const addSingleCondenserConfig = (temporary = false) => {
    let newName = 'Single Condenser Configuration';
    const existingLabels = configurations.map(c => c.name);
    if (existingLabels.includes(newName)) {
      let configCount = 2;
      while (existingLabels.includes(`${newName} ${configCount}`)) {
        configCount += 1;
      }
      newName = `${newName} ${configCount}`;
    }
    let zoneCount = 0;
    const newConfig: Configuration = {
      uniqueId: uuid(),
      name: newName,
      type: 'ductless',
      condensers: [{
        uniqueId: uuid(),
        type: 'ductless',
        name: 'Condenser 1',
        validSystems: [],
        validDuctedSystems: [],
        rankedSystems: [],
        rankedDuctedSystems: [],
        modified: false,
        zones: structure.flatMap(level => level.sections.map((section) => {
          zoneCount += 1;
          return {
            uniqueId: uuid(),
            name: `Zone ${zoneCount}`,
            type: 'ductless',
            modified: false,
            zoneItems: section.spaces
              .filter((s) => s.BTUs.heating || s.BTUs.cooling)
              .map((space) => ({
                uniqueId: uuid(),
                structureItemId: space.uniqueId,
                name: space.label,
                BTUs: space.BTUs,
                originalBTUs: space.BTUs,
                isPartition: false
              }))
          };
        }))
      }],
      valid: false,
      modified: false
    };
    if (temporary) {
      setTemporaryConfigurations((oldConfigs) => [...oldConfigs, newConfig]);
    } else {
      setConfigurations((oldConfigs) => [...oldConfigs, newConfig]);
    }
  };

  const addDuctedConfigWithReplacementSystem = (temporary = false) => {
    let newName = 'Ducted Configuration';
    const existingLabels = configurations.map(c => c.name);
    if (existingLabels.includes(newName)) {
      let configCount = 2;
      while (existingLabels.includes(`${newName} ${configCount}`)) {
        configCount += 1;
      }
      newName = `${newName} ${configCount}`;
    }
    const condensers: Condenser[] = [];
    existingSystems.forEach((system, index) => {
      const newZones: Zone[] = [{
        uniqueId: uuid(),
        name: 'Ducted Zone',
        type: 'ducted',
        modified: false,
        zoneItems: [],
        existingSystemId: system.id
      }];
      condensers.push({
        uniqueId: uuid(),
        type: 'ducted',
        name: `Condenser ${index + 1}`,
        validSystems: [],
        validDuctedSystems: [],
        rankedSystems: [],
        rankedDuctedSystems: [],
        modified: false,
        zones: newZones
      });
    });
    const newConfig: Configuration = {
      uniqueId: uuid(),
      name: newName,
      type: 'ducted',
      condensers,
      valid: false,
      modified: false
    };
    if (temporary) {
      setTemporaryConfigurations((oldConfigs) => [...oldConfigs, newConfig]);
    } else {
      setConfigurations((oldConfigs) => [...oldConfigs, newConfig]);
    }
  };

  const addAreaConfigurations = (temporary = false) => {
    let configCount = configurations.length + 1;
    const newConfigs: Configuration[] = [];

    // Add a new configuration based on the modified floor and area
    const addConfig = (
      floorUniqueId: Level['uniqueId'],
      areaUniqueId: Section['uniqueId'],
      movedSpace: Space,
      remainingSpaces: Space[]
    ) => {
      let zoneCount = 0;
      const newConfig: Configuration = {
        uniqueId: uuid(),
        name: `Alternative Configuration ${configCount}`,
        type: 'ductless',
        condensers: structure.filter((level) => level.sections.length > 0).map((floor, idx) => ({
          uniqueId: floor.uniqueId,
          type: 'ductless',
          name: `Condenser ${idx + 1}`,
          validSystems: [],
          validDuctedSystems: [],
          rankedSystems: [],
          rankedDuctedSystems: [],
          modified: false,
          zones: floor.sections.map((section) => {
            zoneCount += 1;
            return {
              uniqueId: section.uniqueId,
              name: `Zone ${zoneCount}`,
              type: 'ductless',
              modified: false,
              zoneItems: section.spaces
                .filter((space) => space.BTUs.heating || space.BTUs.cooling)
                .map((space) => ({
                  uniqueId: uuid(),
                  structureItemId: space.uniqueId,
                  name: space.label,
                  BTUs: space.BTUs,
                  originalBTUs: space.BTUs,
                  isPartition: false
                }))
            };
          })
        })),
        valid: false,
        modified: false
      };

      // Find the floor in the new configuration
      const floor = newConfig.condensers.find(c => c.uniqueId === floorUniqueId);
      if (!floor) {
        console.log(`Floor not found: ${floorUniqueId}`);
        return;
      }

      // Find the area in the floor's zones
      const area = floor.zones.find(z => z.uniqueId === areaUniqueId);
      if (!area) {
        console.log(`Area not found: ${areaUniqueId}`);
        return;
      }

      // Update the area's zone items with the remaining spaces
      area.zoneItems = remainingSpaces.map(space => ({
        uniqueId: uuid(),
        structureItemId: space.uniqueId,
        name: space.label,
        BTUs: space.BTUs,
        originalBTUs: space.BTUs,
        isPartition: false
      }));

      // Create a new area for the moved space
      const movedArea: Zone = {
        uniqueId: uuid(),
        name: `Zone ${zoneCount += 1}`,
        type: 'ductless',
        modified: false,
        zoneItems: [{
          uniqueId: uuid(),
          structureItemId: movedSpace.uniqueId,
          name: movedSpace.label,
          BTUs: movedSpace.BTUs,
          originalBTUs: movedSpace.BTUs,
          isPartition: false
        }]
      };

      // Add the moved area to the floor's zones
      floor.zones.push(movedArea);

      // Add the new configuration to the list of new configurations
      newConfigs.push(newConfig);
      configCount += 1;
    };

    // Iterate through each floor in the structure to get remaining spaces, then add new configuration
    structure.forEach(floor => {
      floor.sections.forEach(area => {
        if (area.spaces.length > 1) {
          for (let i = 0; i < area.spaces.length; i += 1) {
            const movedSpace = area.spaces[i];
            const remainingSpaces = area.spaces.filter((_, idx) => idx !== i);
            addConfig(floor.uniqueId, area.uniqueId, movedSpace, remainingSpaces);
          }
        }
      });
    });

    if (temporary) {
      setTemporaryConfigurations((oldConfigs) => [...oldConfigs, ...newConfigs]);
    } else {
      setConfigurations((oldConfigs) => [...oldConfigs, ...newConfigs]);
    }
  };

  const addOneCondenserPerZoneConfig = (temporary = false) => {
    const totalZones = structure.flatMap(level => level.sections).length;

    if (totalZones > 0 && totalZones <= 3) {
      const newName = 'One Condenser Per Zone Configuration';
      const existingLabels = configurations.map(c => c.name);
      let configName = newName;
      if (existingLabels.includes(configName)) {
        let configCount = 2;
        while (existingLabels.includes(`${configName} ${configCount}`)) {
          configCount += 1;
        }
        configName = `${configName} ${configCount}`;
      }

      let zoneCount = 0;
      const newConfig: Configuration = {
        uniqueId: uuid(),
        name: configName,
        type: 'ductless',
        condensers: structure
          .flatMap(level => level.sections)
          .map((section, index) => {
            zoneCount += 1;
            return {
              uniqueId: uuid(),
              name: `Condenser ${index + 1}`,
              type: 'ductless',
              validSystems: [],
              validDuctedSystems: [],
              rankedSystems: [],
              rankedDuctedSystems: [],
              modified: false,
              zones: [{
                uniqueId: uuid(),
                name: `Zone ${zoneCount}`,
                type: 'ductless',
                modified: false,
                zoneItems: section.spaces
                  .filter((s) => s.BTUs.heating || s.BTUs.cooling)
                  .map((space) => ({
                    uniqueId: uuid(),
                    structureItemId: space.uniqueId,
                    name: space.label,
                    BTUs: space.BTUs,
                    originalBTUs: space.BTUs,
                    isPartition: false
                  }))
              }]
            };
          }),
        valid: false,
        modified: false
      };

      if (temporary) {
        setTemporaryConfigurations((oldConfigs) => [...oldConfigs, newConfig]);
      } else {
        setConfigurations((oldConfigs) => [...oldConfigs, newConfig]);
      }
    }
  };

  // TODO: Move to helpers - extracts spaces from a structure
  const extractSpaces = (levels): Space[] => {
    const allSpaces: Space[] = [];
    levels.forEach(level => {
      level.sections.forEach(section => {
        const spaces = section.spaces.filter(space => space.BTUs.heating || space.BTUs.cooling);
        allSpaces.push(...spaces);
      });
    });
    return allSpaces;
  };

  // TODO: Move to helpers - helper function to recursively generate partitions of spaces
  function generatePartitions(set: Space[]): Space[][][] {
    if (set.length === 0) return [[]]; // base case
    const first = set[0];
    const rest = set.slice(1);
    const partitionsWithoutFirst = generatePartitions(rest); // recursive call
    const allPartitions: Space[][][] = [];

    partitionsWithoutFirst.forEach(partition => {
      for (let i = 0; i < partition.length; i += 1) {
        const newPartition = [...partition];
        newPartition[i] = [...partition[i], first]; // Insert `first` into an existing subset
        allPartitions.push(newPartition);
      }
      allPartitions.push([[first], ...partition]); // Create a new subset with `first`
    });

    return allPartitions;
  }

  // TODO: Move to helpers - Generate all possible partitions of spaces into zones
  const generateZoneCombinations = (spaces: Space[], maxZones: number): Zone[][] => {
    const allPartitions = generatePartitions(spaces);
    const allCombinations: Zone[][] = [];

    allPartitions.forEach(partition => {
      if (partition.length <= maxZones) {
        const zones: Zone[] = partition.map((zoneSpaces, index) => ({
          uniqueId: uuid(),
          name: `Zone ${index + 1}`,
          type: 'ductless',
          modified: false,
          zoneItems: zoneSpaces.map(space => ({
            uniqueId: uuid(),
            structureItemId: space.uniqueId,
            name: space.label,
            BTUs: space.BTUs,
            originalBTUs: space.BTUs,
            isPartition: false
          }))
        }));
        allCombinations.push(zones);
      }
    });

    return allCombinations;
  };

  // Creates every possible combination of zones
  const addAreaComboConfigurations = (temporary = false) => {
    let configCount = configurations.length + 1;
    const newConfigs: Configuration[] = [];

    // Iterate through each level in the structure
    structure.forEach(level => {
      // Extract spaces for the current level
      const levelSpaces = extractSpaces([level]);

      // Generate all possible zone combinations for the current level's spaces
      const maxZones = Math.min(levelSpaces.length, 5); // Adjust max zones according to your logic
      const zoneCombinations = generateZoneCombinations(levelSpaces, maxZones);

      // Iterate through each area in the level and add new configurations based on the zone combinations
      level.sections.forEach(area => {
        zoneCombinations.forEach(zoneCombination => {
          const newConfig: Configuration = {
            uniqueId: uuid(),
            name: `Alternative Combo Configuration ${configCount}`,
            type: 'ductless',
            condensers: [{
              uniqueId: level.uniqueId,
              name: `Condenser ${configCount}`,
              type: 'ductless',
              validSystems: [],
              validDuctedSystems: [],
              rankedSystems: [],
              rankedDuctedSystems: [],
              modified: false,
              zones: level.sections.map(section => {
                if (section.uniqueId === area.uniqueId) {
                  return zoneCombination;
                }
                return [{
                  uniqueId: uuid(),
                  name: `Zone ${uuid()}`,
                  type: 'ductless',
                  modified: false,
                  zoneItems: section.spaces.filter(space => space.BTUs.heating || space.BTUs.cooling).map(space => ({
                    uniqueId: uuid(),
                    structureItemId: space.uniqueId,
                    name: space.label,
                    BTUs: space.BTUs,
                    originalBTUs: space.BTUs,
                    isPartition: false
                  }))
                }] as Zone[];
              }).flat()
            }],
            valid: false,
            modified: false
          };

          if (newConfig.condensers.length > 0) {
            newConfigs.push(newConfig);
            configCount += 1;
          }
        });
      });
    });

    if (temporary) {
      setTemporaryConfigurations((oldConfigs) => [...oldConfigs, ...newConfigs]);
    } else {
      setConfigurations((oldConfigs) => [...oldConfigs, ...newConfigs]);
    }
  };

  const addSplitZoneConfigurations = (temporary = false) => {
    let configCount = configurations.length + 1;
    const newConfigs: Configuration[] = [];
    structure.forEach(level => {
      level.sections.forEach(section => {
        if (section.spaces.length > 1) {
          // For each section with multiple spaces, create a new configuration
          const configName = `Split Zone Configuration ${configCount}`;
          let zoneCount = 0;
          const newConfig: Configuration = {
            uniqueId: uuid(),
            name: configName,
            type: 'ductless',
            valid: false,
            modified: false,
            condensers: structure.filter(lvl => lvl.sections.length > 0).map((lvl, levelIndex) => ({
              uniqueId: uuid(),
              name: `Condenser ${levelIndex + 1}`,
              type: 'ductless',
              validSystems: [],
              validDuctedSystems: [],
              rankedSystems: [],
              rankedDuctedSystems: [],
              modified: false,
              zones: lvl.sections.flatMap((sec) => {
                if (sec.uniqueId === section.uniqueId) {
                  // For this section, split the spaces into individual zones
                  return section.spaces.map((space) => {
                    zoneCount += 1;
                    return {
                      uniqueId: uuid(),
                      name: `Zone ${zoneCount}`,
                      type: 'ductless',
                      modified: false,
                      zoneItems: [{
                        uniqueId: uuid(),
                        structureItemId: space.uniqueId,
                        name: space.label,
                        BTUs: space.BTUs,
                        originalBTUs: space.BTUs,
                        isPartition: false
                      }]
                    };
                  });
                }
                // For other sections, keep them as a single zone
                zoneCount += 1;
                return [{
                  uniqueId: uuid(),
                  name: `Zone ${zoneCount}`,
                  type: 'ductless',
                  modified: false,
                  zoneItems: sec.spaces.map((space) => ({
                    uniqueId: uuid(),
                    structureItemId: space.uniqueId,
                    name: space.label,
                    BTUs: space.BTUs,
                    originalBTUs: space.BTUs,
                    isPartition: false
                  }))
                }];
              })
            }))
          };
          newConfigs.push(newConfig);
          configCount += 1;
        }
      });
    });
    if (temporary) {
      setTemporaryConfigurations((oldConfigs) => [...oldConfigs, ...newConfigs]);
    } else {
      setConfigurations((oldConfigs) => [...oldConfigs, ...newConfigs]);
    }
  };

  const hasMultipleZoneItems = structure.some(level =>
    level.sections.some(section =>
      section.spaces.length > 1
    )
  );

  function delay(ms) {
    return new Promise(resolve => {
      setTimeout(resolve, ms);
    });
  }

  async function generateTempConfigs() {
    const existingTempConfigNames = temporaryConfigurations.map(c => c.name);

    if (!existingTempConfigNames.includes('Layout Based Configuration')) {
      addLayoutBasedConfig(true);
      await delay(100);
    }

    if (!existingTempConfigNames.includes('Zone-Per-Space Configuration')) {
      addIndividualZonesConfig(true);
      await delay(100);
    }

    if (structure.flatMap(level => level.sections).length <= 5 &&
      !existingTempConfigNames.includes('Single Condenser Configuration')) {
      addSingleCondenserConfig(true);
      await delay(100);
    }

    if (
      ['Full Ductwork', 'Partial Ductwork'].includes(projectSettings.find(ps => ps.code === 'ductwork')?.value) &&
      !partialStructure &&
      !existingTempConfigNames.includes('Ducted Configuration')
    ) {
      if (existingSystems.length > 0) {
        addDuctedConfigWithReplacementSystem(true);
      }
      await delay(100);
    }

    if (hasMultipleZoneItems && !existingTempConfigNames.includes('Split Zone Configuration')) {
      addSplitZoneConfigurations(true);
      await delay(100);
    }

    const hasMultiSpaceArea = structure.some(floor =>
      floor.sections.some(area => area.spaces.length > 1)
    );
    if (hasMultiSpaceArea) {
      addAreaConfigurations(true);
      await delay(100);
      // addAreaComboConfigurations(true);
      // await delay(100);
    }

    setTempConfigsGenerated(true);
  }

  // temporary ranking here
  useEffect(() => {
    if (configurationOptions.length === 0 && temporaryConfigurationsPermutations.length > 0) {
      console.log({ temporaryConfigurationsPermutations });
      const rankedTempConfigPermutations: Configuration[] = rankConfigsNew(
        temporaryConfigurationsPermutations,
        customerValues,
        structure,
        items,
        configRankingModifiers
      );
      console.log({ rankedTempConfigPermutations });
      setTemporaryConfigurationsPermutationsRanked(rankedTempConfigPermutations);
      setAllConfigurationOptions(rankedTempConfigPermutations);
      setConfigurationOptions(rankedTempConfigPermutations.slice(0, 3));
    }
  }, [temporaryConfigurationsPermutations]);

  useEffect(() => {
    if (!generateTempConfigsCalled.current && configurations.length === 0) {
      generateTempConfigsCalled.current = true;
      generateTempConfigs();
    }
  }, [partialStructure, structure, configurations]);

  // Condensed version of the DND code which validates configurations at each level of the data structure
  const validateConfigurations = (
    tempConfigurations: Configuration[],
    BTULimitsData: number[][],
    airHandlerTypesData: AirHandlerType[],
    tempStructure: Level[],
    itemsData: Item[]
  ) => {
    const updatedConfigurations: Configuration[] = tempConfigurations.map((config) => {
      let configErrorMessage = '';
      let configIsValid = true;

      const updatedCondensers = config.condensers.map((condenser) => {
        let condenserIsValid = true;
        let condenserErrorMessage: string | null = null;

        const zoneCoolingBTUs = condenser.zones.map(zone => zone.zoneItems.reduce((acc, item) => acc + item.BTUs.cooling, 0)).sort((a, b) => b - a);
        const zoneHeatingBTUs = condenser.zones.map(zone => zone.zoneItems.reduce((acc, item) => acc + item.BTUs.heating, 0)).sort((a, b) => b - a);
        if (
          BTULimitsData.length > 0 &&
          !BTULimitsData
            .filter(l => l.length === condenser.zones.length)
            .find(l => l.every((limit, i) => zoneCoolingBTUs[i] <= limit && zoneHeatingBTUs[i] <= limit))
        ) {
          condenserIsValid = false;
          condenserErrorMessage = 'Total BTUs too high (Try adding more zones or condensers)';
        }
        if (condenser.zones.length < 1) {
          condenserIsValid = false;
          condenserErrorMessage = 'Condensers must have at least one zone';
        }
        if (condenser.zones.some(zone => zone.zoneItems.length < 1 && !zone.existingSystemId)) {
          condenserIsValid = false;
          condenserErrorMessage = 'One or more zones are empty';
        }
        if (condenser.zones.length > 5) {
          condenserIsValid = false;
          condenserErrorMessage = 'Condensers can only have up to 5 zones';
        } else if (condenser.zones.length <= 5) {
          condenserIsValid = true;
        }
        if (condenser.validSystemsFetched && condenser.validSystems.length < 1 && condenser.validDuctedSystems.length < 1) {
          condenserIsValid = false;
          condenserErrorMessage = 'No valid systems found for this condenser';
        }

        const updatedZones = condenser.zones.map((zone) => {
          const restrictedAirHandlerTypes = getRestrictedAirHandlerTypes(zone, tempStructure, itemsData, config.type === 'ducted');
          let zoneIsValid = true;
          let zoneErrorMessage: string | null = null;
          const uniqueStructureItemIds = Array.from(new Set(zone.zoneItems.map(i => i.structureItemId)));
          const uniqueSectionIds = Array.from(new Set(zone.zoneItems.map(i => findSectionBySpaceId(tempStructure, i.structureItemId)?.uniqueId)));
          const allStructureItems = zone.zoneItems.map((zoneItem) => findSpaceById(tempStructure, zoneItem.structureItemId)).filter(space => space) as Space[];

          zone.zoneItems.forEach((zoneItem) => {
            const structureItem = findSpaceById(tempStructure, zoneItem.structureItemId);
            if (structureItem) {
              if (uniqueSectionIds.length > 1) {
                zoneIsValid = false;
                zoneErrorMessage = 'All spaces within a zone must be in the same area';
                return;
              }
              if (structureItem.attributes.find(a => a.code === 'zone_control')?.value && uniqueStructureItemIds.length > 1) {
                zoneIsValid = false;
                zoneErrorMessage = 'Spaces that require zone control must be by themselves';
                return;
              }
              if (
                structureItem.attributes.find(a => a.code === 'share_air')?.value !== 'Open Floor Plan' &&
                structureItem.attributes.find(a => a.code === 'share_air')?.value !== 'Sharing Air With Door Open' &&
                !allStructureItems.every(item => item.attributes.find(a => a.code === 'space_above')?.value === 'Yes') &&
                !allStructureItems.every(item => item.attributes.find(a => a.code === 'space_below')?.value === 'Yes') &&
                uniqueStructureItemIds.length > 1
              ) {
                zoneIsValid = false;
                zoneErrorMessage = 'Spaces that cannot share air must be by themselves';
                return;
              }
              if (
                structureItem.attributes.find(a => a.code === 'share_air')?.value !== 'Open Floor Plan' &&
                structureItem.attributes.find(a => a.code === 'share_air')?.value !== 'Sharing Air With Door Open' &&
                (
                  allStructureItems.every(item => item.attributes.find(a => a.code === 'space_above')?.value === 'Yes') ||
                  allStructureItems.every(item => item.attributes.find(a => a.code === 'space_below')?.value === 'Yes')
                ) &&
                uniqueStructureItemIds.length > 1 &&
                restrictedAirHandlerTypes.includes('concealed')
              ) {
                zoneIsValid = false;
                zoneErrorMessage = 'This zone can only use a multi room air handler but there are no systems with a multi room air handler available';
              }
            } else {
              console.error(`Item "${zoneItem.name}" not found on structure.`);
            }
          });

          if (zone.zoneItems.length === 0 && !zone.existingSystemId) {
            zoneIsValid = false;
            zoneErrorMessage = 'Zone cannot be empty';
          }
          if (restrictedAirHandlerTypes.length === airHandlerTypesData.length) {
            zoneIsValid = false;
            zoneErrorMessage = 'No valid air handler types found for this zone';
          }
          const updatedZoneItems = zone.zoneItems.map((zoneItem) => {
            let zoneItemIsValid = true;
            let zoneItemErrorMessage: string | null = null;
            const structureItem = findSpaceById(tempStructure, zoneItem.structureItemId);
            const dbItem = itemsData.find((i) => i.id === structureItem?.itemId);

            if (structureItem) {
              const uniqueStructureItemIdsArr = Array.from(new Set(zone.zoneItems.map(i => i.structureItemId)));
              if (!dbItem?.canBeAlone && uniqueStructureItemIdsArr.length === 1) {
                zoneItemIsValid = false;
                zoneItemErrorMessage = 'This space should not be by itself in a zone';
              }
              if (
                structureItem.attributes.find(a => a.code === 'share_air')?.value !== 'Open Floor Plan' &&
                structureItem.attributes.find(a => a.code === 'share_air')?.value !== 'Sharing Air With Door Open' &&
                structureItem.attributes.find(a => a.code === 'space_above')?.value !== 'Yes' &&
                structureItem.attributes.find(a => a.code === 'space_below')?.value !== 'Yes' &&
                uniqueStructureItemIdsArr.length > 1
              ) {
                zoneItemIsValid = false;
                zoneItemErrorMessage = 'This space cannot share air with other spaces';
              }
              if (structureItem.attributes.find(a => a.code === 'zone_control')?.value && uniqueStructureItemIdsArr.length > 1) {
                zoneItemIsValid = false;
                zoneItemErrorMessage = 'This space must be by itself (zone control required)';
              }
              if (zone.zoneItems.filter(i => i.structureItemId === zoneItem.structureItemId).length > 1) {
                zoneItemIsValid = false;
                zoneItemErrorMessage = 'Cannot be in the same zone';
              }
            } else {
              console.error(`ZoneItem "${zoneItem.name}" not found on configurations.`);
            }

            return { ...zoneItem, valid: zoneItemIsValid, errorMessage: zoneItemErrorMessage };
          });

          if (!zoneIsValid) {
            condenserIsValid = false;
          }

          return { ...zone, valid: zoneIsValid, errorMessage: zoneErrorMessage, zoneItems: updatedZoneItems };
        });

        if (!condenserIsValid) {
          configIsValid = false;
        }

        return { ...condenser, valid: condenserIsValid, errorMessage: condenserErrorMessage, zones: updatedZones };
      });

      if (config.condensers.length > 0 && config.condensers.every(cond => cond.validSystemsFetched) && !config.condensers.every(cond => cond.validSystems.length > 0 || cond.validDuctedSystems.length > 0)) {
        configIsValid = false;
        configErrorMessage += 'One or more condensers have no valid systems.';
      }

      if (config.name === 'Single Condenser Configuration' && config.condensers.length > 1) {
        configIsValid = false;
        configErrorMessage += 'Single Condenser Configuration should not have multiple condensers.';
      }

      if (!configIsValid) {
        configErrorMessage += 'Invalid Configuration.';
      }

      return { ...config, valid: configIsValid, errorMessage: configErrorMessage, condensers: updatedCondensers };
    });

    return updatedConfigurations;
  };

  // Automatically fix invalid zones and condensers
  const fixInvalidConfigs = (tempConfigs: Configuration[]) => tempConfigs.map(config => {
    if (!config.valid) {
      console.log('FIXING INVALID CONFIGS');
      const newCondensers: Condenser[] = [];

      config.condensers.forEach(condenser => {
        let zones = [...condenser.zones];

        // First separate invalid zone items into their own zone
        zones = zones.reduce((acc: Zone[], zone) => {
          if (!zone.valid && zone.zoneItems.length > 1) {
            const newZones = zone.zoneItems.slice(1).map(item => ({
              ...zone,
              uniqueId: uuid(),
              name: `Zone ${acc.length + 1}`,
              zoneItems: [item],
              valid: true
            }));
            const modifiedOriginalZone: Zone = {
              ...zone,
              zoneItems: [zone.zoneItems[0]],
              valid: true
            };
            acc.push(modifiedOriginalZone, ...newZones);
          } else if (zone.zoneItems.length > 0) {
            acc.push(zone);
          }
          return acc;
        }, []);

        // Ensure no condenser has more than 5 zones, split if necessary
        while (zones.length > 5) {
          const newCondenserZones = zones.splice(5);
          const newCondenser: Condenser = {
            ...condenser,
            uniqueId: uuid(),
            name: `Condenser ${newCondensers.length + config.condensers.length + 1}`,
            zones: newCondenserZones,
            validSystems: [],
            rankedSystems: [],
            modified: false
          };
          newCondensers.push(newCondenser);
        }
        newCondensers.unshift({
          ...condenser,
          zones
        });
      });

      return {
        ...config,
        condensers: newCondensers.filter(cond => cond.zones.length > 0),
      };
    }
    return config;
  });

  // Check temp configs, if any invalid try to fix them then revalidate 
  useEffect(() => {
    console.log('REVALIDATING TEMP CONFIGS');
    if (tempConfigsGenerated === true) {
      console.log({ temporaryConfigurations });
      const validatedConfigs = validateConfigurations(temporaryConfigurations, BTULimits, airHandlerTypes, structure, items);
      const updatedConfigurations = validatedConfigs.map(config => {
        if (!config.valid) {
          const newConfig = fixInvalidConfigs([config])[0];
          const revalidatedConfig = validateConfigurations([newConfig], BTULimits, airHandlerTypes, structure, items)[0];
          return revalidatedConfig;
        }
        return config;
      });
      setTemporaryConfigurations(updatedConfigurations);
      setTempConfigsFixed(true);
    }
  }, [tempConfigsGenerated]);

  return (
    <DndProvider backend={HTML5Backend}>
      <div
        ref={animate}
        className='mx-auto flex max-w-[1400px] flex-col gap-5'
      >
        <div className='flex justify-between'>
          <div className='text-3xl font-bold text-gray-900'>Configurations</div>
          <div className='flex gap-5'>
            <div className='flex items-center gap-2 rounded-lg bg-orange-200 px-5 py-3'>
              <span className='text-xs font-semibold uppercase text-gray-600'>
                Total Heating BTU
              </span>
              <span className='text-sm font-bold text-gray-800'>
                {totalBTUs.heating.toLocaleString()}
              </span>
            </div>
            <div className='flex items-center gap-2 rounded-lg bg-blue-200 px-5 py-3'>
              <span className='text-xs font-semibold uppercase text-gray-600'>
                Total Cooling BTU
              </span>
              <span className='text-sm font-bold text-gray-800'>
                {totalBTUs.cooling.toLocaleString()}
              </span>
            </div>
          </div>
        </div>
        {configurations.length > 0 && (
          <div className='flex flex-wrap gap-3'>
            {configurations.map((config) => (
              <ConfigOptionComponent
                key={config.uniqueId}
                config={config}
              />
            ))}
          </div>
        )}
        <div className="w-full flex gap-5">
          {configurationOptions.map((config) => (
            <ConfigurationComponent
              key={config.uniqueId}
              config={config}
            />
          ))}
          {configurationOptions.length < 3 && Array.from({ length: 3 - configurationOptions.length }, (v, i) => i + 1).map((index) => (
            <div key={index} className='flex flex-col gap-5 grow shrink-0 basis-0 max-w-[35%] min-h-[300px]'>
              <button
                className='flex grow shrink-0 basis-0 items-center justify-center rounded-lg border-2 border-gray-300 border-dashed cursor-pointer hover:bg-gray-200'
                onClick={() => setConfigurationOptions((oldOptions) => [...oldOptions, createEmptyConfig()])}
              >
                <span className='flex items-center gap-1'>
                  <PlusIcon className='h-7 w-7 text-gray-400' />
                  <span className='text-gray-400 text-lg font-semibold'>New Ductless Option</span>
                </span>
              </button>
              <button
                className='flex grow shrink-0 basis-0 items-center justify-center rounded-lg border-2 border-gray-300 border-dashed cursor-pointer hover:bg-gray-200'
                onClick={() => setConfigurationOptions((oldOptions) => [...oldOptions, createDuctedConfig()])}
              >
                <span className='flex items-center gap-1'>
                  <PlusIcon className='h-7 w-7 text-gray-400' />
                  <span className='text-gray-400 text-lg font-semibold'>New Ducted Option</span>
                </span>
              </button>
              <button
                className='flex grow shrink-0 basis-0 items-center justify-center rounded-lg border-2 border-gray-300 border-dashed cursor-pointer hover:bg-gray-200'
                onClick={() => setConfigurationOptions((oldOptions) => [...oldOptions, createDuctedConfig(true)])}
              >
                <span className='flex items-center gap-1'>
                  <PlusIcon className='h-7 w-7 text-gray-400' />
                  <span className='text-gray-400 text-lg font-semibold'>New Hybrid Option</span>
                </span>
              </button>
            </div>
          ))}
        </div>
      </div>
      <ConfigurationModal
        openState={configurationModalState}
        temporaryConfigurations={temporaryConfigurations}
        setTemporaryConfigurations={setTemporaryConfigurations}
        temporaryConfigurationsPermutations={temporaryConfigurationsPermutations}
        setTemporaryConfigurationsPermutations={setTemporaryConfigurationsPermutations}
      />
    </DndProvider>
  );
}
