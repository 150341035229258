import React, { useContext, useState, useEffect, useRef } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ConfigurationContext, ProjectContext, DataContext, UIContext } from 'data/context';
import { warningModal, removeNullishValues, errorModal } from 'utils/helpers';
import Map from 'components/google-map';
import DnDPanel from 'components/layout/dnd-panel';
import AttributeModal from 'components/modals/attribute-modal';
import StructurePanel from 'components/dnd/structure-panel';
import { Level, Space } from 'data/types';
import InternalNote from 'components/internal-note';
import { createLevel, createSection, createSpace, findStructureItemById } from 'utils/structure';
import StructureModal from 'components/modals/structure-modal';
import axios from 'utils/api';
import OpenAI from 'openai';
import SimpleStructurePanel from 'components/dnd/simple-structure-panel';

export default function StructureLayout() {
  const {
    structure,
    setStructure,
    selectedSystems
  } = useContext(ConfigurationContext);
  const {
    propertySummary,
    attomData,
    totalBTUs,
    projectSettings,
    structureSubtypeSelected,
    propertyLocation,
    locationData,
    showDuctlessOptions
  } = useContext(ProjectContext);
  const {
    validItems,
    items,
    structureTypes
  } = useContext(DataContext);
  const {
    activeItem,
    setActiveItem,
    estimatingStructure,
    setEstimatingStructure,
    setEasyMode
  } = useContext(UIContext);
  const attributeModalState = useState(false);
  const [, setAttributeModalOpen] = attributeModalState;
  const structureModalState = useState(true);
  const [structureModalOpen] = structureModalState;
  const projectSettingsRef = useRef(projectSettings);
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    setEasyMode(structureModalOpen);
    if (!structureModalOpen && structure.length && !isFirstRender) {
      setStructure(draft => {
        draft.forEach(level => {
          level.sections.forEach(section => {
            section.spaces.forEach(space => {
              // eslint-disable-next-line no-param-reassign
              space.valid = false;
              // eslint-disable-next-line no-param-reassign
              space.recalculateBTUs = true;
            });
          });
        });
      });
    }
    setIsFirstRender(false);
  }, [structureModalOpen]);

  useEffect(() => {
    if (activeItem !== null) {
      setAttributeModalOpen(true);
    }
    if (activeItem && selectedSystems.length > 0) {
      warningModal('Changing this space will reset all selected products and systems!');
    }
  }, [activeItem]);

  useEffect(() => {
    if (activeItem) {
      const item = findStructureItemById(structure, activeItem.uniqueId);
      if (item) {
        setActiveItem({ ...activeItem, attributes: item.attributes });
      }
    }
  }, [JSON.stringify(structure.map(level => ({ attributes: level.attributes, sections: level.sections.map(section => ({ spaces: section.spaces.map(space => ({ attributes: space.attributes })) })) })))]);

  useEffect(() => {
    projectSettingsRef.current = projectSettings;
  }, [projectSettings]);

  useEffect(() => {
    setStructure((draft) => {
      const updateRelevantAttributes = (item: Level | Space) => {
        if (!item.modified) {
          item.attributes.forEach((att) => {
            const setting = projectSettings.find((s) => s.id === att.id);
            if (setting?.inheritable) {
              // eslint-disable-next-line no-param-reassign
              att.value = setting.value;
            }
          });
        }
      };
      draft.forEach((level) => {
        updateRelevantAttributes(level);
        level.sections.forEach((section) => {
          section.spaces.forEach((space) => {
            updateRelevantAttributes(space);
          });
        });
      });
    });
  }, [projectSettings]);

  useEffect(() => {
    const levelItem = items.find((i) => i.code === 'level');
    const atticItem = items.find((i) => i.code === 'attic');
    const basementItem = items.find((i) => i.code === 'basement');
    const crawlspaceItem = items.find((i) => i.code === 'crawlspace');
    if (levelItem && atticItem && basementItem && crawlspaceItem) {
      setStructure((draft) => {
        let levelCount = draft.filter((l) => l.itemId === levelItem.id).length;
        draft.forEach((level, levelIndex) => {
          const hasSpaceAbove = levelIndex > 0 && [atticItem.id, crawlspaceItem.id].includes(draft[levelIndex - 1].itemId);
          const hasSpaceBelow = levelIndex < draft.length - 1 && [basementItem.id, crawlspaceItem.id].includes(draft[levelIndex + 1].itemId);
          level.sections.forEach((section) => {
            section.spaces.filter(space => space.attributes.some(att => att.code === 'square_footage') && space.attributes.some(att => att.code === 'ceiling_height')).forEach((space) => {
              space.attributes.forEach((att) => {
                if (att.code === 'space_above' && !att.modified && att.value !== (hasSpaceAbove ? 'Yes' : '')) {
                  // eslint-disable-next-line no-param-reassign
                  att.value = hasSpaceAbove ? 'Yes' : '';
                  // eslint-disable-next-line no-param-reassign
                  space.recalculateBTUs = true;
                  // eslint-disable-next-line no-param-reassign
                  space.valid = false;
                }
                if (att.code === 'space_below' && !att.modified && att.value !== (hasSpaceBelow ? 'Yes' : '')) {
                  // eslint-disable-next-line no-param-reassign
                  att.value = hasSpaceBelow ? 'Yes' : '';
                  // eslint-disable-next-line no-param-reassign
                  space.recalculateBTUs = true;
                  // eslint-disable-next-line no-param-reassign
                  space.valid = false;
                }
              });
            });
          });
          if (level.itemId === levelItem.id && !level.modified) {
            levelCount -= 1;
            // eslint-disable-next-line no-param-reassign
            level.label = `${levelItem.label} ${levelCount + 1}`;
          }
        });
      });
    } else {
      console.error('Level, attic, basement, or crawlspace not found');
    }
  }, [
    JSON.stringify(structure.map((l) => ({ id: l.uniqueId, sections: l.sections.map((s) => ({ id: s.uniqueId, spaces: s.spaces.map((sp) => sp.uniqueId) })) })))
  ]);

  const estimateDefaultStructure = async () => {
    setEstimatingStructure(true);
    if (attomData) {
      const validItemIds = validItems.find(
        (vi) => vi.structureSubtypeId === structureSubtypeSelected
      )?.itemIds || [];
      const itemList = items.filter((i) => validItemIds.includes(i.id)).filter(i => i.code !== 'bathroom').map((i) => ({ id: i.id, label: i.label }));
      const levelCount = attomData.building.summary.levels ?? 1;
      const bedroomCount = attomData.building.rooms.beds ?? 2; // if attom is missing bedroom count, default to 2
      const totalRoomCount = attomData.building.rooms.roomsTotal ?? bedroomCount + 2; // if attom missing total rooms, adding 2 extra rooms for kitchen and living room
      const totalSize = attomData.building.size.livingSize ?? projectSettingsRef.current.find((attr) => attr.code === 'square_footage')?.value ?? 1500;
      const hasAttic = (attomData.building.size.atticSize ?? 0) > 0;
      const hasBasement = (attomData.building.interior.bsmtSize ?? 0) > 0;
      const customAttomData = {
        location: {
          country: attomData.address.country,
          address: attomData.address.oneLine,
          zipCode: attomData.address.postal1,
          county: attomData.area.countrySecSubd,
        },
        summary: {
          propertyClass: attomData.summary.propClass,
          yearBuilt: attomData.summary.yearBuilt,
        },
        building: {
          hasAttic: (attomData.building.size.atticSize ?? 0) > 0,
          hasBasement: (attomData.building.interior.bsmtSize ?? 0) > 0
        }
      };
      const exampleLevelItem = items.find((i) => i.code === 'level');
      const exampleAtticItem = items.find((i) => i.code === 'attic');
      const exampleBasementItem = items.find((i) => i.code === 'basement');
      const exampleBedroomItem = items.find((i) => i.code === 'bedroom');
      const exampleKitchenItem = items.find((i) => i.code === 'kitchen');
      const exampleDiningRoomItem = items.find((i) => i.code === 'dining_room');
      const exampleLivingRoomItem = items.find((i) => i.code === 'living_room');
      const exampleFamilyRoomItem = items.find((i) => i.code === 'family_room');
      const exampleStructure = [
        {
          "itemId": exampleAtticItem?.id ?? 1,
          "label": "Attic",
          "sections": [],
        },
        {
          "itemId": 2,
          "label": "Level 2",
          "sections": [
            {
              "label": "Area 3",
              "spaces": [
                {
                  "label": "Primary Bedroom",
                  "itemId": exampleBedroomItem?.id ?? 5,
                  "size": 210,
                }
              ],
            },
            {
              "label": "Area 4",
              "spaces": [
                {
                  "label": "Bedroom 2",
                  "itemId": exampleBedroomItem?.id ?? 5,
                  "size": 150,
                }
              ],
            },
            {
              "label": "Area 5",
              "spaces": [
                {
                  "label": "Bedroom 3",
                  "itemId": exampleBedroomItem?.id ?? 5,
                  "size": 150,
                }
              ],
            },
            {
              "label": "Area 6",
              "spaces": [
                {
                  "label": "Bedroom 4",
                  "itemId": exampleBedroomItem?.id ?? 5,
                  "size": 150,
                }
              ],
            }
          ],
        },
        {
          "itemId": exampleLevelItem?.id ?? 2,
          "label": "Level 1",
          "sections": [
            {
              "label": "Area 1",
              "spaces": [
                {
                  "label": "Kitchen",
                  "itemId": exampleKitchenItem?.id ?? 6,
                  "size": 135,
                },
                {
                  "label": "Dining Room",
                  "itemId": exampleDiningRoomItem?.id ?? 7,
                  "size": 155,
                }
              ],
            },
            {
              "label": "Area 2",
              "spaces": [
                {
                  "label": "Living Room",
                  "itemId": exampleLivingRoomItem?.id ?? 8,
                  "size": 280,
                },
                {
                  "label": "Family Room",
                  "itemId": exampleFamilyRoomItem?.id ?? 9,
                  "size": 220,
                }
              ],
            }
          ],
        },
        {
          "itemId": exampleBasementItem?.id ?? 3,
          "label": "Basement",
          "sections": [],
        },
      ];
      const messages: OpenAI.Chat.Completions.ChatCompletionMessageParam[] = [
        {
          role: 'system',
          content: `
            I generate a default structure layout with estimated room sizes for an HVAC system based on the property data provided by the user.
            I always respond with a JSON object in this example format (sample 2250 sqft structure) : ${JSON.stringify({ structure: exampleStructure })}.
            Note that in the example format higher levels (like attic) appear first in the array, and lower levels (like basement) appear last. 
            Your response should likewise have higher levels first and lower levels last.
            I only respond with unformatted JSON and nothing else. 
            ${totalRoomCount > 0 ? `The structure has ${levelCount} levels, ${bedroomCount} bedrooms, and ${totalRoomCount} total rooms.` : `The structure has ${levelCount} levels`}
            ${totalRoomCount > 0 ? `
              My response should include ${bedroomCount} bedrooms, and ${totalRoomCount - bedroomCount} other spaces from the following list of items, "items": ${JSON.stringify(itemList)}. 
              I ALWAYS include ${totalRoomCount} total spaces in the structure.
            ` : `My response should only include spaces from the following list of items, "items": ${JSON.stringify(itemList)}.`
            }
            I choose spaces from that list of "items" that would typically complete the user provided structure. 
            All sections should use the "section" item from the "items" list provided and should be labeled as "areas".
            I always separate bedrooms into separate areas/sections as they don't share open air space, while living rooms and kitchens are in the same area/section because they do share open air space.
            Bedrooms, primary bedrooms, and all spaces should be in separate sections/areas unless they share open air. 
            I group spaces that are near each other and share open air together in the same area/section, and I always separate spaces that don't share air into separate area/sections. 
            I do not include more than three spaces in a single area/section. 
            If the structure has an attic it should always be the first level and if it has a basement it should always be the last level in your response.
            For the room size estimations, I consider the room sizing of nearby similar properties in the user's area and how many square feet these rooms tend to be on average for similar properties.
            Room sizes take up no more than 70% of the user provided total home size. 
          `
        },
        {
          role: 'user',
          content: `
            Here is my property data, please generate a default structure based on this data: ${JSON.stringify(customAttomData)}.
            Please always respond with a JSON object in this example format (sample 2250 sqft structure) : ${JSON.stringify({ structure: exampleStructure })}.
            Only respond with unformatted JSON and nothing else.
            The structure ${hasAttic ? 'has' : 'does not have'} an attic and ${hasBasement ? 'has' : 'does not have'} a basement.           
            ${totalRoomCount > 0 ? `The structure has ${levelCount} levels, ${bedroomCount} bedrooms, and ${totalRoomCount} total rooms.` : `The structure has ${levelCount} levels`}
            ${totalRoomCount > 0 ? `
              Your response should include ${bedroomCount} bedrooms, and ${totalRoomCount - bedroomCount} other spaces from the following list of items, "items": ${JSON.stringify(itemList)}. 
              ALWAYS include ${totalRoomCount} total spaces in the structure.
            ` : `Your response should only include spaces from the following list of items, "items": ${JSON.stringify(itemList)}.`
            }
            The structure is ${totalSize} square feet with ${totalRoomCount} total rooms and ${bedroomCount} bedrooms, always use this information in your room size estimations.
            Try and choose spaces from the list of "items" that would typically complete the user provided structure.  
            All sections should use the "section" item from the "items" list provided and should be labeled as "areas". 
            Be mindful to place spaces where they would typically be found in a property (for example dining rooms should be on the first floor).
            Do not include more than three spaces in a single section. 
            Please group spaces that are near each other and share open air together in the same area/section, and always separate spaces that don't share open air into separate area/sections. 
            If the structure has an attic it should always be the first level and if it has a basement it should always be the last level in your response.
            Your room size estimations should consider similar properties in my area that are ${totalSize} square feet and how many square feet these rooms tend to be on average.
            All your room size estimations should take up no more than ${totalSize * .7} square feet, which is 70% of the total home size of ${totalSize} square feet. 
          `
        }
      ];
      if (propertyLocation) {
        messages.push({
          role: 'user',
          content: [
            {
              type: 'text',
              text: "Here is are some images of the property for your reference, if you can't see them don't worry about it."
            },
            {
              type: 'image_url',
              image_url: {
                url: `https://maps.googleapis.com/maps/api/staticmap?center=${propertyLocation.lat},${propertyLocation.lng}&zoom=20&size=500x500&maptype=satellite&markers=color:red%7C${propertyLocation.lat},${propertyLocation.lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
              }
            },
            {
              type: 'image_url',
              image_url: {
                url: `https://maps.googleapis.com/maps/api/streetview?size=500x500&location=${propertyLocation.lat},${propertyLocation.lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
              }
            }
          ]
        });
      }
      try {
        const structureSuggestion = removeNullishValues(JSON.parse((await axios.post('ai?type=json', messages)).data.content).structure);
        if (structureSuggestion) {
          console.log({
            messages: messages.map(m => typeof m.content === 'string' ? m.content.split('\n') : m.content
            ), exampleStructure, structureSuggestion, customAttomData
          });
          const newDefaultStructure = structureSuggestion.map((level) => {
            const levelItem = items.find((i) => i.id === level.itemId);
            if (levelItem) {
              const newLevel = createLevel(levelItem, {
                data: { label: level.label ?? 'Level' },
                attributesToInherit: projectSettingsRef.current
              });
              level.sections?.forEach((section) => {
                if (section) {
                  const newSection = createSection({
                    data: { label: section.label ?? 'Area' }
                  });
                  section.spaces?.forEach((space) => {
                    if (space) {
                      const spaceItem = items.find((i) => i.id === space.itemId);
                      const size = space.size ?? 0;
                      if (spaceItem) {
                        let newSpace = createSpace(spaceItem, {
                          data: { label: space.label ?? 'Space' },
                          attributesToInherit: projectSettingsRef.current
                        });
                        newSpace = {
                          ...newSpace,
                          attributes: newSpace.attributes.map(attr =>
                            attr.code === 'square_footage' ? { ...attr, value: size } : attr
                          )
                        };
                        newSection.spaces.push(newSpace);
                      } else {
                        console.error(`Space item ${space.label} not found`);
                      }
                    }
                  });
                  newLevel.sections.push(newSection);
                }
              });
              return newLevel;
            }
            console.error(`Level item ${level.label} not found`);
            return null;
          }).filter((l) => l) as Level[];
          setStructure(newDefaultStructure);
        } else {
          console.error(`No reply received`);
        }
      } catch (error) {
        console.error(error);
        errorModal('Failed to estimate default structure. Please try again.');
      }
    } else {
      const homeSize = projectSettingsRef.current.find((attr) => attr.code === 'square_footage')?.value ?? null;
      const validItemIds = validItems.find(
        (vi) => vi.structureSubtypeId === structureSubtypeSelected
      )?.itemIds || [];
      const itemList = items.filter((i) => validItemIds.includes(i.id)).filter(i => i.code !== 'bathroom').map((i) => ({ id: i.id, label: i.label }));
      const exampleLevelItem = items.find((i) => i.code === 'level');
      const exampleBedroomItem = items.find((i) => i.code === 'bedroom');
      const exampleKitchenItem = items.find((i) => i.code === 'kitchen');
      const exampleDiningRoomItem = items.find((i) => i.code === 'dining_room');
      const exampleLivingRoomItem = items.find((i) => i.code === 'living_room');
      const exampleFamilyRoomItem = items.find((i) => i.code === 'family_room');
      const exampleStructure = [
        {
          "itemId": 2,
          "label": "Level 2",
          "sections": [
            {
              "label": "Area 3",
              "spaces": [
                {
                  "label": "Primary Bedroom",
                  "itemId": exampleBedroomItem?.id ?? 5,
                  "size": 210,
                }
              ],
            },
            {
              "label": "Area 4",
              "spaces": [
                {
                  "label": "Bedroom 2",
                  "itemId": exampleBedroomItem?.id ?? 5,
                  "size": 150,
                }
              ],
            },
            {
              "label": "Area 5",
              "spaces": [
                {
                  "label": "Bedroom 3",
                  "itemId": exampleBedroomItem?.id ?? 5,
                  "size": 150,
                }
              ],
            },
            {
              "label": "Area 6",
              "spaces": [
                {
                  "label": "Bedroom 4",
                  "itemId": exampleBedroomItem?.id ?? 5,
                  "size": 150,
                }
              ],
            }
          ],
        },
        {
          "itemId": exampleLevelItem?.id ?? 2,
          "label": "Level 1",
          "sections": [
            {
              "label": "Area 1",
              "spaces": [
                {
                  "label": "Kitchen",
                  "itemId": exampleKitchenItem?.id ?? 6,
                  "size": 135,
                },
                {
                  "label": "Dining Room",
                  "itemId": exampleDiningRoomItem?.id ?? 7,
                  "size": 155,
                }
              ],
            },
            {
              "label": "Area 2",
              "spaces": [
                {
                  "label": "Living Room",
                  "itemId": exampleLivingRoomItem?.id ?? 8,
                  "size": 280,
                },
                {
                  "label": "Family Room",
                  "itemId": exampleFamilyRoomItem?.id ?? 9,
                  "size": 220,
                }
              ],
            }
          ],
        }
      ];
      const chosenStructure = structureTypes.find((st) => st.subtypes.some((sst) => sst.id === structureSubtypeSelected))?.subtypes.find((sst) => sst.id === structureSubtypeSelected)?.label;
      const messages: OpenAI.Chat.Completions.ChatCompletionMessageParam[] = [
        {
          role: 'system',
          content: `
            I generate an estimated default structure layout with estimated room sizes for an HVAC system based on the property data provided by the user below.
            I always respond with a JSON object in this format (sample 2250 sqft structure) : ${JSON.stringify({ structure: exampleStructure })}.
            Note that in the example format higher levels (like attic) appear first in the array, and lower levels (like basement) appear last. 
            Your response should likewise have higher levels first and lower levels last.
            I only respond with unformatted JSON and nothing else.            
            My response should only include spaces from the following list of items, "items": ${JSON.stringify(itemList)}
            I choose spaces from the list of "items" that would typically complete the user provided structure. 
            All sections should use the "section" item from the "items" list provided and should be labeled as "areas".
            I always separate bedrooms into separate areas/sections as they don't share open air space, while living rooms and kitchens are in the same area/section because they do share open air space.
            Bedrooms, primary bedrooms, and all spaces should be in separate sections/areas unless they share open air. 
            I group spaces that are near each other and share open air together in the same area/section, and I always separate spaces that don't share air into separate area/sections. 
            I do not include more than three spaces in a single section. 
            For the room size estimations, I consider the room sizing of nearby similar properties in the user's area and how many square feet these rooms tend to be on average for similar properties.
            ${homeSize ? 'Room sizes take up no more than 70% of the total home size' : ''}
            `
        },
        {
          role: 'user',
          content: `
            Please generate a default structure based on this data: ${JSON.stringify(locationData)}.
            My structure is a ${chosenStructure} property.
            ${homeSize ? `The structure is ${homeSize} square feet. Please adjust room sizes to take up no more than 70% of ${homeSize} square feet.` : 'The structure size is unknown.'}
            Always respond with a JSON object in this example format (sample 2250 sqft structure) : ${JSON.stringify({ structure: exampleStructure })}.
            Only respond with unformatted JSON and nothing else.   
            Your response should only include spaces from the following list of items, "items": ${JSON.stringify(itemList)}.
            Try and choose spaces from the list of "items" that would typically complete the user provided structure.  
            All sections should use the "section" item from the "items" list provided and should be labeled as "areas".
            Be mindful to place spaces where they would typically be found in a property (for example dining rooms should be on the first floor).
            Please group spaces that are near each other and share open air together in the same area/section, and always separate spaces that don't share open air into separate area/sections. 
            Do not include more than three spaces in a single section.
            ${homeSize ? `All your room sizes estimates should take up no more than ${homeSize * .7} square feet, which is 70% of the total home size of ${homeSize} square feet.` : 'Your room size estimations should consider similar properties in my area and how many square feet these rooms tend to be on average.'}            
          `
        }
      ];
      if (propertyLocation) {
        messages.push({
          role: 'user',
          content: [
            {
              type: 'text',
              text: "Here is are some images of the property for your reference, if you can't see them don't worry about it."
            },
            {
              type: 'image_url',
              image_url: {
                url: `https://maps.googleapis.com/maps/api/staticmap?center=${propertyLocation.lat},${propertyLocation.lng}&zoom=20&size=500x500&maptype=satellite&markers=color:red%7C${propertyLocation.lat},${propertyLocation.lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
              }
            },
            {
              type: 'image_url',
              image_url: {
                url: `https://maps.googleapis.com/maps/api/streetview?size=500x500&location=${propertyLocation.lat},${propertyLocation.lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
              }
            }
          ]
        });
      }
      try {
        const structureSuggestion = removeNullishValues(JSON.parse((await axios.post('ai?type=json', messages)).data.content).structure);
        if (structureSuggestion) {
          const newDefaultStructure = structureSuggestion.map((level) => {
            const levelItem = items.find((i) => i.id === level.itemId);
            if (levelItem) {
              const newLevel = createLevel(levelItem, {
                data: { label: level.label ?? 'Level' },
                attributesToInherit: projectSettingsRef.current
              });
              level.sections?.forEach((section) => {
                if (section) {
                  const newSection = createSection({
                    data: { label: section.label ?? 'Area' }
                  });
                  section.spaces?.forEach((space) => {
                    if (space) {
                      const spaceItem = items.find((i) => i.id === space.itemId);
                      const size = space.size ?? 0;
                      if (spaceItem) {
                        let newSpace = createSpace(spaceItem, {
                          data: { label: space.label ?? 'Space' },
                          attributesToInherit: projectSettingsRef.current
                        });
                        newSpace = {
                          ...newSpace,
                          attributes: newSpace.attributes.map(attr =>
                            attr.code === 'square_footage' ? { ...attr, value: size } : attr
                          )
                        };
                        newSection.spaces.push(newSpace);
                      } else {
                        console.error(`Space item ${space.label} not found`);
                      }
                    }
                  });
                  newLevel.sections.push(newSection);
                }
              });
              return newLevel;
            }
            console.error(`Level item ${level.label} not found`);
            return null;
          }).filter((l) => l) as Level[];
          setStructure(newDefaultStructure);
        } else {
          console.error(`No reply received`);
        }
      } catch (error) {
        console.error(error);
        errorModal('Failed to estimate default structure. Please try again.');
      } finally {
        setEstimatingStructure(false);
      }
    }
    setEstimatingStructure(false);
  };

  useEffect(() => {
    if (!structure.length && !estimatingStructure) {
      console.log('No structure present, generating default...');
      estimateDefaultStructure();
    }
  }, [structureModalOpen]);

  return (
    <div className='flex w-full flex-col gap-5 h-full'>
      <div className='flex items-center justify-between'>
        <div className='text-3xl font-bold text-gray-900'>Structure Layout</div>
        <div className='flex gap-5'>
          <div className='flex items-center gap-2 rounded-lg bg-orange-200 px-5 py-3'>
            <span className='text-xs font-semibold uppercase text-gray-600'>
              Total Heating BTU
            </span>
            <span className='text-sm font-bold text-gray-800'>
              {totalBTUs.heating.toLocaleString()}
            </span>
          </div>
          <div className='flex items-center gap-2 rounded-lg bg-blue-200 px-5 py-3'>
            <span className='text-xs font-semibold uppercase text-gray-600'>
              Total Cooling BTU
            </span>
            <span className='text-sm font-bold text-gray-800'>
              {totalBTUs.cooling.toLocaleString()}
            </span>
          </div>
        </div>
      </div>
      <DndProvider backend={HTML5Backend}>
        <div className='flex items-start w-full gap-5 grow pb-5'>
          <DnDPanel />
          <div className='flex flex-col gap-5 grow shrink-0 basis-0'>
            {showDuctlessOptions === false ? <SimpleStructurePanel /> : <StructurePanel />}
          </div>
          <div className='flex flex-col gap-5 grow shrink-0 basis-0'>
            {attomData && <Map />}
            {propertySummary && <InternalNote>{propertySummary}</InternalNote>}
          </div>
        </div>
        <AttributeModal
          openState={attributeModalState}
          itemUniqueId={activeItem?.uniqueId || null}
          title={activeItem?.label || 'Unknown Item'}
          attributes={activeItem?.attributes || []}
          fenestrationItems={activeItem?.fenestrationItems || []}
          defaultFenestration={activeItem?.defaultFenestration || []}
          description={
            items.find((i) => i.id === activeItem?.itemId)?.description ||
            undefined
          }
          modified={activeItem?.modified || false}
        />
        <StructureModal openState={structureModalState} />
      </DndProvider>
    </div>
  );
}
