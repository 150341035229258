import React, { useContext, useEffect, useState } from 'react';
import { TrashIcon, PencilSquareIcon, RectangleStackIcon } from '@heroicons/react/24/outline';
import { useDrag, useDrop } from 'react-dnd';
import { ConfigurationContext, DataContext } from 'data/context';
import { errorModal, confirmationModal } from 'utils/helpers';
import { Zone, ZoneItem, AirHandlerTypeCode, Configuration, Space, Condenser, ExistingSystem } from 'data/types';
import ZoneItemComponent from 'components/dnd/zone-item';
import BTUBubbles from 'components/btu-bubbles';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import Tooltip from 'components/tooltip';
import { findSpaceById, getRestrictedAirHandlerTypes, findSectionBySpaceId } from 'utils/structure';
import { v4 as uuid } from 'uuid';
import clsx from 'clsx';
import Text from 'components/inputs/text';
import WarningTooltip from 'components/warning-tooltip';
import ExistingSystemComponent from './existing-system-item';

type ZoneProps = {
  zone: Zone;
  condenser: Condenser;
  configId: string;
  simpleMode?: boolean;
  systemType?: 'ductless' | 'ducted' | 'hybrid';
};

export default function ZoneComponent({
  zone,
  condenser,
  configId,
  simpleMode = false,
  systemType = 'ductless'
}: ZoneProps) {
  const { items, airHandlerTypes } = useContext(DataContext);
  const {
    configurationOptions,
    setConfigurationOptions,
    selectedSystems,
    structure,
    existingSystems
  } = useContext(ConfigurationContext);
  const [valid, setValid] = useState(true);
  const [warning, setWarning] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [restrictedAirHandlerTypes, setRestrictedAirHandlerTypes] = useState<AirHandlerTypeCode[]>([]);
  const [animate] = useAutoAnimate({ duration: 100 });
  const [showSectionNames, setShowSectionNames] = useState(false);
  const [renaming, setRenaming] = useState(false);
  const [newName, setNewName] = useState(zone.name);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [config, setConfig] = useState<Configuration | null>(null);
  const [existingSystem, setExistingSystem] = useState<ExistingSystem | null>(null);
  const renameSizeLimit = 25;

  useEffect(() => {
    setConfig(configurationOptions.find((conf) => conf.uniqueId === configId) ?? null);
  }, [configurationOptions, configId]);

  useEffect(() => {
    setExistingSystem(existingSystems.find((sys) => sys.id === zone.existingSystemId) ?? null);
  }, [existingSystems, zone.existingSystemId]);

  useEffect(() => {
    if (zone.zoneItems.length > 0 && (!zone.airHandlerSpaceId || !zone.zoneItems.some(z => z.structureItemId === zone.airHandlerSpaceId))) {
      setConfigurationOptions((draft) => {
        const configIndex = draft.findIndex((conf) => conf.uniqueId === configId);
        if (configIndex !== -1) {
          const condenserIndex = draft[configIndex].condensers.findIndex(
            (cond) => cond.zones.some(z => z.uniqueId === zone.uniqueId)
          );
          if (condenserIndex !== -1) {
            const zoneIndex = draft[configIndex].condensers[condenserIndex].zones.findIndex(z => z.uniqueId === zone.uniqueId);
            if (zoneIndex !== -1) {
              // eslint-disable-next-line no-param-reassign
              draft[configIndex].condensers[condenserIndex].zones[zoneIndex].airHandlerSpaceId = zone.zoneItems[0].structureItemId;
            }
          }
        }
      });
    }
  }, [zone.zoneItems]);

  useEffect(() => {
    setRestrictedAirHandlerTypes(
      getRestrictedAirHandlerTypes(zone, structure, items, systemType === 'ducted')
    );
  }, [zone.zoneItems]);

  useEffect(() => {
    let isValid = true;
    let isWarning = false;
    let newErrorMessage: string | null = null;
    let newShowSectionNames = false;
    const uniqueStructureItemIds = Array.from(new Set(zone.zoneItems.map(i => i.structureItemId)));
    const uniqueSectionIds = Array.from(new Set(zone.zoneItems.map(i => findSectionBySpaceId(structure, i.structureItemId)?.uniqueId)));
    const partitionItems: string[] = [];
    const allStructureItems = zone.zoneItems.map((zoneItem) => findSpaceById(structure, zoneItem.structureItemId)).filter(space => space) as Space[];
    zone.zoneItems.forEach((zoneItem) => {
      const structureItem = findSpaceById(structure, zoneItem.structureItemId);
      if (structureItem) {
        if (
          systemType !== 'ducted' &&
          structureItem.attributes.find(a => a.code === 'share_air')?.value !== 'Open Floor Plan' &&
          structureItem.attributes.find(a => a.code === 'share_air')?.value !== 'Sharing Air With Door Open' &&
          (
            allStructureItems.every(item => item.attributes.find(a => a.code === 'space_above')?.value === 'Yes') ||
            allStructureItems.every(item => item.attributes.find(a => a.code === 'space_below')?.value === 'Yes')
          ) &&
          uniqueStructureItemIds.length > 1) {
          isWarning = true;
          newErrorMessage = 'This zone can only use a multi room air handler';
        }
        if (systemType !== 'ducted' && structureItem.attributes.find(a => a.code === 'zone_control')?.value && uniqueStructureItemIds.length > 1) {
          isValid = false;
          newErrorMessage = 'Spaces that require zone control must be by themselves';
        }
        if (
          systemType !== 'ducted' &&
          structureItem.attributes.find(a => a.code === 'share_air')?.value !== 'Open Floor Plan' &&
          structureItem.attributes.find(a => a.code === 'share_air')?.value !== 'Sharing Air With Door Open' &&
          !allStructureItems.every(item => item.attributes.find(a => a.code === 'space_above')?.value === 'Yes') &&
          !allStructureItems.every(item => item.attributes.find(a => a.code === 'space_below')?.value === 'Yes') &&
          uniqueStructureItemIds.length > 1
        ) {
          isValid = false;
          newErrorMessage = 'Spaces that cannot share air must be by themselves';
        }
        if (systemType !== 'ducted' && uniqueSectionIds.length > 1) {
          isValid = false;
          newErrorMessage = 'All spaces within a zone must be in the same area';
          newShowSectionNames = true;
        }
      } else {
        console.error(`Item "${zoneItem.name}" not found on structure.`);
        errorModal(`An error occurred while trying to validate ${zoneItem.name}.`);
      }
    });
    if (zone.zoneItems.length === 0 && !zone.existingSystemId) {
      isValid = false;
      newErrorMessage = 'Zone cannot be empty';
    }
    if (restrictedAirHandlerTypes.length === airHandlerTypes.length) {
      isValid = false;
      newErrorMessage = 'No valid air handler types found for this zone';
    }
    zone.zoneItems.forEach((zoneItem) => {
      if (zoneItem.isPartition) {
        if (partitionItems.includes(zoneItem.structureItemId)) {
          isValid = false;
          newErrorMessage = 'Partitioned spaces cannot be in the same zone';
        } else {
          partitionItems.push(zoneItem.structureItemId);
        }
      };
    });
    setValid(isValid);
    setWarning(isWarning);
    setErrorMessage(newErrorMessage);
    setShowSectionNames(newShowSectionNames);
    setConfigurationOptions((draft) => {
      const configIndex = draft.findIndex((conf) => conf.uniqueId === configId);
      const condenserIndex = draft[configIndex].condensers.findIndex((cond) =>
        cond.zones.some((z) => z.uniqueId === zone.uniqueId)
      );
      if (condenserIndex !== -1) {
        const zoneIndex = draft[configIndex].condensers[
          condenserIndex
        ].zones.findIndex((z) => z.uniqueId === zone.uniqueId);
        if (zoneIndex !== -1) {
          // eslint-disable-next-line no-param-reassign
          draft[configIndex].condensers[condenserIndex].zones[zoneIndex].valid =
            isValid;
        }
      }
    });
  }, [zone.zoneItems, zone.existingSystemId, airHandlerTypes, restrictedAirHandlerTypes]);

  const [{ isDragging, canDrag }, drag, dragPreview] = useDrag(() => ({
    type: `config-${configId}-zone`,
    item: { id: zone.uniqueId, type: 'zone' },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
      canDrag: !!monitor.canDrag()
    }),
    canDrag: systemType !== 'ductless'
  }), [config]);

  const moveZoneItem = (zoneItemId: string) => {
    setConfigurationOptions((draft) => {
      const configIndex = draft.findIndex((conf) => conf.uniqueId === configId);
      const fromCondenserIndex = draft[configIndex].condensers.findIndex(
        (cond) =>
          cond.zones.some((z) =>
            z.zoneItems.some((item) => item.uniqueId === zoneItemId)
          )
      );
      const toCondenserIndex = draft[configIndex].condensers.findIndex((cond) =>
        cond.zones.some((z) => z.uniqueId === zone.uniqueId)
      );
      const fromZoneIndex = draft[configIndex].condensers[
        fromCondenserIndex
      ].zones.findIndex((z) =>
        z.zoneItems.some((item) => item.uniqueId === zoneItemId)
      );
      const toZoneIndex = draft[configIndex].condensers[
        toCondenserIndex
      ].zones.findIndex((z) => z.uniqueId === zone.uniqueId);
      const zoneItemIndex = draft[configIndex].condensers[
        fromCondenserIndex
      ].zones[fromZoneIndex].zoneItems.findIndex(
        (item) => item.uniqueId === zoneItemId
      );
      const zoneItem = draft[configIndex].condensers[fromCondenserIndex].zones[
        fromZoneIndex
      ].zoneItems.splice(zoneItemIndex, 1)[0];
      draft[configIndex].condensers[toCondenserIndex].zones[
        toZoneIndex
      ].zoneItems.push(zoneItem);
      // eslint-disable-next-line no-param-reassign
      draft[configIndex].condensers[toCondenserIndex].zones[
        toZoneIndex
      ].modified = true;
    });
  };

  const addUnzonedItem = (unzonedItemId: string) => {
    const structureItem = findSpaceById(structure, unzonedItemId);
    if (structureItem) {
      const newZoneItem: ZoneItem = {
        uniqueId: uuid(),
        structureItemId: unzonedItemId,
        name: structureItem.label,
        BTUs: structureItem.BTUs,
        originalBTUs: structureItem.BTUs,
        isPartition: false
      };
      setConfigurationOptions((draft) => {
        const configIndex = draft.findIndex(
          (conf) => conf.uniqueId === configId
        );
        const condenserIndex = draft[configIndex].condensers.findIndex((cond) =>
          cond.zones.some((z) => z.uniqueId === zone.uniqueId)
        );
        const zoneIndex = draft[configIndex].condensers[
          condenserIndex
        ].zones.findIndex((z) => z.uniqueId === zone.uniqueId);
        draft[configIndex].condensers[condenserIndex].zones[
          zoneIndex
        ].zoneItems.push(newZoneItem);
        // eslint-disable-next-line no-param-reassign
        draft[configIndex].condensers[condenserIndex].zones[
          zoneIndex
        ].modified = true;
      });
    } else {
      console.error('Item not found on structure.');
      errorModal('Item not found on structure.');
    }
  };

  const moveZone = (fromZoneId: string) => {
    setConfigurationOptions((draft) => {
      const configIndex = draft.findIndex((conf) => conf.uniqueId === configId);
      const fromCondenserIndex = draft[configIndex].condensers.findIndex(
        (cond) => cond.zones.some((z) => z.uniqueId === fromZoneId)
      );
      const toCondenserIndex = draft[configIndex].condensers.findIndex(
        (cond) => cond.zones.some((z) => z.uniqueId === zone.uniqueId)
      );
      if (fromCondenserIndex !== -1 && toCondenserIndex !== -1) {
        const fromZoneIndex = draft[configIndex].condensers[fromCondenserIndex].zones.findIndex(
          (z) => z.uniqueId === fromZoneId
        );
        const toZoneIndex = draft[configIndex].condensers[toCondenserIndex].zones.findIndex(
          (z) => z.uniqueId === zone.uniqueId
        );
        if (fromZoneIndex !== -1 && toZoneIndex !== -1) {
          const [zoneToMove] = draft[configIndex].condensers[fromCondenserIndex].zones.splice(fromZoneIndex, 1);
          draft[configIndex].condensers[toCondenserIndex].zones.splice(toZoneIndex, 0, zoneToMove);
        }
      }
    });
  };

  const assignExistingSystem = (existingSystemId: string) => {
    setConfigurationOptions((draft) => {
      const configIndex = draft.findIndex((conf) => conf.uniqueId === configId);
      const condenserIndex = draft[configIndex].condensers.findIndex((cond) =>
        cond.zones.some((z) => z.uniqueId === zone.uniqueId)
      );
      const zoneIndex = draft[configIndex].condensers[condenserIndex].zones.findIndex(z => z.uniqueId === zone.uniqueId);
      // eslint-disable-next-line no-param-reassign
      draft[configIndex].condensers[condenserIndex].zones[zoneIndex].existingSystemId = existingSystemId;
      // eslint-disable-next-line no-param-reassign
      draft[configIndex].condensers[condenserIndex].zones[zoneIndex].modified = true;
    });
  };

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: zone.type === 'ducted' ? [
      `config-${configId}-zone`,
      `config-${configId}-existing-system-item`
    ] : [
      `config-${configId}-zone-item`,
      `config-${configId}-zoneable-space`,
      `config-${configId}-zone`
    ],
    drop: (item: {
      id: string;
      type: 'zone-item' | 'zoneable-space' | 'zone' | 'existing-system-item';
    }, monitor) => {
      if (item.type === 'zone-item' && !monitor.didDrop()) {
        if (selectedSystems.filter(system => system.configuration.uniqueId === configId).length > 0 || config?.condensers.some(cond => cond.selectedProduct || cond.zones.some(z => z.selectedProduct))) {
          confirmationModal('Are you sure you want to update this configuration? This will reset all selected products and systems for this configuration!', () => {
            moveZoneItem(item.id);
          });
        } else {
          moveZoneItem(item.id);
        }
      }
      if (item.type === 'zoneable-space' && !monitor.didDrop()) {
        addUnzonedItem(item.id);
      }
      if (item.type === 'zone' && !monitor.didDrop()) {
        if (selectedSystems.filter(system => system.configuration.uniqueId === configId).length > 0 || config?.condensers.some(cond => cond.selectedProduct || cond.zones.some(z => z.selectedProduct))) {
          confirmationModal('Are you sure you want to update this configuration? This will reset all selected products and systems for this configuration!', () => {
            moveZone(item.id);
          });
        } else {
          moveZone(item.id);
        }
      }
      if (item.type === 'existing-system-item' && !monitor.didDrop()) {
        if (selectedSystems.filter(system => system.configuration.uniqueId === configId).length > 0 || config?.condensers.some(cond => cond.selectedProduct || cond.zones.some(z => z.selectedProduct))) {
          confirmationModal('Are you sure you want to update this configuration? This will reset all selected products and systems for this configuration!', () => {
            assignExistingSystem(item.id);
          });
        } else {
          assignExistingSystem(item.id);
        }
      }
    },
    collect: (monitor) => ({
      canDrop: !!monitor.canDrop() && monitor.getItem().id !== zone.uniqueId,
      isOver: !!monitor.isOver({ shallow: true }) && monitor.getItem().id !== zone.uniqueId
    })
  }), [selectedSystems, config]);

  const deleteSelf = () => {
    setConfigurationOptions((draft) => {
      const configIndex = draft.findIndex((conf) => conf.uniqueId === configId);
      const condenserIndex = draft[configIndex].condensers.findIndex((cond) =>
        cond.zones.some((z) => z.uniqueId === zone.uniqueId)
      );
      const zoneIndex = draft[configIndex].condensers[
        condenserIndex
      ].zones.findIndex((z) => z.uniqueId === zone.uniqueId);
      draft[configIndex].condensers[condenserIndex].zones.splice(zoneIndex, 1);
    });
  };

  const handleDeleteClick = () => {
    const confirmText = selectedSystems.filter(system => system.configuration.uniqueId === configId).length > 0 || config?.condensers.some(cond => cond.selectedProduct || cond.zones.some(z => z.selectedProduct)) ?
      'Are you sure you want to update this configuration? This will reset all selected products and systems for this configuration!' :
      `Are you sure you want to delete ${zone.name}?`;
    if (zone.modified) {
      confirmationModal(confirmText, deleteSelf);
    } else {
      deleteSelf();
    }
  };

  const handleUpdateName = () => {
    if (newName.trim() !== '' && newName !== zone.name) {
      setConfigurationOptions((draft) => {
        const configIndex = draft.findIndex((conf) => conf.uniqueId === configId);
        if (configIndex !== -1) {
          const condenserIndex = draft[configIndex].condensers.findIndex(
            (cond) => cond.zones.some(z => z.uniqueId === zone.uniqueId)
          );
          if (condenserIndex !== -1) {
            const zoneIndex = draft[configIndex].condensers[condenserIndex].zones.findIndex(z => z.uniqueId === zone.uniqueId);
            if (zoneIndex !== -1) {
              // eslint-disable-next-line no-param-reassign
              draft[configIndex].condensers[condenserIndex].zones[zoneIndex].name = newName.trim();
              // eslint-disable-next-line no-param-reassign
              draft[configIndex].condensers[condenserIndex].zones[zoneIndex].modified = true;
            }
          }
        }
      });
      setNewName(newName.trim());
    } else {
      setNewName(zone.name);
    }
    setRenaming(false);
  };

  const handleRenameKeyDown = (key: string) => {
    if (key === 'Enter') {
      handleUpdateName();
    }
  };

  useEffect(() => {
    if (!isFirstRender) {
      if (zone.zoneItems.length === 0) {
        deleteSelf();
      }
    } else {
      setIsFirstRender(false);
    }
  }, [zone.zoneItems]);

  // TODO: re-implement this
  const updateAirHandlerSpaceId = (structureItemId: string) => {
    setConfigurationOptions((draft) => {
      const configIndex = draft.findIndex((conf) => conf.uniqueId === configId);
      if (configIndex !== -1) {
        const condenserIndex = draft[configIndex].condensers.findIndex(
          (cond) => cond.zones.some(z => z.uniqueId === zone.uniqueId)
        );
        if (condenserIndex !== -1) {
          const zoneIndex = draft[configIndex].condensers[condenserIndex].zones.findIndex(z => z.uniqueId === zone.uniqueId);
          if (zoneIndex !== -1) {
            // eslint-disable-next-line no-param-reassign
            draft[configIndex].condensers[condenserIndex].zones[zoneIndex].airHandlerSpaceId = structureItemId;
            // eslint-disable-next-line no-param-reassign
            draft[configIndex].condensers[condenserIndex].zones[zoneIndex].modified = true;
          }
        }
      }
    });
  };

  const classes = clsx(
    'w-full rounded border-2 overflow-hidden',
    !valid && !canDrop && 'border-red-300',
    warning && valid && !canDrop && 'border-amber-300',
    canDrop && 'border-dashed border-green-500'
  );

  const headerClasses = clsx(
    'flex h-10 w-full items-center border-b-2 pl-3',
    !valid && 'bg-red-100',
    warning && valid && 'bg-amber-100',
    valid && !warning && 'bg-gray-100'
  );

  return (
    <div ref={drop}>
      <div ref={dragPreview} className='flex items-start gap-2'>
        <div className="flex items-start relative self-stretch">
          {condenser.zones.findIndex(z => z.uniqueId === zone.uniqueId) < condenser.zones.length - 1 && (
            <div className='absolute left-[4px] h-full border-l-4 border-gray-300' />
          )}
          <div className='border-b-4 border-l-4 border-gray-300 rounded-bl-2xl pt-6 mb-[4px] w-7 ml-[4px]' />
          <div className='h-3 w-3 rounded-full bg-gray-300 mt-[20px]' />
        </div>
        <div className='flex flex-col gap-2 grow pt-3'>
          <div ref={drag} className='flex items-center gap-2'>
            <RectangleStackIcon className='h-6 w-6 text-gray-800 shrink-0' />
            {renaming ? (
              <div className='grow'>
                <div className="relative">
                  <Text
                    size='sm'
                    onInput={(value) => setNewName((value ?? '').slice(0, renameSizeLimit))}
                    value={newName}
                    onBlur={handleUpdateName}
                    onKeyDown={(e) => handleRenameKeyDown(e.key)}
                    autoFocus
                    autoSelect
                    controlled
                  />
                  <div className='absolute inset-y-0 right-0 flex items-center pr-2 text-gray-500 text-sm pointer-events-none'>
                    {newName.length}/{renameSizeLimit}
                  </div>
                </div>
              </div>
            ) : (
              <button
                type='button'
                onClick={() => setRenaming(true)}
                className='relative flex items-center gap-2 truncate outline-none cursor-pointer group'
              >
                <span className='text-xl font-semibold shrink-0'>
                  {zone.name}
                </span>
                <PencilSquareIcon className='h-5 w-5 text-gray-600 group-hover:text-gray-900' />
                <Tooltip text='Rename' />
              </button>
            )}
            {!simpleMode && zone.zoneItems.length > 0 && (
              <BTUBubbles BTUs={zone.zoneItems.map(item => item.BTUs)} />
            )}
            {errorMessage && (
              <WarningTooltip type={warning ? 'warning' : 'error'}>
                {errorMessage}
              </WarningTooltip>
            )}
            {zone.type !== 'ducted' && (
              <button
                className='h-full cursor-pointer text-gray-500 hover:text-gray-700 relative'
                type='button'
                onClick={handleDeleteClick}
              >
                <TrashIcon className='h-5 w-5' />
                <Tooltip text="Delete Zone" />
              </button>
            )}
          </div>
          <div ref={animate} className='flex flex-wrap gap-2'>
            {zone.zoneItems.map((item) => (
              <ZoneItemComponent
                key={item.uniqueId}
                zoneItem={item}
                configId={configId}
                showSectionName={showSectionNames}
              />
            ))}
            {existingSystem && (
              <ExistingSystemComponent
                configId={configId}
                existingSystem={existingSystem}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

ZoneComponent.defaultProps = {
  simpleMode: false
};