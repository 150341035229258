import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useState,
  useEffect,
  ReactNode
} from 'react';
import { useImmer, Updater } from 'use-immer';
import axios from 'utils/api';
import {
  Item,
  Attribute,
  AttributeFollowUp,
  ValidItems,
  FenestrationItem,
  StructureType,
  AirHandlerProduct,
  CondenserProduct,
  AccessoryProduct,
  AirHandlerType,
  ConfigRankingModifiers,
  RankingModifiers,
  ItemAttributeLink,
  RestockData,
  OversizeRange
} from 'data/types';
import { removeNullishValues } from 'utils/helpers';

type DataState = {
  items: Item[];
  fenestration: FenestrationItem[];
  structureTypes: StructureType[];
  validItems: ValidItems[];
  airHandlerProducts: AirHandlerProduct[];
  setAirHandlerProducts: Updater<AirHandlerProduct[]> | (() => void);
  condenserProducts: CondenserProduct[];
  setCondenserProducts: Updater<CondenserProduct[]> | (() => void);
  lineSetCoverProducts: AccessoryProduct[];
  ductingKitProducts: AccessoryProduct[];
  airHandlerTypes: AirHandlerType[];
  configRankingModifiers: ConfigRankingModifiers | null;
  setConfigRankingModifiers: Dispatch<SetStateAction<ConfigRankingModifiers | null>> | (() => void);
  rankingModifiers: RankingModifiers | null;
  setRankingModifiers: Dispatch<SetStateAction<RankingModifiers | null>> | (() => void);
  oversizeRanges: OversizeRange[];
  restockData: RestockData | null;
  BTULimits: number[][];
  criticalDataError: boolean;
  nonCriticalDataError: boolean;
};

const defaultDataState: DataState = {
  items: [],
  fenestration: [],
  structureTypes: [],
  validItems: [],
  airHandlerProducts: [],
  setAirHandlerProducts: () => { },
  condenserProducts: [],
  setCondenserProducts: () => { },
  lineSetCoverProducts: [],
  ductingKitProducts: [],
  airHandlerTypes: [],
  configRankingModifiers: null,
  setConfigRankingModifiers: () => { },
  rankingModifiers: null,
  setRankingModifiers: () => { },
  oversizeRanges: [],
  restockData: null,
  BTULimits: [],
  criticalDataError: false,
  nonCriticalDataError: false,
};

export const DataContext = createContext(defaultDataState);

export function DataContextProvider({ children }: { children: ReactNode }) {
  const [items, setItems] = useState(defaultDataState.items);
  const [fenestration, setFenestration] = useState(defaultDataState.fenestration);
  const [structureTypes, setStructureTypes] = useState(defaultDataState.structureTypes);
  const [validItems, setValidItems] = useState(defaultDataState.validItems);
  const [airHandlerProducts, setAirHandlerProducts] = useImmer(defaultDataState.airHandlerProducts);
  const [condenserProducts, setCondenserProducts] = useImmer(defaultDataState.condenserProducts);
  const [lineSetCoverProducts, setLineSetCoverProducts] = useState(defaultDataState.lineSetCoverProducts);
  const [ductingKitProducts, setDuctingKitProducts] = useState(defaultDataState.ductingKitProducts);
  const [airHandlerTypes, setAirHandlerTypes] = useState(defaultDataState.airHandlerTypes);
  const [configRankingModifiers, setConfigRankingModifiers] = useState(defaultDataState.configRankingModifiers);
  const [rankingModifiers, setRankingModifiers] = useState(defaultDataState.rankingModifiers);
  const [oversizeRanges, setOversizeRanges] = useState(defaultDataState.oversizeRanges);
  const [restockData, setRestockData] = useState(defaultDataState.restockData);
  const [BTULimits, setBTULimits] = useState(defaultDataState.BTULimits);
  const [criticalDataError, setCriticalDataError] = useState(defaultDataState.criticalDataError);
  const [nonCriticalDataError, setNonCriticalDataError] = useState(defaultDataState.nonCriticalDataError);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const newItems = (await axios.get('items')).data as Item[];
        const itemAttributeLinks = (await axios.get(
          'item-attribute-links'
        )).data as ItemAttributeLink[];
        const attributes = (await axios.get('attributes')).data as Attribute[];
        const attributeFollowUps = (await axios.get(
          'attribute-followups'
        )).data as AttributeFollowUp[];

        const formattedAttributes = attributes.map((attr) => {
          const followUps = attributeFollowUps.filter(
            (af) => af.followUpAttributeId === attr.id
          );
          return followUps.length === 0 ? removeNullishValues(attr) : {
            ...removeNullishValues(attr),
            parentAttributes: followUps.map((f) => ({ attributeId: f.attributeId, value: f.value }))
          };
        });

        const formattedItems: Item[] = newItems.map((item) => {
          const links = itemAttributeLinks.filter(
            (link) => link.itemId === item.id
          );
          const itemAttributeIds = links.map((link) => link.attributeId);
          const itemAttributes = formattedAttributes.filter((attr) =>
            itemAttributeIds.includes(attr.id)
          );
          const formattedItemAttributes: Attribute[] = itemAttributes.map((attr) => {
            const link = links.find((l) => l.attributeId === attr.id);
            return {
              ...attr,
              value: link ? link.defaultValue : undefined,
              required: !!link?.required
            };
          });
          attributeFollowUps.forEach((followUp) => {
            if (itemAttributeIds.includes(followUp.attributeId) && !itemAttributeIds.includes(followUp.followUpAttributeId)) {
              const newAttribute = formattedAttributes.find((attr) => attr.id === followUp.followUpAttributeId);
              if (newAttribute) {
                formattedItemAttributes.push({
                  ...newAttribute,
                  value: undefined
                });
              }
            }
          });
          return {
            ...item,
            attributes: formattedItemAttributes
          };
        });
        setItems(formattedItems);
      } catch (error) {
        console.error('Error fetching critical data', error);
        setCriticalDataError(true);
      }

      Promise.all([
        axios.get('air-handlers').then((res) => setAirHandlerProducts(res.data)),
        axios.get('condensers').then((res) => setCondenserProducts(res.data)),
        axios.get('structure-types').then((res) => setStructureTypes(res.data)),
        axios.get('fenestration').then((res) => setFenestration(res.data)),
        axios.get('valid-items').then((res) => setValidItems(res.data)),
        axios.get('ducting-kits').then((res) => setDuctingKitProducts(res.data)),
        axios.get('config-ranking-modifiers').then((res) => setConfigRankingModifiers(res.data)),
        axios.get('ranking-modifiers').then((res) => setRankingModifiers(res.data)),
        axios.get('oversize-ranges').then((res) => setOversizeRanges(res.data)),
        axios.get('air-handler-types').then((res) => setAirHandlerTypes(res.data))
      ]).catch((error) => {
        setCriticalDataError(true);
        console.error('Error fetching critical data', error);
      });

      Promise.all([
        axios.get('line-set-covers').then((res) => setLineSetCoverProducts(res.data)),
        axios.get('restock-data').then((res) => setRestockData(res.data)),
        axios.get('btu-limits').then((res) => setBTULimits(res.data))
      ]).catch((error) => {
        setNonCriticalDataError(true);
        console.error('Error fetching non-critical data', error);
      });
    };
    fetchData();
  }, []);

  return (
    <DataContext.Provider value={{
      items,
      fenestration,
      structureTypes,
      validItems,
      airHandlerProducts,
      setAirHandlerProducts,
      condenserProducts,
      setCondenserProducts,
      lineSetCoverProducts,
      ductingKitProducts,
      airHandlerTypes,
      configRankingModifiers,
      setConfigRankingModifiers,
      rankingModifiers,
      setRankingModifiers,
      oversizeRanges,
      restockData,
      BTULimits,
      criticalDataError,
      nonCriticalDataError
    }}
    >
      {children}
    </DataContext.Provider>
  );
}
