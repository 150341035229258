import React, { createContext, useState, useEffect, ReactNode } from 'react';
import axios from 'utils/api';

type AuthState = {
  isAuthenticated: boolean;
  isLoading: boolean;
  setIsAuthenticated: (newState: boolean) => void;
  hasPermission: (permission: any) => boolean;
  hasRole: (role: any) => boolean;
};

const defaultAuthState: AuthState = {
  isAuthenticated: false,
  isLoading: true,
  setIsAuthenticated: () => { },
  hasPermission: () => false,
  hasRole: () => false,
};

export interface User {
  userID: number;
  agentId: string;
  email: string;
  teams: string[];
  name: string;
  permissions: string[];
  roles: string[];
}

export const AuthContext = createContext(defaultAuthState);

export function AuthContextProvider({ children }: { children: ReactNode }) {
  const [isLoading, setIsLoading] = useState(defaultAuthState.isLoading);
  const [isAuthenticated, setIsAuthenticated] = useState(defaultAuthState.isAuthenticated);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const authenticateUser = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_PARTNERNET_URL}/api/authentication/check-user`,
        );
        if (response.status === 200 && response.data.userID) {
          setIsAuthenticated(true);
          setUser(response.data);
        }
      } catch (error) {
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    };
    authenticateUser();
  }, []);

  const hasPermission = (permission: string) => !!user && user.permissions.includes(permission);
  const hasRole = (role: string) => !!user && user.roles.includes(role);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        setIsAuthenticated,
        hasPermission,
        hasRole
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};