import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import { AdminContext } from 'data/context';
import { successModal, errorModal } from 'utils/helpers';
import axios from 'utils/api';
import Modal from 'components/modals/modal';
import Text from 'components/inputs/text';
import { Item, AirHandlerTypeCode } from 'data/types';
import { useImmer } from 'use-immer';
import Checkbox from 'components/inputs/checkbox';
import ToggleSwitch from 'components/inputs/toggle-switch';
import AttributeComponent from 'components/attribute';
import Textarea from 'components/inputs/textarea';
import NumberInput from 'components/inputs/number';
import { PlusIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { Popover, PopoverClose, PopoverContent, PopoverTrigger } from 'components/popover';
import Button from 'components/inputs/button';
import AirHandler from 'components/admin/air-handler';

type CreateSpaceModalProps = {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
};

export default function CreateSpaceModal({ openState }: CreateSpaceModalProps) {
  const {
    attributes,
    airHandlerTypes,
    fenestration,
    refreshData
  } = useContext(AdminContext);
  const [open, setOpen] = openState;
  const defaultItem = {
    id: 0,
    group: 'Space',
    allowedAirHandlerTypes: airHandlerTypes.map((t) => t.code),
    airHandlerRanking: airHandlerTypes.map((t) => t.code),
    defaultFenestration: [],
    airHandlerSuggestionOverride: false,
    canBeAlone: false,
    attributes: [],
    code: '',
    description: '',
    label: '',
    largeSize: null,
    mediumSize: null,
    smallSize: null,
    recommendOversizing: false,
    typicallySharesAir: false,
  };
  const [item, setItem] = useImmer<Item>(defaultItem);
  const [hoveredAttribute, setHoveredAttribute] = useState<number | null>(null);

  useEffect(() => {
    setItem(defaultItem);
  }, [open]);

  const handleAssignmentChange = (attributeId: number, assigned: boolean) => {
    setItem((draft) => {
      if (draft) {
        const attributeIndex = draft.attributes.findIndex((a) => a.id === attributeId);
        if (attributeIndex !== -1 && !assigned) {
          // eslint-disable-next-line no-param-reassign
          draft.attributes.splice((attributeIndex), 1);
        } else if (attributeIndex === -1 && assigned) {
          const newAttribute = attributes.find((a) => a.id === attributeId);
          if (newAttribute) {
            draft.attributes.push({ ...newAttribute, required: false });
          }
        }
      }
    });
  };

  const handleDefaultValueChange = (attributeId: number, value: any) => {
    setItem((draft) => {
      if (draft) {
        const attributeIndex = draft.attributes.findIndex((a) => a.id === attributeId);
        if (attributeIndex !== -1) {
          // eslint-disable-next-line no-param-reassign
          draft.attributes[attributeIndex].value = value;
        }
      }
    });
  };

  const handleFieldChange = (key: string, value: any) => {
    setItem((draft) => {
      if (draft) {
        // eslint-disable-next-line no-param-reassign
        draft[key] = value;
      }
    });
  };

  const handleRequiredChange = (attributeId: number, required: boolean) => {
    setItem((draft) => {
      if (draft) {
        const attributeIndex = draft.attributes.findIndex((a) => a.id === attributeId);
        if (attributeIndex !== -1) {
          // eslint-disable-next-line no-param-reassign
          draft.attributes[attributeIndex].required = required;
        }
      }
    });
  };

  const handleAllowedAirHandlerTypeChange = (code: AirHandlerTypeCode, allowed: boolean) => {
    setItem((draft) => {
      if (draft) {
        if (allowed) {
          draft.allowedAirHandlerTypes?.push(code);
        } else {
          // eslint-disable-next-line no-param-reassign
          draft.allowedAirHandlerTypes = draft.allowedAirHandlerTypes?.filter((t) => t !== code) ?? null;
        }
      }
    });
  };

  const handleDeleteFenestration = (index: number) => {
    setItem((draft) => {
      if (draft) {
        // eslint-disable-next-line no-param-reassign
        draft.defaultFenestration = draft.defaultFenestration.filter((_, i) => i !== index);
      }
    });
  };

  const handleAddFenestration = (fenestrationItem: string) => {
    setItem((draft) => {
      if (draft) {
        draft.defaultFenestration.push(fenestrationItem);
      }
    });
  };

  const rerankAirHandler = (fromCode: AirHandlerTypeCode, toCode: AirHandlerTypeCode) => {
    setItem((draft) => {
      if (draft) {
        const fromIndex = draft.airHandlerRanking.indexOf(fromCode);
        const toIndex = draft.airHandlerRanking.indexOf(toCode);
        const [movingItem] = draft.airHandlerRanking.splice(fromIndex, 1);
        draft.airHandlerRanking.splice(toIndex, 0, movingItem);
      }
    });
  };

  const handleSave = async () => {
    try {
      await axios.post('items', { item });
      successModal('Space created');
    } catch (error) {
      console.error(error);
      errorModal('Failed to create space');
    }
    refreshData();
    setOpen(false);
  };

  return (
    <Modal
      title='New Space'
      openState={openState}
      size='fullscreen'
      infoMode
    >
      <div className='flex flex-col gap-5'>
        <div className='flex items-center gap-5'>
          <div className='font-semibold text-gray-900'>Name:</div>
          <div>
            <Text
              placeholder='Name...'
              onChange={(value) => handleFieldChange('label', value)}
              value={item.label}
            />
          </div>
        </div>
        <div className='max-h-[400px] overflow-auto'>
          <table className='min-w-full border-separate border-spacing-0 divide-y divide-gray-300'>
            <thead>
              <tr>
                <th
                  scope='col'
                  className='sticky top-0 z-10 border-b border-gray-300 bg-white pb-3.5 pl-3 pr-0 text-left font-semibold text-gray-900'
                />
                <th
                  scope='col'
                  className='sticky top-0 z-10 border-b border-gray-300 bg-white px-3 pb-3.5 text-left font-semibold text-gray-900'
                >
                  Attribute
                </th>
                <th
                  scope='col'
                  className='sticky top-0 z-10 border-b border-gray-300 bg-white px-3 pb-3.5 text-left font-semibold text-gray-900'
                >
                  Default Value
                </th>
                <th
                  scope='col'
                  className='sticky top-0 z-10 border-b border-gray-300 bg-white px-3 pb-3.5 text-left font-semibold text-gray-900'
                >
                  Required?
                </th>
              </tr>
            </thead>
            <tbody className='divide-y divide-gray-200'>
              {attributes
                .sort((a, b) => a.label.localeCompare(b.label))
                .map((attribute) => (
                  <tr
                    key={attribute.id}
                    className={hoveredAttribute === attribute.id ? 'bg-gray-100' : ''}
                  >
                    <td className='whitespace-nowrap border-b border-gray-200 py-4 pl-3 pr-0 text-gray-900'>
                      <Checkbox
                        htmlId={`checkbox-${attribute.id}`}
                        value={!!item?.attributes.find((a) => a.id === attribute.id)}
                        onChange={(checked) => handleAssignmentChange(attribute.id, checked)}
                        disabled={!item}
                      />
                    </td>
                    <td
                      className={`whitespace-nowrap border-b border-gray-200 px-3 py-4 text-gray-900 ${!item ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                      onClick={item ?
                        () => handleAssignmentChange(attribute.id, !item?.attributes.find((a) => a.id === attribute.id)) :
                        undefined
                      }
                      onMouseEnter={() => setHoveredAttribute(attribute.id)}
                      onMouseLeave={() => setHoveredAttribute(null)}
                    >
                      {attribute.label}
                    </td>
                    <td className='whitespace-nowrap border-b border-gray-200 px-3 py-4'>
                      <div className='inline-block'>
                        <AttributeComponent
                          key={attribute.id}
                          {...{ ...attribute }}
                          completed={!!attribute.value}
                          value={item.attributes.find((a) => a.id === attribute.id)?.value}
                          onChange={(value) => handleDefaultValueChange(attribute.id, value)}
                          showProgress={false}
                          disabled={!item?.attributes.find((a) => a.id === attribute.id)}
                        />
                      </div>
                    </td>
                    <td className='whitespace-nowrap border-b border-gray-200 px-3 py-4'>
                      <ToggleSwitch
                        value={item.attributes.find((a) => a.id === attribute.id)?.required}
                        onChange={(value) => handleRequiredChange(attribute.id, value)}
                        disabled={!item?.attributes.find((a) => a.id === attribute.id)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className='flex items-center gap-5'>
          <div className='font-semibold text-gray-900'>Description:</div>
          <Textarea
            value={item.description}
            onChange={(value) => handleFieldChange('description', value)}
            placeholder='Description...'
          />
        </div>
        <div className='flex items-center gap-5'>
          <div className='font-semibold text-gray-900'>Default Sizes:</div>
          <div className='flex flex-col gap-1'>
            <span className='font-semibold text-gray-900'>Small:</span>
            <NumberInput
              onChange={(value) => handleFieldChange('smallSize', value)}
              value={item.smallSize}
              placeholder='Small sqft'
            />
          </div>
          <div className='flex flex-col gap-1'>
            <span className='font-semibold text-gray-900'>Medium:</span>
            <NumberInput
              onChange={(value) => handleFieldChange('mediumSize', value)}
              value={item.mediumSize}
              placeholder='Medium sqft'
            />
          </div>
          <div className='flex flex-col gap-1'>
            <span className='font-semibold text-gray-900'>Large:</span>
            <NumberInput
              onChange={(value) => handleFieldChange('largeSize', value)}
              value={item.largeSize}
              placeholder='Large sqft'
            />
          </div>
        </div>
        <div className='flex gap-5'>
          <div className='font-semibold text-gray-900'>
            Air Handler Ranking:
          </div>
          <div className='flex flex-wrap gap-3 border-2 border-dashed border-gray-300 p-3'>
            {(item ? item.airHandlerRanking : airHandlerTypes.map(t => t.code)).map((typeCode, index) => {
              const airHandlerType = airHandlerTypes.find((t) => t.code === typeCode);
              return airHandlerType ? (
                <AirHandler
                  key={airHandlerType.id}
                  type={airHandlerType}
                  index={index}
                  rerankFn={rerankAirHandler}
                />
              ) : null;
            })}
          </div>
        </div>
        <div className='flex gap-5'>
          <div className='font-semibold text-gray-900'>
            Allowed Air Handler Types:
          </div>
          <div className='flex flex-wrap gap-3'>
            {airHandlerTypes.map((type) => (
              <label
                key={type.id}
                className='flex items-center gap-1 cursor-pointer'
              >
                <Checkbox
                  value={item.allowedAirHandlerTypes.includes(type.code)}
                  onChange={(checked) => handleAllowedAirHandlerTypeChange(type.code, checked)}
                />
                <span>{type.label}</span>
              </label>
            ))}
          </div>
        </div>
        <div className='flex gap-5'>
          <div className='font-semibold text-gray-900'>
            Default Fenestration:
          </div>
          <div className='flex flex-col gap-3'>
            <div className='flex flex-wrap gap-3'>
              {item.defaultFenestration.map((fenestrationItem, index) => (
                <span
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  className='inline-flex items-center gap-1 rounded-md bg-gray-200 px-3 py-2 text-sm font-medium text-gray-700 whitespace-nowrap'
                >
                  <button
                    type='button'
                    className='-ml-1 relative h-5 w-5 rounded-sm hover:bg-gray-500/20 inline-flex items-center justify-center'
                    onClick={() => handleDeleteFenestration(index)}
                  >
                    <XMarkIcon className='w-4 h-4' />
                  </button>
                  {fenestration.find((f) => f.code === fenestrationItem)?.label || 'Unknown Item'}
                </span>
              ))}
              <Popover placement='bottom-start'>
                <div>
                  <PopoverTrigger
                    className={`inline-flex items-center gap-1 justify-center rounded-md px-3 py-2 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 bg-blue-100 text-blue-900 hover:bg-blue-200 whitespace-nowrap ${!item && 'opacity-[70%] cursor-not-allowed'}`}
                  >
                    <PlusIcon className='w-4 h-4 -ml-1' />
                    <span>Add Glass/Doors</span>
                  </PopoverTrigger>
                </div>
                <PopoverContent className="flex flex-col p-2 w-56 rounded-lg bg-white shadow-lg ring-1 ring-black/5 focus:outline-none z-50">
                  {fenestration.map((fenestrationItem) => (
                    <PopoverClose
                      key={fenestrationItem.id}
                      onClick={() => handleAddFenestration(fenestrationItem.code)}
                      className='rounded-md w-full flex items-center gap-3 px-3 py-2 text-left text-sm font-semibold text-gray-900 hover:bg-gray-100'
                    >
                      <span>{fenestrationItem.label}</span>
                    </PopoverClose>
                  ))}
                </PopoverContent>
              </Popover>
            </div>
          </div>
        </div>
        <label className='flex cursor-pointer items-center gap-2'>
          <span className='font-semibold'>Recommend oversizing?</span>
          <ToggleSwitch
            value={item.recommendOversizing}
            onChange={(value) => handleFieldChange('recommendOversizing', value)}
          />
        </label>
        <label className='flex cursor-pointer items-center gap-2'>
          <span className='font-semibold'>Can be in its own zone?</span>
          <ToggleSwitch
            value={item.canBeAlone}
            onChange={(value) => handleFieldChange('canBeAlone', value)}
          />
        </label>
        <label className='flex cursor-pointer items-center gap-2'>
          <span className='font-semibold'>Override air handler suggestion?</span>
          <ToggleSwitch
            value={item.airHandlerSuggestionOverride}
            onChange={(value) => handleFieldChange('airHandlerSuggestionOverride', value)}
          />
        </label>
        <div className='flex items-center gap-3'>
          <Button
            text='Create'
            color='green'
            onClick={handleSave}
          />
          <Button
            text='Cancel'
            color='red'
            onClick={() => setOpen(false)}
          />
        </div>
      </div>
    </Modal>
  );
}
