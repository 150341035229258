import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import { ProjectContext } from 'data/context';
import { errorModal, warningModal } from 'utils/helpers';
import { AxiosError } from 'axios';
import Modal from 'components/modals/modal';
import { getLocationData, getWeatherData } from 'utils/api';
import Text from 'components/inputs/text';

type ZipCodeModalProps = {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
};

export default function ZipCodeModal({ openState }: ZipCodeModalProps) {
  const { setLocationData, setWeatherData, setAttomData } = useContext(ProjectContext);
  const [open, setOpen] = openState;
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setInputValue('');
  }, [open]);

  const handleSave = async () => {
    if (inputValue.length === 5 && !Number.isNaN(Number(inputValue))) {
      setOpen(false);
      getLocationData(inputValue)
        .then((res) => setLocationData(res.data ?? null))
        .catch((error: AxiosError) => {
          warningModal('Failed to load location data');
          console.error('Error fetching location data', error);
        });
      setAttomData(null);
      const newWeatherData = await getWeatherData(inputValue);
      setWeatherData(newWeatherData);
    } else {
      errorModal('Please enter a valid zip code');
    }
  };

  const handleKeyPress = (key: string) => {
    if (key === 'Enter') {
      handleSave();
    }
  };

  return (
    <Modal
      title='Enter a Zip Code'
      openState={openState}
      onSave={handleSave}
      closeOnSave={false}
    >
      <Text
        placeholder='12345'
        value={inputValue}
        onChange={(value) => setInputValue(value ?? '')}
        onKeyUp={(e) => handleKeyPress(e.key)}
        autoFocus
      />
    </Modal>
  );
}
