import React, {
  createContext,
  useState,
  useEffect,
  ReactNode
} from 'react';
import {
  AirHandlerType,
  Attribute,
  AttributeFollowUp,
  FenestrationItem,
  Item,
  ItemAttributeLink,
  StructureType,
  ValidItems,
  RankingModifiers,
  Settings,
  ConfigRankingModifiers
} from 'data/types';
import axios from 'utils/api';

type AdminState = {
  refreshData: () => void;
  airHandlerTypes: AirHandlerType[];
  items: Item[];
  attributes: Attribute[];
  attributeFollowUps: AttributeFollowUp[];
  structureTypes: StructureType[];
  validItems: ValidItems[];
  fenestration: FenestrationItem[];
  rankingModifiers: RankingModifiers | null;
  configRankingModifiers: ConfigRankingModifiers | null;
  settings: Settings | null;
  loading: boolean;
};

const defaultAdminState: AdminState = {
  refreshData: () => { },
  airHandlerTypes: [],
  items: [],
  attributes: [],
  attributeFollowUps: [],
  structureTypes: [],
  validItems: [],
  fenestration: [],
  rankingModifiers: null,
  configRankingModifiers: null,
  settings: null,
  loading: true
};

export const AdminContext = createContext(defaultAdminState);

export function AdminContextProvider({ children }: { children: ReactNode }) {
  const [airHandlerTypes, setAirHandlerTypes] = useState(defaultAdminState.airHandlerTypes);
  const [items, setItems] = useState(defaultAdminState.items);
  const [attributes, setAttributes] = useState(defaultAdminState.attributes);
  const [attributeFollowUps, setAttributeFollowUps] = useState(defaultAdminState.attributeFollowUps);
  const [structureTypes, setStructureTypes] = useState(defaultAdminState.structureTypes);
  const [validItems, setValidItems] = useState(defaultAdminState.validItems);
  const [fenestration, setFenestration] = useState(defaultAdminState.fenestration);
  const [rankingModifiers, setRankingModifiers] = useState(defaultAdminState.rankingModifiers);
  const [configRankingModifiers, setConfigRankingModifiers] = useState(defaultAdminState.configRankingModifiers);
  const [settings, setSettings] = useState(defaultAdminState.settings);
  const [loading, setLoading] = useState(true);

  const refreshData = async () => {
    await Promise.all([
      axios.get('items').then(res => res.data as Item[]),
      axios.get('item-attribute-links').then(res => res.data as ItemAttributeLink[]),
      axios.get('attributes').then(res => res.data as Attribute[]),
      axios.get('attribute-followups').then(res => res.data as AttributeFollowUp[]),
      axios.get('structure-types').then((res) => setStructureTypes(res.data)),
      axios.get('valid-items').then((res) => setValidItems(res.data)),
      axios.get('air-handler-types').then((res) => setAirHandlerTypes(res.data)),
      axios.get('fenestration').then((res) => setFenestration(res.data)),
      axios.get('config-ranking-modifiers').then((res) => setConfigRankingModifiers(res.data)),
      axios.get('ranking-modifiers').then((res) => setRankingModifiers(res.data)),
      axios.get('settings').then((res) => setSettings(res.data))
    ]).then((values) => {
      const [newItems, itemAttributeLinks, newAttributes, newAttributeFollowUps] = values;
      const formattedAttributes = newAttributes.map((attr) => {
        const followUps = newAttributeFollowUps.filter(
          (af) => af.followUpAttributeId === attr.id
        );
        return followUps.length === 0 ? attr : {
          ...attr,
          parentAttributes: followUps.map((f) => ({ attributeId: f.attributeId, value: f.value }))
        };
      });
      const formattedItems = newItems.map((item) => {
        const links = itemAttributeLinks.filter(
          (link) => link.itemId === item.id
        );
        const itemAttributeIds = links.map((link) => link.attributeId);
        const itemAttributes = formattedAttributes.filter((attr) =>
          itemAttributeIds.includes(attr.id)
        );
        const formattedItemAttributes = itemAttributes.map((attr) => {
          const link = links.find((l) => l.attributeId === attr.id);
          return {
            ...attr,
            parentItemId: item.id,
            value: link ? link.defaultValue : attr.value,
            required: !!link?.required
          };
        });
        return {
          ...item,
          attributes: formattedItemAttributes
        };
      });
      setAttributes(formattedAttributes);
      setAttributeFollowUps(newAttributeFollowUps);
      setItems(formattedItems);
    }).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    refreshData();
  }, []);

  return (
    <AdminContext.Provider value={{
      refreshData,
      airHandlerTypes,
      items,
      attributes,
      attributeFollowUps,
      structureTypes,
      validItems,
      fenestration,
      rankingModifiers,
      configRankingModifiers,
      settings,
      loading
    }}
    >
      {children}
    </AdminContext.Provider>
  );
}