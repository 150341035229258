/* eslint-disable react/no-array-index-key */
import React, { useContext, useState, useEffect } from 'react';
import { successModal, errorModal } from 'utils/helpers';
import { AdminContext } from 'data/context';
import Button from 'components/inputs/button';
import axios from 'utils/api';
import { Settings, OversizeRange } from 'data/types';
import OversizeRangeComponent from 'components/admin/oversize-range';
import AttributeComponent from 'components/attribute';

type FilteringField = {
  code: keyof Settings;
  label: string;
  type: 'number';
  placeholder?: string;
  options?: string[];
};

export default function AirHandlers() {
  const { refreshData, settings } = useContext(AdminContext);
  const [values, setValues] = useState({});
  const [oversizeRanges, setOversizeRanges] = useState<OversizeRange[]>([]);

  const fields: FilteringField[] = [
    {
      code: 'airHandlerMinimumCeilingHeightInches',
      label: 'Air Handler Minimum Ceiling Height',
      type: 'number',
      placeholder: '0 inches'
    },
    {
      code: 'undersizeBTUPercentage',
      label: 'Allowable Percentage of BTU Undersizing',
      type: 'number',
      placeholder: '0%'
    },
    {
      code: 'undersizeBTUMaxiumAdjustmentBTU',
      label: 'Maximum BTU Adjustment for Undersizing',
      type: 'number',
      placeholder: '0 BTU'
    },
    {
      code: 'partialUndersizeBTUPercentage',
      label: 'Allowable Percentage of Partial BTU Undersizing',
      type: 'number',
      placeholder: '0%'
    },
    {
      code: 'partialUndersizeBTUMaxiumAdjustmentBTU',
      label: 'Maximum BTU Adjustment for Partial Undersizing',
      type: 'number',
      placeholder: '0%'
    },
    {
      code: 'daysAtHighestTemperatureThreshold',
      label:
        'Number of days at highest temperature to be considered partial cooling',
      type: 'number',
      placeholder: '0'
    },
    {
      code: 'daysAtLowestTemperatureThreshold',
      label:
        'Number of days at lowest temperature to be considered partial heating',
      type: 'number',
      placeholder: '0'
    },
    {
      code: 'mrahCFMRoundDownPercentage',
      label:
        'Maximum percentage of CFMs to round down for MRAH (anything above will round up)',
      type: 'number',
      placeholder: '0'
    },
    {
      code: 'mrahMaxDuctLengthFeet',
      label: 'Maximum duct length for MRAH',
      type: 'number',
      placeholder: '0'
    }
  ];

  useEffect(() => {
    if (settings) {
      setValues(Object.fromEntries(fields.map((field) => [field.code, settings[field.code]]) as any));
      setOversizeRanges(settings.oversizeBTURanges);
    }
  }, [settings]);

  const handleFieldChange = (code: string, value: any) => {
    setValues((oldValues) => ({ ...oldValues, [code]: value }));
  };

  const handleAddOversizeRange = () => {
    setOversizeRanges((oldRanges) => [
      ...oldRanges,
      { minBTU: undefined, maxBTU: undefined, oversizeBTUAmount: 0 }
    ]);
  };

  const handleSave = async () => {
    try {
      await axios.patch('settings', { settings: [...Object.entries(values).map(([key, value]) => ({ key, value })), { key: 'oversizeBTURanges', value: JSON.stringify(oversizeRanges) }] });
      await refreshData();
      successModal(`Settings updated.`);
    } catch (error) {
      errorModal('Something went wrong.');
    }
  };

  return (
    <div className='mx-auto flex max-w-[1600px] flex-col gap-5'>
      <div className='text-3xl font-bold text-gray-900'>
        Filtering Logic Settings
      </div>
      <table className='min-w-full divide-y divide-gray-300'>
        <thead>
          <tr>
            <th
              scope='col'
              className='pb-3.5 pl-0 pr-3 text-left font-semibold text-gray-900'
            >
              Setting
            </th>
            <th
              scope='col'
              className='px-3 pb-3.5 text-left font-semibold text-gray-900'
            >
              Description
            </th>
            <th
              scope='col'
              className='px-3 pb-3.5 text-left font-semibold text-gray-900'
            >
              Value
            </th>
          </tr>
        </thead>
        <tbody className='divide-y divide-gray-200'>
          {fields.map((field) => (
            <tr key={field.code}>
              <td className='py-4 pl-0 pr-3 text-gray-900'>
                {field.code}
              </td>
              <td className='px-3 py-4 text-gray-500'>
                {field.label}
              </td>
              <td className='min-w-[200px] px-3 py-4'>
                <div className='inline-block'>
                  <AttributeComponent
                    id={0}
                    label={field.label}
                    code={field.code}
                    priority={0}
                    inheritable={false}
                    showProgress={false}
                    type={field.type}
                    value={values[field.code]}
                    options={field.options}
                    placeholder={field.placeholder}
                    onChange={(value) => handleFieldChange(field.code, value)}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className='flex flex-col gap-3'>
        <div className='font-semibold text-gray-900'>BTU Oversizing Ranges</div>
        {oversizeRanges.map((range, index) => (
          <OversizeRangeComponent
            key={index}
            range={range}
            index={index}
            updateArray={setOversizeRanges}
            deleteSelf={() =>
              setOversizeRanges((oldRanges) => {
                oldRanges.splice(index, 1);
                return [...oldRanges];
              })
            }
          />
        ))}
        <div className='inline-block'>
          <Button
            text='Add Oversizing Range'
            color='primary'
            onClick={handleAddOversizeRange}
          />
        </div>
      </div>
      <div>
        <Button text='Save' color='green' onClick={handleSave} />
      </div>
    </div>
  );
}
