import { useContext, useEffect } from 'react';
import { ConfigurationContext, ProjectContext, UIContext } from 'data/context';
import { saveProjectData, notification } from 'utils/helpers';

type AutoSaveWatcherProps = {
  loading: boolean;
}

export default function AutoSaveWatcher({ loading }: AutoSaveWatcherProps) {
  const {
    projectDetails,
    attomData,
    projectSettings,
    customerValues,
    structureTypeSelected,
    structureSubtypeSelected,
    partialStructure,
    warmUpNotes,
    locationData,
    showDuctlessOptions
  } = useContext(ProjectContext);
  const {
    configurations,
    configurationOptions,
    structure,
    selectedSystems,
    existingSystems,
    possibleCondenserLocations
  } = useContext(ConfigurationContext);
  const {
    sliderValues,
    zoneSliderValues
  } = useContext(UIContext);
  const debounceTimer = 500;

  // Configurations
  useEffect(() => {
    let saveConfigurations;
    if (!loading && projectDetails?.projectId) {
      saveConfigurations = setTimeout(() => {
        const data = configurations.map((config) => ({
          ...config,
          condensers: config.condensers.map((cond) => ({
            ...cond,
            validSystems: [],
            rankedSystems: [],
            validSystemsFetched: undefined
          }))
        }));
        saveProjectData(projectDetails.projectId, 'configurations', data)
          .then((success) => {
            if (success) {
              notification('Saved', 'success');
            } else {
              notification('Error saving configurations', 'error');
            }
          });
      }, debounceTimer);
    }
    return () => clearTimeout(saveConfigurations);
  }, [
    JSON.stringify(configurations.map(config => ({
      ...config, condensers: config.condensers.map(cond => ({ ...cond, validSystems: [], rankedSystems: [], validSystemsFetched: undefined }))
    })))
  ]);

  // Property data
  useEffect(() => {
    if (!loading && projectDetails?.projectId) {
      saveProjectData(projectDetails.projectId, 'propertyData', attomData)
        .then((success) => {
          if (success) {
            notification('Saved', 'success');
          } else {
            notification('Error saving property data', 'error');
          }
        });
    }
  }, [JSON.stringify(attomData)]);

  // Project settings
  useEffect(() => {
    if (!loading && projectDetails?.projectId) {
      saveProjectData(projectDetails.projectId, 'projectSettings', projectSettings)
        .then((success) => {
          if (success) {
            notification('Saved', 'success');
          } else {
            notification('Error saving project settings', 'error');
          }
        });
    }
  }, [JSON.stringify(projectSettings)]);

  // Customer values
  useEffect(() => {
    if (!loading && projectDetails?.projectId) {
      saveProjectData(projectDetails.projectId, 'customerValues', customerValues)
        .then((success) => {
          if (success) {
            notification('Saved', 'success');
          } else {
            notification('Error saving customer values', 'error');
          }
        });
    }
  }, [JSON.stringify(customerValues)]);

  // Warm up notes
  useEffect(() => {
    if (!loading && projectDetails?.projectId) {
      saveProjectData(projectDetails.projectId, 'warmUpNotes', warmUpNotes)
        .then((success) => {
          if (success) {
            notification('Saved', 'success');
          } else {
            notification('Error saving warm up notes', 'error');
          }
        });
    }
  }, [warmUpNotes]);

  // Project info
  useEffect(() => {
    let timeout;
    if (!loading && projectDetails?.projectId) {
      timeout = setTimeout(() => {
        const data = {
          structureTypeId: structureTypeSelected,
          structureSubtypeId: structureSubtypeSelected,
          partialStructure,
          locationData: JSON.stringify(locationData),
          existingSystems: JSON.stringify(existingSystems),
          showDuctlessOptions,
          possibleCondenserLocations: JSON.stringify(possibleCondenserLocations)

        };
        saveProjectData(projectDetails.projectId, 'projectInfo', data)
          .then((success) => {
            if (success) {
              notification('Saved', 'success');
            } else {
              notification('Error saving project info', 'error');
            }
          });
      }, debounceTimer);
    }
    return () => clearTimeout(timeout);
  }, [
    structureTypeSelected,
    structureSubtypeSelected,
    partialStructure,
    locationData,
    existingSystems,
    showDuctlessOptions,
    possibleCondenserLocations
  ]);

  // Structure
  useEffect(() => {
    let timeout;
    if (!loading && projectDetails?.projectId) {
      timeout = setTimeout(() => {
        saveProjectData(projectDetails.projectId, 'structure', structure)
          .then((success) => {
            if (success) {
              notification('Saved', 'success');
            } else {
              notification('Error saving structure', 'error');
            }
          });
      }, debounceTimer);
    }
    return () => clearTimeout(timeout);
  }, [JSON.stringify(structure)]);

  // Selected systems
  useEffect(() => {
    let timeout;
    if (!loading && projectDetails?.projectId) {
      const data = selectedSystems.map(system => ({
        ...system,
        tax: undefined,
        shipping: undefined,
        rebates: undefined
      }));
      timeout = setTimeout(() => {
        saveProjectData(projectDetails.projectId, 'selectedSystems', data)
          .then((success) => {
            if (success) {
              notification('Saved', 'success');
            } else {
              notification('Error saving selected systems', 'error');
            }
          });
      }, debounceTimer);
    }
    return () => clearTimeout(timeout);
  }, [
    JSON.stringify(selectedSystems.map(system => ({ ...system, tax: undefined, shipping: undefined, rebates: undefined })))
  ]);

  // Slider values
  useEffect(() => {
    let timeout;
    if (!loading && projectDetails?.projectId) {
      timeout = setTimeout(() => {
        const data = { sliderValues, zoneSliderValues };
        saveProjectData(projectDetails.projectId, 'sliderValues', data)
          .then((success) => {
            if (success) {
              notification('Saved', 'success');
            } else {
              notification('Error saving slider values', 'error');
            }
          });
      }, debounceTimer);
    }
    return () => clearTimeout(timeout);
  }, [
    JSON.stringify(sliderValues),
    JSON.stringify(zoneSliderValues)
  ]);

  // Configuration options
  useEffect(() => {
    let timeout;
    if (!loading && projectDetails?.projectId) {
      timeout = setTimeout(() => {
        const data = configurationOptions.map((config) => ({
          ...config,
          condensers: config.condensers.map((cond) => ({
            ...cond,
            validSystems: [],
            rankedSystems: [],
            validSystemsFetched: undefined
          }))
        }));
        saveProjectData(projectDetails.projectId, 'configurationOptions', data)
          .then((success) => {
            if (success) {
              notification('Saved', 'success');
            } else {
              notification('Error saving configuration options', 'error');
            }
          });
      }, debounceTimer);
    }
    return () => clearTimeout(timeout);
  }, [
    JSON.stringify(configurationOptions.map(config => ({
      ...config, condensers: config.condensers.map(cond => ({ ...cond, validSystems: [], rankedSystems: [], validSystemsFetched: undefined }))
    })))
  ]);

  return null;
}
