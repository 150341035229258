import React, { useContext } from 'react';
import { ProjectContext, ConfigurationContext, DataContext } from 'data/context';
import { confirmationModal } from 'utils/helpers';
import Select from 'components/inputs/select';

type StructureSubypeSelectorProps = {
  showInvalid?: boolean;
};

export default function StructureSubypeSelector({
  showInvalid = false
}: StructureSubypeSelectorProps) {
  const {
    structureTypeSelected,
    structureSubtypeSelected,
    setStructureSubtypeSelected,
    setPartialStructure
  } = useContext(ProjectContext);
  const {
    structure,
    configurations,
    selectedSystems
  } = useContext(ConfigurationContext);
  const { structureTypes } = useContext(DataContext);

  const handleChangeStructureSubtype = (structureSubtypeId: number | null) => {
    const changeStructureSubtype = () => {
      setStructureSubtypeSelected(structureSubtypeId);
      setPartialStructure(null);
    };
    if (structure.length === 0 && configurations.length === 0 && selectedSystems.length === 0) {
      changeStructureSubtype();
    } else {
      confirmationModal('Are you sure you want to change the structure subtype? This will reset your structure, configurations, and selected systems!', changeStructureSubtype);
    }
  };

  return (
    <Select
      onChange={(value) => handleChangeStructureSubtype(value !== '' ? Number(value) : null)}
      options={
        structureTypes
          .find((t) => t.id === structureTypeSelected)
          ?.subtypes.map((s) => ({ label: s.label, value: s.id.toString() })) || []
      }
      value={structureSubtypeSelected?.toString() ?? undefined}
      invalid={showInvalid && structureSubtypeSelected === null}
    />
  );
}

StructureSubypeSelector.defaultProps = {
  showInvalid: false
};