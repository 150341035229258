/* eslint-disable no-nested-ternary */
import React, { useContext, useCallback, useState, useEffect, useRef } from 'react';
import { TrashIcon, DocumentDuplicateIcon, PencilIcon } from '@heroicons/react/24/outline';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { useDrag, useDrop } from 'react-dnd';
import { ConfigurationContext, DataContext, UIContext, ProjectContext } from 'data/context';
import BTUBubbles from 'components/btu-bubbles';
import { confirmationModal, createActiveItem, errorModal } from 'utils/helpers';
import { getAllLabelsOfType, createSpace } from 'utils/structure';
import { Space } from 'data/types';
import Tooltip from 'components/tooltip';
import {
  useClientPoint,
  useFloating,
  useInteractions,
  useHover,
  offset,
  useClick,
  useDismiss
} from '@floating-ui/react';
import clsx from 'clsx';

type SpaceProps = {
  space: Space,
  focus?: 'levels' | 'sections' | 'spaces'
};

export default function SpaceComponent({ space, focus }: SpaceProps) {
  const { items, fenestration } = useContext(DataContext);
  const {
    structure,
    setStructure,
    configurations,
    selectedSystems
  } = useContext(ConfigurationContext);
  const { setActiveItem, easyMode } = useContext(UIContext);
  const { projectSettings } = useContext(ProjectContext);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const structureRef = useRef(structure);
  const attributesRef = useRef(space.attributes);
  const projectSettingsRef = useRef(projectSettings);
  const validBorderColor = space.attributes.some((a) => a.code === 'square_footage') ? 'border-green-200' : 'border-gray-200';
  const validBackgroundColor = space.attributes.some((a) => a.code === 'square_footage') ? 'bg-green-100' : 'bg-gray-100';

  const { refs, floatingStyles, context } = useFloating({
    open: showTooltip,
    onOpenChange: setShowTooltip,
    placement: 'bottom-start',
    strategy: 'fixed',
    middleware: [
      offset(10)
    ]
  });

  const { refs: menuRefs, floatingStyles: menuStyles, context: menuContext } = useFloating({
    open: showMenu,
    onOpenChange: setShowMenu,
    placement: 'bottom-start',
    strategy: 'fixed'
  });

  const clientPoint = useClientPoint(context);
  const hover = useHover(context);
  const click = useClick(menuContext);
  const dismiss = useDismiss(menuContext);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    clientPoint,
    hover
  ]);

  const { getReferenceProps: getMenuReferenceProps, getFloatingProps: getMenuFloatingProps } = useInteractions([
    click,
    dismiss
  ]);

  useEffect(() => {
    structureRef.current = structure;
    attributesRef.current = space.attributes;
    projectSettingsRef.current = projectSettings;
  }, [structure, space.attributes, projectSettings]);

  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type: 'space',
    item: { id: space.uniqueId, type: 'space', itemId: space.itemId },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  }));

  useEffect(() => {
    if (isDragging) {
      setShowTooltip(false);
    }
  }, [isDragging]);

  const handleDeleteClick = useCallback(() => {
    const deleteSelf = () => {
      setStructure((draft) => {
        const levelIndex = draft.findIndex((l) =>
          l.sections.some((s) => s.spaces.some((sp) => sp.uniqueId === space.uniqueId))
        );
        if (levelIndex !== -1) {
          const sectionIndex = draft[levelIndex].sections.findIndex((s) =>
            s.spaces.some((sp) => sp.uniqueId === space.uniqueId)
          );
          if (sectionIndex !== -1) {
            const spaceIndex = draft[levelIndex].sections[
              sectionIndex
            ].spaces.findIndex((sp) => sp.uniqueId === space.uniqueId);
            draft[levelIndex].sections[sectionIndex].spaces.splice(spaceIndex, 1);
          }
        }
      });
    };
    const confirmText = configurations.length > 0 || selectedSystems.length > 0 ?
      `Are you sure you want to delete ${space.label}? This will reset all configurations and selected systems!` :
      `Are you sure you want to delete ${space.label}?`;
    if (space.modified || (configurations.length > 0 || selectedSystems.length > 0)) {
      confirmationModal(confirmText, deleteSelf);
    } else {
      deleteSelf();
    }
    setShowMenu(false);
  }, [space, configurations, selectedSystems]);

  const handleDuplicateClick = useCallback(() => {
    const duplicateSelf = () => {
      const spaceItem = items.find((i) => i.id === space.itemId);
      if (spaceItem) {
        let itemNumber = 1;
        const existingLabels = getAllLabelsOfType(structureRef.current, 'space');
        while (existingLabels.includes(`${spaceItem.label} ${itemNumber}`)) {
          itemNumber += 1;
        }
        const newAttributes = spaceItem.attributes.map(att => ({ ...att }));
        space.attributes.forEach((att) => {
          const attributeIndex = newAttributes.findIndex((a) => a.id === att.id);
          if (attributeIndex !== -1) {
            newAttributes[attributeIndex] = { ...newAttributes[attributeIndex], value: att.value };
          }
        });
        const newSpace = createSpace(spaceItem, {
          data: {
            label: `${spaceItem.label} ${itemNumber}`,
            attributes: newAttributes,
            modified: false
          },
        });
        setStructure((draft) => {
          const levelIndex = draft.findIndex((l) =>
            l.sections.some((s) => s.spaces.some((sp) => sp.uniqueId === space.uniqueId))
          );
          if (levelIndex !== -1) {
            const sectionIndex = draft[levelIndex].sections.findIndex((s) =>
              s.spaces.some((sp) => sp.uniqueId === space.uniqueId)
            );
            if (sectionIndex !== -1) {
              draft[levelIndex].sections[sectionIndex].spaces.push(newSpace);
            }
          }
        });
        setActiveItem(createActiveItem({ type: 'space', item: newSpace, items, fenestration }));
      } else {
        console.error('Level item not found');
        errorModal(`Level item not found`);
      }
    };
    if (configurations.length > 0 || selectedSystems.length > 0) {
      confirmationModal('Are you sure you want to add a space? This will reset all configurations and selected systems!', duplicateSelf);
    } else {
      duplicateSelf();
    }
    setShowMenu(false);
  }, [space, configurations, selectedSystems]);

  const handleClick = () => {
    setActiveItem(createActiveItem({ type: 'space', item: space, items, fenestration }));
    setShowMenu(false);
  };

  const moveSpace = (spaceId: string) => {
    setStructure((draft) => {
      const fromLevelIndex = draft.findIndex((l) =>
        l.sections.some((s) => s.spaces.some((sp) => sp.uniqueId === spaceId))
      );
      const toLevelIndex = draft.findIndex((l) =>
        l.sections.some((s) => s.spaces.some((sp) => sp.uniqueId === space.uniqueId))
      );
      if (fromLevelIndex !== -1 && toLevelIndex !== -1) {
        const fromSectionIndex = draft[fromLevelIndex].sections.findIndex((s) =>
          s.spaces.some((sp) => sp.uniqueId === spaceId)
        );
        const toSectionIndex = draft[toLevelIndex].sections.findIndex((s) =>
          s.spaces.some((sp) => sp.uniqueId === space.uniqueId)
        );
        if (fromSectionIndex !== -1 && toSectionIndex !== -1) {
          const fromSpaceIndex = draft[fromLevelIndex].sections[
            fromSectionIndex
          ].spaces.findIndex((sp) => sp.uniqueId === spaceId);
          const toSpaceIndex = draft[toLevelIndex].sections[
            toSectionIndex
          ].spaces.findIndex((sp) => sp.uniqueId === space.uniqueId);
          if (fromSpaceIndex !== -1 && toSpaceIndex !== -1) {
            const [fromSpace] = draft[fromLevelIndex].sections[
              fromSectionIndex
            ].spaces.splice(fromSpaceIndex, 1);
            draft[toLevelIndex].sections[toSectionIndex].spaces.splice(
              toSpaceIndex,
              0,
              fromSpace
            );
          }
        }
      }
    });
  };

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ['space'],
    drop: (item: {
      id: string;
      label: string;
      type: 'space';
      itemId?: number;
    }) => {
      if (item.type === 'space') {
        if (configurations.length > 0 || selectedSystems.length > 0) {
          confirmationModal('Are you sure you want to move this space? This will reset all configurations and selected systems!', () => {
            moveSpace(item.id);
          });
        } else {
          moveSpace(item.id);
        }
      }
    },
    collect: (monitor) => ({
      canDrop: !!monitor.canDrop() && monitor.getItem().id !== space.uniqueId,
      isOver: !!monitor.isOver({ shallow: true }) && monitor.getItem().id !== space.uniqueId
    })
  }), [configurations, selectedSystems]);

  const classes = clsx(
    'relative h-full w-full rounded-lg border-2',
    space.valid && !canDrop && !easyMode && validBorderColor,
    !space.valid && !canDrop && !easyMode && 'border-red-300',
    canDrop && 'border-dashed border-green-500',
    easyMode && !canDrop && 'border-gray-200',
    focus === 'spaces' && 'ring-4 ring-green-500'
  );

  const headerClasses = clsx(
    'flex items-center h-full w-full rounded-md',
    space.valid && !isOver && !easyMode && validBackgroundColor,
    !space.valid && !isOver && !easyMode && 'bg-red-100',
    isOver && 'bg-green-50',
    easyMode && !isOver && 'bg-gray-100'
  );

  const buttonClasses = clsx(
    `h-full w-full flex flex-col items-center justify-center focus:outline-none focus:ring-0`,
    isDragging ? 'cursor-grabbing' : 'cursor-grab',
    space.label.length >= 15 && 'text-sm'
  );

  return (
    <div ref={drop} className='w-full h-full'>
      <div ref={dragPreview} className={classes}>
        <div className={headerClasses}>
          <div ref={drag} className='w-full h-full overflow-hidden grow'>
            <button
              type='button'
              ref={refs.setReference}
              {...getReferenceProps()}
              className={buttonClasses}
              onClick={handleClick}
              disabled={space.recalculateBTUs}
            >
              <span className='w-full truncate text-center'>
                {space.recalculateBTUs ? 'Loading...' : space.label}
              </span>
              {!easyMode && (space.BTUs.cooling > 0 || space.BTUs.heating > 0) && (
                <BTUBubbles BTUs={space.BTUs} />
              )}
            </button>
          </div>
          <button
            className='p-1 cursor-pointer text-gray-500 hover:text-gray-700 shrink-0 absolute top-0 right-0'
            ref={menuRefs.setReference}
            {...getMenuReferenceProps()}
          >
            <EllipsisVerticalIcon className='h-6 w-6' />
            <Tooltip text='Options' />
          </button>
          {showMenu && (
            <div
              ref={menuRefs.setFloating}
              style={menuStyles}
              {...getMenuFloatingProps()}
              className="absolute left-0 flex flex-col p-2 mt-2 w-52 origin-top-right rounded-lg bg-white shadow-lg ring-1 ring-black/5 z-10">
              <button
                onClick={handleClick}
                className='rounded-md w-full flex items-center gap-3 px-3 py-2 text-left text-sm font-semibold text-gray-900 hover:bg-gray-100'
              >
                <PencilIcon className='h-5 w-5' />
                <span>Edit Space</span>
              </button>
              <button
                onClick={handleDuplicateClick}
                className='rounded-md w-full flex items-center gap-3 px-3 py-2 text-left text-sm font-semibold text-gray-900 hover:bg-gray-100'
              >
                <DocumentDuplicateIcon className='h-5 w-5' />
                <span>Duplicate Space</span>
              </button>
              <button
                onClick={handleDeleteClick}
                className='rounded-md w-full flex items-center gap-3 px-3 py-2 text-left text-sm font-semibold text-red-600 hover:bg-red-100'
              >
                <TrashIcon className='h-5 w-5' />
                <span>Delete Space</span>
              </button>
            </div>
          )}
        </div>
        {!space.valid && !easyMode && (
          <>
            <div className='absolute -right-1 -top-1 h-3 w-3 rounded-full bg-red-500' />
            <div className='absolute -right-1 -top-1 h-3 w-3 animate-ping rounded-full bg-red-500' />
          </>
        )}
      </div>
    </div>
  );
}

SpaceComponent.defaultProps = {
  focus: undefined
};