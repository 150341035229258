import React, { useRef, useEffect } from 'react';
import { Switch } from '@headlessui/react';
import clsx from 'clsx';

export type ToggleSwitchProps = {
  onChange?: (value: boolean) => void;
  value?: boolean;
  disabled?: boolean;
  offColor?: 'gray' | 'red';
  autoFocus?: boolean;
};

export default function ToggleSwitch({
  onChange,
  value = false,
  disabled = false,
  offColor = 'gray',
  autoFocus = false
}: ToggleSwitchProps) {
  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (autoFocus) {
      ref.current?.focus();
    }
  }, [autoFocus]);

  const classes = clsx(
    'relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2',
    value && 'bg-green-600',
    !value && offColor === 'gray' && 'bg-gray-300',
    !value && offColor === 'red' && 'bg-red-500',
    disabled ? 'cursor-not-allowed opacity-70' : 'cursor-pointer'
  );

  return (
    <Switch
      ref={ref}
      checked={value}
      onChange={onChange ? (checked) => onChange(checked) : undefined}
      className={classes}
      disabled={disabled}
    >
      <span
        aria-hidden='true'
        className={clsx(
          value ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
        )}
      />
    </Switch>
  );
}

ToggleSwitch.defaultProps = {
  onChange: undefined,
  value: false,
  disabled: false,
  offColor: 'gray',
  autoFocus: false
};
