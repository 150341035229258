/* eslint-disable no-nested-ternary */
import { AirHandlerType, AirHandlerTypeCode } from 'data/types';
import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import clsx from 'clsx';

type AirHandlerProps = {
  type: AirHandlerType;
  index: number;
  rerankFn: (fromCode: AirHandlerTypeCode, toCode: AirHandlerTypeCode) => void;
  disabled?: boolean;
};

export default function AirHandler({
  type,
  index,
  rerankFn,
  disabled = false
}: AirHandlerProps) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'air-handler',
    item: { code: type.code },
    canDrag: () => !disabled,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  }), [disabled]);

  const [{ isOver }, drop] = useDrop(() => ({
    accept: ['air-handler'],
    drop: (item: { code: AirHandlerTypeCode }) => {
      rerankFn(item.code, type.code);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver({ shallow: true })
    })
  }));

  const classes = clsx(
    'flex items-center gap-2 whitespace-nowrap rounded-full border px-3 py-1',
    isOver ? 'bg-blue-100' : 'bg-white',
    isDragging && 'cursor-grabbing',
    disabled ? 'opacity-[70%] cursor-not-allowed' : 'cursor-grab'
  );

  return (
    <div ref={drop}>
      <div ref={drag} className={classes}>
        <span className='text-sm font-bold text-gray-800'>{index + 1}</span>
        <span className='text-xs font-semibold uppercase text-gray-600'>
          {type.label}
        </span>
      </div>
    </div>
  );
}

AirHandler.defaultProps = {
  disabled: false
};