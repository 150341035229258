import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import SteppedModal from 'components/modals/stepped-modal';
import { ExistingSystem, LabelAnalysisResult, ModalStep } from 'data/types';
import { ConfigurationContext, DataContext, ProjectContext, UIContext } from 'data/context';
import AddressInfo from 'components/address-info';
import StructureTypeSelector from 'components/structure-type-selector';
import StructureSubypeSelector from 'components/structure-subtype-selector';
import AttributeComponent from 'components/attribute';
import Select from 'components/inputs/select';
import NumberInput from 'components/inputs/number';
import { v4 as uuid } from 'uuid';
import { fileToBase64 } from 'utils/helpers';
import axios from 'utils/api';
import OpenAI from 'openai';
import Text from 'components/inputs/text';

type DescriptionModalProps = {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
  structureTypeLoading: boolean;
  structureImageUrl?: string;
};

export default function DescriptionModal({
  openState,
  structureTypeLoading,
  structureImageUrl
}: DescriptionModalProps) {
  const {
    attomData,
    projectSettings,
    setProjectSettings,
    locationData,
    structureTypeSelected,
    structureSubtypeSelected,
    partialStructure,
    setPartialStructure,
    showDuctlessOptions,
    setShowDuctlessOptions
  } = useContext(ProjectContext);
  const { structureTypes } = useContext(DataContext);
  const { setEasyModalsFinished } = useContext(UIContext);
  const {
    existingSystems,
    setExistingSystems
  } = useContext(ConfigurationContext);
  const [currentStep, setCurrentStep] = useState('address');
  const [steps, setSteps] = useState<ModalStep[]>([]);
  const projectSettingsRef = useRef(projectSettings);
  const propertyTypeAttribute = projectSettings.find(setting => setting.code === 'property_type');
  const otherPropertyTypeAttribute = projectSettings.find(setting => setting.code === 'other_property_type');
  const projectTypeAttribute = projectSettings.find(setting => setting.code === 'project_type');
  const ductworkAttribute = projectSettings.find(setting => setting.code === 'ductwork');
  const equipmentTypeValues = {
    'furnace': 'indoor',
    'air handler': 'outdoor',
    'null': 'both'
  };

  useEffect(() => {
    projectSettingsRef.current = projectSettings;
  }, [projectSettings]);

  const handleChangeProjectSetting = (attributeId: number, value: any) => {
    setProjectSettings((draft) => {
      const setting = draft.find(s => s.id === attributeId);
      if (setting) {
        setting.value = value;
      }
    });
  };

  useEffect(() => {
    const newSteps: ModalStep[] = [
      {
        id: 'address',
        title: 'Confirm Property Address',
        requirementsMet: !!attomData,
        reason: 'This will help us automatically pull in a lot of information about the property'
      },
      {
        id: 'structure type',
        title: 'Confirm Structure Type',
        requirementsMet: structureTypeSelected !== null && structureSubtypeSelected !== null,
        reason: 'This will help us recommend room types that are commonly found in this type of structure'
      },
      {
        id: 'property type',
        title: 'Confirm Property Type',
        requirementsMet: !!projectSettings.find(setting => setting.code === 'property_type')?.value,
        reason: 'This will help us determine the best kind of system for this property'
      },
      {
        id: 'project type',
        title: 'Confirm Project Type',
        requirementsMet: !!projectSettings.find(setting => setting.code === 'project_type')?.value && (projectSettings.find(setting => setting.code === 'project_type')?.value !== 'Replacing Old System' || existingSystems.length > 0),
        reason: 'This will help us determine the best kind of system for this property'
      }
    ];

    if (
      (projectSettings.find(setting => setting.code === 'project_type')?.value === 'Designing New System' || projectSettings.find(setting => setting.code === 'project_type')?.value === 'Replacing Old System') &&
      !newSteps.some(step => step.id === 'ductwork')
    ) {
      newSteps.push({
        id: 'ductwork',
        title: 'Confirm Structure Ductwork',
        requirementsMet: !!projectSettings.find(setting => setting.code === 'ductwork')?.value,
        reason: 'This will help us determine what kind of components we can use in the system'
      });
    } else if (ductworkAttribute) {
      handleChangeProjectSetting(ductworkAttribute.id, null);
    }

    if (
      projectSettings.find(setting => setting.code === 'project_type')?.value === 'Replacing Old System' &&
      existingSystems.length > 0 &&
      !newSteps.some(step => step.id === 'existing equipment')
    ) {
      newSteps.push({
        id: 'existing equipment',
        title: 'Confirm Existing Equipment',
        requirementsMet: existingSystems.every(system => (
          (system.type === 'ducted' && system.heatingCapacity && system.coolingCapacity && system.heatingEquipmentType !== undefined && system.heatingFuelType && system.heatingOrientation && system.existingCabinetSize) ||
          (system.type === 'ductless' && system.heatingCapacity && system.coolingCapacity) ||
          (system.type === 'hybrid' && system.heatingCapacity && system.coolingCapacity && system.heatingEquipmentType && system.heatingOrientation && system.existingCabinetSize)
        )),
        reason: 'This will help us determine what components we can offer to replace this system'
      });
    }

    if (
      projectSettings.find(setting => setting.code === 'project_type')?.value === 'Replacing Old System' &&
      projectSettings.find(setting => setting.code === 'ductwork')?.value === 'Full Ductwork' &&
      existingSystems.length > 0 &&
      !newSteps.some(step => step.id === 'equipment considerations')
    ) {
      newSteps.push({
        id: 'equipment considerations',
        title: 'Confirm Equipment Considerations',
        requirementsMet: showDuctlessOptions !== null,
        reason: 'This will help us determine how much information we need to gather about the structure'
      });
    } else {
      setShowDuctlessOptions(null);
    }

    setSteps(newSteps);
  }, [
    locationData,
    structureTypeSelected,
    structureSubtypeSelected,
    projectSettings,
    existingSystems,
    showDuctlessOptions
  ]);

  useEffect(() => {
    const projectType = projectSettings.find(setting => setting.code === 'project_type')?.value;
    if ((projectType === 'Replacing Old System' || projectType === 'Designing New System') && partialStructure !== false) {
      setPartialStructure(false);
    }
    if (projectType === 'Adding Partial System' && partialStructure !== true) {
      setPartialStructure(true);
    }
    if (!projectType && partialStructure !== null) {
      setPartialStructure(null);
    }
  }, [projectSettings]);

  const onFinish = async () => {
    setEasyModalsFinished((prev) => [...prev.filter(m => m !== 'Description'), 'Description']);
  };

  const handleChangeNumberOfExistingSystems = (numberOfSystems: number) => {
    setExistingSystems((draft) => {
      while (draft.length < numberOfSystems) {
        draft.push({
          id: uuid(),
          name: `System ${draft.length + 1}`,
        });
      }
      while (draft.length > numberOfSystems) {
        draft.pop();
      }
    });
  };

  const handleUpdateExistingSystem = (systemId: string, key: string, value: any) => {
    setExistingSystems((draft) => {
      const system = draft.find(s => s.id === systemId);
      if (system) {
        system[key] = value;
      }
    });
  };

  const handleUpdateExistingSystemEquipmentType = (systemId: string, value?: string) => {
    setExistingSystems((draft) => {
      const system = draft.find(s => s.id === systemId);
      if (system) {
        if (value === 'indoor') {
          system.heatingEquipmentType = 'furnace';
        }
        if (value === 'outdoor') {
          system.heatingEquipmentType = 'air handler';
        }
        if (value === 'both') {
          system.heatingEquipmentType = null;
        }
        if (!value) {
          system.heatingEquipmentType = undefined;
        }
      }
    });
  };

  const getModelNumberFromImage = async (file: File) => {
    const base64 = await fileToBase64(file);
    if (typeof base64 !== 'string') {
      return null;
    }
    const messages: OpenAI.Chat.ChatCompletionMessageParam[] = [
      {
        role: 'system',
        content: 'You are analyzing images of HVAC equipment labels to extract the model number of the equipment.',
      },
      {
        role: 'user',
        content: [
          {
            type: 'text',
            text: 'Here is an image of a product label. If the label contains an unbroken alphanumeric model number, please respond with either the model number or null in the following JSON format: { modelNumber: string | null }'
          },
          {
            type: 'image_url',
            image_url: {
              url: base64
            }
          }
        ]
      }
    ];
    const response = (await axios.post('chatgpt?type=json', messages)).data.content;
    const { modelNumber } = JSON.parse(response) as { modelNumber: string | null };
    return modelNumber;
  };

  const getProductInfo = async (modelNumber: string) => {
    const messages: OpenAI.Chat.ChatCompletionMessageParam[] = [
      {
        role: 'system',
        content: 'You are providing information about HVAC products based on the model number of that product.',
      },
      {
        role: 'user',
        content: [
          {
            type: 'text',
            text: `We are gathering information about a product with the model number ${modelNumber}. Please respond with the following information about the product in JSON format, using null for unknown or non-applicable values: { systemType: "ductless" | "ducted" | "hybrid" | null, heatingBTUCapacity: number | null, coolingBTUCapacity: number | null, airflowOrientation: "upflow" | "downflow" | "horizontal" | null, fuelType: "electric" | "natural gas" | "lp" | "oil" | null, cabinetSize: "A" | "B" | "C" | "D" | null }`
          }
        ]
      }
    ];
    const response = (await axios.post('chatgpt?type=json', messages)).data.content as string;
    const result = JSON.parse(response) as LabelAnalysisResult;
    return result;
  };

  const updateExistingSystemModelNumber = async (system: ExistingSystem, modelNumber: string) => {
    const productInfo = modelNumber ? await getProductInfo(modelNumber) : null;
    setExistingSystems(draft => {
      const existingSystem = draft.find(s => s.id === system.id);
      if (existingSystem) {
        existingSystem.analyzingLabel = false;
        existingSystem.modelNumber = modelNumber || undefined;
        existingSystem.type = productInfo?.systemType ?? undefined;
        existingSystem.heatingCapacity = productInfo?.heatingBTUCapacity ?? undefined;
        existingSystem.coolingCapacity = productInfo?.coolingBTUCapacity ?? undefined;
        existingSystem.heatingOrientation = productInfo?.airflowOrientation ?? undefined;
        existingSystem.heatingFuelType = productInfo?.fuelType ?? undefined;
        existingSystem.existingCabinetSize = productInfo?.cabinetSize ?? undefined;
        existingSystem.analysisMessage = modelNumber ? `Information populated for model number ${modelNumber}.` : undefined;
      }
    });
  };

  const analyzeLabelImage = async (system: ExistingSystem, file: File | undefined) => {
    if (file) {
      setExistingSystems(draft => {
        const existingSystem = draft.find(s => s.id === system.id);
        if (existingSystem) {
          existingSystem.analyzingLabel = true;
          existingSystem.analysisMessage = 'Analyzing label image...';
        }
      });
      const modelNumber = await getModelNumberFromImage(file);
      if (modelNumber) {
        updateExistingSystemModelNumber(system, modelNumber);
      } else {
        setExistingSystems(draft => {
          const existingSystem = draft.find(s => s.id === system.id);
          if (existingSystem) {
            existingSystem.analyzingLabel = false;
            existingSystem.analysisMessage = 'No model number detected in image.';
          }
        });
      }
    }
  };

  return (
    <SteppedModal
      openState={openState}
      steps={steps}
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      finalButtonText='Finish'
      size='normal'
      handleFinish={onFinish}
    >
      {currentStep === 'address' && (
        <div className='flex flex-col items-center w-full gap-3'>
          {attomData ? (
            <span className='font-semibold text-lg capitalize'>
              {`${attomData.address.line1?.toLowerCase()}, ${attomData.address.locality?.toLowerCase()}, ${attomData.address.countrySubd} ${attomData.address.postal1}`}
            </span>
          ) : (
            <span className='font-semibold text-lg'>What is the address of the property?</span>
          )}
          <div className='w-[400px]'>
            <AddressInfo />
          </div>
        </div>
      )}
      {currentStep === 'structure type' && (
        <div className='flex flex-col gap-5'>
          <div className='flex w-full items-center gap-5'>
            <div className='flex w-full flex-col gap-2'>
              <div className='flex items-center gap-2'>
                <span className='font-semibold text-lg'>
                  Structure Type
                </span>
                {structureTypeLoading && <span className='text-sm text-gray-500'>Loading...</span>}
              </div>
              <StructureTypeSelector showInvalid />
            </div>
            <div className='flex w-full flex-col gap-2'>
              <div className='flex items-center gap-2'>
                <span className='font-semibold text-lg'>
                  {structureTypeSelected
                    ? `${structureTypes.find((s) => s.id === structureTypeSelected)
                      ?.label || 'Unknown'
                    } Type`
                    : 'Structure Subtype'}
                </span>
                {structureTypeLoading && <span className='text-sm text-gray-500'>Loading...</span>}
              </div>
              <StructureSubypeSelector showInvalid />
            </div>
          </div>
          <div className='flex w-full items-start gap-5'>
            <div className='relative h-[120px] w-[200px] shrink-0'>
              {structureSubtypeSelected && (
                <img
                  className='rounded-lg'
                  src={structureImageUrl || undefined}
                  alt='Example of the structure type'
                />
              )}
            </div>
            <div className='flex flex-col gap-1 grow'>
              <div className='text-sm font-semibold'>
                {structureSubtypeSelected
                  ? structureTypes
                    .find((t) => t.id === structureTypeSelected)
                    ?.subtypes.find((s) => s.id === structureSubtypeSelected)
                    ?.label
                  : ''}
              </div>
              <div className='text-sm'>
                {structureSubtypeSelected
                  ? structureTypes
                    .find((t) => t.id === structureTypeSelected)
                    ?.subtypes.find((s) => s.id === structureSubtypeSelected)
                    ?.description
                  : ''}
              </div>
            </div>
          </div>
        </div>
      )}
      {currentStep === 'property type' && (
        <div className='flex flex-col items-center w-full gap-3'>
          <span className='font-semibold text-lg'>What type of property is this?</span>
          {propertyTypeAttribute && (
            <AttributeComponent
              showProgress={false}
              completed={!!propertyTypeAttribute.value}
              value={propertyTypeAttribute.value}
              onChange={(value) => handleChangeProjectSetting(propertyTypeAttribute.id, value)}
              {...{ ...propertyTypeAttribute }}
              required
            />
          )}
          {propertyTypeAttribute?.value === 'Other' && (
            <>
              <span className='font-semibold text-lg'>Property Type:</span>
              {otherPropertyTypeAttribute && (
                <AttributeComponent
                  showProgress={false}
                  completed={!!otherPropertyTypeAttribute.value}
                  value={otherPropertyTypeAttribute.value}
                  onChange={(value) => handleChangeProjectSetting(otherPropertyTypeAttribute.id, value)}
                  debounce={500}
                  {...{ ...otherPropertyTypeAttribute }}
                />
              )}
            </>
          )}
        </div>
      )}
      {currentStep === 'project type' && (
        <div className='flex flex-col items-center w-full gap-3'>
          <span className='font-semibold text-lg'>What type of project are we doing?</span>
          {projectTypeAttribute && (
            <AttributeComponent
              showProgress={false}
              completed={!!projectTypeAttribute.value}
              value={projectTypeAttribute.value}
              onChange={(value) => handleChangeProjectSetting(projectTypeAttribute.id, value)}
              {...{ ...projectTypeAttribute }}
              required
            />
          )}
          {projectTypeAttribute?.value === 'Replacing Old System' && (
            <>
              <span className='font-semibold text-lg'>How many systems are we replacing?</span>
              <div className='w-[70px]'>
                <NumberInput
                  value={existingSystems.length}
                  placeholder='0'
                  onChange={(value) => handleChangeNumberOfExistingSystems(value ?? 0)}
                  debounce={500}
                  invalid={existingSystems.length === 0}
                  min={0}
                />
              </div>
            </>
          )}
        </div>
      )}
      {currentStep === 'ductwork' && (
        <div className='flex flex-col items-center w-full gap-3'>
          <span className='font-semibold text-lg'>What type of ductwork does the structure have?</span>
          {ductworkAttribute && (
            <AttributeComponent
              showProgress={false}
              completed={!!ductworkAttribute.value}
              value={ductworkAttribute.value}
              onChange={(value) => handleChangeProjectSetting(ductworkAttribute.id, value)}
              {...{ ...ductworkAttribute }}
              required
            />
          )}
        </div>
      )}
      {currentStep === 'existing equipment' && (
        <div className='flex flex-col gap-5'>
          {existingSystems.map((system) => (
            <fieldset key={system.id} className='w-full flex flex-col items-center gap-5 border-2 border-gray-200 px-5 pt-3 pb-5 rounded-lg'>
              <legend className='px-1 font-semibold text-lg'>{system.name}</legend>
              <div className='w-full rounded-lg bg-yellow-50 flex items-center p-3 gap-3'>
                {!system.analyzingLabel && !system.analysisMessage && <div className='w-full grow shrink-0 basis-0'>Enter the model number or upload a picture of the label on your furnace/air handler to fill out automatically:</div>}
                {!system.analyzingLabel && system.analysisMessage && <div className='w-full grow shrink-0 basis-0'>{system.analysisMessage}</div>}
                {system.analyzingLabel && (
                  <div className='flex items-center gap-1'>
                    <svg className='w-4 h-4 animate-spin' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path fill='black' className="opacity-40" d="M256 64C150 64 64 150 64 256s86 192 192 192c70.1 0 131.3-37.5 164.9-93.6l.1 .1c-6.9 14.9-1.5 32.8 13 41.2c15.3 8.9 34.9 3.6 43.7-11.7c.2-.3 .4-.6 .5-.9l0 0C434.1 460.1 351.1 512 256 512C114.6 512 0 397.4 0 256S114.6 0 256 0c-17.7 0-32 14.3-32 32s14.3 32 32 32z" />
                      <path fill='black' d="M224 32c0-17.7 14.3-32 32-32C397.4 0 512 114.6 512 256c0 46.6-12.5 90.4-34.3 128c-8.8 15.3-28.4 20.5-43.7 11.7s-20.5-28.4-11.7-43.7c16.3-28.2 25.7-61 25.7-96c0-106-86-192-192-192c-17.7 0-32-14.3-32-32z" />
                    </svg>
                    <span>Analyzing image...</span>
                  </div>
                )}
                <div className={`grow shrink-0 basis-0 flex-col gap-2 ${system.analyzingLabel ? 'hidden' : 'flex'}`}>
                  <div className='w-full'>
                    <Text
                      value={system.modelNumber}
                      onChange={value => updateExistingSystemModelNumber(system, value)}
                      placeholder='Model Number'
                      debounce={500}
                    />
                  </div>
                  <input
                    type='file'
                    accept='image/*'
                    onChange={(e) => analyzeLabelImage(system, e.currentTarget.files?.[0])}
                    disabled={system.analyzingLabel}
                  />
                </div>
              </div>
              <span className='font-semibold text-lg'>What type of system is {system.name}?</span>
              <div className='inline-block'>
                <Select
                  value={system.type ?? ''}
                  onChange={value => handleUpdateExistingSystem(system.id, 'type', value || undefined)}
                  options={[
                    { label: 'Traditional Ducted System', value: 'ducted' },
                    { label: 'Ductless Mini-Split System', value: 'ductless' },
                    { label: 'BMAH/Hybrid System', value: 'hybrid' }
                  ]}
                  invalid={!system.type}
                  enableChips
                />
              </div>
              <span className='font-semibold text-lg'>What equipment are we replacing in {system.name}?</span>
              <div className='inline-block'>
                <Select
                  value={equipmentTypeValues[String(system.heatingEquipmentType)] ?? ''}
                  onChange={value => handleUpdateExistingSystemEquipmentType(system.id, value || undefined)}
                  options={[
                    { label: 'Indoor Unit', value: 'indoor' },
                    { label: 'Outdoor Unit', value: 'outdoor' },
                    { label: 'Both', value: 'both' }
                  ]}
                  invalid={system.heatingEquipmentType === undefined}
                  enableChips
                />
              </div>
              <span className='font-semibold text-lg'>What is the heating capacity of the furnace?</span>
              <div className='inline-block w-[170px]'>
                <NumberInput
                  value={system.heatingCapacity}
                  onChange={value => handleUpdateExistingSystem(system.id, 'heatingCapacity', value ?? undefined)}
                  placeholder='0 BTU'
                  suffix=' BTU'
                  invalid={!system.heatingCapacity}
                />
              </div>
              <span className='font-semibold text-lg'>What is the maximum cooling capacity of the furnace?</span>
              <div className='inline-block w-[170px]'>
                <NumberInput
                  value={system.coolingCapacity}
                  onChange={value => handleUpdateExistingSystem(system.id, 'coolingCapacity', value ?? undefined)}
                  placeholder='0 BTU'
                  suffix=' BTU'
                  invalid={!system.coolingCapacity}
                />
              </div>
              {!!system.type && system.type !== 'ductless' && (
                <>
                  <span className='font-semibold text-lg'>What is the airflow orientation of the furnace?</span>
                  <div className='inline-block'>
                    <Select
                      value={system.heatingOrientation ?? ''}
                      onChange={value => handleUpdateExistingSystem(system.id, 'heatingOrientation', value || undefined)}
                      options={[
                        { label: 'Upflow', value: 'upflow' },
                        { label: 'Downflow', value: 'downflow' },
                        { label: 'Horizontal', value: 'horizontal' }
                      ]}
                      invalid={!system.heatingOrientation}
                      enableChips
                    />
                  </div>
                  {system.type === 'ducted' && (
                    <>
                      <span className='font-semibold text-lg'>What kind of fuel does the furnace use?</span>
                      <div className='inline-block'>
                        <Select
                          value={system.heatingFuelType ?? ''}
                          onChange={value => handleUpdateExistingSystem(system.id, 'heatingFuelType', value || undefined)}
                          options={[
                            { label: 'Electricity', value: 'electric' },
                            { label: 'Natural Gas', value: 'natural gas' },
                            { label: 'Propane', value: 'lp' }
                          ]}
                          invalid={!system.heatingFuelType}
                          enableChips
                        />
                      </div>
                    </>
                  )}
                  <span className='font-semibold text-lg'>What is the {system.type === 'ducted' ? 'furnace' : 'air handler'} cabinet size of {system.name}?</span>
                  <div className='inline-block'>
                    <Select
                      value={system.existingCabinetSize ?? ''}
                      onChange={value => handleUpdateExistingSystem(system.id, 'existingCabinetSize', value || undefined)}
                      options={[
                        { value: 'A', label: 'A (14")' },
                        { value: 'B', label: 'B (17.5")' },
                        { value: 'C', label: 'C (21")' },
                        { value: 'D', label: 'D (24.5")' }
                      ]}
                      invalid={!system.existingCabinetSize}
                      enableChips
                    />
                  </div>
                </>
              )}
            </fieldset>
          ))}
        </div>
      )}
      {currentStep === 'equipment considerations' && (
        <div className='flex flex-col items-center w-full gap-3'>
          <span className='font-semibold text-lg text-center'>Do you want to see system options that include ductless mini-split equipment?</span>
          <div className='inline-block'>
            <Select
              // eslint-disable-next-line no-nested-ternary
              value={showDuctlessOptions === null ? '' : showDuctlessOptions ? 'Yes' : 'No'}
              onChange={(value) => setShowDuctlessOptions(value === '' ? null : value === 'Yes')}
              options={['Yes', 'No']}
              invalid={showDuctlessOptions === null}
              enableChips
            />
          </div>
        </div>
      )}
    </SteppedModal>
  );
}
