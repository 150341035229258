import React, { useContext, useEffect, useState, useRef } from 'react';
import GooglePlacesSearch from 'react-google-places-autocomplete';
import OpenAI from 'openai';
import { confirmationModal, errorModal, warningModal } from 'utils/helpers';
import { AxiosError } from 'axios';
import axios, { getLocationData, getWeatherData } from 'utils/api';
import { ProjectContext, UIContext, DataContext } from 'data/context';
import Button from 'components/inputs/button';
import StreetViewModal from 'components/modals/street-view-modal';
import { SingleValue } from 'react-select';
import { Option } from 'react-google-places-autocomplete/build/types';

export default function AddressInfo() {
  const { structureTypes } = useContext(DataContext);
  const {
    projectDetails,
    attomData,
    setAttomData,
    setAddressSearched,
    locationData,
    setLocationData,
    setPropertyLocation,
    propertySummary,
    setPropertySummary,
    setWeatherData,
    structureTypeSelected,
    setStructureTypeSelected,
    structureSubtypeSelected,
    setStructureSubtypeSelected,
    projectSettings,
    setProjectSettings
  } = useContext(ProjectContext);
  const { setShowPropertySummary } = useContext(UIContext);
  const streetViewModalState = useState(false);
  const [, setStreetViewModalShown] = streetViewModalState;
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [value, setValue] = useState<SingleValue<Option>>(null);
  const projectSettingsRef = useRef(projectSettings);
  const [abortController, setAbortController] = useState<AbortController | null>(null);
  const [searchClicked, setSearchClicked] = useState(false);

  useEffect(() => {
    projectSettingsRef.current = projectSettings;
  }, [projectSettings]);

  const updatePropertyAttributes = async () => {
    if (attomData) {
      const dataForSummary = {
        lot: attomData.lot,
        address: attomData.address,
        location: attomData.location,
        summary: attomData.summary,
        utilities: attomData.utilities,
        building: {
          size: attomData.building.size,
          rooms: attomData.building.rooms,
          interior: attomData.building.interior,
          construction: attomData.building.construction,
          parking: attomData.building.parking,
          summary: attomData.building.summary
        }
      };
      if (attomData.address.postal1) {
        getLocationData(attomData.address.postal1)
          .then((res) => setLocationData(res.data ?? null))
          .catch((error: AxiosError) => {
            warningModal('Failed to load location data');
            console.error('Error fetching location data', error);
          });
      }
      setProjectSettings((draft) => {
        const sqFtIndex = draft.findIndex((a) => a.code === 'square_footage');
        if (sqFtIndex !== -1 && !draft[sqFtIndex].value) {
          // eslint-disable-next-line no-param-reassign
          draft[sqFtIndex].value =
            attomData.building.size.bldgSize ||
            attomData.building.size.livingSize;
        }
        const ductworkIndex = draft.findIndex((a) => a.code === 'ductwork');
        if (ductworkIndex !== -1 && !draft[ductworkIndex].value) {
          // eslint-disable-next-line no-param-reassign
          draft[ductworkIndex].value = attomData.utilities.coolingType || attomData.utilities.heatingType ? 'Yes' : 'No';
        }
        const insulationIndex = draft.findIndex(
          (a) => a.code === 'insulation_level'
        );
        if (insulationIndex !== -1 && !draft[insulationIndex].value) {
          if (attomData.building.construction.condition === 'POOR') {
            // eslint-disable-next-line no-param-reassign
            draft[insulationIndex].value = 'Poor';
          }
          if (attomData.building.construction.condition === 'AVERAGE') {
            // eslint-disable-next-line no-param-reassign
            draft[insulationIndex].value = 'Good';
          }
          if (attomData.building.construction.condition === 'GOOD') {
            // eslint-disable-next-line no-param-reassign
            draft[insulationIndex].value = 'Great';
          }
        }
      });
      setPropertyLocation({
        lat: Number(attomData.location.latitude),
        lng: Number(attomData.location.longitude)
      });
      if (!structureTypeSelected || !structureSubtypeSelected) {
        const messages: OpenAI.Chat.Completions.ChatCompletionMessageParam[] = [
          {
            role: 'system',
            content: `
              We are looking for a structure type for the structure based off of property data provided by the user. Please choose the most appropriate structure type based off of the property data.
              Here are all of the possible structure types (listed under label with attached description) that we can choose from, you will respond with the id and nothing else: 
              ${JSON.stringify(structureTypes.flatMap((t) => t.subtypes.map((st) => ({ id: st.id, label: st.label, description: st.description }))))}.
            `
          },
          {
            role: 'user',
            content: `Here is the property data, please respond with the id of the best matching structure type based on this data: ${JSON.stringify(dataForSummary)}`
          }
        ];
        try {
          const reply = (await axios.post('ai', messages)).data;
          const suggestedSubtype = reply.content || 'None.';
          const suggestedSubtypeId = suggestedSubtype.replace(/\D/g, '');
          if (Number(suggestedSubtypeId)) {
            const suggestedStructureType = structureTypes.find((t) =>
              t.subtypes.some((st) => st.id === Number(suggestedSubtypeId))
            );
            if (suggestedStructureType) {
              setStructureTypeSelected(suggestedStructureType.id);
              setStructureSubtypeSelected(Number(suggestedSubtypeId));
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
      if (!propertySummary) {
        setPropertySummary('Loading...');
        const messages: OpenAI.Chat.Completions.ChatCompletionMessageParam[] = [
          {
            role: 'system',
            content: `Here is the data for the current property: ${JSON.stringify(dataForSummary)}`
          },
          {
            role: 'user',
            content: 'Summarize the property in 2 sentences or less while highlighting important details.'
          }
        ];
        try {
          const reply = (await axios.post('ai', messages)).data;
          setPropertySummary(reply.content);
        } catch (error) {
          console.error(error);
          setPropertySummary('Error generating property summary.');
        }
      }
      if (attomData.address.postal1) {
        const newWeatherData = await getWeatherData(attomData.address.postal1);
        setWeatherData(newWeatherData);
      } else {
        errorModal('No zip code found in attom data!');
      }
    } else {
      setPropertyLocation(null);
      setPropertySummary(null);
      if (!locationData && projectDetails?.postal) {
        getLocationData(projectDetails.postal)
          .then((res) => setLocationData(res.data ?? null))
          .catch((error: AxiosError) => {
            warningModal('Failed to load location data');
            console.error('Error fetching location data', error);
          });
      }
      if (locationData?.zipcode || projectDetails?.postal) {
        const newZipCode = locationData?.zipcode || projectDetails?.postal;
        if (newZipCode) {
          const newWeatherData = await getWeatherData(newZipCode);
          setWeatherData(newWeatherData);
        }
      }
    }
  };

  function normalizeAddress(address) {
    return address
      .replace(/, USA$/i, '') // Remove ", USA" at the end if present
      .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
      .toLowerCase()
      .trim();
  }

  useEffect(() => {
    if (!isFirstRender) {
      updatePropertyAttributes();
    } else {
      setIsFirstRender(false);
    }
  }, [attomData]);

  const lookupAddress = async (address: string, controller: AbortController, retries = 3) => {
    const makeRequest = async (attempt: number) => {
      if (attempt > retries) {
        errorModal(`Error fetching property data for ${address}. Please confirm the address is correct.`);
        setSearchClicked(false);
        return;
      }
      try {
        console.log(`Attempt ${attempt}: Fetching data for address: ${address}`);
        const newPropertyData = (await axios.get(`property-data?address=${normalizeAddress(address)}`, { signal: controller.signal })).data;
        setAttomData(newPropertyData);
        setSearchClicked(false);
      } catch (err: any) {
        if (err.name !== 'CanceledError') {
          console.error(`Attempt ${attempt} failed: ${err} `);
          setTimeout(() => makeRequest(attempt + 1), 3000);
        } else {
          console.log('Request canceled');
        }
      } finally {
        setAddressSearched(true);
      }
    };
    makeRequest(1);
  };

  const handleSearchClick = (val) => {
    if (value?.value?.place_id) {
      const newAbortController = new AbortController();
      setAbortController(newAbortController);
      setSearchClicked(true);
      lookupAddress(value.label, newAbortController);
    }
    if (val) {
      const newAbortController = new AbortController();
      setAbortController(newAbortController);
      setSearchClicked(true);
      lookupAddress(val.label, newAbortController);
    }
  };

  useEffect(() => () => {
    if (abortController) abortController.abort();
  }, [abortController]);

  const handleOnChange = (val: SingleValue<Option>) => {
    setValue(val);
    handleSearchClick(val);
  };

  const handleEditClick = () => {
    confirmationModal(
      'Are you sure you want to change the location? This will reset your structure, configurations, and selected systems!',
      () => {
        setAttomData(null);
        setPropertyLocation(null);
        setPropertySummary(null);
        setWeatherData(null);
        setValue(null);
      }
    );
  };

  return (
    <>
      <div className='flex items-center gap-3 w-full'>
        {attomData ? (
          <div className='flex items-center gap-3'>
            <Button
              text='Street View'
              color='secondary'
              onClick={() => setStreetViewModalShown(true)}
            />
            <Button
              text='Property Summary'
              color='secondary'
              onClick={() => setShowPropertySummary(true)}
            />
            <Button
              text='Change Address'
              color='secondary'
              onClick={handleEditClick}
            />
          </div>
        ) : (
          <div className='flex items-center gap-3 w-full'>
            <div className='grow'>
              <GooglePlacesSearch
                apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                selectProps={{
                  value,
                  onChange: handleOnChange,
                  placeholder: 'Lookup address...',
                  isClearable: true,
                  className: '[&_input]:!ring-0',
                  classNames: {
                    input: () => '!cursor-text',
                    dropdownIndicator: () => '!cursor-pointer',
                    clearIndicator: () => '!cursor-pointer',
                    option: () => '!cursor-pointer'
                  },
                  onKeyDown: (e) => {
                    if (e.key === 'Enter') {
                      handleSearchClick(value);
                    }
                  }
                }}
              />
            </div>
            <Button
              color='primary'
              text={searchClicked ? 'Searching...' : 'Search'}
              onClick={handleSearchClick}
              loading={searchClicked}
              disabled={searchClicked}
            />
          </div>
        )}
      </div>
      <StreetViewModal openState={streetViewModalState} />
    </>
  );
}
