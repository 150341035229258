import React from 'react';

type ProgressBarProps = {
  progress: number;
  showLabel?: boolean;
  rounded?: boolean;
};

export default function ProgressBar({
  progress,
  showLabel = false,
  rounded = false
}: ProgressBarProps) {
  return (
    <div className={`w-full bg-gray-200 ${showLabel ? 'h-4' : 'h-2'} ${rounded && 'rounded-full'}`}>
      <div className={`h-full bg-green-500 flex items-center justify-center ${rounded && 'rounded-full'}`} style={{ width: `${Math.floor(progress)}%` }}>
        {showLabel && (
          <span className='text-sm text-white font-semibold'>{Math.floor(progress)}%</span>
        )}
      </div>
    </div>
  );
}