import React, { useContext, useEffect, useRef } from 'react';
import { useDrop } from 'react-dnd';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { DataContext, ConfigurationContext, ProjectContext, UIContext } from 'data/context';
import { confirmationModal, createActiveItem, errorModal } from 'utils/helpers';
import { createLevel, createSection, createSpace, getAllLabelsOfType } from 'utils/structure';
import LevelComponent from 'components/dnd/level';
import clsx from 'clsx';

type StructurePanelProps = {
  include?: ('levels' | 'sections' | 'spaces')[];
  focus?: 'levels' | 'sections' | 'spaces';
  showBorder?: boolean;
};

export default function StructurePanel({
  include = ['levels', 'sections', 'spaces'],
  focus,
  showBorder = false
}: StructurePanelProps) {
  const {
    items,
    fenestration
  } = useContext(DataContext);
  const {
    structure,
    setStructure,
    configurations,
    selectedSystems
  } = useContext(ConfigurationContext);
  const { projectSettings } = useContext(ProjectContext);
  const {
    setActiveItem,
    estimatingStructure
  } = useContext(UIContext);
  const projectSettingsRef = useRef(projectSettings);
  const structureRef = useRef(structure);
  const [animate] = useAutoAnimate({ duration: 150 });

  useEffect(() => {
    projectSettingsRef.current = projectSettings;
    structureRef.current = structure;
  }, [projectSettings, structure]);

  const addLevel = (itemId: number) => {
    const levelItem = items.find((i) => i.id === itemId);
    if (levelItem) {
      let levelNumber = 1;
      const existingLevelLabels = getAllLabelsOfType(structureRef.current, 'level');
      while (existingLevelLabels.includes(`${levelItem.label} ${levelNumber}`)) {
        levelNumber += 1;
      }
      const newLevel = createLevel(levelItem, {
        data: {
          label: levelItem.code === 'level' ? `${levelItem.label} ${levelNumber}` : levelItem.label,
        },
        attributesToInherit: projectSettingsRef.current
      });
      setStructure((draft) => {
        let newStructure;
        if (levelItem.code === 'attic') {
          newStructure = [newLevel, ...draft];
        } else if (levelItem.code === 'basement' || levelItem.code === 'crawlspace') {
          newStructure = [...draft, newLevel];
        } else {
          newStructure = [
            ...draft.filter((l) => items.find((i) => i.id === l.itemId)?.code === 'attic'),
            newLevel,
            ...draft.filter((l) => items.find((i) => i.id === l.itemId)?.code !== 'attic')
          ];
        }
        return newStructure;
      });
    } else {
      console.error('Level item not found');
      errorModal(`Level item not found`);
    }
  };

  const addSpace = (itemId: number) => {
    const levelItem = items.find((i) => i.code === 'level');
    const spaceItem = items.find((i) => i.id === itemId);
    if (levelItem && spaceItem) {
      let spaceNumber = 2;
      let sectionNumber = 1;
      let levelNumber = 1;
      const existingSpaceLabels = getAllLabelsOfType(structureRef.current, 'space');
      const existingSectionLabels = getAllLabelsOfType(structureRef.current, 'section');
      const existingLevelLabels = getAllLabelsOfType(structureRef.current, 'level');

      const isMultipleSpaces = existingSpaceLabels.filter(label => label.startsWith(spaceItem.label)).length > 0;

      while (existingSpaceLabels.includes(`${spaceItem.label} ${spaceNumber}`)) {
        spaceNumber += 1;
      }
      while (existingSectionLabels.includes(`Area ${sectionNumber}`)) {
        sectionNumber += 1;
      }
      while (existingLevelLabels.includes(`${levelItem.label} ${levelNumber}`)) {
        levelNumber += 1;
      }

      const newSpaceLabel = isMultipleSpaces ? `${spaceItem.label} ${spaceNumber}` : spaceItem.label;

      const newSpace = createSpace(spaceItem, {
        data: {
          label: newSpaceLabel
        },
        attributesToInherit: projectSettingsRef.current
      });
      const newLevel = createLevel(levelItem, {
        data: {
          label: `${levelItem.label} ${levelNumber}`,
          sections: [
            createSection({
              data: {
                label: `Area ${sectionNumber}`,
                spaces: [newSpace]
              }
            })
          ]
        },
        attributesToInherit: projectSettingsRef.current
      });
      setStructure((draft) => [
        ...draft.filter((l) => items.find((i) => i.id === l.itemId)?.code === 'attic'),
        newLevel,
        ...draft.filter((l) => items.find((i) => i.id === l.itemId)?.code !== 'attic')
      ]);
      setActiveItem(createActiveItem({ type: 'space', item: newSpace, items, fenestration }));
    } else {
      console.error('Level/space item not found');
      errorModal(`Level/space item not found`);
    }
  };

  const moveSection = (sectionId: string) => {
    setStructure((draft) => {
      const fromLevelIndex = draft.findIndex((l) => l.sections.some((s) => s.uniqueId === sectionId));
      if (fromLevelIndex !== -1) {
        const fromSectionIndex = draft[fromLevelIndex].sections.findIndex((s) => s.uniqueId === sectionId);
        if (fromSectionIndex !== -1) {
          const section = draft[fromLevelIndex].sections.splice(fromSectionIndex, 1)[0];
          const levelItem = items.find(i => i.code === 'level');
          if (levelItem) {
            let levelNumber = 1;
            const existingLevelLabels = getAllLabelsOfType(structureRef.current, 'level');
            while (existingLevelLabels.includes(`${levelItem.label} ${levelNumber}`)) {
              levelNumber += 1;
            }
            const newLevel = createLevel(levelItem, {
              data: {
                label: `${levelItem.label} ${levelNumber}`,
                sections: [section]
              },
              attributesToInherit: projectSettingsRef.current,
            });
            const hasAttic = draft.some(l => l.itemId === items.find(i => i.code === 'attic')?.id);
            const atticIndex = draft.findIndex((l) => items.find((i) => i.id === l.itemId)?.code !== 'attic');
            draft.splice(hasAttic ? atticIndex : 0, 0, newLevel);
          }
        }
      }
    });
  };

  const moveSpace = (spaceId: string) => {
    setStructure((draft) => {
      const fromLevelIndex = draft.findIndex((l) => l.sections.some((s) => s.spaces.some(sp => sp.uniqueId === spaceId)));
      if (fromLevelIndex !== -1) {
        const fromSectionIndex = draft[fromLevelIndex].sections.findIndex((s) => s.spaces.some(sp => sp.uniqueId === spaceId));
        if (fromSectionIndex !== -1) {
          const fromSpaceIndex = draft[fromLevelIndex].sections[fromSectionIndex].spaces.findIndex((sp) => sp.uniqueId === spaceId);
          if (fromSpaceIndex !== -1) {
            const levelItem = items.find(i => i.code === 'level');
            if (levelItem) {
              const space = draft[fromLevelIndex].sections[fromSectionIndex].spaces.splice(fromSpaceIndex, 1)[0];
              const newSection = createSection({
                data: {
                  label: 'Area 1',
                  spaces: [space]
                }
              });
              let levelNumber = 1;
              const existingLevelLabels = getAllLabelsOfType(structureRef.current, 'level');
              while (existingLevelLabels.includes(`${levelItem.label} ${levelNumber}`)) {
                levelNumber += 1;
              }
              const newLevel = createLevel(levelItem, {
                data: {
                  label: `${levelItem.label} ${levelNumber}`,
                  sections: [newSection]
                },
                attributesToInherit: projectSettingsRef.current,
              });
              const hasAttic = draft.some(l => l.itemId === items.find(i => i.code === 'attic')?.id);
              const atticIndex = draft.findIndex((l) => items.find((i) => i.id === l.itemId)?.code !== 'attic');
              draft.splice(hasAttic ? atticIndex : 0, 0, newLevel);
            }
          }
        }
      }
    });
  };

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ['new-level', 'new-space', 'section', 'space'],
    drop: (
      item: {
        id: string;
        label: string;
        type: 'new-level' | 'new-space' | 'section' | 'space';
        itemId?: number;
      },
      monitor
    ) => {
      if (item.type === 'new-space' && item.itemId && !monitor.didDrop()) {
        if (configurations.length > 0 || selectedSystems.length > 0) {
          confirmationModal('Are you sure you want to add a space? This will reset all configurations and selected systems!', () => {
            addSpace(item.itemId as number);
          });
        } else {
          addSpace(item.itemId);
        }
      }
      if (item.type === 'new-level' && item.itemId && !monitor.didDrop()) {
        if (configurations.length > 0 || selectedSystems.length > 0) {
          confirmationModal('Are you sure you want to add a level? This will reset all configurations and selected systems!', () => {
            addLevel(item.itemId ?? 0);
          });
        } else {
          addLevel(item.itemId);
        }
      }
      if (item.type === 'section' && !monitor.didDrop()) {
        if (configurations.length > 0 || selectedSystems.length > 0) {
          confirmationModal('Are you sure you want to move this section? This will reset all configurations and selected systems!', () => {
            moveSection(item.id);
          });
        } else {
          moveSection(item.id);
        }
      }
      if (item.type === 'space' && !monitor.didDrop()) {
        if (configurations.length > 0 || selectedSystems.length > 0) {
          confirmationModal('Are you sure you want to move this space? This will reset all configurations and selected systems!', () => {
            moveSpace(item.id);
          });
        } else {
          moveSpace(item.id);
        }
      }
    },
    collect: (monitor) => ({
      canDrop: !!monitor.canDrop(),
      isOver: !!monitor.isOver({ shallow: true })
    })
  }), [configurations, selectedSystems]);

  const classes = clsx(
    `relative flex min-h-[500px] flex-col rounded-lg p-5 border-2`,
    isOver ? 'bg-green-50' : 'bg-white',
    // eslint-disable-next-line no-nested-ternary
    canDrop ? 'border-dashed border-green-500' : showBorder ? 'border-dashed border-gray-300' : 'border-transparent'
  );

  return (
    <div ref={drop} className='grow basis-0'>
      <div
        ref={animate}
        className={classes}
      >
        {!!structure.length && (
          <div className='h-0 border-x-[100px] border-b-[100px] border-x-transparent border-b-gray-200 -mx-3' />
        )}
        {!estimatingStructure && structure.map((level) => (
          <LevelComponent
            key={level.uniqueId}
            level={level}
            showSections={include.includes('sections')}
            showSpaces={include.includes('spaces')}
            focus={focus}
          />
        ))}
        {estimatingStructure && (
          <div className='absolute inset-0 flex items-center justify-center'>
            <div className="flex items-center gap-1">
              <svg className='w-4 h-4 animate-spin' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill='gray' className="opacity-40" d="M256 64C150 64 64 150 64 256s86 192 192 192c70.1 0 131.3-37.5 164.9-93.6l.1 .1c-6.9 14.9-1.5 32.8 13 41.2c15.3 8.9 34.9 3.6 43.7-11.7c.2-.3 .4-.6 .5-.9l0 0C434.1 460.1 351.1 512 256 512C114.6 512 0 397.4 0 256S114.6 0 256 0c-17.7 0-32 14.3-32 32s14.3 32 32 32z" />
                <path fill='gray' d="M224 32c0-17.7 14.3-32 32-32C397.4 0 512 114.6 512 256c0 46.6-12.5 90.4-34.3 128c-8.8 15.3-28.4 20.5-43.7 11.7s-20.5-28.4-11.7-43.7c16.3-28.2 25.7-61 25.7-96c0-106-86-192-192-192c-17.7 0-32-14.3-32-32z" />
              </svg>
              <span className='text-gray-400'>Generating default structure...</span>
            </div>
          </div>
        )}
        {(structure.length === 0 && !estimatingStructure) && (
          <div className='absolute inset-0 flex items-center justify-center'>
            <span className='text-gray-400'>Drag items here</span>
          </div>
        )}
      </div>
    </div >
  );
}

StructurePanel.defaultProps = {
  include: ['levels', 'sections', 'spaces'],
  focus: undefined,
  showborder: false
};