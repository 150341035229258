import {
  AccessoryProduct,
  AirHandlerProduct,
  Attribute,
  CondenserProduct,
  Configuration,
  ProjectWithProjectId,
  SystemOption,
  ValidSystem,
  RankedSystem,
  SystemOptionConfiguration,
  SystemFilters,
  Condenser,
  FenestrationItem,
  Item,
  Space,
  Level,
  ActiveItem,
  Section,
  SystemOptionTag,
  OversizeRange
} from 'data/types';
import toast from 'react-hot-toast';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'utils/api';
import { diff } from 'deep-object-diff';
import { renderToString } from 'react-dom/server';
import React, { ReactElement } from 'react';
import { airHandlerTypeCodes } from 'data/constants';
import { fontFamily as tailwindFonts } from 'tailwindcss/defaultTheme';
import { v4 as uuid } from 'uuid';

const sweetalert = withReactContent(Swal);

export function abbreviateNumber(value: number): string {
  let newValue: string | number = value;
  if (value >= 1000) {
    const suffixes = ['', 'K', 'M', 'B', 'T'];
    const suffixNum = Math.floor(value.toString().length / 3);
    let shortValue = parseFloat(
      (suffixNum !== 0 ? value / 1000 ** suffixNum : value).toFixed(1)
    );
    if (shortValue % 1 !== 0) {
      shortValue = parseFloat(shortValue.toFixed(1));
    }
    newValue = shortValue + suffixes[suffixNum];
  }
  return newValue.toString();
}

export function calculateOperatingCost(
  coolingCapacity: number,
  seer: number,
  costPerKWH: number
) {
  return Math.round(
    ((24 * 1200) / 12) * (coolingCapacity / seer) * (costPerKWH / 1000)
  );
}

export function formatUSD(value: number, showZeroCents = false) {
  const hasCents = value % 1 !== 0;
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: hasCents || showZeroCents ? 2 : 0
  }).format(value);
}

export function getNthPercentile(array: number[], percentile: number) {
  return array.sort((a, b) => b - a)[Math.floor(array.length * (percentile / 100))];
}

const projectDataEndpoints = {
  structure: 'project-structure',
  configurations: 'project-configurations',
  configurationOptions: 'project-config-options',
  projectSettings: 'project-settings',
  customerValues: 'project-customer-values',
  projectInfo: 'project-info',
  warmUpNotes: 'project-notes',
  propertyData: 'project-property-data',
  selectedSystems: 'selected-systems',
  sliderValues: 'slider-values'
};

const previousSavedValues: {
  structure?: any,
  configurations?: any,
  configurationOptions?: any,
  projectSettings?: any,
  customerValues?: any,
  projectInfo?: any,
  warmUpNotes?: any,
  propertyData?: any,
  selectedSystems?: any,
  sliderValues?: any
} = {};

export async function saveProjectData(
  projectId: number,
  type: keyof typeof projectDataEndpoints,
  data: object | string | null,
) {
  if (projectId) {
    try {
      const payload = typeof data === 'object' ? JSON.stringify(data) : data;
      const result = await axios.patch(projectDataEndpoints[type], {
        projectId,
        data: payload
      });
      if (process.env.NODE_ENV === 'development') {
        if (typeof data === 'object' && data) {
          const difference = diff(previousSavedValues[type], data);
          console.log(`Saving ${type}...`, { data, difference });
          previousSavedValues[type] = data;
        } else {
          console.log(`Saving ${type}...`, { data });
        }
      }
      return result.status === 200;
    } catch (error) {
      console.error(error);
    }
  }
  console.error('Cannot save data (missing project ID)');
  return false;
}

export async function createDebugSnapshot(
  data: Partial<ProjectWithProjectId>
) {
  console.log('Saving debug snapshot...', data);
  const snapshotId = (await axios.post('debug-snapshot', data)).data;
  return snapshotId ?? false;
}

export function removeNullishValues<T>(obj: T & object): T {
  if (typeof obj === 'object') {
    const newObj = structuredClone(obj);
    Object.keys(newObj).forEach((key) => {
      if (newObj[key] && typeof newObj[key] === 'object') {
        newObj[key] = removeNullishValues(newObj[key]);
      } else if (newObj[key] === null || newObj[key] === undefined) {
        // eslint-disable-next-line no-param-reassign
        delete newObj[key];
      }
    });
    return newObj;
  }
  return obj;
}

export function notification(message: string, type: 'success' | 'warning' | 'error') {
  if (type === 'success') {
    toast.success(message);
  }
  if (type === 'warning') {
    toast.error(message, { iconTheme: { primary: 'orange', secondary: 'white' } });
  }
  if (type === 'error') {
    toast.error(message);
  }
}

export function confirmationModal(message: string | ReactElement, confirmAction: () => void, title: string = 'Are you sure?', cancelButtonText: string = 'Cancel') {
  sweetalert.fire({
    title,
    html: typeof message === 'string' ? `<span>${message}</span>` : renderToString(message),
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText,
    buttonsStyling: false,
    customClass: {
      confirmButton: 'inline-flex items-center justify-center rounded-md px-3 text-sm font-medium leading-4 shadow-sm border focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 border-transparent bg-blue-600 text-white hover:bg-blue-700 py-3 m-2',
      cancelButton: 'inline-flex items-center justify-center rounded-md px-3 text-sm font-medium leading-4 shadow-sm border focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 border-transparent bg-red-600 text-white hover:bg-red-700 py-3 m-2',
    }
  }).then((result) => {
    if (result.isConfirmed) {
      confirmAction();
    }
  });
}

export function warningModal(message: string, options?: SweetAlertOptions) {
  sweetalert.fire({
    ...options,
    title: 'Warning',
    text: message,
    icon: 'warning',
    showCancelButton: false,
    confirmButtonText: 'OK',
    buttonsStyling: false,
    customClass: {
      confirmButton: 'inline-flex items-center justify-center rounded-md px-3 text-sm font-medium leading-4 shadow-sm border focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 border-transparent bg-blue-600 text-white hover:bg-blue-700 py-3 m-2',
    }
  });
}

export function errorModal(message: string, options?: SweetAlertOptions) {
  sweetalert.fire({
    ...options,
    title: 'Error',
    text: message,
    icon: 'error',
    showCancelButton: false,
    confirmButtonText: 'OK',
    buttonsStyling: false,
    customClass: {
      confirmButton: 'inline-flex items-center justify-center rounded-md px-3 text-sm font-medium leading-4 shadow-sm border focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 border-transparent bg-blue-600 text-white hover:bg-blue-700 py-3 m-2',
    }
  });
}

export function successModal(messageOrElement: string | ReactElement, options?: SweetAlertOptions) {
  sweetalert.fire({
    ...options,
    title: 'Success',
    html: typeof messageOrElement === 'string' ? `<span>${messageOrElement}</span>` : renderToString(messageOrElement),
    icon: 'success',
    showCancelButton: false,
    confirmButtonText: 'OK',
    buttonsStyling: false,
    customClass: {
      confirmButton: 'inline-flex items-center justify-center rounded-md px-3 text-sm font-medium leading-4 shadow-sm border focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 border-transparent bg-blue-600 text-white hover:bg-blue-700 py-3 m-2',
    }
  });
}

export function sortAttributes(attributes: Attribute[]) {
  const sortedAttributes = [...attributes]
    .sort((a, b) => a.label.localeCompare(b.label))
    .sort((a, b) => a.priority - b.priority)
    .sort((a, b) => (a.group || 'zzz').localeCompare((b.group || 'zzz')))
    .sort((a, b) => (b.type === 'checkbox' ? 0 : 1) - (a.type === 'checkbox' ? 0 : 1));

  attributes.forEach(att => {
    if (att.parentAttributes) {
      const lastValidParentAttribute = [...sortedAttributes].reverse().find(a => att.parentAttributes?.some(pa => pa.attributeId === a.id && pa.value === a.value));
      if (lastValidParentAttribute) {
        const fromIndex = sortedAttributes.findIndex(a => a.id === att.id);
        const toIndex = sortedAttributes.findIndex(a => a.id === lastValidParentAttribute.id);
        if (fromIndex !== -1 && toIndex !== -1) {
          const splicedAtt = sortedAttributes.splice(fromIndex, 1)[0];
          sortedAttributes.splice(fromIndex > toIndex ? toIndex + 1 : toIndex, 0, splicedAtt);
        }
      }
    }
  });

  return sortedAttributes;
}

export function filterAttributes(attributes: Attribute[], type: 'normal' | 'advanced' | 'both' = 'both', group?: boolean | string) {
  return attributes.filter((a) =>
    (typeof group === 'string' ? a.group === group : true) &&
    (typeof group === 'boolean' ? !!a.group === group : true) &&
    // eslint-disable-next-line no-nested-ternary
    (type === 'normal' ? !a.advanced : type === 'advanced' ? a.advanced : true) &&
    (
      !a.parentAttributes ||
      a.parentAttributes.some((pa) => pa.value?.toString() === attributes.find((na) => na.id === pa.attributeId)?.value?.toString()) ||
      a.parentAttributes.every((pa) => !attributes.find((na) => na.id === pa.attributeId))
    )
  );
}

export function getValidManufacturers(config: Configuration) {
  let validManufacturers: string[] = [];
  if (config.condensers.length >= 1) {
    config.condensers.forEach((cond) => {
      const newManufacturers = cond.rankedSystems.filter(system => cond.zones.every((z) => {
        const zoneIds = [
          system.airHandler1Unique,
          system.airHandler2Unique,
          system.airHandler3Unique,
          system.airHandler4Unique,
          system.airHandler5Unique
        ];
        const airHandlerTypes = [
          system.airHandler1Type,
          system.airHandler2Type,
          system.airHandler3Type,
          system.airHandler4Type,
          system.airHandler5Type
        ];
        return (
          !z.selectedProduct ||
          zoneIds.some(
            (zoneId, index) =>
              z.selectedProduct &&
              z.uniqueId === zoneId &&
              airHandlerTypeCodes[z.selectedProduct.type] ===
              airHandlerTypes[index]
          )
        );
      })).map(system => system.manufacturer);

      if (validManufacturers.length === 0) {
        validManufacturers = newManufacturers;
      } else {
        validManufacturers = newManufacturers.filter((manufacturer) => validManufacturers.includes(manufacturer));
      }
    });
  }
  return Array.from(new Set(validManufacturers));
}

export function getCondenserOptions(condenser: Condenser, condenserProducts: CondenserProduct[]) {
  const validProductIds = [
    ...new Set(
      condenser.rankedSystems
        .filter((s) => {
          let valid = false;
          const zoneIds = [
            s.airHandler1Unique,
            s.airHandler2Unique,
            s.airHandler3Unique,
            s.airHandler4Unique,
            s.airHandler5Unique
          ];
          const airHandlerTypes = [
            s.airHandler1Type,
            s.airHandler2Type,
            s.airHandler3Type,
            s.airHandler4Type,
            s.airHandler5Type
          ];
          if (
            condenser.zones.every((zone) => zoneIds.some(
              (zoneId, index) =>
                zone.uniqueId === zoneId &&
                zone.selectedProduct &&
                airHandlerTypeCodes[zone.selectedProduct.type] ===
                airHandlerTypes[index]
            ))
          ) {
            valid = true;
          }
          return valid;
        })
        .map((s) => s.condenserProductId)
    )
  ];
  const validProducts = validProductIds.map(id => {
    const product = condenserProducts.find(p => p.productId === id);
    if (!product) {
      console.error(`Product with id ${id} not found`);
    }
    return product;
  }).filter(p => p) as CondenserProduct[];
  return validProducts;
};

export function createSystemOption(
  systems: ValidSystem[],
  config: Configuration,
  condenserProducts: CondenserProduct[],
  airHandlerProducts: AirHandlerProduct[],
  ductingKitProducts: AccessoryProduct[],
  name = 'Custom System',
  tags: SystemOptionTag[] = [],
  totalRebates: number | undefined = undefined,
  optimalDesignScore: number = 0
): SystemOption {
  const newConfiguration = structuredClone(config) as Configuration;
  newConfiguration.condensers.forEach((cond, condIndex) => {
    newConfiguration.condensers[condIndex].validSystems = [];
    newConfiguration.condensers[condIndex].rankedSystems = [];
    const newCondenserProduct = condenserProducts.find((p) => p.productId === systems.find((s) => s.condenserUnique === cond.uniqueId)?.condenserProductId);
    if (newCondenserProduct) {
      // eslint-disable-next-line no-param-reassign
      newConfiguration.condensers[condIndex].selectedProduct = newCondenserProduct;
      cond.zones.forEach((zone, zoneIndex) => {
        let productId: number | null = null;
        const ductingKitIds: number[] = [];
        systems.forEach((system) => {
          if (system.airHandler1Unique === zone.uniqueId) {
            productId = system.airHandler1ProductId;
            ductingKitIds.push(...JSON.parse(system.zone1DuctingKitsJson ?? '[]').map((kit) => kit.ProductID));
          } else if (system.airHandler2Unique === zone.uniqueId) {
            productId = system.airHandler2ProductId;
            ductingKitIds.push(...JSON.parse(system.zone2DuctingKitsJson ?? '[]').map((kit) => kit.ProductID));
          } else if (system.airHandler3Unique === zone.uniqueId) {
            productId = system.airHandler3ProductId;
            ductingKitIds.push(...JSON.parse(system.zone3DuctingKitsJson ?? '[]').map((kit) => kit.ProductID));
          } else if (system.airHandler4Unique === zone.uniqueId) {
            productId = system.airHandler4ProductId;
            ductingKitIds.push(...JSON.parse(system.zone4DuctingKitsJson ?? '[]').map((kit) => kit.ProductID));
          } else if (system.airHandler5Unique === zone.uniqueId) {
            productId = system.airHandler5ProductId;
            ductingKitIds.push(...JSON.parse(system.zone5DuctingKitsJson ?? '[]').map((kit) => kit.ProductID));
          }
        });
        const newAirHandlerProduct = airHandlerProducts.find(p => p.productId === productId);
        if (newAirHandlerProduct) {
          // eslint-disable-next-line no-param-reassign
          newConfiguration.condensers[condIndex].zones[zoneIndex].selectedProduct = {
            ...newAirHandlerProduct,
            accessories: [
              ...(newAirHandlerProduct?.accessories ?? []),
              ...ductingKitIds.map((id) => ductingKitProducts.find((kit) => kit.productId === id)).filter(p => p) as AccessoryProduct[]
            ]
          };
        }
      });
    }
  });
  const productAestheticsRanks: number[] = [];
  const productInstallationDifficultyRanks: number[] = [];
  systems.forEach((system) => {
    [
      system.airHandler1ProductId,
      system.airHandler2ProductId,
      system.airHandler3ProductId,
      system.airHandler4ProductId,
      system.airHandler5ProductId
    ].filter(id => id !== null).forEach((productId) => {
      const product = airHandlerProducts.find((p) => p.productId === productId);
      if (product) {
        productAestheticsRanks.push(product.aestheticsRank);
        productInstallationDifficultyRanks.push(product.installComplexityRank);
      }
    });
  });
  const manufacturers = Array.from(new Set(systems.map((system) => system.manufacturer)));
  const newTags: SystemOptionTag[] = [...tags];
  const condensers = systems.map((s) => condenserProducts.find((p) => p.productId === s.condenserProductId)).filter((p) => p) as CondenserProduct[];
  if (manufacturers.length > 1) {
    tags.push('Mixed Manufacturers');
  }
  const totalSizes = newConfiguration.condensers.map(cond => {
    const { selectedProduct } = cond;
    if (selectedProduct) {
      const { depth, height, width } = selectedProduct.dimensions;
      return depth * height * width;
    }
    return 0;
  });
  const totalSize = totalSizes.reduce((acc, size) => acc + size, 0);
  let outdoorVisibility: 'Least Visible' | 'Less Visible' | 'Most Visible' | undefined;
  if (totalSize <= 12498.42) {
    outdoorVisibility = 'Least Visible';
  } else if (totalSize <= 20167.73) {
    outdoorVisibility = 'Less Visible';
  } else {
    outdoorVisibility = 'Most Visible';
  }
  return {
    id: `${config.uniqueId}-${systems.map((s) => s.id).join('-')}`,
    uniqueId: uuid(),
    name,
    price: systems.reduce(
      (total, system) => system.baseRetailPrice + total,
      0
    ),
    numberOfCondensers: config.condensers.length,
    numberOfZones: config.condensers.reduce(
      (total, condenser) => total + condenser.zones.length,
      0
    ),
    btu: condensers.reduce(
      (total, product) => product.coolingBTU + total,
      0
    ),
    heatingScore:
      systems
        .map(
          (system) => system.heatingScore
        )
        .reduce((total, score) => total + score, 0) /
      systems.length,
    coolingScore:
      systems
        .map(
          (system) => system.coolingScore
        )
        .reduce((total, score) => total + score, 0) /
      systems.length,
    condenserCoverage: systems.map((system, systemIndex) => {
      const condenserName = config.condensers.find((cond) => cond.uniqueId === system.condenserUnique)?.name ?? `Condenser ${systemIndex}`;
      return ({
        condenserName,
        daysOfInsufficientCooling: system.daysOfInsufficientCooling,
        avgCoolingPercent: system.avgCoolingPercent,
        daysOfInsufficientHeating: system.daysOfInsufficientHeating,
        avgHeatingPercent: system.avgHeatingPercent
      });
    }),
    configuration: newConfiguration as SystemOptionConfiguration,
    inStock: systems.every((system) => system.inStock),
    restockDate: null,
    operatingCost: systems.reduce((total, system) => system.operatingCost + total, 0),
    averageSeer: Math.round(newConfiguration.condensers.reduce((acc, cond) => acc + (cond.selectedProduct?.seer ?? 0), 0) / newConfiguration.condensers.length),
    averageAestheticsRank: productAestheticsRanks.reduce((acc, rank) => acc + rank, 0) / productAestheticsRanks.length,
    installationDifficultyScore: productInstallationDifficultyRanks.reduce((acc, rank) => acc + rank, 0),
    manufacturers,
    onSale: newConfiguration.condensers.some((cond) => cond.selectedProduct?.isOnSale) || newConfiguration.condensers.some((cond) => cond.zones.some((zone) => zone.selectedProduct?.isOnSale)),
    tags: newTags,
    totalRebates,
    optimalDesignScore,
    amperage: Number(newConfiguration.condensers.reduce((acc, cond) => acc + (Number(cond.selectedProduct?.amperage) ?? 0), 0)),
    outdoorVisibility
  };
};

export function getZoneNumberById(system: ValidSystem | RankedSystem, zoneId: string) {
  if (system.airHandler1Unique === zoneId) return 1;
  if (system.airHandler2Unique === zoneId) return 2;
  if (system.airHandler3Unique === zoneId) return 3;
  if (system.airHandler4Unique === zoneId) return 4;
  if (system.airHandler5Unique === zoneId) return 5;
  return 0;
}

export function filterSystems(systems: RankedSystem[], filters: SystemFilters, customerValues: Attribute[], condenser: Condenser) {
  const isCondenserOnSale = condenser?.selectedProduct?.isOnSale ?? false;
  const isAnyZoneOnSale = condenser.zones?.some((zone) => zone?.selectedProduct?.isOnSale);

  return [...systems]
    .filter((system) => !filters.inStock || system.inStock)
    .filter((system) => !filters.belowBudget || system.baseRetailPrice <= (customerValues.find((cv) => cv.code === 'max_budget')?.value ?? 999999))
    .filter((system) => !filters.selectedAirHandlers || condenser.zones.every((zone) => (
      zone.selectedProduct && (
        (zone.uniqueId === system.airHandler1Unique && system.airHandler1Type === airHandlerTypeCodes[zone.selectedProduct.type]) ||
        (zone.uniqueId === system.airHandler2Unique && system.airHandler2Type === airHandlerTypeCodes[zone.selectedProduct.type]) ||
        (zone.uniqueId === system.airHandler3Unique && system.airHandler3Type === airHandlerTypeCodes[zone.selectedProduct.type]) ||
        (zone.uniqueId === system.airHandler4Unique && system.airHandler4Type === airHandlerTypeCodes[zone.selectedProduct.type]) ||
        (zone.uniqueId === system.airHandler5Unique && system.airHandler5Type === airHandlerTypeCodes[zone.selectedProduct.type])
      )
    )))
    .filter((system) => !filters.isOnSale || isAnyZoneOnSale || isCondenserOnSale || system.discountAmount > 0);
  // TODO: improve onSale filter to check if any individual product is on sale as discountAmount does not always reflect this
}

type CreateActiveItemParams = {
  type: 'level',
  item: Level,
  items: Item[]
} | {
  type: 'section',
  item: Section
} | {
  type: 'space',
  item: Space,
  items: Item[],
  fenestration: FenestrationItem[]
};

export function createActiveItem(params: CreateActiveItemParams): ActiveItem | null {
  if (params.type === 'level') {
    const dbItem = params.items.find(i => i.id === params.item.itemId);
    return dbItem ? {
      itemId: params.item.itemId,
      uniqueId: params.item.uniqueId,
      label: params.item.label,
      attributes: params.item.attributes,
      modified: params.item.modified
    } : null;
  }
  if (params.type === 'section') {
    return {
      uniqueId: params.item.uniqueId,
      label: params.item.label
    };
  }
  if (params.type === 'space') {
    const dbItem = params.items.find(i => i.id === params.item.itemId);
    return dbItem ? {
      itemId: params.item.itemId,
      uniqueId: params.item.uniqueId,
      label: params.item.label,
      attributes: params.item.attributes,
      fenestrationItems: params.item.fenestration,
      defaultFenestration: dbItem.defaultFenestration.map(df => params.fenestration.find(f => f.code === df)?.id).filter(id => id) as number[],
      modified: params.item.modified
    } : null;
  }
  return null;
}

export function measureTextWidth(
  text: string,
  fontSize: number = 16,
  fontFamily: keyof typeof tailwindFonts = 'sans'
) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (!context) {
    return 0;
  }
  context.font = `${fontSize}px ${tailwindFonts[fontFamily].join(', ')}`;
  const { width } = context.measureText(text);
  canvas.remove();
  return Math.round(width);
};

export function sortSystemOptions(systemOptions: SystemOption[], customerValues: Attribute[]) {
  const customerCaresAboutMoney = customerValues.find(cv => cv.code === 'savings_preference')?.value === 'Savings Up Front';
  const customerWantsOptimalSystem = customerValues.find(cv => cv.code === 'savings_preference')?.value === 'Optimal System';
  const customerCaresAboutAesthetics = customerValues.find(cv => cv.code === 'aesthetic_preference')?.value === 'Hidden';
  const customerCaresAboutInstallation = customerValues.find(cv => cv.code === 'installation_difficulty')?.value === 'Simple Installation Preferred';
  const uniqueConfigIds = Array.from(new Set(systemOptions.map(o => o.configuration.uniqueId)));
  const uniqueConfigs = uniqueConfigIds.map(id => systemOptions.find(o => o.configuration.uniqueId === id)?.configuration).filter(c => c) as SystemOptionConfiguration[];
  const systemOptionsMatrix: SystemOption[][] = [];
  const newSystemOptions: SystemOption[] = [];

  uniqueConfigs.sort((a, b) => (b.score ?? 0) - (a.score ?? 0)).forEach(config => {
    const options = systemOptions.filter(o => o.configuration.uniqueId === config.uniqueId);
    systemOptionsMatrix.push(options);
  });

  const addSystemOption = (systemOrTag: SystemOption | SystemOptionTag) => {
    const system = typeof systemOrTag === 'string' ?
      systemOptionsMatrix.flat().find(o => o.tags.includes(systemOrTag)) :
      systemOrTag;
    if (system && !newSystemOptions.map(o => o.uniqueId).includes(system.uniqueId)) {
      newSystemOptions.push(system);
    }
  };

  addSystemOption('Recommended');
  addSystemOption('Preferred Air Handlers');
  if (customerCaresAboutMoney) {
    addSystemOption('Cheapest');
  }
  if (customerWantsOptimalSystem) {
    addSystemOption('Best Coverage');
  }
  if (customerCaresAboutAesthetics) {
    addSystemOption('Most Aesthetic');
  }
  if (customerCaresAboutInstallation) {
    addSystemOption('Easiest Installation');
  }
  addSystemOption('Best Coverage');

  // if third option not on sale, find next highest ranking on sale system and insert it
  newSystemOptions.push(...systemOptions.filter(o => !newSystemOptions.some(no => no.uniqueId === o.uniqueId)));
  if (newSystemOptions[2]?.onSale !== true) {
    const bestOnSaleSystem = newSystemOptions.find(no => no.onSale === true);
    if (bestOnSaleSystem) {
      newSystemOptions.splice(2, 0, bestOnSaleSystem);
    }
  }

  // Remove any duplicates 
  const uniqueNewSystemOptions = newSystemOptions.reduce((acc, curr) => {
    if (!acc.some(item => item.uniqueId === curr.uniqueId)) {
      acc.push(curr);
    }
    return acc;
  }, [] as SystemOption[]);

  return uniqueNewSystemOptions;
}

export function getOversizeAmount(oversizeRanges: OversizeRange[], BTU: number) {
  const range = oversizeRanges.find(r => (r.minBTU ?? 0) <= BTU && (r.maxBTU ?? 9999999999) >= BTU);
  return range ? range.oversizeBTUAmount : 0;
}

export const getComparisonValue = (
  type: 'aesthetics' | 'difficulty' | 'visibility',
  option: SystemOption,
  systemOptions: SystemOption[]
): string | number => {
  if (type === 'aesthetics') {
    const aestheticsRanks = [...systemOptions].slice(0, 3).map((o) => o.averageAestheticsRank);
    const minRank = Math.min(...aestheticsRanks);
    const maxRank = Math.max(...aestheticsRanks);
    if (option.averageAestheticsRank === minRank) {
      return 'Least Visible';
    }
    if (option.averageAestheticsRank === maxRank) {
      return 'Most Visible';
    }
    return 'Less Visible';
  }
  if (type === 'difficulty') {
    const difficultyScores = [...systemOptions].slice(0, 3).map((o) => o.installationDifficultyScore);
    const minScore = Math.min(...difficultyScores);
    const maxScore = Math.max(...difficultyScores);
    if (option.installationDifficultyScore === minScore) {
      return 'Easiest Installation';
    }
    if (option.installationDifficultyScore === maxScore) {
      return 'Hardest Installation';
    }
    return 'Average Installation';
  }
  if (type === 'visibility') {
    return option.outdoorVisibility ?? 'N/A';
  }
  return 'N/A';
};

export function getCoverageTooltipData(type, score, condenserCoverage) {
  if (score >= 100) return null;
  if (condenserCoverage) {
    return condenserCoverage.filter(condenser => {
      if (type === 'heating') {
        return condenser.daysOfInsufficientHeating > 0;
      }
      return condenser.daysOfInsufficientCooling > 0;
    }).map((condenser, condenserIndex) => (
      React.createElement('div', { key: condenserIndex, className: 'whitespace-nowrap text-center text-gray-500 font-normal' },
        condenserCoverage.length > 1 && `${condenser.condenserName}: `,
        `${type === 'heating' ? condenser.daysOfInsufficientHeating : condenser.daysOfInsufficientCooling} day${(type === 'heating' ? condenser.daysOfInsufficientHeating : condenser.daysOfInsufficientCooling) > 1 ? 's' : ''} at `,
        `${Math.floor(type === 'heating' ? condenser.avgHeatingPercent : condenser.avgCoolingPercent)}% average coverage`
      )
    ));
  }
  return null;
}

export const fileToBase64 = (file: File | Blob) => new Promise<string | ArrayBuffer | null>((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
});