import React, { useContext, useState, useEffect } from 'react';
import { Tab, Popover, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { ShoppingCartIcon, BugAntIcon, AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline';
import ShoppingCart from 'components/shopping-cart';
import { warningModal } from 'utils/helpers';
import CanAccess from 'components/auth/can-access';
import ProgressRing from 'components/progress-ring';
import { ActiveTab } from 'data/types';
import { UIContext, ConfigurationContext, ProjectContext } from 'data/context';
import Tooltip from 'components/tooltip';
import { proceedErrorMessages } from 'data/constants';

type HeaderProps = {
  showExtraButtons?: boolean;
}

export default function Header({ showExtraButtons = true }: HeaderProps) {
  const {
    selectedSystems,
    setSelectedSystems
  } = useContext(ConfigurationContext);
  const {
    activeTab,
    setActiveTab,
    pageProgress,
    showDetailsPanel,
    setShowDetailsPanel
  } = useContext(UIContext);
  const { setSaveDebugSnapshot } = useContext(ProjectContext);
  const [showCartIndicator, setShowCartIndicator] = useState(false);

  useEffect(() => {
    if (selectedSystems.length > 0 || selectedSystems.some((system) => system.selectedAccessories.some((acc) => acc.quantity > 0))) {
      setShowCartIndicator(true);
    } else {
      setShowCartIndicator(false);
    }
  }, [
    selectedSystems.length,
    JSON.stringify(selectedSystems.map((system) => system.selectedAccessories))
  ]);

  const removeEmptyAccessoriesFromSystems = () => {
    setSelectedSystems((draft) => {
      draft.forEach((system) => {
        // eslint-disable-next-line no-param-reassign
        system.selectedAccessories = system.selectedAccessories.filter(
          (acc) => acc.quantity > 0
        );
      });
    });
  };

  const handleTabClick = (tab: ActiveTab) => {
    const furthestTab = Object.keys(pageProgress).find(key => pageProgress[key] < 100) as ActiveTab;
    switch (tab) {
      case 'description':
        setActiveTab(tab);
        break;
      case 'layout':
        if (pageProgress.description === 100 || pageProgress.layout === 100) {
          setActiveTab(tab);
        } else {
          warningModal(proceedErrorMessages[furthestTab]);
        }
        break;
      case 'configuration':
        if (pageProgress.layout === 100 || pageProgress.configuration === 100) {
          setActiveTab(tab);
        } else {
          warningModal(proceedErrorMessages[furthestTab]);
        }
        break;
      case 'air handlers':
        if (pageProgress.configuration === 100 || pageProgress['air handlers'] === 100) {
          setActiveTab(tab);
        } else {
          warningModal(proceedErrorMessages[furthestTab]);
        }
        break;
      case 'condensers':
        if (pageProgress['air handlers'] === 100 || pageProgress.condensers === 100) {
          setActiveTab(tab);
        } else {
          warningModal(proceedErrorMessages[furthestTab]);
        }
        break;
      case 'equipment':
        if (pageProgress.condensers === 100 || pageProgress.equipment === 100) {
          setActiveTab(tab);
        } else {
          warningModal(proceedErrorMessages[furthestTab]);
        }
        break;
      case 'accessories':
        if (pageProgress.equipment === 100 || pageProgress.accessories === 100) {
          setActiveTab(tab);
        } else {
          warningModal(proceedErrorMessages[furthestTab]);
        }
        break;
      case 'quote':
        if (pageProgress.accessories === 100 || pageProgress.quote === 100) {
          setActiveTab(tab);
        } else {
          warningModal(proceedErrorMessages[furthestTab]);
        }
        break;
      default:
        break;
    }
  };

  const handleShoppingCartClick = () => {
    setShowDetailsPanel(false);
    setShowCartIndicator(false);
  };

  const tabs: ActiveTab[] = [
    'description',
    'layout',
    'configuration',
    'air handlers',
    'equipment',
    'accessories',
    'quote'
  ];

  const tabClasses = (active: boolean, disabled: boolean, isLast: boolean) =>
    clsx(
      'h-full flex items-center gap-3 border-b border-gray-200 pl-8 -ml-5 group',
      active && 'text-blue-600 bg-blue-50',
      disabled && 'bg-white text-gray-400 cursor-not-allowed',
      !active && !disabled && 'bg-white text-gray-700 hover:bg-gray-50',
      isLast && 'pr-5'
    );

  return (
    <div className='flex h-12 min-h-12 w-full bg-white'>
      <Tab.List className='hidden-scrollbar flex overflow-x-auto'>
        {tabs.map((tab) => {
          let enabled = false;
          let nextTab = '';
          switch (tab) {
            case 'description':
              enabled = true;
              nextTab = 'layout';
              break;
            case 'layout':
              enabled = pageProgress.description === 100 || pageProgress.layout === 100;
              nextTab = 'configuration';
              break;
            case 'configuration':
              enabled = pageProgress.layout === 100 || pageProgress.configuration === 100;
              nextTab = 'air handlers';
              break;
            case 'air handlers':
              enabled = pageProgress.configuration === 100 || pageProgress['air handlers'] === 100;
              nextTab = 'equipment';
              break;
            case 'equipment':
              enabled = pageProgress['air handlers'] === 100 || pageProgress.equipment === 100;
              nextTab = 'accessories';
              break;
            case 'accessories':
              enabled = pageProgress.equipment === 100 || pageProgress.accessories === 100;
              nextTab = 'quote';
              break;
            case 'quote':
              enabled = pageProgress.accessories === 100 || pageProgress.quote === 100;
              break;
            default:
              break;
          }
          return (
            <Tab
              key={tab}
              className='h-full focus:outline-none'
              onClick={() => handleTabClick(tab)}
            >
              <div className={tabClasses(activeTab === tab, !enabled, tab === 'quote')}>
                <ProgressRing progress={pageProgress[tab]} />
                <span className='font-semibold whitespace-nowrap capitalize'>{tab}</span>
                {tab !== 'quote' && (
                  <div className="relative h-full w-5">
                    <div className={`absolute inset-0 ${activeTab !== nextTab && 'group-hover:bg-white'} ${activeTab === nextTab && 'bg-blue-50'}`} />
                    <div className="absolute inset-0">
                      <svg
                        // eslint-disable-next-line no-nested-ternary
                        className={`h-full w-full text-gray-300 fill ${activeTab === tab ? 'fill-blue-50' : enabled ? 'fill-white group-hover:fill-gray-50' : 'fill-white'}`}
                        viewBox="0 0 22 80"
                        preserveAspectRatio="none"
                      >
                        <path
                          d="M0 -2L20 40L0 82"
                          vectorEffect="non-scaling-stroke"
                          stroke="currentcolor"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                )}
              </div>
            </Tab>
          );
        })}
      </Tab.List>
      <div className='h-full grow border-b border-gray-200' />
      {activeTab !== 'description' && showExtraButtons && (
        <button
          type='button'
          className='flex w-[250px] min-w-[250px] cursor-pointer items-center justify-center gap-1 pl-2 border-b border-l border-gray-200 text-gray-900 hover:bg-gray-50'
          onClick={() => setShowDetailsPanel((oldValue) => !oldValue)}
        >
          <span className='whitespace-nowrap font-semibold'>
            {showDetailsPanel ? 'Hide Project Details' : 'Show Project Details'}
          </span>
          {showDetailsPanel ? (
            <ChevronUpIcon className='h-6 w-6' />
          ) : (
            <ChevronDownIcon className='h-6 w-6' />
          )}
        </button>
      )}
      {selectedSystems.length > 0 && (
        <Popover className="relative">
          <Popover.Button
            className='flex h-full shrink-0 cursor-pointer items-center border-b border-l border-gray-200 px-3 text-gray-900 hover:bg-gray-50 relative'
            onClick={handleShoppingCartClick}
          >
            <div className='relative'>
              <ShoppingCartIcon className='h-6 w-6' />
              {showCartIndicator && (
                <div className='absolute -right-1 -top-1 w-[10px] h-[10px] rounded-full bg-blue-500' />
              )}
            </div>
            <Tooltip text='Shopping Cart' />
          </Popover.Button>
          <Transition
            afterLeave={removeEmptyAccessoriesFromSystems}
            enter="absolute z-20 transition ease-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="absolute z-20 transition ease-in duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Popover.Panel className="absolute z-20">
              <ShoppingCart />
            </Popover.Panel>
          </Transition>
        </Popover>
      )}
      <button
        className='flex h-full shrink-0 cursor-pointer items-center border-b border-l border-gray-200 px-3 text-gray-900 hover:bg-gray-50 relative'
        onClick={() => setSaveDebugSnapshot(true)}
      >
        <BugAntIcon className='h-6 w-6' />
        <Tooltip text='Create Debug Snapshot' />
      </button>
      <CanAccess role='administrators'>
        <a
          href={`/admin${window.location.search}`}
          className='flex h-full shrink-0 cursor-pointer items-center border-b border-l border-gray-200 px-3 text-gray-900 hover:bg-gray-50 relative'
        >
          <AdjustmentsHorizontalIcon className='h-6 w-6' />
          <Tooltip text='Admin Panel' />
        </a>
      </CanAccess>
    </div>
  );
}
