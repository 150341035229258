import React from 'react';
import { CondenserProduct } from 'data/types';

export default function CondenserDiagram({ product }: { product: CondenserProduct }) {
  return (
    <div className='relative flex h-[240px] w-full items-center justify-center rounded-lg bg-gray-300'>
      <div className='flex items-end justify-center gap-4'>
        <div className='relative flex h-[200px] w-1/5 items-center justify-center border-r-[30px] border-t-[30px] border-r-gray-400 border-t-gray-200 bg-gray-100'>
          <span className='-rotate-90 whitespace-nowrap text-xs font-semibold leading-none text-gray-400'>
            Exterior Wall
          </span>
          <div className='absolute -top-[30px] left-0 border-[15px] border-b-transparent border-l-gray-300 border-r-transparent border-t-gray-300' />
          <div className='absolute -right-[30px] bottom-0 border-[15px] border-b-gray-300 border-l-transparent border-r-gray-300 border-t-transparent' />
        </div>
        <div className='flex h-[100px] items-center'>
          12&quot;
        </div>
        <div className='relative flex h-[100px] w-1/2 items-center justify-center rounded border-r-[30px] border-t-[30px] border-r-gray-400 border-t-gray-200 bg-gray-100 p-3'>
          <span className='whitespace-nowrap text-center'>
            {Math.ceil(product.dimensions.height)}&quot; H
          </span>
          <div className='absolute -top-[15px] left-[calc(50%+15px)] -translate-x-1/2 -translate-y-1/2 whitespace-nowrap'>
            {Math.ceil(product.dimensions.width)}&quot; W
          </div>
          <div className='absolute left-[calc(100%+15px)] top-2 -translate-x-1/2 whitespace-nowrap'>
            {Math.ceil(product.dimensions.depth)}&quot; L
          </div>
          <div className='absolute -top-[30px] left-0 border-[15px] border-b-transparent border-l-gray-300 border-r-transparent border-t-gray-300' />
          <div className='absolute -right-[30px] bottom-0 border-[15px] border-b-gray-300 border-l-transparent border-r-gray-300 border-t-transparent' />
        </div>
      </div>
    </div>
  );
}
