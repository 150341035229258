import axios from 'axios';
import { TemperatureData, WeatherData } from 'data/types';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SYSTEM_SELECTOR_API_URL,
  withCredentials: true
});

export const getLocationData = async (zipCode: string) => axiosInstance.get(
  `${process.env.REACT_APP_PARTNERNET_API_URL}/v1/api/location/${zipCode}`,
);

export const getWeatherData = async (zipCode: string) => {
  const temperatureData = (await axiosInstance.get(
    `temperature-data?zipCode=${zipCode}`
  )).data as {
    temperatureData: TemperatureData[],
    avgMaxSnowDepth: number,
    elevation: number
  };
  const newWeatherData: WeatherData = {
    minTemps: temperatureData.temperatureData.map((t) => ({
      temperature: t.temperatureFahrenheit,
      avgDaysPerYear: t.daysPerYearMinTemperatureIsAtOrBelow
    })),
    maxTemps: temperatureData.temperatureData.map((t) => ({
      temperature: t.temperatureFahrenheit,
      avgDaysPerYear: t.daysPerYearMaxTemperatureIsAtOrAbove
    })),
    avgMaxSnowDepth: temperatureData.avgMaxSnowDepth,
    elevation: temperatureData.elevation
  };
  return newWeatherData;
};

export default axiosInstance;