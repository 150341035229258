import React, { useContext, useEffect, useRef } from 'react';
import { DataContext, ConfigurationContext, ProjectContext } from 'data/context';
import { errorModal } from 'utils/helpers';
import { createLevel } from 'utils/structure';
import AttributeComponent from 'components/attribute';
import NumberInput from 'components/inputs/number';
import { Level } from 'data/types';

export default function SimpleStructurePanel() {
  const { items } = useContext(DataContext);
  const { structure, setStructure } = useContext(ConfigurationContext);
  const { projectSettings, setProjectSettings } = useContext(ProjectContext);
  const projectSettingsRef = useRef(projectSettings);
  const structureRef = useRef(structure);
  const levelItemId = items?.find(item => item.code === 'level')?.id;
  const totalSquareFootageAttribute = projectSettings.find((attr) => attr.code === 'square_footage');

  useEffect(() => {
    projectSettingsRef.current = projectSettings;
    structureRef.current = structure;
  }, [projectSettings, structure]);

  const handleChangeProjectSettings = (
    attributeId: number,
    value: any
  ) => {
    setProjectSettings((draft) => {
      const updatedProjectSettings = draft;
      const index = updatedProjectSettings.findIndex(
        (a) => a.id === attributeId
      );
      updatedProjectSettings[index].value = value;
      return updatedProjectSettings;
    });
  };

  const handleUpdateNumberOfFloors = (newNumberOfFloors: number) => {
    const levelItem = items.find((i) => i.id === levelItemId);
    if (levelItem) {
      const newLevels: Level[] = [];
      for (let i = 0; i < newNumberOfFloors; i += 1) {
        const newLevel = createLevel(levelItem, {
          data: {
            label: `${levelItem.label} ${i + 1}`,
          },
          attributesToInherit: projectSettingsRef.current
        });
        newLevels.unshift(newLevel);
      }
      setStructure((draft) => ([
        ...draft.filter((l) => items.find((i) => i.id === l.itemId)?.code === 'attic'),
        ...newLevels,
        ...draft.filter((l) => items.find((i) => i.id === l.itemId)?.code === 'basement'),
        ...draft.filter((l) => items.find((i) => i.id === l.itemId)?.code === 'crawlspace')
      ]));
    } else {
      console.error('Level item not found');
      errorModal(`Level item not found`);
    }
  };

  return (
    <div className='grow basis-0'>
      <div className='min-h-[500px] flex flex-col items-center justify-center gap-3'>
        <span className='font-semibold text-lg'>What is the total square footage of the property?</span>
        {totalSquareFootageAttribute && (
          <AttributeComponent
            showProgress={false}
            completed={!!totalSquareFootageAttribute.value}
            value={totalSquareFootageAttribute.value}
            onChange={(value) => handleChangeProjectSettings(totalSquareFootageAttribute.id, value)}
            {...{ ...totalSquareFootageAttribute }}
            required
          />
        )}
        <span className='font-semibold text-lg'>How many air conditioned floors are in the structure?</span>
        <div className='w-[70px]'>
          <NumberInput
            value={structure.length}
            placeholder='0'
            onChange={(value) => handleUpdateNumberOfFloors(value ?? 0)}
            debounce={500}
            invalid={structure.length === 0}
            min={0}
          />
        </div>
      </div>
    </div>
  );
}