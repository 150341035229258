import React, { Dispatch, SetStateAction } from 'react';
import { OversizeRange } from 'data/types';
import NumberInput from 'components/inputs/number';
import { TrashIcon } from '@heroicons/react/24/outline';

type OversizeRangeProps = {
  range: OversizeRange;
  index: number;
  updateArray: Dispatch<SetStateAction<OversizeRange[]>>;
  deleteSelf: () => void;
};

export default function OversizeRangeComponent({
  range,
  index,
  updateArray,
  deleteSelf
}: OversizeRangeProps) {
  const handleChange = (key: keyof OversizeRange, value: number | undefined) => {
    updateArray((prev) => {
      const newArr = [...prev];
      newArr[index] = { ...newArr[index], [key]: value };
      return newArr;
    });
  };

  return (
    <div className='flex items-center gap-3 rounded-lg bg-white p-3'>
      <span>Range:</span>
      <NumberInput onChange={(value) => handleChange('minBTU', value)} value={range.minBTU} placeholder='Min BTU' />
      <span>-</span>
      <NumberInput onChange={(value) => handleChange('maxBTU', value)} value={range.maxBTU} placeholder='Max BTU' />
      <span>Oversize:</span>
      <NumberInput
        onChange={(value) => handleChange('oversizeBTUAmount', value)}
        value={range.oversizeBTUAmount}
        placeholder='BTUs to Oversize'
      />
      <div className='flex-shrink'>
        <TrashIcon
          className='h-5 w-5 shrink-0 cursor-pointer text-gray-500 hover:text-gray-700'
          onClick={deleteSelf}
        />
      </div>
    </div>
  );
}