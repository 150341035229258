import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import { AdminContext } from 'data/context';
import { successModal, errorModal } from 'utils/helpers';
import axios from 'utils/api';
import Modal from 'components/modals/modal';
import Text from 'components/inputs/text';
import Button from 'components/inputs/button';
import NumberInput from 'components/inputs/number';

type CreateFenestrationItemModalProps = {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
};

export default function CreateFenestrationItemModal({ openState }: CreateFenestrationItemModalProps) {
  const { refreshData } = useContext(AdminContext);
  const [open, setOpen] = openState;
  const [name, setName] = useState('');
  const [heatingModifier, setHeatingModifier] = useState<number>();
  const [coolingModifier, setCoolingModifier] = useState<number>();

  useEffect(() => {
    setName('');
    setHeatingModifier(undefined);
    setCoolingModifier(undefined);
  }, [open]);

  const handleSave = async () => {
    try {
      await axios.post('fenestration', { label: name, heatingModifier, coolingModifier });
      successModal('Fenestration item created');
    } catch (error) {
      console.error(error);
      errorModal('Failed to create fenestration item');
    }
    refreshData();
    setOpen(false);
  };

  return (
    <Modal
      title='New Fenestration Item'
      openState={openState}
      size='wide'
      infoMode
    >
      <div className='flex flex-col gap-5'>
        <div className="flex flex-col gap-2">
          <div className='font-semibold text-gray-900'>Name</div>
          <div>
            <Text
              value={name}
              onChange={setName}
              placeholder='Name...'
            />
          </div>
        </div>
        <div className='flex gap-5'>
          <div className="flex flex-col gap-2">
            <div className='font-semibold text-gray-900'>Heating BTU Modifier</div>
            <div>
              <NumberInput
                value={heatingModifier}
                onChange={setHeatingModifier}
                placeholder='Heating Modifier...'
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className='font-semibold text-gray-900'>Cooling BTU Modifier</div>
            <div>
              <NumberInput
                value={coolingModifier}
                onChange={setCoolingModifier}
                placeholder='Cooling Modifier...'
              />
            </div>
          </div>
        </div>
        <div className='flex items-center gap-3'>
          <Button
            text='Create'
            color='green'
            onClick={handleSave}
          />
          <Button
            text='Cancel'
            color='red'
            onClick={() => setOpen(false)}
          />
        </div>
      </div>
    </Modal >
  );
}
