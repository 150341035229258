/* eslint-disable no-param-reassign */
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useState,
  useEffect
} from 'react';
import { ConfigurationContext, ProjectContext } from 'data/context';
import { errorModal, successModal, warningModal } from 'utils/helpers';
import axios from 'utils/api';
import { AirHandlerWithQuantity, CondenserWithQuantity, QuoteEmailInput, QuoteEmailResults } from 'data/types';
import Modal from 'components/modals/modal';
import Typeahead from 'components/inputs/typeahead';

type SendQuoteModalProps = {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
};

export default function SendQuoteModal({ openState }: SendQuoteModalProps) {
  const {
    projectDetails,
    setProjectDetails,
    quotesToSend,
    systemOptions
  } = useContext(ProjectContext);
  const { selectedSystems, setSelectedSystems } = useContext(ConfigurationContext);
  const [email, setEmail] = useState('');
  const [open, setOpen] = openState;
  const [quotesToSendSnapshot, setQuotesToSendSnapshot] = useState<string[]>([]);
  const maxOptimalDesignScore = Math.max(...systemOptions.map(option => (option.optimalDesignScore ?? 1)));

  useEffect(() => {
    if (open) {
      setEmail('');
    }
  }, [open]);

  const saveQuoteInfo = (results: QuoteEmailResults, currentQuotesToSendSnapshot: string[]) => {
    const selectedQuotedSystems = selectedSystems.filter((s) =>
      currentQuotesToSendSnapshot.includes(s.id)
    ).map(system => ({
      ...system,
      configuration: {
        ...system.configuration,
        condensers: system.configuration.condensers.map(c => ({
          ...c,
          selectedProduct: { ...c.selectedProduct }
        }))
      }
    }));

    results.quotes.forEach(quote => {
      const system = selectedQuotedSystems.find((s) => s.id === quote.uniqueId);
      if (system) {
        let updatedSystem = { ...system };

        if (selectedQuotedSystems.length === 2) {
          const [system1, system2] = selectedQuotedSystems;

          // Indoor Visibility (Aesthetics Rank)
          if (system1.averageAestheticsRank === system2.averageAestheticsRank) {
            system1.indoorVisibility = 'Less Visible';
            system2.indoorVisibility = 'Less Visible';
          } else if (system1.averageAestheticsRank < system2.averageAestheticsRank) {
            system1.indoorVisibility = 'Less Visible';
            system2.indoorVisibility = 'More Visible';
          } else {
            system1.indoorVisibility = 'More Visible';
            system2.indoorVisibility = 'Less Visible';
          }

          // Installation Difficulty
          if (system1.installationDifficultyScore === system2.installationDifficultyScore) {
            system1.installationDifficulty = 'Less Difficult';
            system2.installationDifficulty = 'Less Difficult';
          } else if (system1.installationDifficultyScore < system2.installationDifficultyScore) {
            system1.installationDifficulty = 'Less Difficult';
            system2.installationDifficulty = 'More Difficult';
          } else {
            system1.installationDifficulty = 'More Difficult';
            system2.installationDifficulty = 'Less Difficult';
          }

          // Outdoor Visibility
          const system1OutdoorVolume = system1.configuration.condensers.reduce(
            (total, condenser) => total +
              (condenser.selectedProduct?.dimensions.width ?? 1) *
              (condenser.selectedProduct?.dimensions.height ?? 1) *
              (condenser.selectedProduct?.dimensions.depth ?? 1),
            0
          );

          const system2OutdoorVolume = system2.configuration.condensers.reduce(
            (total, condenser) => total +
              (condenser.selectedProduct?.dimensions.width ?? 1) *
              (condenser.selectedProduct?.dimensions.height ?? 1) *
              (condenser.selectedProduct?.dimensions.depth ?? 1),
            0
          );

          if (system1OutdoorVolume === system2OutdoorVolume) {
            system1.outdoorVisibility = 'Normal Visibility';
            system2.outdoorVisibility = 'Normal Visibility';
          } else if (system1OutdoorVolume < system2OutdoorVolume) {
            system1.outdoorVisibility = 'Less Visible';
            system2.outdoorVisibility = 'More Visible';
          } else {
            system1.outdoorVisibility = 'More Visible';
            system2.outdoorVisibility = 'Less Visible';
          }

          // Assign the updated system
          if (updatedSystem.id === system1.id) {
            updatedSystem = { ...updatedSystem, ...system1 };
          } else if (updatedSystem.id === system2.id) {
            updatedSystem = { ...updatedSystem, ...system2 };
          }
        }

        if (selectedQuotedSystems.length === 3) {

          // Indoor Visibility (Aesthetics Rank)
          const sortedByAesthetics = [...selectedQuotedSystems].sort((a, b) => a.averageAestheticsRank - b.averageAestheticsRank);
          if (sortedByAesthetics[0].averageAestheticsRank === sortedByAesthetics[2].averageAestheticsRank) {
            // All 3 systems are tied
            sortedByAesthetics.forEach(sys => {
              sys.indoorVisibility = 'Less Visible';
            });
          } else if (sortedByAesthetics[0].averageAestheticsRank === sortedByAesthetics[1].averageAestheticsRank) {
            sortedByAesthetics[0].indoorVisibility = 'Less Visible';
            sortedByAesthetics[1].indoorVisibility = 'Less Visible';
            sortedByAesthetics[2].indoorVisibility = 'More Visible';
          } else if (sortedByAesthetics[1].averageAestheticsRank === sortedByAesthetics[2].averageAestheticsRank) {
            sortedByAesthetics[0].indoorVisibility = 'Least Visible';
            sortedByAesthetics[1].indoorVisibility = 'More Visible';
            sortedByAesthetics[2].indoorVisibility = 'More Visible';
          } else {
            sortedByAesthetics[0].indoorVisibility = 'Least Visible';
            sortedByAesthetics[1].indoorVisibility = 'Less Visible';
            sortedByAesthetics[2].indoorVisibility = 'Most Visible';
          }

          // Installation Difficulty
          const sortedByDifficulty = [...selectedQuotedSystems].sort((a, b) => a.installationDifficultyScore - b.installationDifficultyScore);
          if (sortedByDifficulty[0].installationDifficultyScore === sortedByDifficulty[2].installationDifficultyScore) {
            // All 3 systems are tied
            sortedByDifficulty.forEach(sys => {
              sys.installationDifficulty = 'Less Difficult';
            });
          } else if (sortedByDifficulty[0].installationDifficultyScore === sortedByDifficulty[1].installationDifficultyScore) {
            sortedByDifficulty[0].installationDifficulty = 'Less Difficult';
            sortedByDifficulty[1].installationDifficulty = 'Less Difficult';
            sortedByDifficulty[2].installationDifficulty = 'More Difficult';
          } else if (sortedByDifficulty[1].installationDifficultyScore === sortedByDifficulty[2].installationDifficultyScore) {
            sortedByDifficulty[0].installationDifficulty = 'Least Difficult';
            sortedByDifficulty[1].installationDifficulty = 'More Difficult';
            sortedByDifficulty[2].installationDifficulty = 'More Difficult';
          } else {
            sortedByDifficulty[0].installationDifficulty = 'Least Difficult';
            sortedByDifficulty[1].installationDifficulty = 'Less Difficult';
            sortedByDifficulty[2].installationDifficulty = 'Most Difficult';
          }

          // Outdoor Visibility
          const sortedByOutdoorVisibility = [...selectedQuotedSystems].sort((a, b) => {
            const aVolume = a.configuration.condensers.reduce(
              (total, condenser) => total +
                (condenser.selectedProduct?.dimensions.width ?? 1) *
                (condenser.selectedProduct?.dimensions.height ?? 1) *
                (condenser.selectedProduct?.dimensions.depth ?? 1),
              0
            );

            const bVolume = b.configuration.condensers.reduce(
              (total, condenser) => total +
                (condenser.selectedProduct?.dimensions.width ?? 1) *
                (condenser.selectedProduct?.dimensions.height ?? 1) *
                (condenser.selectedProduct?.dimensions.depth ?? 1),
              0
            );

            return aVolume - bVolume;
          });
          if (sortedByOutdoorVisibility[0].configuration.condensers === sortedByOutdoorVisibility[2].configuration.condensers) {
            // All 3 systems are tied
            sortedByOutdoorVisibility.forEach(sys => {
              sys.outdoorVisibility = 'Normal Visibility';
            });
          } else if (sortedByOutdoorVisibility[0].configuration.condensers === sortedByOutdoorVisibility[1].configuration.condensers) {
            sortedByOutdoorVisibility[0].outdoorVisibility = 'Less Visible';
            sortedByOutdoorVisibility[1].outdoorVisibility = 'Less Visible';
            sortedByOutdoorVisibility[2].outdoorVisibility = 'More Visible';
          } else if (sortedByOutdoorVisibility[1].configuration.condensers === sortedByOutdoorVisibility[2].configuration.condensers) {
            sortedByOutdoorVisibility[0].outdoorVisibility = 'Least Visible';
            sortedByOutdoorVisibility[1].outdoorVisibility = 'More Visible';
            sortedByOutdoorVisibility[2].outdoorVisibility = 'More Visible';
          } else {
            sortedByOutdoorVisibility[0].outdoorVisibility = 'Least Visible';
            sortedByOutdoorVisibility[1].outdoorVisibility = 'Less Visible';
            sortedByOutdoorVisibility[2].outdoorVisibility = 'Most Visible';
          }

          // Assign the updated system back
          updatedSystem = { ...updatedSystem, ...sortedByAesthetics.find(s => s.id === system.id) };
          updatedSystem = { ...updatedSystem, ...sortedByDifficulty.find(s => s.id === system.id) };
          updatedSystem = { ...updatedSystem, ...sortedByOutdoorVisibility.find(s => s.id === system.id) };
        }

        updatedSystem = {
          ...updatedSystem,
          optimalDesignScore: Math.floor(((updatedSystem.optimalDesignScore ?? 1) / maxOptimalDesignScore) * 10),
        };

        try {
          axios.post('quote-data', {
            quoteId: quote.quoteId,
            quoteData: JSON.stringify({
              ...updatedSystem,
              shipping: undefined,
              tax: undefined,
              totalRebates: undefined,
              rebates: undefined,
              inStock: undefined,
              quoteStatus: updatedSystem.quoteStatus,
              restockDate: undefined,
            })
          });
        } catch (error) {
          console.error(error);
          errorModal('Failed to save quote data');
        }
      } else {
        console.error(`Cannot find system ${quote.uniqueId}`);
        errorModal('Failed to save quote data');
      }
    });
  };

  const handleSendQuote = async () => {
    if (/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      setOpen(false);
      const uniqueQuotesToSendSnapshot = Array.from(new Set(quotesToSend));
      setQuotesToSendSnapshot(uniqueQuotesToSendSnapshot);
      setSelectedSystems((draft) => {
        draft.forEach((system) => {
          if (quotesToSend.includes(system.id)) {
            // eslint-disable-next-line no-param-reassign
            system.quoteStatus = 'sending';
          }
        });
      });
      const kits = {};
      selectedSystems
        .filter((system) => quotesToSend.includes(system.id))
        .forEach((system, systemIndex) => {
          const kitName = `Option ${systemIndex + 1}`;
          const airHandlerProducts: AirHandlerWithQuantity[] = [];
          const condenserProducts: CondenserWithQuantity[] = [];
          system.configuration.condensers.forEach((cond) => {
            if (condenserProducts.find((p) => p.productId === cond.selectedProduct?.productId)) {
              const productIndex = condenserProducts.findIndex((p) => p.productId === cond.selectedProduct?.productId);
              condenserProducts[productIndex].quantity += 1;
            } else if (cond.selectedProduct) {
              condenserProducts.push({
                ...cond.selectedProduct,
                quantity: 1
              });
            }
            cond.zones.forEach((zone) => {
              if (airHandlerProducts.find((p) => p.productId === zone.selectedProduct?.productId)) {
                const productIndex = airHandlerProducts.findIndex((p) => p.productId === zone.selectedProduct?.productId);
                airHandlerProducts[productIndex].quantity += 1;
              } else if (zone.selectedProduct) {
                airHandlerProducts.push({
                  ...zone.selectedProduct,
                  quantity: 1
                });
              }
            });
          });
          kits[kitName] = {
            uniqueId: system.id,
            description: `${system.numberOfZones} Zone Mini Split System`,
            products: [
              ...airHandlerProducts.map((p) => ({
                productID: p.productId,
                quantity: p.quantity,
                quoteCategory: 'Primary Equipment'
              })),
              ...condenserProducts.map((p) => ({
                productID: p.productId,
                quantity: p.quantity,
                quoteCategory: 'Primary Equipment'
              })),
              ...system.selectedAccessories.map((p) => ({
                productID: p.productId,
                quantity: p.quantity,
                quoteCategory: ['line_set', 'drain_hose', 'wire'].includes(p.type) ? 'Essential Components' : 'Optional Enhancements'
              }))
            ]
          };
        });
      const quoteInput: QuoteEmailInput = {
        emailAddress: email,
        kbType: 'Mini-Split v2',
        projectId: projectDetails?.projectId,
        customerContactId: projectDetails?.customerContactId,
        kits
      };
      axios.post(
        `${process.env.REACT_APP_PARTNERNET_URL}/kitbuilder/emailresults`,
        quoteInput
      ).then((res) => {
        saveQuoteInfo(res.data, uniqueQuotesToSendSnapshot);
        successModal('Quote sent to customer');
        setSelectedSystems((draft) => {
          draft.forEach((system) => {
            if (quotesToSend.includes(system.id)) {
              // eslint-disable-next-line no-param-reassign
              system.quoteStatus = 'sent';
            }
          });
        });
        setProjectDetails((draft) => {
          if (draft && !draft.customerEmails.includes(email)) {
            draft.customerEmails.push(email);
          }
        });
      }).catch((error) => {
        console.error(error);
        errorModal('Failed to send quote');
        setSelectedSystems((draft) => {
          draft.forEach((system) => {
            if (quotesToSend.includes(system.id)) {
              // eslint-disable-next-line no-param-reassign
              system.quoteStatus = 'unsent';
            }
          });
        });
      });
    } else {
      warningModal('Please enter a valid email address');
    }
  };

  return (
    <Modal
      title='Enter an Email Address'
      buttonText='Send Quote'
      openState={openState}
      onSave={handleSendQuote}
      closeOnSave={false}
    >
      <Typeahead
        placeholder='example@domain.com'
        options={projectDetails?.customerEmails ?? []}
        onChange={(value) => setEmail(value.toString())}
        customOptionFormat={(input) => `New Email: ${input}`}
        allowCustom
      />
    </Modal>
  );
}
