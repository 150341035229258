import React, { useState, useEffect } from 'react';
import Select from 'react-select';

type MultiselectProps = {
  value?: (string | number)[];
  options: string[] | { label: string; value: string | number }[];
  onChange: (values: (string | number)[]) => void;
  placeholder?: string;
  disabled?: boolean;
  debounce?: number;
  invalid?: boolean;
};

export default function Multiselect({
  value: initialValue,
  options,
  onChange,
  placeholder,
  disabled = false,
  debounce,
  invalid = false
}: MultiselectProps) {
  const [value, setValue] = useState(initialValue ?? []);
  const [formattedOptions, setFormattedOptions] = useState<{ label: string; value: string | number }[]>([]);

  useEffect(() => {
    setValue(initialValue ?? []);
  }, [initialValue]);

  useEffect(() => {
    if (options) {
      if (typeof options[0] === 'string') {
        setFormattedOptions((options as string[]).map((option) => ({ label: option, value: option })));
      } else {
        setFormattedOptions(options as { label: string; value: string | number }[]);
      }
    }
  }, [options]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (onChange && value !== (initialValue ?? '')) {
        onChange(value);
      }
    }, debounce);
    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <Select
      value={formattedOptions.filter((option) => value.includes(option.value))}
      className='[&_input]:!ring-0'
      classNames={{
        container: () => `${invalid ? 'ring-2 ring-red-500 rounded' : ''}`,
        input: () => '!cursor-text',
        dropdownIndicator: () => '!cursor-pointer p-0 flex items-center justify-center',
        clearIndicator: () => '!cursor-pointer',
        option: () => '!cursor-pointer',
        indicatorsContainer: () => 'p-0 flex items-center justify-center',
      }}
      placeholder={placeholder}
      options={formattedOptions}
      onChange={(values) => onChange(values.map((v) => v.value))}
      isDisabled={disabled}
      openMenuOnFocus
      openMenuOnClick
      isClearable
      isMulti
    />
  );
}

Multiselect.defaultProps = {
  disabled: false
};
