import { findSpaceById, getRestrictedAirHandlerTypes } from 'utils/structure';
import {
  AirHandlerType,
  AirHandlerTypeCode,
  Attribute,
  Condenser,
  Level,
  RankingModifiers,
  Space,
  Item,
  AirHandlerProduct,
  ConfigRankingModifiers,
  RankedSystem,
  Configuration
} from 'data/types';
import { airHandlerTypeCodes } from 'data/constants';

export function rankSystems(
  structure: Level[],
  items: Item[],
  condenser: Condenser,
  customerValues: Attribute[],
  airHandlerTypes: AirHandlerType[],
  modifiers: RankingModifiers,
  airHandlerProducts: AirHandlerProduct[]
) {
  const condenserProduct = { ...condenser.selectedProduct };

  // get zones for ranking
  const zones = condenser.zones.map((zone) => {
    const spaces = zone.zoneItems
      .map((zoneItem) => findSpaceById(structure, zoneItem.structureItemId))
      .filter((space) => space) as Space[];
    const spaceItems = spaces.map(space => items.find(i => i.id === space.itemId)).filter((item) => item) as Item[];
    const { selectedProduct } = zone;
    return {
      uniqueId: zone.uniqueId,
      spaces,
      items: spaceItems,
      selectedProduct
    };
  });

  const scoredSystems = [...condenser.validSystems].map((system) => ({
    ...system,
    score: 1
  }));

  function calculatePercentage(value: number, min: number, max: number) {
    return ((value - min) / (max - min));
  }

  // Function to calculate the modifier based on score ranges
  function calculateModifier(score) {
    let percentage = 0;
    if (score >= 90 && score < 100) {
      percentage = calculatePercentage(score, 90, 100);
    }
    if (score >= 100 && score <= 150) {
      percentage = calculatePercentage(score, 150, 100);
    }
    return percentage;
  }

  // get customer values
  const customerInstallationDifficultyPreference = customerValues.find((att) => att.code === 'installation_difficulty')?.value;
  const customerInstallationPlan = customerValues.find((att) => att.code === 'installation_plan')?.value;
  const customerAestheticsPreference = customerValues.find((att) => att.code === 'aesthetic_preference')?.value;
  const customerSavingsPreference = customerValues.find((att) => att.code === 'savings_preference')?.value;

  const installationDifficultyValues: { [key: string]: 1 | 2 | 3 | 4 | 5 } = {
    'Simple Installation Preferred': 2,
    'Okay with Moderately Difficult Installation': 3,
    'Okay with Challenging Installation': 5
  };

  // loop through systems and set/adjust scores based on attributes
  scoredSystems.forEach((system, index) => {

    // Calculate cooling and heating amounts for each zone
    const zoneCoolingHeatingAmounts = zones.map(zone => {
      const zoneCoolingAmount = zone.spaces.reduce((acc, space) => acc + space.BTUs.cooling, 0);
      const zoneHeatingAmount = zone.spaces.reduce((acc, space) => acc + space.BTUs.heating, 0);
      return { zoneCoolingAmount, zoneHeatingAmount };
    });

    // modify score if air handler BTUs are within 25% of each other
    const selectedProductIds = [
      system.airHandler1ProductId,
      system.airHandler2ProductId,
      system.airHandler3ProductId,
      system.airHandler4ProductId,
      system.airHandler5ProductId
    ].filter(id => id !== null) as number[];
    if (selectedProductIds.length >= 2) {
      let allPairsWithin25Percent = true;
      for (let i = 0; i < selectedProductIds.length; i += 1) {
        for (let j = i + 1; j < selectedProductIds.length; j += 1) {
          const productA = airHandlerProducts.find(product => product.productId === selectedProductIds[i]);
          const productB = airHandlerProducts.find(product => product.productId === selectedProductIds[j]);
          if (productA && productB) {
            const heatingDifference = Math.abs(productA.heatingBTU - productB.heatingBTU) / Math.max(productA.heatingBTU, productB.heatingBTU);
            if (!(heatingDifference <= 0.25)) {
              allPairsWithin25Percent = false;
              break;
            }
          }
        }
        if (!allPairsWithin25Percent) {
          break;
        }
      }
      if (allPairsWithin25Percent) {
        if ('airHandlerSimilarSizes' in modifiers) {
          scoredSystems[index].score += Number(modifiers.airHandlerSimilarSizes);
        }
      }
    }

    // modify score based on the difference between zone BTUs and air handler BTUs based on the percentage difference
    let totalScoreAdjustment = 0;
    selectedProductIds.forEach(productId => {
      const product = airHandlerProducts.find(ahp => ahp.productId === productId);
      if (product) {
        zoneCoolingHeatingAmounts.forEach(({ zoneCoolingAmount, zoneHeatingAmount }) => {
          const coolingDifference = Math.abs(zoneCoolingAmount - product.coolingBTU) / product.coolingBTU;
          const heatingDifference = Math.abs(zoneHeatingAmount - product.heatingBTU) / product.heatingBTU;
          // Calculate the score adjustment based on the percentage difference
          const coolingScoreAdjustment = Math.max(0, 1 - coolingDifference);
          const heatingScoreAdjustment = Math.max(0, 1 - heatingDifference);
          const zoneScoreAdjustment = (coolingScoreAdjustment + heatingScoreAdjustment) / 2; // Average adjustment for cooling and heating
          totalScoreAdjustment += Number(zoneScoreAdjustment);
        });
      }
    });
    const averageScoreAdjustment = totalScoreAdjustment / (selectedProductIds.length * zoneCoolingHeatingAmounts.length); // Calculate average adjustment per zone
    const maxScoreAdjustment = Number(modifiers.airHandlerFitsRoomSizes);
    const adjustedScore = averageScoreAdjustment * maxScoreAdjustment; // scale adjustment based on max allowed score
    scoredSystems[index].score += Number(adjustedScore);

    // Modify score if zone's air handler's heating and cooling BTUs are within 20% of each other
    const heatingNotOverSizedToCooling = Number(modifiers.heatingNotOversizedToCooling);
    selectedProductIds.forEach(productId => {
      const product = airHandlerProducts.find(ahp => ahp.productId === productId);
      if (product) {
        const { coolingBTU, heatingBTU } = product;
        const differencePercentage = Math.abs(coolingBTU - heatingBTU) / Math.max(coolingBTU, heatingBTU);
        // TODO: Add more granular scoring based on the difference percentage
        if (differencePercentage <= 0.05) {
          scoredSystems[index].score += heatingNotOverSizedToCooling;
        } else if (differencePercentage <= 0.10) {
          scoredSystems[index].score += heatingNotOverSizedToCooling / 2;
        } else if (differencePercentage <= 0.20) {
          scoredSystems[index].score += heatingNotOverSizedToCooling / 4;
        }
      }
    });

    // Modify score if air handler's total sizes are within 20% of condenser's size
    if (condenserProduct.coolingBTU) {
      const airHandlerFitsCondenserSizeScore = Number(modifiers.airHandlerFitsCondenserSize);
      let totalAirHandlerCoolingBTU = 0;
      selectedProductIds.forEach(productId => {
        const airHandlerProduct = airHandlerProducts.find(ahp => ahp.productId === productId);
        if (airHandlerProduct) {
          totalAirHandlerCoolingBTU += airHandlerProduct.coolingBTU;
        }
      });
      // TODO: Add more granular scoring based on the difference percentage
      const differencePercentage = Math.abs(totalAirHandlerCoolingBTU - condenserProduct.coolingBTU) / Math.max(totalAirHandlerCoolingBTU, condenserProduct.coolingBTU);
      if (differencePercentage <= 0.05) {
        scoredSystems[index].score += airHandlerFitsCondenserSizeScore;
      } else if (differencePercentage <= 0.10) {
        scoredSystems[index].score += airHandlerFitsCondenserSizeScore / 2;
      } else if (differencePercentage <= 0.20) {
        scoredSystems[index].score += airHandlerFitsCondenserSizeScore / 4;
      }
    }

    // modify score based on customer savings preference
    if (customerSavingsPreference === 'Optimal Efficiency') {
      const minOperatingCost = Math.min(...scoredSystems.map((s) => s.operatingCost));
      scoredSystems[index].score =
        Number(system.score) +
        Number(
          (1 - ((system.operatingCost - minOperatingCost) / minOperatingCost)) *
          modifiers.efficiency
        );
    } else {
      const maxPrice = Math.max(...scoredSystems.map((s) => s.baseRetailPrice));
      scoredSystems[index].score =
        Number(system.score) +
        Number(((maxPrice - system.baseRetailPrice) / maxPrice) * (modifiers.priceMultiplier * (customerSavingsPreference === 'Savings Up Front' ? 2 : 1)));
    }

    // modify score based on heating and cooling coverage score separately
    scoredSystems[index].score = Number(system.score) + (system.coolingScore * (calculateModifier(system.coolingScore) * modifiers.coolingCoverage) / 100);
    scoredSystems[index].score = Number(system.score) + (system.heatingScore * (calculateModifier(system.heatingScore) * modifiers.heatingCoverage) / 100);

    // additional bump to score if full coverage 
    if (system.coolingScore >= 98 && system.heatingScore >= 98) {
      scoredSystems[index].score = Number(system.score) + ((modifiers.fullCoverage * calculateModifier(system.coolingScore)) + (modifiers.fullCoverage * calculateModifier(system.heatingScore))) / 2;
    }

    // loop over each zone
    [
      system.airHandler1Unique,
      system.airHandler2Unique,
      system.airHandler3Unique,
      system.airHandler4Unique,
      system.airHandler5Unique
    ].filter(id => id).forEach((zoneId, zoneIndex) => {
      // define variables we need for ranking
      const zoneNumber = zoneIndex + 1;
      const airHandlerType = system[`airHandler${zoneNumber}Type`];
      const zone = zones.find((z) => z.uniqueId === zoneId);

      if (zone) {
        // get the properties of the air handler type
        const airHandlerTypeProperties = airHandlerTypes.find(
          (att) => att.code === airHandlerType
        );

        // get the preferred air handler types for the zone
        const preferredAirHandlerTypes: AirHandlerTypeCode[] = zone.spaces.map(
          (space) =>
            airHandlerTypeCodes[space.attributes.find((a) => a.code === 'preferred_air_handler_type')?.value]
        );

        // modify score based on air handler type preference
        if (
          airHandlerType &&
          preferredAirHandlerTypes.includes(airHandlerType)
        ) {
          scoredSystems[index].score =
            Number(system.score) + Number(modifiers.preferredAirHandlerMatch);
        }

        // modify score based on ceiling cassette compatibility
        if (
          zone.spaces.length === 1 &&
          zone.spaces[0].attributes.find((a) => a.code === 'joist_width')?.value &&
          (airHandlerType === 'cassette' || airHandlerType === 'cassette_16')
        ) {
          if (
            (zone.spaces[0].attributes.find((a) => a.code === 'joist_width')?.value === '16"' ||
              zone.spaces[0].attributes.find((a) => a.code === 'joist_width')?.value === 'Unknown')
            &&
            airHandlerType === 'cassette_16'
          ) {
            scoredSystems[index].score =
              Number(system.score) +
              Number(modifiers.ceilingCassetteElligibilitySlim / zones.length);
          }
          if (
            zone.spaces[0].attributes.find((a) => a.code === 'joist_width')?.value === '24"' &&
            airHandlerType === 'cassette'
          ) {
            scoredSystems[index].score =
              Number(system.score) +
              Number(modifiers.ceilingCassetteElligibilityStandard / zones.length);
          }
        }

        // modify score based on drop ceiling cassette compatibility
        if (
          zone.spaces.length === 1 &&
          zone.spaces[0].attributes.find((a) => a.code === 'ceiling_type')?.value === 'Drop Ceiling' &&
          airHandlerType === 'cassette_drop_ceiling'
        ) {
          scoredSystems[index].score =
            Number(system.score) +
            Number(modifiers.ceilingCassetteElligibilityDrop / zones.length);
        }

        // modify score based on air handler suggestion override
        if (!zone.items.some((item) => item.airHandlerSuggestionOverride)) {
          // modify score based on install complexity if they chose self install
          if (
            airHandlerTypeProperties &&
            (customerInstallationPlan === 'Self Installation' || customerInstallationDifficultyPreference) &&
            airHandlerTypeProperties.installationComplexityRank <= (installationDifficultyValues[customerInstallationDifficultyPreference] ?? 3)
          ) {
            scoredSystems[index].score =
              Number(system.score) +
              Number(1 * modifiers.complexityMultiplier);
          }

          // modify score based on aesthetics
          if (
            airHandlerTypeProperties &&
            customerAestheticsPreference === 'Hidden'
          ) {
            scoredSystems[index].score =
              Number(system.score) +
              Number(
                (5 - airHandlerTypeProperties.aestheticsRank) *
                modifiers.aestheticsMultiplier
              );
          }
        }

        // modify score based on spaces' air handler rankings
        zone.spaces.forEach((space) => {
          space.airHandlerRankings.forEach((type, typeIndex) => {
            if (type === airHandlerType) {
              scoredSystems[index].score = Number(system.score) +
                Number(
                  (space.airHandlerRankings.length - typeIndex) *
                  modifiers.airHandlerRankMultiplier
                );
            }
          });
        });

        // boost score if MRAH is applicable
        if (airHandlerType === 'concealed' && zone.spaces.length > 1) {
          scoredSystems[index].score = Number(system.score) + Number(modifiers.mrahModifier);
        }

      } else {
        console.error(`Zone ${zoneId} not found, unable to rank systems`);
      }
    });
  });
  console.log({ scoredSystems });
  return scoredSystems.sort((a, b) => b.score - a.score);
};

// // Function to get a score based on the standard deviation of an array of numbers
export function getStandardDeviationScore(arr: number[], modifier = 1) {
  const mean = arr.reduce((a, b) => a + b, 0) / arr.length;
  const variance = arr.reduce((a, b) => a + ((b - mean) ** 2), 0) / arr.length;
  return (1 / (1 + Math.sqrt(variance))) * modifier; // Higher score for lower standard deviation
}

// Function to get a score based on the closeness to the mean of an array of numbers, normalized to a range of 1 to 10
export function getClosenessToMeanScore(arr: number[], modifier = 1) {
  if (arr.length === 0) return 1;
  const mean = arr.reduce((a, b) => a + b, 0) / arr.length;
  const totalDeviation = arr.reduce((sum, value) => sum + Math.abs(value - mean), 0);
  const maxDeviation = mean * arr.length; // The maximum possible deviation (all values at extremes)
  const normalizedScore = 1 + ((10 - 1) * (1 - (totalDeviation / maxDeviation)));
  return Math.max(1, Math.min(10, normalizedScore)) * modifier;
}

// Function to get a score based on how far a number is from the max number in an array
export function getFurthestFromMaxScore(value: number, arr: number[], modifier = 1) {
  const maxNumber = Math.max(...arr);
  const minNumber = Math.min(...arr);
  if (maxNumber === minNumber) {
    return modifier;
  }
  return ((maxNumber - value) / (maxNumber - minNumber)) * modifier;
}

// Function to get a score based on how close a number is to the max number in an array
export function getClosestToMaxScore(value: number, arr: number[], modifier = 1) {
  const maxNumber = Math.max(...arr);
  const minNumber = Math.min(...arr);
  if (maxNumber === minNumber) {
    return modifier;
  }
  return ((value - minNumber) / (maxNumber - minNumber)) * modifier;
}

export function rankConfigs(
  configurations: Configuration[],
  customerValues: Attribute[] | null,
  structure: Level[],
  items: Item[],
  modifiers: ConfigRankingModifiers | null
) {

  // Define weights based on customer values
  const installationPreference = customerValues?.find(value => value.code === 'installation_difficulty')?.value || '';
  const savingsOrOptimalPreference = customerValues?.find(value => value.code === 'savings_preference')?.value || '';
  const weights = {
    optimalLayout: 1,
    optimalForSavings: 1,
    optimalForCoverage: 1,
    optimalLineSetDistance: 1,
    shortestLineSet: 1,
    leastComponents: 1,
    mostCondensers: 1,
    zoneSimilarSizes: 1,
    heatingCoolingSimilarSizes: 1,
    lineSetSimilarSizes: 1,
    electricalPanelDistance: 1,
  };

  if (installationPreference === 'Simple Installation Preferred') {
    weights.shortestLineSet = 2;
    weights.leastComponents = 2;
  } else if (installationPreference === 'Okay with Challenging Installation') {
    weights.shortestLineSet = 1;
    weights.leastComponents = 1;
  }

  if (savingsOrOptimalPreference === 'Optimal System') {
    weights.optimalForCoverage = 3;
    weights.optimalForSavings = 0.5;
  } else if (savingsOrOptimalPreference === 'Savings Up Front') {
    weights.optimalForSavings = 3;
    weights.optimalForCoverage = 0.5;
  }

  const scoredConfigs = configurations.map(config => ({
    ...config,
    score: 1,
    tags: [] as string[]
  }));

  const configuredLevelsCount = structure.filter(level => level.sections.some(section => section.spaces.length > 0)).length;

  const allConfigsHaveLineSetInfo = scoredConfigs.every(config =>
    config.condensers.every(condenser =>
      condenser.zones.every(zone => zone.distanceFromCondenser !== undefined)
    )
  );

  const panelDistances = configurations.flatMap(c => c.condensers.map((condenser) => condenser.distanceFromElectricalPanel).filter((distance) => distance !== undefined)) as number[];

  const updatedConfigs = scoredConfigs.map(config => {
    console.groupCollapsed(`Ranking config: ${config.name}`);
    const condenserCount = config.condensers.length;
    const isDucted = config.type === 'ducted';
    // eslint-disable-next-line prefer-const
    let { score, tags } = config;
    // "Optimal Layout": Add points for optimal layout
    let isOptimalLayout = true;
    let isOptimalLayoutForSavings = true;
    let isOptimalLayoutForCoverage = true;
    if (condenserCount === configuredLevelsCount || isDucted) { // If each level has 1 condenser or if config is ducted this is optimal 
      for (const condenser of config.condensers) {
        for (const zone of condenser.zones) {
          console.log({ config: config.name, zoneName: zone.name });
          const restrictedTypes = getRestrictedAirHandlerTypes(zone, structure, items, condenser.type === 'ducted');
          const canUseMultiroom = !restrictedTypes.includes('concealed');
          console.log(`${zone.name} can use multi room: ${canUseMultiroom}, restrictedTypes: ${restrictedTypes}, zone length: ${zone.zoneItems.length}`);
          if (!canUseMultiroom && zone.zoneItems.length > 1) {
            // If can't use a mrah but can share an AH with another space in the section, optimal for savings but not for coverage. If they want optimal coverage, each space should have its own AH. 
            for (const zoneItem of zone.zoneItems) {
              const space = findSpaceById(structure, zoneItem.structureItemId);
              if (!space) {
                console.error(`Space not found for structureItemId: ${zoneItem.structureItemId}`);
                isOptimalLayout = false;
                break;
              }
              const spaceSharesAir = space.attributes.find(attr => attr.code === 'share_air')?.value === 'Open Floor Plan' || space.attributes.find(attr => attr.code === 'share_air')?.value === 'Sharing Air With Door Open';
              if (spaceSharesAir) {
                isOptimalLayout = true;
                isOptimalLayoutForCoverage = false;
                isOptimalLayoutForSavings = true;
                console.log(`Semi-Optimal layout for ${zone.name} -> Optimal layout for savings but not for coverage. Optimal Coverage zones should use a MRAH OR have individual air handlers. Breaking loop. spaceSharesAir: ${spaceSharesAir} , canUseMultiRoom: ${canUseMultiroom} , zone length: ${zone.zoneItems.length}`);
              } else {
                isOptimalLayout = false;
                console.log(`None optimal layout for ${zone.name} -> None optimal layout (!canUseMultiroom && zone.zoneItems.length > 1 && cannot share air). Breaking loop. spaceSharesAir: ${spaceSharesAir} , canUseMultiRoom: ${canUseMultiroom} , zone length: ${zone.zoneItems.length}`);
                break;
              }
            }
          }
          if (canUseMultiroom) {
            if (zone.zoneItems.length > 1) {
              for (const zoneItem of zone.zoneItems) {
                const space = findSpaceById(structure, zoneItem.structureItemId);
                if (!space) {
                  console.error(`Space not found for structureItemId: ${zoneItem.structureItemId}`);
                  isOptimalLayout = false;
                  break;
                }
                const spaceSharesAir = space.attributes.find(attr => attr.code === 'share_air')?.value === 'Open Floor Plan' || space.attributes.find(attr => attr.code === 'share_air')?.value === 'Sharing Air With Door Open';
                const spaceHasSpaceAbove = space.attributes.find(attr => attr.code === 'space_above')?.value === 'Yes';
                const spaceHasSpaceBelow = space.attributes.find(attr => attr.code === 'space_below')?.value === 'Yes';
                const itemTypicallySharesAir = items.find(item => item.id === space.itemId)?.typicallySharesAir;
                if (!spaceSharesAir && !spaceHasSpaceAbove && !spaceHasSpaceBelow && !itemTypicallySharesAir) {
                  console.log(`Space ${space.label} does not share air or does not have space above/below, none optimal layout! breaking loop. spaceSharesAir: ${spaceSharesAir}, spaceHasSpaceAbove: ${spaceHasSpaceAbove}, spaceHasSpaceBelow: ${spaceHasSpaceBelow}, itemTypicallySharesAir: ${itemTypicallySharesAir}`);
                  isOptimalLayout = false;
                  break;
                }
                if (spaceSharesAir && (!spaceHasSpaceAbove && !spaceHasSpaceBelow)) {
                  console.log(`Space ${space.label} can share air, but cannot use MRAH. Optimal layout for savings but not for coverage. spaceSharesAir: ${spaceSharesAir}, spaceHasSpaceAbove: ${spaceHasSpaceAbove}, spaceHasSpaceBelow: ${spaceHasSpaceBelow}, itemTypicallySharesAir: ${itemTypicallySharesAir}`);
                  isOptimalLayoutForCoverage = false;
                }
              }
            }
          }
          if (!isOptimalLayout) {
            console.log(`None optimal layout for ${zone.name}, breaking loop`);
            break;
          } else {
            console.log(`Optimal layout for ${zone.name}`);
          }
        }
        if (!isOptimalLayout) {
          break;
        }
      }
      // Ensure spaces in the same section that can share air or use MRAH are also in the same zone
      for (const level of structure) {
        for (const section of level.sections) {
          const spacesThatShareAir: any = [];
          const spacesThatUseMRAH: any = [];
          for (const space of section.spaces) {
            const spaceSharesAir = space.attributes.find(attr => attr.code === 'share_air')?.value === 'Open Floor Plan' || space.attributes.find(attr => attr.code === 'share_air')?.value === 'Sharing Air With Door Open';
            const spaceHasSpaceAbove = space.attributes.find(attr => attr.code === 'space_above')?.value === 'Yes';
            const spaceHasSpaceBelow = space.attributes.find(attr => attr.code === 'space_below')?.value === 'Yes';
            if (spaceSharesAir) {
              spacesThatShareAir.push(space.uniqueId);
            }
            if (spaceHasSpaceAbove || spaceHasSpaceBelow) {
              spacesThatUseMRAH.push(space.uniqueId);
            }
          }
          if (spacesThatShareAir.length > 1) {
            let allSpacesInSameZone = false;
            for (const condenser of config.condensers) {
              for (const zone of condenser.zones) {
                const zoneItemIds = zone.zoneItems.map(zoneItem => zoneItem.structureItemId);
                if (spacesThatShareAir.every(spaceId => zoneItemIds.includes(spaceId))) {
                  allSpacesInSameZone = true;
                }
              }
            }
            if (!allSpacesInSameZone) {
              console.log(`Semi-Optimal Layout! Spaces with IDs: ${spacesThatShareAir.join(', ')} can share air but are not in the same zone, not optimal for savings.`);
              isOptimalLayoutForSavings = false;
            }
          }
          if (spacesThatUseMRAH.length > 1) {
            let allSpacesInSameZone = false;
            for (const condenser of config.condensers) {
              for (const zone of condenser.zones) {
                const zoneItemIds = zone.zoneItems.map(zoneItem => zoneItem.structureItemId);
                if (spacesThatUseMRAH.every(spaceId => zoneItemIds.includes(spaceId))) {
                  allSpacesInSameZone = true;
                }
              }
            }
            if (!allSpacesInSameZone) {
              console.log(`None optimal layout. Spaces with IDs: ${spacesThatUseMRAH.join(', ')} can use MRAH but are not in the same zone.`);
              isOptimalLayout = false;
              break;
            }
          }
        }
        if (!isOptimalLayout) {
          break;
        }
      }
      if (isDucted || isOptimalLayout) {
        score += (modifiers?.optimalLayout ?? 1) * weights.optimalLayout;
        console.log(score, '1');
        tags.push('Optimal Layout');
      }
      if (isDucted || (isOptimalLayout && isOptimalLayoutForCoverage)) {
        score += (modifiers?.optimalForCoverage ?? 1) * weights.optimalForCoverage;
        console.log(score, '2');
        tags.push('Optimal Coverage');
      }
      if (isDucted || (isOptimalLayout && isOptimalLayoutForSavings)) {
        score += (modifiers?.optimalForSavings ?? 1) * weights.optimalForSavings;
        console.log(score, '3');
        tags.push('Optimal Savings');
      }
    } else {
      console.log(`None optimal layout. Configured levels: ${configuredLevelsCount}, condenser count: ${condenserCount}`);
    }

    // "Ducted Config": Add points if the configuration is ducted
    if (isDucted) {
      score += (modifiers?.ductedConfig ?? 1);
      console.log(score, '4');
      tags.push('BMAH');
    }

    if (allConfigsHaveLineSetInfo) {
      // "Optimal Line Set Distance": Add points if every condenser in the config has a lineset of 1.5x the shortest lineset
      const allCondensersHaveSimilarLineSetLength = config.condensers.every(condenser => {
        const distances = condenser.zones.map(zone => zone.distanceFromCondenser).filter((distance): distance is number => distance !== undefined);
        const maxDistance = Math.max(...distances);
        const minDistance = Math.min(...distances);
        return maxDistance <= 1.5 * minDistance;
      });
      if (allCondensersHaveSimilarLineSetLength) {
        score += 5 * weights.optimalLineSetDistance;
        console.log(score, '5');
        tags.push('Optimal Line Set Distance');
      }
    }

    const totalLineSetLength = config.condensers.reduce((total, condenser) =>
      total + condenser.zones.reduce((zoneTotal, zone) =>
        zoneTotal + (zone.distanceFromCondenser || 0), 0), 0);

    const airHandlerCount = config.condensers.reduce((total, condenser) => total + condenser.zones.length, 0);
    const totalComponents = condenserCount + airHandlerCount;

    // New scoring features from rankConfigsNew
    // Add score for similar zone sizes
    const similarZoneSizeScores: number[] = [];
    if (!isDucted) {
      for (const condenser of config.condensers) {
        if (condenser.zones.length > 0) {
          const zoneSizes = condenser.zones.map(zone => zone.zoneItems.map(item => item.BTUs.heating).reduce((a, b) => a + b, 0));
          const zoneSizeStandardDeviation = getClosenessToMeanScore(zoneSizes, weights.zoneSimilarSizes);
          similarZoneSizeScores.push(zoneSizeStandardDeviation);
        }
      }
    }
    if (similarZoneSizeScores.length > 0) {
      score += (similarZoneSizeScores.reduce((sum, s) => sum + s, 0) / similarZoneSizeScores.length);
      console.log(score, '6');
    }

    // Add score for similar heating and cooling load sizes
    const similarHeatingCoolingScores: number[] = [];
    if (!isDucted) {
      for (const condenser of config.condensers) {
        for (const zone of condenser.zones) {
          const coolingLoad = zone.zoneItems.map(item => item.BTUs.cooling).reduce((a, b) => a + b, 0);
          const heatingLoad = zone.zoneItems.map(item => item.BTUs.heating).reduce((a, b) => a + b, 0);
          const coolingHeatingDifference = getClosenessToMeanScore([coolingLoad, heatingLoad], weights.heatingCoolingSimilarSizes);
          similarHeatingCoolingScores.push(coolingHeatingDifference);
        }
      }
    }
    if (similarHeatingCoolingScores.length > 0) {
      score += similarHeatingCoolingScores.reduce((sum, s) => sum + s, 0) / similarHeatingCoolingScores.length;
      console.log(score, '7');
    }

    // Add score for similar line set lengths
    const similarLineSetScores: number[] = [];
    if (!isDucted) {
      for (const condenser of config.condensers) {
        if (condenser.zones.length > 0 && condenser.zones.every(zone => zone.distanceFromCondenser !== undefined)) {
          const lineSetLengths = condenser.zones.map(zone => zone.distanceFromCondenser) as number[];
          const lineSetStandardDeviation = getClosenessToMeanScore(lineSetLengths, weights.lineSetSimilarSizes);
          similarLineSetScores.push(lineSetStandardDeviation);
        }
      }
    }
    if (similarLineSetScores.length > 0) {
      score += similarLineSetScores.reduce((sum, s) => sum + s, 0) / similarLineSetScores.length;
      console.log(score, '8');
    }

    // Add score for distance to electrical panel, further away is worse
    const panelDistanceScores: number[] = [];
    for (const condenser of config.condensers) {
      if (condenser.distanceFromElectricalPanel !== undefined) {
        const panelDistanceScore = getFurthestFromMaxScore(condenser.distanceFromElectricalPanel, panelDistances, weights.electricalPanelDistance);
        panelDistanceScores.push(panelDistanceScore);
      }
    }
    if (panelDistanceScores.length > 0) {
      score += (panelDistanceScores.reduce((sum, s) => sum + s, 0) / panelDistanceScores.length) * 10;
      console.log(score, '9');
    }

    console.groupEnd();
    return { ...config, score, totalLineSetLength, condenserCount, totalComponents, tags };
  });

  let finalConfigs = updatedConfigs;

  // "Shortest Line Set": award points to shortest line set config, else use most condenseres as basis for shortest line set
  if (allConfigsHaveLineSetInfo) {
    const shortestLineSetConfig = updatedConfigs.reduce((shortest, config) => {
      if (!shortest) {
        return config;
      }
      return config.totalLineSetLength < shortest.totalLineSetLength ? config : shortest;
    }, updatedConfigs[0]);
    finalConfigs = updatedConfigs.map(config => {
      if (config.uniqueId === shortestLineSetConfig.uniqueId) {
        return {
          ...config,
          score: config.score + (modifiers?.shortestLineSet ?? 1) * weights.shortestLineSet,
          tags: [...config.tags, 'Shortest Line Set']
        };
      }
      return config;
    });
  } else {
    const mostCondenserCount = Math.max(...updatedConfigs.map(config => config.condenserCount));
    const configsWithMostCondensers = updatedConfigs.filter(config => config.condenserCount === mostCondenserCount);
    if (configsWithMostCondensers.length === 1) {
      finalConfigs = finalConfigs.map(config => {
        if (config.uniqueId === configsWithMostCondensers[0].uniqueId) {
          return {
            ...config,
            score: config.score + (modifiers?.mostCondensers ?? 1) * weights.mostCondensers,
            tags: [...config.tags, 'Shortest Line Set']
          };
        }
        return config;
      });
    }
  }

  // "Least Components": Add points to the configuration with the least number of components
  const leastComponentsCount = Math.min(...updatedConfigs.map(config => config.totalComponents));
  const configsWithLeastComponents = updatedConfigs.filter(config => config.totalComponents === leastComponentsCount);
  if (configsWithLeastComponents.length === 1) {
    finalConfigs = finalConfigs.map(config => {
      if (config.uniqueId === configsWithLeastComponents[0].uniqueId) {
        return {
          ...config,
          score: config.score + (modifiers?.leastComponents ?? 1) * weights.leastComponents,
          tags: [...config.tags, 'Least Components']
        };
      }
      return config;
    });
  }

  finalConfigs = updatedConfigs.map(config => {
    if (!config.valid) {
      return { ...config, score: 0, tags: ['Invalid Configuration'] };
    }
    return config;
  });

  const rankedConfigs = finalConfigs.sort((a, b) => b.score - a.score);

  const updatedRankedConfigs = rankedConfigs.map((config, index) => {
    if (index === 0) {
      return config;
    }
    const updatedTags = config.tags.map(tag => tag.replace('Optimal', 'Good'));
    return { ...config, tags: updatedTags };
  });

  return updatedRankedConfigs;
}

export function calculateOptimalDesignScore(selectedCombination: RankedSystem[], configScore: number): number {
  const totalScore = selectedCombination.reduce((sum, system) => sum + system.score, 0);
  const averageScore = totalScore / selectedCombination.length;
  return configScore ? averageScore + configScore : averageScore;
}

export function rankConfigsNew(
  configurations: Configuration[],
  customerValues: Attribute[],
  structure: Level[],
  items: Item[],
  modifiers: ConfigRankingModifiers | null
): Configuration[] {
  const savingsPreference = customerValues.find(value => value.code === 'savings_preference')?.value;
  const installationPlan = customerValues.find(value => value.code === 'installation_plan')?.value;
  const difficultyPreference = customerValues.find(value => value.code === 'installation_difficulty')?.value;
  const allConfigsHaveLineSetInfo = configurations.every(config =>
    config.condensers.every(condenser =>
      condenser.zones.every(zone => zone.distanceFromCondenser !== undefined)
    )
  );
  const allConfigsHaveElectricalPanelInfo = configurations.every(config => config.condensers.every(condenser => condenser.distanceFromElectricalPanel !== undefined));
  const allPanelDistances = configurations.flatMap(c => c.condensers.map((condenser) => condenser.distanceFromElectricalPanel).filter((distance) => distance !== undefined)) as number[];
  const allLineSetLengths = configurations.flatMap(c => c.condensers.map((condenser) => condenser.zones.map(zone => zone.distanceFromCondenser).filter((distance) => distance !== undefined).reduce((total, distance) => total + distance, 0)).reduce((total, distance) => total + distance, 0)) as number[];
  const allComponentCounts = configurations.map((c) => c.condensers.length + c.condensers.reduce((sum, condenser) => sum + condenser.zones.length, 0));
  const weights = {
    optimalLayout: 1,
    optimalForSavings: 1,
    optimalForCoverage: 1,
    optimalLineSetDistance: 1,
    shortestLineSet: 1,
    leastComponents: 1,
    mostCondensers: 1,
    zoneSimilarSizes: 1,
    heatingCoolingSimilarSizes: 1,
    lineSetSimilarSizes: 1,
    electricalPanelDistance: 1,
    ductedConfig: 2,
    hybridConfig: 1,
  };

  if (installationPlan !== 'Professional Installation' && difficultyPreference === 'Simple Installation Preferred') {
    weights.shortestLineSet = 3;
    weights.electricalPanelDistance = 3;
  } else if (installationPlan !== 'Professional Installation' && difficultyPreference === 'Okay with Moderately Difficult Installation') {
    weights.shortestLineSet = 2;
    weights.electricalPanelDistance = 2;
  } else if (installationPlan !== 'Professional Installation' && difficultyPreference === 'Okay with Challenging Installation') {
    weights.shortestLineSet = 1;
    weights.electricalPanelDistance = 1;
  }

  if (savingsPreference === 'Optimal System') {
    weights.optimalForCoverage = 3;
    weights.optimalLayout = 3;
    weights.optimalLineSetDistance = 3;
    weights.lineSetSimilarSizes = 3;
    weights.heatingCoolingSimilarSizes = 3;
    weights.optimalForSavings = 0.5;
    weights.zoneSimilarSizes = 3;
  } else if (savingsPreference === 'Savings Up Front') {
    weights.optimalForCoverage = 0.5;
    weights.optimalLayout = 0.5;
    weights.optimalLineSetDistance = 0.5;
    weights.lineSetSimilarSizes = 0.5;
    weights.heatingCoolingSimilarSizes = 0.5;
    weights.optimalForSavings = 3;
    weights.zoneSimilarSizes = 0.5;
  }

  if (difficultyPreference === 'Simple Installation Preferred' && savingsPreference === 'Savings Up Front') {
    weights.leastComponents = 4;
  } else if (difficultyPreference === 'Okay with Moderately Difficult Installation' && savingsPreference === 'Savings Up Front') {
    weights.leastComponents = 3;
  } else if (difficultyPreference === 'Okay with Challenging Installation' && savingsPreference === 'Savings Up Front') {
    weights.leastComponents = 2;
  }

  const rankedConfigs = configurations.map((config) => {
    let score = 1;
    const tags: string[] = [];
    let isOptimalLayout = true;
    let isOptimalForSavings = true;

    // check if the configuration is optimal
    for (const condenser of config.condensers) {
      for (const zone of condenser.zones) {
        const restrictedTypes = getRestrictedAirHandlerTypes(zone, structure, items, condenser.type === 'ducted');
        const canUseMultiroom = !restrictedTypes.includes('concealed');
        if (!canUseMultiroom && zone.zoneItems.length > 1) {
          // If can't use a mrah but can share an AH with another space in the section, optimal for savings but not for coverage. If they want optimal coverage, each space should have its own AH. 
          for (const zoneItem of zone.zoneItems) {
            const space = findSpaceById(structure, zoneItem.structureItemId);
            if (!space) {
              isOptimalLayout = false;
              break;
            }
            const spaceSharesAir = space.attributes.find(attr => attr.code === 'share_air')?.value === 'Open Floor Plan' || space.attributes.find(attr => attr.code === 'share_air')?.value === 'Sharing Air With Door Open';
            if (spaceSharesAir) {
              isOptimalLayout = true;
            } else {
              isOptimalLayout = false;
              break;
            }
          }
        }
        if (canUseMultiroom && zone.zoneItems.length > 1) {
          for (const zoneItem of zone.zoneItems) {
            const space = findSpaceById(structure, zoneItem.structureItemId);
            if (!space) {
              isOptimalLayout = false;
              break;
            }
            const spaceSharesAir = space.attributes.find(attr => attr.code === 'share_air')?.value === 'Open Floor Plan' || space.attributes.find(attr => attr.code === 'share_air')?.value === 'Sharing Air With Door Open';
            const spaceHasSpaceAbove = space.attributes.find(attr => attr.code === 'space_above')?.value === 'Yes';
            const spaceHasSpaceBelow = space.attributes.find(attr => attr.code === 'space_below')?.value === 'Yes';
            const itemTypicallySharesAir = items.find(item => item.id === space.itemId)?.typicallySharesAir;
            if (!spaceSharesAir && !spaceHasSpaceAbove && !spaceHasSpaceBelow && !itemTypicallySharesAir) {
              isOptimalLayout = false;
              break;
            }
          }
        }
      }
      if (!isOptimalLayout) {
        break;
      }
    }

    // Ensure spaces in the same section that can share air or use MRAH are also in the same zone
    for (const level of structure) {
      for (const section of level.sections) {
        const spacesThatShareAir: Space['uniqueId'][] = [];
        const spacesThatCanUseMRAH: Space['uniqueId'][] = [];
        for (const space of section.spaces) {
          const spaceSharesAir = space.attributes.find(attr => attr.code === 'share_air')?.value === 'Open Floor Plan' || space.attributes.find(attr => attr.code === 'share_air')?.value === 'Sharing Air With Door Open';
          const spaceHasSpaceAbove = space.attributes.find(attr => attr.code === 'space_above')?.value === 'Yes';
          const spaceHasSpaceBelow = space.attributes.find(attr => attr.code === 'space_below')?.value === 'Yes';
          if (spaceSharesAir) {
            spacesThatShareAir.push(space.uniqueId);
          }
          if (spaceHasSpaceAbove || spaceHasSpaceBelow) {
            spacesThatCanUseMRAH.push(space.uniqueId);
          }
        }
        if (spacesThatShareAir.length > 1) {
          let allSharingAirSpacesInSameZone = false;
          for (const condenser of config.condensers) {
            for (const zone of condenser.zones) {
              const zoneItemIds = zone.zoneItems.map(zoneItem => zoneItem.structureItemId);
              if (spacesThatShareAir.every(spaceId => zoneItemIds.includes(spaceId))) {
                allSharingAirSpacesInSameZone = true;
              }
            }
          }
          if (!allSharingAirSpacesInSameZone) {
            isOptimalForSavings = false;
          }
        }
        if (spacesThatCanUseMRAH.length > 1) {
          let allMRAHSpacesInSameZone = false;
          for (const condenser of config.condensers) {
            for (const zone of condenser.zones) {
              const zoneItemIds = zone.zoneItems.map(zoneItem => zoneItem.structureItemId);
              if (spacesThatCanUseMRAH.every(spaceId => zoneItemIds.includes(spaceId))) {
                allMRAHSpacesInSameZone = true;
              }
            }
          }
          if (!allMRAHSpacesInSameZone) {
            isOptimalLayout = false;
            break;
          }
        }
      }
      if (!isOptimalLayout && !isOptimalForSavings) {
        break;
      }
    }

    // add score for optimal layout
    if (config.type === 'ducted' || isOptimalLayout) {
      score += (modifiers?.optimalLayout ?? 1) * weights.optimalLayout;
      tags.push('Optimal Coverage');
    }

    // add score for savings
    if (config.type === 'ducted' || (isOptimalForSavings && !configurations.some(conf => conf.type === 'ducted' && (conf.condensers.length + conf.condensers.reduce((sum, condenser) => sum + condenser.zones.length, 0)) < (config.condensers.length + config.condensers.reduce((sum, condenser) => sum + condenser.zones.length, 0))))) {
      score += (modifiers?.optimalForSavings ?? 1) * weights.optimalForSavings;
      tags.push('Optimal Savings');
    }

    // add score for optimal line set distances
    if (allConfigsHaveLineSetInfo) {
      const allCondensersHaveSimilarLineSetLength = config.condensers.every(condenser => {
        const distances = condenser.zones.map(zone => zone.distanceFromCondenser).filter((distance): distance is number => distance !== undefined);
        const maxDistance = Math.max(...distances);
        const minDistance = Math.min(...distances);
        return maxDistance <= 1.5 * minDistance;
      });
      if (allCondensersHaveSimilarLineSetLength) {
        score += (modifiers?.optimalLineSetDistance ?? 1) * weights.optimalLineSetDistance;
        tags.push('Optimal Line Set Distances');
      }
    }

    // add score for shortest Line Sets
    if (allConfigsHaveLineSetInfo && config.condensers.map((condenser) => condenser.zones.map(zone => zone.distanceFromCondenser).filter((distance) => distance !== undefined).reduce((total, distance) => total + distance, 0)).reduce((total, distance) => total + distance, 0) === Math.min(...allLineSetLengths)) {
      score += (modifiers?.shortestLineSet ?? 1) * weights.shortestLineSet;
      tags.push('Shortest Line Sets');
    }

    // add score for least Components
    if (config.condensers.length + config.condensers.reduce((sum, condenser) => sum + condenser.zones.length, 0) === Math.min(...allComponentCounts)) {
      score += (modifiers?.leastComponents ?? 1) * weights.leastComponents;
      tags.push('Easiest Installation');
    }

    // Add score for similar zone sizes
    const similarZoneSizeScores: number[] = [];
    if (config.type !== 'ducted') {
      for (const condenser of config.condensers) {
        if (condenser.zones.length > 0) {
          const zoneSizes = condenser.zones.map(zone => zone.zoneItems.map(item => item.BTUs.heating).reduce((a, b) => a + b, 0));
          const zoneSizeStandardDeviation = getClosenessToMeanScore(zoneSizes, weights.zoneSimilarSizes);
          similarZoneSizeScores.push(zoneSizeStandardDeviation);
        }
      }
    }
    if (similarZoneSizeScores.length > 0) {
      score += (similarZoneSizeScores.reduce((sum, s) => sum + s, 0) / similarZoneSizeScores.length);
    }

    // Add score for similar heating and cooling load sizes
    const similarHeatingCoolingScores: number[] = [];
    if (config.type !== 'ducted') {
      for (const condenser of config.condensers) {
        for (const zone of condenser.zones) {
          const coolingLoad = zone.zoneItems.map(item => item.BTUs.cooling).reduce((a, b) => a + b, 0);
          const heatingLoad = zone.zoneItems.map(item => item.BTUs.heating).reduce((a, b) => a + b, 0);
          const coolingHeatingDifference = getClosenessToMeanScore([coolingLoad, heatingLoad], weights.heatingCoolingSimilarSizes);
          similarHeatingCoolingScores.push(coolingHeatingDifference);
        }
      }
    }
    if (similarHeatingCoolingScores.length > 0) {
      score += similarHeatingCoolingScores.reduce((sum, s) => sum + s, 0) / similarHeatingCoolingScores.length;
    }

    // Add score for similar line set lengths
    if (allConfigsHaveLineSetInfo) {
      const similarLineSetScores: number[] = [];
      if (config.type !== 'ducted') {
        for (const condenser of config.condensers) {
          if (condenser.zones.length > 0 && condenser.zones.every(zone => zone.distanceFromCondenser !== undefined)) {
            const lineSetLengths = condenser.zones.map(zone => zone.distanceFromCondenser) as number[];
            const lineSetStandardDeviation = getClosenessToMeanScore(lineSetLengths, weights.lineSetSimilarSizes);
            similarLineSetScores.push(lineSetStandardDeviation);
          }
        }
      }
      if (similarLineSetScores.length > 0) {
        score += similarLineSetScores.reduce((sum, s) => sum + s, 0) / similarLineSetScores.length;
      }
    }

    // Add score for distance to electrical panel, further away is worse
    if (allConfigsHaveElectricalPanelInfo) {
      const panelDistanceScores: number[] = [];
      for (const condenser of config.condensers) {
        if (condenser.distanceFromElectricalPanel !== undefined) {
          const panelDistanceScore = getFurthestFromMaxScore(condenser.distanceFromElectricalPanel, allPanelDistances, weights.electricalPanelDistance);
          panelDistanceScores.push(panelDistanceScore);
        }
      }
      if (panelDistanceScores.length > 0) {
        score += (panelDistanceScores.reduce((sum, s) => sum + s, 0) / panelDistanceScores.length) * 10;
      }
    }

    // add additional score for ducted configs
    if (config.type === 'ducted') {
      score += (modifiers?.ductedConfig ?? 1) * weights.ductedConfig;
    }

    // add score for hybrid configs
    if (config.type === 'hybrid') {
      score += (modifiers?.hybridConfig ?? 1) * weights.hybridConfig;
    }

    return { ...config, score, tags };
  });

  rankedConfigs.sort((a, b) => b.score - a.score);

  rankedConfigs[0].tags.unshift('Recommended');

  return rankedConfigs;
}