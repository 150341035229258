import React, { JSXElementConstructor, MouseEvent, SVGProps } from 'react';
import clsx from 'clsx';

type ButtonProps = {
  color?: 'white' | 'primary' | 'secondary' | 'gray' | 'green' | 'red' | 'purple' | 'subtle';
  text: string;
  onClick?: (event?: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void;
  onDisabledClick?: (event?: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void;
  disabled?: boolean;
  fullWidth?: boolean;
  size?: 'md' | 'lg';
  icon?: JSXElementConstructor<SVGProps<SVGSVGElement>>;
  iconSize?: 'sm' | 'md' | 'lg';
  iconPosition?: 'left' | 'right';
  loading?: boolean;
};

export default function Button({
  color = 'primary',
  text,
  onClick,
  onDisabledClick,
  disabled = false,
  fullWidth = false,
  size = 'md',
  icon,
  iconSize = 'sm',
  iconPosition = 'left',
  loading
}: ButtonProps) {
  const Icon = icon;
  const classes = clsx(
    'inline-flex items-center gap-1 justify-center rounded-md border focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2',
    (color === 'white' || !color) &&
    'border-gray-300 bg-white text-gray-700 hover:bg-gray-50 shadow-sm',
    color === 'primary' &&
    'border-transparent bg-blue-600 text-white hover:bg-blue-700 shadow-sm',
    color === 'secondary' &&
    'border-transparent bg-blue-100 text-blue-900 hover:bg-blue-200 shadow-sm',
    color === 'gray' && 'border-transparent bg-gray-200 hover:bg-gray-300 shadow-sm',
    color === 'green' &&
    'border-transparent bg-green-600 text-white hover:bg-green-700 shadow-sm',
    color === 'red' &&
    'border-transparent bg-red-600 text-white hover:bg-red-700 shadow-sm',
    color === 'purple' &&
    'border-transparent bg-purple-600 text-white hover:bg-purple-700 shadow-sm',
    color === 'subtle' && 'border-transparent text-gray-500 hover:bg-gray-200',
    disabled && 'opacity-[70%] cursor-not-allowed',
    fullWidth && 'w-full',
    size === 'md' && 'px-3 py-2',
    size === 'lg' && 'px-4 py-3',
    iconPosition === 'right' && 'flex-row-reverse',
    size === 'lg' ? 'font-semibold' : 'font-medium'
  );
  const iconClasses = clsx(
    iconSize === 'sm' && 'w-4 h-4',
    iconSize === 'md' && 'w-5 h-5',
    iconSize === 'lg' && 'w-6 h-6',
    loading && 'animate-spin'
  );

  const handleClick = (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    if (onClick && !disabled) {
      onClick(event);
    }
    if (onDisabledClick && disabled) {
      onDisabledClick(event);
    }
  };

  return (
    <button
      type='button'
      className={classes}
      onClick={(e) => handleClick(e)}
    >
      {Icon && !loading && <Icon className={iconClasses} />}
      {loading && (
        <svg className={iconClasses} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill={color === 'white' ? 'black' : 'white'} className="opacity-40" d="M256 64C150 64 64 150 64 256s86 192 192 192c70.1 0 131.3-37.5 164.9-93.6l.1 .1c-6.9 14.9-1.5 32.8 13 41.2c15.3 8.9 34.9 3.6 43.7-11.7c.2-.3 .4-.6 .5-.9l0 0C434.1 460.1 351.1 512 256 512C114.6 512 0 397.4 0 256S114.6 0 256 0c-17.7 0-32 14.3-32 32s14.3 32 32 32z" />
          <path fill={color === 'white' ? 'black' : 'white'} d="M224 32c0-17.7 14.3-32 32-32C397.4 0 512 114.6 512 256c0 46.6-12.5 90.4-34.3 128c-8.8 15.3-28.4 20.5-43.7 11.7s-20.5-28.4-11.7-43.7c16.3-28.2 25.7-61 25.7-96c0-106-86-192-192-192c-17.7 0-32-14.3-32-32z" />
        </svg>
      )}
      <span className='text-sm leading-none'>{text}</span>
    </button>
  );
}

Button.defaultProps = {
  color: 'primary',
  disabled: false,
  fullWidth: false,
  size: 'md',
  onClick: undefined,
  onDisabledClick: undefined,
  icon: undefined,
  iconSize: 'sm',
  iconPosition: 'left',
  loading: false
};
