import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import { Tab } from '@headlessui/react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import AirHandlers from 'views/admin/air-handlers';
import { HomeIcon } from '@heroicons/react/24/outline';
import Spaces from 'views/admin/spaces';
import Filtering from 'views/admin/filtering';
import Fenestration from 'views/admin/fenestration';
import Structure from 'views/admin/structure-type';
import ConfigRanking from 'views/admin/config-ranking';
import Ranking from 'views/admin/ranking';
import Tooltip from 'components/tooltip';
import { AdminContext } from 'data/context';
import LoadingOverlay from 'components/loading-overlay';
import Attributes from 'views/admin/attributes';
import AttributeFollowups from 'views/admin/attribute-followups';

export default function IndexPage() {
  const { loading } = useContext(AdminContext);
  const [activeTab, setActiveTab] = useState(0);

  const mainClasses = clsx(
    'w-full h-[100vh] bg-gray-100 select-none text-gray-900'
  );

  const tabClasses = (active: boolean) =>
    clsx(
      'h-full flex items-center gap-2 px-8 font-semibold whitespace-nowrap',
      active && 'border-b-2 border-blue-500 text-blue-600 bg-blue-50 pt-[1px]',
      !active && 'border-b border-gray-200 text-gray-700 hover:bg-gray-50'
    );

  const tabs = [
    'Air Handlers',
    'Spaces/Subspaces',
    'Filtering Logic',
    'Fenestration',
    'Structure Types',
    'Config Ranking',
    'Systems Ranking',
    'Attributes',
    'Attribute Followups'
  ];

  return (
    <main className={mainClasses}>
      <Tab.Group selectedIndex={activeTab}>
        <div className='flex h-full w-full flex-col'>
          <div className='flex h-12 w-full bg-white'>
            <Tab.List className='hidden-scrollbar flex overflow-x-auto'>
              {tabs.map((tab, tabIndex) => (
                <Tab
                  key={tab}
                  className='h-full focus:outline-none'
                  onClick={() => setActiveTab(tabIndex)}
                >
                  <div className={tabClasses(activeTab === tabIndex)}>
                    {tab}
                  </div>
                </Tab>
              ))}
            </Tab.List>
            <div className='h-full grow border-b border-gray-200' />
            <a
              href={`/${window.location.search}`}
              className='flex h-full shrink-0 cursor-pointer items-center border-b border-l border-gray-200 px-3 text-gray-900 hover:bg-gray-50 relative'
            >
              <HomeIcon className='h-6 w-6' />
              <Tooltip text='Back to System Selector' />
            </a>
          </div>
          <div className='flex grow'>
            <div className='relative grow'>
              <div className='absolute inset-0 overflow-y-auto p-5'>
                <DndProvider backend={HTML5Backend}>
                  <Tab.Panels className='focus:outline-none'>
                    <Tab.Panel className='focus:outline-none'>
                      <AirHandlers />
                    </Tab.Panel>
                    <Tab.Panel className='focus:outline-none'>
                      <Spaces />
                    </Tab.Panel>
                    <Tab.Panel className='focus:outline-none'>
                      <Filtering />
                    </Tab.Panel>
                    <Tab.Panel className='focus:outline-none'>
                      <Fenestration />
                    </Tab.Panel>
                    <Tab.Panel className='focus:outline-none'>
                      <Structure />
                    </Tab.Panel>
                    <Tab.Panel className='focus:outline-none'>
                      <ConfigRanking />
                    </Tab.Panel>
                    <Tab.Panel className='focus:outline-none'>
                      <Ranking />
                    </Tab.Panel>
                    <Tab.Panel className='focus:outline-none'>
                      <Attributes />
                    </Tab.Panel>
                    <Tab.Panel className='focus:outline-none'>
                      <AttributeFollowups />
                    </Tab.Panel>
                  </Tab.Panels>
                </DndProvider>
              </div>
            </div>
          </div>
        </div>
      </Tab.Group>
      <LoadingOverlay loading={loading} />
    </main>
  );
}
