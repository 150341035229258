import React from 'react';
import { FireIcon, SparklesIcon } from '@heroicons/react/20/solid';
import Tooltip from 'components/tooltip';
import { SystemOption } from 'data/types';

type CoverageIndicatorProps = {
  type: 'heating' | 'cooling';
  score: number;
  missingDays: number;
  minimumPercent: number;
  condenserCoverage?: SystemOption['condenserCoverage'];
} | {
  type: 'heating' | 'cooling';
  score: number;
  missingDays?: number;
  minimumPercent?: number;
  condenserCoverage: SystemOption['condenserCoverage'];
};

export default function CoverageIndicator({ type, score, missingDays, minimumPercent, condenserCoverage }: CoverageIndicatorProps) {
  return (
    <div className={`flex gap-1 items-center relative ${score < 100 ? 'cursor-help' : ''}`}>
      {type === 'heating' ? <FireIcon className='h-5 w-5 text-orange-500' /> : <SparklesIcon className='h-5 w-5 text-sky-500' />}
      <span>
        {Math.floor(score)}%
      </span>
      {score < 100 && type === 'heating' && (
        <Tooltip>
          {condenserCoverage ? condenserCoverage.filter(condenser => condenser.daysOfInsufficientHeating > 0).map((condenser, condenserIndex) => (
            <div key={condenserIndex} className='whitespace-nowrap text-center'>
              {condenserCoverage.length > 1 && `${condenser.condenserName}: `}{condenser.daysOfInsufficientHeating} day{condenser.daysOfInsufficientHeating > 1 && 's'} at {Math.floor(condenser.avgHeatingPercent)}% average coverage
            </div>
          )) : (
            <div>
              {missingDays} day{(missingDays ?? 1) > 1 && 's'} at {Math.floor((minimumPercent ?? 0))}% coverage
            </div>
          )}
        </Tooltip>
      )}
      {score < 100 && type === 'cooling' && (
        <Tooltip>
          {condenserCoverage ? condenserCoverage.filter(condenser => condenser.daysOfInsufficientCooling > 0).map((condenser, condenserIndex) => (
            <div key={condenserIndex} className='whitespace-nowrap text-center'>
              {condenserCoverage.length > 1 && `${condenser.condenserName}: `}{condenser.daysOfInsufficientCooling} day{condenser.daysOfInsufficientCooling > 1 && 's'} at {Math.floor(condenser.avgCoolingPercent)}% average coverage
            </div>
          )) : (
            <div>
              {missingDays} day{(missingDays ?? 1) > 1 && 's'} at {Math.floor((minimumPercent ?? 0))}% coverage
            </div>
          )}
        </Tooltip>
      )}
    </div>
  );
}