import React, { useContext, useEffect, useState } from 'react';
import { successModal, errorModal, confirmationModal } from 'utils/helpers';
import Select from 'components/inputs/select';
import { AdminContext } from 'data/context';
import Button from 'components/inputs/button';
import axios from 'utils/api';
import AttributeComponent from 'components/attribute';
import { AirHandlerAttributes } from 'data/types';

type AirHandlerTypeField = {
  code: keyof AirHandlerAttributes;
  label: string;
  type: 'number' | 'toggleSwitch' | 'select';
  placeholder?: string;
  options?: string[];
};

export default function AirHandlers() {
  const { airHandlerTypes, refreshData } = useContext(AdminContext);
  const [selectedTypeId, setSelectedTypeId] = useState<number | null>(null);
  const [values, setValues] = useState({});

  const setDefaultValues = () => {
    const type = airHandlerTypes.find((aht) => aht.id === selectedTypeId);
    if (type) {
      setValues(type);
    } else {
      errorModal('Air handler type not found.');
    }
  };

  useEffect(() => {
    if (selectedTypeId !== null) {
      setDefaultValues();
    } else {
      setValues({});
    }
  }, [selectedTypeId]);

  const fields: AirHandlerTypeField[] = [
    {
      code: 'installationComplexityRank',
      label: 'Installation Complexity Rank',
      type: 'number',
      placeholder: '1-5'
    },
    {
      code: 'aestheticsRank',
      label: 'Aesthetics Rank',
      type: 'number',
      placeholder: '1-5'
    },
    {
      code: 'requiresFlatCeiling',
      label: 'Requires Flat Ceiling',
      type: 'toggleSwitch'
    },
    {
      code: 'thirdPartyControllerEnabled',
      label: 'Third Party Controller Enabled',
      type: 'toggleSwitch'
    },
    {
      code: 'zoneControlSupported',
      label: 'Zone Control Supported',
      type: 'toggleSwitch'
    },
    {
      code: 'requiredInterstitialSpace',
      label: 'Required Interstitial Space',
      type: 'select',
      placeholder: 'none',
      options: ['above', 'above or below']
    },
    {
      code: 'requiresMinimumCeilingHeight',
      label: 'Requires Minimum Ceiling Height',
      type: 'toggleSwitch'
    }
  ];

  const handleFieldChange = (code: string, value: any) => {
    setValues((oldValues) => ({ ...oldValues, [code]: value }));
  };

  const handleSave = async () => {
    try {
      await axios.patch('air-handler-type', values);
      await refreshData();
      successModal(`${airHandlerTypes.find(t => t.id === selectedTypeId)?.label} air handler updated.`);
    } catch (error) {
      errorModal('Something went wrong.');
    }
  };

  const handleReset = () => {
    confirmationModal('Are you sure you want to reset this air handler type? All your changes will be lost!', () => {
      setDefaultValues();
    });
  };

  return (
    <div className='mx-auto flex max-w-[1600px] flex-col gap-5'>
      <div className='text-3xl font-bold text-gray-900'>
        Air Handler Settings
      </div>
      <div className='flex gap-5'>
        <div className='flex flex-col gap-2'>
          <div className='font-semibold text-gray-900'>Air Handler Type</div>
          <Select
            options={airHandlerTypes.map((aht) => ({ label: aht.label, value: aht.id.toString() }))}
            onChange={(value) => setSelectedTypeId(value !== '' ? Number(value) : null)}
            value={selectedTypeId?.toString() ?? undefined}
          />
        </div>
        <div className='flex grow flex-col gap-5'>
          <table className='min-w-full divide-y divide-gray-300'>
            <thead>
              <tr>
                <th
                  scope='col'
                  className='pb-3.5 pl-0 pr-3 text-left font-semibold text-gray-900'
                >
                  Field
                </th>
                <th
                  scope='col'
                  className='px-3 pb-3.5 text-left font-semibold text-gray-900'
                >
                  Value
                </th>
              </tr>
            </thead>
            <tbody className='divide-y divide-gray-200'>
              {fields.map((field) => (
                <tr key={field.code}>
                  <td className='whitespace-nowrap py-4 pl-0 pr-3 text-gray-900'>
                    {field.label}
                  </td>
                  <td className='whitespace-nowrap px-3 py-4'>
                    <div className="inline-block">
                      <AttributeComponent
                        id={0}
                        label={field.label}
                        code={field.code}
                        priority={0}
                        inheritable={false}
                        showProgress={false}
                        type={field.type}
                        value={values[field.code]}
                        options={field.options}
                        placeholder={field.placeholder}
                        onChange={(value) => handleFieldChange(field.code, value)}
                        disabled={selectedTypeId === null}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className='flex items-center gap-3'>
            <Button
              text='Save'
              color='green'
              onClick={handleSave}
              disabled={selectedTypeId === null}
            />
            <Button
              text='Reset'
              color='red'
              onClick={handleReset}
              disabled={selectedTypeId === null}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
