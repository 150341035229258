/* eslint-disable no-param-reassign */
import { useContext, useEffect } from 'react';
import { ConfigurationContext, DataContext, ProjectContext } from 'data/context';
import { errorModal, warningModal } from 'utils/helpers';
import { BTUs, SizingEstimatorData, FenestrationItem } from 'data/types';
import axios from 'axios';
import { adjustmentFactors, heatingCoolingCodes } from 'data/constants';

export default function BTUWatcher() {
  const { locationData, setTotalBTUs } = useContext(ProjectContext);
  const { structure, setStructure, configurations, setConfigurations } = useContext(ConfigurationContext);
  const { items, fenestration } = useContext(DataContext);

  const getSqFtForSizingEstimator = (
    squareFootage: number,
    height: number,
    vaultedOrSlopedHeight: number | undefined
  ) => {
    if (height && vaultedOrSlopedHeight) {
      return ((height + vaultedOrSlopedHeight) / 2 / 9) * squareFootage;
    }
    return squareFootage * (height / 9);
  };

  const calculateBTUs = async (
    squareFootage: number,
    zipCode: string,
    insulationLevel: 'Poor' | 'Good' | 'Great',
    desiredHeatingTemp: number,
    desiredCoolingTemp: number,
    isBasement: boolean,
    abortController: AbortController
  ) => {
    const insulationValues = {
      'Poor': 7,
      'Good': 9,
      'Great': 11
    };
    const inputData = {
      squareFeet: Math.max(squareFootage, 50),
      zipCode,
      insulationValue: insulationValues[insulationLevel] ?? 9,
      numFloors: 1,
      basement: isBasement,
      thermostatAC: desiredCoolingTemp ?? 75,
      thermostatHT: desiredHeatingTemp ?? 70
    };
    const data = (await axios.post(
      `${process.env.REACT_APP_PARTNERNET_API_URL}/v1/api/calculators/btu`,
      inputData,
      { signal: abortController.signal }
    )).data as SizingEstimatorData;
    return data;
  };

  const calculateAllBTUs = async (abortController: AbortController) => {
    const newSpaceBTUs: { [uniqueId: string]: BTUs } = {};
    let requestCancelled = false;
    for (const level of structure) {
      for (const section of level.sections) {
        for (const space of section.spaces.filter((s) => s.recalculateBTUs)) {
          const attributeValues = Object.fromEntries(space.attributes.map((a) => ([a.code, a.value])));
          newSpaceBTUs[space.uniqueId] = {
            cooling: 0,
            coolingAmount: space.BTUs.coolingAmount,
            heating: 0,
            heatingAmount: space.BTUs.heatingAmount
          };
          const allRequiredAttributesCompleted = space.attributes.every((att) => (
            !att.required ||
            (att.parentAttributes !== undefined && att.parentAttributes.every((pa) => pa.value?.toString() !== space.attributes.find((a) => a.id === pa.attributeId)?.value?.toString())) ||
            att.value
          ));
          if (attributeValues.square_footage > 0 && attributeValues.ceiling_height > 0 && allRequiredAttributesCompleted) {
            if (locationData) {
              try {
                // eslint-disable-next-line no-await-in-loop
                const results = (await calculateBTUs(
                  getSqFtForSizingEstimator(
                    attributeValues.square_footage,
                    attributeValues.ceiling_height,
                    (
                      // eslint-disable-next-line no-nested-ternary
                      attributeValues.ceiling_type === 'Vaulted' ?
                        attributeValues.vaulted_height :
                        attributeValues.ceiling_type === 'Sloped' ?
                          attributeValues.sloped_height :
                          undefined
                    )
                  ),
                  locationData.zipcode,
                  attributeValues.insulation_level,
                  attributeValues.desired_heating,
                  attributeValues.desired_cooling,
                  level.itemId === items.find((i) => i.code === 'basement')?.id,
                  abortController
                ));
                const coolingBTU = Math.max(results.CoolingBTU, 0);
                const heatingBTU = Math.max(results.HeatingBTU, 0);
                let modifiedCoolingBTU = coolingBTU;
                let modifiedHeatingBTU = heatingBTU;
                const heatCoolRatio = heatingBTU / coolingBTU;
                const insulationLevel = attributeValues.insulation_level?.toLowerCase() ?? 'good';
                const adjustmentFactor = heatCoolRatio > 0 ? adjustmentFactors.find((f) => f.heatCoolRatio < heatCoolRatio) : adjustmentFactors[3];
                if (adjustmentFactor) {
                  const fenestrationItems = space.fenestration?.map(
                    (itemId) => fenestration.find((f) => f.id === itemId)
                  ).filter(f => f) as FenestrationItem[] ?? [];
                  const fenestrationHeatingSum = fenestrationItems.reduce(
                    (sum, f) => sum + (f?.heatingBTU ?? 0),
                    0
                  );
                  const fenestrationCoolingSum = fenestrationItems.reduce(
                    (sum, f) => sum + (f?.coolingBTU ?? 0),
                    0
                  );
                  modifiedCoolingBTU = coolingBTU + (fenestrationCoolingSum * adjustmentFactor.insulation[insulationLevel].coolingFactor);
                  modifiedHeatingBTU = heatingBTU + (fenestrationHeatingSum * adjustmentFactor.insulation[insulationLevel].heatingFactor);
                } else {
                  warningModal('No adjustment factor found, fenestration items not calculated');
                }
                newSpaceBTUs[space.uniqueId] = {
                  cooling: Math.ceil(modifiedCoolingBTU),
                  coolingAmount: heatingCoolingCodes[attributeValues.cooling] ?? 'none',
                  heating: Math.ceil(modifiedHeatingBTU),
                  heatingAmount: heatingCoolingCodes[attributeValues.heating] ?? 'none'
                };
              } catch (error: any) {
                console.error(error);
                if (error.name !== 'CanceledError') {
                  errorModal('Failed to calculate BTUs');
                } else {
                  requestCancelled = true;
                }
              }
            } else {
              console.error('Location data not available');
              errorModal('Location data not available, unable to calculate BTUs.');
            }
          }
        };
      };
    };
    if (!requestCancelled) {
      setStructure((draft) => {
        draft.forEach((level) => {
          level.sections.forEach((section) => {
            section.spaces.forEach(async (space) => {
              if (newSpaceBTUs[space.uniqueId]) {
                space.BTUs = newSpaceBTUs[space.uniqueId];
                space.valid = newSpaceBTUs[space.uniqueId].cooling > 0 || newSpaceBTUs[space.uniqueId].heating > 0;
              }
              space.recalculateBTUs = false;
            });
          });
        });
      });
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    if (structure.some((level) => level.sections.some((section) => section.spaces.some((space) => space.recalculateBTUs)))) {
      calculateAllBTUs(abortController);
    }
    return () => abortController.abort();
  }, [
    JSON.stringify(structure.map((level) => level.sections.map((section) => section.spaces.map((space) => space.recalculateBTUs))))
  ]);

  useEffect(() => {
    let newHeatingBTU = 0;
    let newCoolingBTU = 0;
    structure.forEach((level) => {
      level.sections.forEach((section) => {
        section.spaces.forEach((space) => {
          newHeatingBTU += space.BTUs?.heating || 0;
          newCoolingBTU += space.BTUs?.cooling || 0;
        });
      });
    });
    setTotalBTUs({
      heating: newHeatingBTU,
      cooling: newCoolingBTU
    });
  }, [structure]);

  useEffect(() => {
    setConfigurations((draft) => {
      draft.forEach((config, configIndex) => {
        draft[configIndex].condensers.forEach((condenser, condenserIndex) => {
          condenser.zones.forEach((zone, zoneIndex) => {
            zone.zoneItems.forEach((zoneItem, itemIndex) => {
              const partitionCount = config.condensers.flatMap(c => c.zones.flatMap(z => z.zoneItems)).filter(zi => zi.structureItemId === zoneItem.structureItemId).length;
              // eslint-disable-next-line no-param-reassign
              draft[configIndex].condensers[condenserIndex].zones[
                zoneIndex
              ].zoneItems[itemIndex].BTUs = {
                ...zoneItem.originalBTUs,
                heating: zoneItem.originalBTUs.heating / partitionCount,
                cooling: zoneItem.originalBTUs.cooling / partitionCount
              };
              // eslint-disable-next-line no-param-reassign
              draft[configIndex].condensers[condenserIndex].zones[
                zoneIndex
              ].zoneItems[itemIndex].isPartition = partitionCount > 1;
            });
          });
        });
      });
    });
  }, [
    JSON.stringify(configurations.map(config => ({
      uniqueId: config.uniqueId, condensers: config.condensers.map(cond => ({
        uniqueId: cond.uniqueId, zones: cond.zones.map((zone) => ({
          uniqueId: zone.uniqueId, zoneItems: zone.zoneItems.map((item) => ({
            uniqueId: item.uniqueId
          }))
        }))
      }))
    })))
  ]);

  return null;
}