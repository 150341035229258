import React, { useContext, useState, useEffect, useRef } from 'react';
import {
  MagnifyingGlassIcon,
  Square2StackIcon,
  Square3Stack3DIcon
} from '@heroicons/react/24/outline';
import { DataContext, ProjectContext, ConfigurationContext, UIContext } from 'data/context';
import DraggableItem from 'components/dnd/draggable-item';
import InternalNote from 'components/internal-note';
import { createLevel, createSection, createSpace, getAllLabelsOfType } from 'utils/structure';
import { errorModal, createActiveItem, confirmationModal } from 'utils/helpers';
import { useDrop } from 'react-dnd';
import clsx from 'clsx';
import Text from 'components/inputs/text';

type DnDPanelProps = {
  include?: ('levels' | 'spaces')[];
  imageLink?: string;
};

export default function DnDPanel({
  include = ['levels', 'spaces'],
  imageLink
}: DnDPanelProps) {
  const {
    items,
    validItems,
    fenestration
  } = useContext(DataContext);
  const {
    structure,
    setStructure,
    configurations,
    selectedSystems
  } = useContext(ConfigurationContext);
  const {
    structureSubtypeSelected,
    projectSettings
  } = useContext(ProjectContext);
  const {
    setActiveItem,
    estimatingStructure
  } = useContext(UIContext);
  const [validItemIds, setValidItemIds] = useState<number[]>([]);
  const [imageExpanded, setImageExpanded] = useState(false);
  const [searching, setSearching] = useState(false);
  const [searchText, setSearchText] = useState('');
  const projectSettingsRef = useRef(projectSettings);
  const structureRef = useRef(structure);

  useEffect(() => {
    projectSettingsRef.current = projectSettings;
    structureRef.current = structure;
  }, [projectSettings, structure]);

  useEffect(() => {
    setValidItemIds(
      validItems.find(
        (vi) => vi.structureSubtypeId === structureSubtypeSelected
      )?.itemIds || []
    );
  }, [validItems, structureSubtypeSelected]);

  const addLevel = (itemId: number) => {
    const levelItem = items.find((i) => i.id === itemId);
    if (levelItem) {
      let levelNumber = 1;
      const existingLevelLabels = getAllLabelsOfType(structureRef.current, 'level');
      while (existingLevelLabels.includes(`${levelItem.label} ${levelNumber}`)) {
        levelNumber += 1;
      }
      const newLevel = createLevel(levelItem, {
        data: {
          label: levelItem.code === 'level' ? `${levelItem.label} ${levelNumber}` : levelItem.label,
        },
        attributesToInherit: projectSettingsRef.current
      });
      setStructure((draft) => {
        let newStructure;
        if (levelItem.code === 'attic') {
          newStructure = [newLevel, ...draft];
        } else if (levelItem.code === 'basement' || levelItem.code === 'crawlspace') {
          newStructure = [...draft, newLevel];
        } else {
          newStructure = [
            ...draft.filter((l) => items.find((i) => i.id === l.itemId)?.code === 'attic'),
            newLevel,
            ...draft.filter((l) => items.find((i) => i.id === l.itemId)?.code !== 'attic')
          ];
        }
        return newStructure;
      });
    } else {
      console.error('Space item not found');
      errorModal(`Space item not found`);
    }
  };

  const addSpace = (itemId: number) => {
    const levelItem = items.find((i) => i.code === 'level');
    const spaceItem = items.find((i) => i.id === itemId);
    if (levelItem && spaceItem) {
      let spaceNumber = 2;
      let sectionNumber = 1;
      let levelNumber = 1;
      const existingSpaceLabels = getAllLabelsOfType(structureRef.current, 'space');
      const existingSectionLabels = getAllLabelsOfType(structureRef.current, 'section');
      const existingLevelLabels = getAllLabelsOfType(structureRef.current, 'level');

      const isMultipleSpaces = existingSpaceLabels.filter(label => label.startsWith(spaceItem.label)).length > 0;

      while (existingSpaceLabels.includes(`${spaceItem.label} ${spaceNumber}`)) {
        spaceNumber += 1;
      }
      while (existingSectionLabels.includes(`Area ${sectionNumber}`)) {
        sectionNumber += 1;
      }
      while (existingLevelLabels.includes(`${levelItem.label} ${levelNumber}`)) {
        levelNumber += 1;
      }

      const newSpaceLabel = isMultipleSpaces ? `${spaceItem.label} ${spaceNumber}` : spaceItem.label;

      const newSpace = createSpace(spaceItem, {
        data: {
          label: newSpaceLabel
        },
        attributesToInherit: projectSettingsRef.current
      });
      const newSection = createSection({
        data: {
          label: `Area ${sectionNumber}`,
          spaces: [newSpace]
        }
      });
      const newLevel = createLevel(levelItem, {
        data: {
          label: `${levelItem.label} ${levelNumber}`,
          sections: [newSection]
        },
        attributesToInherit: projectSettingsRef.current
      });
      if (structure.filter(l => l.itemId === levelItem.id).length === 0) {
        setStructure((draft) => [
          ...draft.filter((l) => items.find((i) => i.id === l.itemId)?.code === 'attic'),
          newLevel,
          ...draft.filter((l) => items.find((i) => i.id === l.itemId)?.code !== 'attic')
        ]);
      } else {
        setStructure((draft) => {
          const emptiestLevel = draft.filter(l => l.itemId === levelItem.id).sort((a, b) => a.sections.flatMap(s => s.spaces).length - b.sections.flatMap(s => s.spaces).length)[0];
          if (emptiestLevel) {
            emptiestLevel.sections.push(newSection);
          }
        });
      }
      setActiveItem(createActiveItem({ type: 'space', item: newSpace, items, fenestration }));
    } else {
      console.error('Level item not found');
      errorModal(`Level item not found`);
    }
  };

  const deleteLevel = (itemId: string) => {
    setStructure((draft) => draft.filter((l) => l.uniqueId !== itemId));
  };

  const deleteSpace = (itemId: string) => {
    setStructure((draft) => {
      const level = draft.find((l) => l.sections.flatMap(s => s.spaces).find(s => s.uniqueId === itemId));
      if (level) {
        level.sections.forEach((s) => {
          // eslint-disable-next-line no-param-reassign
          s.spaces = s.spaces.filter((sp) => sp.uniqueId !== itemId);
        });
      }
    });
  };

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ['level', 'space'],
    drop: (
      item: {
        id: string;
        label: string;
        type: 'level' | 'space';
      },
      monitor
    ) => {
      if (item.type === 'level') {
        if (configurations.length > 0 || selectedSystems.length > 0) {
          confirmationModal('Are you sure you want to delete this level? This will reset all configurations and selected systems!', () => {
            deleteLevel(item.id);
          });
        } else {
          deleteLevel(item.id);
        }
      }
      if (item.type === 'space' && !monitor.didDrop()) {
        if (configurations.length > 0 || selectedSystems.length > 0) {
          confirmationModal('Are you sure you want to delete this space? This will reset all configurations and selected systems!', () => {
            deleteSpace(item.id);
          });
        } else {
          deleteSpace(item.id);
        }
      }
    },
    collect: (monitor) => ({
      canDrop: !!monitor.canDrop(),
      isOver: !!monitor.isOver({ shallow: true })
    })
  }), [configurations, selectedSystems]);

  const classes = clsx(
    'w-[300px] rounded-lg h-full min-h-[1000px] shrink-0 border-2',
    isOver ? 'bg-green-50' : 'bg-white',
    // eslint-disable-next-line no-nested-ternary
    canDrop ? 'border-dashed border-green-500' : 'border-transparent'
  );

  return (
    <div ref={drop} className={classes}>
      <div className='flex flex-col divide-y divide-gray-200 h-full'>
        <div className='p-5'>
          {imageLink ? (
            <div className='relative'>
              <img
                className='w-full h-auto rounded-lg opacity-0 pointer-events-none'
                src={imageLink}
                alt='Drag & Drop Tutorial'
              />
              <div
                className={`absolute left-0 top-0 ${imageExpanded ? '-right-[150%] -bottom-[150%] shadow-lg' : 'right-0 bottom-0'} z-10 rounded-lg cursor-help transition-all`}
                onMouseEnter={() => setImageExpanded(true)}
                onMouseLeave={() => setImageExpanded(false)}
              >
                <img
                  className='w-full h-full rounded-lg pointer-events-none'
                  src={imageLink}
                  alt='Drag & Drop Tutorial'
                />
              </div>
            </div>
          ) : (
            <InternalNote>
              Drag items from this panel to the structure on the right.
            </InternalNote>
          )}
        </div>
        {include.includes('levels') && (
          <div className='flex flex-col gap-4 p-5'>
            <div className='flex items-center gap-2'>
              <Square3Stack3DIcon className='h-6 w-6' />
              <span className='text-lg font-semibold'>Levels</span>
            </div>
            <div className='flex flex-col gap-3'>
              {items
                .filter(
                  (i) =>
                    i.group === 'Level' &&
                    (!validItemIds.length || validItemIds.includes(i.id))
                )
                .map((level) => (
                  <DraggableItem
                    key={level.id}
                    label={level.label}
                    type='new-level'
                    itemId={level.id}
                    onDoubleClick={() => {
                      if (configurations.length > 0 || selectedSystems.length > 0) {
                        confirmationModal('Are you sure you want to add a level? This will reset all configurations and selected systems!', () => {
                          addLevel(level.id);
                        });
                      } else {
                        addLevel(level.id);
                      }
                    }}
                    disabled={estimatingStructure}
                  />
                ))}
            </div>
          </div>
        )}
        {include.includes('spaces') && (
          <div className='flex flex-col gap-4 p-5 grow min-h-[500px]'>
            <div className='flex items-center gap-2'>
              <Square2StackIcon className='h-6 w-6 shrink-0' />
              {searching ? (
                <Text
                  value={searchText}
                  onInput={setSearchText}
                  onBlur={() => setSearching(false)}
                  autoFocus
                  autoSelect
                  size='sm'
                  placeholder='Search...'
                />
              ) : (
                <>
                  <span className='text-lg font-semibold grow'>Spaces{searchText.length > 0 && ' (Filtered)'}</span>
                  <MagnifyingGlassIcon
                    className='h-7 w-7 p-[3px] cursor-pointer rounded-md shrink-0 hover:bg-gray-100'
                    onClick={() => setSearching(true)}
                  />
                </>
              )}
            </div>
            <div className='grow relative'>
              <div className='flex flex-col gap-3 overflow-auto absolute inset-0'>
                {items
                  .filter(
                    (i) =>
                      i.group === 'Space' &&
                      (!validItemIds.length || validItemIds.includes(i.id)) &&
                      (!searchText.length || i.label.toLowerCase().includes(searchText.toLowerCase()))
                  )
                  .sort((a, b) => a.label.localeCompare(b.label))
                  .map((space) => (
                    <DraggableItem
                      key={space.id}
                      label={space.label}
                      type='new-space'
                      itemId={space.id}
                      onDoubleClick={() => {
                        if (configurations.length > 0 || selectedSystems.length > 0) {
                          confirmationModal('Are you sure you want to add a space? This will reset all configurations and selected systems!', () => {
                            addSpace(space.id);
                          });
                        } else {
                          addSpace(space.id);
                        }
                      }}
                      disabled={estimatingStructure}
                    />
                  ))}
                {searchText.length > 0 && items
                  .filter(
                    (i) =>
                      i.group === 'Space' &&
                      (!validItemIds.length || validItemIds.includes(i.id)) &&
                      (!searchText.length || i.label.toLowerCase().includes(searchText.toLowerCase()))
                  ).length === 0 && (
                    <div className='text-center text-gray-500'>
                      No results found
                    </div>
                  )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

DnDPanel.defaultProps = {
  include: ['levels', 'spaces']
};