import React from 'react';
import { AccessoryProduct, AccessoryWithQuantity, AirHandlerProduct, CondenserProduct } from 'data/types';
import { Popover, PopoverTrigger, PopoverContent } from 'components/popover';
import Tag from 'components/tag';
import { abbreviateNumber } from 'utils/helpers';
import CondenserDiagram from './condenser-diagram';
import AirHandlerDiagram from './air-handler-diagram';

type ProductLinkProps = {
  type: 'air handler';
  product: AirHandlerProduct;
  quantity?: number;
  fullName?: boolean
  hoverInfo?: boolean
} | {
  type: 'condenser';
  product: CondenserProduct;
  quantity?: number;
  fullName?: boolean
  hoverInfo?: boolean
} | {
  type: 'accessory';
  product: AccessoryProduct | AccessoryWithQuantity;
  quantity?: number;
  fullName?: boolean
  hoverInfo?: boolean
};

export default function ProductLink({ type, product, quantity, fullName = false, hoverInfo = false }: ProductLinkProps) {
  return (
    <Popover placement='bottom-start' hoverEnabled>
      <div>
        <PopoverTrigger asChild>
          <a
            className='font-semibold text-blue-500 hover:text-blue-600'
            href={`https://alpinehomeair.com${product.productUrl}`}
            rel='noreferrer'
            target='_blank'
            onClick={(e) => e.stopPropagation()}
          >
            {/* eslint-disable-next-line no-nested-ternary */}
            {type === 'accessory' ? `${product.name}${quantity !== undefined && ` x ${quantity}`}` : fullName ? product.name : product.model}
          </a>
        </PopoverTrigger>
      </div>
      {hoverInfo && (
        <PopoverContent className="flex flex-col gap-2 p-3 w-[400px] rounded-lg bg-white shadow-lg ring-1 ring-black/5 focus:outline-none z-30">
          {type === 'condenser' && (
            <>
              <div className='flex flex-wrap gap-2'>
                <span className='font-semibold'>{product.name}</span>
                <Tag text={`${abbreviateNumber(Math.round(product.coolingBTU / 1000) * 1000)} BTU`} />
                <Tag text={`${product.seer} SEER`} />
              </div>
              <div className='text-gray-600 text-sm'>{product.description}</div>
              <ul className='list-disc mb-2'>
                {product.requirements.map((req, reqIndex) => (
                  <li key={reqIndex} className='ml-5'>{req.value}</li>
                ))}
              </ul>
              <CondenserDiagram product={product} />
            </>
          )}
          {type === 'air handler' && (
            <>
              <div className='flex flex-wrap gap-2'>
                <span className='font-semibold'>{product.name}</span>
                <Tag text={`${product.sizeCode.toUpperCase()} BTU`} />
              </div>
              <div className='text-gray-600 text-sm'>{product.description}</div>
              <ul className='list-disc mb-2'>
                {product.requirements.map((req, reqIndex) => (
                  <li key={reqIndex} className='ml-5'>{req.value}</li>
                ))}
              </ul>
              <AirHandlerDiagram product={product} />
            </>
          )}
        </PopoverContent>
      )}
    </Popover>
  );
}

ProductLink.defaultProps = {
  quantity: 1,
  fullName: false,
  hoverInfo: false,
};