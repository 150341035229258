import React from 'react';
import { AuthContext } from 'data/context/auth-context';

interface CanAccessProps {
    permission?: string;
    role?: string;
    children: React.ReactNode;
}

const CanAccess: React.FC<CanAccessProps> = ({ permission, role, children }) => {
    const { hasPermission, hasRole } = React.useContext(AuthContext);

    if (!hasPermission(permission) && !hasRole(role)) {
        return null;
    }

    return <>{children}</>;
};

export default CanAccess;