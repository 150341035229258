/* eslint-disable no-nested-ternary */
import React, {
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  ReactNode
} from 'react';
import { Dialog } from '@headlessui/react';
import { XMarkIcon, ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { ModalStep } from 'data/types';
import ProgressIndicator from 'components/progress-indicator';
import ProgressBar from 'components/progress-bar';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import Tooltip from 'components/tooltip';
import Header from 'components/layout/header';
import { useSpring, animated } from '@react-spring/web';
import Footer from 'components/layout/footer';

type SteppedModalProps = {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
  steps: ModalStep[];
  currentStep: ModalStep['id'];
  setCurrentStep: Dispatch<SetStateAction<ModalStep['id']>>;
  finalButtonText?: string;
  size?: 'normal' | 'wide' | 'xl' | 'fullscreen';
  handleFinish?: () => void;
  children?: ReactNode;
};

export default function SteppedModal({
  openState,
  steps,
  currentStep,
  setCurrentStep,
  finalButtonText = 'Continue',
  size = 'normal',
  handleFinish = () => { },
  children
}: SteppedModalProps) {
  const [open, setOpen] = openState;
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [stepsVisited, setStepsVisited] = useState<ModalStep['id'][]>([]);
  const [stepProgress, setStepProgress] = useState(0);
  const [styles, api] = useSpring(() => ({
    from: {
      opacity: 1,
      translateY: 0
    }
  }));

  const forwardTransition = () => {
    api.start({
      from: { opacity: 0, translateY: 1000 },
      to: { opacity: 1, translateY: 0 },
    });
  };

  const backwardTransition = () => {
    api.start({
      from: { opacity: 0, translateY: -1000 },
      to: { opacity: 1, translateY: 0 },
    });
  };

  useEffect(() => {
    const stepIndex = steps.findIndex(step => step.id === currentStep);
    setCurrentStepIndex(stepIndex);
    const newStepsVisited = [...stepsVisited, ...steps.filter((step, index) => !stepsVisited.includes(step.id) && index <= stepIndex).map(step => step.id)];
    setStepsVisited(newStepsVisited);
    setStepProgress(Math.min(newStepsVisited.length, steps.filter((step) => step.requirementsMet).length) / steps.length * 100);
  }, [steps, currentStep]);

  const contentClasses = clsx(
    'h-full flex gap-6 mx-auto items-center p-6',
    size === 'normal' && 'max-w-[900px]',
    size === 'wide' && 'max-w-[1200px]',
    size === 'xl' && 'max-w-[1500px]',
    size === 'fullscreen' && 'max-w-full'
  );

  const previousButtonClasses = clsx(
    'w-full py-3 font-semibold rounded-lg text-white bg-blue-500 hover:bg-blue-600 flex items-center justify-center gap-1',
    currentStepIndex > 0
      ? 'cursor-pointer'
      : 'cursor-not-allowed opacity-50'
  );

  const nextButtonClasses = clsx(
    'w-full py-3 font-semibold rounded-lg text-white flex items-center justify-center gap-1',
    steps[currentStepIndex]?.requirementsMet
      ? 'cursor-pointer'
      : 'cursor-not-allowed opacity-50',
    currentStepIndex === steps.length - 1 ? 'bg-green-500 hover:bg-green-600' : 'bg-blue-500 hover:bg-blue-600'
  );

  const handleNextClick = () => {
    if (currentStepIndex === steps.length - 1) {
      handleFinish();
      setOpen(false);
    } else {
      setCurrentStep(steps[currentStepIndex + 1].id);
    }
    forwardTransition();
  };

  const handlePreviousClick = () => {
    if (currentStepIndex > 0) {
      setCurrentStep(steps[currentStepIndex - 1].id);
    }
    backwardTransition();
  };

  const handleStepClick = (stepId: string) => {
    if (stepId !== currentStep) {
      const previousStepIndex = steps.findIndex(step => step.id === currentStep);
      const newStepIndex = steps.findIndex(step => step.id === stepId);
      setCurrentStep(stepId);
      if (newStepIndex > previousStepIndex) {
        forwardTransition();
      } else {
        backwardTransition();
      }
    }
  };

  return (
    <Dialog as='div' className='relative z-30' open={open} onClose={() => setOpen(false)}>
      <div className='fixed inset-0 z-30'>
        <div className='flex min-h-full items-end justify-center p-4 sm:items-center sm:p-0'>
          <Dialog.Panel className='relative transform select-none bg-white transition-all flex flex-col w-[100vw] h-[100vh]'>
            <Header showExtraButtons={false} />
            <ProgressBar progress={stepProgress} />
            <div className='w-full overflow-ellipsis px-12 pt-6 text-center text-lg font-bold text-gray-900 flex items-center justify-center gap-3'>
              {steps[currentStepIndex]?.title}
            </div>
            <div className='w-full grow'>
              <div className={contentClasses}>
                <div className='md:w-[300px] shrink-0 overflow-auto'>
                  {steps.map((step, index) => {
                    // const enabled = index === 0 || stepsVisited.includes(step.id) || (stepsVisited.includes(steps[index - 1]?.id) && steps[index - 1]?.requirementsMet);
                    const enabled = index === 0 || steps[index - 1]?.requirementsMet;
                    // const checked = stepsVisited.includes(step.id) && step.requirementsMet;
                    const checked = step.requirementsMet;
                    // const nextStepChecked = stepsVisited.includes(steps[index + 1]?.id) && steps[index + 1]?.requirementsMet;
                    const nextStepChecked = steps[index + 1]?.requirementsMet;
                    return (
                      <button
                        key={step.id}
                        className={`w-full py-2 px-3 flex items-center justify-start gap-3 rounded-lg hover:bg-gray-100 relative ${enabled ? 'cursor-pointer' : 'cursor-not-allowed'} outline-none`}
                        onClick={() => handleStepClick(step.id)}
                        disabled={!enabled}
                      >
                        <div className='shrink-0 inline-block z-20'>
                          <ProgressIndicator checked={checked} />
                        </div>
                        <span className={`hidden md:block grow text-left ${step.id === currentStep ? 'font-semibold' : 'font-normal'}`}>{step.title}</span>
                        {index > 0 && (
                          <div className={`absolute w-1 ${checked ? 'bg-green-600' : 'bg-gray-400'} left-[22px] top-0 bottom-1/2`} />
                        )}
                        {index < steps.length - 1 && (
                          <div className={`absolute w-1 ${nextStepChecked ? 'bg-green-600' : 'bg-gray-400'} left-[22px] bottom-0 top-1/2`} />
                        )}
                      </button>
                    );
                  })}
                </div>
                <animated.div
                  className='relative grow max-h-full p-1'
                  style={{ ...styles }}
                >
                  {children}
                  {steps[currentStepIndex]?.reason !== undefined && (
                    <div className='flex justify-center'>
                      <div className='inline-flex items-center gap-1 relative cursor-help text-gray-500 mt-5'>
                        <span>Why are we asking this?</span>
                        <InformationCircleIcon className='h-5 w-5' />
                        <Tooltip text={steps[currentStepIndex]?.reason} />
                      </div>
                    </div>
                  )}
                </animated.div>
              </div>
            </div>
            <div className="flex items-center justify-center gap-5 pb-10 z-10">
              <div className='w-[200px]'>
                <button
                  type='button'
                  className={previousButtonClasses}
                  onClick={handlePreviousClick}
                  disabled={currentStepIndex <= 0}
                >
                  <ArrowLeftIcon className='h-6 w-6' />
                  <span>Back</span>
                </button>
              </div>
              <div className='w-[200px]'>
                <button
                  type='button'
                  className={nextButtonClasses}
                  onClick={handleNextClick}
                  disabled={!steps[currentStepIndex]?.requirementsMet}
                >
                  <span>{currentStepIndex === steps.length - 1 ? finalButtonText : 'Next'}</span>
                  {currentStepIndex < steps.length - 1 && <ArrowRightIcon className='h-6 w-6' />}
                </button>
              </div>
            </div>
            <Footer />
            <button
              type='button'
              className='absolute right-0 top-14 cursor-pointer p-3 text-gray-600 hover:text-gray-700'
              onClick={() => setOpen(false)}
            >
              <XMarkIcon className='h-6 w-6' />
            </button>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
}

SteppedModal.defaultProps = {
  finalButtonText: 'Continue',
  size: 'normal'
};
