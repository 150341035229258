import React, { useState, useEffect, useContext } from 'react';
import { Tab } from '@headlessui/react';
import AutoSaveWatcher from 'components/auto-save-watcher';
import ResetDataWatcher from 'components/reset-data-watcher';
import { Toaster, useToasterStore, toast } from 'react-hot-toast';
import { warningModal, errorModal, notification } from 'utils/helpers';
import LoadingOverlay from 'components/loading-overlay';
import { ProjectContext, UIContext, ConfigurationContext, DataContext } from 'data/context';
import ProjectDescription from 'views/description';
import Header from 'components/layout/header';
import DetailsPanel from 'components/layout/details-panel';
import SettingsPanel from 'components/layout/settings-panel';
import Footer from 'components/layout/footer';
import StructureLayout from 'views/structure';
import Configurations from 'views/configurations';
import AirHandlers from 'views/air-handlers';
import Accessories from 'views/accessories';
import Quote from 'views/quote';
import Equipment from 'views/equipment';
import PropertySummary from 'components/property-summary';
import ChatBot from 'components/chat-bot';
import PageProgressUpdater from 'components/page-progress-updater';
import ValidSystemsUpdater from 'components/valid-systems-updater';
import RankingSandbox from 'views/ranking-sandbox';
import axios, { getLocationData, getWeatherData } from 'utils/api';
import {
  LiveChatData,
  Project,
  ProjectWithProjectId,
  ProjectDetails,
  ActiveTab
} from 'data/types';
import BTUWatcher from 'components/btu-watcher';
import { AxiosError } from 'axios';
import TransitionModalWatcher from 'components/transition-modal-watcher';
import DebugSnapshotWatcher from 'components/debug-snapshot-watcher';
import { defaultUIState } from 'data/context/ui-context';
import NotesPopover from 'components/notes-popover';

export default function IndexPage() {
  const {
    items,
    criticalDataError,
    nonCriticalDataError,
    airHandlerProducts,
    condenserProducts,
    BTULimits
  } = useContext(DataContext);
  const {
    attomData,
    setProjectDetails,
    setWarmUpNotes,
    setLocationData,
    setWeatherData,
    setLiveChatData,
    setStructureTypeSelected,
    setStructureSubtypeSelected,
    setPartialStructure,
    setAttomData,
    setProjectSettings,
    setCustomerValues,
    setShowDuctlessOptions
  } = useContext(ProjectContext);
  const {
    setStructure,
    configurations,
    configurationOptions,
    setConfigurations,
    setConfigurationOptions,
    setSelectedSystems,
    setExistingSystems,
    setPossibleCondenserLocations
  } = useContext(ConfigurationContext);
  const {
    activeTab,
    showPropertySummary,
    setShowPropertySummary,
    showRankingSandbox,
    setShowDetailsPanel,
    debugMode,
    setDebugMode,
    setSliderValues,
    setZoneSliderValues
  } = useContext(UIContext);
  const { toasts } = useToasterStore();
  const [loading, setLoading] = useState(true);
  const [projectDataLoaded, setProjectDataLoaded] = useState(true);
  const [projectId, setProjectId] = useState('');
  const [debugData, setDebugData] = useState<ProjectWithProjectId | null>(null);

  const fetchDebugData = (debugId: string) => {
    const debugUrl = process.env.REACT_APP_DEBUG_API_URL ?? '';
    axios.get(`${debugUrl}debug-snapshot?debugId=${debugId}`).then((res) => {
      setDebugData(res.data);
      setProjectId(res.data.projectId?.toString() ?? '');
    }).catch((error) => {
      console.error(error);
    });
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const queryParams = Object.fromEntries(Array.from(urlSearchParams.entries()).map(([k, v]) => [k.toLowerCase(), v]));
    if (queryParams.projectid) {
      setProjectId(queryParams.projectid);
    } else if (queryParams.debugid) {
      setDebugMode(true);
      fetchDebugData(queryParams.debugid);
    }
  }, []);

  const setDefaultSettings = () => {
    setProjectSettings(items.find((i) => i.code === 'project_settings')?.attributes ?? []);
    setCustomerValues(items.find((i) => i.code === 'customer_values')?.attributes ?? []);
  };

  const loadProjectData = async () => {
    setLoading(true);
    if (projectId || debugMode) {
      if (!Number.isNaN(Number(projectId))) {
        const detailsPromise = await axios.get(`project-details?projectId=${projectId}`)
          .then(async (res) => {
            const newProjectDetails = res.data as ProjectDetails;
            setProjectDetails({
              ...newProjectDetails,
              projectId: Number(projectId)
            });
            if (newProjectDetails.system_selector_notes) {
              setWarmUpNotes(newProjectDetails.system_selector_notes);
            }
            if (newProjectDetails.postal) {
              const newWeatherData = await getWeatherData(newProjectDetails.postal);
              setWeatherData(newWeatherData);
            } else {
              errorModal('No zip code found in project details!');
            }
            return newProjectDetails;
          })
          .catch((error: AxiosError) => {
            if (error?.response?.status === 404) {
              warningModal('Project not found');
            } else {
              warningModal('Failed to load project data');
            }
            console.error(error);
            setProjectDataLoaded(false);
            return null;
          });
        const liveChatPromise = await axios.get(`live-chat?projectId=${projectId}`)
          .then((res) => {
            const newLiveChatData = res.data as LiveChatData;
            setLiveChatData(newLiveChatData ?? null);
            return newLiveChatData;
          })
          .catch((error: AxiosError) => {
            if (error?.response?.status !== 404) {
              warningModal('Failed to load live chat data');
            }
            console.error(error);
            return null;
          });
        const projectPromise = await (debugMode ? Promise.resolve({ data: debugData }) : axios.get(`project?projectId=${projectId}`))
          .then((res) => {
            const newProjectData = res.data as Project;
            setStructureTypeSelected(newProjectData.structureTypeId);
            setStructureSubtypeSelected(newProjectData.structureSubtypeId);
            setPartialStructure(!!newProjectData.partialStructure);
            setAttomData(newProjectData.propertyData);
            setProjectSettings(
              (items.find((i) => i.code === 'project_settings')?.attributes ?? [])
                .map((a) => ({ ...a, value: newProjectData?.projectSettings?.find((s) => s.id === a.id)?.value }))
            );
            setCustomerValues(
              (items.find((i) => i.code === 'customer_values')?.attributes ?? [])
                .map((a) => ({ ...a, value: newProjectData?.customerValues?.find((s) => s.id === a.id)?.value }))
            );
            setStructure(newProjectData.structure ?? []);
            setConfigurations(newProjectData.configurations ?? []);
            setConfigurationOptions(newProjectData.configurationOptions ?? []);
            setSelectedSystems(newProjectData.selectedSystems ?? []);
            const newSliderValues = defaultUIState.sliderValues;
            Object.keys(defaultUIState.sliderValues).forEach((key) => {
              if (newProjectData.sliderValues?.[key]) {
                newSliderValues[key] = newProjectData.sliderValues[key];
              }
            });
            setSliderValues(newSliderValues);
            const newZoneSliderValues = defaultUIState.zoneSliderValues;
            Object.keys(defaultUIState.zoneSliderValues).forEach((key) => {
              if (newProjectData.zoneSliderValues?.[key]) {
                newZoneSliderValues[key] = newProjectData.zoneSliderValues[key];
              }
            });
            setZoneSliderValues(newZoneSliderValues);
            setExistingSystems(newProjectData.existingSystems ?? []);
            setShowDuctlessOptions(newProjectData.showDuctlessOptions ?? null);
            setPossibleCondenserLocations(newProjectData.possibleCondenserLocations ?? []);
            return newProjectData;
          })
          .catch((error: AxiosError) => {
            if (error?.response?.status !== 404) {
              warningModal('Failed to load saved data, autosave disabled');
              setProjectDataLoaded(false);
            }
            console.error(error);
            setDefaultSettings();
            return null;
          });
        Promise.all([detailsPromise, projectPromise, liveChatPromise]).then(([newProjectDetails, newProjectData]) => {
          if (newProjectData?.locationData?.zipcode ?? newProjectDetails?.postal) {
            getLocationData(newProjectData?.locationData?.zipcode ?? newProjectDetails?.postal ?? '')
              .then((res2) => setLocationData(res2.data ?? null))
              .catch((error: AxiosError) => {
                warningModal('Failed to load location data');
                console.error('Error fetching location data', error);
              })
              .finally(() => {
                setTimeout(() => setLoading(false), 1000);
              });
            notification('Project data loaded', 'success');
          } else {
            setTimeout(() => setLoading(false), 1000);
          }
        });
      } else {
        warningModal('Invalid project ID');
        setDefaultSettings();
        setProjectDataLoaded(false);
        setLoading(false);
      }
    } else {
      setDefaultSettings();
      setProjectDataLoaded(false);
      setLoading(false);
    }
  };

  const warnOnLeave = (e: BeforeUnloadEvent) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (!projectDataLoaded) {
      window.addEventListener('beforeunload', warnOnLeave);
    }
    return () => {
      window.removeEventListener('beforeunload', warnOnLeave);
    };
  }, [projectDataLoaded]);

  useEffect(() => {
    if (items.length > 0) {
      console.log('Loading data...');
      loadProjectData();
    }
  }, [projectId, debugMode, items]);

  useEffect(() => {
    setShowPropertySummary(false);
    if (activeTab === 'description') {
      setShowDetailsPanel(false);
    }
  }, [activeTab]);

  useEffect(() => {
    const toastLimit = 3;
    const uniqueMessages = new Set();
    const toastsToShow = toasts
      .filter((t) => t.visible)
      .filter((t) => {
        const isUnique = !uniqueMessages.has(t.message);
        uniqueMessages.add(t.message);
        return isUnique;
      })
      .slice(-toastLimit);

    toasts
      .filter((t) => !toastsToShow.find((showToast) => showToast.id === t.id))
      .forEach((t) => toast.dismiss(t.id));
  }, [JSON.stringify(toasts)]);

  useEffect(() => {
    if (criticalDataError) {
      errorModal('Critical data could not be fetched, please try again later.', { showConfirmButton: false, allowEscapeKey: false, allowOutsideClick: false });
    }
    if (nonCriticalDataError) {
      warningModal('Some non-critical data may not be available, functionality may be affected.');
    }
  }, [criticalDataError, nonCriticalDataError]);

  const tabNumbers: { [tab in ActiveTab]?: number } = {
    description: 0,
    layout: 1,
    configuration: 2,
    'air handlers': 3,
    equipment: 4,
    accessories: 5,
    quote: 6
  };

  return (
    <main className='w-full h-[100vh] bg-gray-100 text-gray-900 flex flex-col'>
      <Tab.Group selectedIndex={tabNumbers[activeTab]}>
        {debugMode && (
          <div className="w-full flex justify-center bg-red-300 py-2 font-semibold">
            Debug mode, auto-save disabled!{!projectDataLoaded && ' (Project data not loaded)'}
          </div>
        )}
        {!projectDataLoaded && !debugMode && (
          <div className="w-full flex justify-center bg-orange-300 py-2 font-semibold">
            {projectId ?
              'Project data not loaded, auto-save disabled!' :
              'No project ID, auto-save disabled!'}
          </div>
        )}
        <div className='flex grow w-full flex-col'>
          <Header />
          <DetailsPanel />
          <div className='flex grow'>
            {activeTab !== 'quote' && (
              <SettingsPanel loading={loading} />
            )}
            <div className='relative grow'>
              <div className='absolute inset-0 overflow-y-auto'>
                {showPropertySummary && attomData && !criticalDataError && (
                  <div className="p-5">
                    <PropertySummary />
                  </div>
                )}
                {!showPropertySummary && !criticalDataError && (
                  <Tab.Panels className='focus:outline-none h-full'>
                    <Tab.Panel className='focus:outline-none p-5'>
                      <ProjectDescription loading={loading} />
                    </Tab.Panel>
                    <Tab.Panel className='focus:outline-none pt-5 px-5 h-full'>
                      <StructureLayout />
                    </Tab.Panel>
                    <Tab.Panel className='focus:outline-none p-5'>
                      <Configurations />
                    </Tab.Panel>
                    <Tab.Panel className='focus:outline-none p-5'>
                      <AirHandlers />
                    </Tab.Panel>
                    <Tab.Panel className='focus:outline-none p-5'>
                      {showRankingSandbox ? <RankingSandbox /> : <Equipment />}
                    </Tab.Panel>
                    <Tab.Panel className='focus:outline-none p-5'>
                      <Accessories />
                    </Tab.Panel>
                    <Tab.Panel className='focus:outline-none p-5'>
                      <Quote />
                    </Tab.Panel>
                  </Tab.Panels>
                )}
                {criticalDataError && (
                  <div className="h-full w-full flex items-center justify-center">
                    <span>Critical data error. Please reload the tool.</span>
                  </div>
                )}
              </div>
              <LoadingOverlay loading={
                loading ||
                (activeTab === 'configuration' && !BTULimits.length) ||
                (activeTab === 'air handlers' && (!configurations.every((c) => c.condensers.every((co) => co.rankedSystems.length > 0)) || !airHandlerProducts)) ||
                (activeTab === 'condensers' && (!configurations.every((c) => c.condensers.every((co) => co.rankedSystems.length > 0)) || !condenserProducts))
              } />
            </div>
          </div>
          {!showPropertySummary && <Footer />}
        </div>
      </Tab.Group>
      <div className='fixed bottom-20 right-5 flex flex-col gap-5'>
        {activeTab !== 'description' && (
          <NotesPopover />
        )}
        <ChatBot />
      </div>
      <PageProgressUpdater />
      {configurationOptions.length > 0 && <ValidSystemsUpdater loading={loading} />}
      <ResetDataWatcher loading={loading} />
      <BTUWatcher />
      <TransitionModalWatcher />
      <Toaster position='top-right' containerClassName={(debugMode || !projectDataLoaded) ? 'mt-[90px]' : 'mt-[50px]'} toastOptions={{ duration: 2000 }} />
      {!debugMode && (
        <>
          <AutoSaveWatcher loading={loading || !projectDataLoaded} />
          <DebugSnapshotWatcher />
        </>
      )}
    </main>
  );
}
