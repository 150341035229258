import React, { ReactNode } from 'react';
import SelectInput from 'components/inputs/select';
import NumberInput from 'components/inputs/number';
import TextInput from 'components/inputs/text';
import CheckboxInput from 'components/inputs/checkbox';
import TextareaInput from 'components/inputs/textarea';
import ToggleSwitch from 'components/inputs/toggle-switch';
import ProgressIndicator from 'components/progress-indicator';
import { Attribute } from 'data/types';

interface AttributeComponentProps extends Attribute {
  showProgress?: boolean;
  showLabel?: boolean;
  completed?: boolean;
  onChange?: (value: any) => void;
  debounce?: number;
  disabled?: boolean;
  autoFocus?: boolean;
}

export default function AttributeComponent({
  showProgress = true,
  showLabel = true,
  completed,
  id,
  type,
  label,
  code,
  priority,
  inheritable,
  parentAttributes,
  required = false,
  group,
  advanced,
  modified,
  ...props
}: AttributeComponentProps) {
  let inputElement: ReactNode | null = null;

  if (type === 'number') {
    inputElement = <NumberInput
      {...props}
      invalid={required && !showProgress && !props.value}
    />;
  }

  if (type === 'select') {
    inputElement = <SelectInput
      {...props}
      enableChips={(props.options?.length ?? 0) <= 3 && props.options?.every(o => o.length <= 20)}
      invalid={required && !showProgress && !props.value}
    />;
  }

  if (type === 'text') {
    inputElement = <TextInput
      {...props}
      invalid={required && !showProgress && !props.value}
    />;
  }

  if (type === 'textarea') {
    inputElement = <TextareaInput {...props} />;
  }

  if (type === 'toggleSwitch') {
    inputElement = <ToggleSwitch {...props} />;
  }

  if (type === 'checkbox') {
    return (
      <label className={`flex items-center gap-2 ${props.disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}>
        <CheckboxInput {...props} />
        {showLabel && (
          <span className='font-semibold text-gray-800'>
            {label}
          </span>
        )}
      </label>
    );
  }

  return (
    <div className='flex flex-col gap-2'>
      {showProgress && (
        <div className='flex items-center gap-2'>
          {required && (
            <ProgressIndicator
              required={required}
              checked={completed}
            />
          )}
          <span className='font-semibold text-gray-800'>
            {label}
          </span>
        </div>
      )}
      {inputElement ?? 'Invalid attribute type!'}
    </div>
  );
}