import React, { useEffect, useRef, useState, useContext } from 'react';
import { DataContext } from 'data/context';
import { AccessoryProduct } from 'data/types';
import { formatUSD } from 'utils/helpers';
import Tag from 'components/tag';
import ProductPhoto from 'components/product-photo';
import Quantity from 'components/inputs/quantity';

type QuantityOptionProps = {
  accessory: AccessoryProduct;
  quantity: number;
  compatibleProducts?: string[];
  onChange?: (value: number) => void;
};

export default function QuantityOption({
  accessory,
  quantity,
  compatibleProducts,
  onChange
}: QuantityOptionProps) {
  const { restockData } = useContext(DataContext);
  const [value, setValue] = useState(quantity);
  const valueRef = useRef(value);

  valueRef.current = value;

  const handleChange = (newValue: number) => {
    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  useEffect(() => {
    setValue(quantity);
  }, [quantity]);

  return (
    <div className='flex items-center p-5'>
      <div className='flex grow items-center gap-4'>
        <ProductPhoto product={accessory} />
        <div className='flex flex-col'>
          <div className='flex items-center gap-2'>
            <a
              className='font-semibold text-blue-500 hover:text-blue-600'
              href={`https://alpinehomeair.com${accessory.productUrl}`}
              rel='noreferrer'
              target='_blank'
            >
              {accessory.name}
            </a>
            {accessory.availableStock > 0 &&
              accessory.availableStock >= value && (
                <span className='text-sm font-semibold text-green-500'>In Stock</span>
              )}
            {accessory.availableStock < value && (restockData?.[accessory.productId]?.fulfillableStockAfterRestocking ?? 0) >= value && (
              <Tag
                color='yellow'
                text={`ETA ${new Date(restockData?.[accessory.productId].restockingDate ?? '').toLocaleDateString('en-us', {
                  month: 'numeric',
                  day: 'numeric'
                })}`}
              />
            )}
            {accessory.availableStock < value && (restockData?.[accessory.productId]?.fulfillableStockAfterRestocking ?? 0) < value && (
              <Tag color='red' text='Out of Stock' />
            )}
          </div>
          <span className='text-sm text-gray-600'>{accessory.description}</span>
          {compatibleProducts && (
            <span className='mt-1 text-xs text-gray-500'>
              Compatible with: {compatibleProducts.join(', ')}
            </span>
          )}
        </div>
      </div>
      <div className='border-x border-gray-200 px-10'>
        <Quantity value={value} onChange={(num) => handleChange(num ?? 0)} />
      </div>
      <div className='w-[120px] shrink-0 grow-0 text-right text-lg font-bold'>
        {formatUSD(accessory.price * Math.max(value, 1), true)}
      </div>
    </div>
  );
}

QuantityOption.defaultProps = {
  compatibleProducts: undefined,
  onChange: undefined,
  restockDate: undefined
};
