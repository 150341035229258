import React, { ReactNode, JSXElementConstructor, SVGProps } from 'react';
import clsx from 'clsx';

type TagProps = {
  text: string;
  textColor?: 'black' | 'white';
  color?: 'green' | 'yellow' | 'red' | 'purple' | 'blue' | 'orange' | 'light green' | 'default';
  customColor?: string;
  icon?: JSXElementConstructor<SVGProps<SVGSVGElement>>
  noWrap?: boolean;
  children?: ReactNode;
  bold?: boolean;
  uppercase?: boolean;
} | {
  text?: string;
  textColor?: 'black' | 'white';
  color?: 'green' | 'yellow' | 'red' | 'purple' | 'blue' | 'orange' | 'light green' | 'default';
  customColor?: string;
  icon?: JSXElementConstructor<SVGProps<SVGSVGElement>>
  noWrap?: boolean;
  children: ReactNode;
  bold?: boolean;
  uppercase?: boolean;
};

export default function Tag({ text, textColor = 'black', color = 'default', customColor, icon, noWrap = false, children, bold = false, uppercase = false }: TagProps) {
  const Icon = icon;
  const classes = clsx(
    'inline-flex items-center gap-1 text-xs rounded px-2 py-1 capitalize font-semibold whitespace-nowrap',
    color === 'green' && !customColor && 'bg-green-500 text-white',
    color === 'yellow' && !customColor && 'bg-amber-500 text-white',
    color === 'red' && !customColor && 'bg-red-500 text-white',
    color === 'purple' && !customColor && 'bg-purple-500 text-white',
    color === 'blue' && !customColor && 'bg-blue-500 text-white',
    color === 'orange' && !customColor && 'bg-orange-500 text-white',
    color === 'default' && !customColor && 'bg-gray-200 text-gray-900',
    color === 'light green' && !customColor && 'bg-green-100 text-green-900',
    noWrap && 'whitespace-nowrap',
    textColor === 'black' && 'text-black',
    textColor === 'white' && 'text-white',
    bold === true && 'font-extrabold',
    uppercase === true && 'uppercase',
    uppercase === false && 'lowercase'
  );

  return (
    <div className={classes} style={customColor ? { background: customColor } : undefined}>
      {Icon && <Icon className='w-4 h-4' />}
      {children || <span>{text}</span>}
    </div>
  );
}

Tag.defaultProps = {
  color: 'default',
  textColor: 'black',
  customColor: undefined,
  icon: undefined,
  noWrap: false,
  children: undefined,
  text: undefined
};
