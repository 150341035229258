import React, { useEffect, useContext, useState, MouseEvent } from 'react';
import { Configuration } from 'data/types';
import XButton from 'components/inputs/x-button';
import { ConfigurationContext } from 'data/context';
import {
  useFloating,
  useInteractions,
  useHover,
  offset,
  autoUpdate,
  shift,
  flip
} from '@floating-ui/react';
import { confirmationModal, warningModal } from 'utils/helpers';
import clsx from 'clsx';
import ProgressIndicator from 'components/progress-indicator';
import CondenserMap from './condenser-map';

type ConfigOptionProps = {
  config: Configuration;
  isAirHandlerPage?: boolean;
};

export default function ConfigOptionComponent({
  config,
  isAirHandlerPage
}: ConfigOptionProps) {
  const {
    configurations,
    setConfigurations,
    activeConfig,
    setActiveConfig,
    selectedSystems,
    setSelectedSystems,
    setActiveSelectedSystem
  } = useContext(ConfigurationContext);
  const [showPreview, setShowPreview] = useState(false);
  const [active, setActive] = useState(false);
  const [attentionNeeded, setAttentionNeeded] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    setActive(configurations.findIndex(c => c.uniqueId === config.uniqueId) === activeConfig);
  }, [activeConfig, configurations]);

  useEffect(() => {
    setAttentionNeeded(!config.condensers.every(c => c.zones.every(z => z.selectedProduct)));
  }, [config]);

  const { refs, floatingStyles, context } = useFloating({
    open: showPreview,
    onOpenChange: setShowPreview,
    placement: 'bottom-start',
    strategy: 'fixed',
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(10),
      shift({ padding: 5 }),
      flip({ fallbackAxisSideDirection: 'end' })
    ]
  });

  const hover = useHover(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover
  ]);

  const handleClick = () => {
    setActiveConfig(configurations.findIndex(c => c.uniqueId === config.uniqueId));
  };

  const handleDeleteClick = (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    event.stopPropagation();
    const deleteSelf = () => {
      setConfigurations((draft) => draft.filter((conf) => conf.uniqueId !== config.uniqueId));
      setSelectedSystems((draft) => draft.filter(system => system.configuration.uniqueId !== config.uniqueId));
      setActiveConfig(0);
      setActiveSelectedSystem(0);
    };
    const confirmText = selectedSystems.filter(system => system.configuration.uniqueId === config.uniqueId).length > 0 || config.condensers.some(cond => cond.selectedProduct || cond.zones.some(zone => zone.selectedProduct)) ?
      'Are you sure you want to delete this configuration? This will reset all selected products and systems for this configuration!' :
      `Are you sure you want to delete ${config.name}?`;
    if (
      config.modified ||
      config.condensers.some(cond => cond.modified || cond.zones.some(zone => zone.modified)) ||
      selectedSystems.filter(system => system.configuration.uniqueId === config.uniqueId).length > 0 ||
      config.condensers.some(cond => cond.selectedProduct || cond.zones.some(zone => zone.selectedProduct))
    ) {
      confirmationModal(confirmText, deleteSelf);
    } else {
      deleteSelf();
    }
  };

  const classes = clsx(
    'inline-flex items-center rounded-full pl-2 shadow ring-1 ring-black ring-opacity-5 whitespace-nowrap relative',
    isAirHandlerPage && active && 'bg-blue-100',
    isAirHandlerPage && !active && 'bg-white hover:bg-gray-50',
    !isAirHandlerPage && 'bg-white'
  );

  return (
    <div ref={refs.setReference} {...getReferenceProps()}>
      <div
        className={classes}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        {(!isAirHandlerPage || isHovering) && (
          <XButton
            onClick={(e) => handleDeleteClick(e)}
            tooltip="Delete Configuration"
            onDisabledClick={() => warningModal('You must have at least one configuration.')}
            disabled={isAirHandlerPage && configurations.length === 1}
          />
        )}
        {isAirHandlerPage && !isHovering && (
          <ProgressIndicator size='sm' checked={!attentionNeeded} required />
        )}
        <button
          className={`inline-flex items-center gap-2 py-2 pl-2 pr-3 ${isAirHandlerPage ? 'cursor-pointer' : 'cursor-help'}`}
          onClick={isAirHandlerPage ? handleClick : undefined}
        >
          <span className='text-sm font-semibold'>
            Configuration {configurations.findIndex(c => c.uniqueId === config.uniqueId) + 1}
          </span>
        </button>
      </div>
      {showPreview && (
        <div
          ref={refs.setFloating}
          className='z-20 max-w-lg'
          style={floatingStyles}
          {...getFloatingProps()}
        >
          <div className="flex flex-col gap-2 bg-white rounded-lg shadow-xl p-5">
            {config.condensers.map((condenser) => (
              <CondenserMap
                key={condenser.uniqueId}
                condenser={condenser}
                showBTUs={false}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

ConfigOptionComponent.defaultProps = {
  isAirHandlerPage: false
};