import React, { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { useDrag } from 'react-dnd';

type DraggableItemProps = {
  label: string;
  size?: 'md' | 'sm';
  type: 'new-level' | 'new-space' | 'glass';
  itemId?: number;
  disabled?: boolean;
  onDoubleClick?: () => void;
};

export default function DraggableItem({
  label,
  size = 'md',
  type,
  itemId,
  disabled = false,
  onDoubleClick
}: DraggableItemProps) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type,
    item: { label, type, itemId, disabled },
    canDrag: () => !disabled,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  }), [disabled]);

  const classes = clsx(
    'flex gap-1 items-center font-semibold bg-blue-100 rounded-md',
    size === 'md' && 'py-3 pr-5 text-sm',
    size === 'sm' && 'py-2 pr-4 text-xs',
    isDragging && 'cursor-grabbing',
    disabled ? 'opacity-[70%] cursor-not-allowed' : 'cursor-grab'
  );

  return (
    <button ref={drag} className={classes} onDoubleClick={onDoubleClick}>
      <div className='flex'>
        <EllipsisVerticalIcon className='h-4 w-4 translate-x-3' />
        <EllipsisVerticalIcon className='h-4 w-4' />
      </div>
      {label}
    </button>
  );
}

DraggableItem.defaultProps = {
  size: 'md',
  itemId: undefined,
  disabled: false,
  onDoubleClick: undefined
};
