import React, { useContext, useState, useEffect } from 'react';
import { successModal, errorModal, confirmationModal } from 'utils/helpers';
import { AdminContext } from 'data/context';
import NumberInput from 'components/inputs/number';
import Button from 'components/inputs/button';
import axios from 'utils/api';
import { PlusIcon } from '@heroicons/react/20/solid';
import { TrashIcon } from '@heroicons/react/24/outline';
import CreateFenestrationItemModal from 'components/modals/create-fenestration-item-modal';

export default function Fenestration() {
  const { refreshData, fenestration } = useContext(AdminContext);
  const [values, setValues] = useState(fenestration);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setValues(fenestration);
  }, [fenestration]);

  const handleValueChange = (itemId: number, key: string, value: any) => {
    setValues((oldValues) => {
      const newValues = oldValues.map((item) => {
        if (item.id === itemId) {
          return {
            ...item,
            [key]: value,
          };
        }
        return item;
      });
      return newValues;
    });
  };

  const handleSave = async () => {
    try {
      await axios.patch('fenestration', { items: values });
      await refreshData();
      successModal(`Fenestration settings updated.`);
    } catch (error) {
      errorModal('Something went wrong.');
    }
  };

  const deleteItem = (itemId: number) => {
    confirmationModal('Are you sure you want to delete this fenestration item?', async () => {
      try {
        await axios.delete(`fenestration/${itemId}`);
        await refreshData();
        successModal('Fenestration item deleted.');
      } catch (error) {
        errorModal('Something went wrong.');
      }
    });
  };

  return (
    <div className='mx-auto flex max-w-[1600px] flex-col gap-5'>
      <div className='text-3xl font-bold text-gray-900'>
        Fenestration Settings
      </div>
      <table className='min-w-full divide-y divide-gray-300'>
        <thead>
          <tr>
            <th
              scope='col'
              className='pb-3.5 pl-0 pr-3 text-left font-semibold text-gray-900'
            >
              Item
            </th>
            <th
              scope='col'
              className='px-3 pb-3.5 text-left font-semibold text-gray-900'
            >
              Heating BTU Modifier
            </th>
            <th
              scope='col'
              className='px-3 pb-3.5 text-left font-semibold text-gray-900'
            >
              Cooling BTU Modifier
            </th>
            <th
              scope='col'
              className='px-3 pb-3.5 text-left font-semibold text-gray-900'
            />
          </tr>
        </thead>
        <tbody className='divide-y divide-gray-200'>
          {fenestration.map((item) => (
            <tr key={item.id}>
              <td className='whitespace-nowrap py-4 pl-0 pr-3 text-gray-900'>
                {item.label}
              </td>
              <td className='whitespace-nowrap px-3 py-4'>
                <div className='inline-block'>
                  <NumberInput
                    value={values.find((i) => i.id === item.id)?.heatingBTU}
                    onChange={(value) => handleValueChange(item.id, 'heatingBTU', value)}
                  />
                </div>
              </td>
              <td className='whitespace-nowrap px-3 py-4'>
                <div className='inline-block'>
                  <NumberInput
                    value={values.find((i) => i.id === item.id)?.coolingBTU}
                    onChange={(value) => handleValueChange(item.id, 'coolingBTU', value)}
                  />
                </div>
              </td>
              <td className='px-3 py-4'>
                <TrashIcon
                  className='h-5 w-5 shrink-0 cursor-pointer text-gray-500 hover:text-gray-700'
                  onClick={() => deleteItem(item.id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className='flex items-center gap-3'>
        <Button text='Save' color='green' onClick={handleSave} />
        <Button text='New Fenestration Item' color='secondary' onClick={() => setModalOpen(true)} icon={PlusIcon} />
      </div>
      <CreateFenestrationItemModal openState={[modalOpen, setModalOpen]} />
    </div>
  );
}
