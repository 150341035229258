import React, { useContext } from 'react';
import { BookOpenIcon } from '@heroicons/react/24/outline';
import { ProjectContext } from 'data/context';
import { Popover } from '@headlessui/react';
import Tooltip from 'components/tooltip';
import Textarea from 'components/inputs/textarea';

export default function NotesPopover() {
  const { warmUpNotes, setWarmUpNotes } = useContext(ProjectContext);

  return (
    <Popover className='relative'>
      <Popover.Button className='relative flex h-16 w-16 cursor-pointer items-center justify-center rounded-full bg-white shadow-lg hover:bg-gray-50'>
        <BookOpenIcon className='h-8 w-8 text-gray-700' />
        <Tooltip text='Notes' options={{ placement: 'left' }} />
      </Popover.Button>
      <Popover.Panel className='absolute bottom-0 right-0 flex w-[400px] flex-col gap-3 rounded-lg bg-white p-3 shadow-lg'>
        <div className='font-semibold'>
          Notes
        </div>
        <Textarea
          rows={10}
          value={warmUpNotes}
          onChange={(value) => setWarmUpNotes(value ?? '')}
          placeholder='Notes...'
          debounce={500}
          autoFocus
        />
      </Popover.Panel>
    </Popover>
  );
}
