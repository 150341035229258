import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import { AdminContext } from 'data/context';
import { successModal, errorModal } from 'utils/helpers';
import axios from 'utils/api';
import Modal from 'components/modals/modal';
import Text from 'components/inputs/text';
import Button from 'components/inputs/button';
import NumberInput from 'components/inputs/number';
import Select from 'components/inputs/select';

type CreateAttributeFollowupModalProps = {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
};

export default function CreateAttributeFollowupModal({ openState }: CreateAttributeFollowupModalProps) {
  const { refreshData, attributes } = useContext(AdminContext);
  const [open, setOpen] = openState;
  const [attributeId, setAttributeId] = useState<number>();
  const [attributeValue, setAttributeValue] = useState('');
  const [followupAttributeId, setFollowupAttributeId] = useState<number>();

  useEffect(() => {
    setAttributeId(undefined);
    setAttributeValue('');
    setFollowupAttributeId(undefined);
  }, [open]);

  useEffect(() => {
    setAttributeValue('');
  }, [attributeId]);

  const handleSave = async () => {
    if (attributeId && attributeValue && followupAttributeId) {
      axios.post('attribute-followups', { attributeId, value: attributeValue, followupAttributeId })
        .then(() => {
          successModal(`Attribute followup created`);
        })
        .catch(() => {
          errorModal('Failed to create attribute followup');
        })
        .finally(() => {
          refreshData();
          setOpen(false);
        });
    }
  };

  return (
    <Modal
      title='New Attribute Followup'
      openState={openState}
      size='wide'
      infoMode
    >
      <div className='flex flex-col gap-5'>
        <div className='flex flex-wrap gap-5'>
          <div className="flex flex-col gap-2">
            <div className='font-semibold text-gray-900'>Attribute</div>
            <div>
              <Select
                options={
                  attributes
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map(att => ({ value: att.id.toString(), label: att.label }))
                }
                value={attributeId !== undefined ? attributeId.toString() : ''}
                onChange={value => setAttributeId(value !== '' ? Number(value) : undefined)}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className='font-semibold text-gray-900'>Value</div>
            <div>
              {attributes.find(att => att.id === attributeId)?.type === 'select' && (
                <Select
                  options={attributes.find(att => att.id === attributeId)?.options ?? []}
                  value={attributeValue}
                  onChange={setAttributeValue}
                />
              )}
              {attributes.find(att => att.id === attributeId)?.type === 'number' && (
                <NumberInput
                  value={attributeValue}
                  onChange={value => setAttributeValue(value?.toString() ?? '')}
                  placeholder='Value...'
                />
              )}
              {!['select', 'number'].includes(attributes.find(att => att.id === attributeId)?.type ?? '') && (
                <Text
                  value={attributeValue}
                  onChange={setAttributeValue}
                  placeholder='Value...'
                />
              )}
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className='font-semibold text-gray-900'>Followup Attribute</div>
            <div>
              <Select
                options={
                  attributes
                    .filter(att => att.id !== attributeId)
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map(att => ({ value: att.id.toString(), label: att.label }))
                }
                value={followupAttributeId !== undefined ? followupAttributeId.toString() : ''}
                onChange={value => setFollowupAttributeId(value !== '' ? Number(value) : undefined)}
              />
            </div>
          </div>
        </div>
        <div className='flex items-center gap-3'>
          <Button
            text='Create'
            color='green'
            onClick={handleSave}
          />
          <Button
            text='Cancel'
            color='red'
            onClick={() => setOpen(false)}
          />
        </div>
      </div>
    </Modal >
  );
}
