import React, { useContext } from 'react';
import { DataContext } from 'data/context';
import { PlusIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { Popover, PopoverClose, PopoverContent, PopoverTrigger } from 'components/popover';

type FenestrationProps = {
  items: number[];
  addItem: (itemId: number) => void;
  removeItem: (itemId: number) => void;
}

export default function Fenestration({
  items,
  addItem,
  removeItem
}: FenestrationProps) {
  const { fenestration } = useContext(DataContext);

  return (
    <div className='flex items-center flex-wrap gap-3'>
      {Array.from(new Set(items)).map((itemId, index) => {
        const itemCount = items.filter((id) => id === itemId).length;
        return (
          <span
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className='inline-flex items-center gap-1 rounded-md bg-gray-200 px-3 py-2 text-sm font-medium text-gray-700 whitespace-nowrap'
          >
            <button
              type='button'
              className='-ml-1 relative h-5 w-5 rounded-sm hover:bg-gray-500/20 inline-flex items-center justify-center'
              onClick={() => removeItem(itemId)}
            >
              <XMarkIcon className='w-4 h-4' />
            </button>
            {fenestration?.find((item) => item.id === itemId)?.label || 'Unknown Item'}{itemCount > 1 && ` x ${itemCount}`}
          </span>
        );
      })}
      <Popover placement='bottom-start'>
        <div>
          <PopoverTrigger className='inline-flex items-center gap-1 justify-center rounded-md px-3 py-2 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 bg-blue-100 text-blue-900 hover:bg-blue-200 whitespace-nowrap'>
            <PlusIcon className={`w-4 h-4 -ml-1 ${items.length && '-mr-1'}`} />
            {!items.length && <span>Add Windows/Doors</span>}
          </PopoverTrigger>
        </div>
        <PopoverContent className="flex flex-col p-2 w-56 rounded-lg bg-white shadow-lg ring-1 ring-black/5 focus:outline-none z-50">
          {fenestration.map((item) => (
            <PopoverClose
              key={item.label}
              onClick={() => addItem(item.id)}
              className='rounded-md w-full flex items-center gap-3 px-3 py-2 text-left text-sm font-semibold text-gray-900 hover:bg-gray-100'
            >
              <span>{item.label}</span>
            </PopoverClose>
          ))}
        </PopoverContent>
      </Popover>
    </div>
  );
}
