import React, { useState, useRef, ReactNode } from 'react';
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  FloatingPortal,
  FloatingArrow,
  arrow,
  UseFloatingOptions
} from '@floating-ui/react';

type TooltipProps = {
  text: string;
  options?: Partial<UseFloatingOptions>;
  children?: ReactNode;
} | {
  text?: string;
  options?: Partial<UseFloatingOptions>;
  children: ReactNode;
};

export default function Tooltip({ text, options, children }: TooltipProps) {
  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef(null);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "top",
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip(),
      shift({ padding: 5 }),
      arrow({
        element: arrowRef
      }),
    ],
    ...options
  });

  const hover = useHover(context, { move: false });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role
  ]);

  return (
    <>
      <div
        ref={refs.setReference}
        {...getReferenceProps()}
        className='absolute inset-0'
      />
      <FloatingPortal>
        {isOpen && (
          <div
            className='w-max max-w-[300px] bg-[#444] text-white text-sm px-2 py-1 rounded z-50'
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            {children ?? text}
            <FloatingArrow fill='#444' ref={arrowRef} context={context} />
          </div>
        )}
      </FloatingPortal>
    </>
  );
}