import React, { useContext, useEffect, useState } from 'react';
import { successModal, errorModal, confirmationModal } from 'utils/helpers';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { v4 as uuid } from 'uuid';
import { AdminContext } from 'data/context';
import ToggleSwitch from 'components/inputs/toggle-switch';
import Button from 'components/inputs/button';
import TemplateItem from 'components/admin/template-item';
import Checkbox from 'components/inputs/checkbox';
import axios from 'utils/api';
import Select from 'components/inputs/select';
import CreateStructureTypeModal from 'components/modals/create-structure-type-modal';
import CreateStructureSubtypeModal from 'components/modals/create-structure-subtype-modal';
import Textarea from 'components/inputs/textarea';

export default function StructureType() {
  const {
    refreshData,
    structureTypes,
    items,
    validItems
  } = useContext(AdminContext);
  const [selectedStructureType, setSelectedStrucureType] = useState<number | null>(null);
  const [selectedStructureSubtype, setSelectedStrucureSubtype] = useState<number | null>(null);
  const [validItemIds, setValidItemIds] = useState<number[]>([]);
  const [template, setTemplate] = useState<string[]>([]);
  const [partialEnabled, setPartialEnabled] = useState(false);
  const [addressRequired, setAddressRequired] = useState(false);
  const [createTypeModalOpen, setCreateTypeModalOpen] = useState(false);
  const [createSubtypeModalOpen, setCreateSubtypeModalOpen] = useState(false);
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (selectedStructureType === -1) {
      setCreateTypeModalOpen(true);
      setSelectedStrucureType(null);
    }
    setSelectedStrucureSubtype(null);
  }, [selectedStructureType]);

  const setDefaultValues = () => {
    const structureSubType = structureTypes
      .find((st) => st.id === selectedStructureType)
      ?.subtypes.find((sst) => sst.id === selectedStructureSubtype);
    if (structureSubType) {
      setValidItemIds(
        validItems.find((entry) => entry.structureSubtypeId === selectedStructureSubtype)
          ?.itemIds || []
      );
      setTemplate(structureSubType.template);
      setPartialEnabled(structureSubType.partialEnabled);
      setAddressRequired(structureSubType.addressRequired);
      setDescription(structureSubType.description);
    } else {
      errorModal('Structure subtype not found.');
    }
  };

  useEffect(() => {
    if (selectedStructureSubtype !== null && selectedStructureSubtype !== -1) {
      setDefaultValues();
    } else if (selectedStructureSubtype === -1) {
      setCreateSubtypeModalOpen(true);
      setSelectedStrucureSubtype(null);
    } else {
      setValidItemIds([]);
      setTemplate([]);
      setPartialEnabled(false);
      setAddressRequired(false);
      setDescription('');
    }
  }, [selectedStructureType, selectedStructureSubtype]);

  const handleValidItemChange = (itemId: number, checked: boolean) => {
    setValidItemIds((prev) => {
      if (checked) {
        return [...prev.filter((id) => id !== itemId), itemId];
      }
      return prev.filter((id) => id !== itemId);
    });
  };

  const handleSave = async () => {
    try {
      await axios.patch('structure-subtype', {
        structureSubtypeId: selectedStructureSubtype,
        validItemIds,
        template,
        partialEnabled,
        addressRequired,
        description
      });
      await refreshData();
      successModal(`Structure subtype updated.`);
    } catch (error) {
      errorModal('Something went wrong.');
    }
  };

  const handleReset = () => {
    confirmationModal('Are you sure you want to reset this structure subtype? All your changes will be lost!', () => {
      setDefaultValues();
    });
  };

  const handleDeleteSubtype = () => {
    confirmationModal('Are you sure you want to delete this structure subtype?', async () => {
      if (selectedStructureSubtype) {
        try {
          await axios.delete(`structure-subtypes/${selectedStructureSubtype}`);
          await refreshData();
          successModal('Subtype deleted.');
          setSelectedStrucureSubtype(null);
        } catch (error) {
          errorModal('Something went wrong.');
        }
      }
    });
  };

  const handleDeleteType = () => {
    confirmationModal('Are you sure you want to delete this structure type? This will also delete all of its subtypes!', async () => {
      if (selectedStructureType) {
        try {
          await axios.delete(`structure-types/${selectedStructureType}`);
          await refreshData();
          successModal('Type deleted.');
          setSelectedStrucureType(null);
          setSelectedStrucureSubtype(null);
          setDescription('');
        } catch (error) {
          errorModal('Something went wrong.');
        }
      }
    });
  };

  return (
    <div className='mx-auto flex max-w-[1600px] flex-col gap-5'>
      <div className='text-3xl font-bold text-gray-900'>
        Structure Type/Subtype Settings
      </div>
      <div className='flex gap-5'>
        <div className='flex flex-col gap-3'>
          <div className='font-semibold text-gray-900'>Structure Type</div>
          <Select
            onChange={(value) => setSelectedStrucureType(value !== '' ? Number(value) : null)}
            value={selectedStructureType?.toString() ?? undefined}
            options={[
              ...structureTypes.map((st) => ({ label: st.label, value: st.id.toString() })),
              { label: '+ New Structure Type', value: '-1' }
            ]}
          />
          <div className='font-semibold text-gray-900'>Structure Subtype</div>
          <Select
            onChange={(value) => setSelectedStrucureSubtype(value !== '' ? Number(value) : null)}
            value={selectedStructureSubtype?.toString() ?? undefined}
            options={[
              ...(structureTypes
                .find((st) => st.id === selectedStructureType)
                ?.subtypes.map((subtype) => (
                  { label: subtype.label, value: subtype.id.toString() }
                )) ?? []),
              ...(selectedStructureType !== null ?
                [{ label: `+ New ${structureTypes.find(st => st.id === selectedStructureType)?.label} Subtype`, value: '-1' }] :
                []
              )
            ]}
            disabled={selectedStructureType === null}
          />
        </div>
        <div className='flex grow flex-col gap-5'>
          <div className='max-h-[50vh] overflow-auto'>
            <table className='min-w-full border-separate border-spacing-0 divide-y divide-gray-300'>
              <thead>
                <tr>
                  <th
                    scope='col'
                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-100 pb-3.5 pl-3 pr-0 text-left font-semibold text-gray-900'
                  />
                  <th
                    scope='col'
                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-100 px-3 pb-3.5 text-left font-semibold text-gray-900'
                  >
                    Valid Spaces
                  </th>
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200'>
                {items
                  .filter((s) => s.group === 'Space')
                  .map((space) => (
                    <tr
                      key={space.id}
                      className={selectedStructureSubtype !== null ?
                        'cursor-pointer hover:bg-gray-200' :
                        'cursor-not-allowed'
                      }
                      onClick={selectedStructureSubtype !== null ?
                        () => handleValidItemChange(space.id, !validItemIds.includes(space.id)) :
                        undefined
                      }
                    >
                      <td className='whitespace-nowrap border-b border-gray-200 py-4 pl-3 pr-0 text-gray-900'>
                        <Checkbox
                          htmlId={`checkbox-${space.id}`}
                          value={validItemIds.includes(space.id)}
                          onChange={(checked) => handleValidItemChange(space.id, checked)}
                          disabled={selectedStructureSubtype === null}
                        />
                      </td>
                      <td className='whitespace-nowrap border-b border-gray-200 px-3 py-4 text-gray-900'>
                        {space.label}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className='flex flex-wrap gap-5'>
            <label className='flex cursor-pointer items-center gap-2'>
              <span className='font-semibold'>Partial Enabled?</span>
              <ToggleSwitch
                value={partialEnabled}
                onChange={(checked) => setPartialEnabled(checked)}
                disabled={selectedStructureSubtype === null}
              />
            </label>
            <label className='flex cursor-pointer items-center gap-2'>
              <span className='font-semibold'>Address Required?</span>
              <ToggleSwitch
                value={addressRequired}
                onChange={(checked) => setAddressRequired(checked)}
                disabled={selectedStructureSubtype === null}
              />
            </label>
          </div>
          <div className="flex justify-between">
            <div className='flex items-center gap-3'>
              <Button
                text='Save'
                color='green'
                onClick={handleSave}
                disabled={selectedStructureSubtype === null}
              />
              <Button
                text='Reset'
                color='red'
                onClick={handleReset}
                disabled={selectedStructureSubtype === null}
              />
            </div>
            <div className='flex items-center gap-3'>
              <Button
                text='Delete Type'
                color='red'
                onClick={handleDeleteType}
                disabled={selectedStructureType === null}
              />
              <Button
                text='Delete Subtype'
                color='red'
                onClick={handleDeleteSubtype}
                disabled={selectedStructureSubtype === null}
              />
            </div>
          </div>
        </div>
        <div className='flex grow flex-col gap-3'>
          <div className='font-semibold text-gray-900'>Default Template</div>
          <DndProvider backend={HTML5Backend}>
            <div className='flex flex-col gap-3 border-2 border-dashed border-gray-300 p-3'>
              {template.map((item, index) => (
                <TemplateItem
                  key={uuid()}
                  label={item}
                  index={index}
                  updateArray={setTemplate}
                  deleteSelf={() => {
                    setTemplate((prev) => {
                      const newItems = [...prev];
                      newItems.splice(index, 1);
                      return newItems;
                    });
                  }}
                />
              ))}
            </div>
          </DndProvider>
          <div className='flex flex-wrap gap-3'>
            {['attic', 'basement', 'level', 'crawlspace'].map((item) => (
              <Button
                key={item}
                text={`+ ${item}`}
                color='white'
                onClick={() => setTemplate((prev) => [...prev, item])}
                disabled={selectedStructureSubtype === null}
              />
            ))}
          </div>
          <div className='font-semibold text-gray-900'>Description</div>
          <Textarea
            rows={5}
            value={description}
            onChange={setDescription}
            disabled={selectedStructureSubtype === null}
            placeholder='Description...'
          />
        </div>
      </div>
      <CreateStructureTypeModal openState={[createTypeModalOpen, setCreateTypeModalOpen]} />
      <CreateStructureSubtypeModal
        openState={[createSubtypeModalOpen, setCreateSubtypeModalOpen]}
        structureTypeId={selectedStructureType ?? undefined}
      />
    </div>
  );
}
