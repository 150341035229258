import React, { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import OpenAI from 'openai';
import { notification } from 'utils/helpers';
import Modal from 'components/modals/modal';
import axios from 'utils/api';
import { ProjectContext } from 'data/context';

type LiveChatModalProps = {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
};

export default function LiveChatModal({ openState }: LiveChatModalProps) {
  const {
    warmUpNotes,
    setWarmUpNotes,
    liveChatData
  } = useContext(ProjectContext);

  const summarizeTranscript = async () => {
    setWarmUpNotes('Loading...');
    const messages: OpenAI.Chat.Completions.ChatCompletionMessageParam[] = [
      {
        role: 'system',
        content: `Here is a transcript of a customer contacting one of our sales consultants about a new HVAC system: ${JSON.stringify(
          liveChatData?.transcript.map(
            (entry) => `[${entry.author}] ${entry.chatText}`
          )
        )}. Please respond with the summary only.`
      },
      {
        role: 'user',
        content:
          'Summarize this transcript into a few short sentences for another sales consultant to read. Please respond with the summary only.'
      }
    ];
    try {
      const reply = (await axios.post(
        'ai',
        messages
      )).data;
      setWarmUpNotes(reply.content);
    } catch (err) {
      console.error(err);
      notification('Failed to summarize live chat transcript', 'error');
    }
  };

  useEffect(() => {
    if (liveChatData && !warmUpNotes) {
      summarizeTranscript();
    }
  }, [liveChatData]);

  return (
    <Modal title='Live Chat' openState={openState} infoMode>
      {liveChatData && (
        <div className='flex select-text flex-col gap-3'>
          <table>
            <tbody className='divide-y'>
              <tr>
                <td className='pr-3'>Did we answer questions?:</td>
                <td>{liveChatData.areQuestionsAnswered ?? 'N/A'}</td>
              </tr>
              <tr>
                <td className='pr-3'>What is the chat type?:</td>
                <td>{liveChatData.leadType ?? 'N/A'}</td>
              </tr>
              <tr>
                <td className='pr-3'>ProjectID:</td>
                <td>{liveChatData.existingProjectID ?? 'N/A'}</td>
              </tr>
              <tr>
                <td className='pr-3'>Location:</td>
                <td>{liveChatData.location ?? 'N/A'}</td>
              </tr>
              <tr>
                <td className='pr-3'>Start URL:</td>
                <td>
                  {liveChatData.startURL ? (
                    <a
                      href={`${liveChatData.startURL}`}
                      target='_blank'
                      rel='noreferrer'
                      className='cursor-pointer text-blue-500 hover:text-blue-600'
                    >
                      Click here
                    </a>
                  ) : (
                    <span>N/A</span>
                  )}
                </td>
              </tr>
              <tr>
                <td className='pr-3'>Cutomer Name:</td>
                <td>{liveChatData.customerName ?? 'N/A'}</td>
              </tr>
              <tr>
                <td className='pr-3'>CustomerID:</td>
                <td>
                  {liveChatData.startURL ? (
                    <a
                      href={`https://${window.location.hostname}/partnernet/customer/${liveChatData.existingCustomerID}`}
                      target='_blank'
                      rel='noreferrer'
                      className='cursor-pointer text-blue-500 hover:text-blue-600'
                    >
                      {liveChatData.existingCustomerID}
                    </a>
                  ) : (
                    <span>N/A</span>
                  )}
                </td>
              </tr>
              <tr>
                <td className='pr-3'>Customer email:</td>
                <td>{liveChatData.email ?? 'N/A'}</td>
              </tr>
              <tr>
                <td className='pr-3'>System type:</td>
                <td>{liveChatData.systemType ?? 'N/A'}</td>
              </tr>
              <tr>
                <td className='pr-3'>
                  Did we offer to help with product selection or to call
                  customer?:
                </td>
                <td>{liveChatData.needsAssessmentOrCall ?? 'N/A'}</td>
              </tr>
              <tr>
                <td className='pr-3'>Notes:</td>
                <td>{liveChatData.notes ?? 'N/A'}</td>
              </tr>
              <tr>
                <td className='pr-3'>Inquiry Type:</td>
                <td>{liveChatData.inquiryType ?? 'N/A'}</td>
              </tr>
              <tr>
                <td className='pr-3'>Customer notes:</td>
                <td>{liveChatData.customerNotes ?? 'N/A'}</td>
              </tr>
              <tr>
                <td className='pr-3'>Customer phone:</td>
                <td>{liveChatData.phoneNumber ?? 'N/A'}</td>
              </tr>
            </tbody>
          </table>
          <div>
            <h6 className='text-xl font-semibold'>Transcript</h6>
            <div className='flex max-h-[400px] flex-col gap-2 overflow-auto bg-gray-100 p-1'>
              {liveChatData.transcript.map((entry) => (
                <div key={entry.eventID} className='whitespace-break-spaces'>
                  <div className='font-bold'>
                    {entry.author} ({entry.created}):
                  </div>
                  <div>{entry.chatText}</div>
                </div>
              ))}
            </div>
          </div>
          <div>
            <h6 className='text-xl font-semibold'>Last Pages Visited</h6>
            <ul className='list-disc'>
              {liveChatData.lastPages.map((page, i) => (
                <li
                  // eslint-disable-next-line react/no-array-index-key
                  key={`url${i}`}
                  className='ml-5 overflow-hidden text-ellipsis whitespace-nowrap'
                >
                  <a
                    href={page.pageURL}
                    target='_blank'
                    rel='noreferrer'
                    className='cursor-pointer text-blue-500 hover:text-blue-600'
                  >
                    {page.pageURL}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </Modal>
  );
}
