/* eslint-disable no-nested-ternary */
import React, {
  Fragment,
  Dispatch,
  SetStateAction,
  ReactNode,
  useState,
  useEffect
} from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import Tooltip from 'components/tooltip';
import Text from 'components/inputs/text';

type ModalProps = {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
  title: string;
  children?: ReactNode;
  onSave?: () => void;
  onClose?: () => void;
  onRename?: (value: string) => void;
  saveDisabled?: boolean;
  buttonText?: string;
  infoMode?: boolean;
  closeOnSave?: boolean;
  defaultRename?: boolean;
  size?: 'normal' | 'wide' | 'xl' | 'fullscreen';
  renameSizeLimit?: number;
};

export default function Modal({
  title,
  openState,
  children,
  onSave,
  onClose,
  onRename,
  saveDisabled = false,
  buttonText = 'Save',
  infoMode = false,
  closeOnSave = true,
  defaultRename = false,
  size = 'normal',
  renameSizeLimit
}: ModalProps) {
  const [open, setOpen] = openState;
  const [renaming, setRenaming] = useState(false);
  const [newName, setNewName] = useState(title);

  useEffect(() => {
    setNewName(title);
    setRenaming(false);
  }, [title]);

  const handleSave = () => {
    if (onSave) {
      onSave();
    }
    if (closeOnSave) {
      if (onClose) {
        onClose();
      }
      setOpen(false);
    }
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    setOpen(false);
  };

  const handleTitleClick = () => {
    if (onRename) {
      setRenaming(true);
    }
  };

  useEffect(() => {
    if (defaultRename) {
      handleTitleClick();
    }
  }, [defaultRename, open]);

  const handleUpdateName = () => {
    if (newName.trim() !== '') {
      if (onRename) {
        onRename(newName);
      }
      setNewName(newName.trim());
    } else {
      setNewName(title);
    }
    setRenaming(false);
  };

  const handleRenameKeyDown = (key: string) => {
    if (key === 'Enter') {
      handleUpdateName();
    }
  };

  const handleRenameInput = (input: string) => {
    if (renameSizeLimit !== undefined) {
      setNewName(input.slice(0, renameSizeLimit));
    } else {
      setNewName(input);
    }
  };

  const panelClasses = clsx(
    'relative transform select-none rounded-lg bg-white shadow-xl transition-all sm:my-8 sm:w-full',
    size === 'normal' && 'max-w-xl',
    size === 'wide' && 'max-w-3xl',
    size === 'xl' && 'max-w-6xl',
    size === 'fullscreen' && 'max-w-[90vw] max-h-[90vh] overflow-auto'
  );

  const saveButtonClasses = clsx(
    'w-full py-3 font-semibold rounded-b-lg',
    saveDisabled
      ? 'text-gray-400 bg-gray-200 hover:bg-gray-300 cursor-not-allowed'
      : 'text-white bg-blue-500 hover:bg-blue-600'
  );

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-30' onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>
        <div className='fixed inset-0 z-30 overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className={panelClasses}>
                {renaming ? (
                  <div className='pl-6 pr-12 pt-6'>
                    <div className='relative'>
                      <Text
                        value={newName}
                        onInput={(value) => handleRenameInput(value ?? '')}
                        onKeyDown={(e) => handleRenameKeyDown(e.key)}
                        onBlur={handleUpdateName}
                        autoFocus
                        autoSelect
                        controlled
                      />
                      {renameSizeLimit !== undefined && (
                        <div className='absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500 pointer-events-none'>
                          {newName.length}/{renameSizeLimit}
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  onRename ?
                    <div className='px-12 pt-6'>
                      <button
                        type='button'
                        onClick={handleTitleClick}
                        className='relative flex items-center justify-center gap-2 w-full overflow-ellipsis text-lg font-bold text-gray-900 outline-none group'
                      >
                        <span>
                          {newName}
                        </span>
                        <PencilSquareIcon
                          className='h-5 w-5 text-gray-600 group-hover:text-gray-900'
                        />
                        <Tooltip text='Rename' />
                      </button>
                    </div> :
                    <div className='w-full overflow-ellipsis px-12 pt-6 text-center text-lg font-bold text-gray-900'>
                      {title}
                    </div>
                )}
                <div className='w-full p-6 relative'>{children}</div>
                {!infoMode && (
                  <button
                    type='button'
                    className={saveButtonClasses}
                    onClick={handleSave}
                    disabled={saveDisabled}
                  >
                    {buttonText}
                  </button>
                )}
                <button
                  type='button'
                  className='absolute right-0 top-0 cursor-pointer p-3 text-gray-600 hover:text-gray-700'
                  onClick={handleClose}
                >
                  <XMarkIcon className='h-6 w-6' />
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

Modal.defaultProps = {
  onSave: undefined,
  onClose: undefined,
  onRename: undefined,
  saveDisabled: false,
  buttonText: 'Save',
  infoMode: false,
  children: undefined,
  closeOnSave: true,
  defaultRename: false,
  size: 'normal',
  renameSizeLimit: undefined
};
