import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useState,
  ReactNode
} from 'react';
import { useImmer, Updater } from 'use-immer';
import {
  Configuration,
  ExistingSystem,
  Level,
  PossibleCondenserLocation,
  SelectedSystem
} from 'data/types';

type ConfigurationState = {
  structure: Level[];
  setStructure: Updater<Level[]> | (() => void);
  configurations: Configuration[];
  setConfigurations: Updater<Configuration[]> | (() => void);
  configurationOptions: Configuration[];
  setConfigurationOptions: Updater<Configuration[]> | (() => void);
  allConfigurationOptions: Configuration[];
  setAllConfigurationOptions: Updater<Configuration[]> | (() => void);
  activeConfig: number;
  setActiveConfig: Dispatch<SetStateAction<number>> | (() => void);
  selectedSystems: SelectedSystem[];
  setSelectedSystems: Updater<SelectedSystem[]> | (() => void);
  activeSelectedSystem: number;
  setActiveSelectedSystem: Dispatch<SetStateAction<number>> | (() => void);
  possibleCondenserLocations: PossibleCondenserLocation[];
  setPossibleCondenserLocations: Updater<PossibleCondenserLocation[]> | (() => void);
  existingSystems: ExistingSystem[];
  setExistingSystems: Updater<ExistingSystem[]> | (() => void);
};

const defaultConfigurationState: ConfigurationState = {
  structure: [],
  setStructure: () => { },
  configurations: [],
  setConfigurations: () => { },
  configurationOptions: [],
  setConfigurationOptions: () => { },
  allConfigurationOptions: [],
  setAllConfigurationOptions: () => { },
  activeConfig: 0,
  setActiveConfig: () => { },
  selectedSystems: [],
  setSelectedSystems: () => { },
  activeSelectedSystem: 0,
  setActiveSelectedSystem: () => { },
  possibleCondenserLocations: [],
  setPossibleCondenserLocations: () => { },
  existingSystems: [],
  setExistingSystems: () => { }
};

export const ConfigurationContext = createContext(defaultConfigurationState);

export function ConfigurationContextProvider({ children }: { children: ReactNode }) {
  const [structure, setStructure] = useImmer(defaultConfigurationState.structure);
  const [configurations, setConfigurations] = useImmer(defaultConfigurationState.configurations);
  const [configurationOptions, setConfigurationOptions] = useImmer(defaultConfigurationState.configurationOptions);
  const [allConfigurationOptions, setAllConfigurationOptions] = useImmer(defaultConfigurationState.allConfigurationOptions);
  const [activeConfig, setActiveConfig] = useState(defaultConfigurationState.activeConfig);
  const [selectedSystems, setSelectedSystems] = useImmer(defaultConfigurationState.selectedSystems);
  const [activeSelectedSystem, setActiveSelectedSystem] = useState(defaultConfigurationState.activeSelectedSystem);
  const [possibleCondenserLocations, setPossibleCondenserLocations] = useImmer(defaultConfigurationState.possibleCondenserLocations);
  const [existingSystems, setExistingSystems] = useImmer(defaultConfigurationState.existingSystems);

  return (
    <ConfigurationContext.Provider
      value={{
        structure,
        setStructure,
        configurations,
        setConfigurations,
        configurationOptions,
        setConfigurationOptions,
        allConfigurationOptions,
        setAllConfigurationOptions,
        activeConfig,
        setActiveConfig,
        selectedSystems,
        setSelectedSystems,
        activeSelectedSystem,
        setActiveSelectedSystem,
        possibleCondenserLocations,
        setPossibleCondenserLocations,
        existingSystems,
        setExistingSystems
      }}
    >
      {children}
    </ConfigurationContext.Provider>
  );
}
