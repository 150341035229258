import React, { useContext, useState, useEffect } from 'react';
import { ConfigurationContext, DataContext, UIContext } from 'data/context';
import DuctingKitOption from 'components/accessory-options/ducting-kit-option';
import LineSetOption from 'components/accessory-options/line-set-option';
import QuantityOption from 'components/accessory-options/quantity-option';
import AccessoryDrawer from 'components/drawers/accessory-drawer';
import ToggleSwitch from 'components/inputs/toggle-switch';
import {
  AccessoryCategory,
  AccessoryGroup,
  AccessoryProduct
} from 'data/types';
import SystemOptionComponent from 'components/system-option';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import InternalNote from 'components/internal-note';
import { accessoryCategories, accessoryGroupLabels } from 'data/constants';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

export default function Accessories() {
  const {
    selectedSystems,
    setSelectedSystems,
    activeSelectedSystem
  } = useContext(ConfigurationContext);
  const { setActiveAccessoryDrawer } = useContext(UIContext);
  const { lineSetCoverProducts } = useContext(DataContext);
  const [accessoryGroups, setAccessoryGroups] = useState<AccessoryGroup[]>([]);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [animate] = useAutoAnimate({ duration: 100 });

  const createAccessoryGroups = () => {
    const newGroups: AccessoryGroup[] = [];
    const categories: AccessoryCategory[] = [
      'mounting',
      'power',
      'install',
      'control',
      'lineSetCover'
    ];
    const advancedCategories = [
      'lineSetCover'
    ];
    const productTypes = {
      'mounting': 'condenser',
      'power': 'condenser',
      'install': 'air handler',
      'control': 'air handler',
      'lineSetCover': 'air handler'
    };
    categories.forEach((category) => {
      const newGroup: AccessoryGroup = {
        category,
        label: accessoryGroupLabels[category]?.plural ?? 'N/A',
        description:
          accessoryGroupLabels[category]?.description.plural ?? 'N/A',
        accessories: [],
        productType: productTypes[category],
        advanced: advancedCategories.includes(category)
      };
      if (category === 'lineSetCover') {
        lineSetCoverProducts.forEach((product) => {
          newGroup.accessories.push({
            product,
            compatibleProducts: []
          });
        });
      } else {
        const accessoryProducts: { product: AccessoryProduct; compatibleProduct: string | undefined; }[] = [];
        selectedSystems[activeSelectedSystem]?.configuration.condensers.forEach((condenser) => {
          accessoryProducts.push(...(condenser.selectedProduct?.accessories.map(
            (acc) => ({ product: acc, compatibleProduct: condenser.selectedProduct?.name })
          ) ?? []));
          condenser.zones.forEach((zone) => {
            accessoryProducts.push(...(zone.selectedProduct?.accessories.map(
              (acc) => ({ product: acc, compatibleProduct: zone.selectedProduct?.name })
            ) ?? []));
          });
        });
        accessoryProducts.forEach(({ product: accessory, compatibleProduct }) => {
          if (
            accessoryCategories[category]?.includes(accessory.type) &&
            accessory.type !== 'wire'
          ) {
            if (
              newGroup.accessories.some(
                (a) => a.product.productId === accessory.productId
              )
            ) {
              const index = newGroup.accessories.findIndex(
                (a) => a.product.productId === accessory.productId
              );
              if (
                compatibleProduct &&
                !newGroup.accessories[index].compatibleProducts.includes(compatibleProduct)
              ) {
                newGroup.accessories[index].compatibleProducts.push(compatibleProduct);
              }
            } else {
              newGroup.accessories.push({
                product: accessory,
                compatibleProducts: compatibleProduct ? [compatibleProduct] : []
              });
            }
          }
        });
      }
      newGroups.push(newGroup);
    });
    setAccessoryGroups(newGroups);
  };

  useEffect(() => {
    setSelectedSystems((draft) => {
      accessoryGroups.forEach((group) => {
        if (!group.accessories.length && !draft[activeSelectedSystem].accessoriesNotNeeded.includes(group.category)) {
          draft[activeSelectedSystem].accessoriesNotNeeded.push(group.category);
        }
      });
    });
  }, [accessoryGroups]);

  useEffect(() => {
    if (!selectedSystems[activeSelectedSystem].noAccessoriesNeeded) {
      for (const category of Object.keys(accessoryCategories) as AccessoryCategory[]) {
        if (
          !selectedSystems[activeSelectedSystem].accessoriesNotNeeded.includes(category) &&
          !selectedSystems[activeSelectedSystem].accessoriesCompleted.includes(category) &&
          category !== 'lineSetCover' &&
          (category !== 'ductingKit' || selectedSystems[activeSelectedSystem].configuration.condensers.some((c) => c.zones.some((z) => z.selectedProduct?.type === 'Multi Room')))
        ) {
          setActiveAccessoryDrawer(category);
          return;
        }
      }
    } else {
      setActiveAccessoryDrawer(null);
    }
  }, [
    activeSelectedSystem,
    JSON.stringify(selectedSystems.map(system => system.noAccessoriesNeeded))
  ]);

  useEffect(() => {
    createAccessoryGroups();
  }, [activeSelectedSystem]);

  const handleAccessoriesNotNeeded = (value: boolean) => {
    setSelectedSystems((draft) => {
      // eslint-disable-next-line no-param-reassign
      draft[activeSelectedSystem].noAccessoriesNeeded = value;
    });
  };

  const handleAccessoryNotNeeded = (
    category: AccessoryCategory,
    notNeeded: boolean
  ) => {
    setSelectedSystems((draft) => {
      if (notNeeded) {
        draft[activeSelectedSystem].accessoriesNotNeeded.push(category);
      } else {
        // eslint-disable-next-line no-param-reassign
        draft[activeSelectedSystem].accessoriesNotNeeded = draft[
          activeSelectedSystem
        ]?.accessoriesNotNeeded.filter((c) => c !== category);
      }
    });
  };

  const handleAccessoryChange = (
    accessory: AccessoryProduct,
    quantity: number
  ) => {
    setSelectedSystems((draft) => {
      const category = Object.keys(accessoryCategories).find((key) =>
        accessoryCategories[key].includes(accessory.type)
      ) as AccessoryCategory | undefined;
      const accessoryIndex = draft[
        activeSelectedSystem
      ].selectedAccessories.findIndex(
        (acc) => acc.productId === accessory.productId
      );
      if (accessoryIndex !== -1) {
        if (quantity > 0) {
          // eslint-disable-next-line no-param-reassign
          draft[activeSelectedSystem].selectedAccessories[accessoryIndex].quantity =
            quantity;
        } else {
          draft[activeSelectedSystem].selectedAccessories.splice(accessoryIndex, 1);
        }
      } else if (quantity > 0) {
        draft[activeSelectedSystem].selectedAccessories.push({
          ...accessory,
          quantity
        });
      }
      if (category) {
        // eslint-disable-next-line no-param-reassign
        draft[activeSelectedSystem].accessoriesCompleted = draft[
          activeSelectedSystem
        ].accessoriesCompleted.filter((c) => c !== category);
        if (
          draft[activeSelectedSystem].selectedAccessories.some((acc) =>
            accessoryCategories[category]?.includes(acc.type)
          )
        ) {
          draft[activeSelectedSystem].accessoriesCompleted.push(category);
        }
      }
    });
  };

  return (
    <div className='mx-auto flex max-w-[1400px] flex-col gap-5'>
      <div className='text-3xl font-bold text-gray-900'>
        Accessory Selection
      </div>
      {selectedSystems.length < 1 && (
        <InternalNote>No systems selected. Please select at least one system to accessorize.</InternalNote>
      )}
      {selectedSystems[activeSelectedSystem] && (
        <div ref={animate} className='flex grow flex-col gap-5'>
          <div className='flex justify-between items-center'>
            <div className="flex flex-wrap gap-3">
              {selectedSystems.map((system) => (
                <SystemOptionComponent key={system.id} system={system} isAccessoryPage />
              ))}
            </div>
            <label className='flex cursor-pointer items-center gap-2 p-2'>
              <ToggleSwitch
                value={!selectedSystems[activeSelectedSystem].noAccessoriesNeeded}
                onChange={(value) => handleAccessoriesNotNeeded(!value)}
              />
              <span className='whitespace-nowrap text-sm text-gray-600'>
                Include Accessories
              </span>
            </label>
          </div>
          <div className='flex flex-col gap-5'>
            <AccessoryDrawer
              category='lineSet'
              title='Picking a Line Set'
              description='These are the copper lines that run between the Condenser and the Air Handler inside.'
              itemSelected={selectedSystems[activeSelectedSystem].accessoriesCompleted.includes('lineSet')}
              notNeeded={selectedSystems[activeSelectedSystem].accessoriesNotNeeded.includes('lineSet')}
              handleNotNeeded={(value) => handleAccessoryNotNeeded('lineSet', value)}
              required={!selectedSystems[activeSelectedSystem].noAccessoriesNeeded && !selectedSystems[activeSelectedSystem].configuration.condensers.every(c => c.selectedProduct.diy)}
              disabled={selectedSystems[activeSelectedSystem].noAccessoriesNeeded}
              warning={selectedSystems[activeSelectedSystem].configuration.condensers.filter(c => !c.selectedProduct.diy).flatMap(c => c.zones.filter(z => z.addWire)).length > 0 &&
                selectedSystems[activeSelectedSystem].configuration.condensers.filter(c => !c.selectedProduct.diy).flatMap(c => c.zones.filter(z => z.addWire)).length <
                selectedSystems[activeSelectedSystem].configuration.condensers.filter(c => !c.selectedProduct.diy).flatMap(c => c.zones).length ?
                'Some zones are missing wire!' :
                undefined}
            >
              {selectedSystems[activeSelectedSystem].configuration.condensers.map((condenser) => (
                <LineSetOption
                  key={condenser.uniqueId}
                  condenser={condenser}
                />
              ))}
            </AccessoryDrawer>
            {selectedSystems[activeSelectedSystem].configuration.condensers.some((c) => c.zones.some((z) => z.selectedProduct?.type === 'Multi Room')) && (
              <AccessoryDrawer
                category='ductingKit'
                title='Picking the Ducting Kits'
                description='These are the return ducting kit options for the multi room air handlers. The most optimal supply kit and plenum kit will automatically be included.'
                itemSelected={selectedSystems[activeSelectedSystem].accessoriesCompleted.includes('ductingKit')}
                notNeeded={selectedSystems[activeSelectedSystem].accessoriesNotNeeded.includes('ductingKit')}
                handleNotNeeded={(value) => handleAccessoryNotNeeded('ductingKit', value)}
                required={!selectedSystems[activeSelectedSystem].noAccessoriesNeeded}
                disabled={selectedSystems[activeSelectedSystem].noAccessoriesNeeded}
              >
                {selectedSystems[activeSelectedSystem].configuration.condensers.map((condenser) => (
                  <DuctingKitOption
                    key={condenser.uniqueId}
                    condenser={condenser}
                    zones={condenser.zones}
                    systemOption={selectedSystems[activeSelectedSystem]}
                  />
                ))}
              </AccessoryDrawer>
            )}
            {accessoryGroups.filter(group => !group.advanced && group.accessories.length > 0).map((group) => (
              <AccessoryDrawer
                key={group.category}
                category={group.category}
                title={group.label}
                description={group.description}
                itemSelected={selectedSystems[
                  activeSelectedSystem
                ].accessoriesCompleted.includes(group.category)}
                notNeeded={selectedSystems[
                  activeSelectedSystem
                ].accessoriesNotNeeded.includes(group.category)}
                handleNotNeeded={(value) =>
                  handleAccessoryNotNeeded(group.category, value)
                }
                required={!selectedSystems[activeSelectedSystem].noAccessoriesNeeded}
                disabled={selectedSystems[activeSelectedSystem].noAccessoriesNeeded}
                warning={selectedSystems[activeSelectedSystem].selectedAccessories.filter((acc) => group.accessories.map(a => a.product.productId).includes(acc.productId)).some((acc) =>
                  (group.productType === 'condenser' && acc.quantity > 0 && acc.quantity !== selectedSystems[activeSelectedSystem].configuration.condensers.length) ||
                  (group.productType === 'air handler' && acc.quantity > 0 && acc.quantity !== selectedSystems[activeSelectedSystem].configuration.condensers.flatMap(c => c.zones).length)) ?
                  'Accessory quantities do not match component quantities!' :
                  undefined
                }
              >
                {group.accessories.length > 0 ? group.accessories.map((accessory) => (
                  <QuantityOption
                    key={accessory.product.productId}
                    accessory={accessory.product}
                    quantity={
                      selectedSystems[activeSelectedSystem].selectedAccessories.find(
                        (acc) => acc.productId === accessory.product.productId
                      )?.quantity ?? 0
                    }
                    compatibleProducts={accessory.compatibleProducts}
                    onChange={(value) =>
                      handleAccessoryChange(accessory.product, value)
                    }
                  />
                )) : (
                  <div className='w-full h-32 flex items-center justify-center'>
                    <span className='text-gray-500'>
                      No accessories available for this category
                    </span>
                  </div>
                )}
              </AccessoryDrawer>
            ))}
            <div>
              <button
                type='button'
                className='flex items-center text-xl font-bold text-gray-900'
                onClick={() => setShowAdvanced(!showAdvanced)}
              >
                <span>Advanced</span>
                {showAdvanced ? (
                  <ChevronDownIcon className='h-7 w-7' />
                ) : (
                  <ChevronRightIcon className='h-7 w-7' />
                )}
              </button>
            </div>
            {showAdvanced && accessoryGroups.filter(group => group.advanced && group.accessories.length > 0).map((group) => (
              <AccessoryDrawer
                key={group.category}
                category={group.category}
                title={group.label}
                description={group.description}
                itemSelected={selectedSystems[
                  activeSelectedSystem
                ].accessoriesCompleted.includes(group.category)}
                notNeeded={selectedSystems[
                  activeSelectedSystem
                ].accessoriesNotNeeded.includes(group.category)}
                handleNotNeeded={(value) =>
                  handleAccessoryNotNeeded(group.category, value)
                }
                required={false}
                disabled={selectedSystems[activeSelectedSystem].noAccessoriesNeeded}
                warning={selectedSystems[activeSelectedSystem].selectedAccessories.filter((acc) => group.accessories.map(a => a.product.productId).includes(acc.productId)).some((acc) =>
                  (group.productType === 'condenser' && acc.quantity > 0 && acc.quantity !== selectedSystems[activeSelectedSystem].configuration.condensers.length) ||
                  (group.productType === 'air handler' && acc.quantity > 0 && acc.quantity !== selectedSystems[activeSelectedSystem].configuration.condensers.flatMap(c => c.zones).length)) ?
                  'Accessory quantities do not match component quantities!' :
                  undefined
                }
              >
                {group.accessories.length > 0 ? group.accessories.map((accessory) => (
                  <QuantityOption
                    key={accessory.product.productId}
                    accessory={accessory.product}
                    quantity={
                      selectedSystems[activeSelectedSystem].selectedAccessories.find(
                        (acc) => acc.productId === accessory.product.productId
                      )?.quantity ?? 0
                    }
                    compatibleProducts={accessory.compatibleProducts}
                    onChange={(value) =>
                      handleAccessoryChange(accessory.product, value)
                    }
                  />
                )) : (
                  <div className='w-full h-32 flex items-center justify-center'>
                    <span className='text-gray-500'>
                      No accessories available for this category
                    </span>
                  </div>
                )}
              </AccessoryDrawer>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
