import React, { Dispatch, SetStateAction, useContext } from 'react';
import Modal from 'components/modals/modal';
import { DataContext, ProjectContext, UIContext } from 'data/context';
import Tag from 'components/tag';
import { formatUSD } from 'utils/helpers';
import InfoTooltip from 'components/info-tooltip';
import ProductPhoto from 'components/product-photo';
import { SelectedSystem } from 'data/types';

type RebatesModalProps = {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
  system: SelectedSystem | null;
};

export default function RebatesModal({ openState, system }: RebatesModalProps) {
  const { condenserProducts, airHandlerProducts } = useContext(DataContext);
  const { locationData } = useContext(ProjectContext);
  const { rebatesForModal } = useContext(UIContext);

  const productQuantities = system?.configuration.condensers.reduce((acc, condenser) => {
    if (condenser.selectedProduct) {
      acc[condenser.selectedProduct.productId] = (acc[condenser.selectedProduct.productId] || 0) + 1;
    }
    condenser.zones.forEach(zone => {
      if (zone.selectedProduct) {
        acc[zone.selectedProduct.productId] = (acc[zone.selectedProduct.productId] || 0) + 1;
      }
    });
    return acc;
  }, {} as Record<number, number>) || {};

  return (
    <Modal
      title={`Available Rebates for ${locationData?.location ?? 'Your Location'}`}
      openState={openState}
      infoMode
    >
      <div className='flex flex-col gap-5'>
        {rebatesForModal.map((rebate, rebateIndex) => {
          const { rebateMinimum, rebateMaximum } = rebate;
          const product = airHandlerProducts.find(p => p.productId === rebate.productId) ?? condenserProducts.find(p => p.productId === rebate.productId);
          const quantity = productQuantities[rebate.productId] || 1;
          return (
            <div key={rebateIndex} className="flex flex-col gap-5">
              <div className='flex items-center gap-2'>
                {product && <ProductPhoto product={product} />}
                <div className='grow'>
                  <div className='flex items-center justify-between gap-5 text-lg'>
                    <span>{product?.name ?? rebate.productName} {quantity > 1 && `(${quantity} units)`}</span>
                    <span>{formatUSD(rebate.price * quantity)}</span>
                  </div>
                  <div className='flex items-center justify-between gap-5 text-lg font-semibold'>
                    <div className='inline-flex items-center gap-1'>
                      <span className='whitespace-nowrap'>Potential Rebates</span>
                      <InfoTooltip>Your eligibility for specific rebates and tax credits may depend on various criteria. Please see the individual rebate or tax credit for details.</InfoTooltip>
                    </div>
                    <span>
                      {formatUSD(rebateMinimum * quantity)}
                      {rebateMinimum !== rebateMaximum && ` - ${formatUSD(rebateMaximum * quantity)}`}
                    </span>
                  </div>
                </div>
              </div>
              <div className='flex flex-col gap-5'>
                {rebate.programs.map((program, programIndex) => (
                  <div key={programIndex} className='flex flex-col gap-3 p-3 bg-gray-100 rounded-md border-2 border-gray-300'>
                    <div className="flex items-start justify-between gap-5 text-xl font-semibold">
                      <span>{program.name}</span>
                      <span className='text-right whitespace-nowrap'>up to {formatUSD((program.min / 100) * quantity)}
                        {program.max && program.min !== program.max && ` - ${formatUSD((program.max / 100) * quantity)}`}
                      </span>
                    </div>
                    <div className="flex flex-wrap gap-2">
                      {program.tags.map((tag, tagIndex) => (
                        <Tag
                          key={tagIndex}
                          text={tag.display_name.trim()}
                          customColor={tag.color_hex}
                          textColor='white'
                        />
                      ))}
                    </div>
                    <div className="flex flex-col">
                      <div className='font-semibold'>Program Details:</div>
                      <div>{program.description} {program.link && <a className='text-blue-500 hover:text-blue-600 font-semibold underline' href={program.link}>Learn More</a>}</div>
                    </div>
                    <div className='flex flex-col'>
                      <div className='font-semibold'>How to Redeem:</div>
                      <div>{program.apply_description} {program.app_link_digital && <a className='text-blue-500 hover:text-blue-600 font-semibold underline' href={program.app_link_digital}>Apply Here</a>}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          );
        }
        )}
      </div>
    </Modal>
  );
}