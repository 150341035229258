import React, { ReactNode } from 'react';
import { MegaphoneIcon } from '@heroicons/react/24/outline';

type TalkingPointProps = {
  children?: ReactNode;
};

export default function TalkingPoint({ children }: TalkingPointProps) {
  return (
    <div className='w-full rounded-lg bg-purple-100 px-4 py-3 text-purple-700 shadow'>
      <div className='flex gap-2'>
        <MegaphoneIcon className='h-5 w-5 flex-shrink-0' />
        <div className='grow text-sm'>{children}</div>
      </div>
    </div>
  );
}

TalkingPoint.defaultProps = {
  children: null
};
