import React, { useContext, useEffect, useState } from 'react';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import { ProjectContext, UIContext } from 'data/context';
import PropertyStatistic from 'components/property-statistic';
import Button from 'components/inputs/button';

type PropertyStat = {
  label: string;
  value: string | number | null;
};

export default function PropertySummary() {
  const { attomData } = useContext(ProjectContext);
  const { setShowPropertySummary } = useContext(UIContext);
  const [primaryStats, setPrimaryStats] = useState<PropertyStat[]>([]);
  const [secondaryStats, setSecondaryStats] = useState<PropertyStat[]>([]);
  const [locationStats, setLocationStats] = useState<PropertyStat[]>([]);
  const [generalStats, setGeneralStats] = useState<PropertyStat[]>([]);

  useEffect(() => {
    if (attomData) {
      setPrimaryStats([
        { label: 'Year Built', value: attomData.summary.yearBuilt },
        {
          label: 'Living Square Footage',
          value: attomData.building.size.livingSize
        },
        {
          label: 'Total Rooms',
          value: attomData.building.rooms.roomsTotal
        },
        {
          label: 'Total Bedrooms',
          value: attomData.building.rooms.beds
        },
        {
          label: 'Total Bathrooms',
          value: attomData.building.rooms.bathsTotal
        },
        { label: 'Levels', value: attomData.building.summary.levels },
        { label: 'Structure Style', value: attomData.summary.propClass },
        { label: 'Cooling Type', value: attomData.utilities.coolingType },
        { label: 'Energy Type', value: attomData.utilities.energyType },
        { label: 'Heating Fuel', value: attomData.utilities.heatingFuel },
        { label: 'Heating Type', value: attomData.utilities.heatingType },
        { label: 'Garage Type', value: attomData.building.parking.garageType }
      ]);

      setSecondaryStats([
        { label: 'Lot Depth', value: attomData.lot.depth },
        { label: 'Lot Width', value: attomData.lot.frontage },
        { label: 'Classification', value: attomData.summary.propType },
        {
          label: 'Specific Classification',
          value: attomData.summary.propSubType
        },
        {
          label: 'Building Shape type',
          value: attomData.building.construction.buildingShapeType
        },
        {
          label: 'Sewer Type',
          value: attomData.utilities.sewerType
        },
        {
          label: 'Total Square Footage',
          value: attomData.building.size.bldgSize
        },
        { label: 'Attic Size', value: attomData.building.size.atticSize },
        {
          label: 'Total Area of Basement',
          value: attomData.building.interior.bsmtSize
        },
        { label: 'Basement Type', value: attomData.building.interior.bsmtType },
        {
          label: 'Total Parking Spaces',
          value: attomData.building.parking.prkgSpaces
        },
        { label: 'Parking Type', value: attomData.building.parking.prkgType },
        {
          label: 'Construction Type',
          value: attomData.building.construction.constructionType
        },
        {
          label: 'Building Condition',
          value: attomData.building.construction.condition
        },
        {
          label: 'Total Fireplaces',
          value: attomData.building.interior.fplcCount
        },
        {
          label: 'Frame Type',
          value: attomData.building.construction.frameType
        },
        {
          label: 'Garage Square Footage',
          value: attomData.building.parking.prkgSize
        }
      ]);

      setLocationStats([
        { label: 'Country', value: attomData.address.country },
        { label: 'State', value: attomData.address.countrySubd },
        { label: 'Address 1', value: attomData.address.line1 },
        { label: 'Address 2', value: attomData.address.line2 },
        { label: 'City', value: attomData.address.locality },
        { label: 'Zip Code', value: attomData.address.postal1 },
        { label: 'Unit #', value: attomData.address.unitNumberIdent },
        { label: 'House #', value: attomData.address.situsHouseNumber },
        { label: 'Street Name', value: attomData.address.situsStreetName },
        {
          label: 'Street Name Suffix',
          value: attomData.address.situsAddressSuffix
        },
        { label: 'Elevation', value: attomData.location.elevation },
        { label: 'Latitude', value: attomData.location.latitude },
        { label: 'Longitude', value: attomData.location.longitude }
      ]);

      setGeneralStats([
        { label: 'ATTOM ID', value: attomData.identifier.attomId },
        { label: 'Data Last Modified', value: attomData.vintage.lastModified },
        { label: 'Data Published', value: attomData.vintage.pubDate }
      ]);
    }
  }, [attomData]);

  return (
    <div className='flex flex-col gap-3'>
      <div>
        <Button
          text='Back to Project Details'
          color='secondary'
          icon={ArrowLeftIcon}
          onClick={() => setShowPropertySummary(false)}
        />
      </div>
      <div className='text-3xl font-bold text-gray-900'>Property Summary</div>
      <div className='font-semibold text-gray-900'>Primary Characteristics</div>
      <div className='flex flex-wrap gap-3'>
        {primaryStats.map((stat) => (
          <div
            key={stat.label}
            className='w-full md:w-[calc(50%-6px)] xl:w-[calc(20%-12px)]'
          >
            <PropertyStatistic label={stat.label} value={stat.value} />
          </div>
        ))}
      </div>
      <div className='font-semibold text-gray-900'>
        Secondary Characteristics
      </div>
      <div className='flex flex-wrap gap-3'>
        {secondaryStats.map((stat) => (
          <div
            key={stat.label}
            className='w-full md:w-[calc(50%-6px)] xl:w-[calc(20%-12px)]'
          >
            <PropertyStatistic label={stat.label} value={stat.value} />
          </div>
        ))}
      </div>
      <div className='font-semibold text-gray-900'>Location</div>
      <div className='flex flex-wrap gap-3'>
        {locationStats.map((stat) => (
          <div
            key={stat.label}
            className='w-full md:w-[calc(50%-6px)] xl:w-[calc(20%-12px)]'
          >
            <PropertyStatistic label={stat.label} value={stat.value} />
          </div>
        ))}
      </div>
      <div className='font-semibold text-gray-900'>General</div>
      <div className='flex flex-wrap gap-3'>
        {generalStats.map((stat) => (
          <div
            key={stat.label}
            className='w-full md:w-[calc(50%-6px)] xl:w-[calc(20%-12px)]'
          >
            <PropertyStatistic label={stat.label} value={stat.value} />
          </div>
        ))}
      </div>
    </div>
  );
}
